import React, { FC, useMemo, useRef, useState } from "react"
import { batch, useSelector } from "react-redux"
import { AnchorButton, Button, Icon, Menu, MenuItem } from "@blueprintjs/core"
import { ContextMenu2, Popover2, Tooltip2 } from "@blueprintjs/popover2"
import copy from "copy-to-clipboard"
import { v4 as uuidv4 } from "uuid"

import {
  ClipboardStockLocator,
  ClipboardStockLocatorKindEnum,
  ExportCoordinates,
} from "src/client-axios"
import { DeleteStockModal } from "src/components/Cam/DeleteStockModal/DeleteStockModal"
import { InputStockConfigPanel } from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/InputStockConfigPanel/InputStockConfigPanel"
import { ConfigPanelSelect } from "src/components/Cam/TreeNavigator/ConfigPanel/props"
import { UploadModelDialog } from "src/components/Cam/UploadModelDialog/UploadModelDialog"
import { InputStockSceneViewer } from "src/components/Canvas/Viewer/Scene/Cam/InputStockSceneViewer/InputStockSceneViewer"
import { PasteMenuItem } from "src/components/Generic/Clipboard/PasteMenuItem/PasteMenuItem"
import { AnnotationButtons } from "src/components/Shared/AnnotationButtons/AnnotationButtons"
import { sendEvent } from "src/components/Websocket/Websocket"
import {
  CamJobDetailsFragment,
  PlanGenerationFeedback,
  useUpdateInputStockMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { SceneClickData } from "src/hooks/useSceneClickEventHandler"
import { useToaster } from "src/hooks/useToaster"
import { activeActions } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode } from "src/store/ui/viewerModal"
import { defaultTransform, MatrixTransform, transformToMatrix } from "src/util/geometry/transforms"
import { UpdatePartModelButton } from "../JobOverview"
import { GenerateStockPanel } from "./GenerateStockPanel/GenerateStockPanel"

import styles from "./StockCard.module.css"

export const StockCard: FC<{
  camJob: CamJobDetailsFragment
  planId: string
  operationIdx: number
  revision?: number
  minimal?: boolean
  pauseRender?: boolean
  feedbackMode?: boolean
  generatedFeedback?: PlanGenerationFeedback | undefined
  getSidebarData?: () => void
  locked?: boolean
}> = ({
  camJob,
  planId,
  operationIdx,
  minimal,
  pauseRender,
  feedbackMode,
  generatedFeedback,
  revision,
  getSidebarData,
  locked,
}) => {
  const handleSceneClickDataUpdateRef = useRef<
    ((data: SceneClickData | undefined) => void) | undefined
  >()
  const contextMenuId = useMemo(() => uuidv4(), [])

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )
  const designId = operation?.designId
  const dispatch = useAppDispatch()
  const toaster = useToaster()
  const { plansApi } = useApi()

  const [showDelete, setShowDelete] = useState(false)
  const [forceLocked, setForceLocked] = useState(true)

  const locator: ClipboardStockLocator = {
    planId,
    revision,
    operationIdx,
    kind: ClipboardStockLocatorKindEnum.Stock,
  }

  const copyStock = () => {
    const copyText = JSON.stringify(locator, null, 2)
    copy(copyText)
    toaster.show({ message: `Copied stock details`, icon: "clipboard", intent: "primary" })
  }

  const pasteStock = (pastedText: string) => {
    const source = parseClipboardStockLocator(pastedText)
    if (!source) {
      toaster.show({
        message: "The clipboard contents did not reference a RemoteShop stock",
        icon: "error",
        intent: "warning",
      })
      return
    }

    const target = locator
    plansApi
      .copyStock({ source, target })
      .then(() => toaster.show({ message: "Pasted stock", icon: "clipboard", intent: "success" }))
      .catch(() => toaster.show({ message: "Pasting stock failed", intent: "danger" }))
  }

  const handleClick3dView = () => {
    sendEvent("open3dView", { jobId: camJob?.id, planId })
    batch(() => {
      dispatch(activeActions.setActivePlanId(String(planId)))
      dispatch(activeActions.setActiveOperationIdx(operationIdx))
      dispatch(
        activeActions.setActiveConfigPanel({
          kind: "restStockLocation",
          planId,
          operationIdx,
        } as ConfigPanelSelect)
      )
      dispatch(viewerModalActions.setViewerModalIsOpen(true))
      dispatch(viewerModalActions.setViewerModalFilterPlans(true))
      dispatch(viewerModalActions.setViewerModalModelId(camJob?.model?.id))
      dispatch(viewerModalActions.setViewerModalMode(ViewerModalMode.Setup))
      dispatch(viewerModalActions.setViewerModalJobId(camJob?.id))
    })
  }

  const handleClickLock = () => {
    if (locked) return
    setForceLocked(!forceLocked)
  }

  const contextMenu = (
    <Menu>
      <MenuItem text={"Copy Stock"} onClick={() => copyStock()} />
      <PasteMenuItem text={"Paste Stock"} onPaste={pasteStock} />
    </Menu>
  )

  return (
    <div className={styles.container}>
      {!locked && (
        <div onContextMenu={event => event.preventDefault()}>
          <ContextMenu2 content={contextMenu}>
            <div id={contextMenuId} />
          </ContextMenu2>
        </div>
      )}
      {!minimal && (
        <>
          <div className={styles.rightCardIcons}>
            {!minimal && !forceLocked && (
              <ModelStockUpload planId={planId} operationIdx={operationIdx} />
            )}
            {operationIdx === 0 && !forceLocked && (
              <Popover2 position={"right"} content={<GenerateStockPanel planId={planId} />}>
                <Tooltip2 content={"Auto-select stock"} openOnTargetFocus={false}>
                  <AnchorButton minimal icon="refresh" intent={"success"} />
                </Tooltip2>
              </Popover2>
            )}
            {operationIdx !== 0 && !forceLocked && (
              <>
                <Tooltip2 content={"Remove Stock from this Operation"} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon="cross"
                    onClick={() => {
                      setShowDelete(true)
                    }}
                  />
                </Tooltip2>
                {showDelete && (
                  <DeleteStockModal
                    planId={planId}
                    operationIdx={operationIdx}
                    isOpen={showDelete}
                    close={() => {
                      setShowDelete(false)
                    }}
                  />
                )}
              </>
            )}
            <Button icon={forceLocked ? "lock" : "unlock"} minimal onClick={handleClickLock} />
          </div>
        </>
      )}

      <InputStockSceneViewer
        planId={planId}
        operationIdx={operationIdx}
        modelId={designId ?? camJob.model?.id}
        pauseRender={pauseRender}
        handleClick3dView={handleClick3dView}
        contextMenuId={contextMenuId}
        handleSceneClickDataUpdateRef={handleSceneClickDataUpdateRef}
      />

      <h6 className={"bp3-heading"}>Stock Preparation</h6>
      {!minimal && (
        <InputStockConfigPanel
          forCard
          planId={planId}
          operationIdx={operationIdx}
          locked={forceLocked}
        />
      )}
      <UpdatePartModelButton jobId={camJob.id} planId={planId} operationIdx={operationIdx} />
      {feedbackMode && getSidebarData && (
        <AnnotationButtons
          {...{
            generatedFeedback,
            planId,
            revision,
            component: `stock`,
            getSidebarData,
          }}
        />
      )}
    </div>
  )
}

const ModelStockUpload: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const [modalOpen, setIsModalOpen] = useState(false)

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )

  const {
    settings: { exportCoordinates: coordinates },
  } = useLocalStorageSettings()

  const [updateInputStock] = useUpdateInputStockMutation()

  const onModelCreation = (modelId: string, _: string) => {
    const transform = operation
      ? new MatrixTransform(
          coordinates === ExportCoordinates.Part
            ? defaultTransform()
            : coordinates === ExportCoordinates.Wcs
            ? transformToMatrix(operation.wcs)
            : transformToMatrix(operation.mcs)
        )
      : defaultTransform()

    updateInputStock({
      variables: {
        planId,
        operationIdx,
        stock: {
          model: {
            modelId,
            transform,
          },
        },
      },
    }).then(() => {
      setIsModalOpen(false)
    })
  }

  return (
    <>
      <Tooltip2 content={"Upload Custom Stock"} openOnTargetFocus={false}>
        <AnchorButton
          minimal
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <Icon icon="upload" />
        </AnchorButton>
      </Tooltip2>
      {modalOpen && (
        <UploadModelDialog
          onModelCreation={onModelCreation}
          isOpen={modalOpen}
          handleRequestClose={() => setIsModalOpen(false)}
          title={"Upload Custom Stock Model"}
          requireName={false}
        />
      )}
    </>
  )
}

const parseClipboardStockLocator = (text: string): ClipboardStockLocator | undefined => {
  try {
    const parsed = JSON.parse(text) as Record<string, unknown>
    const { kind, planId, revision, operationIdx } = parsed

    if (kind !== ClipboardStockLocatorKindEnum.Stock) return undefined
    if (typeof planId !== "string") return undefined
    if (revision !== undefined && typeof revision !== "number") return undefined
    if (operationIdx !== undefined && typeof operationIdx !== "number") return undefined

    return { kind, planId, revision, operationIdx: operationIdx ?? 0 }
  } catch (err) {
    return undefined
  }
}
