import React, { FC } from "react"

import { Modal } from "src/components/Generic/Modal/Modal"
import { sendEvent } from "src/components/Websocket/Websocket"
import { useDeleteInputStockMutation } from "src/graphql/generated"

export const DeleteStockModal: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  close: () => void
}> = ({ planId, operationIdx, isOpen, close }) => {
  const [deleteStock] = useDeleteInputStockMutation()
  const handleConfirm = () => {
    sendEvent("deleteStock", { planId, operationIdx })

    deleteStock({ variables: { planId, operationIdx } }).then(() => {
      close()
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      headerText="Delete Input Stock?"
      bodyText="Are you sure you want to delete input stock for this Operation?"
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      handleCancel={close}
      handleConfirm={handleConfirm}
      handleRequestClose={close}
    />
  )
}
