import {
  grayscale,
  PDFArray,
  PDFDict,
  PDFDocument,
  PDFFont,
  PDFName,
  PDFNumber,
  PDFPageLeaf,
  PDFRef,
  PDFString,
  StandardFonts,
} from "pdf-lib"

import { Bubble } from "src/pages/DrawingViewer/interfaces"
import { downloadFromBlob } from "src/util/files"
import { sortBy } from "src/util/sortBy"

export const downloadBubbledPdf = async (
  sourceUrl: string | undefined,
  filename: string,
  bubbles: Bubble[]
): Promise<void> => {
  if (!sourceUrl) return
  const arrayBuffer = await fetch(sourceUrl).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(arrayBuffer)

  const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const reversedBubbles = [...bubbles].reverse()
  reversedBubbles.forEach(bubble => drawBubbleInDocument(pdfDoc, font, bubble))
  await createOutlines(pdfDoc, bubbles)
  // noinspection JSVoidFunctionReturnValueUsed
  const pdfBytes = await pdfDoc.save()

  downloadFromBlob(pdfBytes, filename, "application/pdf")
}

const HIGHQA_SCALING_FACTOR = 1 / 1.3333
const TEXT_HEIGHT_PAGE_FRACTION = 0.01

const drawBubbleInDocument = (pdfDoc: PDFDocument, font: PDFFont, bubble: Bubble): void => {
  const {
    label,
    location: { pageIndex, topLeft, extents },
    appearance: { fill, textColor },
  } = bubble
  if (Number.isNaN(topLeft.y)) return // Not present in the underlying shape data
  if (Number.isNaN(extents.y)) return // Not present in the underlying shape data

  const pages = pdfDoc.getPages()
  const page = pages[pageIndex]
  if (!page) return

  const desiredTextHeight = Math.min(page.getHeight(), page.getWidth()) * TEXT_HEIGHT_PAGE_FRACTION
  const fontSize = font.sizeAtHeight(desiredTextHeight)
  const textWidth = font.widthOfTextAtSize(label, fontSize)
  const textHeight = font.heightAtSize(fontSize)

  const { height: pageHeight } = page.getSize()

  // const offset = { x: -textHeight * 1.2, y: 0.0 }
  const offset = { x: -textHeight * 0.5, y: textHeight * 0.5 }

  if (!fill.hideBox) {
    page.drawRectangle({
      x: topLeft.x * HIGHQA_SCALING_FACTOR,
      y: pageHeight - (topLeft.y + extents.y) * HIGHQA_SCALING_FACTOR,
      width: extents.x * HIGHQA_SCALING_FACTOR,
      height: extents.y * HIGHQA_SCALING_FACTOR,
      borderWidth: 1,
      borderColor: grayscale(0.5),
      color: fill.color,
      opacity: fill.boxOpacity,
      borderOpacity: 0.75,
    })
  }

  page.drawCircle({
    x: topLeft.x * HIGHQA_SCALING_FACTOR + offset.x,
    y: pageHeight - topLeft.y * HIGHQA_SCALING_FACTOR + offset.y,
    size: textHeight * 1.2,
    borderWidth: 1,
    borderColor: grayscale(0.5),
    color: fill.color,
    opacity: fill.bubbleOpacity,
    borderOpacity: 0.75,
  })

  page.drawText(label, {
    x: topLeft.x * HIGHQA_SCALING_FACTOR - textWidth / 2 + offset.x,
    y: pageHeight - topLeft.y * HIGHQA_SCALING_FACTOR - textHeight / 2 + offset.y,
    size: fontSize,
    font: font,
    color: textColor,
  })
}

const createOutlines = async (doc: PDFDocument, bubbles: Bubble[]): Promise<void> => {
  const getPageRefs = (pdfDoc: PDFDocument): PDFRef[] => {
    const refs: PDFRef[] = []
    pdfDoc.catalog.Pages().traverse((kid, ref) => {
      if (kid instanceof PDFPageLeaf) refs.push(ref)
    })
    return refs
  }

  const createOutlineItem = (
    pdfDoc: PDFDocument,
    position: { x: number; y: number },
    title: string,
    parent: PDFRef,
    page: PDFRef,
    next?: PDFRef,
    prev?: PDFRef
  ): PDFDict => {
    // TODO: Use FitR not XYZ
    const array = PDFArray.withContext(pdfDoc.context)
    array.push(page)
    array.push(PDFName.of("XYZ"))
    array.push(PDFNumber.of(position.x))
    array.push(PDFNumber.of(position.y))
    array.push(PDFNumber.of(2.0))

    const map = new Map()
    map.set(PDFName.Title, PDFString.of(title))
    map.set(PDFName.Parent, parent)
    prev && map.set(PDFName.of("Prev"), prev)
    next && map.set(PDFName.of("Next"), next)
    map.set(PDFName.of("Dest"), array)

    return PDFDict.fromMapWithContext(map, pdfDoc.context)
  }

  const pageRefs = getPageRefs(doc)

  const outlinesDictRef = doc.context.nextRef()
  const dimOutlineItemRefs: PDFRef[] = []
  const dimOutlineItems: PDFDict[] = []

  const sortedBubbles = sortBy(bubbles, bubble => +bubble.label)

  sortedBubbles.forEach(() => {
    dimOutlineItemRefs.push(doc.context.nextRef())
  })
  sortedBubbles.forEach((bubble, i) => {
    const {
      label,
      scoring: { hardestDimDescription },
      location: { pageIndex, topLeft },
    } = bubble
    const pageHeight = doc.getPage(pageIndex).getHeight()
    dimOutlineItems.push(
      createOutlineItem(
        doc,
        {
          x: topLeft.x * HIGHQA_SCALING_FACTOR - 100,
          y: pageHeight - topLeft.y * HIGHQA_SCALING_FACTOR + 100,
        },
        `Dim ${label} [${bubble.scoring.difficultyGroup[0].toUpperCase()}]: ${hardestDimDescription}`,
        outlinesDictRef,
        pageRefs[pageIndex],
        i < sortedBubbles.length - 1 ? dimOutlineItemRefs[i + 1] : undefined,
        i > 0 ? dimOutlineItemRefs[i - 1] : undefined
      )
    )
  })

  const outlinesDictMap = new Map()
  outlinesDictMap.set(PDFName.Type, PDFName.of("Outlines"))
  outlinesDictMap.set(PDFName.of("First"), dimOutlineItemRefs[0])
  outlinesDictMap.set(PDFName.of("Last"), dimOutlineItemRefs[dimOutlineItemRefs.length - 1])
  outlinesDictMap.set(PDFName.of("Count"), PDFNumber.of(dimOutlineItemRefs.length))
  doc.catalog.set(PDFName.of("Outlines"), outlinesDictRef)

  const outlinesDict = PDFDict.fromMapWithContext(outlinesDictMap, doc.context)
  doc.context.assign(outlinesDictRef, outlinesDict)
  sortedBubbles.forEach((_, i) => {
    doc.context.assign(dimOutlineItemRefs[i], dimOutlineItems[i])
  })
}

// Below here is an attempt at building a nested hierarchy. It didn't work, but maybe is close?
// const createOutlines = async (doc: PDFDocument, bubbles: Bubble[]): Promise<void> => {
//   const getPageRefs = (pdfDoc: PDFDocument): PDFRef[] => {
//     const refs: PDFRef[] = []
//     pdfDoc.catalog.Pages().traverse((kid, ref) => {
//       if (kid instanceof PDFPageLeaf) refs.push(ref)
//     })
//     return refs
//   }
//
//   const getShapeDestPosition = (
//     location: ShapeLocation,
//     pageHeight: number
//   ): { x: number; y: number } => {
//     const radX = (location.extents.x / 2) * HIGHQA_SCALING_FACTOR
//     const radY = (location.extents.y / 2) * HIGHQA_SCALING_FACTOR
//     const centerX = location.topLeft.x * HIGHQA_SCALING_FACTOR + radX
//     const centerY = pageHeight - location.topLeft.y * HIGHQA_SCALING_FACTOR - radY
//
//     const offset = Math.max(radX, radY)
//     return {
//       x: centerX - radX - offset,
//       y: centerY + radY + offset,
//     }
//   }
//
//   const createOutlineItemDest = (
//     pdfDoc: PDFDocument,
//     page: PDFRef,
//     position?: { x: number; y: number }
//   ): PDFArray => {
//     const destArray = PDFArray.withContext(pdfDoc.context)
//     destArray.push(page)
//     if (!position) {
//       destArray.push(PDFName.of("Fit"))
//     } else {
//       destArray.push(PDFName.of("XYZ"))
//       destArray.push(PDFNumber.of(position.x))
//       destArray.push(PDFNumber.of(position.y))
//       destArray.push(PDFNumber.of(1.0))
//     }
//     return destArray
//   }
//
//   const createOutlineItem = (
//     pdfDoc: PDFDocument,
//     title: string,
//     page: PDFRef,
//     parent: PDFRef,
//     next: PDFRef | undefined,
//     prev: PDFRef | undefined,
//     position?: { x: number; y: number }
//   ): PDFDict => {
//     const destArray = createOutlineItemDest(pdfDoc, page, position)
//
//     const map = new Map()
//     map.set(PDFName.Title, PDFString.of(title))
//     map.set(PDFName.Parent, parent)
//     map.set(PDFName.of("Dest"), destArray)
//     prev && map.set(PDFName.of("Prev"), prev)
//     next && map.set(PDFName.of("Next"), next)
//
//     return PDFDict.fromMapWithContext(map, pdfDoc.context)
//   }
//
//   const createDimsOutline = (pdfDoc: PDFDocument, outlineData: OutlineData) => {
//     const pageRefs = getPageRefs(pdfDoc)
//
//     const allOutlineItemRefs: PDFRef[] = []
//     const allOutlineItems: PDFDict[] = []
//
//     const getNewOutlineItemRef = () => {
//       const ref = pdfDoc.context.nextRef()
//       allOutlineItemRefs.push(ref)
//       return ref
//     }
//
//     const outlinesDictRef = pdfDoc.context.nextRef()
//
//     const outlineItemRefs: {
//       pageItemRef: PDFRef
//       groups: { [K in DifficultyGroup]: { groupRef: PDFRef; dimRefs: PDFRef[] } }
//     }[] = outlineData.map(pageData => {
//       const pageItemRef = getNewOutlineItemRef()
//       const getGroupData = (g: DifficultyGroup) => ({
//         groupRef: getNewOutlineItemRef(),
//         dimRefs: pageData[g]?.map(() => getNewOutlineItemRef()) ?? [],
//       })
//       return {
//         pageItemRef,
//         groups: {
//           [DifficultyGroup.yellow]: getGroupData(DifficultyGroup.yellow),
//           [DifficultyGroup.blue]: getGroupData(DifficultyGroup.blue),
//           [DifficultyGroup.gray]: getGroupData(DifficultyGroup.gray),
//         },
//       }
//     })
//
//     outlineData.forEach((pageData, pageIndex) => {
//       const pageHeight = pdfDoc.getPage(pageIndex).getHeight()
//       const pageRef = pageRefs[pageIndex]
//       const pageOutlineItem = createOutlineItem(
//         pdfDoc,
//         `Page ${pageIndex + 1}`,
//         pageRef,
//         outlinesDictRef,
//         pageIndex < pageRefs.length - 1 ? outlineItemRefs[pageIndex + 1].pageItemRef : undefined,
//         pageIndex > 0 ? outlineItemRefs[pageIndex - 1].pageItemRef : undefined
//       )
//       allOutlineItems.push(pageOutlineItem)
//
//       const groups = [DifficultyGroup.yellow, DifficultyGroup.blue, DifficultyGroup.gray]
//       groups.forEach((group, groupIndex) => {
//         const groupOutlineItem = createOutlineItem(
//           pdfDoc,
//           `${group[0].toUpperCase() + group.slice(1)}`,
//           pageRef,
//           outlineItemRefs[pageIndex].pageItemRef,
//           groupIndex < groups.length - 1
//             ? outlineItemRefs[pageIndex].groups[groups[groupIndex + 1]].groupRef
//             : undefined,
//           groupIndex > 0
//             ? outlineItemRefs[pageIndex].groups[groups[groupIndex - 1]].groupRef
//             : undefined
//         )
//         allOutlineItems.push(groupOutlineItem)
//         const bubbles = pageData[group] ?? []
//         bubbles.forEach((bubble: Bubble, bubbleIndex: number) => {
//           const bubbleOutlineItem = createOutlineItem(
//             pdfDoc,
//             `Dim ${bubble.label}`,
//             pageRef,
//             outlineItemRefs[pageIndex].groups[group].groupRef,
//             bubbleIndex < bubbles.length - 1
//               ? outlineItemRefs[pageIndex].groups[group].dimRefs[bubbleIndex + 1]
//               : undefined,
//             bubbleIndex > 0
//               ? outlineItemRefs[pageIndex].groups[group].dimRefs[bubbleIndex - 1]
//               : undefined,
//             getShapeDestPosition(bubble.location, pageHeight)
//           )
//           allOutlineItems.push(bubbleOutlineItem)
//         })
//       })
//     })
//
//     for (let i = 0; i < allOutlineItemRefs.length; i++) {
//       pdfDoc.context.assign(allOutlineItemRefs[i], allOutlineItems[i])
//     }
//
//     const outlinesDictMap = new Map()
//     outlinesDictMap.set(PDFName.Type, PDFName.of("Outlines"))
//     outlinesDictMap.set(PDFName.of("First"), outlineItemRefs[0].pageItemRef)
//     outlinesDictMap.set(PDFName.of("Last"), outlineItemRefs[outlineItemRefs.length - 1].pageItemRef)
//     outlinesDictMap.set(PDFName.of("Count"), PDFNumber.of(allOutlineItemRefs.length))
//
//     pdfDoc.catalog.set(PDFName.of("Outlines"), outlinesDictRef)
//     const outlinesDict = PDFDict.fromMapWithContext(outlinesDictMap, pdfDoc.context)
//     pdfDoc.context.assign(outlinesDictRef, outlinesDict)
//   }
//
//   const outlineData = buildOutlineData(bubbles, doc.getPages().length)
//   createDimsOutline(doc, outlineData)
// }
//
// // Page index maps to group maps to shapes
// type OutlineData = Record<DifficultyGroup, Bubble[] | undefined>[]
// const buildOutlineData = (bubbles: Bubble[], nPages: number): OutlineData => {
//   const outlineData = []
//   for (let i = 0; i < nPages; i++) {
//     const pageBubbles = bubbles.filter(bubble => bubble.location.pageIndex === i)
//     const pageBubblesByDifficulty: { [K in DifficultyGroup]: Bubble[] } = {
//       [DifficultyGroup.yellow]: [],
//       [DifficultyGroup.blue]: [],
//       [DifficultyGroup.gray]: [],
//     }
//     for (const bubble of pageBubbles) {
//       pageBubblesByDifficulty[bubble.scoring.difficultyGroup].push(bubble)
//     }
//     outlineData.push(pageBubblesByDifficulty)
//   }
//   return outlineData
// }
