import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { applyPatch } from "fast-json-patch"

import {
  AddPatchOperation,
  AppModelsGeometryTransform as Transform,
  CopyPatchOperation,
  FixtureChoice,
  FixtureStock,
  GcodeLinesIssueSelectorKindEnum,
  IssueAcknowledgement,
  MachineKind,
  MachineRecord,
  ModelStock,
  MovePatchOperation,
  Operation,
  OperationOutputStock,
  OperationOutputStockKindEnum,
  OutputStock,
  ParametricStock,
  Plan,
  Probing,
  ProbingIssue,
  ProbingStepsIssueSelector,
  ProbingStepsIssueSelectorKindEnum,
  RemovePatchOperation,
  ReplacePatchOperation,
  TestPatchOperation,
  ToolpathProject,
} from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { defaultTransform } from "src/util/geometry/transforms"
import { machinesSelectors } from "../config/machines"

interface PlanOperations {
  planId: string
  operationIds: string[]
}

export type PatchOperation =
  | AddPatchOperation
  | RemovePatchOperation
  | ReplacePatchOperation
  | MovePatchOperation
  | CopyPatchOperation
  | TestPatchOperation

export type InputStock = ParametricStock | FixtureStock | OperationOutputStock | ModelStock

interface PlanPatch {
  id: string
  operations: PatchOperation[]
}

export interface StoredPlan {
  id: string
  plan: Plan
  revision: number
  // Ensure the plan doesn't receive updates
  noUpdates: boolean
}

/**
 * Slice
 */
const adapter = createEntityAdapter<StoredPlan>()

const slice = createSlice({
  name: "storedPlans",
  initialState: adapter.getInitialState(),
  reducers: {
    addMany: adapter.addMany,
    upsert: adapter.upsertOne,
    remove: adapter.removeOne,

    applyPatch(
      state: ReturnType<typeof adapter.getInitialState>,
      action: PayloadAction<PlanPatch>
    ) {
      const planId = action.payload.id
      const storedPlan = state.entities[planId]

      // NOTE: at the moment this line is what filters out changes from websocket actions
      if (!storedPlan) return // TODO: Treat this the same way as a json patch error

      if (storedPlan.noUpdates) {
        console.log("Update received for plan marked as having no updates:", storedPlan.plan.label)
        return
      }

      // TODO: Handle errors raised by applyJsonPatch
      applyPatch(storedPlan.plan, action.payload.operations)
      storedPlan.revision = storedPlan.revision + action.payload.operations.length
    },
  },
})

/**
 * Reducer
 */
export const { reducer: plansReducer } = slice

/**
 * Actions
 */
export const { actions: plansActions } = slice

/**
 * Selectors
 */

// OperationLocator is interpreted as an index if it is numeric, and as an id if not
type OperationLocator = number | string

// Stored plans

const plansSelectors = adapter.getSelectors((state: RootState) => state.plans)

const selectStoredPlan = (state: RootState, id?: string): StoredPlan | undefined =>
  id ? plansSelectors.selectById(state, id) : undefined

// Plans
const selectPlanRevision = (state: RootState, id?: string): number | undefined =>
  selectStoredPlan(state, id)?.revision

const selectPlan = (state: RootState, id?: string): Plan | undefined =>
  selectStoredPlan(state, id)?.plan

const selectPlansAndOperationIds = (state: RootState, ids: string[]): PlanOperations[] =>
  ids.map((planId: string) => {
    return {
      planId,
      operationIds: (selectOperations(state, planId) ?? []).map(operation => operation.id),
    }
  })

const selectPlanLabel = (state: RootState, planId?: string): string | undefined => {
  return selectPlan(state, planId)?.label
}

const selectPlanNotes = (state: RootState, planId?: string): string | undefined => {
  return selectPlan(state, planId)?.notes
}

const selectInputStock: (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator,
  skipTraversing?: boolean // Skip traversing the plan back to the original stock
) => InputStock | undefined = createSelector(
  (
    state: RootState,
    planId?: string,
    operationLocator?: OperationLocator,
    skipTraversing?: boolean
  ) => {
    return {
      plan: selectPlan(state, planId),
      operationLocator,
      skipTraversing,
    }
  },

  ({ plan, operationLocator, skipTraversing }) => {
    let operationIdx: number | undefined

    if (typeof operationLocator === "number") {
      operationIdx = operationLocator
    } else {
      operationIdx = plan?.operations.findIndex(op => op.id === operationLocator)
    }

    if (operationIdx !== undefined && operationIdx >= 0 && plan !== undefined) {
      if (operationIdx > plan.operations.length) {
        return undefined
      }

      const op = plan.operations[operationIdx]

      if (op === undefined) {
        return undefined
      }

      const curStock = op.inputStock

      if (curStock !== undefined) {
        return curStock
      }

      // Skip traversing back if this option is set
      if (skipTraversing) {
        return undefined
      }

      const reverseArray = plan.operations.slice(0, operationIdx)
      reverseArray.reverse()

      const fromPrevious = reverseArray.find(op => op.outputStock !== undefined)

      if (fromPrevious !== undefined) {
        const outputStock: OperationOutputStock = {
          kind: OperationOutputStockKindEnum.OperationOutput,
          operationId: fromPrevious.id,
          transform: defaultTransform(),
        }

        return outputStock
      }

      if (plan.operations.length > 0) {
        return plan.operations[0].inputStock
      }
    }
    return undefined
  }
)

const selectOperations = (state: RootState, planId?: string): Operation[] | undefined => {
  return selectPlan(state, planId)?.operations
}

const selectOperationsCount = (state: RootState, planId?: string): number => {
  return selectPlan(state, planId)?.operations.length ?? 0
}

const selectOperation = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): Operation | undefined => {
  if (planId === undefined || operationLocator === undefined) return

  const plan = selectPlan(state, planId)

  if (typeof operationLocator === "number") {
    const operations = plan?.operations
    if (operations && operationLocator >= 0 && operationLocator < operations.length) {
      return operations[operationLocator]
    }
  } else {
    return plan?.operations.find(operation => operation.id === operationLocator)
  }
  return undefined
}

const selectOperationIdx = (
  state: RootState,
  planId?: string,
  operationLocator?: string
): number | undefined => {
  if (planId === undefined || operationLocator === undefined) return

  const plan = selectPlan(state, planId)

  const idx = plan?.operations.findIndex(operation => operation.id === operationLocator)

  if (idx !== -1) {
    return idx
  }

  return undefined
}

// Operations

const selectOperationLabel = (planId?: string, operationLocator?: OperationLocator) => (
  state: RootState
): string | undefined => {
  return selectOperation(state, planId, operationLocator)?.label
}

const selectMachineId = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): MachineKind | undefined => {
  return selectOperation(state, planId, operationLocator)?.machineId
}

const selectMachineRecord = (
  planId?: string,
  operationLocator?: OperationLocator
): ((state: RootState) => MachineRecord | undefined) =>
  createSelector(
    [
      (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationLocator),
      machinesSelectors.selectMachineRecordsMap,
    ],
    (operation, machineRecords) => {
      return operation?.machineId ? machineRecords[operation.machineId] : undefined
    }
  )

const selectToolCribId = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): string | undefined => {
  return selectOperation(state, planId, operationLocator)?.toolCribId
}

const selectWcs = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): Transform | undefined => {
  return selectOperation(state, planId, operationLocator)?.wcs
}

const selectMcs = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): Transform | undefined => {
  return selectOperation(state, planId, operationLocator)?.mcs
}

const selectOutputStock: (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
) => OutputStock | undefined = createSelector(
  (state: RootState, planId?: string, operationLocator?: OperationLocator) =>
    selectOperation(state, planId, operationLocator),

  val => val?.outputStock
)

const selectDesign: (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
) => string | undefined = createSelector(
  (state: RootState, planId?: string, operationLocator?: OperationLocator) =>
    selectOperation(state, planId, operationLocator),

  val => val?.designId
)

const selectFixtures = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): FixtureChoice | undefined => {
  return selectOperation(state, planId, operationLocator)?.fixtures
}

const selectProbing = (
  state: RootState,
  planId?: string,
  operationLocator?: OperationLocator
): Probing | undefined => {
  return selectOperation(state, planId, operationLocator)?.probing
}

const createSelectToolpathProject = (
  planId?: string,
  operationLocator?: OperationLocator
): ((state: RootState) => ToolpathProject | undefined) =>
  createSelector(
    (state: RootState) => selectOperation(state, planId, operationLocator),
    operation => operation?.toolpathProject
  )

const createSelectMachineId = (
  planId?: string,
  operationLocator?: OperationLocator
): ((state: RootState) => string | undefined) =>
  createSelector(
    (state: RootState) => selectOperation(state, planId, operationLocator),
    operation => operation?.machineId
  )

const selectToolpathProject = (planId?: string, operationLocator?: OperationLocator) => (
  state: RootState
): ToolpathProject | undefined => {
  return selectOperation(state, planId, operationLocator)?.toolpathProject
}

const createSelectIssueAcknowledgements = (
  planId?: string,
  operationLocator?: OperationLocator
) => (state: RootState) => {
  return selectOperation(state, planId, operationLocator)?.issueAcknowledgements
}

const createSelectIssueAcknowledgementsByType = (
  planId?: string,
  operationLocator?: OperationLocator
) =>
  createSelector(
    createSelectIssueAcknowledgements(planId, operationLocator),
    issueAcknowledgements => (
      type:
        | ProbingStepsIssueSelectorKindEnum.ProbingSteps
        | GcodeLinesIssueSelectorKindEnum.GcodeLines
    ) =>
      issueAcknowledgements?.filter(
        issueAcknowledgement => issueAcknowledgement.selector.kind === type
      )
  )

const createSelectIssueAcknowledgement = (
  planId?: string,
  operationLocator?: OperationLocator
): ((state: RootState) => (issue: ProbingIssue) => IssueAcknowledgement | undefined) =>
  createSelector(
    createSelectIssueAcknowledgementsByType(planId, operationLocator),
    getIssueAcknowledgementsByType => (issue: ProbingIssue) =>
      getIssueAcknowledgementsByType(ProbingStepsIssueSelectorKindEnum.ProbingSteps)
        ?.map(issueAcknowledgement => ({
          ...issueAcknowledgement,
          selector: issueAcknowledgement.selector as ProbingStepsIssueSelector,
        }))
        .find(
          ({ selector }) =>
            selector.issueTag === issue.tag &&
            (selector.probingStepId === issue.stepId || issue.stepId === "None")
        )
  )

// Exports

export const storedPlansSelectors = {
  selectStoredPlans: plansSelectors.selectAll,
  selectStoredPlan,
  selectStoredPlanIds: plansSelectors.selectIds,
  selectStoredPlanEntities: plansSelectors.selectEntities,
  selectPlan,
  selectPlanLabel,
  selectPlanNotes,
  selectOperations,
  selectOperationsCount,
  selectOperation,
  selectOperationIdx,
  selectPlansAndOperationIds,
  selectPlanRevision,
  selectToolpathProject,
}

export const storedOperationSelectors = {
  selectOperationLabel,
  selectMachineRecord,
  selectMachineId,
  selectToolCribId,
  selectWcs,
  selectMcs,
  selectOutputStock,
  selectDesign,
  selectFixtures,
  selectProbing,
  createSelectMachineId,
  createSelectToolpathProject,
  createSelectIssueAcknowledgement,
  selectInputStock,
}
