import React, { FC } from "react"
import { SpringConfig } from "@react-spring/three"
import { useThree } from "@react-three/fiber"

import { Inspector } from "../components/ClickHandlers/Inspector"
import { FLOrbitControls } from "../components/Controls/FocusOrbitControls/FLOrbitControls"
import { Scene } from "../generated/bindings/Scene"
import { AnimationContext } from "../hooks/useAnimationContext"
import { InspectorContextProvider } from "../hooks/useInspectorContext"
import { ANIMATION_CONFIG, CONTROL_UPDATE } from "../util/constants"
import { defaultLoader, UrlLoader, UrlLoaderContext } from "./OpScene/GltfUrlComponent"
import { OpScene } from "./OpScene/OpScene"
import { defaultView, ViewCallback } from "./OpScene/OpSceneViewState"
import { NodeObj3dCallback } from "./OpScene/types"
import AppStage from "./AppStage"

export interface MainSceneProps {
  // The scene object itself which is updated
  scene: Scene

  hideInspector?: boolean

  // Whether to enable animations or not, default uses default anim config
  animationConfig?: SpringConfig | false
  // An optional resolver to use when resolving urls, must convert a standard plm key into the appropriate url
  urlLoader?: UrlLoader

  // A view state based on the node
  view?: ViewCallback

  // A callback with the node and ThreeJS object
  nodeObj?: NodeObj3dCallback
}

export const MainScene: FC<MainSceneProps> = ({
  scene,
  urlLoader,
  hideInspector,
  animationConfig,
  view,
  nodeObj,
  children,
}) => {
  const { gl, invalidate } = useThree()

  return (
    <>
      <AppStage>
        <AnimationContext.Provider
          value={{
            onChange: invalidate,
            config: animationConfig === false ? undefined : animationConfig ?? ANIMATION_CONFIG,
          }}
        >
          <InspectorContextProvider>
            <UrlLoaderContext.Provider value={urlLoader ?? defaultLoader}>
              <OpScene scene={scene} view={view ?? defaultView} nodeObj={nodeObj} />
              {hideInspector !== true && <Inspector showSelectionPoint />}
              {children}
            </UrlLoaderContext.Provider>
            <FLOrbitControls
              onChange={e => {
                const event = new CustomEvent(CONTROL_UPDATE, { detail: e })
                gl.domElement.dispatchEvent(event)
              }}
              makeDefault
              enableDamping={false}
              lockMouseOnOrthoZoom
            />
          </InspectorContextProvider>
        </AnimationContext.Provider>
      </AppStage>
    </>
  )
}
