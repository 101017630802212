import * as THREE from "three"

// import { SelectedPoint } from "src/store/ui/viewOptions"
// import { CylinderData, CylinderOrientation } from "src/util/geometry/cylinder"
import { CylinderData, CylinderOrientation, SelectedPoint } from "../../hooks/useInspectorContext"

const vA = new THREE.Vector3()
const vB = new THREE.Vector3()
const vC = new THREE.Vector3()

export const getSelectedPoint = (
  // canvasId: string,
  intersection: THREE.Intersection,
  clamp: boolean
): SelectedPoint | undefined => {
  let faceNormal: THREE.Vector3 | undefined

  if (intersection.face) {
    faceNormal = intersection.face.normal
      .clone()
      .applyMatrix4(new THREE.Matrix4().extractRotation(intersection.object.matrixWorld))
  }

  const isPart = intersection.object.userData.isPart === true ?? false
  const isStock = intersection.object.userData.isStock === true ?? false

  const object = intersection.object as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
  const position = object.geometry.attributes.position

  if (intersection.object instanceof THREE.Line) {
    return {
      point: [intersection.point.x, intersection.point.y, intersection.point.z],
      intersection,
      faceNormal: undefined,
      isPart: false,
      isStock: false,
      cylinder: undefined,
    }
  }

  // Three face vertices of the mesh

  const face = intersection.face
  if (!face || !(intersection.object instanceof THREE.Mesh)) return
  vA.fromBufferAttribute(position, face.a)
  vB.fromBufferAttribute(position, face.b)
  vC.fromBufferAttribute(position, face.c)

  intersection.object.localToWorld(vA)
  intersection.object.localToWorld(vB)
  intersection.object.localToWorld(vC)

  const vADist = vA.distanceTo(intersection.point)
  const vBDist = vB.distanceTo(intersection.point)
  const vCDist = vC.distanceTo(intersection.point)

  let dist: number
  let clickedPoint: THREE.Vector3
  if (vADist < vBDist && vADist < vCDist) {
    clickedPoint = vA
    dist = vADist
  } else if (vBDist < vCDist) {
    clickedPoint = vB
    dist = vBDist
  } else {
    clickedPoint = vC
    dist = vCDist
  }

  // Don't clamp to edges if the dist is more than 3mm
  if (dist > 2 || !clamp) {
    clickedPoint = intersection.point
  }

  const cylinder: CylinderData | undefined = intersection.object.userData.cylinder // eslint-disable-line

  if (cylinder && clamp) {
    // If this is a bore
    if (cylinder.orientation === CylinderOrientation.Reversed) {
      const axisLocation = new THREE.Vector3(...cylinder.location)
      const axisDirection = new THREE.Vector3(...cylinder.direction)
      // The axis point in the center of the cylinder
      axisDirection.multiplyScalar(cylinder.height / 2)

      // We get the top of the hole
      const topLocation = axisLocation.clone().add(axisDirection)
      // Or the bottom
      const bottomLocation = axisLocation.clone().sub(axisDirection)

      bottomLocation.applyMatrix4(intersection.object.matrixWorld)
      topLocation.applyMatrix4(intersection.object.matrixWorld)

      const botDist = bottomLocation.distanceTo(intersection.point)
      const topDist = topLocation.distanceTo(intersection.point)

      // We either choose the top or the bottom
      if (topDist < botDist) {
        clickedPoint = topLocation
      } else {
        clickedPoint = bottomLocation
      }
    }
  }

  return {
    point: [clickedPoint.x, clickedPoint.y, clickedPoint.z] as [number, number, number],
    // canvasId,
    intersection,
    faceNormal,
    isPart,
    isStock,
    cylinder,
  }
}
