import { MutableRefObject, useEffect, useRef } from "react"

export function useEventListener(
  eventName: string,
  handler: (arg0: Event) => void,
  element:
    | HTMLElement
    | Window
    | null
    | {
        addEventListener(type: string, listener: (event: Event) => void): void
        removeEventListener(type: string, listener: (event: Event) => void): void
      } = window
): void {
  const savedHandler: MutableRefObject<((arg0: Event) => void) | undefined> = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (element === null) return
    const isSupported = element?.addEventListener
    if (!isSupported) return
    const eventListener: (evt: Event) => void = (evt: Event) => savedHandler.current?.(evt)
    element.addEventListener(eventName, eventListener)

    return function (): void {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
