import React, { forwardRef, ForwardRefRenderFunction } from "react"
import { useThree } from "@react-three/fiber"

import { FLDreiOrbitControls } from "./FLDreiOrbitControls"
import { FLStdOrbitControls } from "./FLStdOrbitControls"

const _OrbitControls: ForwardRefRenderFunction<
  FLStdOrbitControls,
  {
    camera?: THREE.OrthographicCamera
    elem?: HTMLElement
    enabled?: boolean
    enableDamping?: boolean
    dampingFactor?: number
    lockMouseOnOrthoZoom?: boolean
  }
> = (
  props: {
    camera?: THREE.OrthographicCamera
    elem?: HTMLElement
    enabled?: boolean
    enableDamping?: boolean
    dampingFactor?: number
    lockMouseOnOrthoZoom?: boolean
  },
  ref
) => {
  const {
    elem: propsElem,
    camera: propsCamera,
    enabled,
    enableDamping,
    dampingFactor,
    lockMouseOnOrthoZoom,
  } = props
  const { gl, camera: defaultCamera } = useThree()
  const defaultElem = gl.domElement

  const elem = propsElem ?? defaultElem
  const camera = propsCamera ?? defaultCamera

  return (
    <FLDreiOrbitControls
      ref={ref}
      camera={camera}
      domElement={elem}
      enabled={enabled}
      enableDamping={enableDamping}
      dampingFactor={dampingFactor}
      lockMouseOnOrthoZoom={lockMouseOnOrthoZoom}
    />
  )
}

export const FLOrbitControls = forwardRef(_OrbitControls)
