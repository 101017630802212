import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Divider, NonIdealState, Position, Spinner } from "@blueprintjs/core"
import { AnchorButton, Button } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { Popover2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"

import { JiraClientData } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { viewOptionsActions } from "src/store/ui/viewOptions"

import styles from "./IssuesPanel.module.css"

export const IssuesPanel: FC<{
  partName: string
  isLoading: boolean
  searchForEpic: (
    partName?: string,
    planId?: string | undefined,
    operationIdx?: number | undefined,
    currentUserData?:
      | {
          email: string
          permissions: string[]
        }
      | undefined,
    jiraAccessToken?: string,
    cloudId?: string
  ) => Promise<void>
  createEpic: (
    partName?: string | undefined,
    jiraAccessToken?: string | undefined,
    cloudId?: string | undefined
  ) => Promise<void>
  handleJiraCatch: (error: AxiosError, cb: () => Promise<void>) => void
  issues: jira.IIssue[]
  currentUserData?:
    | {
        email: string
        permissions: string[]
      }
    | undefined
  jiraAccessToken?: string
  cloudId?: string
  epicExists: boolean
}> = ({
  partName,
  searchForEpic,
  createEpic,
  handleJiraCatch,
  issues,
  currentUserData,
  jiraAccessToken,
  cloudId,
  isLoading,
  epicExists,
}) => {
  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (jiraAccessToken && cloudId) {
      searchForEpic(
        partName,
        planId,
        operationIdx,
        currentUserData,
        jiraAccessToken,
        cloudId
      ).catch(error =>
        handleJiraCatch(error, () => {
          return searchForEpic(
            partName,
            planId,
            operationIdx,
            currentUserData,
            jiraAccessToken,
            cloudId
          )
        })
      )
    }
  }, [
    partName,
    planId,
    operationIdx,
    currentUserData,
    jiraAccessToken,
    cloudId,
    searchForEpic,
    handleJiraCatch,
  ])

  const activeIssue = useSelector(activeSelectors.selectActiveIssue)

  const handleCreateEpic = () => {
    createEpic(partName, jiraAccessToken, cloudId).catch(error =>
      handleJiraCatch(error, () => {
        return createEpic(partName, jiraAccessToken, cloudId)
      })
    )
  }

  return (
    <div className={styles.panelContainer}>
      {jiraAccessToken && cloudId ? (
        <>
          <div className={styles.header}>
            <div>Issue Tracker</div>
            {!isLoading && epicExists && (
              <Button
                icon="small-plus"
                intent="success"
                onClick={() => {
                  dispatch(activeActions.setActiveIssue(undefined))
                  dispatch(viewOptionsActions.showAddIssuesDialog())
                  dispatch(viewOptionsActions.setSelectedPoint1(undefined))
                  dispatch(viewOptionsActions.setSelectedPoint2(undefined))
                }}
              >
                Create Issue
              </Button>
            )}
          </div>

          <Divider />

          <div className={styles.issues}>
            {isLoading && (
              <div className={styles.spinner}>
                <Spinner size={16} />
              </div>
            )}

            {!isLoading && (
              <>
                {!epicExists && <Button onClick={handleCreateEpic}>Create Epic</Button>}

                {epicExists &&
                  issues?.map((issue, i) => {
                    return (
                      <div key={i}>
                        <Popover2
                          popoverClassName={styles.popover}
                          position={Position.TOP}
                          usePortal={false}
                          isOpen={activeIssue?.id === issue.id}
                          content={
                            <div className={styles.popoverContainer}>
                              <div className={styles.input}>
                                Author:{" "}
                                {issue.fields.reporter.emailAddress ||
                                  issue.fields.reporter.displayName}
                              </div>
                              <div className={styles.input}>
                                Severity:{" "}
                                {issue.fields?.customfield_10039?.value ?? "<Not Specified>"}
                              </div>
                              <div className={styles.input}>
                                Link:{" "}
                                <a
                                  href={`https://mattfuerst.atlassian.net/browse/${issue.key}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {issue.key}
                                </a>
                              </div>

                              <div className={styles.description}>
                                {issue.fields.description.content.map(content1 => {
                                  return content1.content.map((content2, i) => {
                                    return <div key={i}>{content2.text}</div>
                                  })
                                })}
                              </div>

                              <div className={styles.threadItems} />
                            </div>
                          }
                          onOpened={() => {
                            dispatch(viewOptionsActions.setSelectedPoint1(undefined))
                            dispatch(viewOptionsActions.setSelectedPoint2(undefined))
                            dispatch(activeActions.setActiveIssue(issue))
                          }}
                        >
                          <div
                            className={styles.issue}
                            onClick={() => {
                              if (activeIssue?.id === issue.id) {
                                dispatch(activeActions.setActiveIssue(undefined))
                              } else {
                                dispatch(activeActions.setActiveIssue(issue))
                              }
                            }}
                          >
                            {issue.fields.status.id === "10002" ? (
                              <div className={styles.resolved}>Resolved</div>
                            ) : (
                              <div className={styles.unresolved}>Unresolved</div>
                            )}{" "}
                            {issue.fields.summary}
                          </div>
                        </Popover2>

                        <Divider />
                      </div>
                    )
                  })}
              </>
            )}
          </div>
        </>
      ) : (
        <ConnectToJiraPrompt />
      )}
    </div>
  )
}

const ConnectToJiraPrompt: FC = () => {
  const { jiraApi } = useApi()
  const [clientData, setClientData] = useState<JiraClientData>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  useEffect(() => {
    jiraApi
      .getJiraClientData(window.location.host)
      .then(({ data }) => {
        setClientData(data)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setError(true)
        setLoading(false)
      })
  }, [jiraApi])

  return (
    <>
      <div className={styles.header}>
        <div>Issue Tracker</div>
      </div>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner size={16} />
        </div>
      ) : (
        <>
          {error && (
            <NonIdealState
              icon={"error"}
              description={"Failed to retrieve JIRA config - please contact support."}
            />
          )}
          {clientData && (
            <NonIdealState
              description={
                <AnchorButton
                  href={getConnectToJiraUrl(clientData)}
                  target={"_blank"}
                  text={`Connect to Jira`}
                />
              }
            />
          )}
        </>
      )}
    </>
  )
}

export const getConnectToJiraUrl = (clientData: JiraClientData): string => {
  const clientId = encodeURIComponent(clientData.clientId)
  const redirectUri = encodeURIComponent(clientData.redirectUri)
  return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&redirect_uri=${redirectUri}&response_type=code&prompt=consent`
}
