import React, { FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Button, Classes, Dialog, InputGroup, Spinner } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { JobUpload, OperationUpload, SimulationUpload } from "src/client-axios"
import { TransformInput } from "src/components/Generic/Forms/TransformInput/TransformInput"
import { Transform } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast } from "src/hooks/useToaster"

import styles from "./UploadModelDialog.module.css"

export const UploadModelDialog: FC<{
  isOpen: boolean
  handleRequestClose: () => void
  onModelCreation: (modelId: string, modelName: string, transform?: Transform) => void
  title: string
  requireName?: boolean
  namePlaceholder?: string
  withTransform?: boolean
}> = ({
  isOpen,
  handleRequestClose,
  title,
  onModelCreation,
  requireName = true,
  namePlaceholder,
  withTransform = false,
}) => {
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [isNameError, setIsNameError] = useState(false)
  const [trsf, setTrsf] = useState<Transform>({ x: 0, y: 0, z: 0, i: 0, j: 0, k: 0 })

  const errorToast = useNetworkErrorToast()

  const { filesApi } = useApi()

  const uploadFile = (file: File) => {
    setIsFileLoading(true)
    filesApi
      .newModel(file)
      .then(results => {
        const customModelId = results.data.id
        onModelCreation(customModelId, modelName, withTransform ? trsf : undefined)
        setIsFileLoading(false)
      })

      .catch(error => errorToast(error, "Upload Model Error"))
  }

  const onDrop = (acceptedFiles: File[]) => {
    if (requireName && modelName === "") {
      setIsNameError(true)
      return
    }
    setIsFileLoading(true)
    acceptedFiles.forEach((file: File) => {
      uploadFile(file)
      setIsFileLoading(false)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 })
  const [modelName, setModelName] = useState<string>(namePlaceholder || "Fixture")

  return (
    <Dialog
      icon={"info-sign"}
      onClose={handleRequestClose}
      title={title}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        {requireName && (
          <Tooltip2
            content={"Enter name before selecting file"}
            openOnTargetFocus={false}
            position="top"
            isOpen={isNameError}
            className={styles.tooltip}
          >
            <InputGroup
              intent={isNameError ? "danger" : "none"}
              onChange={e => {
                const name = e.target.value
                setIsNameError(name === "")
                setModelName(name)
              }}
              value={modelName}
              placeholder={"Name"}
              autoFocus
            />
          </Tooltip2>
        )}
        <div
          {...getRootProps()}
          className={`${styles.dropContainer} ${
            requireName && modelName === "" ? styles.disabledDropContainer : ""
          }`}
          onPointerEnter={() => {
            if (requireName && modelName === "") {
              setIsNameError(true)
            }
          }}
          onPointerLeave={() => {
            setIsNameError(false)
          }}
        >
          <input {...getInputProps()} disabled={requireName && modelName === ""} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag and drop (.step) a design here or click to select one</p>
          )}
        </div>

        {withTransform && (
          <TransformInput
            transform={trsf}
            onChange={trsf => {
              setTrsf(trsf)
            }}
          />
        )}

        {isFileLoading && (
          <div>
            <Spinner />
          </div>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose} intent="danger">
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export const UploadFileDialog: FC<{
  isOpen: boolean
  uploadFileType: JobUpload | OperationUpload | SimulationUpload
  handleRequestClose: () => void
  onFileCreation: (fileId: string) => void
  title: string
}> = ({ isOpen, uploadFileType, handleRequestClose, title, onFileCreation }) => {
  const [isFileLoading, setIsFileLoading] = useState(false)

  const { planchangerApi } = useApi()

  const uploadFile = (file: File) => {
    setIsFileLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    planchangerApi.newFile(JSON.stringify(uploadFileType) as any, file).then(results => {
      const modelSourceFileId = results.data.source.id
      onFileCreation(modelSourceFileId)
      setIsFileLoading(false)
    })
  }

  const onDrop = (acceptedFiles: File[]) => {
    setIsFileLoading(true)
    acceptedFiles.forEach((file: File) => {
      uploadFile(file)
      setIsFileLoading(false)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 })

  return (
    <Dialog
      icon={"info-sign"}
      onClose={handleRequestClose}
      title={title}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <div {...getRootProps()} className={styles.dropContainer}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag and drop (.step) a design here or click to select one</p>
          )}
        </div>

        {isFileLoading && (
          <div>
            <Spinner />
          </div>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose} intent="danger">
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
