import React, { FC, lazy, Suspense } from "react"
import ReactModal from "react-modal"
import { useSelector } from "react-redux"
import { Spinner } from "@blueprintjs/core"

import { activeActions, activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode, viewerModalSelectors } from "src/store/ui/viewerModal"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { MachineChangeBanner } from "./MachineChangeBanner/MachineChangeBanner"
import { OffsetsWarningBanner } from "./OffsetsWarningBanner/OffsetsWarningBanner"
import { ProbingWarningBanner } from "./ProbingWarningBanner/ProbingWarningBanner"
import { StockSimWarningBanner } from "./StockSimWarningBanner/StockSimWarningBanner"

import styles from "./ViewerModal.module.css"

const ThumbnailViewerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ThumbnailViewerPage" */
      "src/pages/ThumbnailViewerPage/ThumbnailViewerPage"
    )
)

const ViewerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ViewerPage" */
      /* webpackPrefetch: true */
      "src/pages/ViewerPage/ViewerPage"
    )
)

export const ViewerModal: FC<{ locked?: boolean }> = ({ locked }) => {
  const dispatch = useAppDispatch()
  const viewerModalIsOpen = useSelector(viewerModalSelectors.selectIsOpen)
  const filterPlans = useSelector(viewerModalSelectors.selectFilterPlans)
  const modelId = useSelector(viewerModalSelectors.selectModelId)
  const jobId = useSelector(viewerModalSelectors.selectJobId)
  const mode = useSelector(viewerModalSelectors.selectMode)
  const assignees = useSelector(viewerModalSelectors.selectAssignees)

  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const operationId = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx)?.id
  )
  const opCardLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.operationLocked(state, operationId)
  )

  const handleRequestClose = () => {
    dispatch(viewerModalActions.setViewerModalIsOpen(false))
    dispatch(activeActions.setActiveConfigPanel(undefined))
    dispatch(viewerModalActions.setViewerModalMode(undefined))
  }

  const editingLocked = locked || opCardLocked

  ReactModal.setAppElement("body")
  return (
    <ReactModal
      shouldCloseOnEsc={false}
      isOpen={viewerModalIsOpen}
      closeTimeoutMS={500}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      onRequestClose={handleRequestClose}
      contentLabel="Example Modal"
    >
      <ProbingWarningBanner
        handleRequestClose={handleRequestClose}
        locked={editingLocked}
        operationId={operationId}
        assignees={assignees}
      />
      <MachineChangeBanner />
      <OffsetsWarningBanner />
      <StockSimWarningBanner locked={editingLocked} />

      <div className={styles.bodyContainer}>
        <Suspense fallback={<Spinner className={styles.spinner} />}>
          {mode === ViewerModalMode.Setup && operationIdx !== undefined ? (
            <ViewerPage {...{ jobId, filterPlans }} inModal locked={editingLocked} />
          ) : (
            <ThumbnailViewerPage modelId={modelId} />
          )}
        </Suspense>
      </div>
    </ReactModal>
  )
}
