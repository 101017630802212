/* eslint-disable */

/**
 * Based on OrbitControls from "@react-three/drei";
 *
 * I used to need to tell the camera to update its matrix immediately after the controls are updated
 * during the frame loop to prevent camera jitters, but it seems this is no longer necessary
 */

import * as React from "react"
import { EventManager, ReactThreeFiber, useFrame, useThree } from "@react-three/fiber"
import { Camera, Event } from "three"
import { OrbitControls as OrbitControlsImpl } from "three-stdlib/controls/OrbitControls"

// import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { FLStdOrbitControls } from "./FLStdOrbitControls"

export type OrbitControlsProps = Omit<
  ReactThreeFiber.Overwrite<
    ReactThreeFiber.Object3DNode<OrbitControlsImpl, typeof OrbitControlsImpl>,
    {
      camera?: Camera
      domElement?: HTMLElement
      enableDamping?: boolean
      makeDefault?: boolean
      onChange?: (e?: Event) => void
      onEnd?: (e?: Event) => void
      onStart?: (e?: Event) => void
      regress?: boolean
      target?: ReactThreeFiber.Vector3
      lockMouseOnOrthoZoom?: boolean
    }
  >,
  "ref"
>

export const FLDreiOrbitControls = React.forwardRef<FLStdOrbitControls, OrbitControlsProps>(
  function _FLDreiOrbitControls(
    {
      makeDefault,
      camera,
      regress,
      domElement,
      enableDamping = true,
      lockMouseOnOrthoZoom = false,
      onChange,
      onStart,
      onEnd,
      ...restProps
    },
    ref
  ) {
    const invalidate = useThree(state => state.invalidate)
    const defaultCamera = useThree(state => state.camera)
    const gl = useThree(state => state.gl)
    const events = useThree(state => state.events) as EventManager<HTMLElement>
    const set = useThree(state => state.set)
    const get = useThree(state => state.get)
    const performance = useThree(state => state.performance)
    const explCamera = camera || defaultCamera
    const explDomElement = (domElement || events.connected || gl.domElement) as HTMLElement
    const controls = React.useMemo(() => new FLStdOrbitControls(explCamera), [explCamera])

    useFrame(() => {
      if (controls.enabled) controls.update()
    }, -1)

    React.useEffect(() => {
      controls.connect(explDomElement)
      return () => controls.dispose()
    }, [explDomElement, regress, controls, invalidate])

    React.useEffect(() => {
      const callback = (e: Event) => {
        invalidate()
        if (regress) performance.regress()
        if (onChange) onChange(e)
      }
      controls.addEventListener("change", callback)
      if (onStart) controls.addEventListener("start", onStart)
      if (onEnd) controls.addEventListener("end", onEnd)

      return () => {
        if (onStart) controls.removeEventListener("start", onStart)
        if (onEnd) controls.removeEventListener("end", onEnd)
        controls.removeEventListener("change", callback)
      }
    }, [onChange, onStart, onEnd, controls, invalidate])

    React.useEffect(() => {
      if (makeDefault) {
        const old = get().controls
        set({ controls })
        return () => set({ controls: old })
      }
      return () => {}
    }, [makeDefault, controls])

    return (
      <primitive
        ref={ref}
        object={controls}
        enableDamping={enableDamping}
        lockMouseOnOrthoZoom={lockMouseOnOrthoZoom}
        {...restProps}
      />
    )
  }
)
