import { FC, useEffect, useState } from "react"
import { batch, useSelector } from "react-redux"
import { Dictionary } from "@reduxjs/toolkit"

import { ConfigPanelSelect } from "src/components/Cam/TreeNavigator/ConfigPanel/props"
import { Camjobstatus } from "src/graphql/generated"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { shortLabel, shortPlanLabel } from "src/pages/JobOverview/JobOverview"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { StoredPlan, storedPlansSelectors } from "src/store/cam/storedPlans"
import { useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode, viewerModalSelectors } from "src/store/ui/viewerModal"

// I have purposely used a component rather than a custom hook here to make it easier
// to place it at a specific spot in the hierarchy of providers, etc.
export const QueryParamsListener: FC = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  useWriteQueryParams({ isLoaded })
  useReadQueryParams({ isLoaded, setIsLoaded })
  return null
}

const useReadQueryParams = ({
  isLoaded,
  setIsLoaded,
}: {
  isLoaded: boolean
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()
  const storedPlanEntities = useSelector(storedPlansSelectors.selectStoredPlanEntities)

  useEffect(() => {
    if (isLoaded) return

    const params = new URLSearchParams(window.location.search)

    const paramPlan = params.get("plan")
    const paramOp = params.get("op")
    const paramActiveConfigPanel = params.get("activeConfigPanel")
    const paramActiveViewerModalMode = params.get("view") as ViewerModalMode
    const paramViewerModalIsOpen = params.get("modal") === "true"
    const paramActiveStageName = params.get("stageName")
    const paramActiveFeedbackId = params.get("feedbackId")
    const paramActiveCamjobstatus = params.get("status")

    // Set active plan
    let planId: string | undefined
    if (paramPlan) {
      planId = getPlansWithParam(storedPlanEntities)?.find(plan => {
        return plan?.planParam === paramPlan
      })?.planId

      // An active plan was specified but not found; most likely, all plans haven't been loaded yet
      if (!planId) return
    }

    batch(() => {
      dispatch(activeActions.setActivePlanId(planId))

      // Set active operation index
      const paramOperationIdxZeroBased = paramOp ? Number(paramOp) - 1 : undefined
      dispatch(activeActions.setActiveOperationIdx(paramOperationIdxZeroBased))

      // Set active config panel

      const isValidParamActiveConfigPanel =
        paramActiveConfigPanel &&
        [
          "probing",
          "wcs",
          "fixture",
          "restStockLocation",
          "machine",
          "ncPrograms",
          "toolcrib",
          "design",
        ].includes(paramActiveConfigPanel)
      if (isValidParamActiveConfigPanel && planId && paramOperationIdxZeroBased !== undefined) {
        const configPanel: ConfigPanelSelect = {
          planId,
          operationIdx: paramOperationIdxZeroBased,
          kind: paramActiveConfigPanel,
          ...(paramActiveConfigPanel === "restStockLocation" && { type: "MCS" }),
          ...(paramActiveConfigPanel === "coordinates" && { type: "WCS" }),
        } as ConfigPanelSelect

        dispatch(activeActions.setActiveConfigPanel(configPanel))
      }

      dispatch(
        viewerModalActions.setViewerModalIsOpen(
          !!(paramActiveViewerModalMode || paramViewerModalIsOpen)
        )
      )
      dispatch(viewerModalActions.setViewerModalMode(paramActiveViewerModalMode))
      dispatch(activeActions.setActiveStageName(paramActiveStageName ?? undefined))
      dispatch(activeActions.setActiveFeedbackId(paramActiveFeedbackId ?? undefined))
      dispatch(
        activeActions.setActiveCamjobstatus((paramActiveCamjobstatus as Camjobstatus) ?? undefined)
      )
    })
    setIsLoaded(true)
  }, [dispatch, isLoaded, setIsLoaded, storedPlanEntities])
}

const useWriteQueryParams = ({ isLoaded }: { isLoaded: boolean }) => {
  const dispatch = useAppDispatch()
  const {
    settings: { camjobStatus },
  } = useLocalStorageSettings()

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const activeOperationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const activeConfigPanel = useSelector(activeSelectors.selectActiveConfigPanel)
  const activeStageName = useSelector(activeSelectors.selectActiveStageName)
  const activeFeedbackId = useSelector(activeSelectors.selectActiveFeedbackId)
  const activeCamjobstatus = useSelector(activeSelectors.selectActiveCamjobstatus)
  const activeViewerModalMode = useSelector(viewerModalSelectors.selectMode)

  const storedPlanEntities = useSelector(storedPlansSelectors.selectStoredPlanEntities)
  const isCamJobsRoute = window.location.toString().includes("/cam-jobs")

  // Set query param for viewerModalMode
  useEffect(() => {
    if (!isLoaded) return

    if (isCamJobsRoute) {
      updateQueryParams({ view: undefined })
      dispatch(viewerModalActions.setViewerModalMode(undefined))
    }

    updateQueryParams({ view: activeViewerModalMode })
  }, [isLoaded, activeViewerModalMode, dispatch, isCamJobsRoute])

  // Set query param for activeStageName -- for guardrails display
  useEffect(() => {
    if (!isLoaded) return
    updateQueryParams({ stageName: activeStageName })
  }, [isLoaded, activeStageName, dispatch])

  // Set query param for activeFeedbackId -- for guardrails display
  useEffect(() => {
    if (!isLoaded) return
    updateQueryParams({ feedbackId: activeFeedbackId })
  }, [isLoaded, activeFeedbackId, dispatch])

  // Set query param for activeCamjobstatus
  useEffect(() => {
    if (!isLoaded) return

    if (isCamJobsRoute) {
      updateQueryParams({
        status: activeCamjobstatus?.toLowerCase() || camjobStatus.toLocaleLowerCase(),
      })
    }

    dispatch(
      activeActions.setActiveCamjobstatus(
        (activeCamjobstatus?.toLowerCase() || camjobStatus.toLocaleLowerCase()) as Camjobstatus
      )
    )
  }, [isLoaded, activeCamjobstatus, dispatch, camjobStatus, isCamJobsRoute])

  // Set query param for activeOperationIdx
  useEffect(() => {
    if (!isLoaded) return
    const operationIdxParam = activeOperationIdx != null ? activeOperationIdx + 1 : undefined
    updateQueryParams({ op: operationIdxParam })
  }, [isLoaded, activeOperationIdx, dispatch])

  // Set query param for activePlanId
  useEffect(() => {
    if (!isLoaded) return
    const plans = getPlansWithParam(storedPlanEntities)
    updateQueryParams({
      plan: plans.find(plan => {
        return plan && plan.planId === activePlanId
      })?.planParam,
    })
  }, [isLoaded, storedPlanEntities, activePlanId, dispatch])

  // Set query param for activeConfigPanel
  useEffect(() => {
    if (!isLoaded) return
    updateQueryParams({ activeConfigPanel: activeConfigPanel?.kind })
  }, [isLoaded, activeConfigPanel, dispatch])
}

const updateQueryParams = (updates: Record<string, string | number | undefined>) => {
  const params = new URLSearchParams(window.location.search)
  const oldParamsString = params.toString()

  for (const k in updates) {
    const v = updates[k]
    if (v === undefined) {
      params.delete(k)
    } else {
      params.set(k, `${v}`)
    }
  }

  const newParamsString = params.toString()

  if (oldParamsString === newParamsString) return

  const url = newParamsString.length > 0 ? `?${newParamsString}` : ``
  window.history.replaceState({}, window.document.title, url)
}

const getPlansWithParam = (
  storedPlanEntities: Dictionary<StoredPlan>
): { planId: string; planParam: string | undefined }[] => {
  const result: { planId: string; planParam: string | undefined }[] = []
  const existingParams = new Set()

  Object.entries(storedPlanEntities)?.forEach(([key, val]) => {
    if (val && val.plan.label.split("/").length === 6) {
      let planParam = val && shortLabel(val.plan.label).replace("/", "-")
      const originalPlanParam = planParam
      let repetition = 1
      while (existingParams.has(planParam)) {
        repetition += 1
        planParam = `${originalPlanParam}-${repetition}`
      }
      if (planParam !== undefined) existingParams.add(planParam)
      result.push({ planId: key, planParam })
    } else if (val && val.plan.label.split("/").length === 5) {
      let planParam = val && shortPlanLabel(val.plan.label)
      if (planParam !== undefined) {
        const originalPlanParam = planParam
        let repetition = 1
        while (existingParams.has(planParam)) {
          repetition += 1
          planParam = `${originalPlanParam}-${repetition}`
        }
      }
      if (planParam !== undefined) existingParams.add(planParam)
      result.push({ planId: key, planParam })
    } else {
      let planParam = val?.plan.label
      if (planParam !== undefined) {
        const originalPlanParam = planParam
        let repetition = 1
        while (existingParams.has(planParam)) {
          repetition += 1
          planParam = `${originalPlanParam}-${repetition}`
        }
      }
      if (planParam !== undefined) existingParams.add(planParam)
      result.push({ planId: key, planParam })
    }
  })

  return result
}
