import { useEffect, useMemo, useRef, useState } from "react"
import { Intent } from "@blueprintjs/core"
import * as THREE from "three"
import create, { GetState, SetState } from "zustand"
import { StoreApiWithSubscribeWithSelector, subscribeWithSelector } from "zustand/middleware"

import { MachineBodyNode, MachineKinematics, MachineRecord, Operation } from "src/client-axios"
import { MachineKind } from "src/client-axios/api"
import { evaluateComposedTransforms, evaluateMatrix } from "src/util/geometry/evaluateMatrix"
import { transformToMatrix } from "src/util/geometry/transforms"

type MachineRecordNodeTree = {
  nodeRecord: Record<string, MachineBodyNode>
  nodeParents: Record<string, string>
}

export type MachineCoords = {
  x: number
  y: number
  z: number
  extraCoords: Record<string, number>
}

type RtcpCoords = {
  x: number
  y: number
  z: number
  extraCoords: Record<string, number>
}

type RtcpState = {
  toolTransforms: Array<string>
  attachTransforms: Array<string>
  wcs: THREE.Matrix4
  mcs: THREE.Matrix4
  partToTable: THREE.Matrix4
}

export type ControllerState = {
  currentTime: number
  currentCoords: MachineCoords
  targetCoordsBuffer: MachineCoords[]
  wcs: MachineCoords
  feedrate: number
  rtcpEnabled: boolean
  toolLength: number
  referencePositionCoords: MachineCoords
  rtcpState: RtcpState
}

const DEFAULT_COORDS = {
  x: 0,
  y: 0,
  z: 0,
  extraCoords: {
    a: 0,
    b: 0,
    c: 0,
  },
}

export function defaultCoords(): MachineCoords {
  return Object.assign({}, DEFAULT_COORDS)
}

export type ActiveMachineState = {
  machineKind: MachineKind
  controllerState: ControllerState
}

function coordsToRecord(coords: MachineCoords | RtcpCoords): Record<string, number> {
  const record = Object.assign({}, coords.extraCoords)
  record.x = coords.x
  record.y = coords.y
  record.z = coords.z
  return record
}

function machineNodeRecord(
  machineBodyNodes: Array<MachineBodyNode>
): Record<string, MachineBodyNode> {
  const record: Record<string, MachineBodyNode> = {}

  function visitNodes(nodes: Array<MachineBodyNode>): void {
    nodes.forEach(node => {
      record[node.name] = node
      if (node.children) {
        visitNodes(node.children)
      }
    })
  }

  visitNodes(machineBodyNodes)

  return record
}

function machineNodeParents(nodes: Record<string, MachineBodyNode>): Record<string, string> {
  const parents: Record<string, string> = {}
  Object.values(nodes).forEach(node => {
    if (node.children) {
      node.children.forEach(subNode => {
        parents[subNode.name] = node.name
      })
    }
  })
  return parents
}

function createMachineRecordNodeTree(machine: MachineRecord): MachineRecordNodeTree {
  const nodeRecord = machineNodeRecord(machine.nodes)
  return {
    nodeRecord,
    nodeParents: machineNodeParents(nodeRecord),
  }
}

function machineNodeTransforms(nodeTree: MachineRecordNodeTree, name: string): Array<string> {
  let node = nodeTree.nodeRecord[name]
  const transforms = [node.transformFormula]
  /* eslint-disable no-prototype-builtins */
  while (nodeTree.nodeParents.hasOwnProperty(node.name)) {
    node = nodeTree.nodeRecord[nodeTree.nodeParents[node.name]]
    transforms.push(node.transformFormula)
  }
  return transforms.reverse()
}

function evalTransforms(transforms: Array<string>, machineCoord: MachineCoords): THREE.Matrix4 {
  const variables = coordsToRecord(machineCoord)
  return evaluateComposedTransforms(transforms, variables)
}

export function rtcpCoordToMachineCoord(
  state: ControllerState,
  coords: RtcpCoords,
  ignoreToolLength?: boolean
): MachineCoords {
  const neutralCoords = Object.assign({}, coords)
  neutralCoords.x = 0.0
  neutralCoords.y = 0.0
  neutralCoords.z = 0.0

  const rtcpState = state.rtcpState
  const toolHomog = evalTransforms(rtcpState.toolTransforms, neutralCoords)
  const attachHomog = evalTransforms(rtcpState.attachTransforms, neutralCoords)
  const homog = toolHomog.invert()
  homog.multiply(attachHomog)
  homog.multiply(rtcpState.partToTable)

  const point = new THREE.Vector3(coords.x, coords.y, coords.z)
  point.applyMatrix4(homog)
  if (!ignoreToolLength) {
    point.z += state.toolLength
  }

  const machineCoords = Object.assign({}, coords)
  machineCoords.x = point.x
  machineCoords.y = point.y
  machineCoords.z = point.z
  return machineCoords
}

export function getWorldToWcsTransform(): THREE.Matrix4 {
  const { currentCoords, rtcpState } = useMachineCoordsStore.getState()
  const attachHomog = evalTransforms(rtcpState.attachTransforms, currentCoords)
  const homog = attachHomog
  homog.multiply(rtcpState.partToTable)
  return homog.invert()
}

export function getWorldToPartTransform(): THREE.Matrix4 {
  const { currentCoords, rtcpState } = useMachineCoordsStore.getState()
  const attachHomog = evalTransforms(rtcpState.attachTransforms, currentCoords)
  const homog = attachHomog
  homog.multiply(rtcpState.mcs.clone().invert())
  return homog.invert()
}

// Transform from table coordinate system to machine coordinate system
// This accounts for table moving w.r.t machine. The transform can be used
// to calculate the actual machine coordinates, i.e. as if the machine actually moves.
// The transform ignores rotations to the table.
// Also, use w.r.t. spindle center point which might be different from machine origin
export function getTableToSpindleTransform(): THREE.Matrix4 {
  const { currentCoords, rtcpState } = useMachineCoordsStore.getState()

  const currentCoordsClone = {
    x: currentCoords.x,
    y: currentCoords.y,
    z: currentCoords.z,
    extraCoords: {
      a: 0,
      b: 0,
      c: 0,
    },
  }

  const attachDefHomog = evalTransforms(rtcpState.attachTransforms, DEFAULT_COORDS)
  const toolDefHomog = evalTransforms(rtcpState.toolTransforms, DEFAULT_COORDS)
  const attachHomog = evalTransforms(rtcpState.attachTransforms, currentCoordsClone)

  const homog = attachDefHomog.multiply(toolDefHomog.multiply(attachHomog).invert())
  return homog
}

// This is a separate function mostly for determining relative orientation of table and spindle
// Not sure exactly why the above function has two attach transforms
export function getTableToSpindleTransformOrientation(): THREE.Matrix4 {
  const { rtcpState } = useMachineCoordsStore.getState()

  const attachDefHomog = evalTransforms(rtcpState.attachTransforms, DEFAULT_COORDS)
  const toolDefHomog = evalTransforms(rtcpState.toolTransforms, DEFAULT_COORDS)

  const homog = attachDefHomog.multiply(toolDefHomog.invert())
  return homog
}

export function getMachineToMcsTransform(): THREE.Matrix4 {
  const { rtcpState } = useMachineCoordsStore.getState()
  return evalTransforms(rtcpState.attachTransforms.slice(-1), DEFAULT_COORDS)
}

export function useWorldToTableTransform(): THREE.Matrix4 {
  const { currentCoords, rtcpState } = useMachineCoordsStore.getState()
  return useMemo(() => {
    const homog = evalTransforms(rtcpState.attachTransforms, currentCoords)
    return homog.invert()
  }, [currentCoords, rtcpState])
}

export function useSpindleToTableTransform(): THREE.Matrix4 {
  const { currentCoords, rtcpState } = useMachineCoordsStore.getState()
  return useMemo(getTableToSpindleTransform, [currentCoords, rtcpState]).invert()
}

export function useMountPartTransform(): THREE.Matrix4 {
  const { rtcpState, referencePositionCoords } = useMachineCoordsStore.getState()
  return useMemo(() => {
    return evalTransforms(rtcpState.attachTransforms, referencePositionCoords)
  }, [rtcpState.attachTransforms, referencePositionCoords])
}

export function machineCoordToRtcpCoord(state: ControllerState, coords: MachineCoords): RtcpCoords {
  const neutralCoords = Object.assign({}, coords)
  neutralCoords.x = 0.0
  neutralCoords.y = 0.0
  neutralCoords.z = 0.0

  const rtcpState = state.rtcpState
  const toolHomog = evalTransforms(rtcpState.toolTransforms, neutralCoords)
  const attachHomog = evalTransforms(rtcpState.attachTransforms, neutralCoords)
  const homog = toolHomog.invert()
  homog.multiply(attachHomog)
  homog.multiply(rtcpState.partToTable)

  const point = new THREE.Vector3(coords.x, coords.y, coords.z - state.toolLength)
  point.applyMatrix4(homog.invert())

  const rtcpCoords = Object.assign({}, coords)
  rtcpCoords.x = point.x
  rtcpCoords.y = point.y
  rtcpCoords.z = point.z
  return rtcpCoords
}

function millisecondsToMinutes(millis: number): number {
  return millis / (1000.0 * 60.0)
}

function linearChange(start: MachineCoords, end: MachineCoords, includeRotation: boolean): number {
  const x = end.x - start.x
  const y = end.y - start.y
  const z = end.z - start.z
  let sum = x * x + y * y + z * z
  if (includeRotation) {
    for (const k in start.extraCoords) {
      if (k in end.extraCoords) {
        const diff = end.extraCoords[k] - start.extraCoords[k]
        sum += diff * diff
      }
    }
  }
  return Math.sqrt(sum)
}

function lerpCoords(start: MachineCoords, end: MachineCoords, t: number): MachineCoords {
  const extraCoords: Record<string, number> = {
    a: 0,
    b: 0,
    c: 0,
  }
  const coords = {
    x: (end.x - start.x) * t + start.x,
    y: (end.y - start.y) * t + start.y,
    z: (end.z - start.z) * t + start.z,
    extraCoords,
  }
  for (const k in start.extraCoords) {
    if (k in end.extraCoords) {
      coords.extraCoords[k] = (end.extraCoords[k] - start.extraCoords[k]) * t + start.extraCoords[k]
    }
  }
  return coords
}

export function setMachineCurrentCoords(
  coords: MachineCoords,
  isRtcp: boolean | undefined,
  ignoreToolLength?: boolean
): void {
  const { rtcpEnabled } = useMachineCoordsStore.getState()
  if ((isRtcp && !rtcpEnabled) || (!isRtcp && rtcpEnabled)) {
    return
  }

  if (rtcpEnabled) {
    setMachineRtcpCurrentCoords(coords, ignoreToolLength)
  } else {
    setMachineToolTipCurrentCoords(coords, ignoreToolLength)
  }
}

function setMachineToolTipCurrentCoords(coords: MachineCoords, ignoreToolLength?: boolean): void {
  const { toolLength } = useMachineCoordsStore.getState()
  const currentCoords = Object.assign({}, coords)
  if (!ignoreToolLength) {
    currentCoords.z += toolLength
  }
  useMachineCoordsStore.setState({
    currentCoords,
    targetCoordsBuffer: [],
    currentTime: Date.now(),
  })
}

function setMachineRtcpCurrentCoords(coords: RtcpCoords, ignoreToolLength?: boolean): void {
  const currentCoords = rtcpCoordToMachineCoord(
    useMachineCoordsStore.getState(),
    coords,
    ignoreToolLength
  )
  useMachineCoordsStore.setState({ currentCoords, targetCoordsBuffer: [], currentTime: Date.now() })
}

function setMachineToolTipTargetCoords(
  coords: MachineCoords,
  ignoreToolLength: boolean | undefined
): void {
  const targetCoords = Object.assign({}, coords)
  const { toolLength } = useMachineCoordsStore.getState()
  if (!ignoreToolLength) {
    targetCoords.z += toolLength
  }
  useMachineCoordsStore.setState({
    targetCoordsBuffer: [targetCoords],
    currentTime: Date.now(),
  })
}

function setMachineRtcpTargetCoords(coords: RtcpCoords): void {
  const targetCoords = rtcpCoordToMachineCoord(useMachineCoordsStore.getState(), coords)
  useMachineCoordsStore.setState({ targetCoordsBuffer: [targetCoords], currentTime: Date.now() })
}

function queueMachineToolTipTargetCoords(coords: MachineCoords[], reset = false): void {
  const { toolLength, targetCoordsBuffer } = useMachineCoordsStore.getState()
  const targetCoords = coords
    .map(c => {
      const coords = Object.assign({}, c)
      coords.z += toolLength
      return coords
    })
    .reverse()
  const coordsToKeep = reset ? [] : targetCoordsBuffer
  useMachineCoordsStore.setState({
    targetCoordsBuffer: [...targetCoords, ...coordsToKeep],
    currentTime: Date.now(),
  })
}

function queueMachineRtcpTargetCoords(coords: MachineCoords[], reset = false): void {
  const state = useMachineCoordsStore.getState()
  const targetCoords = coords.map(c => rtcpCoordToMachineCoord(state, c)).reverse()
  const coordsToKeep = reset ? [] : state.targetCoordsBuffer
  useMachineCoordsStore.setState({
    targetCoordsBuffer: [...targetCoords, ...coordsToKeep],
    currentTime: Date.now(),
  })
}

export function setMachineTargetCoords(
  coords: MachineCoords,
  isRtcp: boolean | undefined,
  ignoreToolLength: boolean | undefined
): void {
  const { rtcpEnabled } = useMachineCoordsStore.getState()
  if ((isRtcp && !rtcpEnabled) || (!isRtcp && rtcpEnabled)) {
    return
  }

  if (rtcpEnabled) {
    setMachineRtcpTargetCoords(coords)
  } else {
    setMachineToolTipTargetCoords(coords, ignoreToolLength)
  }
}

export const setPartialMachineTargetCoords = (
  coords: Partial<MachineCoords>,
  isRtcp: boolean | undefined
): void => {
  const state = useMachineCoordsStore.getState()
  const finalTargetCoords = getFinalTargetCoords(state)
  const extraCoords = { ...finalTargetCoords.extraCoords, ...coords.extraCoords }
  const fullCoords = { ...finalTargetCoords, ...coords, extraCoords }

  if (coords.z === undefined) {
    fullCoords.z -= state.toolLength
  }

  setMachineTargetCoords(fullCoords, isRtcp, false)
}

export function queueTargetCoords(
  coords: MachineCoords[],
  isRtcp: boolean | undefined,
  reset = false
): void {
  const { rtcpEnabled } = useMachineCoordsStore.getState()
  if ((isRtcp && !rtcpEnabled) || (!isRtcp && rtcpEnabled)) {
    return
  }

  if (rtcpEnabled) {
    queueMachineRtcpTargetCoords(coords, reset)
  } else {
    queueMachineToolTipTargetCoords(coords, reset)
  }
}

export function defaultState(): ControllerState {
  return {
    currentTime: Date.now(),
    currentCoords: DEFAULT_COORDS,
    targetCoordsBuffer: [],
    wcs: DEFAULT_COORDS,
    feedrate: 15_000, // mm/min
    rtcpEnabled: false,
    toolLength: 0.0,
    referencePositionCoords: {
      x: 0,
      y: 0,
      z: 0,
      extraCoords: {
        a: 0,
        b: 0,
        c: 0,
      },
    },
    rtcpState: {
      toolTransforms: [],
      attachTransforms: [],
      wcs: new THREE.Matrix4(),
      mcs: new THREE.Matrix4(),
      partToTable: new THREE.Matrix4(),
    },
  }
}

export const useMachineCoordsStore = create<
  ControllerState,
  SetState<ControllerState>,
  GetState<ControllerState>,
  StoreApiWithSubscribeWithSelector<ControllerState>
>(subscribeWithSelector((): ControllerState => defaultState()))

export const updateCoords = (state: ControllerState): void => {
  const newCurrentTime = Date.now()
  const deltaTime = newCurrentTime - state.currentTime
  if (state.targetCoordsBuffer.length === 0) {
    return
  }

  const getWorkingCoords = state.rtcpEnabled
    ? (coords: MachineCoords) => machineCoordToRtcpCoord(state, coords)
    : (coords: MachineCoords) => coords
  const getFinalCoords = state.rtcpEnabled
    ? (coords: RtcpCoords) => rtcpCoordToMachineCoord(state, coords)
    : (coords: MachineCoords) => coords

  let remainingTravel = state.feedrate * millisecondsToMinutes(deltaTime)
  const targetCoordsBuffer = [...state.targetCoordsBuffer]
  let workingCoords = getWorkingCoords(state.currentCoords)

  while (remainingTravel > 0.0 && targetCoordsBuffer.length > 0) {
    const nextTarget = targetCoordsBuffer[targetCoordsBuffer.length - 1]
    const nextTargetCoords = getWorkingCoords(nextTarget)
    const distance = linearChange(workingCoords, nextTargetCoords, true)
    if (distance > remainingTravel) {
      const t = remainingTravel / distance
      workingCoords = lerpCoords(workingCoords, nextTargetCoords, t)
      break
    } else {
      workingCoords = nextTargetCoords
      remainingTravel -= distance
      targetCoordsBuffer.pop()
    }
  }

  const currentCoords = getFinalCoords(workingCoords)
  useMachineCoordsStore.setState({ currentCoords, targetCoordsBuffer, currentTime: newCurrentTime })
}

export const useMachineOperation = (
  machine: MachineRecord | undefined,
  operation: Operation
): void => {
  const { toolTransforms, attachTransforms, referencePositionCoords } = useMemo(() => {
    if (!machine) return { toolTransforms: [], attachTransforms: [] }

    let referencePositionCoords: MachineCoords = {
      x: 0,
      y: 0,
      z: 0,
      extraCoords: { a: 0, b: 0, c: 0 },
    }
    for (let i = 0; i < machine.kinematics.referencePositions.length; ++i) {
      if (machine.kinematics.referencePositions[i].label === "Reference Position") {
        // referencePositionCoords = machine.kinematics.referencePositions[i].coords
        const coords = machine.kinematics.referencePositions[i].coords
        referencePositionCoords = {
          x: coords.x ?? 0,
          y: coords.y ?? 0,
          z: coords.z ?? 0,
          extraCoords: {
            a: coords.a ?? 0,
            b: coords.b ?? 0,
            c: coords.c ?? 0,
          },
        }
      }
    }

    const nodeTree = createMachineRecordNodeTree(machine)
    const toolTransforms = machineNodeTransforms(nodeTree, "Tool")
    const attachTransforms = machineNodeTransforms(nodeTree, "Attach")
    return { toolTransforms, attachTransforms, referencePositionCoords }
  }, [machine])

  const { wcs, mcs } = operation

  useEffect(() => {
    const mcsMatrix = transformToMatrix(mcs)
    const partToTable = mcsMatrix.clone().invert()
    partToTable.multiply(transformToMatrix(wcs))

    if (referencePositionCoords) {
      useMachineCoordsStore.setState({
        referencePositionCoords,
        rtcpState: {
          toolTransforms,
          attachTransforms,
          wcs: transformToMatrix(wcs),
          mcs: mcsMatrix,
          partToTable,
        },
      })
    } else {
      useMachineCoordsStore.setState({
        rtcpState: {
          toolTransforms,
          attachTransforms,
          wcs: transformToMatrix(wcs),
          mcs: mcsMatrix,
          partToTable,
        },
      })
    }
  }, [toolTransforms, attachTransforms, referencePositionCoords, wcs, mcs])
}

export const useMachineBodyNodeTransformFormula = (
  transformFormula: string
): React.MutableRefObject<THREE.Group> => {
  const groupRef = useRef(new THREE.Group())

  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state.currentCoords,
      (currentCoords: MachineCoords) => {
        const homog = evaluateMatrix(transformFormula, coordsToRecord(currentCoords))
        groupRef.current.position.setFromMatrixPosition(homog)
        groupRef.current.setRotationFromMatrix(homog)
      },
      { fireImmediately: true }
    )
  }, [transformFormula])

  return groupRef
}

export const useTargetCoordsState = (): { coords: MachineCoords; toolLength: number } => {
  const state = useMachineCoordsStore.getState()
  const [coords, setCoords] = useState(getFinalTargetCoords(state))
  const [toolLength, setToolLength] = useState(state.toolLength)

  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state,
      (state: ControllerState) => {
        setCoords(getFinalTargetCoords(state))
        setToolLength(state.toolLength)
      },
      { fireImmediately: true }
    )
  }, [])
  return { coords, toolLength }
}

const getFinalTargetCoords = (state: ControllerState): MachineCoords => {
  const { currentCoords, targetCoordsBuffer } = state
  if (targetCoordsBuffer.length > 0) return targetCoordsBuffer[0]
  return currentCoords
}

// Travel limits info
const LINEAR_WARNING_THRESHOLD = -25
const ROTARY_WARNING_THRESHOLD = -5
const LINEAR_AXES = new Set(["x", "y", "z"])

export interface TravelLimitIntents {
  worst: Intent
  axes: Record<string, Intent>
  distances: Record<string, number>
}

export const useTravelLimitsStore = create<
  TravelLimitIntents,
  SetState<TravelLimitIntents>,
  GetState<TravelLimitIntents>,
  StoreApiWithSubscribeWithSelector<TravelLimitIntents>
>(subscribeWithSelector((): TravelLimitIntents => ({ worst: "none", axes: {}, distances: {} })))

export const useTravelLimitsListener = (kinematics: MachineKinematics): void => {
  // const machine = useSelector(activeOperationSelectors.selectActiveMachineRecord)
  // const kinematics = machine?.kinematics
  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state,
      (state: ControllerState) => {
        const { currentCoords } = state
        if (!kinematics) {
          useTravelLimitsStore.setState({ distances: {} })
          return
        }

        const { x, y, z } = currentCoords
        const coords: Record<string, number> = { x, y, z, ...currentCoords.extraCoords }
        const distances: Record<string, number> = {}
        kinematics.axes.forEach(axis => {
          const value = coords[axis]
          const limits = kinematics.travelLimits[axis]
          let distance: number
          if (!limits || value === undefined) {
            distance = -Infinity
          } else {
            const midpoint = (limits.max + limits.min) / 2
            if (value > midpoint) {
              distance = value - limits.max
            } else {
              distance = limits.min - value
            }
          }
          distances[axis] = distance
        })
        useTravelLimitsStore.setState({ distances })
      },
      { fireImmediately: true }
    )
  }, [kinematics])

  useEffect(() => {
    return useTravelLimitsStore.subscribe(
      (state: TravelLimitIntents) => state.distances,
      (distances: Record<string, number>) => {
        let worst: Intent = "none"
        const axes: Record<string, Intent> = {}
        for (const axis in distances) {
          const distance = distances[axis]
          if (distance > 0) {
            axes[axis] = "danger"
            worst = "danger"
          } else if (LINEAR_AXES.has(axis)) {
            if (distance > LINEAR_WARNING_THRESHOLD) {
              axes[axis] = "warning"
              if (worst !== "danger") {
                worst = "warning"
              }
            } else {
              axes[axis] = "none"
            }
          } else {
            if (distance > ROTARY_WARNING_THRESHOLD) {
              axes[axis] = "warning"
              if (worst !== "danger") {
                worst = "warning"
              }
            } else {
              axes[axis] = "none"
            }
          }
        }
        useTravelLimitsStore.setState({ worst, axes })
      },
      { fireImmediately: true }
    )
  }, [kinematics])
}
