import React, { FC, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Callout, Spinner } from "@blueprintjs/core"
import { AxiosError } from "axios"

import { sendEvent } from "src/components/Websocket/Websocket"
import { useOverviewQuery } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import styles from "src/pages/Jobs/RestoreUpload/RestoreUpload.module.css"

export const RestoreUpload: FC<{
  jobId?: string
  isPlan?: boolean
  setRestoreModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isPlan, jobId, setRestoreModalIsOpen }) => {
  const { filesApi } = useApi()
  const toaster = useToaster()
  const errorToast = useNetworkErrorToast()
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false)

  const { refetch } = useOverviewQuery()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileCallBack = (sendEventType: string) => {
        sendEvent(sendEventType)
        toaster.show({ message: `Uploaded Zip file`, intent: "success" })
        refetch().then(() => {
          setRestoreModalIsOpen(false)
          setIsFileLoading(false)
        })
      }

      const errorHandler = (error: AxiosError) => {
        setIsFileLoading(false)
        errorToast(error, "Upload error")
      }
      setIsFileLoading(true)
      const uploadFile = (file: File) => {
        if (isPlan && jobId) {
          filesApi.restorePlanBackup(jobId, file).then(() => {
            fileCallBack("importJob")
          }, errorHandler)
        } else {
          filesApi.restoreJobBackup(file).then(() => {
            fileCallBack("importPlan")
          }, errorHandler)
        }
      }

      acceptedFiles.forEach((file: File) => {
        uploadFile(file)
      })
    },
    [filesApi, isPlan, jobId, setRestoreModalIsOpen, toaster, errorToast, refetch]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className={styles.container}>
      <Callout intent={"warning"}>
        <p>If you are not a RemoteShop developer, this is probably not what you are looking for.</p>
      </Callout>
      <div {...getRootProps()} className={styles.dropContainer}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop exported .zip file here</p>
        ) : (
          <p>Drag exported .zip file here, or click to select</p>
        )}
      </div>
      {isFileLoading && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  )
}
