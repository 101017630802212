import { createAction, createReducer } from "@reduxjs/toolkit"

import { Point } from "src/client-axios"
import { RootState } from "src/store/rootStore"

export interface GuardrailPosition {
  point: Point
  extra_coords: Record<string, number>
}

const setGuardrailArrows = createAction<GuardrailPosition[] | undefined>(
  "probing/setGuardrailArrows"
)

export const guardrailReducer = createReducer<{
  guardrailArrows?: GuardrailPosition[]
}>(
  {
    guardrailArrows: undefined,
  },
  builder => {
    builder.addCase(setGuardrailArrows, (state, action) => {
      state.guardrailArrows = action.payload
    })
  }
)

/**
 * Actions
 */
export const guardrailActions = {
  setGuardrailArrows,
}

/**
 * Selectors
 */
const selectGuardrailArrows = (state: RootState): GuardrailPosition[] | undefined =>
  state.guardrail.guardrailArrows

export const guardrailSelectors = {
  selectGuardrailArrows,
}
