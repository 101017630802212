import React, { FC } from "react"
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer"
import { AnchorButton, Icon, Menu, Position } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"

import { CreateOperationMenuItems } from "src/components/Cam/CreateOperationMenuItems"

import styles from "./CreateOperationButton.module.css"

export const CreateOperationButton: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  return (
    <div className={styles.addButton}>
      <ApolloConsumer>
        {client => (
          <Popover2
            content={
              <Menu>
                <CreateOperationMenuItems
                  planId={planId}
                  operationIdx={operationIdx}
                  client={client}
                  isBefore
                />
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <Tooltip2
              placement={"top"}
              content={`Insert a new operation`}
              openOnTargetFocus={false}
            >
              <AnchorButton intent="success">
                <Icon icon="add" />
              </AnchorButton>
            </Tooltip2>
          </Popover2>
        )}
      </ApolloConsumer>
    </div>
  )
}
