import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AnchorButton, Button } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import {
  ParametricStock,
  ParametricStockKindEnum,
  ParametricStockShapeRectangularKindEnum,
  StockShapeRestriction,
} from "src/client-axios"
import { DeleteStockModal } from "src/components/Cam/DeleteStockModal/DeleteStockModal"
import { ProductStockSelector } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/ProductStockSelector/ProductStockSelector"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { activeActions, ClickHandlerType } from "src/store/cam/active"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { defaultTransform } from "src/util/geometry/transforms"
import { OutputStockConfigPanel } from "../OutputStockConfigPanel/OutputStockConfigPanel"
import { FixtureStockConfigPanel } from "./FixtureStockConfigPanel/FixtureStockConfigPanel"
import { ModelStockConfigPanel } from "./ModelStockConfigPanel/ModelStockConfigPanel"
import { RectangularStockSizeControls } from "./RectangularStockSizeControls/RectangularStockSizeControls"
import { RoundStockSizeControls } from "./RoundStockSizeControls/RoundStockSizeControls"

import styles from "./InputStockConfigPanel.module.css"

// Default tolerances are in 3 files and may need to be updated in each:
// services/backend/app/worker/cam/strategies/stock.py
// models/src/input_stock.rs
// services/frontend/src/components/Cam/TreeNavigator/ConfigPanel/Panels/InputStockConfigPanel/InputStockConfigPanel.tsx
export const DefaultTolerances = {
  xMinTolerance: 3.0,
  xMaxTolerance: 3.0,
  yMinTolerance: 3.0,
  yMaxTolerance: 3.0,
  cutLengthMinTolerance: 3.0,
  cutLengthMaxTolerance: 3.0,
  diameterMinTolerance: 3.0,
  diameterMaxTolerance: 3.0,
}

export const InputStockConfigPanel: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
  forCard?: boolean
}> = ({ planId, operationIdx, locked, forCard }) => {
  const inputStock = useSelector((state: RootState) =>
    storedOperationSelectors.selectInputStock(state, planId, operationIdx, true)
  )

  const [showDelete, setShowDelete] = useState(false)
  const dispatch = useAppDispatch()

  const { tasksApi } = useApi()
  const toaster = useToaster()

  useEffect(() => {
    dispatch(activeActions.setActiveClickHandler(ClickHandlerType.INSPECTOR))
  }, [dispatch])

  if (inputStock === undefined && operationIdx === 0) {
    const onSubmit = () => {
      tasksApi
        .generateStock(planId, StockShapeRestriction.None, {
          minStockExtent: {},
          maxStockExtent: {},
          offsetProportions: { x: 0, y: 0, z: 0 },
          padMax: { x: 2.0, y: 2.0, z: 2.0 },
          padMin: { x: 2.0, y: 2.0, z: 2.0 },
        })
        .then(() => toaster.show({ message: "Generating stock...", intent: "primary" }))
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Stock generation request failed: ${err}`, intent: "danger" })
        })
    }

    const defaultInputStock: ParametricStock = {
      kind: ParametricStockKindEnum.Parametric,
      shape: {
        kind: ParametricStockShapeRectangularKindEnum.Rectangular,
        x: 0,
        y: 0,
      },
      cutLength: 0,
      xMinTolerance: DefaultTolerances.xMinTolerance,
      xMaxTolerance: DefaultTolerances.xMaxTolerance,
      yMinTolerance: DefaultTolerances.yMinTolerance,
      yMaxTolerance: DefaultTolerances.yMaxTolerance,
      cutLengthMinTolerance: DefaultTolerances.cutLengthMinTolerance,
      cutLengthMaxTolerance: DefaultTolerances.cutLengthMaxTolerance,
      diameterMinTolerance: DefaultTolerances.diameterMinTolerance,
      diameterMaxTolerance: DefaultTolerances.diameterMaxTolerance,
      transform: defaultTransform(),
    }

    return (
      <>
        <ProductStockSelector
          planId={planId}
          operationIdx={operationIdx}
          inputStock={defaultInputStock}
          locked={locked}
        />
        <Button intent="success" minimal onClick={onSubmit}>
          Auto-select stock
        </Button>
      </>
    )
  }

  if (inputStock?.kind === "fixture") {
    return (
      <FixtureStockConfigPanel
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
        forCard={forCard}
      />
    )
  }

  if (inputStock?.kind === "model") {
    return (
      <ModelStockConfigPanel
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
      />
    )
  }

  // This is only the case if the kind is blank or operation output stock
  if (inputStock?.kind !== "parametric") {
    return <OutputStockConfigPanel planId={planId} operationIdx={operationIdx} locked={locked} />
  }

  return (
    <div className={styles.container}>
      <ProductStockSelector
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
      />
      {inputStock.shape.kind === "rectangular" && (
        <RectangularStockSizeControls
          planId={planId}
          operationIdx={operationIdx}
          inputStock={inputStock}
          locked={locked}
        />
      )}
      {inputStock.shape.kind === "round" && (
        <RoundStockSizeControls
          planId={planId}
          operationIdx={operationIdx}
          inputStock={inputStock}
          locked={locked}
        />
      )}

      {operationIdx !== 0 && !locked && !forCard && (
        <>
          <Tooltip2
            hoverOpenDelay={300}
            content={
              "Removes the stock from this operation, defaulting back to the output stock of a previous operation."
            }
            openOnTargetFocus={false}
          >
            <AnchorButton
              minimal
              icon="cross"
              intent="danger"
              onClick={() => {
                setShowDelete(true)
              }}
            >
              Remove Stock{" "}
            </AnchorButton>
          </Tooltip2>
          {showDelete && (
            <DeleteStockModal
              planId={planId}
              operationIdx={operationIdx}
              isOpen={showDelete}
              close={() => {
                setShowDelete(false)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

export const argSort = (array: number[]): number[] => {
  return array
    .map((v, i) => [v, i])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])
}
