import React, { FC, Suspense, useEffect, useState } from "react"

import {
  AnimatedGltfModelProps,
  AnimatedGltfUrlModel,
} from "src/components/Canvas/Viewer/SceneItems/GltfUrlModel"
import {
  SceneModelFragment,
  useModelByIdLazyQuery,
  useSceneModelByIdQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useDebugLog } from "src/hooks/useDebugLog/useDebugLog"
import { CylinderData } from "src/util/geometry/cylinder"

interface GltfLocatorModelProps extends AnimatedGltfModelProps {
  locator: string
  flatShading: boolean
}

export const GltfLocatorModel: FC<GltfLocatorModelProps> = ({ locator, flatShading, ...props }) => {
  const [url, setUrl] = useState<URL | undefined>()
  const { planchangerApi } = useApi()

  useEffect(() => {
    planchangerApi.urlFor_getFile(locator).then(result => {
      setUrl(result)
    })
  }, [planchangerApi, locator])

  if (!url) return null
  return (
    <Suspense fallback={<></>}>
      <AnimatedGltfUrlModel url={url.toString()} flatShading={flatShading} {...props} />
    </Suspense>
  )
}

interface GltfIdModelProps extends AnimatedGltfModelProps {
  modelId: string
}

export const GltfIdModel: FC<GltfIdModelProps> = ({ modelId, ...props }) => {
  const debugLog = useDebugLog()

  debugLog(`GltfIdModel modelId: ${modelId}`)

  const { data: model, loading } = useSceneModelByIdQuery({ variables: { id: modelId } })

  debugLog(`GltfIdModel useSceneModelByIdQuery loading: ${loading}`)
  debugLog(`GltfIdModel useSceneModelByIdQuery modelId:, ${modelId}`)

  if (!model?.model3D) {
    if (!loading) {
      console.error(`Model ${modelId} NOT found`)
    }
    return null
  }
  return <GltfFullModel model={model.model3D} {...props} />
}

interface GltfFullModelProps extends AnimatedGltfModelProps {
  model: SceneModelFragment
}

export const GltfFullModel: FC<GltfFullModelProps> = ({ model, ...props }) => {
  const debugLog = useDebugLog()

  debugLog(`GltfFullModel modelId:, ${model?.id}`)

  const [url, setUrl] = useState<URL | undefined>()

  const [pollInterval, setPollInterval] = useState(1000)
  const [getModel, { called, data: modelData }] = useModelByIdLazyQuery({ pollInterval })
  const { planchangerApi } = useApi()

  const modelLocator = model?.scene?.locator || modelData?.model3D?.scene?.locator
  const modelExists = model?.scene?.exists || modelData?.model3D?.scene?.exists
  const cylinderData: CylinderData[] | undefined = model?.description?.cylinders as any // eslint-disable-line

  useEffect(() => {
    if (modelLocator && modelExists) {
      planchangerApi.urlFor_getFile(modelLocator).then(result => {
        setUrl(result)
        setPollInterval(0)
      })
    } else if (!called && model !== undefined) {
      getModel({ variables: { id: model.id } })
    }
  }, [planchangerApi, model, called, getModel, modelLocator, modelExists])

  if (!url) return null
  return (
    <Suspense fallback={<></>}>
      <AnimatedGltfUrlModel
        url={url.toString()}
        cylinderData={cylinderData}
        flatShading={model.flatShading}
        {...props}
      />
    </Suspense>
  )
}
