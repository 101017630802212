import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import {
  MachinedStockSimSurfaceCone,
  MachinedStockSimSurfaceCylinder,
  MachinedStockSimSurfaceCylinderKindEnum,
  MachinedStockSimSurfacePlane,
  MachinedStockSimSurfaceSphere,
  MachinedStockSimSurfaceTorus,
} from "src/client-axios"
import {
  DisplayMode as CuttingSimDisplayMode,
  viewOptionsSelectors,
} from "src/store/ui/viewOptions"
import { useCuttingSimDisplayStore } from "src/store/zustandCuttingSim"
import { transformToMatrix } from "src/util/geometry/transforms"

type MachinedStockSimSurface =
  | MachinedStockSimSurfaceCylinder
  | MachinedStockSimSurfacePlane
  | MachinedStockSimSurfaceSphere
  | MachinedStockSimSurfaceTorus
  | MachinedStockSimSurfaceCone

function createCylinder(machinedSurface: MachinedStockSimSurfaceCylinder) {
  const radialScale = 0.99
  const heightScale = 1
  const radius = machinedSurface.radius * radialScale
  const height = machinedSurface.height * heightScale

  const geometry = new THREE.CylinderGeometry(radius, radius, height, 32, 1, true)
  const material = new THREE.MeshBasicMaterial({ opacity: 0.65, color: 0x111111 })
  material.side = THREE.DoubleSide
  material.transparent = true
  const cylinder = new THREE.Mesh(geometry, material)

  material.onBeforeCompile = (shader: THREE.Shader) => {
    shader.vertexShader = shader.vertexShader.replace(
      "#include <common>",
      `
          varying vec2 v_uv;
          #include <common>
      `
    )

    shader.vertexShader = shader.vertexShader.replace(
      "#include <fog_vertex>",
      `
          v_uv = uv;
      `
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <common>",
      `
          varying vec2 v_uv;

          #include <common>
      `
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <dithering_fragment>",
      `
            #include <dithering_fragment>
            // gl_FragColor.rgb = totalSpecular;
            // gl_FragColor.rgb = vec3(metalness);
            gl_FragColor.a = opacity;
            gl_FragColor.rgb = vec3(1., 0.2, 0.2);
            gl_FragColor.r = v_uv.s;
            gl_FragColor.g = v_uv.t;
            float x = mod(v_uv.t * 4., 1.0);
            float y = mod(v_uv.s * 4., 1.0);
            gl_FragColor.rgb = (x < 0.5 && y >= 0.5 || x >= 0.5 && y < 0.5) ? vec3(0.1) : vec3(1.);
    `
    )
  }

  cylinder.translateX(machinedSurface.position.x)
  cylinder.translateY(machinedSurface.position.y)
  cylinder.translateZ(machinedSurface.position.z)

  const quaternion = new THREE.Quaternion()
  const up = new THREE.Vector3(0, 1, 0)
  const axis = new THREE.Vector3(
    machinedSurface.axis.x,
    machinedSurface.axis.y,
    machinedSurface.axis.z
  ).normalize()
  quaternion.setFromUnitVectors(up, axis)
  cylinder.applyQuaternion(quaternion)

  const lineMaterial = new THREE.LineBasicMaterial({
    color: 0xff0000,
  })

  const points = []
  points.push(
    new THREE.Vector3(
      machinedSurface.position.x,
      machinedSurface.position.y,
      machinedSurface.position.z
    )
  )
  points.push(
    new THREE.Vector3(
      machinedSurface.position.x + machinedSurface.axis.x * machinedSurface.height * 0.75,
      machinedSurface.position.y + machinedSurface.axis.y * machinedSurface.height * 0.75,
      machinedSurface.position.z + machinedSurface.axis.z * machinedSurface.height * 0.75
    )
  )
  const line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), lineMaterial)

  return (
    <group>
      <primitive object={cylinder} visible />
      <primitive object={line} visible />
    </group>
  )
}

function createSurface(machinedSurface: MachinedStockSimSurface) {
  if (machinedSurface.kind === MachinedStockSimSurfaceCylinderKindEnum.Cylinder) {
    return createCylinder(machinedSurface)
  }
  return undefined
}

export const StockSimParamSurfScene: FC = () => {
  const cuttingSimDisplayMode = useSelector(viewOptionsSelectors.cuttingSimDisplayMode)

  const state = useCuttingSimDisplayStore()
  const machinedParametricSurfaces = state?.stockMachinedParamSurfaces
  const selectedParamSurfaceIdx = state.selectedStockMachinedParamSurfaces[0]

  const { surface, translation, quaternion } = useMemo(() => {
    if (
      machinedParametricSurfaces &&
      selectedParamSurfaceIdx < machinedParametricSurfaces.surfaces.length
    ) {
      const partToSurface = transformToMatrix(machinedParametricSurfaces.transform)
      const invPartToSurface = partToSurface.clone().invert()

      const translation = new THREE.Vector3()
      const quaternion = new THREE.Quaternion()
      const scale = new THREE.Vector3()
      invPartToSurface.decompose(translation, quaternion, scale)

      const surface = createSurface(machinedParametricSurfaces.surfaces[selectedParamSurfaceIdx])
      return { surface, translation, quaternion }
    }
    return {}
  }, [selectedParamSurfaceIdx, machinedParametricSurfaces])

  return (
    <>
      {cuttingSimDisplayMode !== CuttingSimDisplayMode.Hidden && (
        <group position={translation} quaternion={quaternion}>
          {surface}
        </group>
      )}
    </>
  )
}
