import React, { FC, useEffect, useRef, useState } from "react"
import { a, animated, useSpring } from "@react-spring/three"
import * as THREE from "three"

import { AppModelsGeometryTransform as Transform } from "src/client-axios"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { TRANSITION_CONFIG } from "src/util/animation/springConfig"
import { transformToSpringInputs } from "src/util/geometry/transforms"

interface AxesHelperProps {
  transform: Transform
  size: number
  opacity: number
}

const AxesHelper: FC<AxesHelperProps> = ({ transform, size, opacity, children }) => {
  const { transferInvalidate } = useTransferInvalidate()

  const [ready, setReady] = useState(true)
  const axesRef = useRef<THREE.AxesHelper>()

  const { position: targetPosition, rotation: targetRotation } = transformToSpringInputs(transform)

  const [{ position, rotation }] = useSpring(
    {
      to: { position: targetPosition, rotation: targetRotation },
      onChange: () => transferInvalidate(),
      config: TRANSITION_CONFIG,
    },
    [targetPosition, targetRotation, transferInvalidate]
  )

  useEffect(() => {
    if (axesRef.current) {
      const material = axesRef.current.material as THREE.Material
      material.transparent = true
    }
  }, [axesRef])

  useEffect(() => {
    if (axesRef.current) {
      const material = axesRef.current.material as THREE.Material
      material.opacity = opacity
      // Ensure that opacity is set before visible
      setTimeout(() => setReady(true), 0)
    }
  }, [axesRef, opacity])

  return (
    <a.group
      position={(position as unknown) as [x: number, y: number, z: number]}
      rotation={(rotation as unknown) as [x: number, y: number, z: number]}
    >
      <a.axesHelper ref={axesRef} args={[size]} visible={ready} />
      {children}
    </a.group>
  )
}

export const AnimatedAxesHelper = animated(AxesHelper)
