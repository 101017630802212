import React, { FC, useEffect, useState } from "react"
import { Button, MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select"

import { highlightText } from "src/util/text"
import { VERICUT_MATERIAL_IDS } from "./materialIds"

const VericutMaterialSelect = Select.ofType<string>()

const VericutMaterialMenuItem: ItemRenderer<string> = (
  vericutMaterial,
  { handleClick, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={vericutMaterial}
      onClick={handleClick}
      text={highlightText(vericutMaterial, query)}
    />
  )
}

const filterVericutMaterial: ItemPredicate<string> = (query, vericutMaterial) => {
  return vericutMaterial.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

interface VericutMaterialSelectorProps {
  material: string | undefined
  setMaterial: (material: string) => void
  className?: string
  disabled?: boolean
}

export const VericutMaterialSelector: FC<VericutMaterialSelectorProps> = ({
  material,
  setMaterial,
  className,
  disabled,
}) => {
  const [selection, setSelection] = useState(VERICUT_MATERIAL_IDS[0])

  useEffect(() => {
    material && setSelection(material)
  }, [material])

  return (
    <VericutMaterialSelect
      disabled={disabled}
      activeItem={selection}
      items={VERICUT_MATERIAL_IDS}
      itemPredicate={filterVericutMaterial}
      itemRenderer={VericutMaterialMenuItem}
      className={className || ""}
      onItemSelect={item => {
        setSelection(item)
        setMaterial(item)
      }}
      popoverProps={{ minimal: true, captureDismiss: true }}
    >
      <Button
        id={"vericut-material"}
        fill={true}
        alignText={"left"}
        rightIcon="caret-down"
        small={true}
        text={selection}
        disabled={disabled}
      />
    </VericutMaterialSelect>
  )
}
