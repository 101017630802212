import * as THREE from "three"
import create, { GetState, SetState } from "zustand"
import { StoreApiWithSubscribeWithSelector, subscribeWithSelector } from "zustand/middleware"

import {
  MachinedParametricStockSimInfo,
  OpCamFeatureInfo,
  ParametricStockSimInfo,
  StockAnalysisToolPathData,
} from "src/client-axios"
import { ProductionControlMenu } from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/ProductionControlsPanel"
import {
  KinematicData,
  KinematicError,
} from "src/components/Canvas/Viewer/Scene/Cam/kinematicError"

export enum OperationStockType {
  InputStock = "InputStock",
  OutputStock = "OutputStock",
}

export enum DisplayMode {
  ToolChange = "ToolChange",
  CutterComp = "CutterComp",
  KinematicTolerance = "KinematicTolerance",
}

export enum ToolChangeDisplayMode {
  ToolChange = "ToolChange",
  Speed = "Speed",
  Feed = "Feed",
}

export enum CompensationDisplayMode {
  Usage = "Usage",
  Index = "Index",
  Inspection = "Inspection",
}

export enum StockMeshKind {
  OutputStock = "OutputStock",
  ToolChangeStock = "ToolChangeStock",
}

export interface StockMeshInfo {
  kind: StockMeshKind
  index?: number
  loaded: boolean
}

export type CutDataCutInfo = {
  leftEngaged?: boolean
  leftMinWidth?: number
  leftMaxWidth?: number
  rightEngaged?: boolean
  rightMinWidth?: number
  rightMaxWidth?: number
  maxDepthOfCut?: number
  widthOfCut?: number
  volume?: number
}

export type CutData = {
  vertexIndex: number
  cutNumber: number
  distance?: number
  gcodeLine: number
  toolPosition: THREE.Vector3
  toolAxis: THREE.Vector3
  normalVec: THREE.Vector3
  toolChange: number
  cutterComp?: number
  radialComp?: number
  speed?: number
  feed?: number
  toolNumber?: string
  cutInfo?: CutDataCutInfo
  kinematicError?: KinematicError
}

export type CuttingSimDisplayStateSettings = {
  displayMode: DisplayMode
  toolChangedisplayMode: ToolChangeDisplayMode
  compensationDisplayMode: CompensationDisplayMode
  metalness: number
  roughness: number
  enableRestGougeMaterial: boolean
  showRestGougeMaterial: boolean
  stockOpacity: number
  maxKinematicError?: number
  kinematicErrorThreshold: number
  kinematicWarningThreshold: number
  showToolPath: boolean
  showTool: boolean
  showHolder: boolean
  showInputStock: boolean
  showFcs: boolean
}

export type CuttSimDisplayStateData = {
  stockMeshes: Array<StockMeshInfo>
  currentStockMesh: StockMeshInfo
  cutData?: CutData
  toolPathCbor?: StockAnalysisToolPathData
  stockMachinedParamSurfaces?: MachinedParametricStockSimInfo
  stockParamSurfaces?: ParametricStockSimInfo
  opCamFeatureInfo?: OpCamFeatureInfo
  selectedStockMachinedParamSurfaces: number[]
  toolIds: Array<string | undefined>
  kinematicBenchmark?: KinematicData
  selectedToolPaths: number[]
}

export type CuttingSimDisplayState = CuttingSimDisplayStateSettings & CuttSimDisplayStateData

export interface StockMeshToolPathSelection {
  currentStockMesh: StockMeshInfo
  selectedToolPaths: number[]
}

export function outputStockInfo(): StockMeshInfo {
  return {
    kind: StockMeshKind.OutputStock,
    loaded: false,
  }
}

export function toolChangeStockInfo(index: number): StockMeshInfo {
  return {
    kind: StockMeshKind.ToolChangeStock,
    index,
    loaded: false,
  }
}

export function defaultStockMeshNames(): Array<StockMeshInfo> {
  return [outputStockInfo()]
}

// 0 = initial stock
// 1 = stock after the first tool change / before tool change with index=1
// ...
// N (tool change count) = output stock
export function stockMeshInfo(index: number, toolChangeCount: number): StockMeshInfo {
  if (index < toolChangeCount) {
    return toolChangeStockInfo(Math.max(0, index))
  }
  return outputStockInfo()
}

export function stockMeshToolPathSelection(
  index: number,
  toolChangeCount: number
): StockMeshToolPathSelection {
  const currentStockMesh = stockMeshInfo(index, toolChangeCount)
  const selectedToolPaths: number[] = []
  if (currentStockMesh.index === undefined) {
    selectedToolPaths.push(toolChangeCount - 1)
  } else if (currentStockMesh.index >= 1) {
    selectedToolPaths.push(currentStockMesh.index - 1)
  }
  return {
    currentStockMesh,
    selectedToolPaths,
  }
}

export function currentStockMeshLoaded(state: CuttingSimDisplayState): boolean | undefined {
  const currentStockMeshIndex = state.currentStockMesh.index
  return (
    // If initial stock, it should be loaded
    // For stock at tool changes, check whether they have been loaded
    currentStockMeshIndex === 0 ||
    state.stockMeshes.find(v => v.index === currentStockMeshIndex)?.loaded
  )
}

export function getSelectedStockParamSurfaces(): number[] {
  const {
    stockMachinedParamSurfaces,
    selectedStockMachinedParamSurfaces,
  } = useCuttingSimDisplayStore.getState()

  return stockMachinedParamSurfaces
    ? selectedStockMachinedParamSurfaces.map(
        idx => stockMachinedParamSurfaces.surfaces[idx].machiningInfo.surface_index
      )
    : []
}

export function defaultSettings(): CuttingSimDisplayStateSettings {
  return {
    displayMode: DisplayMode.ToolChange,
    toolChangedisplayMode: ToolChangeDisplayMode.ToolChange,
    compensationDisplayMode: CompensationDisplayMode.Usage,
    metalness: 0.99,
    roughness: 1.0,
    enableRestGougeMaterial: true,
    showRestGougeMaterial: true,
    stockOpacity: 1.0,
    maxKinematicError: undefined,
    kinematicErrorThreshold: 0.002,
    kinematicWarningThreshold: 0.001,
    showToolPath: false,
    showTool: false,
    showHolder: false,
    showInputStock: false,
    showFcs: false,
  }
}

export function resetState(): CuttSimDisplayStateData {
  return {
    stockMeshes: [],
    currentStockMesh: outputStockInfo(),
    cutData: undefined,
    toolPathCbor: undefined,
    stockMachinedParamSurfaces: undefined,
    stockParamSurfaces: undefined,
    opCamFeatureInfo: undefined,
    selectedStockMachinedParamSurfaces: [],
    toolIds: [],
    kinematicBenchmark: undefined,
    selectedToolPaths: [],
  }
}

export function defaultState(): CuttingSimDisplayState {
  return {
    ...defaultSettings(),
    ...resetState(),
  }
}

export const useCuttingSimDisplayStore = create<
  CuttingSimDisplayState,
  SetState<CuttingSimDisplayState>,
  GetState<CuttingSimDisplayState>,
  StoreApiWithSubscribeWithSelector<CuttingSimDisplayState>
>(subscribeWithSelector((): CuttingSimDisplayState => defaultState()))

export type ProductionControlsDisplayState = {
  toolChangeIndex?: number
  toolId?: string
  machineOffsetsDialogIsOpen: boolean
  productionControlMenu?: ProductionControlMenu
}

export function defaultMachineOffsetsState(): ProductionControlsDisplayState {
  return {
    toolChangeIndex: undefined,
    toolId: undefined,
    machineOffsetsDialogIsOpen: false,
    productionControlMenu: undefined,
  }
}

export const useProductionControlsDisplayStore = create<
  ProductionControlsDisplayState,
  SetState<ProductionControlsDisplayState>,
  GetState<ProductionControlsDisplayState>,
  StoreApiWithSubscribeWithSelector<ProductionControlsDisplayState>
>(subscribeWithSelector((): ProductionControlsDisplayState => defaultMachineOffsetsState()))
