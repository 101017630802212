import { useState } from "react"

import { useTransferCanvas } from "src/hooks/transferCanvas/useTransferCanvas"
import { useEventListener } from "src/hooks/useEventListener"

export interface SceneClickData {
  canvas: HTMLCanvasElement
  camera: THREE.OrthographicCamera
  raycaster: THREE.Raycaster
  mouse: THREE.Vector2
  scene: THREE.Scene
  mouseEvent: MouseEvent
}

const SCENE_POINTER_UP_EVENT = "SCENE_POINTER_UP_EVENT"
const SCENE_POINTER_DOWN_EVENT = "SCENE_POINTER_DOWN_EVENT"

const DOUBLE_CLICK_WINDOW_MS = 500
const LONG_CLICK_WINDOW_MS = 1000

export const useSceneClickEventHandler: (
  onClick?: (event: CustomEvent<SceneClickData>) => void,
  onDoubleClick?: (event: CustomEvent<SceneClickData>) => void,
  onRightClick?: (event: CustomEvent<SceneClickData>) => void,
  onMouseMove?: (event: CustomEvent<SceneClickData>) => void
) => void = (
  onClick?: (event: CustomEvent<SceneClickData>) => void,
  onDoubleClick?: (event: CustomEvent<SceneClickData>) => void,
  onRightClick?: (event: CustomEvent<SceneClickData>) => void,
  onMouseMove?: (event: CustomEvent<SceneClickData>) => void
) => {
  const { ctx } = useTransferCanvas()
  const canvas = ctx.canvas

  const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 })
  const [mouseDownTimestamp, setMouseDownTimestamp] = useState(0)
  const [lastMouseUpTimestamp, setLastMouseUpTimestamp] = useState(0)
  const [buttons, setButtons] = useState(0)

  const resetDoubleClickTimer = (mouseEvent: CustomEvent<SceneClickData>) => {
    setLastMouseUpTimestamp(mouseEvent.timeStamp - DOUBLE_CLICK_WINDOW_MS)
  }

  const onPointerDown = (event: CustomEvent<SceneClickData>) => {
    const {
      detail: { mouseEvent },
    } = event
    if (
      window.navigator.platform.includes("Mac") &&
      mouseEvent.buttons === 1 &&
      mouseEvent.ctrlKey
    ) {
      // Treat this like a right click
      setButtons(2)
    } else {
      setButtons(mouseEvent.buttons)
    }

    setMouseDownTimestamp(mouseEvent.timeStamp)
    setMouseDownPos({ x: mouseEvent.clientX, y: mouseEvent.clientY })
  }

  const onPointerUp = (event: CustomEvent<SceneClickData>) => {
    const {
      detail: { mouseEvent },
    } = event
    const mouseUpPos = { x: mouseEvent.clientX, y: mouseEvent.clientY }
    const mouseUpTimestamp = mouseEvent.timeStamp
    setLastMouseUpTimestamp(mouseUpTimestamp)

    if (
      Math.abs(mouseUpPos.x - mouseDownPos.x) > 10 ||
      Math.abs(mouseUpPos.y - mouseDownPos.y) > 10 ||
      mouseUpTimestamp - mouseDownTimestamp > LONG_CLICK_WINDOW_MS
    ) {
      resetDoubleClickTimer(event)
      return // ignore long still clicks or large moves
    }

    if (onDoubleClick && mouseUpTimestamp - lastMouseUpTimestamp < DOUBLE_CLICK_WINDOW_MS) {
      if (buttons === 1) {
        onDoubleClick(event)
        resetDoubleClickTimer(event)
      }
    } else {
      if (buttons === 1) {
        onClick?.(event)
      } else if (buttons === 2) {
        onRightClick?.(event)
      }
    }
  }

  useScenePointerUpListener(onPointerUp, canvas)
  useScenePointerDownListener(onPointerDown, canvas)
  useMouseMoveListener(onMouseMove, canvas)
}

export const emitScenePointerUp = (data: SceneClickData, canvas: HTMLCanvasElement): void => {
  const event = new CustomEvent<SceneClickData>(SCENE_POINTER_UP_EVENT, { detail: data })
  canvas.dispatchEvent(event)
}

export const emitScenePointerDown = (data: SceneClickData, canvas: HTMLCanvasElement): void => {
  const event = new CustomEvent<SceneClickData>(SCENE_POINTER_DOWN_EVENT, { detail: data })
  canvas.dispatchEvent(event)
}

export const useScenePointerUpListener = (
  handler: (event: CustomEvent<SceneClickData>) => void,
  canvas: HTMLCanvasElement
): void => {
  useEventListener(
    SCENE_POINTER_UP_EVENT,
    (event: Event) => {
      const clickEvent = event as CustomEvent<SceneClickData>
      handler(clickEvent)
    },
    canvas
  )
}

export const useScenePointerDownListener = (
  handler: (event: CustomEvent<SceneClickData>) => void,
  canvas: HTMLCanvasElement
): void => {
  useEventListener(
    SCENE_POINTER_DOWN_EVENT,
    (event: Event) => {
      const clickEvent = event as CustomEvent<SceneClickData>
      handler(clickEvent)
    },
    canvas
  )
}

export const useMouseMoveListener = (
  handler: ((event: CustomEvent<SceneClickData>) => void) | undefined,
  canvas: HTMLCanvasElement
): void => {
  useEventListener(
    "mousemove",
    (event: Event) => {
      const moveEvent = event as CustomEvent<SceneClickData>
      handler && handler(moveEvent)
    },
    canvas
  )
}
