import { DependencyList, useEffect, useMemo, useRef } from "react"
import { useThree } from "@react-three/fiber"
import * as THREE from "three"

import { FLStdOrbitControls } from "src/components/Canvas/Viewer/Camera/FocusOrbitControls/FLStdOrbitControls"
import { createRequiredOptionalContext } from "src/hooks/requiredOptionalContext"

export interface CameraSetup {
  up: [number, number, number]
  position: [number, number, number]
}

export interface TransferCanvasProps {
  scene: THREE.Scene
  ctx: CanvasRenderingContext2D
  camera: THREE.OrthographicCamera
  cameraSetup: CameraSetup
  setCameraSetup: React.Dispatch<React.SetStateAction<CameraSetup>>
  controlsRef: React.MutableRefObject<FLStdOrbitControls | null>
  index: number
}

export const [TransferCanvasContext, useTransferCanvas] = createRequiredOptionalContext<
  TransferCanvasProps
>("TransferCanvasContext")

const INVALID_CANVAS_IDS: { [key: string]: boolean } = {}

export const useTransferInvalidate = (): {
  transferInvalidate: () => void
  isValid: () => boolean
  validate: () => void
} => {
  const { invalidate } = useThree()
  const transferCanvasContext = useTransferCanvas()
  const canvasId = transferCanvasContext.ctx.canvas.id

  return useMemo(() => {
    const transferInvalidate = () => {
      INVALID_CANVAS_IDS[canvasId] = true
      invalidate()
    }
    const isValid = () => {
      return !(INVALID_CANVAS_IDS[canvasId] ?? true)
    }

    const validate = () => {
      INVALID_CANVAS_IDS[canvasId] = false
    }

    return { transferInvalidate, isValid, validate }
  }, [invalidate, canvasId])
}

export const useTransferInvalidateOnChange = (dependencies: DependencyList): void => {
  const { transferInvalidate } = useTransferInvalidate()
  // The following awkward pattern eliminates the need for transferInvalidate as a useEffect dependency;
  // this should be safe since transferInvalidate should never change
  const transferInvalidateRef = useRef(transferInvalidate)
  useEffect(() => (transferInvalidateRef.current = transferInvalidate), [transferInvalidate])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => transferInvalidateRef.current(), dependencies)
}
