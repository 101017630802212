import { Node as SceneNode } from "../../generated/bindings/Node"
export enum Visibility {
  visible,
  transparent,
  hidden,
}

export type ViewCallback = (node: SceneNode) => Visibility | undefined

export const defaultView: ViewCallback = node => {
  if (node.object?.kind === "stock") {
    return Visibility.transparent
  } else {
    return undefined
  }
}
