import React, { FC, lazy, Suspense, useRef, useState } from "react"

import { OptionalChildrenContainerProps } from "src/components/Canvas/TransferCanvas/props"
import { TransferCanvasComponentSpecWithId } from "src/components/Canvas/TransferCanvas/TransferCanvasComponentSpecWithId"
import { createRequiredOptionalContext } from "src/hooks/requiredOptionalContext"

const ProvidedMainCanvas = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ProvidedMainCanvas" */
      "src/components/Canvas/TransferCanvas/MainCanvas"
    )
)

interface MainCanvasProps {
  gl: THREE.WebGLRenderer
  events: { [key: string]: EventListener }
}

type UseMainCanvasProps = {
  props: MainCanvasProps | undefined
  setProps: React.Dispatch<React.SetStateAction<MainCanvasProps | undefined>>
  components: TransferCanvasComponentSpecWithId[]
  setComponents: React.Dispatch<React.SetStateAction<TransferCanvasComponentSpecWithId[]>>
  resizeHandlers: React.MutableRefObject<{
    [canvasId: string]: { [handlerId: string]: () => void } | null
  }>
}

export const [MainCanvasContext, useMainCanvas] = createRequiredOptionalContext<UseMainCanvasProps>(
  "MainCanvasContext"
)

const MainCanvasProvider: FC<OptionalChildrenContainerProps> = ({
  children,
  ...mainCanvasProps
}) => {
  const [props, setProps] = useState<MainCanvasProps | undefined>(undefined)
  const [components, setComponents] = useState<TransferCanvasComponentSpecWithId[]>([])
  const resizeHandlers = useRef({})
  return (
    <MainCanvasContext.Provider
      value={{ setProps, props, components, setComponents, resizeHandlers }}
    >
      {children}
      <div style={{ height: "calc(100% - 50px)" }}>
        <Suspense fallback={<></>}>
          <ProvidedMainCanvas {...mainCanvasProps} />
        </Suspense>
      </div>
    </MainCanvasContext.Provider>
  )
}

export default MainCanvasProvider
