import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"
import { BufferGeometry } from "three"

import { activeProbingSelectors } from "src/store/cam/active"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { ProbeHolder } from "../../Scene/Cam/ProbeHolder/ProbeHolder"

export const ProbeScene: FC<{ showHolder: boolean }> = ({ showHolder }) => {
  // TODO: Could make this more efficient by sharing the configured material and/or geometry buffers
  const probeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)

  const probingDisplayMode = useSelector(viewOptionsSelectors.probingDisplayMode)

  const showProbeScene = probingDisplayMode === DisplayMode.Visible

  const probeSphere = useMemo(() => {
    if (!probeRecord) return undefined

    const sphereDiameter = probeRecord.sphereDiameter
    const geometry = new THREE.SphereBufferGeometry(sphereDiameter / 2, 20, 20)
    geometry.computeVertexNormals()
    const translatedGeometry = geometry.translate(0, sphereDiameter / 2, 0)

    const material = new THREE.MeshStandardMaterial({
      color: new THREE.Color(0x660000).convertSRGBToLinear(),
      roughness: 0.7,
    })
    return new THREE.Mesh(translatedGeometry, material)
  }, [probeRecord])

  const probeShaft = useMemo(() => {
    if (!probeRecord) return undefined
    const {
      sphereDiameter,
      stylusShaftDiameter: shaftDiameter,
      stylusLength: shaftLength,
    } = probeRecord

    let geometry: BufferGeometry = new THREE.CylinderBufferGeometry(
      shaftDiameter / 2,
      shaftDiameter / 2,
      shaftLength + sphereDiameter / 2
    )
    geometry.computeVertexNormals()
    geometry = geometry.translate(0, shaftLength / 2 + sphereDiameter / 2, 0)

    const material = new THREE.MeshStandardMaterial({
      color: new THREE.Color(0x343434).convertSRGBToLinear(),
      roughness: 0.5,
    })
    return new THREE.Mesh(geometry, material)
  }, [probeRecord])

  const userData = { ignoreIntersection: true }

  return (
    <>
      {showProbeScene && (
        <group rotation-x={Math.PI / 2} position-z={-(probeRecord?.gaugeLength ?? 0)}>
          {probeSphere && <primitive userData={userData} object={probeSphere} />}
          {probeShaft && <primitive userData={userData} object={probeShaft} />}
          {showHolder && (
            <group rotation-x={-Math.PI / 2} position-y={probeRecord?.gaugeLength ?? 0}>
              <ProbeHolder />
            </group>
          )}
        </group>
      )}
    </>
  )
}
