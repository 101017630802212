import React, { FC } from "react"
import { HTMLSelect } from "@blueprintjs/core"

import styles from "./MachineInstanceSelector.module.css"

export const MachineInstanceSelector: FC<{
  activeItem: string
  onMachineChanged: (machineInstance: string) => void
}> = ({ activeItem, onMachineChanged }) => {
  const LIST_OF_MACHINE_INSTANCES: string[] = [
    "",
    "DVA1",
    "DVA2",
    "DVA3",
    "DVA4",
    "DVA5",
    "DVA6",
    "DVB1",
    "DVB2",
    "DVB3",
    "DVB4",
    "DVB5",
    "DVB6",
    "DVC1",
    "DVC2",
    "DVC3",
    "DVC4",
    "DVC5",
    "DVC6",
    "G3A1",
    "G3A2",
    "G3A3",
    "G3A4",
    "G350TB1",
    "G350B2",
    "G350B3",
    "G350B4",
    "G550C2",
    "G550C3",
  ]

  return (
    <HTMLSelect
      value={activeItem}
      className={styles.machineInstance}
      onChange={e => {
        onMachineChanged(e.target.value)
      }}
    >
      {LIST_OF_MACHINE_INSTANCES.map(instance => {
        return (
          <option value={instance} key={instance}>
            {instance}
          </option>
        )
      })}
    </HTMLSelect>
  )
}
