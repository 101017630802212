import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import {
  BoreFeatureKindEnum,
  BossFeatureKindEnum,
  MultiLevelBoreFeatureKindEnum,
  MultiLevelBossFeatureKindEnum,
  PointsFeatureKindEnum,
  SphereFeatureKindEnum,
} from "src/client-axios"
import { colorMaterial } from "src/components/Canvas/Viewer/Scene/Cam/materials"
import {
  useTransferCanvas,
  useTransferInvalidate,
} from "src/hooks/transferCanvas/useTransferCanvas"
import { activeOperationSelectors, activeProbingSelectors } from "src/store/cam/active"
import { useGetKinematicsRotation } from "src/store/cam/machine"
import { probingStepDetailSelectors } from "src/store/cam/probing"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import {
  getMultiLevelBoreBossSteps,
  getStepArcs,
  getStepPoints,
  isFeatureDivider,
  PointVariant,
  StepArc,
} from "src/util/cam/probing/stepPoints"
import { Sphere } from "../../Canvas/Viewer/SceneItems/Sphere"

enum SelectionMode {
  SelectedStep,
  SelectedGroup,
  NotSelected,
}

interface PathPoint {
  key: string
  point: THREE.Vector3
  variant: PointVariant
  approach?: number

  sectionId: string | undefined
  groupId: string | undefined
  stepId: string

  visible: boolean
}

type VisiblePathItem<Item> = Item & { visible: boolean }
type ProbingPathStep = VisiblePathItem<ProbingStep> | VisiblePathItem<WcsProbingStep>

export const ProbingPath: FC<{ visible?: boolean }> = ({ visible = true }) => {
  const {
    ctx: { canvas },
    camera,
  } = useTransferCanvas()

  const { transferInvalidate } = useTransferInvalidate()
  const [probingPathSteps, setProbingPathSteps] = useState<ProbingPathStep[]>([])

  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const probeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)
  const machine = useSelector(activeOperationSelectors.selectActiveMachineRecord)
  const defaultApproachDistance = useSelector(
    activeProbingSelectors.selectActiveProbingApproachDist
  )

  const isStepVisible = useSelector(probingStepDetailSelectors.selectStepVisibility)

  const getKinematicsRotation = useGetKinematicsRotation(machine)

  useEffect(() => {
    const probingSteps: (ProbingStep | WcsProbingStep)[] = selectedSection?.steps ?? []
    const probingPathSteps: ProbingPathStep[] = probingSteps.flatMap(step => {
      const visible = isStepVisible(step.id)
      const steps = [{ ...step, visible }]
      if (step.kind === PointsFeatureKindEnum.PointsFeature && step?.steps?.length) {
        steps.push(
          ...step.steps.map(step => ({ ...step, visible: visible || isStepVisible(step.id) }))
        )
      }
      if (
        step.kind === MultiLevelBoreFeatureKindEnum.MultiLevelBoreFeature ||
        step.kind === MultiLevelBossFeatureKindEnum.MultiLevelBossFeature
      ) {
        steps.push(
          ...getMultiLevelBoreBossSteps(step, getKinematicsRotation).map(step => ({
            ...step,
            visible,
          }))
        )
      }
      return steps
    })
    transferInvalidate()
    setProbingPathSteps(probingPathSteps)
  }, [getKinematicsRotation, isStepVisible, selectedSection, transferInvalidate])

  const pathPoints = useMemo(() => {
    const result: PathPoint[] = []

    let sectionId: string | undefined
    let groupId: string | undefined

    probingPathSteps.forEach(step => {
      if (isFeatureDivider(step)) {
        groupId = undefined
      }
      if (step.kind === PointsFeatureKindEnum.PointsFeature) {
        groupId = step.id
        return
      }
      probeRecord &&
        getStepPoints(
          getKinematicsRotation,
          step,
          probeRecord.sphereDiameter,
          defaultApproachDistance
        ).forEach(({ point, stepId, variant }, index) => {
          const key = `${stepId}-${index}`
          result.push({ key, point, variant, sectionId, groupId, stepId, visible: !!step.visible })
        })
    })
    return result
  }, [probingPathSteps, probeRecord, getKinematicsRotation, defaultApproachDistance])

  const stepArcs = useMemo(() => {
    if (!probeRecord) return
    const stepArcs: VisiblePathItem<StepArc>[] = []

    probingPathSteps.forEach(step => {
      if (
        !(
          step.kind === BoreFeatureKindEnum.BoreFeature ||
          step.kind === BossFeatureKindEnum.BossFeature ||
          step.kind === SphereFeatureKindEnum.SphereFeature
        )
      ) {
        return
      }

      const arcs = getStepArcs(
        getKinematicsRotation,
        step,
        probeRecord.sphereDiameter,
        defaultApproachDistance
      )
      stepArcs?.push(...arcs.map(arc => ({ ...arc, visible: step.visible })))
    })
    return stepArcs
  }, [probeRecord, probingPathSteps, getKinematicsRotation, defaultApproachDistance])

  const spheres = useMemo(() => {
    const spheres: VisiblePathItem<{ key: string; color: string; point: THREE.Vector3 }>[] = []
    pathPoints.forEach(pathPoint => {
      const { key, point, visible } = pathPoint
      const color = getSphereColor(pathPoint, undefined, undefined)
      if (pathPoint.variant !== PointVariant.Arc && pathPoint.variant !== PointVariant.Approach) {
        spheres.push({ key, color, point, visible })
      }
    })
    return spheres
  }, [pathPoints])

  const lineSegments = useMemo(() => {
    const lineSegments: VisiblePathItem<{
      key: string
      geometry: THREE.BufferGeometry
      color: string
    }>[] = []
    if (pathPoints.length < 2) return lineSegments

    let prevPoint = pathPoints[0]

    pathPoints.slice(1).forEach(nextPoint => {
      if (
        prevPoint.sectionId === nextPoint.sectionId &&
        !(prevPoint.variant === PointVariant.Arc && nextPoint.variant === PointVariant.Arc)
      ) {
        const key = `${prevPoint.key}-${nextPoint.key}`
        const geometry = new THREE.BufferGeometry().setFromPoints([
          prevPoint.point,
          nextPoint.point,
        ])
        const color = getSegmentColor(prevPoint, nextPoint, undefined, undefined)
        const visible = prevPoint.visible && nextPoint.visible
        lineSegments.push({ key, geometry, color, visible })
      }
      prevPoint = nextPoint
    })
    return lineSegments
  }, [pathPoints])

  if (!visible) return null

  return (
    <>
      <group>
        {spheres.map(({ key, color, point, visible }) => {
          return (
            <Sphere
              key={key}
              position={point}
              radius={1.25}
              color={color}
              visible={visible}
              resize={{ camera, canvas }}
            />
          )
        })}
      </group>

      <group>
        {lineSegments.map(({ key, color, geometry, visible }) => (
          <React.Fragment key={key}>
            <lineSegments geometry={geometry} visible={visible}>
              <lineBasicMaterial {...colorMaterial(color)} />
            </lineSegments>
          </React.Fragment>
        ))}
      </group>

      <group>
        {stepArcs?.map(({ key, line, visible }) => {
          return (
            <React.Fragment key={key}>
              <primitive object={line} visible={visible} />
            </React.Fragment>
          )
        })}
      </group>
    </>
  )
}

const getSegmentColor = (
  pathPoint1: PathPoint,
  pathPoint2: PathPoint,
  selectedGroupId: string | undefined,
  selectedStepId: string | undefined
): string => {
  const selectionMode = Math.max(
    getSelectionMode(pathPoint1, selectedGroupId, selectedStepId),
    getSelectionMode(pathPoint2, selectedGroupId, selectedStepId)
  )
  const variant = Math.min(pathPoint1.variant, pathPoint2.variant)
  return getColor(variant, selectionMode)
}

const getSphereColor = (
  pathPoint: PathPoint,
  selectedGroupId: string | undefined,
  selectedStepId: string | undefined
): string => {
  return getColor(pathPoint.variant, getSelectionMode(pathPoint, selectedGroupId, selectedStepId))
}

const getSelectionMode = (
  pathPoint: PathPoint,
  selectedGroupId: string | undefined,
  selectedStepId: string | undefined
): SelectionMode => {
  let selectionMode = SelectionMode.NotSelected
  if (selectedStepId === pathPoint.stepId) selectionMode = SelectionMode.SelectedStep
  else if (selectedGroupId === pathPoint.groupId) selectionMode = SelectionMode.SelectedGroup
  return selectionMode
}

const getColor = (variant: PointVariant, selection: SelectionMode): string => {
  switch (selection) {
    case SelectionMode.NotSelected:
      switch (variant) {
        case PointVariant.Contact:
          return "#FF0000"
        case PointVariant.Approach:
        case PointVariant.Arc:
          return "#00CCFF"
        case PointVariant.Manual:
          return "#0000FF"
        case PointVariant.Automatic:
          return "#00FF00"
      }
      console.error("Should be unreachable")
      return "#000000"
    case SelectionMode.SelectedGroup:
      switch (variant) {
        case PointVariant.Contact:
          return "#FF2222"
        case PointVariant.Approach:
        case PointVariant.Arc:
          return "#FFFF22"
        case PointVariant.Manual:
          return "#2222FF"
        case PointVariant.Automatic:
          return "#22FF22"
      }
      console.error("Should be unreachable")
      return "#000000"
    case SelectionMode.SelectedStep:
      switch (variant) {
        case PointVariant.Contact:
          return "#FF0000"
        case PointVariant.Approach:
        case PointVariant.Arc:
          return "#DDDD00"
        case PointVariant.Manual:
          return "#0000FF"
        case PointVariant.Automatic:
          return "#00FF00"
      }
      console.error("Should be unreachable")
      return "#000000"
  }
}
