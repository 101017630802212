import React, { FC, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useSpring } from "@react-spring/three"
import * as THREE from "three"

import { TransferAnimatedOrbitControls } from "src/components/Canvas/Viewer/Camera/TransferAnimatedOrbitControls"
import { quat2euler } from "src/components/Canvas/Viewer/geometry"
import { CamSceneLoader } from "src/components/Canvas/Viewer/Scene/Cam/CamScene/CamSceneLoader"
import { getInverseMcsTransformMatrix } from "src/components/Canvas/Viewer/Scene/Cam/operationUtil"
import { SceneModelFragment } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { TRANSITION_CONFIG, TRANSITION_FIXTURE_OPENING } from "src/util/animation/springConfig"

interface AnimatedCamSceneLoaderProps {
  planId?: string
  operationIdx?: number
  partModel?: SceneModelFragment
  partCenter?: THREE.Vector3
  onLoad?: () => void
  minimal?: boolean
  noAnimate?: boolean
  noMachine?: boolean
  controls?: { zoomOnMouse: boolean }
}

export const AnimatedCamSceneLoader: FC<AnimatedCamSceneLoaderProps> = ({
  partModel,
  planId,
  operationIdx,
  onLoad,
  minimal,
  noAnimate,
  noMachine,
  controls,
}) => {
  const { transferInvalidate } = useTransferInvalidate()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )
  const coordinates = useSelector(viewOptionsSelectors.coordinates)
  const reduxShowGrid = useSelector(viewOptionsSelectors.showGrid)

  const showGrid = minimal ? false : reduxShowGrid

  const [sceneEuler, scenePosition] = useMemo(() => {
    let sceneTransformMatrix: THREE.Matrix4

    if (coordinates === "machine" && operation) {
      sceneTransformMatrix = getInverseMcsTransformMatrix(operation.mcs)
    } else {
      sceneTransformMatrix = new THREE.Matrix4()
    }

    const sceneEuler = quat2euler(
      new THREE.Quaternion().setFromRotationMatrix(sceneTransformMatrix)
    )
    const scenePosition = new THREE.Vector3().setFromMatrixPosition(sceneTransformMatrix)

    return [sceneEuler, scenePosition]
  }, [coordinates, operation])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [initialSceneEuler, initialScenePosition] = useMemo(() => [sceneEuler, scenePosition], [])

  const sceneRef = useRef<THREE.Group>()

  const [{ partOpacity, fixturesOpacity, fixturesOpening }] = useSpring(
    {
      from: {
        fixturesOpacity: 0,
        fixturesOpening: TRANSITION_FIXTURE_OPENING,
        partOpacity: 0,
      },
      to: async next => {
        await next({ partOpacity: 1 })
        await next({ fixturesOpacity: 1 })
        await next({ fixturesOpening: 0 })
      },
      onChange: () => transferInvalidate(),
      config: TRANSITION_CONFIG,
      immediate: noAnimate,
    },
    [transferInvalidate]
  )

  const [{ position, rotX, rotY, rotZ }] = useSpring(
    {
      from: {
        position: [initialScenePosition.x, initialScenePosition.y, initialScenePosition.z],
        rotX: initialSceneEuler.x,
        rotY: initialSceneEuler.y,
        rotZ: initialSceneEuler.z,
      },
      to: {
        position: [scenePosition.x, scenePosition.y, scenePosition.z],
        rotX: sceneEuler.x,
        rotY: sceneEuler.y,
        rotZ: sceneEuler.z,
      },
      onChange: () => transferInvalidate(),
      config: TRANSITION_CONFIG,
      immediate: noAnimate,
    },
    [
      initialScenePosition,
      initialSceneEuler,
      sceneEuler.x,
      sceneEuler.y,
      sceneEuler.z,
      scenePosition.x,
      scenePosition.y,
      scenePosition.z,
      transferInvalidate,
    ]
  )

  const [{ partGridOpacity, mcsGridOpacity }] = useSpring(
    {
      from: {
        partGridOpacity: showGrid && coordinates === "part" ? 1 : 0,
        mcsGridOpacity: showGrid && coordinates === "machine" ? 1 : 0,
      },
      to: {
        partGridOpacity: showGrid && coordinates === "part" ? 1 : 0,
        mcsGridOpacity: showGrid && coordinates === "machine" ? 1 : 0,
      },
      onChange: () => transferInvalidate(),
      config: TRANSITION_CONFIG,
      immediate: noAnimate,
    },
    [showGrid, coordinates, noAnimate, transferInvalidate]
  )
  const opacities = useMemo(() => {
    return {
      part: partOpacity,
      fixtures: fixturesOpacity,
      partGrid: partGridOpacity,
      mcsGrid: mcsGridOpacity,
    }
  }, [partOpacity, fixturesOpacity, mcsGridOpacity, partGridOpacity])

  const rotation = useMemo(() => ({ x: rotX, y: rotY, z: rotZ }), [rotX, rotY, rotZ])

  return (
    <group>
      <CamSceneLoader
        partModel={partModel}
        planId={planId}
        operationIdx={operationIdx}
        rotation={rotation}
        position={position}
        opacities={opacities}
        fixturesOpening={fixturesOpening}
        onLoad={onLoad}
        minimal={minimal}
        noMachine={noMachine}
        sceneRef={sceneRef}
      />
      {controls && (
        <TransferAnimatedOrbitControls
          zoomOnMouse={controls.zoomOnMouse}
          enabled={true}
          enableDamping={false}
        />
      )}
    </group>
  )
}
