import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, ButtonGroup, Tab, Tabs } from "@blueprintjs/core"
import { AxiosError } from "axios"

import { TransferCanvas } from "src/components/Canvas/TransferCanvas/TransferCanvas"
import { OperationCardScene } from "src/components/Canvas/Viewer/Scene/Cam/CamScene/OperationCardScene"
import { sendEvent } from "src/components/Websocket/Websocket"
import { CamJobDetailsFragment } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { activeActions } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { useAppDispatch } from "src/store/rootStore"
import { viewerModalSelectors } from "src/store/ui/viewerModal"
import { OverviewTabPanel } from "../OverviewTabPanel/OverviewTabPanel"

import styles from "./PlanProposalsTabPanel.module.css"

export const PlanProposalsTabPanel: FC<{
  jobId: string
  camJob?: CamJobDetailsFragment
  onJobDetailsChange: () => void
}> = ({ jobId, camJob, onJobDetailsChange }) => {
  const dispatch = useAppDispatch()

  const { plansApi } = useApi()
  const errorToast = useNetworkErrorToast()
  const viewerModalIsOpen = useSelector(viewerModalSelectors.selectIsOpen)

  const storedPlanEntities = useSelector(storedPlansSelectors.selectStoredPlanEntities)

  // TODO: Fix this hack for sorting by final strategy
  const proposedPlans = useMemo(
    () =>
      Array.from(camJob?.plans.filter(plan => plan.isProposal) ?? []).sort((a, b) => {
        const aLabel = storedPlanEntities[a.id]?.plan.label ?? ""
        const bLabel = storedPlanEntities[b.id]?.plan.label ?? ""

        return aLabel > bLabel ? 1 : aLabel < bLabel ? -1 : 0
      }),
    [storedPlanEntities, camJob]
  )

  const [selectedTabId, setSelectedTabId] = useState<string | undefined>()

  useEffect(() => {
    if (selectedTabId === undefined && proposedPlans.length > 0 && proposedPlans[0].id) {
      setSelectedTabId(proposedPlans[0].id)
    }
  }, [selectedTabId, setSelectedTabId, proposedPlans])

  const handleStartWithPlanClick = () => {
    if (selectedTabId) {
      sendEvent("startWithGeneratedPlan", { planId: selectedTabId })
      plansApi
        .duplicatePlan(selectedTabId)
        .then(response => {
          onJobDetailsChange()
          // This timeout is needed as otherwise the canvas/scene teardown will throw an exception
          setTimeout(() => {
            dispatch(activeActions.setActivePlanId(response.data))
          }, 50)
        })
        .catch((error: AxiosError) => {
          const message = `Error requesting plan creation for job ${jobId}`
          errorToast(error, message)
        })
    }
  }

  const handleCreatePlanClick = () => {
    sendEvent("createEmptyPlan")
    plansApi
      .createPlan(jobId)
      .then(response => {
        onJobDetailsChange()
        setTimeout(() => {
          dispatch(activeActions.setActivePlanId(response.data))
        }, 50)
      })
      .catch((error: AxiosError) => {
        const message = `Error requesting plan creation for job ${jobId}`
        errorToast(error, message)
      })
  }

  return (
    <div>
      <Tabs
        renderActiveTabPanelOnly
        id="planTabs"
        onChange={tab => setSelectedTabId(tab + "")}
        selectedTabId={selectedTabId}
      >
        {camJob &&
          proposedPlans.map((plan, i) => {
            const planLabel = storedPlanEntities[plan.id]?.plan.label ?? `Plan ${i + 1}`
            const opLength = storedPlanEntities[plan.id]?.plan?.operations?.length
            const operationIdx = opLength ? opLength - 1 : undefined
            return (
              <Tab
                id={plan.id}
                title={
                  <div className={styles.prototypeLabel}>
                    <TransferCanvas pauseRender={viewerModalIsOpen}>
                      <OperationCardScene
                        {...{
                          partModel: camJob?.model ?? undefined,
                          planId: plan.id,
                          operationIdx,
                        }}
                      />
                    </TransferCanvas>
                    {planLabel}
                  </div>
                }
                panel={
                  <OverviewTabPanel
                    minimal={true}
                    planId={plan.id}
                    camJob={camJob}
                    onJobDetailsChange={() => onJobDetailsChange()}
                  />
                }
                key={plan.id}
              />
            )
          })}
      </Tabs>
      <ButtonGroup>
        {selectedTabId && (
          <Button icon="add-to-artifact" intent="success" onClick={handleStartWithPlanClick}>
            Start with {storedPlanEntities[selectedTabId]?.plan.label ?? `Plan`}
          </Button>
        )}
        <Button icon="small-plus" onClick={handleCreatePlanClick}>
          Create Empty Plan
        </Button>
      </ButtonGroup>
    </div>
  )
}
