import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { a, to, useSpring, useTransition } from "@react-spring/three"
import * as THREE from "three"

import {
  ClampedDovetailParams,
  OutputStock,
  ParametricStockShapeRectangularKindEnum,
  ParametricStockShapeRoundKindEnum,
  StockKind,
} from "src/client-axios"
import { intrinsicZYXRotation, quat2euler } from "src/components/Canvas/Viewer/geometry"
import { AnimatedPartialTransformNode } from "src/components/Canvas/Viewer/Scene/AnimatedPartialTransformNode"
import {
  ESTIMATED_OUTPUT_STOCK_MATERIAL,
  INPUT_STOCK_MATERIAL,
  MaterialData,
  SIMULATED_OUTPUT_STOCK_MATERIAL,
  STOCK_WIRE_MATERIAL,
  TRANSPARENT_MATERIAL,
} from "src/components/Canvas/Viewer/Scene/Cam/materials"
import { GltfIdModel } from "src/components/Canvas/Viewer/SceneItems/GltfModelUtils"
import { UserData } from "src/components/Canvas/Viewer/SceneItems/GltfUrlModel"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { InputStock } from "src/store/cam/storedPlans"
import { fixturesSelectors } from "src/store/config/fixtures"
import { DisplayMode, DisplayStock, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { MaybeFluidValue, mergeValues } from "src/util/animation/mergeValues"
import { TRANSITION_CONFIG } from "src/util/animation/springConfig"
import { CylinderData, CylinderOrientation } from "src/util/geometry/cylinder"
import { AnimatedMovementNode } from "../AnimatedMovementNode"
import { FixtureBody } from "./FixtureChoiceScene"

type TRANSITION_KEY = string

interface StocksSceneProps {
  outputStock?: OutputStock
  inputStock?: InputStock
  operationOutputStock?: OutputStock
  opacity?: MaybeFluidValue<number>
  noAnimate?: boolean
  operationIdx?: number
  displayStock?: DisplayStock
  displayMode?: DisplayMode
}

export const StocksScene: FC<StocksSceneProps> = ({
  outputStock,
  inputStock,
  operationOutputStock,
  opacity,
  operationIdx,
  displayStock,
  displayMode,
}) => {
  const { transferInvalidate } = useTransferInvalidate()
  const stock = outputStock ?? inputStock

  const reduxStockDisplayMode = useSelector(viewOptionsSelectors.stockDisplayMode)
  const reduxStockToDisplay = useSelector(viewOptionsSelectors.stockToDisplay)
  const stockDisplayMode = displayMode ?? reduxStockDisplayMode
  const stockToDisplay = displayStock ?? reduxStockToDisplay

  const transitions = useTransition(stock ? [stock] : [], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    key: (stock: OutputStock | InputStock | undefined) => stock?.kind ?? "undefined",
    leave: { opacity: 0 },
    onChange: () => transferInvalidate(),
    config: TRANSITION_CONFIG,
  })

  return transitions((values, _item: OutputStock | InputStock | undefined, transition) => {
    let combinedOpacity
    if (typeof opacity === "number") {
      combinedOpacity = opacity
    } else if (opacity) {
      combinedOpacity = to([opacity, values.opacity], (o1, o2) => Math.min(o1, o2))
    } else {
      combinedOpacity = values.opacity
    }

    return (
      <StockScene
        operationIdx={operationIdx}
        outputStock={outputStock}
        inputStock={inputStock}
        opacity={combinedOpacity}
        stockDisplayMode={stockDisplayMode}
        stockToDisplay={stockToDisplay}
        key={transition.key as TRANSITION_KEY}
        operationOutputStock={operationOutputStock}
      />
    )
  })
}

interface StockSceneProps {
  stockDisplayMode: DisplayMode
  stockToDisplay: DisplayStock
  inputStock?: InputStock
  outputStock?: OutputStock
  operationOutputStock?: OutputStock
  opacity?: MaybeFluidValue<number>
  operationIdx?: number
}

const StockScene: FC<StockSceneProps> = ({
  stockDisplayMode,
  stockToDisplay,
  inputStock,
  outputStock,
  opacity,
  operationOutputStock,
  operationIdx,
}) => {
  const materialOpacity =
    stockDisplayMode === DisplayMode.Visible ? 1.0 : INPUT_STOCK_MATERIAL.opacity
  const inputMaterial = { ...INPUT_STOCK_MATERIAL, opacity: materialOpacity }

  const { transferInvalidate } = useTransferInvalidate()
  transferInvalidate()

  switch (stockToDisplay) {
    case DisplayStock.Input:
      return (
        <InputStockScene
          operationIdx={operationIdx}
          material={inputMaterial}
          stockDisplayMode={stockDisplayMode}
          inputStock={inputStock}
          operationOutputStock={operationOutputStock}
          opacity={opacity}
        />
      )
    case DisplayStock.Output:
      return (
        <OutputStockScene
          operationIdx={operationIdx}
          stockDisplayMode={stockDisplayMode}
          inputStock={inputStock}
          outputStock={outputStock}
          opacity={opacity}
        />
      )
  }
}

const InputStockScene: FC<{
  material: MaterialData
  stockDisplayMode: DisplayMode
  inputStock?: InputStock
  operationOutputStock?: OutputStock
  opacity?: MaybeFluidValue<number>
  dovetailSide?: DovetailSide
  dovetailParams?: ClampedDovetailParams
  operationIdx?: number
}> = ({
  material,
  stockDisplayMode,
  inputStock,
  opacity,
  dovetailSide,
  dovetailParams,
  operationOutputStock,
  operationIdx,
}) => {
  if (inputStock) {
    return (
      <PlanInputStockScene
        displayMode={stockDisplayMode}
        material={material}
        inputStock={inputStock}
        opacity={opacity}
        dovetailSide={dovetailSide}
        dovetailParams={dovetailParams}
        operationOutputStock={operationOutputStock}
        operationIdx={operationIdx}
      />
    )
  }

  return null
}

const OutputStockScene: FC<{
  inputStock?: InputStock
  outputStock?: OutputStock
  stockDisplayMode: DisplayMode
  opacity?: MaybeFluidValue<number>
  operationIdx?: number
}> = ({ outputStock, stockDisplayMode, opacity, inputStock, operationIdx }) => {
  const transparent = stockDisplayMode === DisplayMode.Transparent
  const displayOpacity = transparent ? ESTIMATED_OUTPUT_STOCK_MATERIAL.opacity : 1.0

  const modelId = outputStock?.modelId
  const baseMaterial =
    outputStock?.kind === StockKind.ESTIMATED
      ? ESTIMATED_OUTPUT_STOCK_MATERIAL
      : SIMULATED_OUTPUT_STOCK_MATERIAL
  const material = { ...baseMaterial, opacity: displayOpacity, transparent }

  const fixtureRecordsMap = useSelector(fixturesSelectors.selectFixtureRecordsMap)

  let fixtures

  if (inputStock?.kind === "fixture") {
    const fixtureRecord = fixtureRecordsMap[inputStock.fixtureId]
    if (!fixtureRecord) {
      return null
    }
    const selectedParameter = inputStock.parameter ?? 0
    const initialParameter = fixtureRecord.parameter?.initial ?? 0
    const deltaParameter = selectedParameter - initialParameter

    fixtures = Object.entries(fixtureRecord?.bodies).map(([label, body]) => {
      if (!body.isCustomMillable && operationIdx !== undefined) {
        return (
          <FixtureBody
            deltaParameter={deltaParameter}
            body={body}
            key={`${inputStock.fixtureId}-${label}`}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      {modelId && stockDisplayMode !== DisplayMode.Hidden && (
        <GltfIdModel
          userData={{ isStock: true }}
          modelId={modelId}
          opacity={opacity}
          // showSharpEdges={true}
          material={material}
        />
      )}

      {fixtures}
    </>
  )
}

const PlanInputStockScene: FC<{
  displayMode: DisplayMode
  material: MaterialData
  inputStock: InputStock
  opacity?: MaybeFluidValue<number>
  dovetailSide?: DovetailSide
  dovetailParams?: ClampedDovetailParams
  noAnimate?: boolean
  operationOutputStock?: OutputStock
  operationIdx?: number
}> = ({
  material,
  displayMode,
  inputStock,
  opacity,
  dovetailSide,
  dovetailParams,
  noAnimate,
  operationOutputStock,
  operationIdx,
}) => {
  const { transferInvalidate } = useTransferInvalidate()
  const transform = inputStock.transform

  const targetEuler = useMemo(() => {
    const targetRotation = { i: transform.i, j: transform.j, k: transform.k }
    return quat2euler(intrinsicZYXRotation(targetRotation))
  }, [transform.i, transform.j, transform.k])

  const [{ position, rotation }] = useSpring(
    {
      to: {
        position: [transform.x, transform.y, transform.z] as const,
        rotation: [targetEuler.x, targetEuler.y, targetEuler.z] as const,
      },
      config: TRANSITION_CONFIG,
      immediate: noAnimate,
      onChange: () => transferInvalidate(),
    },
    [transform.x, transform.y, transform.z, targetEuler, transferInvalidate]
  )

  return (
    <a.group
      position={(position as unknown) as [x: number, y: number, z: number]}
      rotation={(rotation as unknown) as [x: number, y: number, z: number]}
    >
      <BasePlanInputStockScene
        operationIdx={operationIdx}
        displayMode={displayMode}
        material={material}
        inputStock={inputStock}
        opacity={opacity}
        dovetailSide={dovetailSide}
        dovetailParams={dovetailParams}
        operationOutputStock={operationOutputStock}
      />
    </a.group>
  )
}

const BasePlanInputStockScene: FC<{
  displayMode: DisplayMode
  material: MaterialData
  inputStock: InputStock
  opacity?: MaybeFluidValue<number>
  dovetailSide?: DovetailSide
  dovetailParams?: ClampedDovetailParams
  operationOutputStock?: OutputStock
  operationIdx?: number
}> = ({
  displayMode,
  material,
  inputStock,
  opacity,
  dovetailSide,
  dovetailParams,
  operationOutputStock,
  operationIdx,
}) => {
  const fixtureRecordsMap = useSelector(fixturesSelectors.selectFixtureRecordsMap)
  if (!inputStock) return null

  if (inputStock?.kind === "fixture") {
    const fixtureRecord = fixtureRecordsMap[inputStock.fixtureId]
    if (!fixtureRecord) {
      return null
    }
    const selectedParameter = inputStock.parameter ?? 0
    const initialParameter = fixtureRecord.parameter?.initial ?? 0
    const deltaParameter = selectedParameter - initialParameter

    return (
      <>
        {Object.entries(fixtureRecord.bodies).map(([label, body]) => {
          if (body.isCustomMillable) {
            return (
              <React.Fragment key={`${inputStock.fixtureId}-${label}`}>
                {displayMode !== DisplayMode.Hidden && (
                  <AnimatedMovementNode deltaParameter={deltaParameter} movement={body.movement}>
                    <AnimatedPartialTransformNode transform={body.transform}>
                      <GltfIdModel
                        modelId={body.model}
                        material={material}
                        userData={{ isStock: true }}
                        // setSceneBox={b => console.log(b)}
                      />
                    </AnimatedPartialTransformNode>
                  </AnimatedMovementNode>
                )}
              </React.Fragment>
            )
          } else if (operationIdx !== undefined) {
            return (
              <FixtureBody
                body={body}
                key={`${inputStock.fixtureId}-${label}`}
                deltaParameter={deltaParameter}
              />
            )
          } else {
            return null
          }
        })}
      </>
    )
  } else if (displayMode === DisplayMode.Hidden) return null
  else if (inputStock?.kind === "operation_output" && operationOutputStock) {
    return (
      <OutputStockScene
        operationIdx={operationIdx}
        stockDisplayMode={displayMode}
        outputStock={operationOutputStock}
        opacity={opacity}
      />
    )
  } else if (inputStock?.kind === "model") {
    return (
      <GltfIdModel
        userData={{ isStock: true }}
        modelId={inputStock.modelId}
        opacity={opacity}
        // showSharpEdges={true}
        material={material}
      />
    )
  } else if (
    inputStock?.kind === "parametric" &&
    inputStock.shape.kind === ParametricStockShapeRectangularKindEnum.Rectangular
  ) {
    return dovetailParams && dovetailSide ? (
      <DovetailPrismStock
        displayMode={displayMode}
        opacity={opacity}
        material={material}
        extents={[inputStock.shape.x, inputStock.shape.y, inputStock.cutLength]}
        side={dovetailSide}
        params={dovetailParams}
        key={`product-stock-dovetail-prism`}
      />
    ) : (
      <PrismStock
        displayMode={displayMode}
        opacity={opacity}
        material={material}
        extents={[inputStock.shape.x, inputStock.shape.y, inputStock.cutLength]}
        key={`product-stock-prism`}
      />
    )
  } else if (
    inputStock?.kind === "parametric" &&
    inputStock.shape.kind === ParametricStockShapeRoundKindEnum.Round
  ) {
    return dovetailParams && dovetailSide ? (
      <DovetailCylinderStock
        displayMode={displayMode}
        opacity={opacity}
        material={material}
        diameter={inputStock.shape.diameter ?? 0}
        extentZ={inputStock.cutLength ?? 0}
        side={dovetailSide}
        params={dovetailParams}
        key={`product-stock-dovetail-cylinder`}
      />
    ) : (
      <CylinderStock
        displayMode={displayMode}
        opacity={opacity}
        material={material}
        diameter={inputStock.shape.diameter ?? 0}
        extentZ={inputStock.cutLength ?? 0}
        key={`product-stock-cylinder`}
      />
    )
  }
  return null
}

interface PrismStockProps {
  material: MaterialData
  extents: readonly [number, number, number]
  opacity?: MaybeFluidValue<number>
  displayMode: DisplayMode
  isMachiningEnvelope?: boolean
  wireOpacityMultiplier?: number
}

export const PrismStock: FC<PrismStockProps> = ({
  material,
  extents,
  opacity,
  displayMode,
  isMachiningEnvelope,
  wireOpacityMultiplier = 1.0,
}) => {
  const geom = useMemo(() => new THREE.BoxBufferGeometry(...extents), [extents])
  const mergedOpacity = mergeValues(opacity, INPUT_STOCK_MATERIAL.opacity)
  const mergedWireOpacity = mergeValues(mergedOpacity, wireOpacityMultiplier)

  const transparent = displayMode === DisplayMode.Transparent
  const userData: UserData = useMemo(
    () => (isMachiningEnvelope ? { ignoreIntersection: true } : { isStock: true }),
    [isMachiningEnvelope]
  )

  const meshMaterial = (
    // @ts-expect-error works
    <a.meshStandardMaterial
      attach="material"
      {...material}
      {...(transparent ? TRANSPARENT_MATERIAL : {})}
      opacity={mergedOpacity}
      transparent={transparent}
    />
  )

  return (
    <>
      <mesh userData={userData} geometry={geom}>
        {meshMaterial}
      </mesh>
      <lineSegments>
        <edgesGeometry args={[geom]} />
        <a.lineBasicMaterial
          {...TRANSPARENT_MATERIAL}
          {...STOCK_WIRE_MATERIAL}
          opacity={mergedWireOpacity}
          transparent={true}
        />
      </lineSegments>
    </>
  )
}

interface CylinderStockProps {
  displayMode: DisplayMode
  material: MaterialData
  diameter: number
  extentZ: number
  opacity?: MaybeFluidValue<number>
  wireOpacityMultiplier?: number
}

const CylinderStock: FC<CylinderStockProps> = ({
  displayMode,
  material,
  diameter: d,
  extentZ,
  opacity,
  wireOpacityMultiplier = 1.0,
}) => {
  // The cylinder points up the x axis so we rotate it 90 degrees to have it align with z being up
  const cylinderOrientation = useMemo(() => {
    return new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), (90 * Math.PI) / 180)
  }, [])

  const circleGeometry = useMemo(() => new THREE.RingGeometry(d / 2, d / 2, 64), [d])

  const lineGeometry = useMemo(() => {
    return new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, -d / 2, -extentZ / 2),
      new THREE.Vector3(0, -d / 2, extentZ / 2),
    ])
  }, [extentZ, d])

  const cylinder: CylinderData = {
    face_idx: -1,
    direction: [0, 1, 0],
    location: [0, 0, 0],
    diameter: d,
    min_angle: 0,
    max_angle: 2 * Math.PI,
    area: Math.PI * d * extentZ,
    height: extentZ,
    orientation: CylinderOrientation.Forward,
  }
  const transparent = displayMode === DisplayMode.Transparent

  const mergedOpacity = mergeValues(opacity, INPUT_STOCK_MATERIAL.opacity)
  const mergedWireOpacity = mergeValues(mergedOpacity, wireOpacityMultiplier)
  return (
    <>
      <mesh userData={{ isStock: true, cylinder }} quaternion={cylinderOrientation}>
        <a.meshStandardMaterial
          {...material}
          opacity={mergedOpacity}
          {...(transparent ? TRANSPARENT_MATERIAL : {})}
          transparent={transparent}
        />
        <cylinderBufferGeometry args={[d / 2, d / 2, extentZ, 50, 2, true]} />
      </mesh>
      <mesh userData={{ isStock: true }} position={[0, 0, extentZ / 2]}>
        <a.meshStandardMaterial
          {...material}
          opacity={mergedOpacity}
          {...(transparent ? TRANSPARENT_MATERIAL : {})}
          transparent={transparent}
        />
        <circleBufferGeometry args={[d / 2, 50, 0, 2 * Math.PI]} />
      </mesh>
      <mesh userData={{ isStock: true }} position={[0, 0, -extentZ / 2]}>
        <a.meshStandardMaterial
          {...material}
          opacity={mergedOpacity}
          {...(transparent ? TRANSPARENT_MATERIAL : {})}
          transparent={transparent}
        />
        <circleBufferGeometry args={[d / 2, 50, 0, 2 * Math.PI]} />
      </mesh>
      <lineLoop geometry={circleGeometry} position={[0, 0, extentZ / 2]}>
        <a.lineBasicMaterial
          {...TRANSPARENT_MATERIAL}
          {...STOCK_WIRE_MATERIAL}
          opacity={mergedWireOpacity}
        />
      </lineLoop>
      <lineLoop geometry={circleGeometry} position={[0, 0, -extentZ / 2]}>
        <a.lineBasicMaterial
          {...TRANSPARENT_MATERIAL}
          {...STOCK_WIRE_MATERIAL}
          opacity={mergedWireOpacity}
        />
      </lineLoop>
      <lineSegments geometry={lineGeometry}>
        <a.lineBasicMaterial
          {...TRANSPARENT_MATERIAL}
          {...STOCK_WIRE_MATERIAL}
          opacity={mergedWireOpacity}
        />
      </lineSegments>
    </>
  )
}

type DovetailSide =
  | "+xy"
  | "+xz"
  | "+yx"
  | "+yz"
  | "+zx"
  | "+zy"
  | "-xy"
  | "-xz"
  | "-yx"
  | "-yz"
  | "-zx"
  | "-zy"

const getDovetailQuaternion = (side: DovetailSide): THREE.Quaternion => {
  const quaternion = new THREE.Quaternion()
  if (side === "-zx") quaternion.setFromEuler(new THREE.Euler(Math.PI, 0, Math.PI / 2))
  else if (side === "+zx") quaternion.setFromEuler(new THREE.Euler(0, 0, Math.PI / 2))
  else if (side === "-zy") quaternion.setFromEuler(new THREE.Euler(Math.PI, 0, 0))
  else if (side === "+zy") quaternion.setFromEuler(new THREE.Euler(0, 0, 0))
  else if (side === "-yx") quaternion.setFromEuler(new THREE.Euler(-Math.PI / 2, Math.PI / 2, 0))
  else if (side === "+yx") quaternion.setFromEuler(new THREE.Euler(Math.PI / 2, Math.PI / 2, 0))
  else if (side === "-yz") quaternion.setFromEuler(new THREE.Euler(-Math.PI / 2, 0, 0))
  else if (side === "+yz") quaternion.setFromEuler(new THREE.Euler(Math.PI / 2, 0, 0))
  else if (side === "-xy") quaternion.setFromEuler(new THREE.Euler(0, Math.PI / 2, 0))
  else if (side === "+xy") quaternion.setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0))
  else if (side === "-xz") quaternion.setFromEuler(new THREE.Euler(-Math.PI / 2, 0, Math.PI / 2))
  else if (side === "+xz") quaternion.setFromEuler(new THREE.Euler(Math.PI / 2, 0, Math.PI / 2))
  return quaternion
}

interface DovetailPrismStockProps {
  displayMode: DisplayMode
  material: MaterialData
  extents: [number, number, number]
  side: DovetailSide
  params: ClampedDovetailParams
  opacity?: MaybeFluidValue<number>
  wireOpacityMultiplier?: number
}

const DovetailPrismStock: FC<DovetailPrismStockProps> = ({
  displayMode,
  material,
  extents,
  side,
  params,
  opacity,
  wireOpacityMultiplier,
}) => {
  // TODO: the dovetail side should be specified as part of the InputStock or similar
  const quaternion = getDovetailQuaternion(side)
  let [x, y, z] = extents

  const v = new THREE.Vector3(x, y, z)
  v.applyQuaternion(quaternion)
  x = Math.abs(v.x)
  y = Math.abs(v.y)
  z = Math.abs(v.z)

  const [minDovetailX, maxDovetailX] = useMemo(() => getDovetailX(params), [params])

  const outline = useMemo(() => {
    const xRadius = x / 2
    const zRadius = z / 2

    const dovetailBaseZ = zRadius - params.height
    const dovetailFlatZ = zRadius - params.squareHeight
    return [
      new THREE.Vector2(-zRadius, xRadius),
      new THREE.Vector2(dovetailBaseZ, xRadius),
      new THREE.Vector2(dovetailBaseZ, minDovetailX),
      new THREE.Vector2(dovetailFlatZ, maxDovetailX),
      new THREE.Vector2(zRadius, maxDovetailX),
      new THREE.Vector2(zRadius, -maxDovetailX),
      new THREE.Vector2(dovetailFlatZ, -maxDovetailX),
      new THREE.Vector2(dovetailBaseZ, -minDovetailX),
      new THREE.Vector2(dovetailBaseZ, -xRadius),
      new THREE.Vector2(-zRadius, -xRadius),
      new THREE.Vector2(-zRadius, xRadius),
    ]
  }, [x, z, minDovetailX, maxDovetailX, params])

  /**
   * extrudeGeometry will have extents (z, x, y)
   */
  const extrudeGeometry = useMemo(() => getExtrudedGeometry(outline, y), [outline, y])
  const lineGeometry = useMemo(() => getExtrudedLineGeometry(outline, y), [outline, y])

  const transparent = displayMode === DisplayMode.Transparent

  const mergedOpacity = mergeValues(opacity, INPUT_STOCK_MATERIAL.opacity)
  const mergedWireOpacity = mergeValues(mergedOpacity, wireOpacityMultiplier)
  return (
    <group quaternion={quaternion.invert()}>
      <group rotation={new THREE.Euler(Math.PI / 2, 0, Math.PI / 2)}>
        <mesh userData={{ isStock: true }} geometry={extrudeGeometry}>
          <a.meshStandardMaterial
            attach="material"
            {...material}
            {...(transparent ? TRANSPARENT_MATERIAL : {})}
            transparent={transparent}
            opacity={mergedOpacity}
          />
        </mesh>
        <lineSegments geometry={lineGeometry}>
          <a.lineBasicMaterial
            attach="material"
            {...TRANSPARENT_MATERIAL}
            {...STOCK_WIRE_MATERIAL}
            opacity={mergedWireOpacity}
          />
        </lineSegments>
      </group>
    </group>
  )
}

interface DovetailCylinderStockProps {
  displayMode: DisplayMode
  material: MaterialData
  diameter: number
  extentZ: number
  side: DovetailSide
  params: ClampedDovetailParams
  opacity?: MaybeFluidValue<number>
  wireOpacityMultiplier?: number
}

const DovetailCylinderStock: FC<DovetailCylinderStockProps> = ({
  displayMode,
  material,
  diameter,
  extentZ,
  side,
  params,
  opacity,
  wireOpacityMultiplier = 1.0,
}) => {
  const quaternion = getDovetailQuaternion(side)
  const v = new THREE.Vector3(0, 0, -params.height / 2)
  v.applyQuaternion(quaternion)
  const { x, y, z } = v

  const [minDovetailX, maxDovetailX] = useMemo(() => getDovetailX(params), [params])

  const dovetailOutline = useMemo(() => {
    const zRadius = extentZ / 2
    const dovetailBaseZ = zRadius - params.height
    const dovetailFlatZ = zRadius - params.squareHeight
    return [
      new THREE.Vector2(dovetailBaseZ, minDovetailX),
      new THREE.Vector2(dovetailFlatZ, maxDovetailX),
      new THREE.Vector2(zRadius, maxDovetailX),
      new THREE.Vector2(zRadius, -maxDovetailX),
      new THREE.Vector2(dovetailFlatZ, -maxDovetailX),
      new THREE.Vector2(dovetailBaseZ, -minDovetailX),
    ]
  }, [extentZ, minDovetailX, maxDovetailX, params])

  const depth = 2 * Math.sqrt((diameter / 2) * (diameter / 2) - maxDovetailX * maxDovetailX)
  const dovetailGeometry = useMemo(() => {
    return getExtrudedGeometry(dovetailOutline, depth)
  }, [depth, dovetailOutline])

  const lineGeometry = useMemo(() => getExtrudedLineGeometry(dovetailOutline, depth), [
    depth,
    dovetailOutline,
  ])

  const mergedOpacity = mergeValues(opacity, INPUT_STOCK_MATERIAL.opacity)
  const mergedWireOpacity = mergeValues(mergedOpacity, wireOpacityMultiplier)

  if (!["+zx", "+zy", "-zx", "-zy"].includes(side)) {
    console.error("Unsupported side for cylinder stock:", side)
    return (
      <CylinderStock
        displayMode={displayMode}
        material={material}
        diameter={diameter}
        extentZ={extentZ}
        opacity={opacity}
      />
    )
  }
  return (
    <>
      <group position={[x, y, z]}>
        <CylinderStock
          displayMode={displayMode}
          material={material}
          diameter={diameter}
          extentZ={extentZ - params.height}
          opacity={opacity}
        />
      </group>
      <group quaternion={quaternion.invert()}>
        <group rotation={new THREE.Euler(Math.PI / 2, 0, Math.PI / 2)}>
          <mesh userData={{ isStock: true }} geometry={dovetailGeometry}>
            <a.meshStandardMaterial
              attach="material"
              {...material}
              {...TRANSPARENT_MATERIAL}
              opacity={mergedOpacity}
            />
          </mesh>
          <lineSegments geometry={lineGeometry}>
            <a.lineBasicMaterial
              attach="material"
              {...TRANSPARENT_MATERIAL}
              {...STOCK_WIRE_MATERIAL}
              opacity={mergedWireOpacity}
            />
          </lineSegments>
        </group>
      </group>
    </>
  )
}

const getExtrudedGeometry = (
  xyProfile: THREE.Vector2[],
  zExtent: number
): THREE.ExtrudeBufferGeometry => {
  const shape = new THREE.Shape()
  shape.setFromPoints(xyProfile)
  const extrudeSettings = { depth: zExtent, steps: 3, bevelEnabled: false }
  const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings)
  geometry.translate(0, 0, -zExtent / 2)
  return geometry
}

const getExtrudedLineGeometry = (
  xyProfile: THREE.Vector2[],
  zExtent: number
): THREE.BufferGeometry => {
  const points = xyProfile.flatMap((v: THREE.Vector2) => [
    new THREE.Vector3(v.x, v.y, -zExtent / 2),
    new THREE.Vector3(v.x, v.y, zExtent / 2),
  ])
  const allPoints = [...points]
  for (let i = 0; i < points.length; i++) {
    const v1 = points[i]
    const v2 = points[(i + 1) % points.length]
    allPoints.push(new THREE.Vector3(v1.x, v1.y, -zExtent / 2))
    allPoints.push(new THREE.Vector3(v2.x, v2.y, -zExtent / 2))
    allPoints.push(new THREE.Vector3(v1.x, v1.y, zExtent / 2))
    allPoints.push(new THREE.Vector3(v2.x, v2.y, zExtent / 2))
    allPoints.push(new THREE.Vector3(v1.x, v1.y, -zExtent / 2))
    allPoints.push(new THREE.Vector3(v1.x, v1.y, zExtent / 2))
  }
  return new THREE.BufferGeometry().setFromPoints(allPoints)
}

const getDovetailX = (params: ClampedDovetailParams): [number, number] => {
  const pinRadius = params.pinDiameter / 2
  const pinCenterX = params.measurementOverPins / 2 - pinRadius
  const minDovetailX = pinCenterX - pinRadius / Math.tan((params.angleDegrees * Math.PI) / 360)
  const maxDovetailX =
    minDovetailX +
    (params.height - params.squareHeight) / Math.tan((params.angleDegrees * Math.PI) / 180)
  return [minDovetailX, maxDovetailX]
}
