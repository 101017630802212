import React, { ChangeEvent, FC, useState } from "react"
import { Button, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core"

import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"

import styles from "./GuardrailModal.module.css"

export const GuardrailModal: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  close: () => void
}> = ({ planId, operationIdx, isOpen, close }) => {
  const [notes, setNotes] = useState<string>()
  const toaster = useToaster()

  const { guardrailApi } = useApi()

  const handleSubmit = (_e: React.MouseEvent<HTMLElement>) => {
    if (notes?.length) {
      guardrailApi
        .updateGuardrailTestCase(planId, operationIdx, notes)
        .then(() => {
          toaster.show({ message: "Issue reported successfully", intent: "success" })
        })
        .catch((error: unknown) => {
          console.error({ error })
          toaster.show({ message: "Failed to report issue", intent: "danger" })
        })
    }
  }

  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    close()
    handleSubmit(e)
    setNotes("")
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.currentTarget.value)
  }

  const handleCancel = () => {
    close()
    setNotes("")
  }

  const bodyComponent = (
    <>
      <h5 className={"bp3-heading"}>Notes</h5>
      <TextArea
        growVertically={true}
        large={true}
        intent={Intent.PRIMARY}
        onChange={handleChange}
        value={notes}
        className={styles.textArea}
      />
    </>
  )

  return (
    <Dialog
      icon={"info-sign"}
      onClose={close}
      title={`Report an undetected problem with CAM for Op ${operationIdx + 1}`}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
      className={styles.dialog}
    >
      <div className={Classes.DIALOG_BODY}>{bodyComponent}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={"primary"} onClick={handleConfirm}>
            Submit
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}
