import { FC, useEffect } from "react"

export const JiraCallbackPage: FC = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const code = searchParams.get("code")

  useEffect(() => {
    window.location.href = `${window.location.origin}/callback-jira2?code=${code}`
  }, [code])

  return null
}
