import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Button, FormGroup, MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select"
import { v4 as uuidv4 } from "uuid"

import {
  FixtureStock,
  MaterialRecord,
  ModelStock,
  ParametricStock,
  ParametricStockKindEnum,
  ParametricStockShapeRectangularKindEnum,
  ParametricStockShapeRoundKindEnum,
} from "src/client-axios"
import {
  InputParametricStockShape,
  UpsertStock,
  useUpdateInputStockMutation,
} from "src/graphql/generated"
import { stocksSelectors } from "src/store/config/stocks"
import { defaultTransform } from "src/util/geometry/transforms"
import { highlightText } from "src/util/text"
import { DefaultTolerances } from "../../../ConfigPanel/Panels/InputStockConfigPanel/InputStockConfigPanel"

import styles from "./ProductStockSelector.module.css"

enum Shape {
  Round = "Round",
  Rectangular = "Rectangular",
  Fixture = "Fixture",
  Custom = "Custom Model",
}
const shapeValues: Shape[] = Object.values(Shape).filter((item): item is Shape => {
  return typeof item !== "number" && item !== Shape.Custom && item !== Shape.Fixture
})
Object.freeze(Shape)

const ProductMaterialSelect = Select.ofType<MaterialRecord>()
const ShapeSelect = Select.ofType<Shape>()

const ProductMaterialMenuItem: ItemRenderer<MaterialRecord> = (
  productStock,
  { handleClick, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={productStock.materialId}
      onClick={handleClick}
      text={highlightText(productStock.materialId, query)}
    />
  )
}

const ProductShapeMenuItem: ItemRenderer<Shape> = (
  productStock,
  { handleClick, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={productStock}
      onClick={handleClick}
      text={highlightText(productStock.toString(), query)}
    />
  )
}

const filterMaterialStock: ItemPredicate<MaterialRecord> = (query, productStock) => {
  return productStock.materialId.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

interface ProductStockSelectorProps {
  planId: string
  operationIdx: number
  inputStock: ParametricStock | ModelStock | FixtureStock
  className?: string
  locked?: boolean
}

export const ProductStockSelector: FC<ProductStockSelectorProps> = ({
  planId,
  className,
  operationIdx,
  inputStock,
  locked,
}) => {
  const productMaterialChoices = useSelector(stocksSelectors.selectMaterials)
  const productStockShapeChoices: Shape[] = shapeValues.slice(0)

  const id = useMemo(() => {
    return uuidv4()
  }, [])

  const [updateInputStock] = useUpdateInputStockMutation()

  return (
    <div className={styles.container}>
      {inputStock.kind === "parametric" && (
        <ProductMaterialSelect
          disabled={locked}
          key="ProductMaterialSelector"
          activeItem={undefined}
          items={productMaterialChoices}
          itemPredicate={filterMaterialStock}
          itemRenderer={ProductMaterialMenuItem}
          itemsEqual={"materialId"}
          className={className || ""}
          onItemSelect={item => {
            const newStock = { ...inputStock }
            newStock.materialId = item.materialId

            updateInputStock({
              variables: {
                planId,
                operationIdx,
                stock: {
                  ...toUpsertStock(newStock),
                },
              },
            })
          }}
          popoverProps={{ minimal: true, captureDismiss: true, position: "bottom" }}
        >
          <FormGroup label="Material" labelFor={`product-stock-bar-${id}`}>
            {locked ? (
              <div className={styles.input}>{inputStock.materialId}</div>
            ) : (
              <Button
                id={`product-stock-bar-${id}`}
                fill={true}
                alignText={"left"}
                rightIcon="caret-down"
                small={true}
                text={inputStock.materialId}
              />
            )}
          </FormGroup>
        </ProductMaterialSelect>
      )}
      <ShapeSelect
        disabled={locked}
        activeItem={null}
        items={productStockShapeChoices}
        itemRenderer={ProductShapeMenuItem}
        className={className || ""}
        onItemSelect={item => {
          let newStock: ParametricStock

          if (inputStock.kind === "parametric") {
            newStock = { ...inputStock }
          } else {
            newStock = {
              kind: ParametricStockKindEnum.Parametric,
              cutLength: 0,
              xMinTolerance: DefaultTolerances.xMinTolerance,
              xMaxTolerance: DefaultTolerances.xMaxTolerance,
              yMinTolerance: DefaultTolerances.yMinTolerance,
              yMaxTolerance: DefaultTolerances.yMaxTolerance,
              cutLengthMinTolerance: DefaultTolerances.cutLengthMinTolerance,
              cutLengthMaxTolerance: DefaultTolerances.cutLengthMaxTolerance,
              diameterMinTolerance: DefaultTolerances.diameterMinTolerance,
              diameterMaxTolerance: DefaultTolerances.diameterMaxTolerance,
              shape: {
                kind: ParametricStockShapeRectangularKindEnum.Rectangular,
                x: 0,
                y: 0,
              },
              transform: defaultTransform(),
            }
          }

          if (item === "Round") {
            newStock.shape = {
              kind: ParametricStockShapeRoundKindEnum.Round,
              diameter: "diameter" in newStock.shape ? newStock.shape.diameter : 0,
            }
          }

          if (item === "Rectangular") {
            newStock.shape = {
              kind: ParametricStockShapeRectangularKindEnum.Rectangular,
              x: "x" in newStock.shape ? newStock.shape.x : 0,
              y: "y" in newStock.shape ? newStock.shape.y : 0,
            }
          }

          updateInputStock({
            variables: {
              planId,
              operationIdx,
              stock: {
                ...toUpsertStock(newStock),
              },
            },
          })
        }}
        popoverProps={{ minimal: true, captureDismiss: true, position: "bottom" }}
      >
        <FormGroup label={"Shape"} labelFor={`product-stock-bar-${id}`}>
          {locked ? (
            <div className={styles.input}>
              {inputStock.kind === "parametric"
                ? inputStock.shape.kind === "round"
                  ? "Round"
                  : "Rectangular"
                : "Custom Model"}
            </div>
          ) : (
            <Button
              id={`product-stock-bar-${id}`}
              fill={true}
              alignText={"left"}
              rightIcon="caret-down"
              small={true}
              text={
                inputStock.kind === "parametric"
                  ? inputStock.shape.kind === "round"
                    ? "Round"
                    : "Rectangular"
                  : "Custom Model"
              }
            />
          )}
        </FormGroup>
      </ShapeSelect>
    </div>
  )
}

const toUpsertStock = (inputStock: ParametricStock | ModelStock | FixtureStock): UpsertStock => {
  if (inputStock.kind === "fixture") {
    return {
      fixture: {
        fixtureId: inputStock.fixtureId,
        transform: inputStock.transform,
        parameter: inputStock.parameter,
      },
    }
  } else if (inputStock.kind === "parametric") {
    let output
    if (inputStock.shape.kind === "round") {
      output = {
        parametric: {
          kind: InputParametricStockShape.Round,
          cutLength: inputStock.cutLength,
          xMinTolerance: inputStock.xMinTolerance,
          yMinTolerance: inputStock.yMinTolerance,
          cutLengthMinTolerance: inputStock.cutLengthMinTolerance,
          diameterMinTolerance: inputStock.diameterMinTolerance,
          xMaxTolerance: inputStock.xMaxTolerance,
          yMaxTolerance: inputStock.yMaxTolerance,
          cutLengthMaxTolerance: inputStock.cutLengthMaxTolerance,
          diameterMaxTolerance: inputStock.diameterMaxTolerance,
          diameter: inputStock.shape.diameter,
          transform: inputStock.transform,
          materialId: inputStock.materialId ?? "Aluminum 6061",
          x: 0,
          y: 0,
        },
      }
    } else {
      output = {
        parametric: {
          kind: InputParametricStockShape.Rectangular,
          cutLength: inputStock.cutLength,
          xMinTolerance: inputStock.xMinTolerance,
          yMinTolerance: inputStock.yMinTolerance,
          cutLengthMinTolerance: inputStock.cutLengthMinTolerance,
          diameterMinTolerance: inputStock.diameterMinTolerance,
          xMaxTolerance: inputStock.xMaxTolerance,
          yMaxTolerance: inputStock.yMaxTolerance,
          cutLengthMaxTolerance: inputStock.cutLengthMaxTolerance,
          diameterMaxTolerance: inputStock.diameterMaxTolerance,
          diameter: 0,
          transform: inputStock.transform,
          materialId: inputStock.materialId ?? "Aluminum 6061",
          x: inputStock.shape.x,
          y: inputStock.shape.y,
        },
      }
    }
    return output
  } else {
    return {
      model: {
        modelId: inputStock.modelId,
        transform: inputStock.transform,
      },
    }
  }
}
