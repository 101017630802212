import React, { FC, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, ControlGroup, FormGroup, HTMLSelect, Label } from "@blueprintjs/core"

import { StockGenerationConstraints, StockShapeRestriction } from "src/client-axios"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { stocksSelectors } from "src/store/config/stocks"

import styles from "./GenerateStockPanel.module.css"

const MAX_PADDING = 25

export const GenerateStockPanel: FC<{ planId: string }> = ({ planId }) => {
  const toaster = useToaster()
  const { tasksApi } = useApi()

  const stocks = useSelector(stocksSelectors.selectStocks)
  const materials = useMemo(() => {
    return [...new Set(stocks.map(stock => stock.materialId))].sort()
  }, [stocks])

  const [shapeRestriction, setShapeRestriction] = useState(StockShapeRestriction.None)
  const [materialId, setMaterialId] = useState<string>()

  const [padding, setPadding] = useState(2.0) // default padding: 2mm
  const [minStockExtent, setMinStockExtent] = useState({ x: 0, y: 0, z: 0 })
  const [offsetProportions, setOffsetProportions] = useState({ x: 0, y: 0, z: 0 })

  const stockGenerationConstraints: StockGenerationConstraints = {
    minStockExtent,
    maxStockExtent: {},
    offsetProportions,
    padMax: { x: padding, y: padding, z: padding },
    padMin: { x: padding, y: padding, z: padding },
  }

  const onSubmit = () => {
    tasksApi
      .generateStock(planId, shapeRestriction, stockGenerationConstraints, materialId)
      .then(() => toaster.show({ message: "Generating stock...", intent: "primary" }))
      .catch(err => {
        console.error(err)
        toaster.show({ message: `Stock generation request failed: ${err}`, intent: "danger" })
      })
  }

  return (
    <div style={{ padding: "12px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormGroup label={"Shape"}>
          <HTMLSelect
            value={shapeRestriction}
            onChange={e => {
              setShapeRestriction(e.target.value as StockShapeRestriction)
            }}
          >
            <option value={StockShapeRestriction.None}>No Restriction</option>
            <option value={StockShapeRestriction.Square}>Square</option>
            <option value={StockShapeRestriction.Round}>Round</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup label={"Material"}>
          <HTMLSelect
            value={materialId}
            onChange={e => {
              setMaterialId(e.target.value as string | undefined)
            }}
          >
            <option>No Restriction</option>
            {materials.map(material => (
              <option value={material} key={material}>
                {material}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </div>
      <FormGroup
        className={styles.fullWidthForm}
        label={"Padding"}
        labelInfo={`(0 - ${MAX_PADDING}mm)`}
      >
        <FormulaInput
          commitOnBlur={true}
          onValueChange={value => setPadding(value)}
          value={padding}
          min={0}
          max={25}
        />
      </FormGroup>

      <FormGroup
        inline
        className={styles.fullWidthForm}
        label={
          <>
            <br />X<br />Y<br />Z
          </>
        }
      >
        <ControlGroup vertical fill>
          <ControlGroup>
            <Label>
              Min dimensions (mm)
              <FormulaInput
                commitOnBlur={true}
                min={0}
                max={2000}
                value={minStockExtent.x}
                onValueChange={value => setMinStockExtent(prev => ({ ...prev, x: value }))}
              />
            </Label>
            <Label>
              Offset (-100% to 100%)
              <FormulaInput
                commitOnBlur={true}
                min={-100}
                max={100}
                value={offsetProportions.x * 100}
                onValueChange={value => setOffsetProportions(prev => ({ ...prev, x: value / 100 }))}
              />
            </Label>
          </ControlGroup>
          <ControlGroup>
            <FormulaInput
              commitOnBlur={true}
              min={0}
              max={2000}
              value={minStockExtent.y}
              onValueChange={value => setMinStockExtent(prev => ({ ...prev, y: value }))}
            />
            <FormulaInput
              commitOnBlur={true}
              min={-100}
              max={100}
              value={offsetProportions.y * 100}
              onValueChange={value => setOffsetProportions(prev => ({ ...prev, y: value / 100 }))}
            />
          </ControlGroup>
          <ControlGroup>
            <FormulaInput
              commitOnBlur={true}
              min={0}
              max={2000}
              value={minStockExtent.z}
              onValueChange={value => setMinStockExtent(prev => ({ ...prev, z: value }))}
            />
            <FormulaInput
              commitOnBlur={true}
              min={-100}
              max={100}
              value={offsetProportions.z * 100}
              onValueChange={value => setOffsetProportions(prev => ({ ...prev, z: value / 100 }))}
            />
          </ControlGroup>
        </ControlGroup>
      </FormGroup>
      <Button fill intent={"success"} icon={"refresh"} onClick={() => onSubmit()}>
        Auto-select stock
      </Button>
    </div>
  )
}
