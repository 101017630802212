import React, { FC, useEffect, useState } from "react"
import { DebounceInput } from "react-debounce-input"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { Button, Tab, Tabs } from "@blueprintjs/core"
import { useAuth } from "@subscale/formlogic-auth-ts"

import { Camjobstatus, OverviewNodeFragment, useOverviewQuery } from "src/graphql/generated"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { UploadPartDialog } from "src/pages/Jobs/UploadPartDialog/UploadPartDialog"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { viewerModalActions } from "src/store/ui/viewerModal"
import JobsPanel from "./JobsPanel"

import styles from "./Jobs.module.css"

export enum JobsSortByEnum {
  label,
  promisedDate,
  createdAt,
  materialDisplayName,
  lastUpdated,
  priority,
}

export interface JobsSortByState {
  sortBy: JobsSortByEnum
  ascending: boolean
}

export const Jobs: FC = () => {
  const dispatch = useAppDispatch()
  const activeCamjobstatus = useSelector(activeSelectors.selectActiveCamjobstatus)
  const location = useLocation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = new URLSearchParams(window.location.search)
  params.delete("modal")
  params.delete("activeConfigPanel")
  const paramsString = params.toString()
  const url = paramsString.length > 0 ? `?${paramsString}` : ``
  window.history.replaceState({}, "", url)

  const [selectedTabId, setSelectedTabId] = useState<string>(
    activeCamjobstatus || Camjobstatus.Programming
  )
  const { user } = useAuth()

  const jobFilterEncoded = params.get("jobFilter")
  const jobFilter = decodeURIComponent(jobFilterEncoded || "")

  const [rows, setRows] = useState<OverviewNodeFragment[]>()
  const [archiveFilteredJobs, setArchiveFilteredJobs] = useState<OverviewNodeFragment[]>()
  const [query, setQuery] = useState(jobFilter)

  const [uploadPartModalIsOpen, setUploadPartModalIsOpen] = useState<boolean>(false)

  const {
    settings,
    settings: {
      showArchivedJobs,
      showAnalysisJobs,
      showUnassignedJobs,
      showJobsAssignedToOthers,
      camjobStatus,
    },
    setSettings,
  } = useLocalStorageSettings()

  const { loading, error, data: camJobsData } = useOverviewQuery({
    variables: {
      isForAnalysis: showAnalysisJobs ? undefined : false,
      isArchived: showArchivedJobs ? undefined : false,
    },
  })

  useEffect(() => {
    dispatch(activeActions.setActiveConfigPanel(undefined))
    dispatch(viewerModalActions.setViewerModalIsOpen(false))
  }, [dispatch])

  useEffect(() => {
    const jobs = camJobsData?.getOverview
    const archiveFilteredJobs = jobs?.slice().filter(({ job }) => {
      const isUnassigned = job.camProgrammers?.length === 0
      const isAssignedToOthers = !job.camProgrammers?.some(val => val === user?.email)

      if (!showUnassignedJobs && isUnassigned) {
        return false
      } else if (!showJobsAssignedToOthers && isAssignedToOthers) {
        return false
      }

      return true
    })

    setArchiveFilteredJobs(archiveFilteredJobs)
  }, [
    camJobsData,
    showAnalysisJobs,
    showArchivedJobs,
    showUnassignedJobs,
    showJobsAssignedToOthers,
    user,
  ])

  useEffect(() => {
    if (query !== "") {
      const queryLowercase = query.toLowerCase()

      const queryfilteredJobs = archiveFilteredJobs?.slice().filter(({ job }) => {
        const isCamProgrammerMatch = job.camProgrammers?.some(camProgrammer => {
          return camProgrammer.toLowerCase().includes(queryLowercase)
        })

        return (
          isCamProgrammerMatch ||
          job.label.toLowerCase().includes(queryLowercase) ||
          job.customerCodename.toLowerCase().includes(queryLowercase)
        )
      })

      setRows(queryfilteredJobs)
    } else {
      setRows(archiveFilteredJobs)
    }
  }, [query, archiveFilteredJobs])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const jobsTab = params.get("status")?.toUpperCase()
    const statusValues = Object.values(Camjobstatus)
    if (statusValues.some(value => value === jobsTab)) {
      dispatch(activeActions.setActiveCamjobstatus(jobsTab as Camjobstatus))

      setSelectedTabId(jobsTab as Camjobstatus)
      if (camjobStatus !== (jobsTab as Camjobstatus)) {
        setSettings({
          ...settings,
          camjobStatus: jobsTab as Camjobstatus,
        })
      }
    }
  }, [params, location, camjobStatus, dispatch, setSettings, settings])

  const handleTabChange = (newTabId: string) => {
    dispatch(activeActions.setActiveCamjobstatus(newTabId as Camjobstatus))
    setSettings({
      ...settings,
      camjobStatus: (newTabId as Camjobstatus) ?? camjobStatus,
    })
    const params = new URLSearchParams(window.location.search)
    params.set("status", `${newTabId.toLowerCase()}`)

    window.history.pushState({}, "", `?${params.toString()}`)
  }

  useEffect(() => {
    setSelectedTabId(activeCamjobstatus?.toUpperCase() || Camjobstatus.Programming)
  }, [activeCamjobstatus])

  return (
    <div className={styles.container}>
      <h3>CAM Jobs</h3>
      <div className="bp3-input-group .modifier">
        <span className="bp3-icon bp3-icon-search" />

        <DebounceInput
          className="bp3-input"
          type="search"
          placeholder="Search input"
          dir="auto"
          value={query}
          debounceTimeout={800}
          onChange={e => {
            setQuery(e.target.value)
          }}
          onBlur={e => {
            const params = new URLSearchParams(window.location.search)
            if (e.target.value !== "") {
              params.set("jobFilter", encodeURIComponent(e.target.value))
            } else {
              params.delete("jobFilter")
            }
            const paramsString = params.toString()
            const url = paramsString.length > 0 ? `?${paramsString}` : ``
            window.history.replaceState({}, "", url)
          }}
        />
      </div>
      <div className={styles.functionButtons}>
        <Link to="/account">
          <Button icon={"cog"}>Edit jobs filters</Button>
        </Link>
        <Button icon={"bring-data"} onClick={() => setUploadPartModalIsOpen(true)}>
          Create New Part
        </Button>
      </div>

      <div className={styles.tabsContainer}>
        <div className={styles.tabsRow}>
          <Tabs
            renderActiveTabPanelOnly
            id="jobsTabsLeft"
            onChange={handleTabChange}
            selectedTabId={selectedTabId}
            className={styles.tabs}
          >
            <Tab title={"Programming"} id={Camjobstatus.Programming} />
            <Tab title={"Prove Out"} id={Camjobstatus.ProveOut} />
            <Tab title={"Production"} id={Camjobstatus.Production} />
          </Tabs>
          <Tabs
            renderActiveTabPanelOnly
            id="jobsTabsRight"
            onChange={handleTabChange}
            selectedTabId={selectedTabId}
            className={styles.tabs}
          >
            <Tab title={"Completed"} id={Camjobstatus.Completed} />
            <Tab title={"Internal"} id={Camjobstatus.Internal} />
            {showArchivedJobs && <Tab title={"Archived"} id={Camjobstatus.Archived} />}
          </Tabs>
        </div>
        <JobsPanel
          rows={rows}
          selectedTabId={selectedTabId as Camjobstatus}
          error={error}
          loading={loading}
        />
      </div>

      {uploadPartModalIsOpen && (
        <UploadPartDialog
          isOpen={uploadPartModalIsOpen}
          handleRequestClose={() => setUploadPartModalIsOpen(false)}
        />
      )}
    </div>
  )
}
