import React, { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { WcsProbingKindEnum } from "src/client-axios"
import { ProbingPath } from "src/components/Cam/ProbingPath/ProbingPath"
import { activeProbingSelectors } from "src/store/cam/active"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"
import {
  useMachineCoordsStore,
  useSpindleToTableTransform,
  useWorldToTableTransform,
} from "src/store/zustandMachine"

export const SpindleScene: FC = () => {
  const section = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const probingDisplayMode = useSelector(viewOptionsSelectors.probingDisplayMode)

  const spindleToTableTransform = useWorldToTableTransform().multiply(useSpindleToTableTransform())

  const { position, quaternion } = useMemo(
    () => ({
      position:
        spindleToTableTransform &&
        new THREE.Vector3().setFromMatrixPosition(spindleToTableTransform),
      quaternion:
        spindleToTableTransform &&
        new THREE.Quaternion().setFromRotationMatrix(spindleToTableTransform),
    }),
    [spindleToTableTransform]
  )

  const showProbePath = section?.kind === WcsProbingKindEnum.WcsProbing

  useEffect(() => {
    if (showProbePath) {
      useMachineCoordsStore.setState({ rtcpEnabled: false })
    }
  }, [showProbePath])

  if (!showProbePath) return null

  return (
    <group position={position} quaternion={quaternion}>
      <ProbingPath
        visible={
          probingDisplayMode === DisplayMode.Visible || probingDisplayMode === DisplayMode.Focused
        }
      />
    </group>
  )
}
