import { useSelector } from "react-redux"

import {
  BoreFeatureKindEnum,
  BossFeatureKindEnum,
  MultiLevelBoreFeatureKindEnum,
  MultiLevelBossFeatureKindEnum,
  PointsFeatureKindEnum,
  PointsFeaturePointKindEnum,
  SphereFeatureKindEnum,
  WcsEdgeSurfaceKindEnum,
} from "src/client-axios"
import { activeProbingSelectors } from "src/store/cam/active"
import { probingStepDetailSelectors } from "src/store/cam/probing"

export const useSelectedStepStartPoint = (
  contactVerificationMode = false
): [number, number, number] => {
  const selectedProbeStep = useSelector(activeProbingSelectors.selectActiveProbingStep)
  const probeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)
  const contact = useSelector(probingStepDetailSelectors.selectContact)
  const offset = useSelector(probingStepDetailSelectors.selectOffset)

  let point = contact?.point || [0, 0, 0]

  if (
    (selectedProbeStep?.kind === BoreFeatureKindEnum.BoreFeature ||
      selectedProbeStep?.kind === BossFeatureKindEnum.BossFeature ||
      selectedProbeStep?.kind === MultiLevelBoreFeatureKindEnum.MultiLevelBoreFeature ||
      selectedProbeStep?.kind === MultiLevelBossFeatureKindEnum.MultiLevelBossFeature ||
      selectedProbeStep?.kind === SphereFeatureKindEnum.SphereFeature) &&
    !contactVerificationMode
  ) {
    point = [
      selectedProbeStep.approach.point.x,
      selectedProbeStep.approach.point.y,
      selectedProbeStep.approach.point.z,
    ]
  }

  let normalOffset = 0.0
  if (
    selectedProbeStep?.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
    selectedProbeStep?.kind === PointsFeatureKindEnum.PointsFeature ||
    selectedProbeStep?.kind === WcsEdgeSurfaceKindEnum.EdgeSurface
  ) {
    normalOffset = offset + (probeRecord?.sphereDiameter ?? 0) * 0.5
  }

  if (contact?.normal) {
    point = [
      point[0] + contact.normal[0] * normalOffset,
      point[1] + contact.normal[1] * normalOffset,
      point[2] + contact.normal[2] * normalOffset,
    ]
  }

  return point
}
