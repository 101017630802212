import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Callout, Classes } from "@blueprintjs/core"

import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { ManufacturingIssue } from "../ManufacturingIssue/ManufacturingIssue"
import {
  getArePublishIssuesPresent,
  getPrePublishCheckResults,
  PrePublishCheckResults,
} from "./ManufacturingCheckResults"
import { ManufacturingChecksStatus, ManufacturingConfirm } from "./ManufacturingConfirm"

export const PublishConfirm: FC<{
  vericutValidated: boolean
  planId: string
  operationIdx: number
  currentVersion?: string
  nextVersion: string
  noPendingChanges: boolean
  handleRequestClose: () => void
  handlePublishClick: () => void
}> = ({
  vericutValidated,
  planId,
  operationIdx,
  currentVersion,
  nextVersion,
  noPendingChanges,
  handleRequestClose,
  handlePublishClick,
}) => {
  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )

  const { checkResults, areIssuesPresent } = useMemo(() => {
    const checkResults = getPrePublishCheckResults(toolpathProject)
    const areIssuesPresent = getArePublishIssuesPresent(checkResults, vericutValidated)
    return { checkResults, areIssuesPresent }
  }, [toolpathProject, vericutValidated])

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {currentVersion && <p>Current Version: {currentVersion}</p>}
        <PrePublishFeedback
          areIssuesPresent={areIssuesPresent}
          vericutValidated={vericutValidated}
          checkResults={checkResults}
          noPendingChanges={noPendingChanges}
        />
      </div>
      <ManufacturingConfirm
        actionText="Publish"
        nextVersion={nextVersion}
        showWarning={areIssuesPresent || noPendingChanges}
        handleRequestClose={handleRequestClose}
        handleConfirmClick={handlePublishClick}
      />
    </>
  )
}

const PrePublishFeedback: FC<{
  areIssuesPresent: boolean
  vericutValidated: boolean
  checkResults: PrePublishCheckResults
  noPendingChanges: boolean
}> = ({ areIssuesPresent, vericutValidated, checkResults, noPendingChanges }) => {
  // TODO: Should display whether fixtures are defined, since currently MES will reject publishing if they aren't
  return areIssuesPresent || noPendingChanges ? (
    <Callout intent={"danger"} icon={null} title={"Possible problems detected"}>
      <ul>
        <ManufacturingIssue isPresent={noPendingChanges}>
          No changes have been made to the operation since last publish
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.isCuttingUndefined}>
          No cutting program has been submitted for this operation
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={!vericutValidated}>
          Cutting program is not passing simulation
        </ManufacturingIssue>
      </ul>
    </Callout>
  ) : (
    <ManufacturingChecksStatus actionText="Publish" />
  )
}
