import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"

import {
  CompensationKind,
  MspMaterialCondition,
  PointCompensations,
  ProbingTouchType,
  SphereFeatureT,
  WcsSurfaceDirection,
} from "src/client-axios"
import { RootState } from "src/store/rootStore"

export const DEFAULT_APPROACH_OFFSET = 10.0
export const DEFAULT_MOVE_APPROACH_OFFSET = 25.0
export const DEFAULT_VERTICAL_NORMAL_THRESHOLD = 0.9
export const DEFAULT_LOWER_TOL = -0.05
export const DEFAULT_UPPER_TOL = 0.05
export const DEFAULT_ANGULARITY_TOL = 0.1
export const DEFAULT_FORM_TOL = 0.1
export const DEFAULT_POSITION_TOL = 0.1
export const DEFAULT_MATERIAL_CONDITION = MspMaterialCondition.NUMBER_0
export const DEFAULT_COMPENSATION_KIND = CompensationKind.NUMBER_2
export const DEFAULT_N_POINTS = 6
export const DEFAULT_FEATURE_WEIGHT = 1.0
export const DEFAULT_RELATIVE_POINT_WEIGHT = 1.0

export interface ProbingContact {
  point: [number, number, number]
  normal: [number, number, number]
  isRtcp?: boolean
}

const setLabel = createAction<string | undefined>("probing/setLabel")
const setDescriptor = createAction<string | undefined>("probing/setDescriptor")
const setClearance = createAction<number | undefined>("probing/setClearance")
const setDepth = createAction<number | undefined>("probing/setDepth")
const setApproachDistance = createAction<number | undefined>("probing/setApproachDistance")
const setSearchDistance = createAction<number | undefined>("probing/setSearchDistance")
const setDiameter = createAction<number | undefined>("probing/setDiameter")
const setNIterations = createAction<number | undefined>("probing/setNIterations")
const setOffset = createAction<number>("probing/setOffset")
const setSphereFeatureT = createAction<SphereFeatureT | undefined>("probing/setSphereFeatureT")
const setWcsSurfaceDirection = createAction<WcsSurfaceDirection | undefined>(
  "probing/setWcsSurfaceDirection"
)
const setXSize = createAction<number | undefined>("probing/setXSize")
const setYSize = createAction<number | undefined>("probing/setYSize")
const setNPoints = createAction<number>("probing/setNPoints")
const setNLevels = createAction<number>("probing/setNLevels")
const setLevelSpacing = createAction<number>("probing/setLevelSpacing")
const setFeatureWeight = createAction<number>("probing/setFeatureWeight")
const setRelativePointWeight = createAction<number>("probing/setRelativePointWeight")
const setTouchType = createAction<ProbingTouchType | undefined>("probing/setTouchType")
const setWcsSpecifyCoordsX = createAction<number | undefined>("probing/setWcsSpecifyCoordsX")
const setWcsSpecifyCoordsY = createAction<number | undefined>("probing/setWcsSpecifyCoordsY")
const setWcsSpecifyCoordsZ = createAction<number | undefined>("probing/setWcsSpecifyCoordsZ")
const setWcsSpecifyCoordsIncremental = createAction<boolean | undefined>(
  "probing/setWcsSpecifyCoordsIncremental"
)
const setNominal = createAction<number | undefined>("probing/setNominal")
const setLowerTol = createAction<number | undefined>("probing/setLowerTol")
const setUpperTol = createAction<number | undefined>("probing/setUpperTol")

const setPointLower = createAction<number | undefined>("probing/setPointLower")
const setPointUpper = createAction<number | undefined>("probing/setPointUpper")
const setFeatureLower = createAction<number | undefined>("probing/setFeatureLower")
const setFeatureUpper = createAction<number | undefined>("probing/setFeatureUpper")
const setFeatureAngularity = createAction<number | undefined>("probing/setFeatureAngularity")
const setFeaturePosition = createAction<number | undefined>("probing/setFeaturePosition")
const setFeatureForm = createAction<number | undefined>("probing/setFeatureForm")
const setMaterialCondition = createAction<MspMaterialCondition | undefined>(
  "probing/setMaterialCondition"
)
const setPointCompensations = createAction<PointCompensations | undefined>(
  "probing/setPointCompensations"
)

const setExpandedSectionId = createAction<string | undefined>("probing/setExpandedSectionId")
const setExpandedStepId = createAction<string | undefined>("probing/setExpandedStepId")
const setFocusStepId = createAction<string | undefined>("probing/setFocusStepId")

const setContact = createAction<ProbingContact | undefined>("probing/setContact")
const setTempContact = createAction<ProbingContact | undefined>("probing/setTempContact")
const setTempContactMode = createAction<boolean | undefined>("probing/setTempContactMode")
const setContactVerificationMode = createAction<boolean | undefined>(
  "probing/setContactVerificationMode"
)
const setIssueViewMode = createAction<boolean | undefined>("probing/setIssueViewMode")
const setIsSliderChange = createAction<boolean>("active/setIsSliderChange")

export const probingReducer = createReducer<{
  // Step config state
  label?: string
  descriptor?: string
  clearance?: number
  depth?: number
  approachDistance?: number
  searchDistance?: number
  diameter?: number
  nIterations?: number
  offset: number
  sphereFeatureT?: SphereFeatureT
  wcsSurfaceDirection?: WcsSurfaceDirection
  xSize?: number
  ySize?: number
  nPoints: number
  nLevels?: number
  levelSpacing?: number
  featureWeight?: number
  relativePointWeight?: number
  touchType?: ProbingTouchType
  wcsSpecifyCoordsX?: number
  wcsSpecifyCoordsY?: number
  wcsSpecifyCoordsZ?: number
  wcsSpecifyCoordsIncremental?: boolean
  nominal?: number
  lowerTol?: number
  upperTol?: number

  pointUpper?: number
  pointLower?: number
  featureUpper?: number
  featureLower?: number
  featureAngularity?: number
  featurePosition?: number
  featureForm?: number
  materialCondition?: MspMaterialCondition
  pointCompensations?: PointCompensations

  // Tree state
  expandedSectionId?: string | undefined
  expandedStepId?: string | undefined
  focusedStepId?: string

  // Visualization state
  contact?: ProbingContact
  tempContact?: ProbingContact
  tempContactMode?: boolean
  contactVerificationMode?: boolean
  issueViewMode?: boolean
  isSliderChange: boolean
}>(
  {
    offset: DEFAULT_APPROACH_OFFSET,
    isSliderChange: false,
    nPoints: DEFAULT_N_POINTS,
  },
  builder => {
    builder.addCase(setLabel, (state, action) => {
      state.label = action.payload
    })
    builder.addCase(setDescriptor, (state, action) => {
      state.descriptor = action.payload
    })
    builder.addCase(setClearance, (state, action) => {
      state.clearance = action.payload
    })
    builder.addCase(setDepth, (state, action) => {
      state.depth = action.payload
    })
    builder.addCase(setApproachDistance, (state, action) => {
      state.approachDistance = action.payload
    })
    builder.addCase(setSearchDistance, (state, action) => {
      state.searchDistance = action.payload
    })
    builder.addCase(setDiameter, (state, action) => {
      state.diameter = action.payload
    })
    builder.addCase(setNIterations, (state, action) => {
      state.nIterations = action.payload
    })
    builder.addCase(setOffset, (state, action) => {
      state.offset = action.payload
    })
    builder.addCase(setSphereFeatureT, (state, action) => {
      state.sphereFeatureT = action.payload
    })
    builder.addCase(setWcsSurfaceDirection, (state, action) => {
      state.wcsSurfaceDirection = action.payload
    })
    builder.addCase(setXSize, (state, action) => {
      state.xSize = action.payload
    })
    builder.addCase(setYSize, (state, action) => {
      state.ySize = action.payload
    })
    builder.addCase(setNPoints, (state, action) => {
      state.nPoints = action.payload
    })
    builder.addCase(setNLevels, (state, action) => {
      state.nLevels = action.payload
    })
    builder.addCase(setLevelSpacing, (state, action) => {
      state.levelSpacing = action.payload
    })
    builder.addCase(setFeatureWeight, (state, action) => {
      state.featureWeight = action.payload
    })
    builder.addCase(setRelativePointWeight, (state, action) => {
      state.relativePointWeight = action.payload
    })
    builder.addCase(setTouchType, (state, action) => {
      state.touchType = action.payload
    })
    builder.addCase(setExpandedSectionId, (state, action) => {
      state.expandedSectionId = action.payload
    })
    builder.addCase(setFocusStepId, (state, action) => {
      state.focusedStepId = action.payload
    })
    builder.addCase(setExpandedStepId, (state, action) => {
      state.expandedStepId = action.payload
    })
    builder.addCase(setContact, (state, action) => {
      state.contact = action.payload
    })
    builder.addCase(setTempContact, (state, action) => {
      state.tempContact = action.payload
    })
    builder.addCase(setTempContactMode, (state, action) => {
      state.tempContactMode = action.payload
    })
    builder.addCase(setContactVerificationMode, (state, action) => {
      state.contactVerificationMode = action.payload
    })
    builder.addCase(setIssueViewMode, (state, action) => {
      state.issueViewMode = action.payload
    })
    builder.addCase(setWcsSpecifyCoordsX, (state, action) => {
      state.wcsSpecifyCoordsX = action.payload
    })
    builder.addCase(setWcsSpecifyCoordsY, (state, action) => {
      state.wcsSpecifyCoordsY = action.payload
    })
    builder.addCase(setWcsSpecifyCoordsZ, (state, action) => {
      state.wcsSpecifyCoordsZ = action.payload
    })
    builder.addCase(setWcsSpecifyCoordsIncremental, (state, action) => {
      state.wcsSpecifyCoordsIncremental = action.payload
    })
    builder.addCase(setNominal, (state, action) => {
      state.nominal = action.payload
    })
    builder.addCase(setLowerTol, (state, action) => {
      state.lowerTol = action.payload
    })
    builder.addCase(setUpperTol, (state, action) => {
      state.upperTol = action.payload
    })
    builder.addCase(setPointLower, (state, action) => {
      state.pointLower = action.payload
    })
    builder.addCase(setPointUpper, (state, action) => {
      state.pointUpper = action.payload
    })
    builder.addCase(setFeatureLower, (state, action) => {
      state.featureLower = action.payload
    })
    builder.addCase(setFeatureUpper, (state, action) => {
      state.featureUpper = action.payload
    })
    builder.addCase(setFeatureAngularity, (state, action) => {
      state.featureAngularity = action.payload
    })
    builder.addCase(setFeaturePosition, (state, action) => {
      state.featurePosition = action.payload
    })
    builder.addCase(setFeatureForm, (state, action) => {
      state.featureForm = action.payload
    })
    builder.addCase(setMaterialCondition, (state, action) => {
      state.materialCondition = action.payload
    })
    builder.addCase(setPointCompensations, (state, action) => {
      state.pointCompensations = action.payload
    })
    builder.addCase(setIsSliderChange, (state, action) => {
      state.isSliderChange = action.payload
    })
  }
)

/**
 * Actions
 */
export const probingActions = {
  setLabel,
  setDescriptor,
  setClearance,
  setDepth,
  setApproachDistance,
  setSearchDistance,
  setDiameter,
  setNIterations,
  setOffset,
  setSphereFeatureT,
  setWcsSurfaceDirection,
  setXSize,
  setYSize,
  setWcsSpecifyCoordsX,
  setWcsSpecifyCoordsY,
  setWcsSpecifyCoordsZ,
  setWcsSpecifyCoordsIncremental,
  setNominal,
  setLowerTol,
  setUpperTol,
  setPointUpper,
  setPointLower,
  setFeatureUpper,
  setFeatureLower,
  setFeatureAngularity,
  setFeaturePosition,
  setFeatureForm,
  setMaterialCondition,
  setPointCompensations,
  setNPoints,
  setNLevels,
  setLevelSpacing,
  setFeatureWeight,
  setRelativePointWeight,
  setTouchType,

  setExpandedSectionId,
  setExpandedStepId,
  setFocusStepId,

  setContact,
  setTempContact,
  setTempContactMode,
  setContactVerificationMode,
  setIssueViewMode,
  setIsSliderChange,
}

/**
 * Selectors
 */
const selectClearance = (state: RootState): number | undefined => state.probing.clearance
const selectDepth = (state: RootState): number | undefined => state.probing.depth
const selectApproachDistance = (state: RootState): number | undefined =>
  state.probing.approachDistance
const selectSearchDistance = (state: RootState): number | undefined => state.probing.searchDistance
const selectDiameter = (state: RootState): number | undefined => state.probing.diameter
const selectNIterations = (state: RootState): number | undefined => state.probing.nIterations
const selectOffset = (state: RootState): number => state.probing.offset
const selectSphereFeatureT = (state: RootState): SphereFeatureT | undefined =>
  state.probing.sphereFeatureT
const selectWcsSurfaceDirection = (state: RootState): WcsSurfaceDirection | undefined =>
  state.probing.wcsSurfaceDirection
const selectXSize = (state: RootState): number | undefined => state.probing.xSize
const selectYSize = (state: RootState): number | undefined => state.probing.ySize
const selectNPoints = (state: RootState): number => state.probing.nPoints
const selectNLevels = (state: RootState): number | undefined => state.probing.nLevels
const selectLevelSpacing = (state: RootState): number | undefined => state.probing.levelSpacing
const selectFeatureWeight = (state: RootState): number =>
  state.probing.featureWeight ?? DEFAULT_FEATURE_WEIGHT
const selectRelativePointWeight = (state: RootState): number =>
  state.probing.relativePointWeight ?? DEFAULT_RELATIVE_POINT_WEIGHT
const selectTouchType = (state: RootState): ProbingTouchType | undefined => state.probing.touchType

const selectExpandedSectionId = (state: RootState): string | undefined =>
  state.probing.expandedSectionId
const selectExpandedStepId = (state: RootState): string | undefined => state.probing.expandedStepId
const selectFocusedStepId = (state: RootState): string | undefined => state.probing.focusedStepId

const selectContact = (state: RootState): ProbingContact | undefined => state.probing.contact
const selectTempContact = (state: RootState): ProbingContact | undefined =>
  state.probing.tempContact
const selectTempContactMode = (state: RootState): boolean | undefined =>
  state.probing.tempContactMode
const selectContactVerificationMode = (state: RootState): boolean | undefined =>
  state.probing.contactVerificationMode
const selectIssueViewMode = (state: RootState): boolean | undefined => state.probing.issueViewMode
const selectWcsSpecifyCoordsX = (state: RootState): number | undefined =>
  state.probing.wcsSpecifyCoordsX ?? undefined
const selectWcsSpecifyCoordsY = (state: RootState): number | undefined =>
  state.probing.wcsSpecifyCoordsY ?? undefined
const selectWcsSpecifyCoordsZ = (state: RootState): number | undefined =>
  state.probing.wcsSpecifyCoordsZ ?? undefined
const selectWcsSpecifyCoordsIncremental = (state: RootState): boolean | undefined =>
  state.probing.wcsSpecifyCoordsIncremental ?? undefined
const selectNominal = (state: RootState): number | undefined => state.probing.nominal
const selectLowerTol = (state: RootState): number | undefined => state.probing.lowerTol
const selectUpperTol = (state: RootState): number | undefined => state.probing.upperTol

const selectLabel = (state: RootState): string | undefined => state.probing.label
const selectDescriptor = (state: RootState): string | undefined => state.probing.descriptor
const selectPointUpper = (state: RootState): number | undefined => state.probing.pointUpper
const selectPointLower = (state: RootState): number | undefined => state.probing.pointLower
const selectFeatureUpper = (state: RootState): number | undefined => state.probing.featureUpper
const selectFeatureLower = (state: RootState): number | undefined => state.probing.featureLower
const selectFeatureAngularity = (state: RootState): number | undefined =>
  state.probing.featureAngularity
const selectFeaturePosition = (state: RootState): number | undefined =>
  state.probing.featurePosition
const selectFeatureForm = (state: RootState): number | undefined => state.probing.featureForm
const selectMaterialCondition = (state: RootState): MspMaterialCondition | undefined =>
  state.probing.materialCondition
const selectPointCompensations = (state: RootState): PointCompensations | undefined =>
  state.probing.pointCompensations
const selectIsSliderChange = (state: RootState): boolean => state.probing.isSliderChange

// Step visibility selectors
const selectStepVisibility = createSelector(
  selectFocusedStepId,
  focusedStepId => (stepId: string) => (focusedStepId ? focusedStepId === stepId : true)
)
const selectStepFocus = createSelector(selectFocusedStepId, focusedStepId => (stepId: string) =>
  focusedStepId === stepId
)

export const probingStepDetailSelectors = {
  selectSphereFeatureT,
  selectNPoints,
  selectNLevels,
  selectLevelSpacing,
  selectFeatureWeight,
  selectRelativePointWeight,
  selectTouchType,
  selectOffset,
  selectDiameter,
  selectNIterations,
  selectWcsSurfaceDirection,
  selectXSize,
  selectYSize,
  selectDepth,
  selectApproachDistance,
  selectSearchDistance,
  selectClearance,
  selectLabel,
  selectDescriptor,
  selectNominal,
  selectLowerTol,
  selectUpperTol,
  selectPointUpper,
  selectPointLower,
  selectFeatureUpper,
  selectFeatureLower,
  selectFeatureAngularity,
  selectFeaturePosition,
  selectFeatureForm,
  selectMaterialCondition,
  selectPointCompensations,

  selectExpandedSectionId,
  selectExpandedStepId,
  selectStepVisibility,
  selectStepFocus,

  selectContact,
  selectTempContact,
  selectTempContactMode,
  selectContactVerificationMode,
  selectIssueViewMode,
  selectWcsSpecifyCoordsX,
  selectWcsSpecifyCoordsY,
  selectWcsSpecifyCoordsZ,
  selectWcsSpecifyCoordsIncremental,
  selectIsSliderChange,
}
