import React, { FC } from "react"

import { ParametricStock } from "src/client-axios"
import { InputStockLengthInput } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/InputStockLengthInput/InputStockLengthInput"

import styles from "./RoundStockSizeControls.module.css"

export const RoundStockSizeControls: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
  inputStock: ParametricStock
}> = ({ planId, operationIdx, locked, inputStock }) => {
  return (
    <div>
      <div className={styles.inputRow}>
        <InputStockLengthInput
          planId={planId}
          operationIdx={operationIdx}
          inputStock={inputStock}
          locked={locked}
          field="diameter"
          label={"Diameter"}
          className={styles.dimensionInput}
        />
        <div className={styles.tolerancesContainer}>
          <div>Tol</div>
          <div className={styles.toleranceInputContainer}>
            <div className={styles.sign}>+</div>
            <InputStockLengthInput
              planId={planId}
              operationIdx={operationIdx}
              inputStock={inputStock}
              locked={locked}
              field="diameterMaxTolerance"
              label={""}
              className={styles.toleranceInput}
            />
          </div>
          <div className={styles.toleranceInputContainer}>
            <div className={styles.sign}>-</div>
            <InputStockLengthInput
              planId={planId}
              operationIdx={operationIdx}
              inputStock={inputStock}
              locked={locked}
              field="diameterMinTolerance"
              label={""}
              className={styles.toleranceInput}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <InputStockLengthInput
          planId={planId}
          operationIdx={operationIdx}
          inputStock={inputStock}
          locked={locked}
          field="cutLength"
          label={"Length"}
          className={styles.dimensionInput}
        />
        <div className={styles.tolerancesContainer}>
          <div>Tol</div>
          <div className={styles.toleranceInputContainer}>
            <div className={styles.sign}>+</div>

            <InputStockLengthInput
              planId={planId}
              operationIdx={operationIdx}
              inputStock={inputStock}
              locked={locked}
              field="cutLengthMaxTolerance"
              label={""}
              className={styles.toleranceInput}
            />
          </div>
          <div className={styles.toleranceInputContainer}>
            <div className={styles.sign}>-</div>
            <InputStockLengthInput
              planId={planId}
              operationIdx={operationIdx}
              inputStock={inputStock}
              locked={locked}
              field="cutLengthMinTolerance"
              label={""}
              className={styles.toleranceInput}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
