import { useSelector } from "react-redux"

import {
  AlignmentKindEnum,
  InspectionKindEnum,
  PointsFeature,
  PointsFeatureKindEnum,
  PointsFeatureMoveKindEnum,
  PointsFeaturePointKindEnum,
  WcsProbingKindEnum,
} from "src/client-axios"
import {
  updateAlignment,
  updateInspection,
  updateWcsProbing,
} from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/updateProductionControls"
import { activeProbingSelectors } from "src/store/cam/active"
import { useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { useAppDispatch } from "src/store/rootStore"
import { arrayMove } from "src/util/arrayMove"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const useMoveProbeStep: () => {
  moveProbeSteps: (stepsToMove: (ProbingStep | WcsProbingStep)[], offset?: number) => void
  isMoveDisabled: (stepsToMove: (ProbingStep | WcsProbingStep)[], offset: number) => boolean
} = () => {
  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const dispatch = useAppDispatch()
  const probingThunkContext = useProbingThunkContext()

  const isMoveDisabled: (
    stepsToMove: (ProbingStep | WcsProbingStep)[],
    offset: number
  ) => boolean = (stepsToMove, offset) => {
    if (!stepsToMove.length) return true
    if (
      selectedSection?.kind === AlignmentKindEnum.Alignment ||
      selectedSection?.kind === InspectionKindEnum.Inspection
    ) {
      let fromIndex = 0

      if (
        stepsToMove[0].kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
        stepsToMove[0].kind === PointsFeatureMoveKindEnum.PointsFeatureMove
      ) {
        const parentStep = selectedSection.steps.find(
          step =>
            step.kind === PointsFeatureKindEnum.PointsFeature &&
            step.steps.some(step => step.id === stepsToMove[0].id)
        ) as PointsFeature

        if (parentStep) {
          fromIndex = parentStep.steps.findIndex(step => step.id === stepsToMove[0].id)
          if (
            fromIndex + offset < 0 ||
            fromIndex + stepsToMove.length - 1 + offset > parentStep.steps.length - 1
          ) {
            return true
          }
        }
      } else {
        fromIndex = selectedSection.steps.findIndex(step => step.id === stepsToMove[0]?.id)
        if (
          fromIndex + offset < 0 ||
          fromIndex + stepsToMove.length - 1 + offset > selectedSection.steps.length - 1
        ) {
          return true
        }
      }
    } else if (selectedSection?.kind === WcsProbingKindEnum.WcsProbing) {
      const fromIndex = selectedSection.steps.findIndex(step => step.id === stepsToMove[0]?.id)
      if (
        fromIndex + offset < 0 ||
        fromIndex + stepsToMove.length - 1 + offset > selectedSection.steps.length - 1
      ) {
        return true
      }
    }
    return false
  }

  const moveProbeSteps: (stepsToMove: (ProbingStep | WcsProbingStep)[], offset?: number) => void = (
    stepsToMove,
    offset = -1
  ) => {
    if (
      selectedSection?.kind === AlignmentKindEnum.Alignment ||
      selectedSection?.kind === InspectionKindEnum.Inspection
    ) {
      let fromIndex = 0
      let newSteps

      if (
        stepsToMove[0].kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
        stepsToMove[0].kind === PointsFeatureMoveKindEnum.PointsFeatureMove
      ) {
        const parentStep = selectedSection.steps.find(
          step =>
            step.kind === PointsFeatureKindEnum.PointsFeature &&
            step.steps.some(step => step.id === stepsToMove[0].id)
        ) as PointsFeature

        if (parentStep) {
          fromIndex = parentStep.steps.findIndex(step => step.id === stepsToMove[0].id)
          if (!(fromIndex + offset < 0 || fromIndex + offset > parentStep.steps.length - 1)) {
            const newSubSteps = arrayMove(
              parentStep.steps,
              fromIndex,
              fromIndex + offset,
              stepsToMove.length
            )

            newSteps = selectedSection.steps.slice()
            const parentStepIndex = selectedSection.steps.findIndex(
              step => step.id === parentStep.id
            )
            newSteps[parentStepIndex] = {
              ...parentStep,
              steps: newSubSteps,
            }
          }
        }
      } else {
        fromIndex = selectedSection.steps.findIndex(step => step.id === stepsToMove[0].id)
        if (!(fromIndex + offset < 0 || fromIndex + offset > selectedSection.steps.length - 1)) {
          newSteps = arrayMove(
            selectedSection.steps,
            fromIndex,
            fromIndex + offset,
            stepsToMove.length
          )
        }
      }

      if (selectedSection?.kind === AlignmentKindEnum.Alignment) {
        updateAlignment({
          alignment: selectedSection,
          thunkInputs: { ...probingThunkContext, dispatch },
          updates: { steps: newSteps },
        })
      } else if (selectedSection?.kind === InspectionKindEnum.Inspection) {
        updateInspection({
          inspection: selectedSection,
          thunkInputs: { ...probingThunkContext, dispatch },
          updates: { steps: newSteps },
        })
      }
    } else if (selectedSection?.kind === WcsProbingKindEnum.WcsProbing) {
      const fromIndex = selectedSection.steps.findIndex(step => step.id === stepsToMove[0].id)
      if (!(fromIndex + offset < 0 || fromIndex + offset > selectedSection.steps.length - 1)) {
        const newSteps = arrayMove(
          selectedSection.steps,
          fromIndex,
          fromIndex + offset,
          stepsToMove.length
        )
        updateWcsProbing({
          wcsProbing: selectedSection,
          thunkInputs: { ...probingThunkContext, dispatch },
          params: { steps: newSteps },
        })
      }
    }
  }
  return { moveProbeSteps, isMoveDisabled }
}
