import React, { FC } from "react"
import { Dialog } from "@blueprintjs/core"

import styles from "./PdfPreview.module.css"

interface PdfPreviewProps {
  url?: string
  onClose: () => void
}

export const PdfPreview: FC<PdfPreviewProps> = ({ url, onClose }) => {
  return (
    <Dialog
      icon={"document"}
      onClose={onClose}
      title={"PDF Preview"}
      isOpen={url !== undefined}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
      className={styles.pdfDialog}
    >
      <div className={styles.pdfContainer}>
        <embed src={url} type="application/pdf" />
      </div>
    </Dialog>
  )
}
