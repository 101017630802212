import React, { FC, useEffect, useState } from "react"
import { Button, Classes } from "@blueprintjs/core"
import { useAuth } from "@subscale/formlogic-auth-ts"
import copy from "copy-to-clipboard"

import { useToaster } from "src/hooks/useToaster"

import styles from "./TokenView.module.css"

export const TokenView: FC = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth()
  const [accessToken, setAccessToken] = useState<string | undefined>()

  const toaster = useToaster()

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(result => {
        setAccessToken(result)
      })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  const handleCopyAccessToken = () => {
    if (!accessToken) return
    copy(accessToken)
    toaster.show({ message: "Copied Access Token", intent: "success" })
  }

  const handleCopyHeaders = () => {
    if (!accessToken) return
    copy(getGraphiqlHeaders(accessToken))
    toaster.show({ message: "Copied GraphiQL headers", intent: "success" })
  }

  return (
    <>
      {accessToken && (
        <>
          <h3>Tokens</h3>
          <div>
            <div className={styles.token}>
              <div className={styles.key}>Raw Access Token:</div>
              <input type="text" value={accessToken} className={styles.value} readOnly />
              <Button intent="primary" onClick={handleCopyAccessToken} icon={"clipboard"}>
                Copy
              </Button>
            </div>
            <div className={styles.token}>
              <div className={styles.key}>GraphiQL Headers:</div>
              <input
                type="text"
                value={getGraphiqlHeaders(accessToken)}
                className={styles.value}
                readOnly
              />
              <Button intent="primary" onClick={handleCopyHeaders} icon={"clipboard"}>
                Copy
              </Button>
            </div>
            <div className={styles.token + " " + styles.jwtDiv}>
              <div className={styles.key}>JWT Info:</div>
              <pre className={Classes.CODE_BLOCK + " " + styles.jwtBlock}>
                {JSON.stringify(parseJwt(accessToken), undefined, 2)}
              </pre>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const getGraphiqlHeaders = (accessToken: string): string => {
  return `{
  "Authorization": "Bearer ${accessToken}"
}`
}

export const parseJwt = (accessToken: string): Record<string, unknown> => {
  const base64Url = accessToken.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )

  return JSON.parse(jsonPayload)
}
