import React, { FC, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Classes, HTMLTable, Icon } from "@blueprintjs/core"
import { useAuth } from "@subscale/formlogic-auth-ts"

import { Assignee } from "src/components/Cam/Assignee/Assignee"
import { Thumbnail } from "src/components/Generic/Thumbnail/Thumbnail"
import { PrioritySelector } from "src/components/Shared/PrioritySelector/PrioritySelector"
import { Camjobstatus, OverviewNodeFragment, Priority } from "src/graphql/generated"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { sortBy as lodashSortBy } from "src/util/sortBy"
import { JobActions } from "../JobActions/JobActions"
import { JobsSortByEnum, JobsSortByState } from "../Jobs"

import styles from "./JobsTable.module.css"

export const JobsTable: FC<{
  camJobs: OverviewNodeFragment[]
  selectedTabId: Camjobstatus
}> = ({ camJobs, selectedTabId }) => {
  // TODO: Use a better system for managing admin access
  const {
    settings: { enablePriorityEditing },
  } = useLocalStorageSettings()
  const { user } = useAuth()

  const [sortBy, setSortBy] = useState<JobsSortByState>({
    sortBy: JobsSortByEnum.promisedDate,
    ascending: true,
  })

  const getJobsSortIcon = (sortByEnum: JobsSortByEnum) => {
    if (sortByEnum === sortBy.sortBy) {
      return <Icon icon={sortBy.ascending ? "caret-up" : "caret-down"} />
    } else return null
  }

  const handleClickSort = (sortByEnum: JobsSortByEnum) => {
    setSortBy({
      sortBy: sortByEnum,
      ascending: sortBy.sortBy === sortByEnum ? !sortBy.ascending : true,
    })
  }

  const sortCurrentUserPriority = (filteredRows: OverviewNodeFragment[]) => {
    return lodashSortBy(filteredRows, o =>
      o.job.camProgrammers?.some(val => val === user?.email) ? -1 : 0
    )
  }

  const camJobsDefaultSorted = useMemo(() => {
    let filteredRows: OverviewNodeFragment[] = camJobs

    if (selectedTabId === Camjobstatus.Programming) {
      // Sort by promised date (or created date if promised date is not present)
      filteredRows = lodashSortBy(filteredRows, o =>
        new Date(o.job.promisedDate ?? o.job.createdAt ?? "").getTime()
      )
      // Put high-priority items above low-priority items, but only on the "current" tab
      filteredRows = lodashSortBy(filteredRows, o => _PRIORITY_MAP[o.job.priority])
    } else {
      // Put most recent jobs first outside of the "current" tab
      filteredRows = lodashSortBy(filteredRows, o =>
        new Date(o.job.promisedDate ?? o.job.createdAt ?? "").getTime()
      )
      filteredRows.reverse()
    }

    return filteredRows
  }, [camJobs, selectedTabId])

  const sortByFn = useMemo(() => {
    return (a: OverviewNodeFragment, b: OverviewNodeFragment) => {
      const ascendingMultiplier = sortBy.ascending ? 1 : -1

      switch (sortBy.sortBy) {
        case JobsSortByEnum.label: {
          const aMatch = a.job.label.match(/([a-zA-Z]+)-([0-9]+)(.*)$/)
          const bMatch = b.job.label.match(/([a-zA-Z]+)-([0-9]+)(.*)$/)
          if (aMatch && bMatch) {
            // Treat first field as a string
            if (aMatch[1] !== bMatch[1])
              return ascendingMultiplier * aMatch[1].localeCompare(bMatch[1])
            // Treat second field as a number
            if (aMatch[2] !== bMatch[2]) return ascendingMultiplier * (+aMatch[2] - +bMatch[2])
            // Treat remaining text (re√ision?) as a string
            if (aMatch[3] !== bMatch[3])
              return ascendingMultiplier * aMatch[3].localeCompare(bMatch[3])
          }
          // Use standard string comparison if the match process fails to return a value
          return ascendingMultiplier * a.job.label.localeCompare(b.job.label)
        }
        case JobsSortByEnum.promisedDate: {
          return (
            ascendingMultiplier *
            (new Date(a.job.promisedDate).getTime() - new Date(b.job.promisedDate).getTime())
          )
        }
        case JobsSortByEnum.createdAt: {
          return (
            ascendingMultiplier *
            (new Date(a.job.createdAt).getTime() - new Date(b.job.createdAt).getTime())
          )
        }
        case JobsSortByEnum.materialDisplayName: {
          return (
            ascendingMultiplier * a.job.materialDisplayName.localeCompare(b.job.materialDisplayName)
          )
        }
        case JobsSortByEnum.lastUpdated: {
          return (
            ascendingMultiplier *
            (new Date(a.job.lastUpdated).getTime() - new Date(b.job.lastUpdated).getTime())
          )
        }
        case JobsSortByEnum.priority: {
          return (
            ascendingMultiplier * (_PRIORITY_MAP[b.job.priority] - _PRIORITY_MAP[a.job.priority])
          )
        }
        default:
          return 0
      }
    }
  }, [sortBy])

  // If we want to get the status column back, undo https://github.com/subscale/remoteshop/pull/3875
  // Just know that is has a huge performance impact because of the deep comparison of operations it does.
  return (
    <HTMLTable
      className={styles.jobsTable}
      bordered={true}
      condensed={true}
      interactive={true}
      striped={true}
    >
      <thead>
        <tr>
          <th></th>
          <th
            className={styles.jobsHeaderCell}
            onClick={() => handleClickSort(JobsSortByEnum.label)}
          >
            Part Name {getJobsSortIcon(JobsSortByEnum.label)}
          </th>
          <th
            className={styles.dateCell}
            onClick={() => handleClickSort(JobsSortByEnum.promisedDate)}
          >
            Ships On {getJobsSortIcon(JobsSortByEnum.promisedDate)}
          </th>
          <th className={styles.dateCell} onClick={() => handleClickSort(JobsSortByEnum.createdAt)}>
            Created {getJobsSortIcon(JobsSortByEnum.createdAt)}
          </th>
          <th
            className={styles.jobsHeaderCell}
            onClick={() => handleClickSort(JobsSortByEnum.materialDisplayName)}
          >
            Material {getJobsSortIcon(JobsSortByEnum.materialDisplayName)}
          </th>
          <th
            className={styles.dateCell}
            onClick={() => handleClickSort(JobsSortByEnum.lastUpdated)}
          >
            Last Updated {getJobsSortIcon(JobsSortByEnum.lastUpdated)}
          </th>
          <th>Assignee</th>
          <th
            className={styles.jobsHeaderCell}
            onClick={() => handleClickSort(JobsSortByEnum.priority)}
          >
            Priority {getJobsSortIcon(JobsSortByEnum.priority)}
          </th>
          {enablePriorityEditing && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {sortCurrentUserPriority(camJobsDefaultSorted.sort(sortByFn)).map(val => {
          return (
            <JobsRow node={val} key={val.job.id} enablePriorityEditing={enablePriorityEditing} />
          )
        })}
      </tbody>
    </HTMLTable>
  )
}

export const renderDate: (date: string | null | undefined) => string | null | undefined = (
  date: string | null | undefined
) => {
  if (!date) return date
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
}

const JobsRow: FC<{
  node: OverviewNodeFragment
  enablePriorityEditing: boolean
}> = ({ node, enablePriorityEditing }) => {
  const job = node.job

  return (
    <tr>
      <td className={styles.centerCell}>
        <Link to={`/jobs/${job.uri}`}>
          <Thumbnail
            model={{
              id: job.modelId,
              thumbnail: {
                exists: job.thumbnailExists,
                locator: job.thumbnailLocator,
              },
            }}
            small
          />
        </Link>
      </td>
      <td>
        <Link to={`/jobs/${job.uri}`}>
          <div className={styles.partName}>
            <span
              className={job.status === Camjobstatus.Archived ? Classes.TEXT_MUTED : undefined}
              title={job.label}
            >
              {job.label}
            </span>
          </div>
        </Link>
      </td>
      <td className={styles.dateCell}>{job.promisedDate ? renderDate(job.promisedDate) : ""}</td>
      <td className={styles.dateCell}>{renderDate(job.createdAt)}</td>
      <td>
        <div>{job.materialDisplayName}</div>
      </td>
      <td className={styles.dateCell}>{renderDate(job.lastUpdated)}</td>
      <td className={styles.centerCell}>
        <Assignee jobId={job.id} camProgrammers={job?.camProgrammers} />
      </td>
      <td className={styles.centerCell}>
        <PrioritySelector camJob={job} />
      </td>
      {enablePriorityEditing && (
        <td className={styles.centerCell}>
          <JobActions camJob={job} />
        </td>
      )}
    </tr>
  )
}

const _PRIORITY_MAP = {
  [Priority.OnHold]: 0,
  [Priority.Low]: -1,
  [Priority.Standard]: -2,
  [Priority.High]: -3,
}
