import { useMemo } from "react"

import { VericutToolChangeDetails } from "src/client-axios"
import {
  NcEventNodeFragment,
  ToolInfoNodeFragment,
  VericutFeedbackNodeFragment,
} from "src/graphql/generated"

export interface WcsData {
  row: number
  wcs: string
}

export interface ToolchangeData {
  row: number
  toolId: string
  comment?: string | null
  toolInfo?: ToolInfoNodeFragment
  vericutDetails?: VericutToolChangeDetails
  minFeed?: number
  radialCutterComp: boolean
  wcses: WcsData[]
}

const NO_TOOL_ID = 0
const CALIBRATION_TOOL_ID = 59
const PROBE_TOOL_ID = 60
const IGNORED_TOOL_IDS = new Set([NO_TOOL_ID, CALIBRATION_TOOL_ID, PROBE_TOOL_ID])

export const useToolchangesData = (
  tools: ToolInfoNodeFragment[],
  ncEvents: NcEventNodeFragment[],
  feedback?: VericutFeedbackNodeFragment
): ToolchangeData[] => {
  const toolsInfoMap = useMemo(() => {
    const toolsInfoMap: Record<string, ToolInfoNodeFragment | undefined> = {}
    tools.forEach(val => {
      toolsInfoMap[val.id] = val
    })
    return toolsInfoMap
  }, [tools])

  const toolchangesDetails = useMemo(() => getToolchangesDetails(feedback), [feedback])

  return useMemo(() => {
    const toolchangeTableRowsData: ToolchangeData[] = []
    const workingToolchangesDetails = [...toolchangesDetails]

    let currentRowData: ToolchangeData | undefined
    ncEvents.forEach(event => {
      switch (event.kind) {
        case "TOOL": {
          if (IGNORED_TOOL_IDS.has(Number(event.val))) {
            break
          }

          const toolInfo = toolsInfoMap[event.val]
          let toolchangeDetails: VericutToolChangeDetails | undefined
          if (
            workingToolchangesDetails.length > 0 &&
            `${workingToolchangesDetails[0].tool}` === event.val
          ) {
            toolchangeDetails = workingToolchangesDetails.shift()
          }
          currentRowData = {
            row: event.row,
            toolId: event.val,
            comment: event.comment,
            toolInfo,
            vericutDetails: toolchangeDetails,
            radialCutterComp: false,
            wcses: [],
          }
          toolchangeTableRowsData.push(currentRowData)
          break
        }
        case "COMP": {
          if (!currentRowData) break
          currentRowData.radialCutterComp = true
          break
        }
        case "FEED": {
          const feedValue = +event.val
          if (!Number.isFinite(feedValue)) break
          if (!currentRowData) break
          if (currentRowData.minFeed === undefined) {
            currentRowData.minFeed = feedValue
          } else {
            currentRowData.minFeed = Math.min(currentRowData.minFeed, feedValue)
          }
          break
        }
        case "WCS": {
          if (!currentRowData) break
          currentRowData.wcses.push({
            row: event.row,
            wcs: event.val,
          })
          break
        }
      }
    })
    return toolchangeTableRowsData
  }, [ncEvents, toolchangesDetails, toolsInfoMap])
}

export interface OldVericutToolChangeDetails {
  air_time: number
  cut_distance: number
  cutter_height: number
  cutter_stickout: number
  description: string
  distance: number
  nc_line: string
  record: number
  seq: number
  time: number
  tool: number
}

function oldToNew(old: OldVericutToolChangeDetails): VericutToolChangeDetails {
  return {
    airTime: old.air_time,
    cutDistance: old.cut_distance,
    cutterHeight: old.cutter_height,
    cutterStickout: old.cutter_stickout,
    ncLine: old.nc_line,
    ...old,
  }
}

const getToolchangesDetails = (
  feedback?: VericutFeedbackNodeFragment
): VericutToolChangeDetails[] => {
  // TODO: Get a little more type safety here...
  return (
    ((feedback?.toolchangeDetails as unknown) as OldVericutToolChangeDetails[]) ?? []
  ).map(old => oldToNew(old))
}
