import { createAction, createReducer } from "@reduxjs/toolkit"
import { Maybe } from "graphql/jsutils/Maybe"

import { RootState } from "src/store/rootStore"

const setViewerModalIsOpen = createAction<boolean>("viewerModal/setViewerModalIsOpen")
const setViewerModalFilterPlans = createAction<boolean>("viewerModal/setViewerModalFilterPlans")
const setViewerModalModelId = createAction<string | undefined | null>(
  "viewerModal/setViewerModalModelId"
)
const setViewerModalJobId = createAction<string | undefined | null>(
  "viewerModal/setViewerModalJobId"
)
const setViewerModalMode = createAction<ViewerModalMode | undefined | null>(
  "viewerModal/setViewerModalMode"
)
const setViewerModalAssignees = createAction<Maybe<string[]> | undefined>(
  "viewerModal/setViewerModalAssignees"
)

export enum ViewerModalMode {
  Setup = "setup",
  Part = "part",
}

export enum ViewerModalModeLegacy {
  Setup = "SETUP",
  Part = "PART",
}

export const viewerModalReducer = createReducer<{
  viewerModalIsOpen: boolean
  viewerModalFilterPlans: boolean
  viewerModalModelId: string | undefined | null
  viewerModalJobId: string | undefined | null
  viewerModalMode: ViewerModalMode | undefined | null
  viewerModalAssignees: Maybe<string[]> | undefined
}>(
  {
    viewerModalIsOpen: false,
    viewerModalFilterPlans: true,
    viewerModalModelId: undefined,
    viewerModalJobId: undefined,
    viewerModalMode: undefined,
    viewerModalAssignees: undefined,
  },
  builder => {
    builder.addCase(setViewerModalIsOpen, (state, action) => {
      state.viewerModalIsOpen = action.payload
    })
    builder.addCase(setViewerModalFilterPlans, (state, action) => {
      state.viewerModalFilterPlans = action.payload
    })
    builder.addCase(setViewerModalModelId, (state, action) => {
      state.viewerModalModelId = action.payload
    })
    builder.addCase(setViewerModalJobId, (state, action) => {
      state.viewerModalJobId = action.payload
    })
    builder.addCase(setViewerModalMode, (state, action) => {
      state.viewerModalMode = action.payload
    })
    builder.addCase(setViewerModalAssignees, (state, action) => {
      state.viewerModalAssignees = action.payload
    })
  }
)

/**
 * Actions
 */
export const viewerModalActions = {
  setViewerModalIsOpen,
  setViewerModalFilterPlans,
  setViewerModalModelId,
  setViewerModalJobId,
  setViewerModalMode,
  setViewerModalAssignees,
}

/**
 * Selectors
 */
const selectIsOpen = (state: RootState): boolean => state.viewerModal.viewerModalIsOpen
const selectFilterPlans = (state: RootState): boolean => state.viewerModal.viewerModalFilterPlans
const selectModelId = (state: RootState): string | undefined =>
  state.viewerModal.viewerModalModelId ?? undefined
const selectJobId = (state: RootState): string | undefined =>
  state.viewerModal.viewerModalJobId ?? undefined
const selectMode = (state: RootState): ViewerModalMode | undefined =>
  state.viewerModal.viewerModalMode ?? undefined
const selectAssignees = (state: RootState): Maybe<string[]> | undefined =>
  state.viewerModal.viewerModalAssignees ?? undefined

export const viewerModalSelectors = {
  selectIsOpen,
  selectFilterPlans,
  selectModelId,
  selectJobId,
  selectMode,
  selectAssignees,
}
