import React, { FC, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import {
  Button,
  Divider,
  Icon,
  Intent,
  IToastProps,
  ProgressBar,
  Spinner,
  Toaster,
} from "@blueprintjs/core"
import { useAuth } from "@subscale/formlogic-auth-ts"
import { debounce as lodashDebounce } from "lodash-es"

import { PlanchangerApi } from "src/client-axios"
import {
  Taskstatus,
  useJobBackupForTaskLazyQuery,
  useMesPublishingsQuery,
  useModelsArchiveForTaskLazyQuery,
  useWorkflowProgressQuery,
  useWorkflowTaskProgressDataQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useEventListener } from "src/hooks/useEventListener"
import { useToaster } from "src/hooks/useToaster"
import { activeSelectors } from "src/store/cam/active"
import { PatchOperation, plansActions } from "src/store/cam/storedPlans"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { AppDispatch, rootStore, useAppDispatch } from "src/store/rootStore"
import { downloadFromUrl } from "src/util/files"
import { StopTaskDialog } from "./StopTaskDialog/StopTaskDialog"
import { Idle } from "./idle"

import styles from "./Websocket.module.css"

interface PlanDeleted {
  type: "PlanDeleted"
  planId: string
}

interface PlanInvalidated {
  type: "PlanInvalidated"
  planId: string
  reason: string
}

interface PlanUpdate {
  type: "PlanUpdate"
  ops: PatchOperation[]
  planId: string
  changeToken?: string
}

interface PlanPublished {
  type: "PlanPublished"
  planId: string
}

interface JobToast {
  type: "JobToast"
  jobId: string
  toast: IToastProps
}

interface ConfigReload {
  type: "ConfigReload"
}

export enum TaskStatus {
  Pending = "Pending",
  Processing = "Processing",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

const debounce = lodashDebounce(() => {
  if (websocket) {
    websocket.send(
      JSON.stringify({
        type: "heartbeat",
      })
    )
  }
}, 1000)

new Idle({
  onActivity: () => {
    debounce()
  },
})

export const fromGraphQLStatus = (gqlStatus?: Taskstatus): TaskStatus | undefined => {
  switch (gqlStatus) {
    case Taskstatus.Error:
      return TaskStatus.Error
    case Taskstatus.Pending:
      return TaskStatus.Pending
    case Taskstatus.Processing:
      return TaskStatus.Processing
    case Taskstatus.Success:
      return TaskStatus.Success
    case Taskstatus.Warning:
      return TaskStatus.Warning
    default:
      return undefined
  }
}

interface TaskProgress {
  type: "TaskProgress"
  id: string
  runId?: string
  jobId: string
  planId?: string
  operationIdx?: number
  name: string
  progress: number
  status: TaskStatus
  message?: string
}

type WsMessage =
  | JobToast
  | PlanUpdate
  | TaskProgress
  | ConfigReload
  | PlanInvalidated
  | PlanDeleted
  | PlanPublished

interface WsEvent {
  name: string
  context?: Record<string, unknown>
}

const WEBSOCKET_MESSAGE = "WEBSOCKET_MESSAGE"

export const useWebsocketMessageListener = (handler: (wsMessage: WsMessage) => void): void => {
  useEventListener(
    WEBSOCKET_MESSAGE,
    (event: Event) => {
      const wsEvent = event as CustomEvent<WsMessage>
      handler(wsEvent.detail)
    },
    window
  )
}

// The buffer is used to queue up events if the websocket goes down for whatever reason
let buffer: WsEvent[] = []
let websocket: WebSocket | undefined

export const sendEvent = (name: string, context?: Record<string, unknown>): void => {
  if (websocket) {
    websocket.send(
      JSON.stringify({
        type: "event",
        name,
        context,
      })
    )
  } else {
    buffer.push({ name, context })
  }
}

export const niceName = (name: string): string => {
  const startIdx = name.lastIndexOf(".") + 1

  return name
    .slice(startIdx)
    .split("_")
    .filter(comps => comps !== "task")
    .map(comps => {
      return comps.charAt(0).toLocaleUpperCase() + comps.slice(1)
    })
    .join(" ")
}

interface SimStatus {
  preSim: TaskProgress
  vericut: TaskProgress
  review?: TaskProgress
  stock: TaskProgress
  postSim: TaskProgress
  probing: TaskProgress
}

export const SimRunProgress: FC<{
  currentProgress: TaskProgress
  runId: string
  toaster: Toaster
}> = ({ currentProgress, runId, toaster }) => {
  // This backfills the statuses if we've loaded the page halfway through
  const { data } = useWorkflowProgressQuery({ variables: { runId } })
  const { data: workflowTaskProgressData } = useWorkflowTaskProgressDataQuery({
    variables: { id: currentProgress.id },
  })

  useEffect(() => {
    if (data?.taskProgresses) {
      data.taskProgresses.nodes.forEach(val => {
        const convertedProgress: TaskProgress = {
          type: "TaskProgress",
          id: val.id,
          jobId: val.jobId ?? "",
          runId: val.runId ?? undefined,
          name: val.name,
          progress: val.progress,
          status: fromGraphQLStatus(val.status) ?? TaskStatus.Pending,
        }

        status.current = getSimStatus(convertedProgress, status.current)

        // rerender
        toaster.show(
          {
            message: (
              <SimRunProgress runId={runId} currentProgress={currentProgress} toaster={toaster} />
            ),
            timeout: 1800000,
          },
          runId
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const status = useRef(getSimStatus(currentProgress))
  status.current = getSimStatus(currentProgress, status.current)

  // This is used to trigger a rerender when things have finished
  useEffect(() => {
    // might be a no-op but we should leave it here just incase
    status.current = getSimStatus(currentProgress, status.current)

    if (simStatusComplete(status.current)) {
      // rerender with a timeout
      toaster.show(
        {
          message: (
            <SimRunProgress runId={runId} currentProgress={currentProgress} toaster={toaster} />
          ),
          timeout: 60000,
        },
        runId
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress])

  console.log(currentProgress)

  const simWorkflowTitle = () => {
    const operationIdx = currentProgress.operationIdx
    const createdBy = workflowTaskProgressData?.taskProgress?.createdBy
    const labels = workflowTaskProgressData?.taskProgress?.plan?.plan
    if (typeof operationIdx === "number" && labels) {
      const opLabel = labels.operations[operationIdx]?.label
      const userName = createdBy?.split(" ")[0]
      return (
        <h6 className={"bp3-heading"}>
          Sim for {opLabel} ({userName})
        </h6>
      )
    }
    return <></>
  }

  return (
    <>
      {simWorkflowTitle()}
      <div className={styles.simRunHeader}>
        <SimIcon value={status.current.preSim} />
        <span className={styles.simRunLabel}>Pre-Sim</span>
        <Divider />
        <SimIcon value={status.current.vericut} />
        <span className={styles.simRunLabel}>Vericut</span>
        <Divider />
        {status.current.review && (
          <>
            <SimIcon value={status.current.review} />
            <span className={styles.simRunLabel}>Upload</span>
            <Divider />
          </>
        )}
        <SimIcon value={status.current.stock} />
        <span className={styles.simRunLabel}>Stock</span>
        <Divider />
        <SimIcon value={status.current.probing} />
        <span className={styles.simRunLabel}>Probing</span>
        <Divider />
        <SimIcon value={status.current.postSim} />
        <span className={styles.simRunLabel}>Post-Sim</span>
      </div>
      <ToastProgress value={currentProgress} />
    </>
  )
}

const SimIcon: FC<{ value: TaskProgress }> = ({ value }) => {
  switch (value.status) {
    case TaskStatus.Pending:
      return <Spinner size={20} value={0} />
    case TaskStatus.Processing:
      return <Spinner size={20} value={value.progress === 0 ? undefined : value.progress} />
    case TaskStatus.Success:
      return <Icon size={20} icon="tick-circle" intent="success" />
    case TaskStatus.Warning:
      return <Icon size={20} icon="warning-sign" intent="warning" />
    case TaskStatus.Error:
      return <Icon size={20} icon="error" intent="danger" />
  }
}

const isTaskDone = (taskProgress: TaskProgress): boolean => {
  return taskProgress.status !== TaskStatus.Processing && taskProgress.status !== TaskStatus.Pending
}

const ToastProgressDoneDownload: FC<{ value: TaskProgress }> = ({ value }) => {
  const { planchangerApi } = useApi()
  const [getModelsArchive] = useModelsArchiveForTaskLazyQuery({
    onCompleted: data => {
      const locator = data?.modelsArchiveByTaskId?.file?.locator
      if (locator) {
        downloadArchive(locator)
      }
    },
  })
  const [getJobBackup] = useJobBackupForTaskLazyQuery({
    onCompleted: data => {
      const locator = data?.jobBackupByTaskId?.file?.locator
      if (locator) {
        downloadArchive(locator)
      }
    },
  })

  const taskId = value.id

  const downloadArchive = (locator: string) => {
    planchangerApi.urlFor_getFile(locator).then(url => downloadFromUrl(url.toString()))
  }

  useEffect(() => {
    getModelsArchive({ variables: { taskId: taskId } })
    getJobBackup({ variables: { taskId: taskId } })
  }, [getModelsArchive, getJobBackup, taskId])

  return (
    <div>
      <span>{niceName(value.name)} Completed!</span>
    </div>
  )
}

const ToastProgress: FC<{ value: TaskProgress }> = ({ value }) => {
  let intent: Intent = "none"

  const [toStop, setToStop] = useState<string | undefined>()
  const [forceClose, setForceClose] = useState(false)

  switch (value.status) {
    case TaskStatus.Error:
      intent = "danger"
      break
    case TaskStatus.Processing:
      intent = "primary"
      break
    case TaskStatus.Warning:
      intent = "warning"
      break
    case TaskStatus.Success:
      intent = "success"
      break
    case TaskStatus.Pending:
    default:
      intent = "none"
  }

  return (
    <div>
      {niceName(value.name)}
      {value.message && (
        <pre className={"bp3-code-block " + styles.taskBlockMessage}>{value.message}</pre>
      )}
      <div className={styles.progressContainer}>
        <ProgressBar
          intent={intent}
          animate={!isTaskDone(value)}
          stripes={!isTaskDone(value)}
          className={styles.toastProgress}
          value={value.progress}
        />
        {(value.status === "Processing" || value.status === "Pending") && (
          <div className={styles.stopButtonContainer}>
            <Button
              className={styles.stopButton}
              minimal
              onClick={() => {
                if (value?.id) {
                  setToStop(value.id)
                }
              }}
              icon="stop"
            >
              Stop
            </Button>
          </div>
        )}
      </div>
      <StopTaskDialog
        forceClose={forceClose}
        setForceClose={setForceClose}
        toStop={toStop}
        setToStop={setToStop}
      />
    </div>
  )
}

const defaultProgress: TaskProgress = {
  type: "TaskProgress",
  id: "",
  jobId: "",
  name: "",
  progress: 0,
  status: TaskStatus.Pending,
}

const getSimStatus = (progress: TaskProgress, simRun?: SimStatus): SimStatus => {
  if (simRun === undefined) {
    simRun = {
      preSim: defaultProgress,
      vericut: defaultProgress,
      stock: defaultProgress,
      probing: defaultProgress,
      postSim: defaultProgress,
    }
  }
  switch (progress.name) {
    case "app.worker_app.tasks.misc.run_guardrail_presim_checks_task":
      simRun.preSim = progress
      break
    case "run_vericut_simulation":
      simRun.vericut = progress
      break
    case "uploading_reviewer_file":
      simRun.review = progress
      break
    case "app.worker_app.tasks.misc.simulate_stock_gcode_mapping_task":
      simRun.stock = progress
      break
    case "app.worker_app.tasks.probing.validate_probing_task":
      simRun.probing = progress
      break
    case "app.worker_app.tasks.misc.run_guardrail_postsim_checks_task":
      simRun.postSim = progress
      break
  }

  return simRun
}

const simStatusComplete = (status: SimStatus) => {
  return (
    isTaskDone(status.preSim) &&
    isTaskDone(status.vericut) &&
    isTaskDone(status.stock) &&
    isTaskDone(status.probing) &&
    isTaskDone(status.postSim)
  )
}

const onJobProgressToast = (progress: TaskProgress, toaster: Toaster) => {
  if (
    rootStore.getState().active.activeJobId === progress.jobId ||
    // This checks if there is an active toast.
    // It is needed because if a toast is showing progress and you navigate away then the toast will stay around forever
    toaster.getToasts().some(toast => toast.key === progress.id)
  ) {
    // We want to show the "special" toaster if this is a sim/guardrail run
    if (progress.runId) {
      toaster.show(
        {
          message: (
            <SimRunProgress runId={progress.runId} currentProgress={progress} toaster={toaster} />
          ),
          timeout: 1800000,
        },
        progress.runId
      )
      return
    }

    const nName = niceName(progress.name)

    switch (progress.status) {
      case TaskStatus.Pending:
        toaster.show(
          {
            message: <ToastProgress value={progress} />,
            timeout: 10000,
          },
          progress.runId ?? progress.id
        )
        break
      case TaskStatus.Processing:
        toaster.show(
          {
            icon: "time",
            message: <ToastProgress value={progress} />,
            timeout: 0,
          },
          progress.runId ?? progress.id
        )
        break
      case TaskStatus.Success:
        toaster.show(
          {
            icon: "tick-circle",
            message: <ToastProgressDoneDownload value={progress} />,
            timeout: 10000,
            intent: "success",
          },
          progress.id
        )
        break
      case TaskStatus.Warning:
        toaster.show(
          {
            icon: "tick-circle",
            message: progress.message
              ? `${nName} Finished with warnings: ${progress.message}`
              : `${nName} Finished with warnings`,
            timeout: 10000,
            intent: "warning",
          },
          progress.id
        )
        break

      case TaskStatus.Error:
        toaster.show(
          {
            icon: "error",
            message: progress.message ? (
              <>
                {nName} Failed:
                <pre className={"bp3-code-block " + styles.taskBlockMessage}>
                  {progress.message}
                </pre>
              </>
            ) : (
              `${nName} Failed!`
            ),
            timeout: 20000,
            intent: "danger",
          },
          progress.id
        )
        break
    }
  }
}

const wsKey = "websocket_error_toast"

const setupWebsocket = (
  token: string,
  dispatch: AppDispatch,
  planchangerApi: PlanchangerApi,
  toaster: Toaster,
  refetchMesPublishes: ({ planId }: { planId: string }) => void
) => {
  const loc = window.location
  const uri = `${loc.protocol === "https:" ? "wss:" : "ws:"}//${
    loc.host
  }/api/rs/v1/subscribe?access_token=${token}`
  const connection = new WebSocket(uri)

  console.log("Subscribing to websocket events")
  connection.onclose = reason => {
    websocket = undefined
    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
    if (reason.code !== 1000 && reason.code !== 1001) {
      console.error("Websocket connection closed", reason)
      toaster.show(
        {
          icon: "offline",
          intent: "danger",
          message: "Connection to RemoteShop lost.  Reconnecting...",
          timeout: 0,
        },
        wsKey
      )
      // May need to trigger a plan reload here too
      setTimeout(() => {
        setupWebsocket(token, dispatch, planchangerApi, toaster, refetchMesPublishes)
      }, 500)
    }
  }

  connection.onopen = () => {
    const toSend = buffer
    buffer = []

    toSend.forEach(val => {
      connection.send(
        JSON.stringify({
          type: "event",
          ...val,
        })
      )
    })

    if (toaster.getToasts().some(val => val.key === wsKey)) {
      toaster.show(
        {
          icon: "offline",
          intent: "success",
          message: "Reconnected to RemoteShop!",
          timeout: 10000,
          action: {
            onClick: () => {
              window.location.reload()
            },
            text: "Refresh",
            icon: "refresh",
          },
        },
        wsKey
      )
    }

    console.log("Websocket opened!")
    websocket = connection
  }

  connection.onerror = error => {
    console.error("Error with websocket", error)
    connection.close()
  }

  connection.onmessage = message => {
    const changeToken = rootStore.getState().active.changeToken
    const wsMessage: WsMessage | undefined = parseWsMessage(
      JSON.parse(message.data) as Record<string, unknown>
    )
    if (!wsMessage) {
      console.error("Unknown Websocket Message Received:", wsMessage)
      return
    }

    const event = new CustomEvent<WsMessage>(WEBSOCKET_MESSAGE, { detail: wsMessage })

    switch (wsMessage.type) {
      case "PlanDeleted": {
        console.warn("Plan", wsMessage.planId, "has been deleted")

        const plan = rootStore.getState().plans.entities[wsMessage.planId]?.plan

        if (plan !== undefined) {
          dispatch(plansActions.remove(wsMessage.planId))
        }

        break
      }

      case "PlanInvalidated": {
        console.error("Plan", wsMessage.planId, "has been invalidated:", wsMessage.reason)

        const plan = rootStore.getState().plans.entities[wsMessage.planId]?.plan

        // Only refresh the plan if it's loaded
        if (plan !== undefined) {
          toaster.show({
            icon: "offline",
            intent: "warning",
            message: (
              <>
                Plan {plan.label} has been invalidated. Reloading Plan.
                <pre className={"bp3-code-block " + styles.taskBlockMessage}>
                  {wsMessage.reason}
                </pre>
              </>
            ),
            timeout: 10000,
          })
          dispatch(storedPlanThunks.fetchPlanById({ planId: wsMessage.planId, planchangerApi }))
        }

        break
      }

      case "PlanUpdate":
        if (changeToken === wsMessage.changeToken) {
          // console.log("Received previously-submitted PlanUpdate:", wsMessage)
          break
        } else {
          console.log("PlanUpdate received:", wsMessage)
          dispatch(
            plansActions.applyPatch({
              id: wsMessage.planId,
              operations: wsMessage.ops,
            })
          )
        }
        break

      case "PlanPublished": {
        const plan = rootStore.getState().plans.entities[wsMessage.planId]?.plan

        // Only refresh the plan if it's loaded
        if (plan !== undefined) {
          refetchMesPublishes({ planId: wsMessage.planId })
          dispatch(storedPlanThunks.fetchPlanById({ planId: wsMessage.planId, planchangerApi }))
        }

        break
      }

      case "JobToast":
        console.log("Toast received:", wsMessage)

        if (rootStore.getState().active.activeJobId === wsMessage.jobId) {
          // const className = wsMessage.toast.intent === Intent.DANGER ?  : undefined
          toaster.show({ ...wsMessage.toast, className: styles.toastText })
        }

        break

      case "TaskProgress":
        onJobProgressToast(wsMessage, toaster)
        break

      case "ConfigReload":
        console.log("Config reload received")
        break

      default:
        console.warn("Unknown Websocket Message Received:", wsMessage)
    }

    window.dispatchEvent(event)
  }
}

export const Websocket: FC = () => {
  const dispatch = useAppDispatch()

  const { planchangerApi } = useApi()
  const toaster = useToaster()

  const { getAccessTokenSilently } = useAuth()

  getAccessTokenSilently().then(val => {
    setAuthToken(val)
  })

  const [authToken, setAuthToken] = useState<string | undefined>()

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  const { refetch: refetchMesPublishes } = useMesPublishingsQuery({
    variables: { planId: activePlanId ?? "" },
  })

  useEffect(() => {
    if (authToken) {
      console.log("Setting up websocket")
      return setupWebsocket(authToken, dispatch, planchangerApi, toaster, refetchMesPublishes)
    }
  }, [authToken, dispatch, planchangerApi, refetchMesPublishes, toaster])

  return <></>
}

const parseWsMessage = (record: Record<string, unknown>): WsMessage | undefined => {
  return (record as unknown) as WsMessage
}
