// TODO: Should probably drop the handleUndefined argument at some point,
//  but at the time this code was written, it made it easier to ensure that nothing broke
export function withPartialUpdates<T>(item: T, updates: Partial<T>, handleUndefined?: boolean): T {
  const updatedItem = { ...item }
  for (const key in updates) {
    const value = updates[key]
    if (value != null || handleUndefined) {
      updatedItem[key] = value as T[Extract<keyof T, string>]
    }
  }
  return updatedItem
}
