import React, { FC, useCallback, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IToasterProps, Toaster } from "@blueprintjs/core"
import { AxiosError } from "axios"

import { activeSelectors } from "src/store/cam/active"

export const ToasterContext = React.createContext<Toaster | null>(null)

export const ToasterProvider: FC<IToasterProps> = ({ children, ...props }) => {
  const [toaster, setToaster] = useState<Toaster | null>(null)
  const activeToastMessage = useSelector(activeSelectors.selectActiveToastMessage)

  useEffect(() => {
    if (activeToastMessage) {
      toaster?.show(activeToastMessage)
    }
  }, [activeToastMessage, toaster])

  return (
    <ToasterContext.Provider value={toaster}>
      <Toaster ref={setToaster} {...props} />
      {toaster && children}
    </ToasterContext.Provider>
  )
}

export const useToaster = (): Toaster => {
  const toaster = useContext(ToasterContext)
  if (!toaster) {
    throw new Error("No Toaster was present")
  }
  return toaster
}

export const useNetworkErrorToast = (): ((
  error: AxiosError,
  shortDescription: string,
  key?: string
) => void) => {
  const toaster = useToaster()
  return useCallback(
    (error: AxiosError, shortDescription: string, key) => {
      let message: React.ReactNode = shortDescription
      if (error.code) {
        message = `${message} ${error.code}`
      }

      const response = error.response
      if (response) {
        // best effort trying to guess the type of error from message body, could be JSON or plaintext
        let data = (response.data as unknown) as string | Record<string, unknown>
        if (data instanceof Object) {
          if (typeof data.detail === "string") {
            data = data.detail
          } else {
            data = JSON.stringify(data)
          }
        }

        message = (
          <>
            <p>{`${message}: ${response.status} ${response.statusText}`}</p>
            {data}
          </>
        )
        console.error(shortDescription, error, response)
      } else {
        console.error(shortDescription, error)
      }

      toaster.show({ message, intent: "danger" }, key)
    },
    [toaster]
  )
}
