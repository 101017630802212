import { StockSimSurfaceCylinderKindEnum } from "src/client-axios"

export enum CylinderOrientation {
  Forward = 0,
  Reversed = 1,
  Internal = 2,
  External = 3,
}

export interface CylinderData {
  face_idx: number
  direction: [number, number, number]
  location: [number, number, number]
  diameter: number
  min_angle: number
  max_angle: number
  height: number
  area: number
  orientation: CylinderOrientation
}

export interface StockSimCylinderSurfMachined {
  toolChange: number
  toolId: string
  inspected: boolean
  kind: StockSimSurfaceCylinderKindEnum
  geometryData: CylinderData
}
