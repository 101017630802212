import { rgb } from "pdf-lib"

import {
  BBox,
  Bubble,
  BubbleAppearance,
  DifficultyGroup,
  ShapeData,
  ShapesFilterOptions,
} from "src/pages/DrawingViewer/interfaces"

// ---------------
// Shape filtering
// ---------------

export const getFilteredShapes = (
  shapes: ShapeData[],
  options: ShapesFilterOptions
): ShapeData[] => {
  let result = [...shapes]

  if (options.pageIndex !== undefined) {
    result = result.filter(shape => shape.location.pageIndex === options.pageIndex)
  }
  if (!options.includeBasicDims) {
    result = result.filter(shape => !shape.isBasic)
  }
  if (!options.includeReferenceDims) {
    result = result.filter(shape => !shape.isReference)
  }
  return result
}

// -----------------
// Bubble conversion
// -----------------
export const getBubble = (shape: ShapeData): Bubble | undefined => {
  if (shape.dims.length === 0) return undefined

  const { label, scoring, location } = shape

  const appearance = getBubbleAppearance(scoring.difficultyGroup)

  return { label, location, appearance, scoring }
}

const getBubbleAppearance = (group: DifficultyGroup): BubbleAppearance => {
  switch (group) {
    case DifficultyGroup.yellow:
      return {
        fill: { color: rgb(217 / 255, 158 / 255, 11 / 255), bubbleOpacity: 0.7, boxOpacity: 0.15 },
        textColor: rgb(1.0, 1.0, 1.0),
      }
    case DifficultyGroup.blue:
      return {
        fill: { color: rgb(30 / 255, 91 / 255, 139 / 255), bubbleOpacity: 0.7, boxOpacity: 0.15 },
        textColor: rgb(1.0, 1.0, 1.0),
      }
    case DifficultyGroup.gray:
      return {
        fill: { color: rgb(0.95, 0.95, 0.95), bubbleOpacity: 0.0, boxOpacity: 0.0, hideBox: true },
        textColor: rgb(0.4, 0.4, 0.4),
      }
  }
}

// ----
// Misc
// ----
export const bboxKey = (bbox: BBox): string => {
  // May be a good idea to use toFixed on these numbers...
  return `bbox_${bbox.topLeft.x},${bbox.topLeft.y},${bbox.extents.x},${bbox.extents.y}`
}
