import * as THREE from "three"
import { LineBasicMaterialParameters } from "three"
import { MeshStandardMaterialParameters } from "three/src/materials/MeshStandardMaterial"

type MeshMaterialData = Pick<
  MeshStandardMaterialParameters,
  | "color"
  | "flatShading"
  | "metalness"
  | "opacity"
  | "polygonOffset"
  | "polygonOffsetFactor"
  | "roughness"
  | "side"
  | "transparent"
>

type WireMaterialData = Pick<LineBasicMaterialParameters, "color" | "opacity" | "transparent">

export type MaterialsData = {
  mesh: THREE.MeshStandardMaterial
  wire: THREE.LineBasicMaterial
}

export const buildMeshMaterial = (data: MeshMaterialData): THREE.MeshStandardMaterial => {
  return configureMeshMaterial(new THREE.MeshStandardMaterial(), data)
}

export const buildWireMaterial = (data: WireMaterialData): THREE.LineBasicMaterial => {
  return configureWireMaterial(new THREE.LineBasicMaterial(), data)
}

export const configureMeshMaterial = (
  material: THREE.MeshStandardMaterial,
  data: Partial<MeshMaterialData>
): THREE.MeshStandardMaterial => {
  if (data.color !== undefined) material.color.set(data.color)
  if (data.flatShading !== undefined) material.flatShading = data.flatShading
  if (data.metalness !== undefined) material.metalness = data.metalness
  if (data.opacity !== undefined) material.opacity = data.opacity
  if (data.polygonOffset !== undefined) material.polygonOffset = data.polygonOffset
  if (data.polygonOffsetFactor !== undefined)
    material.polygonOffsetFactor = data.polygonOffsetFactor
  if (data.roughness !== undefined) material.roughness = data.roughness
  if (data.side !== undefined) material.side = data.side
  if (data.transparent !== undefined) {
    material.depthWrite = !material.transparent
    material.transparent = data.transparent
  }
  return material
}

export const configureWireMaterial = (
  material: THREE.LineBasicMaterial,
  data: Partial<WireMaterialData>
): THREE.LineBasicMaterial => {
  if (data.color !== undefined) material.color.set(data.color)
  if (data.opacity !== undefined) material.opacity = data.opacity
  if (data.transparent !== undefined) material.transparent = data.transparent

  return material
}
