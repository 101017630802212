import * as THREE from "three"

import { AppModelsGeometryTransform as Transform, PartialTransform } from "src/client-axios"
import { intrinsicZYXRotation, quat2euler } from "src/components/Canvas/Viewer/geometry"

export const DEG2RAD = Math.PI / 180
export const RAD2DEG = 180 / Math.PI

export function transformToMatrix(transform?: PartialTransform): THREE.Matrix4 {
  if (!transform) return new THREE.Matrix4()

  const position = new THREE.Vector3(transform.x ?? 0, transform.y ?? 0, transform.z ?? 0)
  const quaternion = intrinsicZYXRotation(transform)
  const scale = new THREE.Vector3(1, 1, 1)

  const matrix = new THREE.Matrix4()
  matrix.compose(position, quaternion, scale)
  return matrix
}

export const matrixToTransform = (matrix: THREE.Matrix4): Transform => {
  const euler = quat2euler(new THREE.Quaternion().setFromRotationMatrix(matrix), "ZYX")
  const position = new THREE.Vector3().setFromMatrixPosition(matrix)
  return {
    x: position.x,
    y: position.y,
    z: position.z,
    i: euler.x * RAD2DEG,
    j: euler.y * RAD2DEG,
    k: euler.z * RAD2DEG,
  }
}

export const invertTransform = (transform: Transform): Transform => {
  const matrix = transformToMatrix(transform)
  matrix.invert()
  return matrixToTransform(matrix)
}

export function transformToSpringInputs(
  transform?: PartialTransform
): { position: [number, number, number]; rotation: [number, number, number] } {
  if (!transform) return { position: [0, 0, 0], rotation: [0, 0, 0] }

  const rotation = quat2euler(intrinsicZYXRotation(transform))
  return {
    position: [transform.x ?? 0, transform.y ?? 0, transform.z ?? 0],
    rotation: [rotation.x, rotation.y, rotation.z],
  }
}

/**
 * Removes any extra fields that happen to live on the object.
 *
 * This is necessary because graphql doesn't like having `__typename` properties on submission
 */
// export const sanitizedTransform = (transform: Transform): Transform => {
//   const { x, y, z, i, j, k } = transform
//   return { x, y, z, i, j, k }
// }

export const defaultTransform = (): Transform => {
  return { x: 0, y: 0, z: 0, i: 0, j: 0, k: 0 }
}

export const transformIncrementally = (
  transform: Transform,
  incrementalTransform: Transform,
  mcs?: Transform,
  wcs?: Transform
): Transform => {
  const partTransform = transformToMatrix({
    i: wcs?.i,
    j: wcs?.j,
    k: wcs?.k,
  })
  const mcsMatrix = transformToMatrix({
    x: 0,
    y: 0,
    z: 0,
    i: mcs?.i,
    j: mcs?.j,
    k: mcs?.k,
  })

  // Transform the "incremental" transform relative to
  // the composition of the WCS and MCS
  // This is legacy code ... I'm not 100% sure this is
  // correct.
  // The conjugationMatrix here, if the WCS is passed in, represents
  // the transformation from table coordinates to part coordinates

  // When just the MCS is passed in, it represents the
  // transformation from table coordinates to the part as
  // mounted in the fixture
  const invPartTransform = partTransform.clone().invert()
  const conjugationMatrix = invPartTransform.multiply(mcsMatrix)
  const incrementalMatrix = transformToMatrix(incrementalTransform)
  const invConjugationMatrix = conjugationMatrix.clone().invert()
  const finalIncrementalMatrix = conjugationMatrix
    .multiply(incrementalMatrix)
    .multiply(invConjugationMatrix)

  const oldMatrix = transformToMatrix(transform)
  return matrixToTransform(oldMatrix.multiply(finalIncrementalMatrix))
}

type MaybeTransform = THREE.Matrix4 | Transform | MatrixTransform | undefined

export class MatrixTransform {
  x = 0
  y = 0
  z = 0
  i = 0
  j = 0
  k = 0

  constructor(transform?: MaybeTransform) {
    let filledTransform

    if (transform instanceof THREE.Matrix4) {
      filledTransform = matrixToTransform(transform)
    } else {
      filledTransform = { ...defaultTransform(), ...transform }
    }

    this.x = filledTransform.x
    this.y = filledTransform.y
    this.z = filledTransform.z
    this.i = filledTransform.i
    this.j = filledTransform.j
    this.k = filledTransform.k
  }

  toMatrix(): THREE.Matrix4 {
    return transformToMatrix(this)
  }

  invert(): MatrixTransform {
    const matrix = transformToMatrix(this)
    matrix.invert()

    return new MatrixTransform(matrixToTransform(matrix))
  }

  multiply(other: MatrixTransform): MatrixTransform {
    const matrix = this.toMatrix().multiply(other.toMatrix())

    return new MatrixTransform(matrixToTransform(matrix))
  }
}
