import React, { createRef, FC, useCallback, useEffect, useMemo, useState } from "react"
import {
  ControlGroup,
  HTMLInputProps,
  Intent,
  INumericInputProps,
  NumericInput,
} from "@blueprintjs/core"
import { Parser } from "expr-eval"

interface FormulaInputProps {
  commitOnBlur: boolean
  reset?: boolean
  focus?: boolean
  suffix?: string
}

export const FormulaInput: FC<FormulaInputProps & HTMLInputProps & INumericInputProps> = ({
  className,
  onValueChange,
  commitOnBlur,
  value: sourceValue,
  minorStepSize,
  reset,
  focus,
  suffix,
  ...props
}) => {
  const inputRef = createRef<NumericInput>()
  const nDecimals = Math.max(Math.ceil(-Math.log10(minorStepSize ?? 1e-4)), 0)
  const value = parseFloat(Number(sourceValue).toFixed(nDecimals))
  const [parsedValue, setParsedValue] = useState<number | null>(
    isNaN(Number(value)) ? null : Number(value)
  )
  const [intentOverride, setIntentOverride] = useState<Intent | null>(null)
  const [controlledChangeHappened, setControlledChangeHappened] = useState(false)
  const [typingHappened, setTypingHappened] = useState(false)

  const [localValue, setLocalValue] = useState(
    value
      .toFixed(nDecimals)
      .replace(/([0-9]\.[0-9]*[1-9])0+$/g, "$1")
      .replace(/([0-9])\.0*$/g, "$1")
  )

  useEffect(() => {
    focus && inputRef.current?.inputElement?.focus()
  }, [focus, inputRef])

  useEffect(() => {
    const value = parseFloat(Number(sourceValue).toFixed(nDecimals))
    setLocalValue(
      value
        .toFixed(nDecimals)
        .replace(/([0-9]\.[0-9]*[1-9])0+$/g, "$1")
        .replace(/([0-9])\.0*$/g, "$1")
    )
  }, [nDecimals, sourceValue])

  const parser = useMemo(() => new Parser(), [])

  const combinedOnValueChange = useCallback(
    (_: number, valueAsString: string) => {
      setTypingHappened(true)
      setLocalValue(valueAsString)

      try {
        const value = parser.evaluate(valueAsString, {})
        setParsedValue(value)
      } catch (e) {
        setParsedValue(null)
      }
    },
    [parser]
  )

  useEffect(() => {
    if (parsedValue === null) {
      setIntentOverride(Intent.DANGER)
    } else if (props.min !== undefined && parsedValue < props.min) {
      setIntentOverride(Intent.WARNING)
    } else if (props.max !== undefined && parsedValue > props.max) {
      setIntentOverride(Intent.WARNING)
    } else {
      setIntentOverride(null)
    }
  }, [props.min, props.max, parsedValue])

  useEffect(() => {
    if (!value) return
    const propsValueNumber = Number(value)
    if (!isNaN(propsValueNumber)) {
      setParsedValue(propsValueNumber)
    }
  }, [value])

  const onValueConfirmed = useCallback(() => {
    setTypingHappened(false)
    if (parsedValue !== null && intentOverride === null) {
      if (`${value}` !== `${parsedValue}`) {
        onValueChange?.(parsedValue, `${parsedValue}`, null)
        if (reset) {
          setLocalValue(value.toString())
        }
      }
    }
  }, [onValueChange, value, parsedValue, intentOverride, reset])

  useEffect(() => {
    if (controlledChangeHappened) {
      onValueConfirmed()
      setControlledChangeHappened(false)
    }
  }, [controlledChangeHappened, onValueConfirmed])

  let sanitizedLocalValue = localValue
  if (!typingHappened) {
    if (localValue === "" || sanitizedLocalValue.includes(" ")) {
      // This indicates a formula is being entered; don't worry about preventing validation warnings
    } else if (Number.isNaN(localValue) || localValue === "NaN") {
      // Blueprint sanitizes NaN as "", we do this change to prevent warnings during development
      sanitizedLocalValue = ""
    } else if (Number.isFinite(Number(sanitizedLocalValue))) {
      // In the case where a number can be converted to be
      sanitizedLocalValue = Number(sanitizedLocalValue).toString()
      if (sanitizedLocalValue !== localValue) {
        setLocalValue(sanitizedLocalValue)
      }
    }
  }

  return (
    <ControlGroup>
      <NumericInput
        {...props}
        selectAllOnFocus={focus}
        ref={inputRef}
        className={className}
        intent={intentOverride ?? props.intent}
        onBlur={commitOnBlur ? onValueConfirmed : undefined}
        onKeyPress={e => {
          if (e.key === "Enter") {
            onValueConfirmed()
          } else {
            setTypingHappened(true)
          }
        }}
        onKeyDown={e => {
          if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            setControlledChangeHappened(true)
          }
        }}
        onValueChange={combinedOnValueChange}
        onButtonClick={() => setControlledChangeHappened(true)}
        value={sanitizedLocalValue}
        minorStepSize={Number(Math.pow(10, -nDecimals).toFixed(nDecimals))}
        allowNumericCharactersOnly={false}
      />
      {suffix && (
        <select value={suffix} disabled={true}>
          <option value={suffix}>{suffix}</option>
        </select>
      )}
    </ControlGroup>
  )
}
