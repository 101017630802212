import * as THREE from "three"

import { X_COLOR, Y_COLOR, Z_COLOR } from "src/util/ui/toolColor"
import { trihedronGroup } from "./Trihedron"

const THREE_X_COLOR = new THREE.Color(X_COLOR)
const THREE_Y_COLOR = new THREE.Color(Y_COLOR)
const THREE_Z_COLOR = new THREE.Color(Z_COLOR)

function modifyMaterial(material: THREE.Material) {
  material.onBeforeCompile = (shader: THREE.Shader) => {
    shader.vertexShader = shader.vertexShader.replace(
      "#include <common>",
      `
                attribute vec2 parameter;

                varying vec2 v_parameter;
                #include <common>
            `
    )

    shader.vertexShader = shader.vertexShader.replace(
      "#include <fog_vertex>",
      `
                v_parameter = parameter;
                #include <fog_vertex>
            `
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <common>",
      `
                varying vec2 v_parameter;

                #include <common>
            `
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <dithering_fragment>",
      `
                float E = 0.49;
                vec2 coord = abs(v_parameter - vec2(0.5));
                float t = max(coord.x, coord.y);
                float tt = smoothstep(E - 0.005, E, t);
                // float a = tt * 0.7 + 0.3;
                float a_min = 0.3;
                float a = tt * (1.0 - a_min) + a_min;
                float b = tt * 0.5 + 0.5;

                vec3 cc = mix(vec3(1.0), sqrt(totalDiffuse), b);
                float u = min(coord.x, coord.y);
                float uu = smoothstep(0.0025, 0.005, u);
                // cc = mix(vec3(0.05), cc, smoothstep(0.005, 0.01, uu));

                gl_FragColor.rgb = sqrt(totalDiffuse) * tt;
                // gl_FragColor.a = (1.0 - tt) * 0.8 + 0.2;
                gl_FragColor.rgb = cc;
                // gl_FragColor.rgb = vec3(smoothstep(0.0025, 0.005, u));
                gl_FragColor.a = a;
          `
    )
  }
}

function getPlaneMaterial(): THREE.MeshStandardMaterial {
  const material = new THREE.MeshStandardMaterial({
    color: new THREE.Color(1.0, 1.0, 1.0),
    metalness: 0.88,
    roughness: 0.7,
    depthWrite: false,
    flatShading: true,
    side: THREE.DoubleSide,
    vertexColors: true,
    transparent: true,
  })
  modifyMaterial(material)
  return material
}

function drfPlanes(length: number): THREE.Mesh {
  const position = []
  position.push(0, -length, -length)
  position.push(0.0, length, -length)
  position.push(0.0, length, length)
  position.push(0.0, -length, length)

  position.push(-length, 0, -length)
  position.push(-length, 0.0, length)
  position.push(length, 0.0, length)
  position.push(length, 0.0, -length)

  position.push(-length, -length, 0)
  position.push(length, -length, 0)
  position.push(length, length, 0)
  position.push(-length, length, 0)

  const parameter = []
  parameter.push(0, 0)
  parameter.push(1, 0)
  parameter.push(1, 1)
  parameter.push(0, 1)

  parameter.push(0, 0)
  parameter.push(1, 0)
  parameter.push(1, 1)
  parameter.push(0, 1)

  parameter.push(0, 0)
  parameter.push(1, 0)
  parameter.push(1, 1)
  parameter.push(0, 1)

  const color = []
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)

  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)
  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)
  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)
  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)

  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)
  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)
  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)
  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)

  const index = []
  index.push(0, 1, 2)
  index.push(0, 2, 3)

  index.push(4, 5, 6)
  index.push(4, 6, 7)

  index.push(8, 9, 10)
  index.push(8, 10, 11)

  const geometry = new THREE.BufferGeometry()
  geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(position), 3))
  geometry.setAttribute("color", new THREE.BufferAttribute(new Float32Array(color), 3))
  geometry.setAttribute("parameter", new THREE.BufferAttribute(new Float32Array(parameter), 2))
  geometry.setIndex(index)

  const material = getPlaneMaterial()

  return new THREE.Mesh(geometry, material)
}

function drfAxes(length: number): THREE.LineSegments {
  const position = []
  position.push(-length, 0, 0)
  position.push(length, 0, 0)

  position.push(0, -length, 0)
  position.push(0, length, 0)

  position.push(0, 0, -length)
  position.push(0, 0, length)

  const color = []
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)
  color.push(THREE_X_COLOR.r, THREE_X_COLOR.g, THREE_X_COLOR.b)

  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)
  color.push(THREE_Y_COLOR.r, THREE_Y_COLOR.g, THREE_Y_COLOR.b)

  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)
  color.push(THREE_Z_COLOR.r, THREE_Z_COLOR.g, THREE_Z_COLOR.b)

  const lineGeometry = new THREE.BufferGeometry()
  lineGeometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(position), 3))
  lineGeometry.setAttribute("color", new THREE.BufferAttribute(new Float32Array(color), 3))

  const lineMaterial = new THREE.LineBasicMaterial({
    vertexColors: true,
    depthWrite: false,
  })

  return new THREE.LineSegments(lineGeometry, lineMaterial)
}

export function datumReferenceFrameVis(): THREE.Group {
  const LENGTH = 30.0
  const planes = drfPlanes(LENGTH)
  const axes = drfAxes(LENGTH)
  const axesTrihedron = trihedronGroup(0.3, 20)

  const group = new THREE.Group()
  group.add(planes)
  group.add(axes)
  group.add(axesTrihedron)
  return group
}
