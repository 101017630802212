import { batch } from "react-redux"
import {
  combineReducers,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createReducer,
  createSelector,
  createSlice,
  Dictionary,
} from "@reduxjs/toolkit"

import { ConfigApi, FixtureRecord, FixturesConfig, RenderMaterial } from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { activeActions } from "../cam/active"

/**
 * Adapters
 */
const fixturesAdapter = createEntityAdapter<FixtureRecord & { id: string }>()

const materialsAdapter = createEntityAdapter<RenderMaterial & { id: string }>()

/**
 * Slices
 */
const fixturesSlice = createSlice({
  name: "FIXTURE_FIXTURES",
  initialState: fixturesAdapter.getInitialState(),
  reducers: {
    addMany: fixturesAdapter.addMany,
  },
})

const materialsSlice = createSlice({
  name: "FIXTURE_MATERIALS",
  initialState: materialsAdapter.getInitialState(),
  reducers: {
    addMany: materialsAdapter.addMany,
  },
})

/**
 * Reducers + actions
 */
const { name: fixturesName, reducer: fixturesReducer, actions: fixturesActions } = fixturesSlice

const { name: materialsName, reducer: materialsReducer, actions: materialsActions } = materialsSlice

const signalIsLoaded = createAction("config/fixtures/signalIsLoaded")
const isLoadedReducer = createReducer<boolean>(false, builder => {
  builder.addCase(signalIsLoaded, () => true)
})

const storeRawFixturesConfig = createAction<FixturesConfig>(
  "config/fixtures/storeRawFixturesConfig"
)

const rawConfigReducer = createReducer<FixturesConfig | null>(null, builder => {
  builder.addCase(storeRawFixturesConfig, (_state, { payload }) => payload)
})

export const fixturesConfigReducer = combineReducers({
  [fixturesName]: fixturesReducer,
  [materialsName]: materialsReducer,
  rawConfig: rawConfigReducer,
  isLoaded: isLoadedReducer,
})

/**
 * Selectors
 */
const fixturesAdapterSelectors = fixturesAdapter.getSelectors<RootState>(
  state => state.config.fixturesConfig[fixturesName]
)

const materialsSelectors = materialsAdapter.getSelectors<RootState>(
  state => state.config.fixturesConfig[materialsName]
)

const selectFixturesConfig = (state: RootState): FixturesConfig | null =>
  state.config.fixturesConfig.rawConfig

const selectFixtureRecord = (state: RootState, id?: string): FixtureRecord | undefined =>
  id === undefined ? undefined : fixturesAdapterSelectors.selectById(state, id)

const selectMaterial = (state: RootState, id?: string): RenderMaterial | undefined =>
  id === undefined ? undefined : materialsSelectors.selectById(state, id)

const selectFixtureRecordsMap = (state: RootState): Dictionary<FixtureRecord> =>
  fixturesAdapterSelectors.selectEntities(state)

const selectIsFixtureConfigLoaded = (state: RootState): boolean =>
  state.config.fixturesConfig.isLoaded

const selectCustomMillableFixtureIds = createSelector(
  [fixturesAdapterSelectors.selectAll],
  fixtures =>
    fixtures
      .filter(fixture => Object.values(fixture.bodies).some(body => body.isCustomMillable))
      .map(record => record.id)
      .sort()
)

export const fixturesSelectors = {
  selectFixturesConfig,
  selectFixtureRecord,
  selectMaterial,
  selectFixtureRecordsMap,
  selectIsFixtureConfigLoaded,
  selectCustomMillableFixtureIds,
}

/**
 * Thunks
 */
const fetchFixturesConfig = createAsyncThunk(
  "config/fixtures/fetchFixturesConfig",
  async ({ configApi }: { configApi: ConfigApi }, thunkAPI) => {
    try {
      let state = (await thunkAPI.getState()) as RootState
      if (state.config.fixturesConfig.rawConfig !== null) return // Already loaded

      const fixturesConfig = (await configApi.getFixturesConfig()).data

      // Remove null values so they don't overwrite fixture material defaults
      Object.values(fixturesConfig.materials).forEach(material => {
        let key: keyof RenderMaterial
        for (key in material) {
          if (!Object.prototype.hasOwnProperty.call(material, key)) continue
          if (!material[key]) {
            delete material[key]
          }
        }
      })

      state = (await thunkAPI.getState()) as RootState
      if (state.config.fixturesConfig.rawConfig !== null) return // Already loaded, prevent rerender

      batch(() => {
        thunkAPI.dispatch(fixturesActions.addMany(fixturesConfig.fixtures))
        thunkAPI.dispatch(materialsActions.addMany(fixturesConfig.materials))
        thunkAPI.dispatch(storeRawFixturesConfig(fixturesConfig))
        thunkAPI.dispatch(signalIsLoaded())
      })
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch fixtures config: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const fixturesThunks = {
  fetchFixturesConfig,
}
