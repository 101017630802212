import React, { FC } from "react"
import { useThree } from "@react-three/fiber"

import { Lighting } from "./Lighting"

/**
 * This component exists only so that we can make use of Canvas-requiring hooks without tying the Lighting to them
 */

const WrappedLighting: FC = () => {
  const { camera } = useThree()
  return <Lighting ambientIntensity={2.5} camera={camera} cameraPointIntensity={2.5} />
}

export default WrappedLighting
