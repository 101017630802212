import { PlanchangerApi } from "src/client-axios"
import { Alignment, Inspection, MachineOffset, OffsetKind, WcsProbing } from "src/client-axios/api"
import { activeActions } from "src/store/cam/active"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { AppDispatch } from "src/store/rootStore"
import { ProbingStep, TopLevelProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import { withPartialUpdates } from "src/util/util"

export interface ThunkInputs {
  operationIdx: number | undefined
  planId: string | undefined
  planchangerApi: PlanchangerApi
  dispatch: AppDispatch
}

export const updateWcsProbing = ({
  wcsProbing,
  thunkInputs,
  params: { steps, tiltOverride, disabled },
  newSelectedStep,
}: {
  thunkInputs: ThunkInputs
  wcsProbing: WcsProbing
  params: {
    steps?: WcsProbingStep[]
    tiltOverride?: number | null
    disabled?: boolean
  }
  newSelectedStep?: WcsProbingStep
}): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  const updatedWcsProbing = { ...wcsProbing }
  if (steps) updatedWcsProbing.steps = steps
  if (disabled != null) updatedWcsProbing.disabled = disabled

  // If you want to change tiltOverride to undefined, set tiltOverride == null in the call to this function:
  if (tiltOverride !== undefined) updatedWcsProbing.tiltOverride = tiltOverride ?? undefined

  dispatch(
    storedPlanThunks.updateWcsProbing({
      planId,
      operationIdx,
      planchangerApi,
      updatedWcsProbing,
    })
  ).then(() => {
    if (newSelectedStep) {
      dispatch(activeActions.setActiveProbeStepId(newSelectedStep.id))
    }
  })
}

export const updateAlignment = (
  {
    thunkInputs,
    alignment,
    updates,
    handleUndefined,
  }: {
    thunkInputs: ThunkInputs
    alignment: Alignment
    updates: Partial<Alignment>
    handleUndefined?: boolean
  },
  newSelectedStepId?: string
): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  const updatedAlignment = withPartialUpdates(alignment, updates, handleUndefined)
  dispatch(
    storedPlanThunks.updateAlignment({
      planId,
      operationIdx,
      planchangerApi,
      updatedAlignment,
    })
  ).then(() => {
    if (newSelectedStepId) {
      dispatch(activeActions.setActiveProbeStepId(newSelectedStepId))
    }
  })
}

export const updateAlignments = (
  {
    thunkInputs,
    updatedAlignments,
  }: {
    thunkInputs: ThunkInputs
    updatedAlignments: Alignment[]
  },
  newSelectedStepId?: string
): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  dispatch(
    storedPlanThunks.updateAlignments({
      planId,
      operationIdx,
      planchangerApi,
      updatedAlignments,
    })
  ).then(() => {
    if (newSelectedStepId) {
      dispatch(activeActions.setActiveProbeStepId(newSelectedStepId))
    }
  })
}
export const updateInspections = (
  {
    thunkInputs,
    updatedInspections,
  }: {
    thunkInputs: ThunkInputs
    updatedInspections: Inspection[]
  },
  newSelectedStepId?: string
): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  dispatch(
    storedPlanThunks.updateInspections({
      planId,
      operationIdx,
      planchangerApi,
      updatedInspections,
    })
  ).then(() => {
    if (newSelectedStepId) {
      dispatch(activeActions.setActiveProbeStepId(newSelectedStepId))
    }
  })
}

export const updateMachineOffsets = ({
  thunkInputs,
  updatedOffsets,
}: {
  thunkInputs: ThunkInputs
  updatedOffsets: MachineOffset[]
}): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  dispatch(
    storedPlanThunks.setOffsets({
      planId,
      operationIdx,
      planchangerApi,
      offsets: updatedOffsets,
    })
  )
}

export const checkMachineOffsetExists = (
  offsets: MachineOffset[],
  offset: MachineOffset
): boolean =>
  offsets.some(
    o =>
      o.currentTool === offset.currentTool &&
      o.previousTool === offset.previousTool &&
      o.nextTool === offset.nextTool &&
      offset.kind !== OffsetKind.WCS &&
      o.kind === offset.kind &&
      o.machineKind === offset.machineKind &&
      o.machineInstance === offset.machineInstance &&
      o.instanceIndex === offset.instanceIndex &&
      o.instanceCount === offset.instanceCount
  )

export interface InspectionUpdateParams {
  alignmentNumber?: number | null
  steps?: Array<TopLevelProbingStep>
  ncFileId?: string | null | undefined
  performAfterLine?: number | null | undefined
  preMachining?: boolean | undefined
  disabled?: boolean | undefined
  newSelectedStep?: ProbingStep
}

export const updateInspection = (
  {
    thunkInputs,
    inspection,
    updates,
    handleUndefined,
  }: {
    thunkInputs: ThunkInputs
    inspection: Inspection
    updates: Partial<Inspection>
    handleUndefined?: boolean
  },
  newSelectedStepId?: string
): void => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs
  const updatedInspection = withPartialUpdates(inspection, updates, handleUndefined)

  dispatch(
    storedPlanThunks.updateInspection({
      planId,
      operationIdx,
      planchangerApi,
      updatedInspection,
    })
  ).then(() => {
    if (newSelectedStepId) {
      dispatch(activeActions.setActiveProbeStepId(newSelectedStepId))
    }
  })
}

export const setSpindleTravelLimitMove = (thunkInputs: ThunkInputs, value: number) => {
  const { planId, operationIdx, planchangerApi, dispatch } = thunkInputs

  dispatch(
    storedPlanThunks.setSpindleTravelLimitMove({
      planId,
      operationIdx,
      planchangerApi,
      spindleTravelLimitMove: value,
    })
  )
}

export enum SectionCategory {
  preMachining = "Pre-Machining",
  orphaned = "Orphaned",
  postMachining = "Post-Machining",
  machining = "Machining",
}
