import React, { FC, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Checkbox, Classes, Dialog, HTMLTable, Icon } from "@blueprintjs/core"
import { isEqual } from "lodash-es"

import { MachineOffset, OffsetKind } from "src/client-axios"
import {
  checkMachineOffsetExists,
  updateMachineOffsets,
} from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/updateProductionControls"
import { Modal } from "src/components/Generic/Modal/Modal"
import { useCamPlanJobQuery } from "src/graphql/generated"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { RootState } from "src/store/rootStore"
import { MACHINE_OFFSET_VALUES_KEY_COMPARATOR } from "../../TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/ProductionControlsTreeLabel/MachineOffsetSettingsPanel/MachineOffsetSettingsPanel"
import { AddMachineOffsetDialog } from "./AddMachineOffsetDialog/AddMachineOffsetDialog"
import { EditMachineOffsetDialog } from "./EditMachineOffsetDialog/EditMachineOffsetDialog"

import styles from "./MachineOffsetsDialog.module.css"

enum SortByEnum {
  offsetType,
  machineName,
}

interface SortByState {
  sortBy: SortByEnum
  ascending: boolean
}

export const MachineOffsetsDialog: FC<{
  isOpen: boolean
  handleRequestClose: () => void
  toolchangeIndex: number
  planId?: string
  operationIdx?: number
  toolChanges: string[]
  signatureFilter?: string
}> = ({
  isOpen,
  handleRequestClose,
  toolchangeIndex,
  planId,
  operationIdx,
  toolChanges,
  signatureFilter,
}) => {
  const { data: jobData } = useCamPlanJobQuery({ variables: { planId: String(planId) } })

  const plan = useSelector((state: RootState) => storedPlansSelectors.selectPlan(state, planId))

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )

  const probingThunkContext = useProbingThunkContext()

  const [isAddOffsetOpen, setIsAddOffsetOpen] = useState<boolean>(false)
  const [isEditOffsetOpen, setIsEditOffsetOpen] = useState<boolean>(false)
  const [checkedCheckboxIndexes, setCheckedCheckboxIndexes] = useState<Record<string, boolean>>({})
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const [sortBy, setSortBy] = useState<SortByState>({
    sortBy: SortByEnum.offsetType,
    ascending: true,
  })

  const signatureParts = signatureFilter?.split("-")
  const prevToolchangeToolId = signatureParts?.at(0) ?? toolChanges[toolchangeIndex - 1]
  const toolchangeToolId = signatureParts?.at(1) ?? toolChanges[toolchangeIndex]
  const nextToolchangeToolId = signatureParts?.at(2) ?? toolChanges[toolchangeIndex + 1]

  const toolchangeSignature = {
    prev: prevToolchangeToolId,
    curr: toolchangeToolId,
    next: nextToolchangeToolId,
    index: toolchangeIndex,
  }

  const toolchangeSignatures =
    toolChanges?.map((tc, i) => {
      const prevTc = toolChanges[i - 1]
      const nextTc = toolChanges[i + 1]
      return {
        prev: prevTc,
        curr: tc,
        next: nextTc,
        index: i,
      }
    }) ?? []
  const signatures =
    toolchangeSignatures.filter(tc => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index: _1, ...tcSig } = tc
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index: _2, ...tcSig2 } = toolchangeSignature
      return isEqual(tcSig, tcSig2)
    }) ?? []

  const signatureIndex = Number(
    signatureParts?.at(3) ?? signatures?.findIndex(tc => isEqual(tc, toolchangeSignature)) ?? 0
  )
  const signatureCount = Number(signatureParts?.at(4) ?? signatures?.length ?? 1)

  const opOffsets = operation?.offsets ?? []
  const opOffsetVisibility = opOffsets.reduce((acc: boolean[], offset) => {
    const offsetSignature = {
      prev: offset.previousTool?.toString(),
      curr: offset.currentTool?.toString(),
      next: offset.nextTool?.toString(),
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { index: _1, ...tcSig } = toolchangeSignature
    if (
      isEqual(tcSig, offsetSignature) &&
      offset.instanceIndex === signatureIndex &&
      offset.instanceCount === signatureCount
    ) {
      acc.push(true)
    } else {
      acc.push(false)
    }
    return acc
  }, [])

  const handleCheckOffsetExists = (offset: MachineOffset) =>
    checkMachineOffsetExists(opOffsets, offset)

  const handleAddOffset = (offset: MachineOffset) => {
    const updatedOffsets = [...opOffsets, offset]
    updateMachineOffsets({
      thunkInputs: {
        ...probingThunkContext,
        planId: planId ?? probingThunkContext.planId,
        operationIdx: operationIdx ?? probingThunkContext.operationIdx,
      },
      updatedOffsets,
    })
    setIsAddOffsetOpen(false)
  }

  const handleRemoveOffsets = () => {
    const updatedOffsets = opOffsets.slice()

    const indexesToRemove = Object.keys(checkedCheckboxIndexes).sort().reverse()

    indexesToRemove.forEach(i => {
      updatedOffsets.splice(Number(i), 1)
    })

    updateMachineOffsets({
      thunkInputs: {
        ...probingThunkContext,
        planId: planId ?? probingThunkContext.planId,
        operationIdx: operationIdx ?? probingThunkContext.operationIdx,
      },
      updatedOffsets,
    })

    setCheckedCheckboxIndexes({})
    setShowConfirmDelete(false)
  }

  const handleCheckboxChange = (i: number) => {
    const newCheckedCheckboxIndexes = { ...checkedCheckboxIndexes }
    if (newCheckedCheckboxIndexes[i]) {
      delete newCheckedCheckboxIndexes[i]
    } else {
      newCheckedCheckboxIndexes[i] = true
    }
    setCheckedCheckboxIndexes(newCheckedCheckboxIndexes)
  }

  const dialogTitle = `Machine Controls`

  const offsetKindFormatted = new Map<OffsetKind, string>([
    [OffsetKind.POSITION, "Position Offset"],
    [OffsetKind.TOOL, "Tool Offset"],
    [OffsetKind.FEEDANDSPEED, "Feed/Speed"],
    [OffsetKind.TOOLRENUMBER, "Tool Renumber"],
    [OffsetKind.WCS, "WCS"],
  ])

  const offsetId = Object.keys(checkedCheckboxIndexes)[0]
  const offset = opOffsets[Number(offsetId)]

  const sortByFn = useCallback(
    (a: MachineOffset, b: MachineOffset) => {
      const lhs = sortBy.ascending ? a : b
      const rhs = sortBy.ascending ? b : a

      return sortBy.sortBy === SortByEnum.offsetType
        ? lhs.kind.localeCompare(rhs.kind)
        : lhs.machineInstance.localeCompare(rhs.machineInstance)
    },
    [sortBy]
  )

  const handleClickSort = (sortByEnum: SortByEnum) => {
    setSortBy({
      sortBy: sortByEnum,
      ascending: sortBy.sortBy === sortByEnum ? !sortBy.ascending : true,
    })
  }

  const getSortIcon = useMemo(
    () => (sortByEnum: SortByEnum) => {
      if (sortByEnum === sortBy.sortBy) {
        return <Icon icon={sortBy.ascending ? "caret-up" : "caret-down"} />
      } else return null
    },
    [sortBy]
  )

  return (
    <>
      <Dialog
        icon={"info-sign"}
        onClose={handleRequestClose}
        title={dialogTitle}
        isOpen={isOpen}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        enforceFocus={true}
        usePortal={true}
        className={styles.offsetsDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.labelsContainer}>
            <div className={styles.labelRow}>
              <div className={styles.labelTitle}>SKU</div>
              <div>{jobData?.camPlan?.job?.label}</div>
            </div>
            <div className={styles.labelRow}>
              <div className={styles.labelTitle}>Project</div>
              <div>{plan?.label}</div>
            </div>

            <div className={styles.labelRow}>
              <div className={styles.labelTitle}>Operation</div>
              <div>{operation?.label}</div>
            </div>

            <div className={styles.labelRow}>
              <div className={styles.labelTitle}>Tool Change</div>
              {toolchangeIndex === -1 ? (
                <div>
                  Orphaned (editing will be disabled)
                  <Icon size={18} icon="warning-sign" intent="warning" />
                </div>
              ) : (
                <div>{Number(toolchangeIndex) + 1}</div>
              )}
            </div>

            <div className={styles.labelRow}>
              <div className={styles.labelTitle}>Tool</div>
              <div>{`T${toolchangeToolId}`}</div>
            </div>
          </div>

          <HTMLTable striped bordered condensed interactive className={styles.offsetsTable}>
            <thead className={styles.offsetsTableHead}>
              <tr>
                <th
                  className={styles.offsetHeaderCell}
                  onClick={() => handleClickSort(SortByEnum.offsetType)}
                >
                  Type {getSortIcon(SortByEnum.offsetType)}
                </th>
                <th
                  className={styles.machineHeaderCell}
                  onClick={() => handleClickSort(SortByEnum.machineName)}
                >
                  Machine {getSortIcon(SortByEnum.machineName)}
                </th>
                <th className={styles.valuesHeaderCell}>Values</th>
              </tr>
            </thead>
            <tbody className={styles.offsetsTableBody}>
              {opOffsets
                .slice()
                .map((offset, i) => ({ offset, index: i }))
                .filter(
                  ({ offset, index }) =>
                    opOffsetVisibility[index] && offset.currentTool === Number(toolchangeToolId)
                )
                .sort((a, b) => sortByFn(a.offset, b.offset))
                .map(({ offset, index }) => {
                  return (
                    <tr key={index}>
                      <td className={styles.offsetBodyCell}>
                        <Checkbox
                          checked={checkedCheckboxIndexes[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        {offsetKindFormatted.get(offset.kind) ?? offset.kind}
                      </td>
                      <td className={styles.machineHeaderCell}>
                        {offset.machineKind} {offset.machineInstance}
                      </td>
                      <td className={styles.valuesHeaderCell}>
                        {Object.entries(offset.offsets)
                          .sort(MACHINE_OFFSET_VALUES_KEY_COMPARATOR)
                          .map(([key, value], i) => {
                            return (
                              <span key={i}>
                                {key}={value}
                                {i !== Object.entries(offset.offsets).length - 1 ? `,` : ``}{" "}
                              </span>
                            )
                          })}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </HTMLTable>

          <div className={styles.editButtons}>
            <Button
              disabled={toolchangeIndex === -1}
              className={styles.addButton}
              onClick={() => setIsAddOffsetOpen(true)}
              intent="none"
            >
              Add Control
            </Button>
            <Button
              className={styles.removeButton}
              onClick={() => {
                setShowConfirmDelete(true)
              }}
              intent="none"
              disabled={Object.keys(checkedCheckboxIndexes).length !== 1}
            >
              Remove Control
            </Button>
            <Button
              onClick={() => setIsEditOffsetOpen(true)}
              intent="none"
              disabled={toolchangeIndex === -1 || Object.keys(checkedCheckboxIndexes).length !== 1}
            >
              Edit Control
            </Button>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleRequestClose} intent="danger">
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      {isAddOffsetOpen && toolchangeToolId !== undefined && (
        <AddMachineOffsetDialog
          onClose={() => setIsAddOffsetOpen(false)}
          checkExists={handleCheckOffsetExists}
          onCreate={handleAddOffset}
          kind={OffsetKind.POSITION}
          toolchangeIndex={toolchangeIndex}
          toolChanges={toolChanges}
        />
      )}
      {isEditOffsetOpen && (
        <EditMachineOffsetDialog
          onClose={() => setIsEditOffsetOpen(false)}
          offset={offset}
          offsetId={offsetId}
          planId={planId}
          operationIdx={operationIdx}
        />
      )}
      <Modal
        isOpen={showConfirmDelete}
        iconName="delete"
        headerText="Delete control?"
        bodyText="Are you sure you want to delete this control?"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        handleCancel={() => {
          setShowConfirmDelete(false)
        }}
        handleRequestClose={() => {
          setShowConfirmDelete(false)
        }}
        handleConfirm={handleRemoveOffsets}
      />
    </>
  )
}
