import React, { FC, useState } from "react"
import { Button } from "@blueprintjs/core"
import { v4 as uuidv4 } from "uuid"

import { sendEvent } from "src/components/Websocket/Websocket"
import { Camjobstatus, useCreateCamJobMutation } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { downloadFromUrl } from "src/util/files"
import { slugify } from "src/util/text"
import { getModelUrl, getThumbnailUrl } from "src/util/url"
import { LibraryPart } from "../PartLibrary"

import styles from "./PartCard.module.css"

export const PartCard: FC<{ part: LibraryPart }> = ({ part }) => {
  const [createCamJobMutation] = useCreateCamJobMutation()
  const [creatingJob, setCreatingJob] = useState<boolean>(false)
  const toaster = useToaster()
  const { filesApi, tasksApi } = useApi()

  const stepUrl = getModelUrl(part.id)

  const imageUrl = getThumbnailUrl(part.id)

  const handleDownload = () => {
    downloadFromUrl(stepUrl)
  }

  const handleCreateJob = () => {
    setCreatingJob(true)
    toaster.show({ message: `Creating Job`, intent: "primary" })
    fetch(stepUrl).then(r => {
      r.blob().then(blob => {
        const file = new File([blob], `${part.id}.step`)
        uploadFile(file)
      })
    })
  }

  const uploadFile = (file: File) => {
    filesApi.newModel(file).then(results => {
      const modelId = results.data.id
      const materialDisplayName = `Aluminum 6061`
      const label = file.name.split(".")[0]
      const id = uuidv4()

      createCamJobMutation({
        variables: {
          id,
          modelId: modelId,
          materialDisplayName,
          label,
          uri: slugify(`${id.substring(0, 4)}-${label}`),
          status: Camjobstatus.Internal,
          isForAnalysis: true,
        },
      }).then(response => {
        const id = response.data?.createCamJob?.camJob?.id
        if (id) {
          sendEvent("createJob", { jobId: id, partLibrary: part.id })
          tasksApi.generateProposals(id)
          toaster.show({ message: `Generating Proposals`, intent: "success" })
          setCreatingJob(false)
        }
      })
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img style={{ maxWidth: 200 }} src={imageUrl} alt="" />
      </div>

      <div className={styles.rightContents}>
        <div className={styles.middle}>
          <div className={styles.labelLarge}>{part.id}</div>

          <div className={styles.labelNormal}>
            Part Volume: {(part.volume / 1000).toFixed(0)} cm³
          </div>
          <div className={styles.labelNormal}>
            Bounding Box Dimensions:
            {part.x.toFixed(1)} mm × {part.y.toFixed(1)} mm × {part.z.toFixed(1)} mm
          </div>
          <div className={styles.labelNormal}>
            Bounding Box Volume: {(part.bboxVolume / 1000).toFixed(0)} cm³
          </div>
          <div className={styles.buttonsContainer}>
            <Button className={styles.downloadButton} intent="primary" onClick={handleDownload}>
              Download Step File
            </Button>
            <Button intent="success" onClick={handleCreateJob} disabled={creatingJob}>
              Create Job
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
