import { useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import {
  BoreFeature,
  BoreFeatureKindEnum,
  BossFeature,
  BossFeatureKindEnum,
  EmptyToolchangeKindEnum,
  ExplicitMove,
  ExplicitMoveKindEnum,
  MultiLevelBoreFeatureKindEnum,
  MultiLevelBossFeatureKindEnum,
  PointsFeature,
  PointsFeatureKindEnum,
  PointsFeatureMove,
  PointsFeatureMoveKindEnum,
  PointsFeaturePoint,
  PointsFeaturePointKindEnum,
  ProbeToolchangeKindEnum,
  ReferencePositionMoveKindEnum,
  SphereFeature,
  SphereFeatureKindEnum,
  WcsBoreBossKindEnum,
  WcsEdgeSurfaceKindEnum,
  WcsProbingMoveKindEnum,
  WcsSpecifyCoordsKindEnum,
  WcsWebPocketKindEnum,
} from "src/client-axios"
import { useHandleAddSteps } from "src/hooks/useHandleAddSteps"
import { activeProbingSelectors } from "src/store/cam/active"
import { useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { useAppDispatch } from "src/store/rootStore"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import { handlePastePointsFeaturePoint } from "./handlePastePointsFeaturePoint"

export const useHandlePasteProbeFeature: () => {
  handlePasteProbeFeature: (
    copiedProbeSteps?: (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[],
    selectedProbeStep?: WcsProbingStep | ProbingStep | undefined,
    offset?: number | undefined
  ) => void
} = () => {
  const { handleAddSteps, handleAddWcsProbingSteps } = useHandleAddSteps()
  const probingThunkContext = useProbingThunkContext()
  const dispatch = useAppDispatch()
  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)

  const selectedProbingSection = useSelector(activeProbingSelectors.selectActiveProbingSection)

  const handlePasteProbeFeature: (
    copiedProbeSteps?: (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[],
    selectedProbeStep?: WcsProbingStep | ProbingStep | undefined,
    offset?: number
  ) => void = (
    copiedProbeSteps?: (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[],
    selectedProbeStep?: WcsProbingStep | ProbingStep | undefined,
    offset = 0
  ) => {
    if (!copiedProbeSteps?.length) return

    const addSteps = (selectedProbeStep?: WcsProbingStep | ProbingStep | undefined) => {
      const newSteps: (
        | BoreFeature
        | BossFeature
        | SphereFeature
        | ExplicitMove
        | PointsFeature
      )[] = []
      copiedProbeSteps.forEach(copiedProbeStep => {
        if (copiedProbeStep.kind === PointsFeatureKindEnum.PointsFeature) {
          const subSteps = copiedProbeStep.steps.map(step => ({ ...step, id: uuidv4() }))
          const newStep = { ...copiedProbeStep, id: uuidv4(), steps: subSteps }
          newSteps.push(newStep)
        } else {
          const newStep = { ...copiedProbeStep, id: uuidv4() } as
            | BoreFeature
            | BossFeature
            | SphereFeature
            | ExplicitMove
            | PointsFeature
          newSteps.push(newStep)
        }
      })
      handleAddSteps(newSteps, selectedProbeStep?.id, offset)
    }

    if (!selectedProbeStep?.kind) {
      if (
        !copiedProbeSteps.some(
          step =>
            step.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
            step.kind === PointsFeatureMoveKindEnum.PointsFeatureMove
        )
      ) {
        addSteps()
      }
    } else if (
      selectedProbeStep.kind === PointsFeatureKindEnum.PointsFeature ||
      selectedProbeStep.kind === BoreFeatureKindEnum.BoreFeature ||
      selectedProbeStep.kind === BossFeatureKindEnum.BossFeature ||
      selectedProbeStep.kind === SphereFeatureKindEnum.SphereFeature ||
      selectedProbeStep.kind === ExplicitMoveKindEnum.ExplicitMove ||
      selectedProbeStep.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
      selectedProbeStep.kind === PointsFeatureMoveKindEnum.PointsFeatureMove ||
      selectedProbeStep.kind === MultiLevelBoreFeatureKindEnum.MultiLevelBoreFeature ||
      selectedProbeStep.kind === MultiLevelBossFeatureKindEnum.MultiLevelBossFeature
    ) {
      if (
        copiedProbeSteps.every(
          step =>
            step.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
            step.kind === PointsFeatureMoveKindEnum.PointsFeatureMove
        )
      ) {
        if (
          selectedProbeStep.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
          selectedProbeStep.kind === PointsFeatureMoveKindEnum.PointsFeatureMove ||
          selectedProbeStep.kind === PointsFeatureKindEnum.PointsFeature
        ) {
          handlePastePointsFeaturePoint(
            1,
            selectedProbeStep,
            copiedProbeSteps as (PointsFeaturePoint | PointsFeatureMove)[],
            selectedProbingSection,
            probingThunkContext,
            dispatch,
            selectedSection
          )
        }
      } else {
        addSteps(selectedProbeStep)
      }
    } else if (
      selectedProbeStep.kind === WcsSpecifyCoordsKindEnum.SpecifyCoords ||
      selectedProbeStep.kind === WcsProbingMoveKindEnum.WcsExplicitMove ||
      selectedProbeStep.kind === WcsBoreBossKindEnum.BoreBoss ||
      selectedProbeStep.kind === WcsWebPocketKindEnum.WebPocket ||
      selectedProbeStep.kind === WcsEdgeSurfaceKindEnum.EdgeSurface ||
      selectedProbeStep.kind === ProbeToolchangeKindEnum.ProbeToolchange ||
      selectedProbeStep.kind === EmptyToolchangeKindEnum.EmptyToolchange ||
      selectedProbeStep.kind === ReferencePositionMoveKindEnum.ReferencePositionMove
    ) {
      const newSteps: WcsProbingStep[] = []
      copiedProbeSteps?.forEach(copiedProbeStep => {
        const newStep = { ...copiedProbeStep, id: uuidv4() }
        newSteps.push(newStep as WcsProbingStep)
      })
      handleAddWcsProbingSteps(newSteps, selectedProbeStep?.id, offset)
    }
  }

  return { handlePasteProbeFeature }
}
