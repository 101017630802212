import { sendEvent } from "src/components/Websocket/Websocket"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"

export const useDebugLog: () => (log: string) => void = () => {
  const {
    settings: { showDebugLogs },
  } = useLocalStorageSettings()

  const debugLog: (log: string) => void = (log: string) => {
    if (showDebugLogs) {
      sendEvent("debugLog", { log })

      console.log(log)
    }
  }

  return debugLog
}
