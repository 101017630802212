import React, { useContext, useEffect } from "react"

import { ExportCoordinates } from "src/client-axios"
import { Camjobstatus } from "src/graphql/generated"

export interface LocalStorageSettings {
  showArchivedJobs: boolean
  showAnalysisJobs: boolean

  showUnassignedJobs: boolean
  showJobsAssignedToOthers: boolean
  enablePriorityEditing: boolean
  enableVcreview: boolean
  showDebugLogs: boolean
  exportCoordinates: ExportCoordinates
  camjobStatus: Camjobstatus
  jiraAccessToken?: string
  jiraRefreshToken?: string
  cloudId?: string
  releaseNotesDate?: string
}

interface LocalStorageSettingsContextInterface {
  settings: LocalStorageSettings
  setSettings: (settings: Partial<LocalStorageSettings>) => void
}

export const LocalStorageSettingsContext = React.createContext<LocalStorageSettingsContextInterface | null>(
  null
)

export const useLocalStorageSettings = (): LocalStorageSettingsContextInterface => {
  const localStorageSettings = useContext(LocalStorageSettingsContext)
  if (!localStorageSettings) {
    throw new Error("No LocalStorageSettings was present")
  }
  return localStorageSettings
}

const LOCAL_STORAGE_SETTINGS_KEY = "remoteShop"
const DEFAULT_SETTINGS: LocalStorageSettings = {
  showArchivedJobs: false,
  showAnalysisJobs: false,
  showDebugLogs: false,

  showUnassignedJobs: true,
  showJobsAssignedToOthers: true,

  enablePriorityEditing: false,
  enableVcreview: true,
  exportCoordinates: ExportCoordinates.Part,
  camjobStatus: Camjobstatus.Programming,
  jiraAccessToken: undefined,
  jiraRefreshToken: undefined,
  cloudId: undefined,
  releaseNotesDate: undefined,
}

const loadFromLocal = () => {
  const rawStoredSettings = localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY)
  let parsedStoredSettings: Record<string, unknown> | undefined

  if (rawStoredSettings) {
    try {
      parsedStoredSettings = JSON.parse(rawStoredSettings) as Record<string, unknown>
    } catch (e) {
      console.error("Invalid stored settings; resetting to defaults: ", rawStoredSettings, e)
    }
  }

  if (parsedStoredSettings === undefined) {
    console.log("First time loading settings")
    localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(DEFAULT_SETTINGS))
    return { ...DEFAULT_SETTINGS }
  }

  const newSettings: LocalStorageSettings = { ...DEFAULT_SETTINGS }
  const {
    showArchivedJobs,
    showAnalysisJobs,
    showUnassignedJobs,
    showJobsAssignedToOthers,
    enablePriorityEditing,
    enableVcreview,
    exportCoordinates,
    camjobStatus,
    jiraAccessToken,
    jiraRefreshToken,
    cloudId,
    releaseNotesDate,
    showDebugLogs,
  } = parsedStoredSettings

  Object.entries({
    showArchivedJobs,
    showAnalysisJobs,
    showUnassignedJobs,
    showJobsAssignedToOthers,
    enablePriorityEditing,
    enableVcreview,
    exportCoordinates,
    camjobStatus,
    jiraAccessToken,
    jiraRefreshToken,
    cloudId,
    releaseNotesDate,
    showDebugLogs,
  }).forEach(([key, value]) => {
    if (typeof value === "boolean") {
      ;(newSettings[key as keyof LocalStorageSettings] as boolean) = value
    } else if (typeof value === "string") {
      ;(newSettings[key as keyof LocalStorageSettings] as string) = value
    } else {
      // console.error("Failed to store setting!", { key, value })
    }
  })

  return newSettings
}

export const LocalStorageSettingsProvider: React.FC = ({ children }) => {
  const [currentSettings, setCurrentSettings] = React.useState<LocalStorageSettings>(
    loadFromLocal()
  )

  // Persist selected values
  useEffect(() => {
    if (currentSettings !== undefined)
      localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(currentSettings))
  }, [currentSettings])

  const setSettings = (settings: Partial<LocalStorageSettings>): void => {
    Object.entries(settings).forEach(([a, b]) => {
      setCurrentSettings(prev => ({
        ...(prev || DEFAULT_SETTINGS),
        [a as keyof LocalStorageSettings]: b,
      }))
    })
  }

  return (
    <LocalStorageSettingsContext.Provider
      value={{ settings: currentSettings || DEFAULT_SETTINGS, setSettings }}
    >
      {children}
    </LocalStorageSettingsContext.Provider>
  )
}
