import React, { FC, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, Icon } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { formatDistanceToNow } from "date-fns"
import { debounce } from "lodash-es"

import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  useCamPlanChangesSincePublishQuery,
  useGetOperationHistoryQuery,
} from "src/graphql/generated"
import { OperationHistory } from "src/pages/OperationHistory/OperationHistory"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

import styles from "./OpChangesIcon.module.css"

export const OpChangesIcon: FC<{
  operationIdx: number
  planId: string
  locked?: boolean
}> = ({ operationIdx, planId, locked }) => {
  const [isOpen, setIsOpen] = useState(false)

  const operationLabel = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )?.label

  const {
    data: getOperationHistoryQueryData,
    refetch: refetchOperationHistoryQuery,
  } = useGetOperationHistoryQuery({
    variables: { planId, operationIdx, limit: 1 },
  })

  const {
    data: changeTypes,
    refetch: refetchChangesSincePublishQuery,
  } = useCamPlanChangesSincePublishQuery({
    variables: { planId, operationLabel: operationLabel ?? "" },
  })

  const debounceRefetchChangesSincePublishQuery = useMemo(
    () => debounce(refetchChangesSincePublishQuery, 500),
    [refetchChangesSincePublishQuery]
  )

  const debounceRefetchOperationHistoryQuery = useMemo(
    () => debounce(refetchOperationHistoryQuery, 500),
    [refetchOperationHistoryQuery]
  )

  useWebsocketMessageListener(wsMessage => {
    if (wsMessage.type === "PlanUpdate" && wsMessage.planId === planId) {
      debounceRefetchChangesSincePublishQuery()
      debounceRefetchOperationHistoryQuery()
    }
  })

  const lastChange = getOperationHistoryQueryData?.getOperationHistory?.[0]
  const hasChanges =
    changeTypes?.camPlanChangesSincePublish.hasCamChanges ||
    changeTypes?.camPlanChangesSincePublish.hasManufacturingChanges

  return (
    <>
      <Tooltip2
        openOnTargetFocus={false}
        content={
          <div>
            {hasChanges && (
              <div className={styles.publishChanges}>
                {changeTypes?.camPlanChangesSincePublish.hasPublish ? (
                  <>
                    <div>Since the last publish:</div>
                    {(
                      changeTypes?.camPlanChangesSincePublish.description ?? "No Change Description"
                    )
                      .split("\n")
                      .map((x, i) => (
                        <div key={i}>{`• ${x}`}</div>
                      ))}
                  </>
                ) : (
                  changeTypes?.camPlanChangesSincePublish.description ?? "No Change Description"
                )}
              </div>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Last changed by: {lastChange?.createdBy}</div>
              <div>Changed: {lastChange?.path.split("/")?.[3]}</div>
              {lastChange?.createdAt && (
                <div>When: {formatDistanceToNow(new Date(lastChange?.createdAt))} ago</div>
              )}
            </div>
          </div>
        }
      >
        <Icon
          onClick={() => setIsOpen(!isOpen)}
          className={styles.icon}
          icon={"history"}
          style={{
            color: hasChanges ? undefined : "#5c7080",
          }}
          intent={hasChanges ? "warning" : "none"}
        />
      </Tooltip2>

      <Dialog
        className={styles.dialog}
        icon={"info-sign"}
        onClose={() => setIsOpen(!isOpen)}
        title={`History of changes for ${operationLabel}`}
        isOpen={isOpen}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        enforceFocus={true}
        usePortal={true}
      >
        <OperationHistory planId={planId} operationIdx={operationIdx} locked={locked} />
      </Dialog>
    </>
  )
}
