import { v4 as uuidv4 } from "uuid"

import {
  Alignment,
  AlignmentKindEnum,
  Inspection,
  InspectionKindEnum,
  PointsFeature,
  PointsFeatureMove,
  PointsFeaturePoint,
} from "src/client-axios"
import {
  updateAlignment,
  updateInspection,
} from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/updateProductionControls"
import { ProbingThunkProps } from "src/store/cam/storedPlanThunks"
import { AppDispatch } from "src/store/rootStore"
import { ProbingSection, ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const handleAddSubStep: (
  stepsToAdd: (PointsFeaturePoint | PointsFeatureMove)[],
  offset: number,
  pointsFeature: PointsFeature,
  selectedProbingSection: Alignment | Inspection,
  step: ProbingStep | WcsProbingStep,
  probingThunkContext: ProbingThunkProps,
  dispatch: AppDispatch,
  selectedSection: ProbingSection | undefined
) => void = (
  stepsToAdd: (PointsFeaturePoint | PointsFeatureMove)[],
  offset: number,
  pointsFeature: PointsFeature,
  selectedProbingSection: Alignment | Inspection,
  step: ProbingStep | WcsProbingStep,
  probingThunkContext: ProbingThunkProps,
  dispatch: AppDispatch,
  selectedSection: ProbingSection | undefined
) => {
  const insertBeforeId = step.id

  const newSubSteps: (PointsFeaturePoint | PointsFeatureMove)[] = stepsToAdd.map(step => ({
    ...step,
    id: uuidv4(),
  }))

  if (insertBeforeId !== undefined) {
    const index = pointsFeature?.steps.findIndex(step => step.id === insertBeforeId)
    const subSteps = pointsFeature?.steps.slice()
    if (index !== undefined && pointsFeature) {
      subSteps?.splice(index + offset || 0, 0, ...newSubSteps)
      pointsFeature = { ...pointsFeature, steps: subSteps ?? [] }
    }
  }

  const newSteps = selectedProbingSection.steps.map(step => {
    if (step.id === pointsFeature?.id) {
      return pointsFeature
    } else {
      return step
    }
  })

  if (selectedProbingSection?.kind === AlignmentKindEnum.Alignment) {
    updateAlignment(
      {
        alignment: selectedProbingSection,
        thunkInputs: { ...probingThunkContext, dispatch },
        updates: { steps: newSteps },
      },
      newSubSteps[newSubSteps.length - 1].id
    )
  } else if (selectedProbingSection?.kind === InspectionKindEnum.Inspection) {
    updateInspection(
      {
        inspection: selectedSection as Inspection,
        thunkInputs: { ...probingThunkContext, dispatch },
        updates: {
          steps: newSteps,
        },
      },
      newSubSteps[newSubSteps.length - 1].id
    )
  }
}
