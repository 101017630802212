import React, { FC, useEffect, useState } from "react"
import { Button, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { useAuth, useCurrentUserData } from "@subscale/formlogic-auth-ts"
import { Maybe } from "graphql/jsutils/Maybe"

import {
  useCreateUserJobMutation,
  useCreateUserPlanMutation,
  useDeleteUserJobMutation,
  useDeleteUserPlanMutation,
  UserFragment,
} from "src/graphql/generated"
import { AssigneeMultiSelector } from "./AssigneeMultiSelector/AssigneeMultiSelector"

import styles from "./Assignee.module.css"

export const Assignee: FC<{
  camProgrammers?: Maybe<string[]> | undefined
  jobId?: string
  planId?: string
  className?: string
  label?: string
  disabled?: boolean
}> = ({ camProgrammers, jobId, planId, label, disabled }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth()
  const [viewAllPermissionDisabled, setViewAllPermissionDisabled] = useState<boolean>(
    disabled ?? true
  )
  const [currentUserEmail, setCurrentUserEmail] = useState<string>()
  const currentUserData = useCurrentUserData()

  useEffect(() => {
    setCurrentUserEmail(currentUserData?.email)
    setViewAllPermissionDisabled(
      disabled ?? currentUserData?.permissions.includes("view:all_jobs") ?? false
    )
  }, [isAuthenticated, disabled, getAccessTokenSilently, currentUserData])

  const [createUserJob] = useCreateUserJobMutation()
  const [deleteUserJob] = useDeleteUserJobMutation()
  const [createUserPlan] = useCreateUserPlanMutation()
  const [deleteUserPlan] = useDeleteUserPlanMutation()

  const handleCreateUserJob = (user: UserFragment) => {
    jobId &&
      createUserJob({
        variables: {
          jobId,
          userId: user.id,
        },
        refetchQueries: ["overview"],
      })
  }

  const handleDeleteUserJob = (userId: string) => {
    jobId &&
      deleteUserJob({
        variables: {
          jobId,
          userId,
        },
        refetchQueries: ["overview"],
      })
  }

  const handleCreateUserPlan = (user: UserFragment) => {
    planId &&
      createUserPlan({
        variables: {
          planId,
          userId: user.id,
        },
        refetchQueries: ["camJobDetails"],
      })
  }

  const handleDeleteUserPlan = (userId: string) => {
    planId &&
      deleteUserPlan({
        variables: {
          planId,
          userId,
        },
        refetchQueries: ["camJobDetails"],
      })
  }

  let setSelection
  let handleDelete

  if (jobId) {
    setSelection = handleCreateUserJob
    handleDelete = handleDeleteUserJob
  } else if (planId) {
    setSelection = handleCreateUserPlan
    handleDelete = handleDeleteUserPlan
  }

  if (!setSelection || !handleDelete) return null

  return (
    <>
      <Popover2
        disabled={disabled}
        popoverClassName={styles.popover}
        position={Position.LEFT}
        usePortal={false}
        content={
          <AssigneeMultiSelector
            setSelection={setSelection}
            handleDelete={handleDelete}
            camProgrammers={camProgrammers as string[]}
            viewAllPermissionDisabled={viewAllPermissionDisabled}
            currentUserEmail={currentUserEmail}
          />
        }
      >
        <Button
          className={`${styles.button} ${planId ? styles.planButton : ""}`}
          disabled={disabled}
        >
          {camProgrammers ? (
            camProgrammers
              .slice()
              .sort((a, b) => a.localeCompare(b))
              ?.map(camProgrammer => {
                return <div key={camProgrammer}>{camProgrammer}</div>
              })
          ) : (
            <>{label}</>
          )}
        </Button>
      </Popover2>
    </>
  )
}
