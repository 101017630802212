export function bytesToSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "n/a"
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export function downloadFromUrl(url: string): void {
  // The current implementation may be equivalent to this one liner, I'm not sure:
  // window.location.href = url

  // One possible upshot to the approach below is it would be easier to specify a different basename
  const basename = url.substring(url.lastIndexOf("/") + 1)
  const a = document.createElement("a")
  a.href = url.toString()
  a.download = basename
  a.click()
  a.remove()
}

export function downloadFromBlob(bytes: Uint8Array, filename: string, mimeType: string): void {
  const url = typedArrayToURL(bytes, mimeType)

  const link = document.createElement("a")
  link.href = url
  link.innerText = "Download the blob"
  link.target = "_blank"
  link.download = filename

  link.click()
  link.remove()
  URL.revokeObjectURL(url)
}

function typedArrayToURL(typedArray: Uint8Array, mimeType: string) {
  const blob = new Blob([typedArray.buffer], { type: mimeType })
  return URL.createObjectURL(blob)
}
