import React, { FC, useEffect, useRef, useState } from "react"
import { animated, AnimatedComponent, useSpring } from "@react-spring/three"
import * as THREE from "three"

import {
  configureMaterialOpacity,
  configureMeshMaterial,
  configureWireMaterial,
} from "src/components/Canvas/Viewer/materials"
import { MaterialData } from "src/components/Canvas/Viewer/Scene/Cam/materials"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { useGltfLoaderContext } from "src/hooks/useGltfLoaderContext"
import { CylinderData } from "src/util/geometry/cylinder"
import { Primitive } from "./Primitive"

export interface UserData {
  treeNodeId?: string
  isPart?: boolean
  isStock?: boolean
  isFixture?: boolean
  cylinder?: CylinderData
  ignoreIntersection?: boolean
  machineBody?: string
}

interface GltfModelProps {
  material: MaterialData
  userData?: UserData
  cylinderData?: CylinderData[]
  fadeDuration?: number
  opacity?: number
  visible?: boolean
  setSceneBox?: (arg0: THREE.Box3) => void
  onLoad?: () => void
  sceneRef?: React.MutableRefObject<THREE.Group | undefined>
  sceneCallback?: (scene: THREE.Group) => void
}

interface GltfUrlModelProps extends GltfModelProps {
  url: string
  flatShading: boolean
  onProgress?: (event: ProgressEvent<EventTarget>) => void
}

interface InitializationStatus {
  loaded?: boolean
  initialized?: boolean
  configured?: boolean
  mergedOpacitySet?: boolean
}

const GltfUrlModel: FC<GltfUrlModelProps> = ({
  url,
  userData,
  flatShading = false,
  material,
  fadeDuration,
  opacity: targetOpacity,
  visible: targetVisibility = true,
  setSceneBox,
  onLoad,
  sceneRef,
  children,
  cylinderData,
  sceneCallback,
  onProgress,
}) => {
  const [scene, setScene] = useState<THREE.Group>()
  const loader = useGltfLoaderContext()

  useEffect(() => {
    loader.load(
      url,

      gltf => {
        setScene(gltf.scene.clone())
      },

      // undefined,
      onProgress,

      error => {
        console.log("GLTF Loading error: ", error)
      }
    )
  }, [url, loader, onProgress])

  const { transferInvalidate } = useTransferInvalidate()

  // const gltf = useGLTF(url, "/draco/gltf/")
  // const scene = useMemo(() => gltf.scene.clone(), [gltf])

  const [meshes, setMeshes] = useState<THREE.Mesh[]>([])
  const [wires, setWires] = useState<THREE.LineSegments[]>([])

  const [status, setStatus] = useState<InitializationStatus>({})
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    transferInvalidate()
  }, [material, flatShading, visible, transferInvalidate])

  useEffect(() => {
    if (scene) {
      const localBbox = new THREE.Box3().setFromObject(scene)
      setSceneBox && setSceneBox(localBbox)
    }
  }, [setSceneBox, scene])

  // Visibility effects
  // The sequencing of their first run is critical to prevent artifacts on initial render;
  // this is why the `status` object is used.
  useEffect(() => {
    scene && onLoad && onLoad()
    ;(userData || cylinderData) &&
      scene?.traverse((obj3d: THREE.Object3D) => {
        const nameComponents = obj3d.name.split("_")
        if (nameComponents.length === 2 && nameComponents[0] === "face") {
          const idx = +nameComponents[1]
          obj3d.userData.cylinder = cylinderData?.find(val => val.face_idx === idx)
        } else if (cylinderData && obj3d instanceof THREE.Mesh) {
          obj3d.userData.cylinderData = cylinderData
        }

        Object.assign(obj3d.userData, { ...userData, url })
      })
    scene && setStatus(({ ...status }) => ({ loaded: true, ...status }))
  }, [scene, onLoad, userData, cylinderData, url])

  // const lineSegments = useMemo(() => {
  //   if (!showSharpEdges) return
  //
  //   if (!primaryMeshGeometry) return
  //
  //   const indices = primaryMeshGeometry.index
  //   if (!indices) return
  //
  //   const lineSegments = sharpLineSegments(primaryMeshGeometry, indices)
  //   configureWireMaterial(lineSegments, material)
  //   return lineSegments
  // }, [showSharpEdges, primaryMeshGeometry, material])

  useEffect(() => {
    if (scene) {
      const [newMeshes, newWires] = getInitializedMeshesWires(scene, flatShading)
      setMeshes(newMeshes)
      setWires(newWires)
      setStatus(({ ...status }) => ({ initialized: true, ...status }))
    }
  }, [scene, flatShading, userData])

  useEffect(() => {
    if (status.initialized) {
      meshes.forEach(mesh => configureMeshMaterial(mesh, material))
      wires.forEach(wire => configureWireMaterial(wire, material))
      if (!status.configured) {
        setStatus(({ ...status }) => ({ configured: true, ...status }))
      }
    }
  }, [status, meshes, wires, material])

  useEffect(() => {
    if (status.configured) {
      setMergedOpacity(meshes, wires, material, targetOpacity)
      if (!status.mergedOpacitySet) {
        setStatus(({ ...status }) => ({ mergedOpacitySet: true, ...status }))
      }
    }
  }, [status, meshes, wires, material, targetOpacity])

  useEffect(() => {
    if (status.mergedOpacitySet) {
      const fullyTransparent = typeof targetOpacity !== "undefined" && targetOpacity <= 0
      setVisible(targetVisibility && !fullyTransparent)
    }
  }, [status, targetVisibility, targetOpacity])

  // Fade animation
  const opacityDataRef = useRef<
    { meshes: THREE.Mesh[]; wires: THREE.LineSegments[]; material: MaterialData } | undefined
  >(undefined)
  useEffect(() => {
    opacityDataRef.current = { meshes, wires, material }
  }, [meshes, wires, material])

  useSpring(
    {
      from: { opacity: fadeDuration ? 0.0 : targetOpacity ?? 1 },
      to: { opacity: targetOpacity ?? 1 },
      config: { duration: fadeDuration ?? 0 },
      immediate: typeof targetOpacity !== "undefined",
      onFrame: ({ opacity }: { opacity: number | undefined }) => {
        if (!fadeDuration) return
        if (opacityDataRef.current && opacityDataRef.current.material) {
          const { meshes, wires, material } = opacityDataRef.current
          setMergedOpacity(meshes, wires, material, opacity)
        }
        const fullyTransparent = opacity !== undefined && opacity <= 0
        setVisible(!fullyTransparent)
        transferInvalidate()
      },
    },
    [fadeDuration, targetOpacity, opacityDataRef, setMergedOpacity, setVisible, transferInvalidate]
  )

  useEffect(() => {
    scene && sceneCallback?.(scene)
  }, [sceneCallback, scene])

  if (!scene) return null

  return (
    <group visible={visible} ref={sceneRef}>
      <Primitive object={scene}>{children}</Primitive>
      {/* {lineSegments && <primitive object={lineSegments} />} */}
    </group>
  )
}

function getInitializedMeshesWires(
  scene: THREE.Group,
  flatShading: boolean
): [THREE.Mesh[], THREE.LineSegments[]] {
  const newMeshes: THREE.Mesh[] = []
  const newWires: THREE.LineSegments[] = []
  scene.traverse((obj3d: THREE.Object3D) => {
    if (obj3d.type === "Mesh") {
      const mesh = obj3d as THREE.Mesh
      const material = (mesh.material as THREE.MeshStandardMaterial).clone() // Make each face independent
      material.flatShading = flatShading

      mesh.material = material
      if (mesh.geometry.getAttribute("normal") === undefined) {
        mesh.geometry.computeVertexNormals()
      }
      newMeshes.push(mesh)
    } else if (obj3d.type === "LineSegments") {
      const wires = obj3d as THREE.LineSegments
      wires.material = (wires.material as THREE.LineBasicMaterial).clone() // Make each set of wires independent
      newWires.push(wires)
    }
  })
  return [newMeshes, newWires]
}

function setMergedOpacity(
  meshes: THREE.Mesh[],
  wires: THREE.LineSegments[],
  material: MaterialData,
  opacity?: number
) {
  if (opacity === undefined) return

  const netOpacity = (material.opacity ?? 1) * opacity
  if (netOpacity >= 1) {
    meshes.forEach(mesh => configureMeshMaterial(mesh, material))
    wires.forEach(wire => configureWireMaterial(wire, material))
  } else {
    meshes.forEach(mesh => configureMaterialOpacity(mesh, netOpacity))
    wires.forEach(wire => configureMaterialOpacity(wire, netOpacity))
  }
}

export const AnimatedGltfUrlModel = animated(GltfUrlModel)
export type AnimatedGltfModelProps = React.ComponentProps<AnimatedComponent<FC<GltfModelProps>>>
