import React, { FC, useEffect, useState } from "react"
import { Button, ButtonGroup, EditableText } from "@blueprintjs/core"

import { PlanGenerationFeedback, useUpdateGenerationFeedbackMutation } from "src/graphql/generated"

import styles from "./AnnotationButtons.module.css"

enum AnnotationType {
  BAD = "BAD",
  OKAY = "OKAY",
  GOOD = "GOOD",
}

export const AnnotationButtons: FC<{
  generatedFeedback: PlanGenerationFeedback | undefined
  planId: string
  component: string
  revision: number | undefined
  notes?: string | null | undefined
  getSidebarData: () => void
}> = ({ generatedFeedback, planId, component, revision, getSidebarData }) => {
  const [updateGenerationFeedbackMutation] = useUpdateGenerationFeedbackMutation()
  const [noteText, setNoteText] = useState<string | null | undefined>(
    generatedFeedback?.notes || ""
  )
  const [isEditingNotes, setIsEditingNotes] = useState<boolean>(false)

  useEffect(() => {
    setNoteText(generatedFeedback?.notes)
  }, [generatedFeedback])

  const intentMap = {
    [AnnotationType.BAD]: "danger",
    [AnnotationType.OKAY]: "none",
    [AnnotationType.GOOD]: "success",
  } as const

  const handleClick = (annotationType: AnnotationType) => {
    updateGenerationFeedbackMutation({
      variables: {
        planId,
        component,
        label: annotationType,
        ...(revision !== undefined && { revision }),
        notes: noteText,
      },
      refetchQueries: ["getGenerationFeedbackByPlanIds"],
    }).then(() => getSidebarData())
  }

  const handleUpdateNote = (label: string): void => {
    setIsEditingNotes(false)
    if (!label.length || label === generatedFeedback?.notes) return
    updateGenerationFeedbackMutation({
      variables: {
        planId,
        component,
        label: generatedFeedback?.label || "",
        notes: noteText,
        ...(revision !== undefined && { revision }),
      },
      refetchQueries: ["getGenerationFeedbackByPlanIds"],
    }).then(() => getSidebarData())
  }

  return (
    <div className={styles.container}>
      <ButtonGroup
        className={`${styles.buttonGroup} ${
          generatedFeedback?.label
            ? styles[intentMap[generatedFeedback?.label as AnnotationType]]
            : ""
        }`}
      >
        {Object.keys(AnnotationType).map(annotationType => {
          const intent: "danger" | "none" | "success" = intentMap[annotationType as AnnotationType]
          return (
            <Button
              key={annotationType}
              intent={intent}
              active={generatedFeedback?.label === annotationType}
              onClick={() => handleClick(annotationType as AnnotationType)}
            >
              {annotationType}
            </Button>
          )
        })}
      </ButtonGroup>

      <div className={styles.notes}>
        <EditableText
          className={!isEditingNotes ? styles.editableText : ""}
          value={noteText || ""}
          onChange={setNoteText}
          onConfirm={handleUpdateNote}
          placeholder={"Notes"}
          selectAllOnFocus
          onEdit={() => setIsEditingNotes(true)}
          onCancel={() => setIsEditingNotes(false)}
        />
      </div>
    </div>
  )
}
