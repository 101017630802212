import React, { FC } from "react"
import { useSelector } from "react-redux"

import { AppModelsGeometryTransform as Transform } from "src/client-axios"
import { TransformInput } from "src/components/Generic/Forms/TransformInput/TransformInput"
import { useUpdateInputStockTransformMutation } from "src/graphql/generated"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { CoordinateSystem, viewOptionsSelectors } from "src/store/ui/viewOptions"

export const ProductStockTransform: FC<{
  planId: string
  operationIdx: number
  transform: Transform
  locked?: boolean
}> = ({ planId, operationIdx, transform, locked }) => {
  const [updateTransform] = useUpdateInputStockTransformMutation()

  const mcs = useSelector((state: RootState) =>
    storedOperationSelectors.selectMcs(state, planId, operationIdx)
  )
  const coordinates: CoordinateSystem = useSelector(viewOptionsSelectors.coordinates)

  return (
    <TransformInput
      locked={locked}
      showIncremental={true}
      label="Part-Relative Transform"
      mcs={coordinates === "machine" ? mcs : undefined}
      wcs={transform}
      transform={transform}
      onChange={transform =>
        updateTransform({
          variables: {
            planId,
            operationIdx,
            transform,
          },
        })
      }
    />
  )
}
