import React, { FC } from "react"
import * as THREE from "three"

import { Arrow, arrowGroup } from "./Arrow"

const ORIGIN = new THREE.Vector3(0, 0, 0)
const X = new THREE.Vector3(1, 0, 0)
const Y = new THREE.Vector3(0, 1, 0)
const Z = new THREE.Vector3(0, 0, 1)

const makeColor = (c: [number, number, number]) => {
  const v = [
    Math.floor(Math.pow(c[0] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[1] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[2] / 255, 2.0) * 255),
  ]
  return `rgb(${v[0]}, ${v[1]}, ${v[2]})`
}

const X_COLOR = new THREE.Color(makeColor([170, 0, 0]))
const Y_COLOR = new THREE.Color(makeColor([0, 128, 0]))
const Z_COLOR = new THREE.Color(makeColor([0, 0, 170]))

interface TrihedronProps {
  radius: number
  length: number
}

export function trihedronGroup(radius: number, length: number): THREE.Group {
  const group = new THREE.Group()
  const x_arrow = arrowGroup(ORIGIN, X, X_COLOR, radius, length, 0.0, false)
  const y_arrow = arrowGroup(ORIGIN, Y, Y_COLOR, radius, length, 0.0, false)
  const z_arrow = arrowGroup(ORIGIN, Z, Z_COLOR, radius, length, 0.0, false)
  group.add(x_arrow)
  group.add(y_arrow)
  group.add(z_arrow)
  return group
}

export const Trihedron: FC<TrihedronProps> = ({ radius, length }) => {
  return (
    <group>
      <Arrow
        position={ORIGIN}
        direction={X}
        color={X_COLOR}
        radius={radius}
        length={length}
        offset={0.0}
        reverse={false}
      />
      <Arrow
        position={ORIGIN}
        direction={Y}
        color={Y_COLOR}
        radius={radius}
        length={length}
        offset={0.0}
        reverse={false}
      />
      <Arrow
        position={ORIGIN}
        direction={Z}
        color={Z_COLOR}
        radius={radius}
        length={length}
        offset={0.0}
        reverse={false}
      />
    </group>
  )
}
