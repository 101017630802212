import React, { FC } from "react"
import { PopoverInteractionKind, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"

import styles from "./AbbreviatedLabel.module.css"

export const AbbreviatedLabel: FC<{ label: string; maxLength: number }> = ({
  label,
  maxLength,
}) => {
  if (!label) return null
  return label.length > maxLength ? (
    <Popover2
      minimal
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.RIGHT}
      content={<div className={styles.popoverContainer}>{label}</div>}
    >
      <div className={styles.label} style={{ maxWidth: 8 * maxLength }}>
        {label}
      </div>
    </Popover2>
  ) : (
    <>{label}</>
  )
}
