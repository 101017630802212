import { createAction, createReducer } from "@reduxjs/toolkit"

import { RootState } from "src/store/rootStore"

export interface FileModalFile {
  locator: string | undefined
  labelText?: string | undefined
  language?: string | undefined
  annotations?: Annotation[]
}

interface FileModalProps {
  files: FileModalFile[]
}

export interface Annotation {
  row: number
  column?: number
  text: string
  type: "error" | "warning"
}

const setFileModalIsOpen = createAction<boolean>("fileModal/setFileModalIsOpen")

const setFileModalProps = createAction<FileModalProps>("fileModal/setFileModalProps")

export const fileModalReducer = createReducer<{
  fileModalIsOpen: boolean
  fileModalProps: FileModalProps
}>(
  {
    fileModalIsOpen: false,
    fileModalProps: { files: [] },
  },
  builder => {
    builder.addCase(setFileModalIsOpen, (state, action) => {
      state.fileModalIsOpen = action.payload
    })
    builder.addCase(setFileModalProps, (state, action) => {
      state.fileModalProps = action.payload
    })
  }
)

/**
 * Actions
 */
export const fileModalActions = {
  setFileModalIsOpen,
  setFileModalProps,
}

/**
 * Selectors
 */
const selectFileModalIsOpen = (state: RootState): boolean => state.fileModal.fileModalIsOpen
const selectFileModalProps = (state: RootState): FileModalProps => state.fileModal.fileModalProps

export const fileModalSelectors = {
  selectFileModalIsOpen,
  selectFileModalProps,
}
