import { useEffect } from "react"
import { useThree } from "@react-three/fiber"

/**
 * This component ensures the camera that will be used throughout the app remains mounted into the scene.
 * This enables us to add a point light just by mounting it onto the camera.
 *
 * Users of this library should take care to keep the camera mounted into the relevant scene if they want to make
 * use of the camera-mounted lighting functionality. Structuring the library in this way allows it to be agnostic
 * to the approach to camera implementation.
 */
const useMountCamera = (): void => {
  const { camera, scene } = useThree()

  useEffect(() => {
    scene.add(camera)
    camera.position.set(-500, -500, 500)
    return () => {
      scene.remove(camera)
    }
  }, [camera, scene])
}

export default useMountCamera
