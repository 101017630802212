import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Classes, Dialog, HTMLSelect, Icon, Label } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { isEqual } from "lodash-es"

import { MachineKind, MachineOffset, OffsetKind, OffsetStateKind } from "src/client-axios"
import { MACHINE_OFFSET_VALUES_KEY_COMPARATOR } from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/ProductionControlsTreeLabel/MachineOffsetSettingsPanel/MachineOffsetSettingsPanel"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { activeSelectors } from "src/store/cam/active"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { MachineInstanceSelector } from "../MachineInstanceSelector/MachineInstanceSelector"
import { ALLOW_CUSTOM_OFFSETS, defaultOffsets } from "../utils"

import styles from "./AddMachineOffsetDialog.module.css"

export const AddMachineOffsetDialog: FC<{
  onCreate: (machineOffset: MachineOffset) => void
  checkExists: (machineOffset: MachineOffset) => boolean
  onClose: () => void
  kind: OffsetKind
  toolchangeIndex?: number
  toolChanges: string[]
}> = ({ onCreate, checkExists, onClose, kind, toolchangeIndex, toolChanges }) => {
  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const machineId = useSelector((state: RootState) =>
    storedOperationSelectors.selectMachineId(state, planId, operationIdx)
  )

  const [toolChangeIdx, setToolChangeIdx] = useState<number>(toolchangeIndex ?? 0)
  const [offsetKind, setOffsetKind] = useState(kind)
  const [offsets, setOffsets] = useState(defaultOffsets(kind))
  const [machineKind, setMachineKind] = useState(machineId ?? MachineKind.DoosanDvf5000)
  const [machineInstance, setMachineInstance] = useState("")

  const prevToolchangeToolId = toolChanges[toolChangeIdx - 1]
  const toolchangeToolId = toolChanges[toolChangeIdx]
  const nextToolchangeToolId = toolChanges[toolChangeIdx + 1]

  const previousTool = prevToolchangeToolId !== undefined ? Number(prevToolchangeToolId) : undefined
  const currentTool = Number(toolchangeToolId)
  const nextTool = nextToolchangeToolId !== undefined ? Number(nextToolchangeToolId) : undefined

  const toolchangeSignature = {
    prev: prevToolchangeToolId,
    curr: toolchangeToolId,
    next: nextToolchangeToolId,
    index: toolChangeIdx,
  }

  const toolchangeSignatures =
    toolChanges?.map((tc, i) => {
      const prevTc = toolChanges[i - 1]
      const nextTc = toolChanges[i + 1]
      return {
        prev: prevTc,
        curr: tc,
        next: nextTc,
        index: i,
      }
    }) ?? []

  const signatures =
    toolchangeSignatures.filter(tc => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index: _1, ...tcSig } = tc
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index: _2, ...tcSig2 } = toolchangeSignature
      return isEqual(tcSig, tcSig2)
    }) ?? []

  const signatureIndex = signatures?.findIndex(tc => isEqual(tc, toolchangeSignature)) ?? 0
  const signatureCount = signatures?.length ?? 1

  const handleKindChange = (kind: OffsetKind) => {
    if (kind !== offsetKind) {
      setOffsetKind(kind)
      setOffsets(defaultOffsets(kind))
    }
  }

  const handleConfirmOffsetKeyValue = (oldKey: string, newKey: string, value: number) => {
    let newOffsets = offsets
    if (oldKey !== newKey) {
      delete newOffsets[oldKey]
    }
    newOffsets = { ...newOffsets, [newKey]: value }
    setOffsets(newOffsets)
  }

  const handleRemoveOffsetKeyValue = (key: string) => {
    const newOffsets = offsets
    delete newOffsets[key]
    setOffsets(newOffsets)
  }

  const handleAddOffsetKeyValue = () => {
    setOffsets({ ...offsets, "<Key>": 0 })
  }

  const offsetKindFormatted = new Map<OffsetKind, string>([
    [OffsetKind.POSITION, "Position Offset"],
    [OffsetKind.TOOL, "Tool Offset"],
    [OffsetKind.FEEDANDSPEED, "Feed/Speed"],
    [OffsetKind.TOOLRENUMBER, "Tool Renumber"],
    [OffsetKind.WCS, "WCS"],
  ])

  const offset = {
    previousTool,
    currentTool,
    nextTool,
    instanceIndex: signatureIndex,
    instanceCount: signatureCount,
    kind: offsetKind,
    machineKind: MachineKind.DoosanDvf5000,
    offsets,
    machineInstance,
    currentState: OffsetStateKind.VALID,
  }

  const offsetExists = checkExists(offset)

  return (
    <Dialog
      icon={"info-sign"}
      onClose={onClose}
      title={`Add Machine Control`}
      isOpen={true}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <Tooltip2 content="Apply machine controls at selected toolchange">
            <Label>
              <HTMLSelect
                value={toolChangeIdx}
                onChange={e => setToolChangeIdx(Number(e.target.value))}
              >
                {toolChanges.map((toolChange, toolChangeIdx) => (
                  <option value={toolChangeIdx} key={toolChangeIdx}>
                    {toolChangeIdx + 1} (T{toolChanges[toolChangeIdx]})
                  </option>
                ))}
              </HTMLSelect>
            </Label>
          </Tooltip2>
        </div>
        <div className={styles.offsetFields}>
          <div className={styles.offsetField}>Type</div>
          <div className={styles.offsetField}>Machine</div>
          <div className={styles.offsetField}>Values</div>
        </div>

        <div className={styles.offsetValues}>
          <div className={styles.offsetValue}>
            <HTMLSelect
              value={offsetKind}
              onChange={e => handleKindChange(e.target.value as OffsetKind)}
              className={styles.select}
            >
              {Object.entries(OffsetKind).map(([key, value]) => {
                return (
                  <option value={value} key={key}>
                    {offsetKindFormatted.get(value) ?? key}
                  </option>
                )
              })}
            </HTMLSelect>
          </div>
          <div className={styles.offsetValue}>
            <HTMLSelect
              value={machineKind}
              onChange={e => setMachineKind(e.target.value as MachineKind)}
              className={styles.select}
            >
              {Object.entries(MachineKind).map(([key, value]) => {
                return (
                  <option value={value} key={key}>
                    {key}
                  </option>
                )
              })}
            </HTMLSelect>
            <div className={styles.machineInstanceContainer}>
              <Label htmlFor="machineInstance" className={styles.instanceLabel}>
                Instance:
                <MachineInstanceSelector
                  activeItem={machineInstance}
                  onMachineChanged={machineInstance => {
                    setMachineInstance(machineInstance)
                  }}
                ></MachineInstanceSelector>
              </Label>
            </div>
          </div>
          <div className={styles.offsetValue}>
            {Object.entries(offsets)
              .sort(MACHINE_OFFSET_VALUES_KEY_COMPARATOR)
              .map(([key, value]) => {
                return (
                  <div className={styles.offsetInputRow} key={key}>
                    <div>
                      <input
                        type="text"
                        value={key}
                        className={styles.offsetInputKey}
                        disabled={true}
                        onChange={e => {
                          handleConfirmOffsetKeyValue(key, e.target.value, value)
                        }}
                      />
                    </div>
                    <div>
                      {key === "wcs" && offset.kind === OffsetKind.WCS && (
                        <select
                          value={value}
                          onChange={e => {
                            handleConfirmOffsetKeyValue(key, key, parseInt(e.target.value))
                          }}
                        >
                          {(() => {
                            const arr = []
                            for (let i = 55; i <= 59; i++) {
                              arr.push(
                                <option value={i} key={i}>
                                  G{i}
                                </option>
                              )
                            }
                            return arr
                          })()}
                        </select>
                      )}
                      {(key !== "wcs" || offset.kind !== OffsetKind.WCS) && (
                        <FormulaInput
                          fill
                          commitOnBlur={true}
                          reset={false}
                          value={value}
                          onValueChange={v => {
                            handleConfirmOffsetKeyValue(key, key, v)
                          }}
                          suffix={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? "%"
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? "TC"
                              : "mm"
                          }
                          stepSize={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? 1.0
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? 1
                              : 0.1
                          }
                          minorStepSize={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? 0.1
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? 1
                              : 0.001
                          }
                        />
                      )}
                    </div>
                    {ALLOW_CUSTOM_OFFSETS ? (
                      <Button onClick={() => handleRemoveOffsetKeyValue(key)}>
                        <Icon icon="cross" />
                      </Button>
                    ) : null}
                  </div>
                )
              })}
            {ALLOW_CUSTOM_OFFSETS ? (
              <Button rightIcon="add" onClick={handleAddOffsetKeyValue}>
                Add offset key value
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {offsetExists && <Label className={styles.offsetExists}>Control already exists</Label>}
          <Button
            disabled={offsetExists}
            onClick={() => {
              onCreate(offset)
              onClose()
            }}
            intent="primary"
          >
            Submit
          </Button>
          <Button onClick={onClose} intent="danger">
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
