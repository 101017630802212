import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ApolloClient } from "@apollo/client/core/ApolloClient"
import { ApolloProvider } from "@apollo/client/react"
import { Intent, MenuDivider, MenuItem } from "@blueprintjs/core"

import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { useApi } from "../../hooks/useApi"

export const CreateOperationMenuItems: FC<{
  planId: string
  operationIdx: number
  isBefore?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>
}> = ({ planId, operationIdx, isBefore, client }) => {
  return (
    <ApolloProvider client={client}>
      <ProvidedCreateOperationMenuItems
        planId={planId}
        operationIdx={operationIdx}
        isBefore={isBefore}
      />
    </ApolloProvider>
  )
}

/**
 * isBefore indicates whether the menu items should be written as though the choice is "positioned"
 * _before_ the specified operationIdx, or _on_ the specified operationIdx
 */
export const ProvidedCreateOperationMenuItems: FC<{
  planId: string
  operationIdx: number
  isBefore?: boolean
  intent?: Intent
}> = ({ planId, operationIdx, intent, isBefore = false }) => {
  const operations =
    useSelector((state: RootState) => storedPlansSelectors.selectOperations(state, planId)) ?? []

  const { plansApi } = useApi()

  const handleNewOperationClick = (
    operationIdx: number,
    copyOperationIdx: number | null,
    transferInputStock?: boolean
  ) => () => {
    plansApi
      .createOperation(planId, operationIdx, copyOperationIdx ?? undefined, transferInputStock)
      .then(result => {
        console.log("handleNewOperationClick -> result", result)
      })
  }

  return isBefore ? (
    <>
      <MenuItem
        intent={intent}
        onClick={handleNewOperationClick(operationIdx, null, false)}
        text={"Create Blank Operation"}
      />
      {operations.length > 0 && <MenuDivider />}
      {operationIdx > 0 && (
        <MenuItem
          intent={intent}
          onClick={handleNewOperationClick(operationIdx, operationIdx - 1, false)}
          text={"Copy Previous Operation"}
        />
      )}
      {operationIdx < operations.length && (
        <MenuItem
          intent={intent}
          onClick={handleNewOperationClick(operationIdx, operationIdx)}
          text={"Copy Next Operation"}
        />
      )}
    </>
  ) : (
    <>
      <MenuItem
        intent={intent}
        onClick={handleNewOperationClick(operationIdx, null)}
        text={"Create Blank Before"}
      />
      <MenuItem
        intent={intent}
        onClick={handleNewOperationClick(operationIdx + 1, null)}
        text={"Create Blank After"}
      />
      <MenuDivider />
      <MenuItem
        intent={intent}
        onClick={handleNewOperationClick(operationIdx, operationIdx)}
        text={"Copy Before"}
      />
      <MenuItem
        intent={intent}
        onClick={handleNewOperationClick(operationIdx + 1, operationIdx)}
        text={"Copy After"}
      />
    </>
  )
}
