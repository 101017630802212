import { Dictionary } from "@reduxjs/toolkit"
import * as THREE from "three"

import {
  AppModelsGeometryTransform as Transform,
  FixtureChoice,
  FixtureRecord,
  FixturesConfig,
} from "src/client-axios/api"
import { transformToMatrix } from "src/util/geometry/transforms"

export const getInverseMcsTransformMatrix = (mcs: Transform): THREE.Matrix4 => {
  const matrix = getMcsTransformMatrix(mcs)
  matrix.invert()
  return matrix
}

export const getMcsTransformMatrix = (mcs: Transform): THREE.Matrix4 => {
  return transformToMatrix(mcs)
}

export const getInverseFocusFixtureTransformMatrix = (
  mcs: Transform,
  fixtures: FixtureChoice | undefined,
  config: FixturesConfig
): THREE.Matrix4 => {
  const matrix = getFocusFixtureTransformMatrix(mcs, fixtures, config)
  matrix.invert()
  return matrix
}

export const getFocusFixtureTransformMatrix = (
  mcs: Transform,
  fixtures: FixtureChoice | undefined,
  config: FixturesConfig
): THREE.Matrix4 => {
  const mcsTransformMatrix = getMcsTransformMatrix(mcs)
  if (!fixtures) return mcsTransformMatrix

  const matrices = [mcsTransformMatrix]

  const transformStack = getFocusFixtureTransformStack(
    fixtures.rootElementId,
    fixtures,
    config,
    matrices
  ).matrices

  const matrix = new THREE.Matrix4()
  transformStack.forEach(m => matrix.multiply(m))

  return matrix
}

export const getClickedBiteTransform = (
  clickedPoint: THREE.Vector3,
  clickedNormal: THREE.Vector3,
  parentFixtureTransform: THREE.Matrix4
): Transform => {
  const inverseParentTransform = parentFixtureTransform.clone().invert()
  const inverseRotation = new THREE.Quaternion()
  inverseParentTransform.decompose(new THREE.Vector3(), inverseRotation, new THREE.Vector3())

  const untransformedPoint = clickedPoint.clone().applyMatrix4(inverseParentTransform)
  const untransformedNormal = clickedNormal.clone().applyQuaternion(inverseRotation)

  return {
    x: untransformedPoint.x,
    y: untransformedPoint.y,
    z: 0,
    i: 0,
    j: 0,
    k: (Math.atan2(-untransformedNormal.y, -untransformedNormal.x) * 180) / Math.PI,
  }
}

export const getParentTransformMatrix = (
  elementId: string,
  fixtureChoice: FixtureChoice,
  config: FixturesConfig
): THREE.Matrix4 => {
  const element2parent: Dictionary<{ parentId: string; attachment: string }> = {}
  fixtureChoice.elements.forEach(parent => {
    parent.children.forEach(child => {
      element2parent[child.element] = { parentId: parent.id, attachment: child.identifier }
    })
  })

  const stack = getReversedFixtureTransformStack(
    elementId,
    fixtureChoice,
    config,
    element2parent,
    []
  ).matrices

  const matrix = new THREE.Matrix4()
  for (const m of stack) {
    matrix.premultiply(m)
  }
  return matrix
}

const getReversedFixtureTransformStack = (
  elementId: string,
  fixtureChoice: FixtureChoice,
  config: FixturesConfig,
  element2parent: Dictionary<{ parentId: string; attachment: string }>,
  matrices: THREE.Matrix4[]
): { matrices: Array<THREE.Matrix4> } => {
  // TODO: Should handle nonzero movement if appropriate

  const parentData = element2parent[elementId]
  if (!parentData) {
    return { matrices }
  }
  const { parentId: parentElementId, attachment } = parentData

  const parentElement = fixtureChoice.elements.find(element => element.id === parentElementId)
  if (!parentElement) {
    return { matrices }
  }

  const outputMatrices = Array.from(matrices)

  const parentFixtureRecord = getFixtureRecord(parentElement.fixture, config)
  const attachmentTransform = parentFixtureRecord?.points?.[attachment]?.transform
  attachmentTransform && outputMatrices.push(transformToMatrix(attachmentTransform))

  if (parentElement.transform) {
    if (parentFixtureRecord?.isTransformAllowed || parentElement.customModelId !== undefined) {
      outputMatrices.push(transformToMatrix(parentElement.transform))
    } else {
      console.warn(
        `A transform was specified for fixture `,
        parentFixtureRecord,
        `(element `,
        parentElement,
        `); it will be ignored`
      )
    }
  }

  return getReversedFixtureTransformStack(
    parentElementId,
    fixtureChoice,
    config,
    element2parent,
    outputMatrices
  )
}

export const getFixtureRelativeTransformMatrix = (
  elementId: string,
  fixtureChoice: FixtureChoice,
  config: FixturesConfig
): THREE.Matrix4 => {
  const identityMatrix = new THREE.Matrix4()

  const element = fixtureChoice.elements.find(element => element.id === elementId)
  if (element) {
    const fixtureRecord = getFixtureRecord(element.fixture, config)
    if (element.transform) {
      if (fixtureRecord?.isTransformAllowed || element.customModelId !== undefined) {
        return transformToMatrix(element.transform)
      }
    }
  }

  const element2parent: Dictionary<{ parentId: string; attachment: string }> = {}
  fixtureChoice.elements.forEach(parent => {
    parent.children.forEach(child => {
      element2parent[child.element] = { parentId: parent.id, attachment: child.identifier }
    })
  })

  const parentData = element2parent[elementId]
  if (!parentData) {
    return identityMatrix
  }
  const { parentId: parentElementId, attachment } = parentData

  const parentElement = fixtureChoice.elements.find(element => element.id === parentElementId)
  if (!parentElement) {
    return identityMatrix
  }

  const parentFixtureRecord = getFixtureRecord(parentElement.fixture, config)
  const attachmentTransform = parentFixtureRecord?.points?.[attachment]?.transform
  return attachmentTransform ? transformToMatrix(attachmentTransform) : identityMatrix
}

const getFocusFixtureTransformStack = (
  elementId: string,
  fixtureChoice: FixtureChoice,
  config: FixturesConfig,
  matrices: THREE.Matrix4[]
): { matrices: Array<THREE.Matrix4>; isNonAuxiliary: boolean } => {
  const outputMatrices = Array.from(matrices)

  const element = fixtureChoice.elements.find(element => element.id === elementId)
  if (!element) {
    console.error(`Couldn't find element id ${elementId}`)
    return { matrices: outputMatrices, isNonAuxiliary: false }
  }

  const fixtureRecord = getFixtureRecord(element.fixture, config)
  if (!fixtureRecord) {
    // console.error(`Couldn't find fixture record for fixture ${element.fixture}`)
    return { matrices: outputMatrices, isNonAuxiliary: false }
  }

  if (element.transform) {
    if (fixtureRecord.isTransformAllowed) {
      outputMatrices.push(transformToMatrix(element.transform))
    } else {
      console.warn(
        `A transform was specified for fixture `,
        fixtureRecord,
        `(element `,
        element,
        `); it will be ignored`
      )
    }
  }

  if (!fixtureRecord.isAuxiliary) {
    return { matrices: outputMatrices, isNonAuxiliary: true }
  }

  for (const attachment of element.children) {
    const child = fixtureChoice.elements.find(element => element.id === attachment.element)
    if (!child) {
      console.warn("Can't find child", attachment.element)
      continue
    }
    const pointData = fixtureRecord.points?.[attachment.identifier]
    if (!pointData) {
      console.warn("Can't find point", attachment.identifier, "for fixture", fixtureRecord)
      continue
    }

    outputMatrices.push(transformToMatrix(pointData.transform))
    const { matrices, isNonAuxiliary } = getFocusFixtureTransformStack(
      attachment.element,
      fixtureChoice,
      config,
      outputMatrices
    )
    if (isNonAuxiliary) {
      return { matrices, isNonAuxiliary }
    }
  }
  return { matrices: outputMatrices, isNonAuxiliary: false }
}

const getFixtureRecord = (fixtureId: string, config: FixturesConfig): FixtureRecord | undefined => {
  return config.fixtures.find(v => v.id === fixtureId)
}
