import * as THREE from "three"

import { buildMeshMaterial, buildWireMaterial, MaterialsData } from "../util/materials"

// Use differing offset factors by type to ensure surfaces of different types are not likely to have coinciding offsets.
// I'm not sure if this is the best way to set the polygon offsets to prevent visual artifacts, but I have chosen
// this scheme because I expect the "most interesting" contacts will be those between objects of differing types.

const DESIGN_POLYGON_OFFSET_FACTOR = 2.0
const STOCK_POLYGON_OFFSET_FACTOR = 2.0
const FIXTURE_POLYGON_OFFSET_FACTOR = 2.0

// May want to make it easier to use THREE.FrontSide for investigation of model issues
// If the model looks wrong with FrontSide, it generally means there are problems with the normals

const getDesignMaterials = (transparent: boolean) => ({
  mesh: buildMeshMaterial({
    color: new THREE.Color("#84878a"),
    flatShading: false,
    metalness: 1.0,
    opacity: transparent ? 0.4 : 1.0,
    polygonOffset: true,
    polygonOffsetFactor: DESIGN_POLYGON_OFFSET_FACTOR,
    roughness: 0.6,
    side: THREE.DoubleSide,
    transparent: transparent,
  }),
  wire: buildWireMaterial({ color: new THREE.Color("#000000") }),
})

const getStockMaterials = (transparent: boolean): MaterialsData => ({
  mesh: buildMeshMaterial({
    color: new THREE.Color("#afe9f8"),
    flatShading: true,
    metalness: 0.99,
    opacity: transparent ? 0.2 : 1.0,
    polygonOffset: true,
    polygonOffsetFactor: STOCK_POLYGON_OFFSET_FACTOR,
    roughness: 0.5,
    side: THREE.DoubleSide,
    transparent: transparent,
  }),
  wire: buildWireMaterial({ color: new THREE.Color("#000000") }),
})

const getFixtureMaterials = (transparent: boolean, millable: boolean): MaterialsData => ({
  mesh: buildMeshMaterial({
    // color: millable ? new THREE.Color("#EE874C") : new THREE.Color("#111111"),
    color: new THREE.Color("#EE874C"),
    flatShading: false,
    metalness: 0.88,
    opacity: transparent ? 0.5 : 1.0,
    polygonOffset: true,
    polygonOffsetFactor: FIXTURE_POLYGON_OFFSET_FACTOR,
    roughness: 1.0,
    side: THREE.FrontSide,
    transparent: transparent,
  }),
  wire: buildWireMaterial({ color: new THREE.Color("#000000") }),
})

const getOtherMaterials = (transparent: boolean): MaterialsData => ({
  mesh: buildMeshMaterial({
    // color: new THREE.Color("#ffffff"),
    flatShading: true,
    metalness: 0.0,
    opacity: transparent ? 0.5 : 1.0,
    // polygonOffset: true,
    // polygonOffsetFactor: -1,
    roughness: 0.0,
    side: THREE.DoubleSide,
    transparent: transparent,
  }),
  wire: buildWireMaterial({ color: new THREE.Color("#000000") }),
})

export const MATERIALS = {
  design: getDesignMaterials,
  stock: getStockMaterials,
  fixture: getFixtureMaterials,
  other: getOtherMaterials,
}
