import React, { FC, useEffect } from "react"
import { batch } from "react-redux"
import { useParams } from "react-router-dom"
import { NonIdealState, Spinner } from "@blueprintjs/core"

import { usePublishSummaryQuery } from "src/graphql/generated"
import { activeActions } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode } from "src/store/ui/viewerModal"
import { viewOptionsActions } from "src/store/ui/viewOptions"
import { JobIdOverview } from "../JobOverview/JobOverview"

import styles from "./SetupSheet.module.css"

const VersionWarningBar: FC = () => {
  return (
    <>
      <div className={styles.container}>
        NOTE: PLAN REVISIONS FOR OP NOT BEING VIEWED WILL NOT BE DISPLAYED
      </div>
      <div className={styles.container}>
        OTHER OP&apos;S VERSIONS DON&apos;T CORRESPOND TO THIS PLAN REVISON
      </div>
      <div className={styles.container}>REMOTE SHOP IS BASED ON PLAN REVISIONS</div>
      <div className={styles.container}>METRONOME IS BASED ON OPERATION REVISONS</div>
    </>
  )
}

const SetupSheet: FC = () => {
  const { manifestUri } = useParams<{ manifestUri?: string }>()
  const dispatch = useAppDispatch()
  // const params = new URLSearchParams(window.location.search)

  const { data, loading, error } = usePublishSummaryQuery({ variables: { uri: manifestUri ?? "" } })

  useEffect(() => {
    if (error === undefined && data !== undefined) {
      batch(() => {
        dispatch(activeActions.setActivePlanId(data.publishSummary.planId))
        dispatch(activeActions.setActiveOperationIdx(data.publishSummary.operationIdx))
        dispatch(activeActions.setActiveRevision(data.publishSummary.revision))
        dispatch(viewOptionsActions.setMachineDisplayMode("Interior"))
        dispatch(viewOptionsActions.setDisableRtcp(true))
        dispatch(viewerModalActions.setViewerModalMode(ViewerModalMode.Setup))
        dispatch(viewerModalActions.setViewerModalIsOpen(true))
      })
    }
  }, [data, error, dispatch])

  if (!loading) {
    if (error === undefined && data !== undefined) {
      return (
        <div>
          <VersionWarningBar />
          <JobIdOverview
            jobId={data.publishSummary.jobId}
            planId={data.publishSummary.planId}
            revision={data.publishSummary.revision}
            locked
          />
        </div>
      )
    } else {
      return (
        <div>
          <VersionWarningBar />
          <NonIdealState title="Error with Query">{error?.message}</NonIdealState>
        </div>
      )
    }
  } else {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    )
  }
}

export default SetupSheet
