import React, { FC } from "react"
import { useSelector } from "react-redux"
import { Button, ButtonGroup, Menu, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"

import { ControlPlanUploadKindEnum } from "src/client-axios"
import { FileUpload, FileUploadMode } from "src/components/FileUpload/FileUpload"
import { useCreateQualityControlPlanFileMutation } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { RootState } from "src/store/rootStore"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"

export const QualityControlPlanUpload: FC<{
  jobId: string
  camPlanId: string
  controlPlanId?: string
  controlPlanRevision?: number
  locked?: boolean
  onPlanUpload: () => void
  onFileUpload: () => void
}> = ({
  jobId,
  camPlanId,
  controlPlanId,
  controlPlanRevision,
  locked,
  onPlanUpload,
  onFileUpload,
}) => {
  const errorToast = useNetworkErrorToast()
  const { planchangerApi } = useApi()

  const forceLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.controlPlanLocked(state)
  )

  const editLocked = locked || forceLocked

  const [createControlPlanFile] = useCreateQualityControlPlanFileMutation()

  return (
    <ButtonGroup>
      <FileUpload
        fileUploadMode={FileUploadMode.QualityControlPlanUpload}
        tooltip={"Upload HighQA xdf file. Click to select the file to upload."}
        label={"QC Plan"}
        onUpload={file =>
          planchangerApi
            .associateXdf(jobId, file)
            .catch((error: AxiosError) => {
              errorToast(error, "Upload Control plan error")
            })
            .then(() => {
              onPlanUpload()
            })
        }
        extensionWhitelist={[".xdf"]}
        locked={editLocked}
      />
      <Popover2
        position={Position.BOTTOM}
        disabled={locked}
        content={
          <Menu>
            <FileUpload
              fileUploadMode={FileUploadMode.QualityControlDrawingUpload}
              tooltip={
                controlPlanId
                  ? "Upload files associated with the control plan. Click to select file to upload."
                  : "Upload control plan first before uploading other files."
              }
              label={"QC File"}
              locked={editLocked || !controlPlanId || !camPlanId || !controlPlanRevision}
              onUpload={file =>
                planchangerApi
                  .newFile(
                    JSON.stringify({
                      kind: ControlPlanUploadKindEnum.ControlPlan,
                      camPlanId,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    }) as any,
                    file
                  )
                  .then(result => {
                    if (controlPlanId && controlPlanRevision) {
                      createControlPlanFile({
                        variables: {
                          controlPlanId,
                          controlPlanRevision,
                          fileId: result.data.id,
                        },
                      }).then(() => {
                        onFileUpload()
                      })
                    }
                  })
                  .catch((error: AxiosError) => {
                    errorToast(error, "Upload File Error")
                  })
              }
            />
          </Menu>
        }
      >
        <Button icon={"chevron-down"} disabled={locked} />
      </Popover2>
    </ButtonGroup>
  )
}
