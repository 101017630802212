import React, { FC, useContext, useEffect, useMemo, useState } from "react"
import * as THREE from "three"

import { useGltfLoaderContext } from "../../hooks"
import { Obj3dCallback } from "./types"

export type UrlLoader = (url: string) => string

// Load the urls via the standard `/plm/` interface
export const defaultLoader: UrlLoader = url => {
  return `/plm/${url}`
}

// Load the urls via the remoteshop API
export const remoteshopLoader: UrlLoader = url => {
  return `/api/rs/v1/file?key=${encodeURIComponent(url)}&scene_rendition=true`
}

export const localLoader: UrlLoader = url => {
  return `${url}`
}

export const UrlLoaderContext = React.createContext(defaultLoader)

export const GltfUrlComponent: FC<{
  url: string
  meshMaterial: THREE.Material
  wireMaterial: THREE.Material
  dracoLoaderPath?: string
  obj3dCallback?: Obj3dCallback
}> = ({ url, meshMaterial, wireMaterial, obj3dCallback, children }) => {
  const [scene, setScene] = useState<THREE.Group>()

  const uriLoader = useContext(UrlLoaderContext)
  const loader = useGltfLoaderContext()

  const uri = useMemo(() => uriLoader(url), [url, uriLoader])

  useEffect(() => {
    loader.load(
      uri,
      gltf => {
        const scene = gltf.scene.clone()
        initializeScene(scene, meshMaterial, wireMaterial)
        setScene(scene)
      },

      undefined,
      error => {
        console.error("GLTF Loading error: ", error)
      }
    )
  }, [uri, loader, meshMaterial, wireMaterial])

  useEffect(() => {
    if (scene && obj3dCallback) {
      obj3dCallback(scene)
    }
  }, [scene, obj3dCallback])

  if (!scene) {
    return null
  }

  return <primitive object={scene}>{children}</primitive>
}

/**
 * Currently, this function configures up the loaded scene based on the RemoteShop material configuration for the Part
 */
const initializeScene = (
  scene: THREE.Group,
  meshMaterial: THREE.Material,
  wireMaterial: THREE.Material
): void => {
  scene.traverse((obj3d: THREE.Object3D) => {
    switch (obj3d.type) {
      case "Mesh": {
        const mesh = obj3d as THREE.Mesh
        if (mesh.geometry.attributes.color === undefined) {
          mesh.material = meshMaterial
          mesh.castShadow = true
          mesh.receiveShadow = true
        }

        if (mesh.geometry.getAttribute("normal") === undefined) {
          mesh.geometry.computeVertexNormals()
        }
        return
      }
      case "LineSegments": {
        const wires = obj3d as THREE.LineSegments
        wires.material = wireMaterial
      }
    }
  })
}
