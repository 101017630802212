import React, { FC } from "react"
import { Dialog } from "@blueprintjs/core"

import { MachineOffset } from "src/client-axios"
import { MachineOffsetSettingsPanel } from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/ProductionControlsTreeLabel/MachineOffsetSettingsPanel/MachineOffsetSettingsPanel"

export const EditMachineOffsetDialog: FC<{
  onClose: () => void
  offset: MachineOffset
  offsetId: string
  planId?: string
  operationIdx?: number
}> = ({ onClose, offset, offsetId, planId, operationIdx }) => {
  return (
    <Dialog
      icon={"info-sign"}
      onClose={onClose}
      title={`Edit Machine Control`}
      isOpen={true}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <MachineOffsetSettingsPanel
        offset={offset}
        offsetId={offsetId}
        planId={planId}
        operationIdx={operationIdx}
      />
    </Dialog>
  )
}
