import React, { FC, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Classes,
  HTMLTable,
  Intent,
  NonIdealState,
  ProgressBar,
  Spinner,
  Tag,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { format } from "date-fns"

import { Thumbnail } from "src/components/Generic/Thumbnail/Thumbnail"
import { StopTaskDialog } from "src/components/Websocket/StopTaskDialog/StopTaskDialog"
import { niceName } from "src/components/Websocket/Websocket"
import {
  PlanLabelsFragment,
  TaskProgress,
  useJobBackupForTaskLazyQuery,
  useModelsArchiveForTaskLazyQuery,
  usePlansLabelsLazyQuery,
  useVericutStatsQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { downloadFromUrl } from "src/util/files"

import styles from "./WorkerStats.module.css"

export const WorkerStats: FC = () => {
  const [updatedSince] = useState(new Date(Date.now() - 24 * 3600 * 1000))
  const [progressesPlanIds, setProgressesPlanIds] = useState<string[]>()

  const { data } = useVericutStatsQuery({
    variables: { updatedSince: updatedSince.toISOString() },
    pollInterval: 5000,
  })

  const [getPlansLabels, { data: plansLabels }] = usePlansLabelsLazyQuery()

  const [toStop, setToStop] = useState<string | undefined>()
  const [forceClose, setForceClose] = useState(false)

  useEffect(() => {
    const planIds = data?.taskProgresses?.nodes?.map(progress => progress.planId)
    if (planIds) {
      const validPlanIds = planIds.filter(planId => planId) as string[]
      const uniquePlanIds = new Set(validPlanIds)
      const ids = [...uniquePlanIds]
      setProgressesPlanIds(ids)
      getPlansLabels({ variables: { ids } })
    }
  }, [data, getPlansLabels])

  const planIdLabelMap: Record<string, PlanLabelsFragment> = {}
  plansLabels?.plansById.forEach((v, i) => {
    const planId = progressesPlanIds?.[i]
    if (planId) {
      planIdLabelMap[planId] = v
    }
  })

  const interactiveLock = data?.vericutLocks
    .filter(val => val.name === "Interactive" && val.locks.length > 0)
    .map(val => val.locks[0])
    .find(() => true)

  if (!data) {
    return <Spinner />
  }

  const planOpLabel = (planId: string, opIdx: number) => {
    const labels = planIdLabelMap[planId]
    if (!labels) {
      return "N/A"
    } else {
      const opLabel = labels.operations[opIdx]?.label ?? "N/A"
      return `${labels.label} ${opLabel}`
    }
  }

  return (
    <div className={styles.statsContainer}>
      <div className={styles.statsDiv}>
        {data.workerStats.length > 0 && (
          <>
            <h1>Worker Stats</h1>
            <HTMLTable
              className={styles.statsTable}
              bordered={true}
              condensed={true}
              interactive={true}
              striped={true}
            >
              <thead>
                <tr>
                  <th>Task Type</th>
                  <th>Max CPU Usage</th>
                  <th>RAM Usage</th>
                  <th>Tasks Processed</th>
                  <th>Average Time</th>
                </tr>
              </thead>
              <tbody>
                {data.workerStats
                  .filter(val => val.name !== "save_vericut_project")
                  .map((val, i) => {
                    const maxCpu = val.info.cpu.reduce((p, v) => (p > v ? p : v), 0)

                    return (
                      <tr key={i}>
                        <td>
                          <Tag className={styles.tagCell} minimal>
                            {niceName(val.name)}
                          </Tag>
                        </td>
                        <td>
                          <TagCell val={maxCpu} />
                        </td>
                        <td>
                          <TagCell val={val.info.mem} />
                        </td>
                        <td>{val.info.numProcessedTasks}</td>
                        <td>{val.info.avgTimeMs.toFixed(0)} ms</td>
                      </tr>
                    )
                  })}
                {interactiveLock && (
                  <tr key={"interactive"}>
                    <td>
                      <Tag intent="warning" className={styles.tagCell} minimal>
                        Interactive
                      </Tag>
                    </td>
                    <td colSpan={4}>
                      An Interactive Session is currently Open{" "}
                      <Button
                        intent="warning"
                        onClick={() => {
                          setForceClose(true)
                          setToStop(interactiveLock)
                        }}
                      >
                        Forcefully Close Task
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </HTMLTable>
          </>
        )}

        {!data.workerStats.some(val => val.name === "run_vericut_simulation") && (
          <NonIdealState
            className={styles.offlineState}
            icon="offline"
            title="No Vericut Workers Running"
            description="Please ensure that vericut workers are running on the camplete machine"
          />
        )}

        <h1>Recent Task Status</h1>
        <HTMLTable className={styles.statsTable} condensed={true}>
          <thead>
            <tr>
              <th>Task Name</th>
              <th></th>
              <th>Part Name</th>
              <th>Plan/Op</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Progress</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.taskProgresses?.nodes
              .filter(progress => !progress.name.endsWith("generate_scene_task"))
              .map((progress, i) => {
                let intent: Intent = "none"

                switch (progress.status) {
                  case "ERROR":
                    intent = "danger"
                    break
                  case "WARNING":
                    intent = "warning"
                    break
                  case "PROCESSING":
                    intent = "primary"
                    break
                  case "SUCCESS":
                    intent = "success"
                }

                return (
                  <React.Fragment key={i}>
                    <tr className={i % 2 ? styles.oddRow : undefined}>
                      <td>{niceName(progress.name)}</td>
                      <td>
                        {progress.job && (
                          <Link to={`/jobs/${progress.job.uri}`}>
                            <Thumbnail model={progress.job.model} small />
                          </Link>
                        )}
                      </td>
                      <td>
                        {progress.job ? (
                          <Link to={`/jobs/${progress.job.uri}`}>{progress.job.label}</Link>
                        ) : (
                          <span className={Classes.TEXT_MUTED}>N/A</span>
                        )}
                      </td>
                      <td>
                        {progress.job &&
                        typeof progress.operationIdx === "number" &&
                        planIdLabelMap[progress.planId ?? ""] ? (
                          planOpLabel(progress.planId ?? "", progress.operationIdx)
                        ) : (
                          <span className={Classes.TEXT_MUTED}>N/A</span>
                        )}
                      </td>
                      <td>{progress.status}</td>
                      <td>{progress.createdBy}</td>
                      <td>{format(new Date(progress.createdAt), "MMM d h:mma")}</td>
                      <td>{format(new Date(progress.updatedAt), "MMM d h:mma")}</td>
                      <td>
                        <ProgressBar
                          className={styles.taskProgress}
                          intent={intent}
                          animate={intent === "primary"}
                          stripes={intent === "primary"}
                          value={progress.progress}
                        />
                      </td>
                      <td>
                        {(progress.status === "PROCESSING" || progress.status === "PENDING") && (
                          <Button
                            minimal
                            onClick={() => {
                              if (progress?.id) {
                                setToStop(progress.id)
                              }
                            }}
                            icon="stop"
                          >
                            Stop
                          </Button>
                        )}
                        {progress.status === "SUCCESS" && (
                          <ToastProgressDoneDownload value={progress as TaskProgress} />
                        )}
                      </td>
                    </tr>
                    {progress.message && (
                      <tr className={i % 2 ? styles.oddRow : undefined}>
                        <td colSpan={8}>
                          <pre className={Classes.CODE_BLOCK + " " + styles.taskMessage}>
                            {progress.message}
                          </pre>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
          </tbody>
        </HTMLTable>

        <h1>Software Licenses</h1>
        <HTMLTable
          className={styles.statsTable}
          bordered={true}
          condensed={true}
          interactive={true}
          striped={true}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>License server host</th>
              <th>Remote Access</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>hyperMILL</td>
              <td>10.201.52.3</td>
              <td>
                <a href="anydesk:883202136">AnyDesk ID 883202136</a>
              </td>
              <td>
                <Button
                  intent="primary"
                  onClick={() => window.open("http://10.201.52.3:22350/", "_blank", "noreferrer")}
                >
                  Status [shop network only]
                </Button>
              </td>
            </tr>
            <tr>
              <td>WinTool</td>
              <td>10.3.204.215</td>
              <td>
                <a href="anydesk:611870663">AnyDesk ID 611870663</a>
              </td>
              <td>
                <Button
                  intent="primary"
                  onClick={() => window.open("http://10.3.204.215:22350/", "_blank", "noreferrer")}
                >
                  Status [shop network only]
                </Button>
              </td>
            </tr>
            <tr>
              <td>Vericut</td>
              <td>18.205.82.247</td>
              <td>
                <a href="rdp:full%20address:s:18.205.82.247&username:s:Administrator">
                  Microsoft Remote Desktop (RDP)
                </a>
              </td>
              <td>
                From RDP: \\Start\CGTech License Administration\SentinelLM License Administrator
                (WlmAdmin)
              </td>
            </tr>
          </tbody>
        </HTMLTable>
      </div>
      <StopTaskDialog
        forceClose={forceClose}
        setForceClose={setForceClose}
        toStop={toStop}
        setToStop={setToStop}
      />
    </div>
  )
}

const TagCell: FC<{ val: number }> = ({ val }) => {
  let intent: Intent = "none"

  if (val > 90) {
    intent = "danger"
  } else if (val > 70) {
    intent = "warning"
  } else if (val > 50) {
    intent = "primary"
  }

  return (
    <Tag intent={intent} className={styles.tagCell}>
      {val.toFixed(2)}%
    </Tag>
  )
}

const ToastProgressDoneDownload: FC<{ value: TaskProgress }> = ({ value }) => {
  const { planchangerApi } = useApi()
  const [locator, setLocator] = useState<string | undefined>()
  const [getModelsArchive] = useModelsArchiveForTaskLazyQuery({
    onCompleted: data => {
      const locator = data?.modelsArchiveByTaskId?.file?.locator
      if (locator) {
        setLocator(locator)
      }
    },
  })
  const [getJobBackup] = useJobBackupForTaskLazyQuery({
    onCompleted: data => {
      const locator = data?.jobBackupByTaskId?.file?.locator
      if (locator) {
        setLocator(locator)
      }
    },
  })

  const taskId = value.id

  useEffect(() => {
    getModelsArchive({ variables: { taskId: taskId } })
    getJobBackup({ variables: { taskId: taskId } })
  }, [getModelsArchive, getJobBackup, taskId])

  const downloadArchive = (locator: string) => {
    planchangerApi.urlFor_getFile(locator).then(url => downloadFromUrl(url.toString()))
  }

  const handleDownloadClick = () => {
    console.log("hello " + locator)
    if (locator) {
      downloadArchive(locator)
    }
  }

  return (
    <>
      {locator && (
        <Tooltip2 content={"Download File From Task"} openOnTargetFocus={false}>
          <Button icon="download" minimal={true} onClick={handleDownloadClick} />
        </Tooltip2>
      )}
    </>
  )
}
