import React, { FC, useCallback, useEffect, useState } from "react"
import { FormGroup } from "@blueprintjs/core"

import {
  ParametricStock,
  ParametricStockShapeRectangularKindEnum,
  ParametricStockShapeRoundKindEnum,
} from "src/client-axios"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import {
  InputParametricStock,
  InputParametricStockShape,
  useUpdateInputStockMutation,
} from "src/graphql/generated"

import styles from "./InputStockLengthInput.module.css"

interface InputStockLengthInputProps {
  planId: string
  operationIdx: number
  inputStock: ParametricStock
  disabled?: boolean
  locked?: boolean
  className?: string
  formulaInputClassName?: string
  label: string
  field:
    | "x"
    | "y"
    | "cutLength"
    | "diameter"
    | "xMinTolerance"
    | "yMinTolerance"
    | "cutLengthMinTolerance"
    | "diameterMinTolerance"
    | "xMaxTolerance"
    | "yMaxTolerance"
    | "cutLengthMaxTolerance"
    | "diameterMaxTolerance"
}

export const InputStockLengthInput: FC<InputStockLengthInputProps> = ({
  planId,
  operationIdx,
  disabled,
  inputStock,
  locked,
  label,
  field,
  className,
  formulaInputClassName,
}) => {
  const transform = inputStock.transform
  const x =
    inputStock.shape.kind === ParametricStockShapeRectangularKindEnum.Rectangular
      ? inputStock.shape.x
      : 0
  const y =
    inputStock.shape.kind === ParametricStockShapeRectangularKindEnum.Rectangular
      ? inputStock.shape.y
      : 0
  const diameter =
    inputStock.shape.kind === ParametricStockShapeRoundKindEnum.Round
      ? inputStock.shape.diameter
      : 0
  const cutLength = inputStock.cutLength
  const xMinTolerance = inputStock.xMinTolerance
  const xMaxTolerance = inputStock.xMaxTolerance
  const yMinTolerance = inputStock.yMinTolerance
  const yMaxTolerance = inputStock.yMaxTolerance
  const cutLengthMinTolerance = inputStock.cutLengthMinTolerance
  const cutLengthMaxTolerance = inputStock.cutLengthMaxTolerance
  const diameterMinTolerance = inputStock.diameterMinTolerance
  const diameterMaxTolerance = inputStock.diameterMaxTolerance

  let selectedFieldTemporary
  switch (field) {
    case "x":
      selectedFieldTemporary = x
      break
    case "y":
      selectedFieldTemporary = y
      break
    case "cutLength":
      selectedFieldTemporary = cutLength
      break
    case "xMinTolerance":
      selectedFieldTemporary = xMinTolerance
      break
    case "yMinTolerance":
      selectedFieldTemporary = yMinTolerance
      break
    case "cutLengthMinTolerance":
      selectedFieldTemporary = cutLengthMinTolerance
      break
    case "diameterMinTolerance":
      selectedFieldTemporary = diameterMinTolerance
      break
    case "xMaxTolerance":
      selectedFieldTemporary = xMaxTolerance
      break
    case "yMaxTolerance":
      selectedFieldTemporary = yMaxTolerance
      break
    case "cutLengthMaxTolerance":
      selectedFieldTemporary = cutLengthMaxTolerance
      break
    case "diameterMaxTolerance":
      selectedFieldTemporary = diameterMaxTolerance
      break
    case "diameter":
      selectedFieldTemporary = diameter
      break
  }

  const selectedLength = selectedFieldTemporary

  const [currentValue, setCurrentValue] = useState(String(selectedLength))

  useEffect(() => {
    setCurrentValue(String(Number(selectedLength?.toFixed(4))))
  }, [selectedLength])

  const [updateInputStock] = useUpdateInputStockMutation()

  const onValueChange = useCallback(
    (valueAsNumber: number, valueAsString: string) => {
      setCurrentValue(valueAsString)

      if (!valueAsString) return
      if (Number.isNaN(valueAsNumber)) return

      let newStockShape: InputParametricStock
      switch (inputStock.shape.kind) {
        case ParametricStockShapeRectangularKindEnum.Rectangular:
          newStockShape = {
            kind: InputParametricStockShape.Rectangular,
            x: x,
            y: y,
            diameter: 0,
            materialId: inputStock.materialId ? inputStock.materialId : "",
            cutLength: cutLength,
            xMinTolerance,
            xMaxTolerance,
            yMinTolerance,
            yMaxTolerance,
            cutLengthMinTolerance,
            cutLengthMaxTolerance,
            diameterMinTolerance,
            diameterMaxTolerance,
            transform,
          }
          break
        case ParametricStockShapeRoundKindEnum.Round:
          newStockShape = {
            kind: InputParametricStockShape.Round,
            diameter: diameter,
            x: 0,
            y: 0,
            materialId: inputStock.materialId ? inputStock.materialId : "",
            cutLength: cutLength,
            xMinTolerance,
            xMaxTolerance,
            yMinTolerance,
            yMaxTolerance,
            cutLengthMinTolerance,
            cutLengthMaxTolerance,
            diameterMinTolerance,
            diameterMaxTolerance,
            transform,
          }
          break
      }

      switch (field) {
        case "x":
          newStockShape.x = valueAsNumber
          break
        case "y":
          newStockShape.y = valueAsNumber
          break
        case "cutLength":
          newStockShape.cutLength = valueAsNumber
          break
        case "xMinTolerance":
          newStockShape.xMinTolerance = valueAsNumber
          break
        case "yMinTolerance":
          newStockShape.yMinTolerance = valueAsNumber
          break
        case "cutLengthMinTolerance":
          newStockShape.cutLengthMinTolerance = valueAsNumber
          break
        case "diameterMinTolerance":
          newStockShape.diameterMinTolerance = valueAsNumber
          break
        case "xMaxTolerance":
          newStockShape.xMaxTolerance = valueAsNumber
          break
        case "yMaxTolerance":
          newStockShape.yMaxTolerance = valueAsNumber
          break
        case "cutLengthMaxTolerance":
          newStockShape.cutLengthMaxTolerance = valueAsNumber
          break
        case "diameterMaxTolerance":
          newStockShape.diameterMaxTolerance = valueAsNumber
          break
        case "diameter":
          newStockShape.diameter = valueAsNumber
          break
      }

      updateInputStock({
        variables: {
          planId,
          operationIdx,
          stock: {
            parametric: newStockShape,
          },
        },
        context: {
          debounceKey: "InputStockSlider",
        },
      })
    },
    [
      planId,
      updateInputStock,
      transform,
      operationIdx,
      field,
      cutLength,
      xMinTolerance,
      xMaxTolerance,
      yMinTolerance,
      yMaxTolerance,
      cutLengthMinTolerance,
      cutLengthMaxTolerance,
      diameterMinTolerance,
      diameterMaxTolerance,
      x,
      y,
      diameter,
      inputStock,
    ]
  )

  const validRange = ""

  const min = [
    "xMinTolerance",
    "yMinTolerance",
    "cutLengthMinTolerance",
    "diameterMinTolerance",
    "xMaxTolerance",
    "yMaxTolerance",
    "cutLengthMaxTolerance",
    "diameterMaxTolerance",
  ].includes(field)
    ? 0
    : 1.0

  return (
    <FormGroup
      // helperText="Helper text with details..."
      label={label}
      labelFor={`product-stock-length${field}`}
      className={className || ""}
      contentClassName={formulaInputClassName}
    >
      {!locked ? (
        <FormulaInput
          commitOnBlur={true}
          id={`product-stock-length${field}`}
          fill={true}
          placeholder={validRange}
          value={selectedLength}
          disabled={!inputStock || disabled}
          min={min}
          onValueChange={onValueChange}
          minorStepSize={0.1}
        />
      ) : (
        <div className={styles.input}>{isNaN(Number(currentValue)) ? 0 : currentValue}</div>
      )}
    </FormGroup>
  )
}
