import React, { FC, useEffect, useMemo, useState } from "react"
import { ResizeSensor } from "@blueprintjs/core"
import { v4 as uuidv4 } from "uuid"

import { useMainCanvas } from "src/hooks/transferCanvas/useMainCanvas"

import styles from "./TransferCanvas.module.css"
export const TransferCanvas: FC<{
  pauseRender?: boolean
  className?: string
  style?: React.CSSProperties
}> = ({ pauseRender, className, style, children }) => {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(null)
  const { props, resizeHandlers } = useMainCanvas()
  const canvasId = useMemo(() => uuidv4(), [])
  const onContextMenu: React.MouseEventHandler<HTMLElement> = (
    e: React.MouseEvent<HTMLElement> & Event
  ) => {
    e.preventDefault() // prevent opening of browser contextMenu
    props?.events.onContextMenu?.(e)
  }

  const { setComponents } = useMainCanvas()

  useEffect(() => {
    if (!canvasElement) return

    setComponents(components => [...components, { canvasId, pauseRender, children }])

    return () => {
      setComponents(components => components.filter(c => c.canvasId !== canvasId))
    }
  }, [children, canvasId, pauseRender, setComponents, canvasElement])

  if (!props?.events) return null
  const combinedStyle = { height: "100%", width: "100%", ...style }

  return (
    <ResizeSensor
      onResize={() => {
        const handlers = resizeHandlers.current[canvasId]
        if (handlers == null) return
        Object.values(handlers).forEach(handler => handler())
      }}
    >
      <canvas
        id={canvasId}
        ref={c => setCanvasElement(c)}
        style={combinedStyle}
        className={`${styles.unselectable} ${className ?? ""}`}
        {...props?.events}
        onContextMenu={onContextMenu}
      />
    </ResizeSensor>
  )
}
