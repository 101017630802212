import { createAction, createReducer } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

import { RootState } from "src/store/rootStore"
import { activeActions } from "./active"

const appendUndoRevision = createAction<number>("revision/appendUndoRevision")
const updateIndex = createAction<number>("revision/updateIndex")
const pruneHistory = createAction("revision/pruneHistory")

export const revisionActions = {
  appendUndoRevision,
  updateIndex,
  pruneHistory,
}

interface RevisionStore {
  undoToken?: string
  undoHistory: number[] // Revision numbers that you can undo/redo
  currentIndex?: number
}

const initialState: RevisionStore = {
  undoHistory: [],
}

export const revisionReducer = createReducer(initialState, builder => {
  builder.addCase(activeActions.setActivePlanId, (state, _action) => {
    state.undoHistory = []
    state.currentIndex = undefined
    state.undoToken = uuidv4()
  })
  builder.addCase(appendUndoRevision, (state, action) => {
    state.undoHistory.push(action.payload)
  })
  builder.addCase(updateIndex, (state, action) => {
    state.currentIndex = action.payload
  })
  builder.addCase(pruneHistory, (state, _action) => {
    console.log(state.undoHistory.slice(0, (state.currentIndex ?? 0) + 1))
    state.undoHistory = state.undoHistory.slice(0, (state.currentIndex ?? 0) + 1)
    state.currentIndex = undefined
  })
})

const selectUndoToken = (state: RootState): string | undefined => state.revision.undoToken
const selectUndoHistory = (state: RootState): number[] => state.revision.undoHistory
const selectCurrentIndex = (state: RootState): number | undefined => state.revision.currentIndex

export const revisionSelectors = {
  selectUndoToken,
  selectUndoHistory,
  selectCurrentIndex,
}
