import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { a, to, useSpring, useTransition } from "@react-spring/three"
import * as THREE from "three"

import { Operation, PlacedBodyRecord } from "src/client-axios"
import { AnimatedMovementNode } from "src/components/Canvas/Viewer/Scene/AnimatedMovementNode"
import { AnimatedPartialTransformNode } from "src/components/Canvas/Viewer/Scene/AnimatedPartialTransformNode"
import { CUSTOM_MODEL, FIXTURE_MATERIAL } from "src/components/Canvas/Viewer/Scene/Cam/materials"
import { GltfIdModel } from "src/components/Canvas/Viewer/SceneItems/GltfModelUtils"
import { FixtureChoice, FixtureChoiceElement } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { activeSelectors } from "src/store/cam/active"
import { fixturesSelectors } from "src/store/config/fixtures"
import { RootState } from "src/store/rootStore"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { MaybeFluidValue, mergeValuesSum } from "src/util/animation/mergeValues"
import { TRANSITION_CONFIG, TRANSITION_FIXTURE_OPENING } from "src/util/animation/springConfig"

interface FixtureBodyProps {
  body: PlacedBodyRecord
  deltaParameter?: number
  opening?: MaybeFluidValue<number>
  opacity?: MaybeFluidValue<number>
  visible?: boolean
  hovered?: boolean
  selected?: boolean
}

export const FixtureBody: FC<FixtureBodyProps> = ({
  body,
  deltaParameter: targetDeltaParameter,
  opening,
  opacity,
  visible,
  hovered,
  selected,
}) => {
  const { transferInvalidate } = useTransferInvalidate()

  const configMaterial =
    useSelector((state: RootState) =>
      fixturesSelectors.selectMaterial(state, body.material ?? "default")
    ) ?? FIXTURE_MATERIAL

  const color =
    typeof configMaterial.color === "string"
      ? selected
        ? new THREE.Color("#0000FF")
        : hovered
        ? new THREE.Color("#FF0000")
        : new THREE.Color(configMaterial.color)
      : FIXTURE_MATERIAL.color

  const material = { ...FIXTURE_MATERIAL, ...configMaterial, color }

  // Seems to actually look better without transparency set
  material.depthWrite = !!(configMaterial.opacity && configMaterial.opacity < 1)
  material.transparent = !!(configMaterial.opacity && configMaterial.opacity < 1)

  const [{ deltaParameter }] = useSpring(
    {
      to: { deltaParameter: targetDeltaParameter },
      config: TRANSITION_CONFIG,
      onChange: () => transferInvalidate(),
    },
    [targetDeltaParameter, transferInvalidate]
  )

  if (!body.model) {
    return <></>
  }

  const deltaParameterWithOpening = mergeValuesSum(deltaParameter, opening)

  return (
    <AnimatedMovementNode deltaParameter={deltaParameterWithOpening} movement={body.movement}>
      <AnimatedPartialTransformNode transform={body.transform}>
        <GltfIdModel
          userData={{ isFixture: true }}
          modelId={body.model}
          material={material}
          opacity={opacity}
          visible={visible}
        />
      </AnimatedPartialTransformNode>
    </AnimatedMovementNode>
  )
}

interface CustomModelBodyProps {
  treeNodeId?: string
  modelId: string
  opacity?: MaybeFluidValue<number>
  visible?: boolean
}

export const CustomModelBody: FC<CustomModelBodyProps> = ({
  modelId,
  opacity,
  visible,
  treeNodeId,
}) => {
  return (
    <GltfIdModel
      userData={{ isFixture: true, treeNodeId }}
      modelId={modelId}
      material={CUSTOM_MODEL}
      opacity={opacity}
      visible={visible}
      // showSharpEdges={true}
    />
  )
}

interface FixtureSceneProps {
  elements: FixtureChoiceElement[]
  elemId: string
  parentDeltaParameter?: number
  offset?: MaybeFluidValue<[number, number, number]>
  opening: MaybeFluidValue<number>
  opacity: MaybeFluidValue<number>
  minimal?: boolean
}

export const FixtureScene: FC<FixtureSceneProps> = ({
  elements,
  elemId,
  offset,
  opening,
  opacity,
  minimal,
}) => {
  const { transferInvalidate } = useTransferInvalidate()
  // Rerender the scene any time this component rerenders
  // Using a settimeout makes this work more reliably, but would ideally not be necessary
  setTimeout(() => transferInvalidate(), 100)

  const elem = elements.find(elem => elem.id === elemId)
  const showAuxiliary = !minimal
  const config = useSelector((state: RootState) =>
    fixturesSelectors.selectFixtureRecord(state, elem?.fixture)
  )
  const hoveredFixtureElemId = useSelector(activeSelectors.selectHoveredFixtureElemId)
  const selectedFixtureElemId = useSelector(activeSelectors.selectSelectedFixtureElemId)

  const bodies = useMemo(() => {
    let bodies = config?.bodies && Object.entries(config.bodies)

    if (elem?.customModelId) {
      bodies = bodies?.filter(([_name, body]) => !body.isCustomMillable) || []
    }
    return bodies
  }, [config, elem])

  if (!elem) {
    console.error(`Could not find element with id '${elemId}' in tree`)
    return <></>
  }

  const deltaParameter =
    elem.selectedParameter == null ? 0 : elem.selectedParameter - (config?.parameter?.initial ?? 0)

  const selfVisible = showAuxiliary || !config?.isAuxiliary
  const elemTransform =
    config?.isTransformAllowed || elem.customModelId ? elem.transform : undefined
  return (
    <AnimatedPartialTransformNode transform={elemTransform ?? undefined}>
      <a.group position={(offset as unknown) as [x: number, y: number, z: number]}>
        {elem.customModelId && (
          <CustomModelBody
            modelId={elem.customModelId}
            opacity={opacity}
            visible={selfVisible}
            treeNodeId={elem.id}
          />
        )}
        {bodies?.map(([name, body]) => {
          return (
            <FixtureBody
              key={name}
              body={body}
              deltaParameter={deltaParameter}
              opacity={opacity}
              opening={opening}
              visible={selfVisible}
              hovered={elemId === hoveredFixtureElemId}
              selected={elemId === selectedFixtureElemId}
            />
          )
        })}

        {elem.children.map(childAttachment => {
          const childElement = elements.find(elem => elem.id === childAttachment.element)

          if (!childElement) {
            console.error(`Couldn't find child element ${childAttachment.element}`)
            return undefined
          }

          const attachmentConfig = config?.points?.[childAttachment.identifier]
          const transform = attachmentConfig?.transform

          return (
            <AnimatedPartialTransformNode key={childAttachment.element} transform={transform}>
              <FixtureScene
                parentDeltaParameter={deltaParameter}
                opening={opening}
                opacity={opacity}
                elements={elements}
                minimal={minimal}
                elemId={childAttachment.element}
              />
            </AnimatedPartialTransformNode>
          )
        })}
      </a.group>
    </AnimatedPartialTransformNode>
  )
}

interface FixtureChoiceSceneProps {
  fixture: FixtureChoice
  opening: MaybeFluidValue<number>
  opacity: MaybeFluidValue<number>
  position?: [number, number, number]
  rotation?: [number, number, number]
  grid?: boolean
  offset?: MaybeFluidValue<[number, number, number]>
  minimal?: boolean
}

export const FixtureChoiceScene: FC<FixtureChoiceSceneProps> = ({
  fixture,
  offset,
  opening,
  opacity,
  position: targetPosition,
  rotation: targetRotation,
  minimal,
}) => {
  const { transferInvalidate } = useTransferInvalidate()

  const [{ position, rotation }] = useSpring(
    {
      to: {
        position: targetPosition ?? [0, 0, 0],
        rotation: targetRotation ?? [0, 0, 0],
      },
      config: TRANSITION_CONFIG,
      onChange: () => transferInvalidate(),
    },
    [targetPosition, targetRotation, transferInvalidate]
  )

  return (
    <a.group
      position={(position as unknown) as [x: number, y: number, z: number]}
      rotation={(rotation as unknown) as [x: number, y: number, z: number]}
    >
      <FixtureScene
        elements={fixture.elements}
        elemId={fixture.rootElementId}
        offset={offset}
        opening={opening}
        opacity={opacity}
        minimal={minimal}
      />
    </a.group>
  )
}

interface FixtureChoicesSceneProps {
  operation?: Operation
  opening?: MaybeFluidValue<number>
  opacity?: MaybeFluidValue<number>
  offset?: MaybeFluidValue<[number, number, number]>
  minimal?: boolean
}

export const FixtureChoicesScene: FC<FixtureChoicesSceneProps> = ({
  operation,
  opening = 0,
  opacity: _opacityOverride = 1,
  offset,
  minimal,
}) => {
  const { transferInvalidate } = useTransferInvalidate()
  const fixturesConfig = useSelector(fixturesSelectors.selectFixturesConfig)
  const displayMode = useSelector(viewOptionsSelectors.fixturesDisplayMode)

  const visible = displayMode !== DisplayMode.Hidden

  const transparencyFactor: MaybeFluidValue<number> =
    displayMode === DisplayMode.Transparent ? 0.5 : 1.0

  // See TODO: note below about why this is not used due to breaking display on first load
  // let displayOpacityOverride: MaybeFluidValue<number>
  // if (typeof opacityOverride === "number") {
  //   displayOpacityOverride = opacityOverride * transparencyFactor
  // } else {
  //   displayOpacityOverride = to([opacityOverride], o1 => o1 * transparencyFactor)
  // }

  const transitions = useTransition(operation ? [operation] : [null], {
    key: (item: Operation | null) => item?.id || "null",
    from: { opacity: 0, opening: TRANSITION_FIXTURE_OPENING },
    enter: () => ({
      to: async next => {
        await next({ opacity: 1 })
        await next({ opening: 0 })
      },
      delay: 500,
    }),
    leave: () => async next => {
      await next({ opening: TRANSITION_FIXTURE_OPENING })
      await next({ opacity: 0 })
    },
    onChange: () => transferInvalidate(),
    config: TRANSITION_CONFIG,
  })

  transferInvalidate()
  if (!fixturesConfig || !visible) return null

  // Rerender the scene any time this component rerenders
  // Using a settimeout makes this work more reliably, but would ideally not be necessary
  setTimeout(() => transferInvalidate(), 100)

  return (
    <group visible={visible}>
      {transitions((values, item) => {
        const fixtures = item?.fixtures
        if (!fixtures) return null

        // TODO: Figure out why we can't use the displayOpacityOverride without breaking first load
        //  (If you use this instead of values.opacity below, the fixtures will not appear when
        //  they are first created for an operation)
        // const opacity =
        //   typeof displayOpacityOverride === "number"
        //     ? displayOpacityOverride
        //     : to([values.opacity, displayOpacityOverride], (o1, o2) => Math.min(o1, o2))
        const opacity = to([values.opacity], o => o * transparencyFactor)

        return (
          <FixtureChoiceScene
            fixture={fixtures}
            opening={opening}
            opacity={opacity}
            offset={offset}
            minimal={minimal}
          />
        )
      })}
    </group>
  )
}
