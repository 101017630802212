import { createAsyncThunk, createEntityAdapter, createSlice, Dictionary } from "@reduxjs/toolkit"

import { ConfigApi, MachineRecord } from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { activeActions } from "../cam/active"

/**
 * Adapters
 */
const machinesAdapter = createEntityAdapter<MachineRecord>({
  selectId: machine => machine.kind,
})

/**
 * Slices
 */
const machinesSlice = createSlice({
  name: "MACHINES",
  initialState: machinesAdapter.getInitialState(),
  reducers: {
    addMany: machinesAdapter.addMany,
  },
})

/**
 * Reducers + actions
 */
export const { reducer: machinesConfigReducer } = machinesSlice
const { actions: machinesActions } = machinesSlice

/**
 * Selectors
 */
const machinesAdapterSelectors = machinesAdapter.getSelectors<RootState>(
  state => state.config.machinesConfig
)

export const selectMachineRecord = (state: RootState, id?: string): MachineRecord | undefined =>
  id === undefined ? undefined : machinesAdapterSelectors.selectById(state, id)

const selectMachineRecords = (state: RootState): MachineRecord[] =>
  machinesAdapterSelectors.selectAll(state).slice()

const selectMachineRecordsMap = (state: RootState): Dictionary<MachineRecord> =>
  machinesAdapterSelectors.selectEntities(state)

export const machinesSelectors = {
  selectMachineRecord,
  selectMachineRecords,
  selectMachineRecordsMap,
}
/**
 * Thunks
 */
const fetchMachinesConfig = createAsyncThunk(
  "config/fixtures/fetchFixturesConfig",
  async ({ configApi }: { configApi: ConfigApi }, thunkAPI) => {
    try {
      const machinesConfig = (await configApi.getMachinesConfig()).data
      thunkAPI.dispatch(machinesActions.addMany(machinesConfig.machines))
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch machines config: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const machinesThunks = {
  fetchMachinesConfig,
}
