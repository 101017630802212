import { Dimension } from "src/client-axios"
import { DimTolType, DimUnits } from "src/pages/DrawingViewer/interfaces"

export const getDatums = (dims: Dimension[]): string[] => {
  return Array.from(
    new Set(
      dims
        .map(dim => {
          const datums: string[] = []

          if (dim.dimDatum1 !== undefined && isNaN(+dim.dimDatum1)) {
            datums.push(dim.dimDatum1)
          }

          if (dim.dimDatum2 !== undefined && isNaN(+dim.dimDatum2)) {
            datums.push(dim.dimDatum2)
          }

          if (dim.dimDatum3 !== undefined && isNaN(+dim.dimDatum3)) {
            datums.push(dim.dimDatum3)
          }

          return datums
        })
        .flat()
    )
  )
}

export const hasDatum = (dims: Dimension[]): boolean => {
  return getDatums(dims).length > 0
}

export const getToleranceBandSize = (dims: Dimension[]): number => {
  return Math.min(
    ...dims.map(dim => {
      const dimUpperTol = dim.dimUpperTol ?? 0
      const dimLowerTol = dim.dimLowerTol ?? 0
      let bandSize = dimUpperTol - dimLowerTol
      if (dim.dimUnits === DimUnits.imperial) {
        bandSize *= 25.4
      }
      return bandSize
    })
  )
}

export const isBasic = (dims: Dimension[]): boolean => {
  return dims.every(dim => dim.dimTolType === DimTolType.basic)
}

export const isReference = (dims: Dimension[]): boolean => {
  return dims.every(dim => dim.dimTolType === DimTolType.reference)
}

export const dimLabel = (dims: Dimension[]): string => {
  if (dims.length === 1) {
    return dims[0].dimCode ?? ""
  }

  const labelSet = new Set()

  dims.forEach(val => {
    const dimCode = val.dimCode ?? ""
    labelSet.add(dimCode.split(".")[0])
  })

  return Array.from(labelSet.values()).join(",")
}
