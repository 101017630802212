import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Vector3 } from "three"

import { AppModelsGeometryTransform as Transform } from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { CylinderData } from "src/util/geometry/cylinder"

export const DEFAULT_SCENE_SIZE = 500

export type MoveShiftClickMode = "mate" | "translate" | "inspect"
export type CoordinateSystem = "part" | "machine"
export type PointCoordinateSystem = "part" | "machine" | "g54"

export enum DisplayStock {
  Input = "Input",
  Output = "Output",
}

export enum DisplayMode {
  Visible = "Visible",
  Transparent = "Transparent",
  Hidden = "Hidden",
  Focused = "Focused",
}

// Lowercase to match the `TransformControls` property
export enum TransformType {
  Translate = "translate",
  Rotate = "rotate",
}

export enum MoveSelectionType {
  STOCK = "STOCK",
  PART = "PART",
  BOTH = "BOTH",
}

export interface SelectedPoint {
  point: [number, number, number]
  canvasId: string
  faceNormal?: Vector3
  isPart: boolean
  isStock: boolean
  cylinder?: CylinderData
}

interface ViewOptions {
  coordinates: CoordinateSystem
  pointCoordinates: PointCoordinateSystem

  partDisplayMode: DisplayMode
  stockDisplayMode: DisplayMode
  fixturesDisplayMode: DisplayMode
  cuttingSimDisplayMode: DisplayMode
  issuesDisplayMode: DisplayMode
  machineCoordsDisplayMode: DisplayMode
  probingDisplayMode: DisplayMode
  addIssuesDialogDisplayMode: DisplayMode
  overtravelLimitsDisplayMode: DisplayMode
  machineDisplayMode: string

  stockToDisplay: DisplayStock

  isDragging: boolean
  transformType: TransformType
  moveShiftClickMode: MoveShiftClickMode
  moveSelectionEnabled: boolean
  moveSelectionType?: MoveSelectionType
  movementTransform?: Transform

  disableRtcp: boolean
  showGrid: boolean
  showWcs: boolean
  axisMovementChanged: string | undefined

  selectedPoint1?: SelectedPoint
  selectedPoint2?: SelectedPoint
  hoverSnapPoint1?: SelectedPoint
  selectedGcodeLines: number[]
  selectedProbeTempPoint?: SelectedPoint

  operationLockedState: Record<string, boolean>

  controlPlanLocked: boolean
}

const initialState: ViewOptions = {
  coordinates: "machine",

  pointCoordinates: "g54",

  partDisplayMode: DisplayMode.Visible,
  stockDisplayMode: DisplayMode.Transparent,
  fixturesDisplayMode: DisplayMode.Visible,
  cuttingSimDisplayMode: DisplayMode.Hidden,
  issuesDisplayMode: DisplayMode.Hidden,
  machineCoordsDisplayMode: DisplayMode.Hidden,
  probingDisplayMode: DisplayMode.Visible,
  addIssuesDialogDisplayMode: DisplayMode.Hidden,
  overtravelLimitsDisplayMode: DisplayMode.Hidden,
  machineDisplayMode: DisplayMode.Hidden,

  stockToDisplay: DisplayStock.Input,
  isDragging: false,
  transformType: TransformType.Translate,
  moveSelectionEnabled: false,
  moveSelectionType: MoveSelectionType.BOTH,
  moveShiftClickMode: "mate",

  disableRtcp: false,
  showGrid: false,
  showWcs: true,
  axisMovementChanged: undefined,
  selectedGcodeLines: [],
  operationLockedState: {},
  controlPlanLocked: true,
}

const viewOptionsSlice = createSlice({
  name: "VIEW_OPTIONS",
  initialState,

  reducers: {
    toggleCoordinates: state => ({
      ...state,
      selectedPoint1: undefined,
      coordinates: state.coordinates === "machine" ? "part" : "machine",
      pointCoordinates: state.coordinates === "machine" ? "part" : "machine",
    }),

    togglePointCoordinates: state => ({
      ...state,
      pointCoordinates: state.pointCoordinates === "machine" ? "g54" : "machine",
    }),

    cyclePartDisplayMode: state => ({
      ...state,
      partDisplayMode: cycleDisplayMode(state.partDisplayMode),
    }),
    cycleStockDisplayMode: state => ({
      ...state,
      stockDisplayMode: cycleDisplayMode(state.stockDisplayMode),
    }),
    cycleOvertravelDisplayMode: state => ({
      ...state,
      overtravelLimitsDisplayMode: cycleDisplayMode(state.overtravelLimitsDisplayMode),
    }),
    cycleFixturesDisplayMode: state => ({
      ...state,
      fixturesDisplayMode: cycleDisplayMode(state.fixturesDisplayMode),
    }),
    cycleStockToDisplay: state => ({
      ...state,
      stockToDisplay: cycleDisplayStock(state.stockToDisplay),
    }),
    cycleCuttingSimDisplay: state => ({
      ...state,
      cuttingSimDisplayMode:
        state.cuttingSimDisplayMode === DisplayMode.Visible
          ? DisplayMode.Hidden
          : DisplayMode.Visible,
    }),
    showCuttingSimDisplay: state => ({
      ...state,
      cuttingSimDisplayMode: DisplayMode.Visible,
    }),
    hideCuttingSimDisplay: state => ({
      ...state,
      cuttingSimDisplayMode: DisplayMode.Hidden,
    }),
    cycleIssuesDisplay: state => ({
      ...state,
      issuesDisplayMode:
        state.issuesDisplayMode === DisplayMode.Visible ? DisplayMode.Hidden : DisplayMode.Visible,
    }),
    cycleMachineCoordsDisplay: state => ({
      ...state,
      machineCoordsDisplayMode:
        state.machineCoordsDisplayMode === DisplayMode.Visible
          ? DisplayMode.Hidden
          : DisplayMode.Visible,
    }),
    cycleProbingDisplay: state => ({
      ...state,
      probingDisplayMode: cycleProbingDisplayMode(state.probingDisplayMode),
    }),
    toggleProbingDisplayFocused: state => ({
      ...state,
      probingDisplayMode:
        state.probingDisplayMode === DisplayMode.Focused
          ? DisplayMode.Visible
          : DisplayMode.Focused,
    }),
    showIssuesPanel: state => ({
      ...state,
      issuesDisplayMode: DisplayMode.Visible,
    }),
    showAddIssuesDialog: state => ({
      ...state,
      addIssuesDialogDisplayMode: DisplayMode.Visible,
    }),
    hideAddIssuesDialog: state => ({
      ...state,
      addIssuesDialogDisplayMode: DisplayMode.Hidden,
    }),

    toggleShowGrid: state => ({ ...state, showGrid: !state.showGrid }),
    toggleShowWcs: state => ({ ...state, showWcs: !state.showWcs }),

    setAxisMovementChanged: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      axisMovementChanged: action.payload,
    }),

    setSelectedPoint1: (state, action: PayloadAction<SelectedPoint | undefined>) => ({
      ...state,
      selectedPoint1: action.payload,
    }),

    setSelectedPoint2: (state, action: PayloadAction<SelectedPoint | undefined>) => ({
      ...state,
      selectedPoint2: action.payload,
    }),

    setHoverSnapPoint1: (state, action: PayloadAction<SelectedPoint | undefined>) => ({
      ...state,
      hoverSnapPoint1: action.payload,
    }),

    setSelectedProbeTempPoint: (state, action: PayloadAction<SelectedPoint | undefined>) => ({
      ...state,
      selectedProbeTempPoint: action.payload,
    }),

    setSelectedGcodeLines: (state, action: PayloadAction<number[]>) => ({
      ...state,
      selectedGcodeLines: action.payload,
    }),

    setOperationLocked: (state, action: PayloadAction<string>) => ({
      ...state,
      operationLockedState: { ...state.operationLockedState, [action.payload]: true },
    }),
    setOperationUnlocked: (state, action: PayloadAction<string>) => ({
      ...state,
      operationLockedState: { ...state.operationLockedState, [action.payload]: false },
    }),
    toggleDragging: state => ({
      ...state,
      isDragging: !state.isDragging,
    }),
    toggleTransform: state => ({
      ...state,
      transformType:
        state.transformType === TransformType.Rotate
          ? TransformType.Translate
          : TransformType.Rotate,
    }),
    setTransformType: (state, action: PayloadAction<TransformType>) => ({
      ...state,
      transformType: action.payload,
    }),
    setMoveSelectionEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      moveSelectionEnabled: action.payload,
    }),
    setMoveSelectionType: (state, action: PayloadAction<MoveSelectionType | undefined>) => ({
      ...state,
      moveSelectionType: action.payload,
    }),
    setMoveShiftClickMode: (state, action: PayloadAction<MoveShiftClickMode>) => ({
      ...state,
      moveShiftClickMode: action.payload,
    }),
    setMovementTransform: (state, action: PayloadAction<Transform | undefined>) => ({
      ...state,
      movementTransform: action.payload,
    }),
    setMachineDisplayMode: (state, action: PayloadAction<string>) => ({
      ...state,
      machineDisplayMode: action.payload,
    }),
    setDisableRtcp: (state, action: PayloadAction<boolean>) => ({
      ...state,
      disableRtcp: action.payload,
    }),
    toggleControlPlanLock: state => ({
      ...state,
      controlPlanLocked: !state.controlPlanLocked,
    }),
  },
})

export const { actions: viewOptionsActions, reducer: viewOptionsReducer } = viewOptionsSlice

// TODO: I suspect there is a way to get this without so much boilerplate
export const viewOptionsSelectors = {
  all: (state: RootState): ViewOptions => state.viewOptions,
  coordinates: (state: RootState): CoordinateSystem => state.viewOptions.coordinates,
  pointCoordinates: (state: RootState): PointCoordinateSystem => state.viewOptions.pointCoordinates,

  partDisplayMode: (state: RootState): DisplayMode => state.viewOptions.partDisplayMode,
  stockDisplayMode: (state: RootState): DisplayMode => state.viewOptions.stockDisplayMode,
  fixturesDisplayMode: (state: RootState): DisplayMode => state.viewOptions.fixturesDisplayMode,
  cuttingSimDisplayMode: (state: RootState): DisplayMode => state.viewOptions.cuttingSimDisplayMode,
  issuesDisplayMode: (state: RootState): DisplayMode => state.viewOptions.issuesDisplayMode,
  machineCoordsDisplayMode: (state: RootState): DisplayMode =>
    state.viewOptions.machineCoordsDisplayMode,
  probingDisplayMode: (state: RootState): DisplayMode => state.viewOptions.probingDisplayMode,
  addIssuesDialogDisplayMode: (state: RootState): DisplayMode =>
    state.viewOptions.addIssuesDialogDisplayMode,
  overtravelLimitsDisplayMode: (state: RootState): DisplayMode =>
    state.viewOptions.overtravelLimitsDisplayMode,
  machineDisplayMode: (state: RootState): string => state.viewOptions.machineDisplayMode,
  stockToDisplay: (state: RootState): DisplayStock => state.viewOptions.stockToDisplay,

  showGrid: (state: RootState): boolean => state.viewOptions.showGrid,
  showWcs: (state: RootState): boolean => state.viewOptions.showWcs,
  axisMovementChanged: (state: RootState): string | undefined =>
    state.viewOptions.axisMovementChanged,

  selectedPoint1: (state: RootState): SelectedPoint | undefined => state.viewOptions.selectedPoint1,
  selectedPoint2: (state: RootState): SelectedPoint | undefined => state.viewOptions.selectedPoint2,
  hoverSnapPoint1: (state: RootState): SelectedPoint | undefined =>
    state.viewOptions.hoverSnapPoint1,
  selectedProbeTempPoint: (state: RootState): SelectedPoint | undefined =>
    state.viewOptions.selectedProbeTempPoint,

  selectedGcodeLines: (state: RootState): number[] => state.viewOptions.selectedGcodeLines,
  operationLocked: (state: RootState, operationId?: string): boolean => {
    if (!operationId) {
      return true
    }
    return state.viewOptions.operationLockedState[operationId] ?? true
  },
  isDragging: (state: RootState): boolean => state.viewOptions.isDragging,
  transformType: (state: RootState): TransformType => state.viewOptions.transformType,
  moveSelectionEnabled: (state: RootState): boolean => state.viewOptions.moveSelectionEnabled,
  moveSelectionType: (state: RootState): MoveSelectionType | undefined =>
    state.viewOptions.moveSelectionType,
  moveShiftClickMode: (state: RootState): MoveShiftClickMode =>
    state.viewOptions.moveShiftClickMode,
  movementTransform: (state: RootState): Transform | undefined =>
    state.viewOptions.movementTransform,
  disableRtcp: (state: RootState): boolean => state.viewOptions.disableRtcp,
  controlPlanLocked: (state: RootState): boolean => state.viewOptions.controlPlanLocked,
}

const cycleDisplayMode = (displayMode: DisplayMode): DisplayMode => {
  switch (displayMode) {
    case DisplayMode.Transparent:
      return DisplayMode.Hidden
    case DisplayMode.Hidden:
      return DisplayMode.Visible
    case DisplayMode.Visible:
      return DisplayMode.Transparent
    default:
      return DisplayMode.Visible
  }
}
const cycleProbingDisplayMode = (displayMode: DisplayMode): DisplayMode => {
  switch (displayMode) {
    case DisplayMode.Focused:
      return DisplayMode.Hidden
    case DisplayMode.Hidden:
      return DisplayMode.Visible
    case DisplayMode.Visible:
      return DisplayMode.Focused
    default:
      return DisplayMode.Visible
  }
}

export const cycleDisplayMachineMode = (displayMode: string, action: string[]): string => {
  const newVisibilitiesIndex = (action.indexOf(displayMode) + 1) % action.length
  return action[newVisibilitiesIndex]
}

const cycleDisplayStock = (displayStock: DisplayStock): DisplayStock => {
  switch (displayStock) {
    case DisplayStock.Input:
      return DisplayStock.Output
    case DisplayStock.Output:
      return DisplayStock.Input
  }
}
