import { Context, createContext, useContext } from "react"

type OptionalContext<T> = Context<T | undefined>

export function createRequiredOptionalContext<T>(
  displayName: string
): [OptionalContext<T>, () => T] {
  const context = createContext<T | undefined>(undefined)
  context.displayName = displayName
  return [context, getUseRequiredOptionalContext(context)]
}

function getUseRequiredOptionalContext<T>(context: OptionalContext<T>): () => T {
  return () => {
    const value = useContext(context)
    if (value === undefined) {
      throw new Error(`${context.displayName} provider not present`)
    }
    return value
  }
}
