import { Color } from "pdf-lib"

import { Dimension } from "src/client-axios"

interface Point {
  x: number
  y: number
}

export interface BBox {
  topLeft: Point
  extents: Point
}

export interface DimBBox {
  bbox: BBox
  dim: Dimension
}

export interface ShapeData {
  shapeId: number // only for transient use within remoteshop
  label: string
  location: ShapeLocation
  dims: Dimension[]
  // parts: PlanSummary[]
  scoring: DimsScoring
  isBasic: boolean
  isReference: boolean
  hasDatum: boolean
}

export interface ShapesFilterOptions {
  pageIndex?: number
  includeBasicDims?: boolean
  includeReferenceDims?: boolean
}

export interface DimsDataOptions {
  drawingId?: string
  jobId?: string
  filters?: ShapesFilterOptions
}

export enum DimTolType {
  unspecified = 0,
  tolerance = 1,
  asLimit = 2,
  basic = 3,
  reference = 4,
  min = 5,
  max = 6,
}

export enum DimType {
  angular = 1,
  angularity = 2,
  bilateralX = 3,
  bilateralY = 4,
  bilateralZ = 5,
  circularity = 6,
  circularRunout = 7,
  concentricity = 8,
  coordinate = 9,
  csDistance = 10,
  cylindricity = 11,
  diameter = 12,
  flatness = 13,
  lineProfile = 14,
  linear = 15,
  width = 16,
  general = 17,
  square = 18,
  note = 19,
  parallelism = 20,
  perpendicularity = 21,
  point = 22,
  pointProfile = 23,
  polar = 24,
  radial = 25,
  straightness = 26,
  surfaceProfile = 27,
  symmetry = 28,
  totalRunout = 29,
  truePosition = 30,
  polarAngular = 31,
  polarRadial = 32,
  depth = 33,
  chamfer = 34,
  thread = 35,
  surfaceFinish = 36,
  welding = 37,
  counterbore = 38,
  countersink = 39,
  fastener = 40,
  flagNote = 41,
  customBilateral = 42,
  customPassFail = 43,
  sphericalDiameter = 44,
  sphericalRadius = 45,
  edge = 46,
  customUnilateral = 47,
  arcLength = 48,
  conicalTaper = 49,
  slope = 50,
  spotface = 51,
}

export enum DimDesignatorType {
  major = 3125,
  minor = 3126,
  key = 3123,
}

export enum DimToolType {
  coordinateMeasuringInstrument = 1,
  insideMeasuringInstrument = 3,
  contourTracer = 13,
  opticalComparator = 15,
  customMeasuringInstrument = 18,
  visual = 34,
  depthGage = 35,
  threadPlugGage = 31,
  threadGage = 1029,
  chamferGage = 1030,
  handheldScanner = 1036,
  caliper = 1003,
  profilometer = 22,
}

export enum DimSourceType {
  manual = 1,
  auto = 3,
}

export enum DimUnits {
  metric = 1,
  imperial = 2,
  degrees = 3,
  radians = 4,
}

export type ShapeLocation = { pageIndex: number } & BBox

export interface DimsData {
  drawingId?: string // UUID
  drawingFileId?: string // UUID
  drawingLocator?: string
  jobLabel?: string
  allShapes: ShapeData[]
  filteredShapes: ShapeData[]
  // partsData: PlanSummary[]
  loading: boolean
  refetch?: () => Promise<unknown>
}

export interface DimsScoring {
  difficultyGroup: DifficultyGroup
  safetyFactor: number
  toleranceBandSize: number
  hardestDimDescription: string
}

export enum DifficultyGroup {
  yellow = "yellow",
  blue = "blue",
  gray = "gray",
}

export interface BubbleAppearance {
  fill: { color: Color; bubbleOpacity: number; boxOpacity: number; hideBox?: boolean }
  textColor: Color
}

export interface Bubble {
  label: string
  location: ShapeLocation
  appearance: BubbleAppearance
  scoring: DimsScoring
}
