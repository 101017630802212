import * as THREE from "three"

export function scalarAttribute(values: number[]): THREE.BufferAttribute {
  const array = new Float32Array(values)
  return new THREE.BufferAttribute(array, 1)
}

export function vector2Attribute(values: number[]): THREE.BufferAttribute {
  const array = new Float32Array(values)
  return new THREE.BufferAttribute(array, 2)
}

export function vector3Attribute(values: number[]): THREE.BufferAttribute {
  const array = new Float32Array(values)
  return new THREE.BufferAttribute(array, 3)
}
