import { IToastProps, TreeNodeInfo } from "@blueprintjs/core"
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import { Box3 } from "three"
import { v4 as uuidv4 } from "uuid"

import {
  AlignmentKindEnum,
  AlignmentWcsKind,
  InspectionKindEnum,
  IssueAcknowledgement,
  PointsFeatureKindEnum,
  PointsFeatureMove,
  PointsFeaturePoint,
  ProbeRecord,
  ProbingIssue,
  WcsProbingKindEnum,
} from "src/client-axios"
import { ConfigPanelSelect } from "src/components/Cam/TreeNavigator/ConfigPanel/props"
import { Camjobstatus } from "src/graphql/generated"
import { RootState } from "src/store/rootStore"
import { ProbingSection, ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import { machinesSelectors } from "../config/machines"
import { probesSelectors } from "../config/probes"
import { storedOperationSelectors, storedPlansSelectors } from "./storedPlans"

export enum ClickHandlerType {
  BITE_PLACER = "BITE_PLACER",
  INSPECTOR = "INSPECTOR",
  MOVEMENT = "MOVEMENT",
}

interface CamOperationSceneInputs {
  planId: string
  operationIdx?: number
}

interface ActivePanelProps {
  nodes: TreeNodeInfo<ConfigPanelSelect>[]
}

const setActiveConfigTab = createAction<string | undefined>("active/setActiveConfigTab")
const setActiveConfigPanel = createAction<ConfigPanelSelect | undefined>(
  "active/setActiveConfigPanel"
)
const setActiveJobId = createAction<string | undefined>("active/setActiveJobId")
const setActiveJobLabel = createAction<string | undefined>("active/setActiveJobLabel")
const setActivePlanId = createAction<string | undefined>("active/setActivePlanId")
const setActiveOperationIdx = createAction<number | undefined>("active/setActiveOperationIdx")
const setActiveProbingSectionId = createAction<string | undefined>(
  "active/setActiveProbingSectionId"
)
const setActiveMachineOffsetId = createAction<string | undefined>("active/setActiveMachineOffsetId")
const setActiveProbeStepId = createAction<string | undefined>("active/setActiveProbeStepId")
const setActiveCopiedProbeSteps = createAction<
  (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[] | undefined
>("active/setActiveCopiedProbeStep")
const setMultipleSelectedProbeSteps = createAction<
  (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[] | undefined
>("active/setMultipleSelectedProbeSteps")
const setActivePanelProps = createAction<ActivePanelProps | undefined>("active/setActivePanelProps")
const setActiveToastMessage = createAction<IToastProps | undefined>("active/setActiveToastMessage")

const setHoveredFixtureElemId = createAction<string | undefined>("active/setHoveredFixtureElemId")
const setSelectedFixtureElemId = createAction<string | undefined>("active/setSelectedFixtureElemId")
const setActiveClickHandler = createAction<ClickHandlerType | undefined>(
  "active/setActiveClickHandler"
)

const setActiveRevision = createAction<number | undefined>("active/setActiveRevision")
const setActiveStageName = createAction<string | undefined>("active/setActiveStageName")
const setActiveFeedbackId = createAction<string | undefined>("active/setActiveFeedbackId")
const setActiveCamjobstatus = createAction<Camjobstatus | undefined>("active/setActiveCamjobstatus")

const cycleActiveClickHandler = createAction<undefined>("active/cycleActiveClickHandler")
const setActiveIssue = createAction<jira.IIssue | undefined>("active/setActiveIssue")
const setActiveEpicKey = createAction<string | undefined>("active/setActiveEpicKey")
const setActivePartBoundingBox = createAction<Box3 | undefined>("active/setActivePartBoundingBox")
const setActiveOpRuntime = createAction<{ opId: string; runtime: number }>(
  "active/setActiveOpRuntime"
)

interface ActiveStore {
  activeJobId: string | undefined
  activeJobLabel: string | undefined
  activePlanId: string | undefined
  activeOperationIdx: number | undefined
  activeProbingSectionId: string | undefined
  activeMachineOffsetId: string | undefined
  activeProbeStepId: string | undefined
  activeCopiedProbeSteps:
    | (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[]
    | undefined

  multipleSelectedProbeSteps:
    | (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[]
    | undefined

  activeCopiedWcsProbeStep: WcsProbingStep | undefined

  activeConfigTab: string | undefined
  activeConfigPanel: ConfigPanelSelect | undefined
  activeClickHandler: ClickHandlerType | undefined
  activePanelProps?: ActivePanelProps | undefined
  activeToastMessage?: IToastProps | undefined

  activeRevision?: number | undefined

  activeStageName?: string | undefined
  activeFeedbackId?: string | undefined
  activeCamjobstatus?: Camjobstatus | undefined
  hoveredFixtureElemId: string | undefined
  selectedFixtureElemId: string | undefined
  activeIssue: jira.IIssue | undefined
  activeEpicKey: string | undefined
  activePartBoundingBox: Box3 | undefined

  changeToken: string

  opRuntimes: Record<string, number> | undefined
}

export const activeReducer = createReducer<ActiveStore>(
  {
    activeConfigTab: "production",
    activeConfigPanel: undefined,
    activeJobId: undefined,
    activeJobLabel: undefined,
    activePlanId: undefined,
    activeOperationIdx: undefined,
    activeProbingSectionId: undefined,
    activeProbeStepId: undefined,
    activeMachineOffsetId: undefined,
    activeCopiedProbeSteps: undefined,
    multipleSelectedProbeSteps: undefined,
    activeCopiedWcsProbeStep: undefined,
    hoveredFixtureElemId: undefined,
    selectedFixtureElemId: undefined,
    changeToken: uuidv4(),
    activeClickHandler: ClickHandlerType.INSPECTOR,
    activeIssue: undefined,
    activeEpicKey: undefined,
    activePartBoundingBox: undefined,
    opRuntimes: undefined,
  },
  builder => {
    builder.addCase(setActiveConfigTab, (state, action) => {
      state.activeConfigTab = action.payload
    })
    builder.addCase(setActiveConfigPanel, (state, action) => {
      state.activeConfigPanel = action.payload
    })
    builder.addCase(setActiveJobId, (state, action) => {
      state.activeJobId = action.payload
    })
    builder.addCase(setActiveJobLabel, (state, action) => {
      state.activeJobLabel = action.payload
    })
    builder.addCase(setActivePlanId, (state, action) => {
      state.activePlanId = action.payload
    })
    builder.addCase(setActiveOperationIdx, (state, action) => {
      state.activeOperationIdx = action.payload
    })
    builder.addCase(setActiveProbingSectionId, (state, action) => {
      state.activeProbingSectionId = action.payload
    })
    builder.addCase(setActiveMachineOffsetId, (state, action) => {
      state.activeMachineOffsetId = action.payload
    })
    builder.addCase(setActiveProbeStepId, (state, action) => {
      state.activeProbeStepId = action.payload
    })
    builder.addCase(setActiveCopiedProbeSteps, (state, action) => {
      state.activeCopiedProbeSteps = action.payload
    })
    builder.addCase(setMultipleSelectedProbeSteps, (state, action) => {
      state.multipleSelectedProbeSteps = action.payload
    })
    builder.addCase(setHoveredFixtureElemId, (state, action) => {
      state.hoveredFixtureElemId = action.payload
    })
    builder.addCase(setSelectedFixtureElemId, (state, action) => {
      state.selectedFixtureElemId = action.payload
    })
    builder.addCase(setActiveClickHandler, (state, action) => {
      state.activeClickHandler = action.payload
    })
    builder.addCase(setActivePanelProps, (state, action) => {
      state.activePanelProps = action.payload
    })
    builder.addCase(setActiveToastMessage, (state, action) => {
      state.activeToastMessage = action.payload
    })
    builder.addCase(setActiveRevision, (state, action) => {
      state.activeRevision = action.payload
    })
    builder.addCase(setActiveStageName, (state, action) => {
      state.activeStageName = action.payload
    })
    builder.addCase(setActiveFeedbackId, (state, action) => {
      state.activeFeedbackId = action.payload
    })
    builder.addCase(setActiveCamjobstatus, (state, action) => {
      state.activeCamjobstatus = action.payload
    })
    builder.addCase(cycleActiveClickHandler, state => {
      if (state.activeClickHandler === ClickHandlerType.INSPECTOR) {
        state.activeClickHandler = ClickHandlerType.MOVEMENT
      } else if (state.activeClickHandler === ClickHandlerType.MOVEMENT) {
        state.activeClickHandler = ClickHandlerType.INSPECTOR
      }
    })
    builder.addCase(setActiveIssue, (state, action) => {
      state.activeIssue = action.payload
    })
    builder.addCase(setActiveEpicKey, (state, action) => {
      state.activeEpicKey = action.payload
    })
    builder.addCase(setActivePartBoundingBox, (state, action) => {
      state.activePartBoundingBox = action.payload
    })
    builder.addCase(setActiveOpRuntime, (state, action) => {
      state.opRuntimes = {
        ...state.opRuntimes,
        [action.payload.opId]: action.payload.runtime,
      }
    })
  }
)

/**
 * Actions
 */
export const activeActions = {
  setActiveConfigTab,
  setActiveConfigPanel,
  setActiveJobId,
  setActiveJobLabel,
  setActivePlanId,
  setActiveOperationIdx,
  setActiveProbingSectionId,
  setActiveMachineOffsetId,
  setActiveProbeStepId,
  setMultipleSelectedProbeSteps,
  setActiveCopiedProbeSteps,
  setHoveredFixtureElemId,
  setSelectedFixtureElemId,
  setActiveClickHandler,
  setActivePanelProps,
  setActiveToastMessage,
  setActiveRevision,
  setActiveStageName,
  setActiveFeedbackId,
  setActiveCamjobstatus,
  cycleActiveClickHandler,
  setActiveIssue,
  setActiveEpicKey,
  setActivePartBoundingBox,
  setActiveOpRuntime,
}

/**
 * Selectors
 */
const selectActiveProbingSectionId = (state: RootState): string | undefined =>
  state.active.activeProbingSectionId
const selectActiveMachineOffsetId = (state: RootState): string | undefined =>
  state.active.activeMachineOffsetId
const selectActiveProbeStepId = (state: RootState): string | undefined =>
  state.active.activeProbeStepId
const selectActiveCopiedProbeSteps = (
  state: RootState
): (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[] | undefined =>
  state.active.activeCopiedProbeSteps
const selectMultipleSelectedProbeSteps = (
  state: RootState
): (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[] | undefined =>
  state.active.multipleSelectedProbeSteps
const selectActiveConfigTab = (state: RootState): string | undefined => state.active.activeConfigTab
const selectActiveConfigPanel = (state: RootState): ConfigPanelSelect | undefined =>
  state.active.activeConfigPanel
const selectActiveJobId = (state: RootState): string | undefined =>
  state.active.activeJobId ?? undefined
const selectActiveJobLabel = (state: RootState): string | undefined =>
  state.active.activeJobLabel ?? undefined
const selectActivePlanId = (state: RootState): string | undefined =>
  state.active.activePlanId ?? undefined
const selectHoveredFixtureElemId = (state: RootState): string | undefined =>
  state.active.hoveredFixtureElemId ?? undefined
const selectSelectedFixtureElemId = (state: RootState): string | undefined =>
  state.active.selectedFixtureElemId ?? undefined
const selectActiveOperationIdx = (state: RootState): number | undefined =>
  state.active.activeOperationIdx ?? undefined
const selectActiveClickHandler = (state: RootState): ClickHandlerType | undefined =>
  state.active.activeClickHandler ?? undefined
const selectChangeToken = (state: RootState): string => state.active.changeToken
const selectActivePanelProps = (state: RootState): ActivePanelProps | undefined =>
  state.active.activePanelProps
const selectActiveToastMessage = (state: RootState): IToastProps | undefined =>
  state.active.activeToastMessage

const selectActiveRevison = (state: RootState): number | undefined => state.active.activeRevision
const selectActiveStageName = (state: RootState): string | undefined => state.active.activeStageName
const selectActiveFeedbackId = (state: RootState): string | undefined =>
  state.active.activeFeedbackId
const selectActiveCamjobstatus = (state: RootState): Camjobstatus | undefined =>
  state.active.activeCamjobstatus

const createSelectCamOperationScenes = (): ((state: RootState) => CamOperationSceneInputs[]) =>
  createSelector(selectActivePlanId, selectActiveOperationIdx, (activePlanId, activeOperationIdx) =>
    getCamOperationScenes(activePlanId, activeOperationIdx)
  )
const selectActiveIssue = (state: RootState): jira.IIssue | undefined => state.active.activeIssue
const selectActiveEpicKey = (state: RootState): string | undefined => state.active.activeEpicKey
const selectActivePartBoundingBox = (state: RootState): Box3 | undefined =>
  state.active.activePartBoundingBox
const selectActiveOpRuntimes = (state: RootState): Record<string, number> | undefined =>
  state.active.opRuntimes

export const activeSelectors = {
  createSelectCamOperationScenes,
  selectActiveConfigTab,
  selectActiveConfigPanel,
  selectActiveJobId,
  selectActiveJobLabel,
  selectActivePlanId,
  selectHoveredFixtureElemId,
  selectSelectedFixtureElemId,
  selectActiveOperationIdx,
  selectActiveClickHandler,
  selectChangeToken,
  selectActivePanelProps,
  selectActiveToastMessage,
  selectActiveRevison,
  selectActiveStageName,
  selectActiveFeedbackId,
  selectActiveCamjobstatus,
  selectActiveIssue,
  selectActiveEpicKey,
  selectActivePartBoundingBox,
  selectActiveOpRuntimes,
}

const selectActivePlan = (state: RootState) =>
  storedPlansSelectors.selectStoredPlan(state, activeSelectors.selectActivePlanId(state))?.plan

const selectActiveOperation = createSelector(
  [selectActivePlan, activeSelectors.selectActiveOperationIdx],
  (activePlan, operationIdx) =>
    operationIdx === undefined ? undefined : activePlan?.operations[operationIdx]
)

const selectActiveMachineRecord = createSelector(
  [selectActiveOperation, machinesSelectors.selectMachineRecordsMap],
  (operation, machineRecords) =>
    operation?.machineId ? machineRecords[operation.machineId] : undefined
)

const selectActiveToolpathProject = createSelector(
  selectActiveOperation,
  operation => operation?.toolpathProject
)

const selectActiveIssueAcknowledgement = (
  state: RootState
): ((issue: ProbingIssue) => IssueAcknowledgement | undefined) =>
  storedOperationSelectors.createSelectIssueAcknowledgement(
    activeSelectors.selectActivePlanId(state),
    activeSelectors.selectActiveOperationIdx(state)
  )(state)

const selectActiveWcs = createSelector(selectActiveOperation, operation => operation?.wcs)
const selectActiveMcs = createSelector(selectActiveOperation, operation => operation?.mcs)
const selectActiveProbing = createSelector(selectActiveOperation, operation => operation?.probing)
const selectActivePrecisionStrategy = createSelector(
  selectActiveProbing,
  probing => probing?.strategy
)
const selectActiveProbeId = createSelector(selectActiveProbing, probing => probing?.probeId)

const selectActiveProbeRecord = createSelector(
  [selectActiveProbeId, probesSelectors.selectEntities],
  (activeProbeId, entities): ProbeRecord | undefined => {
    return activeProbeId !== undefined ? entities[activeProbeId] : undefined
  }
)

const selectActiveIndexedProbingSection = createSelector(
  [selectActiveProbing, selectActiveProbingSectionId],
  (probing, sectionId): { section: ProbingSection; index: number } | undefined => {
    const strategy = probing?.strategy
    if (!strategy) return undefined
    const selectedSections = Object.values(strategy)
      .flatMap((sectionList: ProbingSection[]) =>
        sectionList.map((item, i) => ({
          item,
          i,
        }))
      )
      .filter(indexedSection => indexedSection.item.id === sectionId)

    if (selectedSections.length > 0) {
      const indexedSection = selectedSections[0]
      return { section: indexedSection.item, index: indexedSection.i }
    }
    return undefined
  }
)

const selectActiveProbingSection = createSelector(
  selectActiveIndexedProbingSection,
  indexedSection => indexedSection?.section
)

const selectActiveProbingSectionIndex = createSelector(
  selectActiveIndexedProbingSection,
  indexedSection => indexedSection?.index
)

const selectActiveProbingStep = createSelector(
  [selectActiveProbingSection, selectActiveProbeStepId],
  (
    section: ProbingSection | undefined,
    stepId: string | undefined
  ): ProbingStep | WcsProbingStep | undefined => {
    if (!section || !stepId) return undefined
    switch (section.kind) {
      case WcsProbingKindEnum.WcsProbing:
        return section.steps.find(step => step.id === stepId)
      case AlignmentKindEnum.Alignment: {
        let foundStep
        section.steps.forEach(step => {
          if (step.id === stepId) {
            foundStep = step
          } else if (step.kind === PointsFeatureKindEnum.PointsFeature) {
            step.steps.forEach(subStep => {
              if (subStep.id === stepId) {
                foundStep = subStep
              }
            })
          }
        })
        return foundStep
      }
      case InspectionKindEnum.Inspection: {
        let foundStep
        section.steps.forEach(step => {
          if (step.id === stepId) {
            foundStep = step
          } else if (step.kind === PointsFeatureKindEnum.PointsFeature) {
            step.steps.forEach(subStep => {
              if (subStep.id === stepId) {
                foundStep = subStep
              }
            })
          }
        })
        return foundStep
      }
    }
  }
)

const selectActiveProbingApproachDist = createSelector(
  [selectActiveProbingSection],
  (section: ProbingSection | undefined): number => {
    if (section?.kind === AlignmentKindEnum.Alignment) {
      return section.wcsKind === AlignmentWcsKind.G54 ||
        section.wcsKind === AlignmentWcsKind.AlignmentAndG54
        ? 15.0
        : 5.0
    }
    return 5.0
  }
)

const selectActiveMachineOffset = createSelector(
  [selectActiveOperation, selectActiveMachineOffsetId],
  (operation, machineOffsetId) =>
    machineOffsetId ? operation?.offsets?.[Number(machineOffsetId)] : undefined
)

const selectActiveProductionControlId = createSelector(
  [selectActiveProbingSectionId, selectActiveMachineOffsetId],
  (probingSectionId, machineOffsetId) => probingSectionId || machineOffsetId
)

const selectActiveProductionControl = createSelector(
  [selectActiveProbingSection, selectActiveMachineOffset],
  (probingSection, machineOffset) => probingSection || machineOffset
)

export const activeOperationSelectors = {
  selectActiveToolpathProject,
  selectActiveWcs,
  selectActiveMcs,
  selectActiveProbing,
  selectActivePrecisionStrategy,
  selectActiveMachineRecord,
  selectActiveIssueAcknowledgement,
}

export const activeProbingSelectors = {
  selectActiveProbeId,
  selectActiveProbeRecord,
  selectActiveProbingSectionId,
  selectActiveProbingSection,
  selectActiveProbingSectionIndex,
  selectActiveProbingStep,
  selectMultipleSelectedProbeSteps,
  selectActiveProbeStepId,
  selectActiveCopiedProbeSteps,
  selectActiveProbingApproachDist,
}

export const activeMachineOffsetSelectors = {
  selectActiveMachineOffsetId,
  selectActiveMachineOffset,
}

export const activeProductionControlSelectors = {
  selectActiveProductionControlId,
  selectActiveProductionControl,
}

/**
 * Utility functions
 */
function getCamOperationScenes(
  activePlanId?: string,
  operationIdx?: number
): CamOperationSceneInputs[] {
  if (activePlanId === undefined || operationIdx === undefined) {
    return []
  } else {
    return [
      {
        planId: activePlanId,
        operationIdx,
      },
    ]
  }
}
