import React, { FC, useCallback } from "react"
import { AxiosError } from "axios"

import { sendEvent } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { downloadFromUrl } from "src/util/files"

interface DownloadButtonProps {
  className?: string
  locator?: string
}

export const DownloadButton: FC<DownloadButtonProps> = ({ locator, className, children }) => {
  const toaster = useToaster()
  const errorToast = useNetworkErrorToast()
  const { planchangerApi } = useApi()

  const handleDownloadClick = useCallback(() => {
    if (!locator) {
      toaster.show({ message: "No download URL present", intent: "danger" })
      return
    }
    sendEvent("downloadFile", { locator })
    planchangerApi
      .urlFor_getFile(locator)
      .then(url => downloadFromUrl(url.toString()))
      .catch((error: AxiosError) => {
        errorToast(error, "Download error")
      })
  }, [locator, planchangerApi, toaster, errorToast])

  if (!locator) return null
  return (
    <div onClick={() => handleDownloadClick()} className={className} style={{ cursor: "pointer" }}>
      {children}
    </div>
  )
}
