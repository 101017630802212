import React, { FC, useCallback, useContext } from "react"
import { useAuth } from "@subscale/formlogic-auth-ts"
import axios, { AxiosResponse } from "axios"

import {
  ConfigApi,
  DocsApi,
  FilesApi,
  GuardrailApi,
  JiraApi,
  JobsApi,
  PaperlessPartsApi,
  PlanchangerApi,
  PlansApi,
  SubmissionApi,
  TasksApi,
  ToolsApi,
} from "src/client-axios"

export interface HttpApi {
  configApi: ConfigApi
  docsApi: DocsApi
  filesApi: FilesApi
  jobsApi: JobsApi
  plansApi: PlansApi
  planchangerApi: PlanchangerApi
  submissionApi: SubmissionApi
  tasksApi: TasksApi
  paperlessPartsApi: PaperlessPartsApi
  postFile: (url: string, formData: FormData) => Promise<AxiosResponse<void>>
  toolsApi: ToolsApi
  guardrailApi: GuardrailApi
  jiraApi: JiraApi
}

export const ApiContext = React.createContext<HttpApi | undefined>(undefined)

export const ApiProvider: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth()
  const accessToken = async () => await getAccessTokenSilently()
  const basePath =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  const configuration = {
    basePath,
    accessToken,
  }

  const configApi = new ConfigApi(configuration)
  const docsApi = new DocsApi(configuration)
  const filesApi = new FilesApi(configuration)
  const jobsApi = new JobsApi(configuration)
  const plansApi = new PlansApi(configuration)
  const planchangerApi = new PlanchangerApi(configuration)
  const submissionApi = new SubmissionApi(configuration)
  const tasksApi = new TasksApi(configuration)
  const paperlessPartsApi = new PaperlessPartsApi(configuration)
  const toolsApi = new ToolsApi(configuration)
  const guardrailApi = new GuardrailApi(configuration)
  const jiraApi = new JiraApi(configuration)

  const postFile = useCallback(async (url: string, formData: FormData): Promise<
    AxiosResponse<void>
  > => {
    const options = {
      headers: {
        "content-type": 'multipart/form-data; charset="utf-8"',
      },
    }
    return await axios.post(url, formData, options)
  }, [])

  const api = {
    configApi,
    docsApi,
    filesApi,
    jobsApi,
    plansApi,
    planchangerApi,
    submissionApi,
    tasksApi,
    paperlessPartsApi,
    postFile,
    toolsApi,
    guardrailApi,
    jiraApi,
  }
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): HttpApi => {
  const api = useContext(ApiContext)
  if (typeof api === "undefined") {
    throw new Error("No ApiContext was present")
  }
  return api
}
