import React, { FC, Suspense, useEffect, useState } from "react"

import { OvertravelScene } from "src/components/Canvas/Viewer/Scene/Cam/OvertravelScene"
import { useApi } from "src/hooks/useApi"
import { AnimatedGltfUrlModel } from "../../../SceneItems/GltfUrlModel"
import { getPartMaterial } from "../materials"

export const MachineBody: FC<{
  nodeName: string
  machineBody: string
  visible?: boolean
}> = ({ nodeName, machineBody, visible }) => {
  const { filesApi } = useApi()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (machineBody) {
      filesApi.urlFor_getMachineBodyModel(machineBody).then(result => {
        setUrl(result.toString())
      })
    }
  }, [machineBody, filesApi])

  if (!machineBody) return null

  if (url) {
    if (nodeName === "TravelLimits") {
      return (
        <Suspense fallback={<></>}>
          <OvertravelScene url={url} />
        </Suspense>
      )
    } else {
      return (
        <Suspense fallback={<></>}>
          <AnimatedGltfUrlModel
            url={url}
            flatShading={true}
            material={getPartMaterial(false)}
            visible={visible}
            userData={{ machineBody }}
          />
        </Suspense>
      )
    }
  }
  return null
}
