import { batch } from "react-redux"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"

import {
  ConfigApi,
  FixtureStockData,
  FixtureStockDataKindEnum,
  FixtureStockRecord,
  MaterialRecord,
} from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { activeActions } from "../cam/active"

const stocksAdapter = createEntityAdapter<FixtureStockRecord>({ selectId: item => item.stockId })
const stocksSlice = createSlice({
  name: "STOCKS",
  initialState: stocksAdapter.getInitialState(),
  reducers: {
    addMany: stocksAdapter.addMany,
    upsertMany: stocksAdapter.upsertMany,
    setAll: stocksAdapter.setAll,
    upsert: stocksAdapter.upsertOne,
    remove: stocksAdapter.removeOne,
  },
})

const { actions: stocksActions } = stocksSlice
const stocksConfigSelectors = stocksAdapter.getSelectors<RootState>(
  state => state.config.stocksConfig
)
const selectStocks = (state: RootState): FixtureStockRecord[] =>
  stocksConfigSelectors.selectAll(state)
const selectStock = (state: RootState, id?: string): FixtureStockRecord | undefined =>
  id ? stocksConfigSelectors.selectById(state, id) : undefined

const selectFixtureStocks = createSelector([selectStocks], records =>
  records.filter(
    (record: FixtureStockRecord): record is FixtureStockRecord & { data: FixtureStockData } =>
      record.data.kind === FixtureStockDataKindEnum.Fixture
  )
)

const materialsAdapter = createEntityAdapter<MaterialRecord>({ selectId: item => item.materialId })
const materialsSlice = createSlice({
  name: "MATERIALS",
  initialState: materialsAdapter.getInitialState(),
  reducers: {
    addMany: materialsAdapter.addMany,
    upsertMany: materialsAdapter.upsertMany,
    setAll: materialsAdapter.setAll,
    upsert: materialsAdapter.upsertOne,
    remove: materialsAdapter.removeOne,
  },
})
const { actions: materialsActions } = materialsSlice
const materialConfigSelectors = materialsAdapter.getSelectors<RootState>(
  state => state.config.materialsConfig
)

const selectMaterial = (state: RootState, id: string | undefined): MaterialRecord | undefined =>
  id ? materialConfigSelectors.selectById(state, id) : undefined

const selectMaterials = (state: RootState): MaterialRecord[] =>
  materialConfigSelectors.selectAll(state)

/**
 * Thunks
 */
const fetchStocksConfig = createAsyncThunk(
  "config/fixtures/fetchStocksConfig",
  async ({ configApi }: { configApi: ConfigApi }, thunkAPI) => {
    try {
      const stocksConfig = (await configApi.getProductStocks()).data

      batch(() => {
        thunkAPI.dispatch(stocksActions.setAll(stocksConfig.stocks))
        thunkAPI.dispatch(materialsActions.setAll(stocksConfig.materials))
      })
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch stocks config: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const { reducer: stocksConfigReducer } = stocksSlice
export const stocksSelectors = {
  selectStock,
  selectStocks,
  selectFixtureStocks,
  selectMaterial,
  selectMaterials,
}

export const { reducer: materialsConfigReducer } = materialsSlice

export const stocksThunks = {
  fetchStocksConfig,
}
