// See @alxmiron at https://github.com/palantir/blueprint/issues/3604
import React, { useMemo } from "react"
import { HotkeyConfig, useHotkeys } from "@blueprintjs/core"

import { CameraControls } from "src/hooks/useCameraControls"
import { activeActions } from "src/store/cam/active"
import { AppDispatch, crossSliceActions } from "src/store/rootStore"
import { viewOptionsActions } from "src/store/ui/viewOptions"

interface KeyboardControlsProps {
  onPreviousProbeStep: () => void
  onNextProbeStep: () => void
  onUndo: () => void
  onRedo: () => void
  onCopy: () => void
  onCut: () => void
  onPaste: () => void
  onAddMove: () => void
  onMoveProbeStepUp: () => void
  onMoveProbeStepDown: () => void
  resetCallbackRef?: React.MutableRefObject<(() => void) | undefined>
}

export const KeyboardControls: React.FC<
  KeyboardControlsProps & { dispatch: AppDispatch; cameraControls: CameraControls }
> = props => {
  const hotkeys: HotkeyConfig[] = useMemo(
    () => [
      {
        global: true,
        group: "Editing",
        combo: "mod + z",
        label: "Undo last change",
        onKeyDown: props.onUndo,
      },
      {
        global: true,
        group: "Editing",
        combo: "mod + y",
        label: "Redo last change",
        preventDefault: true,
        onKeyDown: props.onRedo,
      },
      {
        global: true,
        group: "Editing",
        combo: "shift + mod + z",
        label: "Redo last change",
        preventDefault: true,
        onKeyDown: props.onRedo,
      },
      // {
      //   global: true,
      //   group: "Orientation",
      //   combo: "u",
      //   label: "Toggle part vs. machine orientation",
      //   onKeyDown: () => props.dispatch(viewOptionsActions.toggleCoordinates()),
      // },
      // {
      //   global: true,
      //   group: "Orientation",
      //   combo: "r",
      //   label: "Reset camera position and orientation",
      //   onKeyDown: () => {
      //     if (props.resetCallbackRef?.current) props.resetCallbackRef?.current()
      //   },
      // },
      {
        global: true,
        group: "Probing",
        combo: "up",
        label: "Previous probe step",
        onKeyDown: props.onPreviousProbeStep,
      },
      {
        global: true,
        group: "Probing",
        combo: "down",
        label: "Next probe step",
        onKeyDown: props.onNextProbeStep,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + up",
        label: "Shift probe step up",
        onKeyDown: props.onMoveProbeStepUp,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + down",
        label: "Shift probe step down",
        onKeyDown: props.onMoveProbeStepDown,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + i",
        label: "Insert move",
        onKeyDown: props.onAddMove,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + c",
        label: "Copy probe step",
        onKeyDown: props.onCopy,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + x",
        label: "Cut probe step",
        onKeyDown: props.onCut,
      },
      {
        global: true,
        group: "Probing",
        combo: "mod + v",
        label: "Paste probe step",
        onKeyDown: props.onPaste,
      },
      {
        global: true,
        group: "Visibility",
        combo: "m",
        label: "Cycle machine display mode",
        onKeyDown: () => props.dispatch(crossSliceActions.cycleMachineDisplayMode()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "p",
        label: "Cycle part transparency",
        onKeyDown: () => props.dispatch(viewOptionsActions.cyclePartDisplayMode()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "s",
        label: "Cycle stock transparency",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleStockDisplayMode()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "o",
        label: "Cycle overtravel transparency",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleOvertravelDisplayMode()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "t",
        label: "Cycle stock to display (input stock, output stock)",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleStockToDisplay()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "f",
        label: "Cycle fixtures transparency",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleFixturesDisplayMode()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "g",
        label: "Toggle grid visibilty",
        onKeyDown: () => props.dispatch(viewOptionsActions.toggleShowGrid()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "w",
        label: "Toggle work coordinate system (WCS) visibilty",
        onKeyDown: () => props.dispatch(viewOptionsActions.toggleShowWcs()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "c",
        label: "Toggle cutting sim display",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleCuttingSimDisplay()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "o",
        label: "Toggle issues display",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleIssuesDisplay()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "a",
        label: "Toggle machine coordinates display",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleMachineCoordsDisplay()),
      },
      {
        global: true,
        group: "Visibility",
        combo: "r",
        label: "Toggle probing display",
        onKeyDown: () => props.dispatch(viewOptionsActions.cycleProbingDisplay()),
      },
      {
        global: true,
        group: "Interaction",
        combo: "i",
        label: "Cycle interaction mode",
        onKeyDown: () => props.dispatch(activeActions.cycleActiveClickHandler()),
      },
      {
        global: true,
        group: "Interaction",
        combo: "e",
        label: "Toggle translate / rotate",
        onKeyDown: () => props.dispatch(viewOptionsActions.toggleTransform()),
      },
    ],
    [props]
  )
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys)

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      {props.children}
    </div>
  )
}
