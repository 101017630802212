import React, { FC } from "react"
import { Vector3 } from "three"

interface PointLightsProps {
  distance: number
  target: Vector3
  pointIntensity?: number
}

export const PointLights: FC<PointLightsProps> = ({ distance, target, pointIntensity = 0.1 }) => {
  const pointLights: React.ReactElement[] = []
  let key = 0
  for (const x of [-distance, 0, distance]) {
    for (const y of [-distance, 0, distance]) {
      for (const z of [-distance / 2, distance]) {
        key += 1
        pointLights.push(
          <pointLight
            position={[x, y, z]}
            args={[0xffffff, pointIntensity]}
            key={`PointLight ${key}`}
          />
        )
      }
    }
  }
  return <group position={target}>{pointLights}</group>
}
