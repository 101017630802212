import React, { FC } from "react"
import * as THREE from "three"

interface ArrowProps {
  position: THREE.Vector3
  direction: THREE.Vector3
  color: THREE.Color
  radius: number
  length: number
  offset: number
  reverse: boolean
}

const CYL_AXIS = new THREE.Vector3(0, 1, 0)

export function arrowGroup(
  position: THREE.Vector3,
  direction: THREE.Vector3,
  color: THREE.Color,
  radius: number,
  length: number,
  offset: number,
  reverse: boolean
): THREE.Group {
  const bodyLength = length * 0.8
  const headLength = length * 0.2
  const headScale = 1.5
  const segmentCount = 16
  const arrowBodyArgs: [width: number, height: number, depth: number, radialSegments: number] = [
    radius,
    radius,
    bodyLength,
    segmentCount,
  ]
  const arrowHeadArgs: [
    width: number,
    height: number,
    depth: number,
    radialSegments: number
  ] = reverse
    ? [0, radius * headScale, headLength, segmentCount] // [radius * headScale, 0., headLength, segmentCount]
    : [0, radius * headScale, headLength, segmentCount]

  const meshMaterial = new THREE.MeshStandardMaterial({
    color,
    flatShading: false,
    metalness: 0.69,
    opacity: 1.0,
    // polygonOffset: true,
    // polygonOffsetFactor: STOCK_POLYGON_OFFSET_FACTOR,
    roughness: 0.05,
    // side: THREE.DoubleSide,
  })

  const bodyPosition = reverse
    ? new THREE.Vector3(0, -(offset + headLength + bodyLength * 0.5), 0)
    : new THREE.Vector3(0, offset + bodyLength * 0.5, 0)
  const headPosition = reverse
    ? new THREE.Vector3(0, -(offset + headLength * 0.5), 0)
    : new THREE.Vector3(0, offset + bodyLength + headLength * 0.5, 0)

  const quaternion = new THREE.Quaternion()
  quaternion.setFromUnitVectors(CYL_AXIS, direction)

  const body = new THREE.CylinderBufferGeometry(...arrowBodyArgs)
  const bodyMesh = new THREE.Mesh(body, meshMaterial)
  bodyMesh.position.set(bodyPosition.x, bodyPosition.y, bodyPosition.z)

  const head = new THREE.CylinderBufferGeometry(...arrowHeadArgs)
  const headMesh = new THREE.Mesh(head, meshMaterial)
  headMesh.position.set(headPosition.x, headPosition.y, headPosition.z)

  const group = new THREE.Group()
  group.position.set(position.x, position.y, position.z)
  group.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w)
  group.add(bodyMesh)
  group.add(headMesh)
  return group
}

export const Arrow: FC<ArrowProps> = ({
  position,
  direction,
  color,
  radius,
  length,
  offset,
  reverse,
}) => {
  const bodyLength = length * 0.8
  const headLength = length * 0.2
  const headScale = 1.5
  const segmentCount = 16
  const arrowBodyArgs: [width: number, height: number, depth: number, radialSegments: number] = [
    radius,
    radius,
    bodyLength,
    segmentCount,
  ]
  const arrowHeadArgs: [
    width: number,
    height: number,
    depth: number,
    radialSegments: number
  ] = reverse
    ? [0, radius * headScale, headLength, segmentCount] // [radius * headScale, 0., headLength, segmentCount]
    : [0, radius * headScale, headLength, segmentCount]

  const meshMaterial = new THREE.MeshStandardMaterial({
    color,
    flatShading: false,
    metalness: 0.69,
    opacity: 1.0,
    // polygonOffset: true,
    // polygonOffsetFactor: STOCK_POLYGON_OFFSET_FACTOR,
    roughness: 0.05,
    // side: THREE.DoubleSide,
  })

  const bodyPosition = reverse
    ? new THREE.Vector3(0, -(offset + headLength + bodyLength * 0.5), 0)
    : new THREE.Vector3(0, offset + bodyLength * 0.5, 0)
  const headPosition = reverse
    ? new THREE.Vector3(0, -(offset + headLength * 0.5), 0)
    : new THREE.Vector3(0, offset + bodyLength + headLength * 0.5, 0)

  const quaternion = new THREE.Quaternion()
  quaternion.setFromUnitVectors(CYL_AXIS, direction)
  return (
    <group position={position} quaternion={quaternion}>
      <mesh position={bodyPosition}>
        <cylinderGeometry args={arrowBodyArgs} />
        <primitive object={meshMaterial} attach={"material"} />
      </mesh>
      <mesh position={headPosition}>
        <cylinderGeometry args={arrowHeadArgs} />
        <primitive object={meshMaterial} attach={"material"} />
      </mesh>
    </group>
  )
}
