import React, { FC } from "react"
import { Button, Callout, Classes, Dialog } from "@blueprintjs/core"

import styles from "./UnlockDialog.module.css"

export const UnlockDialog: FC<{
  isOpen: boolean
  handleUnlock: () => void
  onClose: () => void
}> = ({ isOpen, handleUnlock, onClose }) => {
  const handleConfirmUnlock = () => {
    onClose()
    handleUnlock()
  }

  return (
    <Dialog
      title="Unlock for editing"
      icon="confirm"
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <Callout icon={"edit"} intent={"primary"} title={`Unlock`}>
          <div style={{ display: "flex" }}>{"Are you sure you want to unlock?"}</div>
        </Callout>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <div className={styles.actions}>
            <div>
              <Button onClick={handleConfirmUnlock}>Unlock</Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
