import React, { Dispatch, FC, SetStateAction, useMemo } from "react"

import { GuardrailFeedback, StageEnum } from "src/client-axios"
import { preferredGuardrailStagesOrder } from "src/pages/JobOverview/OperationCard/GuardrailInfo/GuardrailInfo"
import { activeActions } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { guardrailProgressIcon } from "../guardrailProgressIcon"

import styles from "./GuardrailStagesPipeline.module.css"

export const stagesEnumMap = {
  [StageEnum.Plan]: "Plan",
  [StageEnum.PostSimulation]: "Post simulation",
  [StageEnum.PreSimulation]: "Pre simulation",
  [StageEnum.Probing]: "Probing",
  [StageEnum.Setup]: "Setup",
  [StageEnum.Simulation]: "Simulation",
}

export const GuardrailStagesPipeline: FC<{
  feedbacks: GuardrailFeedback[] | undefined
  activeStageId: string
  setActiveStageId: Dispatch<SetStateAction<string>>
}> = ({ feedbacks, activeStageId, setActiveStageId }) => {
  const dispatch = useAppDispatch()

  const groupedFeedbacks = useMemo(
    () =>
      [
        ...(feedbacks
          ?.filter(feedback => {
            return [
              StageEnum.Probing,
              StageEnum.PreSimulation,
              StageEnum.Simulation,
              StageEnum.PostSimulation,
            ].includes(feedback.stage)
          })
          ?.reduce((acc: Map<StageEnum, GuardrailFeedback[]>, feedback) => {
            const group: StageEnum = feedback.stage
            acc.set(group, [...(acc.get(group) ?? []), feedback])
            return acc
          }, new Map())
          .entries() ?? []),
      ].sort((a, b) => {
        return (
          preferredGuardrailStagesOrder.indexOf(a[0]) - preferredGuardrailStagesOrder.indexOf(b[0])
        )
      }),
    [feedbacks]
  )

  return (
    <div className={styles.container}>
      <div className={styles.stageNamesContainer}>
        {groupedFeedbacks?.map(([stage, group]) => {
          return (
            <div
              className={styles.stageContainer}
              onClick={() => {
                dispatch(activeActions.setActiveStageName(stage))
                setActiveStageId(stage)
              }}
              key={stage}
            >
              <div className={activeStageId === stage ? styles.activeStageName : ""} key={stage}>
                {stagesEnumMap[stage]}
              </div>
              <div className={styles.stage}>{guardrailProgressIcon(group)}</div>
            </div>
          )
        })}

        <div className={styles.line} />
      </div>
    </div>
  )
}

// {Array.from(stages.entries()).map(([stage, level]) => {
