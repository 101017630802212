import React, { FC, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import {
  Alignment,
  AnchorButton,
  Button,
  HTMLTable,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  NavbarGroup,
  Position,
} from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { useAuth } from "@subscale/formlogic-auth-ts"

import { Modal } from "src/components/Generic/Modal/Modal"
import { useApi } from "src/hooks/useApi"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import logo from "src/images/formlogic.svg"
import { parseJwt } from "src/pages/Account/TokenView/TokenView"
import { renderDate } from "src/pages/Jobs/JobsTable/JobsTable"
import { RestoreUpload } from "src/pages/Jobs/RestoreUpload/RestoreUpload"
import { releaseNotes } from "src/pages/ReleaseNotesPage/ReleaseNotes"
import { ReleaseNote } from "src/pages/ReleaseNotesPage/ReleaseNotesPage"
import { ItarBar } from "../ItarBar/ItarBar"

import styles from "./Nav.module.css"

export const Nav: FC = () => {
  const {
    settings: { releaseNotesDate },
  } = useLocalStorageSettings()

  const hasUnreadReleaseNote = useMemo(() => {
    return (
      !releaseNotesDate ||
      releaseNotes?.some(
        releaseNote => new Date(releaseNote.attributes.date) > new Date(releaseNotesDate)
      )
    )
  }, [releaseNotesDate])

  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth()
  const authText = isAuthenticated ? "Log Out" : "Log In"
  const [restoreModalIsOpen, setRestoreModalIsOpen] = useState(false)
  const [hasViewAllJobsPermission, setHasViewAllJobsPermission] = useState(false)

  const handleAuthClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    isAuthenticated ? logout({ returnTo: window.location.origin }) : loginWithRedirect()
  }

  const handleMenuLogoutClick = (event: React.MouseEvent<HTMLElement>) => {
    handleAuthClick(event)
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(result => {
        setHasViewAllJobsPermission(
          (parseJwt(result)?.permissions as string[] | undefined)?.some((permission: string) => {
            return permission === "view:all_jobs"
          }) ?? false
        )
      })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  const searchParams = new URLSearchParams(window.location.search)

  const MainMenu = (
    <Menu>
      <MenuDivider title="CAM" />
      <MenuItem text="Browse Jobs" href="/cam-jobs" />
      <MenuDivider title="Config" />
      <MenuItem text="View Fixtures" href="/fixtures-config-viewer" />
      <MenuItem text="Custom Fixtures" href="/custom-fixtures" />
      <MenuItem text="Tool Library" href={"/tool-library"} />
      <MenuItem text="Tool Library Compare" href={"/tool-library-diff"} />
      <MenuItem text="Downloads" href={"/downloads"} />
      {hasViewAllJobsPermission && (
        <>
          <MenuItem text="Edit Stocks" href={"/stock-config"} />
          <MenuDivider title="Debug" />
          <MenuItem text="Import Job" onClick={() => setRestoreModalIsOpen(true)} />
          <MenuItem text="Parts Library" href="/parts-library" />
          <MenuItem text="Workers Status" href={"/workers-status"} />
          <MenuItem text="NC Viewer" href={"/nc-view"} />
          <MenuItem text="NC Diff" href={"/nc-diff"} />
          <MenuItem text="Guardrail Test Cases" href={"/guardrail"} />
        </>
      )}
      {isAuthenticated && <HelpLink />}
      <MenuDivider title="Account" />
      <MenuItem text="Profile" href="/account" />
      <MenuItem text={authText} onClick={handleMenuLogoutClick} />
    </Menu>
  )

  if (searchParams.get("noNav") === "true") {
    return <></>
  }

  const ReleaseNotesList = (
    <>
      <HTMLTable striped bordered condensed interactive className={styles.notes}>
        <thead>
          <tr>
            <th className={styles.dateHeaderCell}>Date</th>
            <th>Title</th>
          </tr>
        </thead>

        <tbody>
          {releaseNotes?.map((releaseNote: ReleaseNote, i) => {
            return (
              <HashLink
                key={i}
                target={"_blank"}
                to={`/release-notes#${encodeURIComponent(releaseNote.attributes.title)}`}
              >
                <tr>
                  <td className={styles.dateCol}>
                    {new Date(releaseNote.attributes.date) > new Date(releaseNotesDate || 0) && (
                      <Icon icon={"dot"} intent={"warning"} />
                    )}
                    {renderDate(new Date(releaseNote.attributes.date).toISOString())}
                  </td>
                  <td className={styles.titleCol}>{releaseNote.attributes.title}</td>
                </tr>
              </HashLink>
            )
          })}
        </tbody>
      </HTMLTable>
      <AnchorButton
        icon={"document-open"}
        href={"/release-notes"}
        target={"_blank"}
        className={styles.releaseNotesButton}
        fill
      >
        Open release notes
      </AnchorButton>
    </>
  )

  return (
    <>
      <Navbar className={styles.appBar}>
        <NavbarGroup align={Alignment.LEFT}>
          {isAuthenticated && (
            <Popover2 content={MainMenu} position={Position.BOTTOM}>
              <Button minimal>
                <Icon icon="menu" color="white" />
              </Button>
            </Popover2>
          )}
          <a href="/cam-jobs">
            <img src={logo} alt="Formlogic" className={styles.logo} />
          </a>
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          {isAuthenticated && (
            <>
              <Popover2
                content={ReleaseNotesList}
                position={Position.BOTTOM}
                popoverClassName={styles.releaseNotesPopover}
              >
                <div className={styles.releaseNotes}>
                  {hasUnreadReleaseNote && <Icon icon={"dot"} intent={"warning"} />}
                  <div className={styles.releaseNotesText}>Release Notes</div>
                </div>
              </Popover2>
              <span className={styles.navDivider}></span>
              <Link to="/cam-jobs" className={styles.navLink}>
                CAM
              </Link>
              {/* <Link to="/plan-feedback" className={styles.navLink}> */}
              {/*  Annotate */}
              {/* </Link> */}
              <Link to="/account" className={styles.navLink}>
                Account
              </Link>
              <Link to="#" onClick={handleAuthClick} className={styles.navLink}>
                {authText}
              </Link>
            </>
          )}
        </NavbarGroup>

        <Modal
          isOpen={restoreModalIsOpen}
          headerText={"Import Previously-Exported Job"}
          handleRequestClose={e => {
            e.stopPropagation()
            setRestoreModalIsOpen(false)
          }}
          bodyComponent={<RestoreUpload setRestoreModalIsOpen={setRestoreModalIsOpen} />}
        />
      </Navbar>
      <ItarBar />
    </>
  )
}

const HelpLink: FC = () => {
  const { docsApi } = useApi()

  const [url, setUrl] = useState<string>()

  useEffect(() => {
    docsApi.urlFor_docsProxy().then(val => {
      setUrl(val.toString())
    })
  }, [docsApi])

  if (!url) {
    return <></>
  }

  return (
    <>
      <MenuDivider title="Help" />
      <MenuItem text="Documentation" href={url} target={"_blank"} />
    </>
  )
}
