import { useMemo } from "react"
import { useSelector } from "react-redux"

import { WcsProbingKindEnum } from "src/client-axios"
import { activeProbingSelectors } from "src/store/cam/active"

export function useProbingGaugeLength(): number {
  const activeSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const probeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)
  return useMemo(() => {
    if (!probeRecord) return 0
    if (activeSection?.kind === WcsProbingKindEnum.WcsProbing) {
      return probeRecord.gaugeLength
    } else {
      // Alignment || Inspection
      return probeRecord.gaugeLength - probeRecord.sphereDiameter * 0.5
    }
  }, [probeRecord, activeSection])
}
