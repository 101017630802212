import { piecewise } from "d3-interpolate"

// Kinematics Data Background
// primary = rotary axis aligned with vector pointing towards spindle
//    Grob => A=-90, B=0
//    DVF => B=0, C=0

// Secondary = other rotary axis

// Process="headps0": sweep head primary secondary=0
//    * moves primary 0-360 in 45 degree increments
// Process="headps90" sweep head primary secondary=90
//    * moves primary 0-360 in 45 degree increments
// Process="heads+p0": head primary=0 secondary
//    * moves secondary 0 - 90 in 15 degree increments (could have +ve and -ve)

// Process="pperf": Probe performance
//    * measure of a sphere with 25 points (measures form of sphere)
// Process="prep": Probe repeatability
//    * measures some # of points (e.g. 13) and records a best fit sphere which is summarized in the prep result (is effectively a measure of backlash)
// Process="plul": Probe Load/Unload (probe loaded, tool change to reference tool, probe loaded again)
//    * Measures some # of points (e.g. 13) and records a best fit sphere which is summarized in the plul result
//      (measures of repeatability of machine affected by tool change)

export interface AxialRadialLog {
  Process: string
  B: number
  C: number
  x: number
  y: number
  z: number
  Profile: number
  i: number
  j: number
  k: number
  Axial: number
  u: number
  v: number
  w: number
  Radial: number
  A?: number
}

export interface KinematicData {
  timestamp: string

  bore_boss: number

  in_plane: number
  profile: number

  multi_axial: number

  multi_radial: number

  sphere_x: number

  sphere_y: number

  sphere_z: number

  axial_radial_log: AxialRadialLog[]
}

enum LogProcess {
  HEADPS0 = "headps0", // primary axis changes, secondary doesn't
  HEADS_P0 = "heads+p0", // secondary axis changes, primary doesn't
  HEADPS90 = "headps90", // primary axis changes, secondary doesn't
}

interface KinematicAxes {
  primary: string
  secondary: string
}

function getLogsOfProcess(logs: AxialRadialLog[], process: LogProcess): AxialRadialLog[] {
  return logs.filter(log => log.Process === process.valueOf())
}

function changingAxis(logs: AxialRadialLog[]): string {
  const aValues = logs.map(log => log.A ?? 0)
  const bValues = logs.map(log => log.B)
  const cValues = logs.map(log => log.C)

  const uniqueAValues: number[] = [...new Set(aValues)]
  const uniqueBValues: number[] = [...new Set(bValues)]
  const uniqueCValues: number[] = [...new Set(cValues)]
  if (uniqueAValues.length > 1) {
    return "A"
  } else if (uniqueBValues.length > 1) {
    return "B"
  }
  if (uniqueCValues.length === 1) {
    console.error("Expected 'C' coordinate values to change")
  }
  return "C"
}

function getAxes(logs: AxialRadialLog[]): KinematicAxes {
  const primary = changingAxis(getLogsOfProcess(logs, LogProcess.HEADPS0))
  const secondary = changingAxis(getLogsOfProcess(logs, LogProcess.HEADS_P0))
  return { primary, secondary }
}

type AxialRadialLogInterpolator = (t: number) => AxialRadialLog

export interface KinematicErrorMap {
  axes: KinematicAxes
  secondaryInterpolator: AxialRadialLogInterpolator
  primary0Interpolator: AxialRadialLogInterpolator
  primary90Interpolator: AxialRadialLogInterpolator
  secondaryMin: number
  secondaryMax: number
  primarySecondaryMin: number
  primarySecondaryMax: number
}

export interface MachineRotaryCoords {
  A: number
  B: number
  C: number
}

export interface KinematicError {
  Axial: number
  Radial: number
  toolVector: [number, number, number]
  deviation: [number, number, number]
  radialProjection: [number, number, number]
  coords: MachineRotaryCoords
}

export function machineCoordsKinematicError(
  errorMap: KinematicErrorMap,
  coords: MachineRotaryCoords
): KinematicError {
  // NOTE: could solve on grid with a Poisson solver and measurements as boundary conditions
  const {
    axes,
    secondaryInterpolator,
    primary0Interpolator,
    primary90Interpolator,
    secondaryMin,
    secondaryMax,
    primarySecondaryMin,
    primarySecondaryMax,
  } = errorMap

  let primary = logAxis(coords, axes.primary)
  let secondary = logAxis(coords, axes.secondary)

  while (primary < 0) {
    primary += 360
  }
  while (primary > 360) {
    primary -= 360
  }

  secondary = Math.min(Math.max(secondary, secondaryMin), secondaryMax)

  const primaryParam = primary / 360
  const secondaryParam = (secondary - secondaryMin) / (secondaryMax - secondaryMin)
  const primarySecondaryParam =
    (secondary - primarySecondaryMin) / (primarySecondaryMax - primarySecondaryMin)
  // Weight heads_p0Logs against interpolated value from primary columns
  // If primary coord is 0, will be all heads_p0Logs.  Otherwise, will max out at 0.25 * heads_p0Logs + 0.75 * interpolated_primary
  const secondary2Param = Math.sin((primary / 360.0) * Math.PI) * 0.75

  const primary1 = primary0Interpolator(primaryParam)
  const primary2 = primary90Interpolator(primaryParam)

  const secondary1 = secondaryInterpolator(secondaryParam)
  const secondary2 = piecewise([primary1, primary2])(primarySecondaryParam) as AxialRadialLog
  const { Axial, Radial, x, y, z, i, j, k, u, v, w } = piecewise([secondary1, secondary2])(
    secondary2Param
  ) as AxialRadialLog
  const deviation: [number, number, number] = [x, y, z]
  const toolVector: [number, number, number] = [i, j, k]
  const radialProjection: [number, number, number] = [u, v, w]
  return { Axial, Radial, deviation, toolVector, radialProjection, coords }
}

function logAxis(log: AxialRadialLog | MachineRotaryCoords, axis: string): number {
  if (axis === "B") {
    return log.B
  } else if (axis === "C") {
    return log.C
  } else {
    return log.A ?? 0
  }
}

function setLogAxis(log: AxialRadialLog | MachineRotaryCoords, axis: string, value: number) {
  if (axis === "B") {
    log.B = value
  } else if (axis === "C") {
    log.C = value
  }
}

function isOrdered(logs: AxialRadialLog[], axis: string): boolean {
  for (let i = 0; i < logs.length - 1; ++i) {
    const v1 = logAxis(logs[i], axis)
    const v2 = logAxis(logs[i + 1], axis)
    if (v1 > v2) {
      if (!(i === logs.length - 2 && v2 === 0)) {
        return false
      }
    }
  }
  return true
}

function fix360(logs: AxialRadialLog[], axis: string): void {
  if (logAxis(logs[logs.length - 1], axis) === 0) {
    setLogAxis(logs[logs.length - 1], axis, 360)
  }
}

function sortLogsByAxis(logs: AxialRadialLog[], axis: string): void {
  logs.sort((a, b) => {
    return logAxis(a, axis) < logAxis(b, axis) ? -1 : 1
  })
}

export function getKinematicErrorMap(data: KinematicData): KinematicErrorMap {
  const headps0Logs = getLogsOfProcess(data.axial_radial_log, LogProcess.HEADPS0)

  const heads_p0Logs = getLogsOfProcess(data.axial_radial_log, LogProcess.HEADS_P0)
  const headps90Logs = getLogsOfProcess(data.axial_radial_log, LogProcess.HEADPS90)

  if (headps0Logs.length !== headps90Logs.length) {
    console.error(
      `Expected len(headps)=${headps0Logs.length} and len(headps90)=${headps90Logs.length} lengths to be the same`
    )
  }

  const axes = getAxes(data.axial_radial_log)

  if (logAxis(heads_p0Logs[0], axes.primary) !== 0) {
    console.error(`Expected heads_p0Logs.${axes.primary} to be zero`)
  }

  if (isOrdered(headps0Logs, axes.primary) && isOrdered(headps90Logs, axes.primary)) {
    fix360(headps0Logs, axes.primary)
    fix360(headps90Logs, axes.primary)
  } else {
    sortLogsByAxis(headps0Logs, axes.primary)
    sortLogsByAxis(headps90Logs, axes.primary)
  }

  sortLogsByAxis(heads_p0Logs, axes.secondary)

  const secondaryInterpolator = piecewise(heads_p0Logs)
  const primary0Interpolator = piecewise(headps0Logs)
  const primary90Interpolator = piecewise(headps90Logs)
  const secondaryMin = logAxis(heads_p0Logs[0], axes.secondary)
  const secondaryMax = logAxis(heads_p0Logs[heads_p0Logs.length - 1], axes.secondary)
  const primarySecondaryMin = logAxis(headps0Logs[0], axes.secondary)
  const primarySecondaryMax = logAxis(headps90Logs[0], axes.secondary)

  return {
    axes,
    secondaryInterpolator,
    primary0Interpolator,
    primary90Interpolator,
    secondaryMin,
    secondaryMax,
    primarySecondaryMin,
    primarySecondaryMax,
  }
}
