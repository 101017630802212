import { Dimension } from "src/client-axios"
import {
  DimDesignatorType,
  DimSourceType,
  DimToolType,
  DimType,
} from "src/pages/DrawingViewer/interfaces"
import { getDatums, isBasic, isReference } from "src/pages/DrawingViewer/util/dim"

export const getDimDescription = (dim: Dimension, fullDesc: boolean = false): string => {
  let description = getDimTypeDescription(dim)
  if (fullDesc) {
    description += getDimTolMod2Description(dim)
    description += getDimDatumsDescription(dim)
    description += getDimTolTypeDescription(dim)
    description += getDimTextDescription(dim)
  }
  return description
}

const getDimTypeDescription = (dim: Dimension): string => {
  const dimType = dim?.dimType
  if (dimType) {
    if (dimType in DimType) {
      const label = DimType[dimType].replace(/([A-Z])/g, " $1")
      return label.charAt(0).toUpperCase() + label.slice(1)
    }
  }
  return `Unrecognized DimType: ${dimType}`
}

const getDimTolMod2Description = (dim: Dimension): string => {
  if (dim.dimTolMod2 === "Ì") return " at Maximum Material Condition"
  if (dim.dimTolMod2 === "Ë") return " at Least Material Condition"
  return ""
}

const getDimDatumsDescription = (dim: Dimension): string => {
  const datums = getDatums([dim])
  if (datums.length === 0) return ""
  if (datums.length === 1) {
    return ` relative to ${datums[0]}`
  }
  if (datums.length === 2) {
    return ` relative to ${datums[0]} and ${datums[1]}`
  }
  return ` relative to ${datums.slice(0, -1).join(", ") + ` and ${datums[datums.length - 1]}`}`
}

const getDimTolTypeDescription = (dim: Dimension): string => {
  if (isBasic([dim])) return " (Basic Dimension)"
  if (isReference([dim])) return " (Reference Dimension)"
  return ""
}

const getDimTextDescription = (dim: Dimension): string => {
  if (dim.dimText !== "") return ` (${dim.dimText})`
  return ""
}

export const getDimDesignator = (dim: Dimension): string => {
  const dimDesignator = dim.dimDesignator
  if (dimDesignator) {
    if (dimDesignator in DimDesignatorType) {
      const label = DimDesignatorType[dimDesignator]
      return label.charAt(0).toUpperCase() + label.slice(1)
    }
  }
  return `None`
}

export const getDimTool = (dim: Dimension): string => {
  const dimTool = dim.dimTool
  if (dimTool) {
    if (dimTool in DimToolType) {
      if (DimToolType[dimTool] === "coordinateMeasuringInstrument") {
        return "CMM"
      } else {
        const label = DimToolType[dimTool].replace(/([A-Z])/g, " $1")
        return label.charAt(0).toUpperCase() + label.slice(1)
      }
    }
  }
  return `None`
}

export const getDimSource = (dim: Dimension): string => {
  const dimSource = dim.dimSource
  if (dimSource) {
    if (dimSource in DimSourceType) {
      const label = DimSourceType[dimSource]
      return label.charAt(0).toUpperCase() + label.slice(1)
    }
  }
  return `None`
}
