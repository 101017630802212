import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { Dimension, QualityControlPlanData } from "src/client-axios"
import { useGetDrawingLazyQuery, useQualityControlPlansLazyQuery } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import {
  DimBBox,
  DimsData,
  DimsDataOptions,
  ShapeData,
  ShapeLocation,
} from "src/pages/DrawingViewer/interfaces"
import { dimLabel, hasDatum, isBasic, isReference } from "src/pages/DrawingViewer/util/dim"
import { activeSelectors } from "src/store/cam/active"
import { sortBy } from "src/util/sortBy"
import { bboxKey, getFilteredShapes } from "./util/dimCollection"
import { getDimsScoring } from "./difficulty"

export const useGetDimsData = (opts?: DimsDataOptions): DimsData => {
  const { jobId } = opts ?? {}

  const { planchangerApi } = useApi()

  const activeJobId = useSelector(activeSelectors.selectActiveJobId)
  const [getControlPlans, { data: controlPlans }] = useQualityControlPlansLazyQuery()
  const [getDrawing, { data: drawingData, loading, refetch }] = useGetDrawingLazyQuery()

  const [controlPlanData, setControlPlanData] = useState<QualityControlPlanData>()

  useEffect(() => {
    const maybeJob = jobId ?? activeJobId
    if (maybeJob !== undefined) {
      getControlPlans({ variables: { jobId: maybeJob } })
    }
  }, [jobId, activeJobId, getControlPlans])

  const controlPlanNode = controlPlans?.qualityControlPlans?.nodes[0]

  useEffect(() => {
    if (controlPlanNode !== undefined) {
      getDrawing({ variables: { drawingId: controlPlanNode.drawingId } })
    }
  }, [controlPlanNode, getDrawing])

  useEffect(() => {
    if (controlPlanNode !== undefined) {
      planchangerApi.getPlanchangerQualityControlPlan(controlPlanNode.id).then(val => {
        setControlPlanData((val?.data as unknown) as QualityControlPlanData)
      })
    }
  }, [controlPlanNode, planchangerApi])

  const drawingArray = controlPlanData?.drawings
  const highqaDrawingIdToPageIndex: Record<number, number> = useMemo(() => {
    const drawingIdToPageIndex: Record<number, number> = {}
    if (drawingArray !== undefined) {
      drawingArray.forEach(val => {
        const id = val.id
        const drawingPageNo = val.drawingPageNo
        if (id !== undefined && drawingPageNo !== undefined) {
          drawingIdToPageIndex[id] = parseInt(drawingPageNo)
        }
      })
    }
    return drawingIdToPageIndex
  }, [drawingArray])

  const dimArray = controlPlanData?.dimensions

  // const parts = [controlPlanData?.summary]

  const allShapes = useMemo(() => {
    if (dimArray === undefined) return []
    const dimsByBbox = new Map<string, Dimension[]>()
    dimArray.map(getDimBbox).forEach(({ dim, bbox }) => {
      const key = bboxKey(bbox)
      const existing = dimsByBbox.get(key)
      if (existing !== undefined) {
        existing.push(dim)
        dimsByBbox.set(key, existing)
      } else {
        dimsByBbox.set(key, [dim])
      }
    })

    let shapes = Array.from(dimsByBbox.values()).map((dims, i) => {
      // Can safely assume that dims has positive length based on logic used to build dimArray
      return {
        shapeId: i,
        label: dimLabel(dims),
        location: getShapeLocation(dims[0], highqaDrawingIdToPageIndex),
        dims,
        // parts,
        scoring: getDimsScoring(dims),
        isBasic: isBasic(dims),
        isReference: isReference(dims),
        hasDatum: hasDatum(dims),
      }
    })

    shapes = getSortedShapes(shapes)

    // Re-assign IDs in ascending order based on the sort
    shapes.map((shape, i) => {
      shape.shapeId = i + 1
      return shape
    })
    return shapes
  }, [dimArray, highqaDrawingIdToPageIndex])

  const filteredShapes = useMemo(() => {
    if (!opts?.filters) return allShapes
    return getFilteredShapes(allShapes, opts.filters)
  }, [allShapes, opts?.filters])

  return {
    drawingId: controlPlanNode?.drawingId,
    drawingFileId: drawingData?.drawing?.camJobFile?.fileId,
    drawingLocator: drawingData?.drawing?.camJobFile?.file?.locator,
    jobLabel: drawingData?.drawing?.camJobFile?.job?.label,
    allShapes,
    filteredShapes,
    // parts,
    refetch,
    loading,
  }
}

export const getSortedShapes = (shapes: ShapeData[]): ShapeData[] => {
  let sortedShapes = shapes

  // First, sort by the DimSort from HighQA
  sortedShapes = sortBy(sortedShapes, shape => shape.dims[0]?.dimSort ?? "")

  // Next, sort by the bucketed safety factor
  sortedShapes = sortBy(sortedShapes, shape => {
    // round safety factor to nearest 0.1
    let sortingSafetyFactor = +shape.scoring.safetyFactor.toFixed(1)

    // if the safety factor is above a lower threshold, round it to a multiple
    const roundingMultiple = 2.0
    const lowerThreshold = roundingMultiple * 3
    if (sortingSafetyFactor > lowerThreshold) {
      sortingSafetyFactor = roundingMultiple * Math.round(sortingSafetyFactor / roundingMultiple)
    }

    // don't distinguish between items with safetyFactor above an upper threshold
    const upperThreshold = 12.0
    return Math.min(sortingSafetyFactor, upperThreshold)
  })

  // Finally, put all the basic dimensions and reference dimensions at the end
  sortedShapes = sortBy(sortedShapes, shape => {
    if (shape.isBasic) return 1
    if (shape.isReference) return 2
    return 0
  })

  return sortedShapes
}

const getShapeLocation = (
  dim: Dimension,
  highqaDrawingIdToPageIndex: Record<number, number>
): ShapeLocation => {
  const dimDrawingId = dim.dimDrawingId
  const dimShapeCenter = dim.shapeCenter ?? "0,0"
  const dimShapePoints = dim.shapePoints ?? "0,0"

  const pageIndex = dimDrawingId ? highqaDrawingIdToPageIndex[dimDrawingId] : 0
  const topLeft = dimShapeCenter.split(",")
  const extents = dimShapePoints.split(",")

  return {
    pageIndex,
    topLeft: { x: +topLeft[0], y: +topLeft[1] },
    extents: { x: +extents[0], y: +extents[1] },
  }
}

const getDimBbox = (dim: Dimension): DimBBox => {
  const dimShapeCenter = dim.shapeCenter ?? "0,0"
  const dimShapePoints = dim.shapePoints ?? "0,0"

  const top = dimShapeCenter.split(",")
  const extent = dimShapePoints.split(",")

  return {
    bbox: {
      topLeft: {
        x: +top[0],
        y: +top[1],
      },
      extents: {
        x: +extent[0],
        y: +extent[1],
      },
    },
    dim,
  }
}
