import React, { FC, useCallback, useEffect, useRef } from "react"
import { Cone, Cylinder, Sphere, Text } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"

import { useCoordinateSystemContext } from "../../hooks/useCoordinateSystemContext"
import { isOrthographicCamera } from "../../util/camera"
import { Obj3dCallback } from "./types"

export const CoordinateSystem: FC<{
  label?: string
  camera?: THREE.Camera
  obj3dCallback?: Obj3dCallback
}> = ({ label, camera, obj3dCallback }) => {
  const groupRef = useRef(null)
  const labelGroupRef = useRef(new THREE.Group())
  const csysGroupRef = useRef(new THREE.Group())

  const {
    maxSize,
    hideLabel,
    textScale,
    shaftRadius,
    shaftLength,
    headRadius,
    headLength,
  } = useCoordinateSystemContext()

  const updateForCamera = useCallback(() => {
    if (!isOrthographicCamera(camera)) return

    const canvasSize = Math.max(camera.right - camera.left, camera.top - camera.bottom)
    const zoomedTextScale = (canvasSize * textScale) / camera.zoom
    const zoomedCsysScale = Math.min(zoomedTextScale * 0.5, maxSize)

    csysGroupRef.current.scale.setScalar(zoomedCsysScale)

    labelGroupRef.current.quaternion.copy(camera.quaternion)
    labelGroupRef.current.position.setZ(
      zoomedCsysScale * 1.05 + (zoomedTextScale - zoomedCsysScale) * 0.05
    )
    labelGroupRef.current.scale.setScalar(zoomedTextScale)
  }, [camera, maxSize, textScale])

  useEffect(() => updateForCamera(), [updateForCamera])
  useFrame(() => updateForCamera())

  useEffect(() => {
    if (groupRef.current && obj3dCallback) {
      obj3dCallback(groupRef.current)
    }
  }, [groupRef.current, obj3dCallback])

  return (
    <group ref={groupRef}>
      <group ref={labelGroupRef}>
        {!hideLabel && (
          <Text color={"black"} anchorX={"center"} anchorY={"middle"}>
            {label}
          </Text>
        )}
      </group>
      <group ref={csysGroupRef}>
        <CoordinateSystemGeometry
          shaftRadius={shaftRadius}
          shaftLength={shaftLength}
          headRadius={headRadius}
          headLength={headLength}
        />
      </group>
    </group>
  )
}

const CoordinateSystemGeometry: FC<{
  shaftLength: number
  shaftRadius: number
  headLength: number
  headRadius: number
}> = ({ shaftRadius, shaftLength, headLength, headRadius }) => {
  return (
    <group>
      <Sphere args={[shaftRadius * 2, 32, 32]}>
        <meshBasicMaterial color={"#ffffff"} />
      </Sphere>
      <group rotation-x={Math.PI / 2}>
        <Arrow
          shaftLength={shaftLength}
          shaftRadius={shaftRadius}
          headLength={headLength}
          headRadius={headRadius}
          color={"#0000ff"}
        />
      </group>
      <group rotation-z={-Math.PI / 2}>
        <Arrow
          shaftLength={shaftLength}
          shaftRadius={shaftRadius}
          headLength={headLength}
          headRadius={headRadius}
          color={"#ff0000"}
        />
      </group>
      <Arrow
        shaftLength={shaftLength}
        shaftRadius={shaftRadius}
        headLength={headLength}
        headRadius={headRadius}
        color={"#00ff00"}
      />
    </group>
  )
}

const Arrow: FC<{
  shaftLength: number
  shaftRadius: number
  headLength: number
  headRadius: number
  color: string
}> = ({ shaftRadius, shaftLength, headLength, headRadius, color }) => {
  const radialSegments = 32
  return (
    <>
      <group position-y={shaftLength / 2}>
        <Cylinder args={[shaftRadius, shaftRadius, shaftLength, radialSegments]}>
          <meshBasicMaterial color={color} />
        </Cylinder>
      </group>
      <group position-y={shaftLength}>
        <Cone args={[headRadius, headLength, radialSegments]}>
          <meshBasicMaterial color={color} />
        </Cone>
      </group>
    </>
  )
}
