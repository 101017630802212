import React, { FC, useEffect } from "react"
import * as THREE from "three"

export type LightingProps = {
  ambientIntensity?: number
  camera?: THREE.Object3D | (() => THREE.Object3D)
  cameraPointIntensity?: number
}

export const Lighting: FC<LightingProps> = ({ ambientIntensity, camera, cameraPointIntensity }) => {
  return (
    <>
      <CameraPointLight intensity={cameraPointIntensity} camera={camera} />
      <ambientLight intensity={ambientIntensity} />
    </>
  )
}

const CameraPointLight: FC<{
  intensity?: number
  camera?: THREE.Object3D | (() => THREE.Object3D)
}> = ({ intensity, camera: cameraOrGetCamera }) => {
  useEffect(() => {
    const camera = typeof cameraOrGetCamera === "function" ? cameraOrGetCamera() : cameraOrGetCamera
    if (!camera) return

    const pointLight = new THREE.PointLight(0xffffff, intensity)
    camera.add(pointLight)
    return () => {
      camera.remove(pointLight)
    }
  }, [intensity, cameraOrGetCamera])

  return null
}
