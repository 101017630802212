import { useCallback, useEffect, useState } from "react"
import axios, { AxiosError } from "axios"

import { useApi } from "src/hooks/useApi"
import { LocalStorageSettings, useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { useToaster } from "src/hooks/useToaster"
import { activeActions } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"

export const useIssues: () => {
  searchForEpic: (
    partName?: string | undefined,
    planId?: string | undefined,
    operationIdx?: number | undefined,
    currentUserData?:
      | {
          email: string
          permissions: string[]
        }
      | undefined,
    jiraAccessToken?: string | undefined,
    cloudId?: string | undefined
  ) => Promise<void>
  createEpic: (
    partName?: string | undefined,
    jiraAccessToken?: string | undefined,
    cloudId?: string | undefined
  ) => Promise<void>
  handleJiraCatch: (error: AxiosError, cb: () => Promise<void>) => void
  issues: jira.IIssue[]
  isLoading: boolean
  epicExists: boolean
  jiraAccessToken: string | undefined
  setJiraAccessToken: (value: React.SetStateAction<string | undefined>) => void
  jiraRefreshToken: string | undefined
  cloudId: string | undefined
} = () => {
  const { jiraApi } = useApi()

  const [issues, setIssues] = useState<jira.IIssue[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [epicExists, setEpicExists] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const toaster = useToaster()

  const {
    settings: {
      jiraAccessToken: jiraAccessTokenInitial,
      cloudId: cloudIdInitial,
      jiraRefreshToken: jiraRefreshTokenInitial,
    },
    setSettings,
  } = useLocalStorageSettings()

  const [jiraAccessToken, setJiraAccessToken] = useState<string | undefined>(jiraAccessTokenInitial)
  const [cloudId, setCloudId] = useState<string | undefined>(cloudIdInitial)
  const [jiraRefreshToken, setJiraRefreshToken] = useState<string | undefined>(
    jiraRefreshTokenInitial
  )

  useEffect(() => {
    window.addEventListener("storage", () => {
      const rawStoredSettings = localStorage.getItem("remoteShop")
      if (rawStoredSettings) {
        const vars: LocalStorageSettings = JSON.parse(rawStoredSettings)
        if (vars.jiraAccessToken !== jiraAccessToken) {
          setJiraAccessToken(vars.jiraAccessToken as string)
        }
        if (vars.cloudId !== cloudId) {
          setCloudId(vars.cloudId as string)
        }
        if (vars.jiraRefreshToken !== jiraRefreshToken) {
          setJiraRefreshToken(vars.jiraRefreshToken as string)
        }
      }
    })
  }, [cloudId, jiraAccessToken, jiraRefreshToken])

  const searchForEpic: (
    partName?: string | undefined,
    planId?: string | undefined,
    operationIdx?: number | undefined,
    currentUserData?:
      | {
          email: string
          permissions: string[]
        }
      | undefined,
    jiraAccessToken?: string | undefined,
    cloudId?: string | undefined
  ) => Promise<void> = useCallback(
    (
      partName?: string,
      planId?: string,
      operationIdx?: number,
      currentUserData?:
        | {
            email: string
            permissions: string[]
          }
        | undefined,
      jiraAccessToken?: string,
      cloudId?: string
    ) => {
      setIsLoading(true)

      const body = {
        expand: ["names", "schema", "operations"],
        jql: `summary ~ ${partName} AND issuetype = Epic`,
        maxResults: 15,
        fieldsByKeys: false,
        fields: ["summary", "status", "assignee"],
        startAt: 0,
      }

      const url = `${window.location.origin}/jira/ex/jira/${cloudId}/rest/api/3/search`
      const options = {
        headers: {
          Authorization: `Bearer ${jiraAccessToken}`,
          Accept: "application/json",
        },
      }

      return axios
        .post(url, body, options)
        .then((response: { data: { issues: jira.IIssue[] } }) => {
          if (response.data.issues.length) {
            const epicId = response.data.issues[0].id
            const epicKey = response.data.issues[0].key
            const body = {
              expand: ["names", "schema", "operations"],
              jql: `issuetype = Bug AND parentEpic = ${epicId} AND cf[10032] = ${planId} AND cf[10033] = ${operationIdx}`,
              maxResults: 15,
              fieldsByKeys: false,
              fields: ["*all"],
              startAt: 0,
            }
            axios
              .post(url, body, options)
              .then(response => {
                setEpicExists(true)
                dispatch(activeActions.setActiveEpicKey(epicKey))
                setIssues(response.data.issues)
              })
              .catch((error: AxiosError) => {
                toaster.show({ message: error.message, intent: "danger" })
              })
              .finally(() => {
                setIsLoading(false)
              })
          } else {
            setEpicExists(false)
            setIsLoading(false)
          }
        })
    },
    [dispatch, toaster]
  )

  const handleJiraCatch: (error: AxiosError, cb: () => Promise<void>) => void = useCallback(
    (error: AxiosError, cb: () => Promise<void>) => {
      if (jiraRefreshToken) {
        const body = {
          host: window.location.host,
          grantType: "refresh_token",
          refreshToken: jiraRefreshToken,
        }

        const options = {
          headers: {
            Content: "application/json",
          },
        }

        jiraApi
          .getJiraToken(body, options)
          .then((response: { data: { accessToken: string; refreshToken: string } }) => {
            setJiraAccessToken(response.data.accessToken)
            setJiraRefreshToken(response.data.refreshToken)

            setSettings({
              jiraAccessToken: response.data.accessToken,
              jiraRefreshToken: response.data.refreshToken,
            })

            const options = {
              headers: {
                Authorization: `Bearer ${response.data.accessToken}`,
                Accept: "application/json",
              },
            }

            axios
              .get(`${window.location.origin}/jira/oauth/token/accessible-resources`, options)
              .then((response: { data: { id: string }[] }) => {
                setSettings({
                  cloudId: response.data?.[0]?.id,
                })
                cb()
              })
              .finally(() => {
                setIsLoading(false)
              })
          })
          .catch((error: AxiosError) => {
            toaster.show({ message: error.message, intent: "danger" })

            setJiraAccessToken(undefined)
            setJiraRefreshToken(undefined)
            setCloudId(undefined)

            setSettings({
              jiraAccessToken: undefined,
              jiraRefreshToken: undefined,
              cloudId: undefined,
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        toaster.show({ message: error.message, intent: "danger" })

        setJiraAccessToken(undefined)
        setJiraRefreshToken(undefined)
        setCloudId(undefined)

        setSettings({
          jiraAccessToken: undefined,
          jiraRefreshToken: undefined,
          cloudId: undefined,
        })
      }
      setIsLoading(false)
    },
    [jiraRefreshToken, setSettings, toaster, jiraApi]
  )

  const createEpic: (
    partName?: string | undefined,
    jiraAccessToken?: string | undefined,
    cloudId?: string | undefined
  ) => Promise<void> = useCallback(
    (partName?: string, jiraAccessToken?: string, cloudId?: string) => {
      setIsLoading(true)

      const options = {
        headers: {
          Authorization: `Bearer ${jiraAccessToken}`,
          Accept: "application/json",
        },
      }
      const url = `${window.location.origin}/jira/ex/jira/${cloudId}/rest/api/3/issue`
      const body = {
        fields: {
          project: {
            id: "10002",
          },
          summary: partName,
          issuetype: {
            id: "10000",
          },
          description: {
            type: "doc",
            version: 1,
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: partName,
                  },
                ],
              },
            ],
          },
          customfield_10011: partName,
        },
      }

      return axios
        .post(url, body, options)
        .then(response => {
          setEpicExists(true)
          dispatch(activeActions.setActiveEpicKey(response.data.key))
        })
        .catch((error: AxiosError) => {
          toaster.show({ message: error.message, intent: "danger" })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [dispatch, toaster]
  )

  return {
    searchForEpic,
    createEpic,
    handleJiraCatch,
    issues,
    isLoading,
    epicExists,
    jiraAccessToken,
    setJiraAccessToken,
    cloudId,
    jiraRefreshToken,
  }
}
