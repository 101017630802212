import React, { createContext, FC, useContext, useEffect, useRef } from "react"
import { ControllerUpdate, SpringConfig } from "@react-spring/three"
import * as THREE from "three"

import { isOrthographicCamera } from "src/util/threeUtils"
import {
  configureOrthographicFrustrum,
  initializeCamera,
} from "../components/Canvas/Viewer/Camera/camera"
import { FLStdOrbitControls } from "../components/Canvas/Viewer/Camera/FocusOrbitControls/FLStdOrbitControls"

// SharedUpdate is meant to serve the role of capturing the fields of ControllerUpdate<{[key]: T}> not dependent on T.
// It restricts the allowed types in such a way that it can be safely shared by different attributes
// Support for other options from ControllerUpdate could be added if more control is desired
interface SharedUpdate {
  config?: SpringConfig
  delay?: number
  immediate?: boolean
}

type CameraControlsRequest = (
  | {
      kind: "target"
      to: { target: [number, number, number]; preserveCameraOrientation?: boolean }
    }
  | { kind: "position"; to: [number, number, number] }
  | { kind: "direction"; to: [number, number, number] }
  | { kind: "azimuthalAngle"; to: number }
  | { kind: "polarAngle"; to: number }
  | { kind: "zoom"; to: number }
  | { kind: "frustrumWidth"; to: number }
  | {
      kind: "sceneBox"
      to: { box: THREE.Box3; scale?: number; preserveCameraOrientation?: boolean }
    }
) & { shared?: SharedUpdate }

interface CameraController {
  gl: THREE.Renderer
  camera: THREE.OrthographicCamera
  canvas: HTMLCanvasElement
  controls: FLStdOrbitControls

  setControlsTarget: (values: ControllerUpdate<{ target: [number, number, number] }>) => void
  setControlsAzimuthalAngle: (values: ControllerUpdate<{ azimuthalAngle: number }>) => void
  setControlsPolarAngle: (values: ControllerUpdate<{ polarAngle: number }>) => void
  setCameraPosition: (values: ControllerUpdate<{ position: [number, number, number] }>) => void
  setZoom: (values: ControllerUpdate<{ zoom: number }>) => void
  setFrustrumWidth: (values: ControllerUpdate<{ frustrumWidth: number }>) => void
}

const EPS = 1e-3
const PI_OVER_2 = Math.PI / 2

export class CameraControls {
  controller?: CameraController

  cancel?: () => void

  requestQueue: CameraControlsRequest[]

  lastSetFrustrumWidth: number

  constructor() {
    this.requestQueue = []
    this.lastSetFrustrumWidth = 500
  }

  setController(controller?: CameraController): void {
    this.controller = controller
    if (!controller) {
      return
    }
    // Execute all requests that have been queued
    this.requestQueue.forEach(request => {
      switch (request.kind) {
        case "target":
          this.setControlsTarget(request.to, request.shared)
          return
        case "position":
          this.setCameraPosition(request.to, request.shared)
          return
        case "azimuthalAngle":
          this.setControlsAzimuthalAngle(request.to, request.shared)
          return
        case "polarAngle":
          this.setControlsPolarAngle(request.to, request.shared)
          return
        case "zoom":
          this.setZoom(request.to, request.shared)
          return
        case "frustrumWidth":
          this.setFrustrumWidth(request.to, request.shared)
          return
        case "sceneBox":
          this.setSceneBox(request.to, request.shared)
      }
    })

    // Clear the queue
    this.requestQueue.length = 0
  }

  setControlsTarget(
    to: { target: [number, number, number]; preserveCameraOrientation?: boolean },
    settings?: SharedUpdate
  ): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "target", to, shared: settings })
      return
    }

    // It is critical that these values are copied prior to any calls to this.controller.setX, which may modify them
    const currentTarget = this.controller.controls.target.clone()
    const currentPosition = this.controller.camera.position.clone()

    this.controller.setControlsTarget({
      to: { target: to.target },
      from: { target: currentTarget.toArray() as [number, number, number] },
      ...settings,
    })

    if (to.preserveCameraOrientation !== false) {
      const desiredTarget = new THREE.Vector3().fromArray(to.target)

      const desiredPosition = new THREE.Vector3()
        .subVectors(currentPosition, currentTarget)
        .add(desiredTarget)

      this.controller.setCameraPosition({
        to: { position: desiredPosition.toArray() as [number, number, number] },
        from: { position: currentPosition.toArray() as [number, number, number] },
        ...settings,
      })
    }
  }

  setCameraPosition(to: [number, number, number], settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "position", to, shared: settings })
      return
    }

    const currentPosition = this.controller.camera.position.toArray() as [number, number, number]

    this.controller.setCameraPosition({
      to: { position: to },
      from: { position: currentPosition },
      ...settings,
    })
  }

  setControlsDirection(to: [number, number, number], settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "direction", to, shared: settings })
      return
    }

    const cameraUp = this.controller.controls.object.up
    const rotation = new THREE.Quaternion().setFromUnitVectors(cameraUp, new THREE.Vector3(0, 1, 0))
    const rotatedTo = new THREE.Vector3(...to).applyQuaternion(rotation)
    const spherical = new THREE.Spherical().setFromVector3(rotatedTo.negate())

    const desiredPolarAngle = spherical.phi
    let desiredAzimuthalAngle = spherical.theta

    // Modify the desiredAzimuthalAngle to behave nicely based on the current camera position
    const currentAzimuthalAngle = this.controller.controls.getAzimuthalAngle()
    if (Math.abs(desiredPolarAngle) < EPS || Math.abs(desiredPolarAngle - Math.PI) < EPS) {
      // At the poles, rotate the azimuth to the nearest multiple of 90°
      const floor = Math.floor(currentAzimuthalAngle / PI_OVER_2)
      if (
        currentAzimuthalAngle - PI_OVER_2 * floor <
        PI_OVER_2 * (floor + 1) - currentAzimuthalAngle
      ) {
        desiredAzimuthalAngle = PI_OVER_2 * floor
      } else {
        desiredAzimuthalAngle = PI_OVER_2 * (floor + 1)
      }
    } else {
      // Away from the poles, rotate the azimuth in the shorter direction
      const delta = Math.abs(desiredAzimuthalAngle - currentAzimuthalAngle)
      if (Math.abs(desiredAzimuthalAngle - 2 * Math.PI) < delta) {
        desiredAzimuthalAngle -= 2 * Math.PI
      } else if (Math.abs(desiredAzimuthalAngle + 2 * Math.PI) < delta) {
        desiredAzimuthalAngle += 2 * Math.PI
      }
    }

    this.setControlsAzimuthalAngle(desiredAzimuthalAngle, settings)
    this.setControlsPolarAngle(desiredPolarAngle, settings)
  }

  setControlsAzimuthalAngle(to: number, settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "azimuthalAngle", to, shared: settings })
      return
    }
    this.controller.setControlsAzimuthalAngle({
      to: { azimuthalAngle: to },
      from: { azimuthalAngle: this.controller.controls.getAzimuthalAngle() },
      ...settings,
    })
  }

  setControlsPolarAngle(to: number, settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "polarAngle", to, shared: settings })
      return
    }
    this.controller.setControlsPolarAngle({
      to: { polarAngle: to },
      from: { polarAngle: this.controller.controls.getPolarAngle() },
      ...settings,
    })
  }

  setZoom(to: number, settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "zoom", to, shared: settings })
      return
    }
    this.controller.setZoom({
      to: { zoom: to },
      from: { zoom: this.controller.camera.zoom },
      ...settings,
    })
  }

  setSceneBox(
    to: { box: THREE.Box3; scale?: number; preserveCameraOrientation?: boolean },
    settings?: SharedUpdate
  ): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "sceneBox", to, shared: settings })
      return
    }

    const box = to.box
    const boxSize = box.max.clone().sub(box.min).length()

    const camera = this.controller.camera
    const width = camera.right - camera.left
    const height = camera.top - camera.bottom
    const desiredFrustrumWidth = (Math.max(width / height, 1) * boxSize) / (to.scale ?? 1)
    const center = box.getCenter(new THREE.Vector3()).toArray() as [number, number, number]

    this.setZoom(1.0)
    this.setFrustrumWidth(desiredFrustrumWidth, settings)
    this.setControlsTarget(
      {
        target: center,
        preserveCameraOrientation: to.preserveCameraOrientation,
      },
      settings
    )
  }

  setFrustrumWidth(to: number, settings?: SharedUpdate): void {
    if (!this.controller) {
      this.requestQueue.push({ kind: "frustrumWidth", to, shared: settings })
      return
    }
    const camera = this.controller.controls.object
    if (!isOrthographicCamera(camera)) throw new Error("Expected orthographic camera")
    this.controller.setFrustrumWidth({
      to: { frustrumWidth: to },
      from: { frustrumWidth: camera.right - camera.left },
      ...settings,
    })
  }

  updateFrustrumWidth(): void {
    if (!this.controller) return
    const results = configureOrthographicFrustrum(
      this.controller.gl,
      this.controller.camera,
      this.lastSetFrustrumWidth,
      this.controller.canvas
    )
    if (results) {
      this.lastSetFrustrumWidth = results.newFrustrumWidth
    }
  }

  reset(): void {
    if (!this.controller) return

    const camera = this.controller.camera
    const desiredFrustrumWidth = camera.right - camera.left

    initializeCamera(this.controller.camera, 5_000, 1.0)
    configureOrthographicFrustrum(this.controller.gl, this.controller.camera, desiredFrustrumWidth)
  }
}

export const CameraControlsContext = createContext<CameraControls>(new CameraControls())

export const CameraControlsProvider: FC = ({ children }) => {
  const cameraControlsRef = useRef(new CameraControls())

  useEffect(() => {
    const cameraControls = cameraControlsRef.current
    return () => cameraControls.cancel?.()
  }, [cameraControlsRef])

  return (
    <CameraControlsContext.Provider value={cameraControlsRef.current}>
      {children}
    </CameraControlsContext.Provider>
  )
}
export const useCameraControls = (): CameraControls => {
  return useContext(CameraControlsContext)
}
