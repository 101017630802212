import { useState } from "react"
import { useThree } from "@react-three/fiber"

import { useEventListener } from "./useEventListener"

export interface SceneClickData {
  camera: THREE.Camera
  raycaster: THREE.Raycaster
  mouse: THREE.Vector2
  scene: THREE.Scene
  mouseEvent: MouseEvent
}

const SCENE_POINTER_UP_EVENT = "pointerup"
const SCENE_POINTER_DOWN_EVENT = "pointerdown"

const DOUBLE_CLICK_WINDOW_MS = 500
const LONG_CLICK_WINDOW_MS = 1000

export const useSceneClickEventHandler: (
  onClick?: (event: PointerEvent) => void,
  onDoubleClick?: (event: PointerEvent) => void,
  onRightClick?: (event: PointerEvent) => void
) => void = (
  onClick?: (event: PointerEvent) => void,
  onDoubleClick?: (event: PointerEvent) => void,
  onRightClick?: (event: PointerEvent) => void
) => {
  const { gl } = useThree()
  const canvas = gl.domElement

  const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 })
  const [mouseDownTimestamp, setMouseDownTimestamp] = useState(0)
  const [lastMouseUpTimestamp, setLastMouseUpTimestamp] = useState(0)
  const [buttons, setButtons] = useState(0)

  const resetDoubleClickTimer = (mouseEvent: PointerEvent) => {
    setLastMouseUpTimestamp(mouseEvent.timeStamp - DOUBLE_CLICK_WINDOW_MS)
  }

  const onPointerDown = (event: PointerEvent) => {
    const mouseEvent = event

    if (
      window.navigator.platform.includes("Mac") &&
      mouseEvent.buttons === 1 &&
      mouseEvent.ctrlKey
    ) {
      // Treat this like a right click
      setButtons(2)
    } else {
      setButtons(mouseEvent.buttons)
    }

    setMouseDownTimestamp(mouseEvent.timeStamp)
    setMouseDownPos({ x: mouseEvent.clientX, y: mouseEvent.clientY })
  }

  const onPointerUp = (event: PointerEvent) => {
    const mouseEvent = event

    const mouseUpPos = { x: mouseEvent.clientX, y: mouseEvent.clientY }
    const mouseUpTimestamp = mouseEvent.timeStamp
    setLastMouseUpTimestamp(mouseUpTimestamp)

    if (
      Math.abs(mouseUpPos.x - mouseDownPos.x) > 10 ||
      Math.abs(mouseUpPos.y - mouseDownPos.y) > 10 ||
      mouseUpTimestamp - mouseDownTimestamp > LONG_CLICK_WINDOW_MS
    ) {
      resetDoubleClickTimer(event)
      return // ignore long still clicks or large moves
    }

    if (onDoubleClick && mouseUpTimestamp - lastMouseUpTimestamp < DOUBLE_CLICK_WINDOW_MS) {
      if (buttons === 1) {
        onDoubleClick(event)
        resetDoubleClickTimer(event)
      }
    } else {
      if (buttons === 1) {
        onClick?.(event)
      } else if (buttons === 2) {
        onRightClick?.(event)
      }
    }
  }

  useScenePointerUpListener(onPointerUp, canvas)
  useScenePointerDownListener(onPointerDown, canvas)
}

export const useScenePointerUpListener = (
  handler: (event: PointerEvent) => void,
  canvas: HTMLCanvasElement
): void => {
  useEventListener(
    SCENE_POINTER_UP_EVENT,
    (event: Event) => {
      const clickEvent = event as PointerEvent
      handler(clickEvent)
    },
    canvas
  )
}

export const useScenePointerDownListener = (
  handler: (event: PointerEvent) => void,
  canvas: HTMLCanvasElement
): void => {
  useEventListener(
    SCENE_POINTER_DOWN_EVENT,
    (event: Event) => {
      const clickEvent = event as PointerEvent
      handler(clickEvent)
    },
    canvas
  )
}
