import { LineBasicMaterial, LineSegments, Material, Mesh, MeshStandardMaterial } from "three"

import { MaterialData } from "src/components/Canvas/Viewer/Scene/Cam/materials"

export function configureMaterialOpacity(body: LineSegments | Mesh, opacity: number): void {
  const material = body.material as Material
  material.transparent = true
  material.depthWrite = opacity === 1
  material.opacity = opacity
  material.visible = opacity > 0
}

export function configureWireMaterial(
  wire: LineSegments,
  { wireColor, transparent, opacity }: MaterialData
): void {
  const material = wire.material as LineBasicMaterial
  material.color = wireColor
  material.transparent = transparent
  if (opacity) {
    material.opacity = opacity
  }
}

export function configureMeshMaterial(
  mesh: Mesh,
  {
    transparent,
    opacity,
    metalness,
    roughness,
    color,
    side,
    polygonOffset,
    polygonOffsetFactor,
  }: MaterialData
): void {
  const material = mesh.material as MeshStandardMaterial

  material.transparent = transparent
  if (opacity) {
    material.opacity = opacity
  }
  material.depthWrite = !transparent || opacity === 1

  // use polygonOffsetFactor to move non-transparent polygons away from the camera to:
  // 1. prevent polygon-wire clipping issues, and
  // 2. prevent transparent surfaces in contact with non-transparent surfaces from flickering
  material.polygonOffset = polygonOffset ?? !transparent
  material.polygonOffsetFactor = (polygonOffsetFactor ?? 0) + (transparent ? 0 : 2)
  material.side = side

  material.metalness = metalness
  material.roughness = roughness
  material.color = color
}
