/**
 * Based on OrbitControls from "@react-three/drei";
 *
 * I used to need to tell the camera to update its matrix immediately after the controls are updated
 * during the frame loop to prevent camera jitters, but it seems this is no longer necessary
 */

import * as React from "react"
import { ReactThreeFiber, useFrame, useThree } from "@react-three/fiber"

import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { FLStdOrbitControls } from "./FLStdOrbitControls"

export type OrbitControlsProps = ReactThreeFiber.Overwrite<
  ReactThreeFiber.Object3DNode<FLStdOrbitControls, typeof FLStdOrbitControls>,
  {
    target?: ReactThreeFiber.Vector3
    camera?: THREE.Camera
    domElement?: HTMLElement
    regress?: boolean
    enableDamping?: boolean
    makeDefault?: boolean
    lockMouseOnOrthoZoom?: boolean
  }
>

export const FLDreiOrbitControls = React.forwardRef<FLStdOrbitControls, OrbitControlsProps>(
  function _FLDreiOrbitControls(
    {
      makeDefault,
      camera,
      regress,
      domElement,
      enableDamping = true,
      lockMouseOnOrthoZoom = false,
      enabled = true,
      ...restProps
    },
    ref
  ) {
    const { transferInvalidate } = useTransferInvalidate()
    const defaultCamera = useThree(({ camera }) => camera)
    const gl = useThree(({ gl }) => gl)
    const set = useThree(({ set }) => set)
    const get = useThree(({ get }) => get)
    const performance = useThree(({ performance }) => performance)
    const explCamera = camera || defaultCamera
    const explDomElement = domElement || gl.domElement
    const controls = React.useMemo(() => new FLStdOrbitControls(explCamera), [explCamera])

    useFrame(() => {
      if (enabled) controls.update()
    })

    React.useEffect(() => {
      if (!enabled) {
        controls.clearListeners()
      }
    }, [enabled, controls])

    React.useEffect(() => {
      const callback = () => {
        if (regress) performance.regress()
        transferInvalidate()
      }

      controls.connect(explDomElement)
      controls.addEventListener("change", callback)
      return () => {
        controls.removeEventListener("change", callback)
        controls.dispose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regress, controls, transferInvalidate])

    React.useEffect(() => {
      if (makeDefault) {
        const old = get().controls
        set({ controls })
        return () => set({ controls: old })
      }
      return undefined
    }, [makeDefault, controls, get, set])

    return (
      <primitive
        ref={ref}
        object={controls}
        enableDamping={enableDamping}
        lockMouseOnOrthoZoom={lockMouseOnOrthoZoom}
        {...restProps}
      />
    )
  }
)
