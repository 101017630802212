import React, { FC, useEffect, useState } from "react"
import { animated } from "@react-spring/three"
import * as THREE from "three"

interface SizedZPlaneGridProps {
  size: number
  opacity?: number
}

const SizedZPlaneGrid: FC<SizedZPlaneGridProps> = ({ size, opacity = 1 }) => {
  // Assume units of mm; use 1-cm grid spacing
  const divisions = Math.ceil(size / 10)
  const roundedSize = divisions * 10
  const [gridHelper] = useState(new THREE.GridHelper(roundedSize, divisions))
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const material = gridHelper.material as THREE.Material
    material.transparent = true
  }, [gridHelper])

  useEffect(() => {
    const material = gridHelper.material as THREE.Material
    material.opacity = opacity
    // Ensure that opacity is set before visible
    setTimeout(() => setReady(true), 0)
  }, [gridHelper, opacity])

  const visible = opacity > 0 ? ready : false
  return <primitive object={gridHelper} rotation-x={Math.PI / 2} visible={visible} />
}

export const AnimatedSizedZPlaneGrid = animated(SizedZPlaneGrid)
