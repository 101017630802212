import React, { FC } from "react"
import { Callout } from "@blueprintjs/core"

import { Modal } from "src/components/Generic/Modal/Modal"
import {
  CamJobDetailsFragment,
  useCamJobDetailsQuery,
  useDeleteCamPlanMutation,
  useUpdateCamJobActivePlanMutation,
} from "src/graphql/generated"
import { activeActions } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"

export const DeletePlanModal: FC<{
  camJob: CamJobDetailsFragment
  planId: string | undefined
  isOpen: boolean
  close: () => void
  onDelete: () => void
}> = ({ camJob, planId, isOpen, close, onDelete }) => {
  const [updateCamJobActivePlanMutation] = useUpdateCamJobActivePlanMutation()
  const dispatch = useAppDispatch()

  const { data, refetch } = useCamJobDetailsQuery({
    variables: { jobId: camJob?.id },
  })
  const [deleteCamPlanMutation] = useDeleteCamPlanMutation()

  const handleCancelDelete = () => {
    close()
  }

  const handleConfirmDelete = () => {
    close()
    handleDelete(planId)
  }

  const handleDelete = (id: string | undefined) => {
    if (id) {
      deleteCamPlanMutation({ variables: { id } })
        .then(_ => {
          onDelete && onDelete()
          refetch()
          if (id === camJob?.activePlanId) {
            updateCamJobActivePlanMutation({
              variables: {
                id: camJob?.id,
                activePlanId: null,
              },
              refetchQueries: ["camJobDetails"],
            })
          }
          const nonPropsalPlans =
            data?.camJob?.plans.filter(plan => !plan.isProposal && plan.id !== id) || []
          const nextActivePlan = nonPropsalPlans[0]?.id || `new-plan`
          dispatch(activeActions.setActivePlanId(nextActivePlan))
        })
        .catch(e => {
          console.error("handleDelete -> e", e)
        })
    }
  }
  return (
    <Modal
      iconName={false}
      isOpen={isOpen}
      headerText="Delete Plan?"
      bodyComponent={
        <div style={{ padding: "10px" }}>
          <Callout intent={"danger"} icon={"warning-sign"}>
            <p>
              This will delete the <b>ENTIRE PLAN</b>.
            </p>
            <p>
              Are you <b>sure</b> this is what you want?
            </p>
          </Callout>
        </div>
      }
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      handleCancel={handleCancelDelete}
      handleConfirm={handleConfirmDelete}
      handleRequestClose={close}
    />
  )
}
