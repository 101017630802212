import { SceneClickData } from "src/hooks/useSceneClickEventHandler"

export const getClickedIntersection = (
  clickData: SceneClickData
): THREE.Intersection | undefined => {
  const { raycaster, mouse, camera, scene } = clickData
  raycaster.setFromCamera(mouse, camera)

  return getRaycasterIntersection(raycaster, scene)
}

export const findNearbyIntersection = (
  intersections: THREE.Intersection[],
  func: (o: THREE.Object3D) => boolean,
  nearThreshold = 1.5
): THREE.Intersection | undefined => {
  let intersection: THREE.Intersection | undefined
  if (intersections.length > 0) {
    // Get Sim stock mesh if it's close to the
    if (func(intersections[0].object)) {
      intersection = intersections[0]
    } else {
      for (let i = 1; i < intersections.length; ++i) {
        const relativeDistance = intersections[i].distance - intersections[0].distance
        if (relativeDistance > nearThreshold) {
          break
        }
        if (func(intersections[i].object)) {
          intersection = intersections[i]
          break
        }
      }
    }
  }
  return intersection
}

export const getClickedIntersections = (clickData: SceneClickData): THREE.Intersection[] => {
  const { raycaster, mouse, camera, scene } = clickData
  raycaster.setFromCamera(mouse, camera)

  return getRaycasterIntersections(raycaster, scene)
}

export const getIntersection = (
  clickData: SceneClickData,
  origin: THREE.Vector3,
  direction: THREE.Vector3,
  excludeMachine?: boolean,
  excludeFixture?: boolean
): THREE.Intersection | undefined => {
  const { raycaster, scene } = clickData
  raycaster.set(origin, direction)

  return getRaycasterIntersection(raycaster, scene, excludeMachine, excludeFixture)
}

export const getRaycasterIntersection = (
  raycaster: THREE.Raycaster,
  scene: THREE.Scene,
  excludeMachine?: boolean,
  excludeFixture?: boolean
): THREE.Intersection | undefined => {
  const machinePrefixes = ["dvf", "grob"]

  const intersects = raycaster
    .intersectObjects(scene.children, true)
    .filter(
      inter =>
        inter.object.type !== "GridHelper" &&
        inter.object.type !== "LineSegments" &&
        inter.object.type !== "Points" &&
        inter.object.type !== "TransformControlsPlane" &&
        inter.object.type !== "AxesHelper" &&
        inter.object.visible === true &&
        !(excludeMachine && machinePrefixes.some(machine => inter.object.name.includes(machine))) &&
        !(excludeFixture && inter.object.userData.isFixture) &&
        !inter.object.userData.ignoreIntersection
    )

  let target: THREE.Intersection | undefined
  for (const intersection of intersects) {
    if (!isObjectVisible(intersection.object)) continue
    target = intersection

    // Treat clicking on transform controls the same as clicking on nothing
    let itemToCheck: THREE.Object3D | null = target?.object
    while (itemToCheck) {
      if (itemToCheck.userData.isTransformControls) {
        return undefined
      }
      itemToCheck = itemToCheck.parent
    }

    break
  }

  return target
}

const getRaycasterIntersections = (
  raycaster: THREE.Raycaster,
  scene: THREE.Scene
): THREE.Intersection[] => {
  const intersects = raycaster
    .intersectObjects(scene.children, true)
    .filter(
      inter =>
        inter.object.type !== "GridHelper" &&
        inter.object.type !== "LineSegments" &&
        inter.object.type !== "Points" &&
        inter.object.type !== "TransformControlsPlane" &&
        inter.object.type !== "AxesHelper" &&
        inter.object.visible === true &&
        !inter.object.userData.ignoreIntersection
    )

  const targets: THREE.Intersection[] = []
  for (const intersection of intersects) {
    if (!isObjectVisible(intersection.object)) continue
    // Treat clicking on transform controls the same as clicking on nothing
    let target: THREE.Intersection | undefined = intersection
    let itemToCheck: THREE.Object3D | null = intersection?.object
    while (itemToCheck) {
      if (itemToCheck.userData.isTransformControls) {
        target = undefined
        break
      }
      itemToCheck = itemToCheck.parent
    }
    if (target) {
      targets.push(target)
    }
  }

  return targets
}

export const isObjectVisible = (object: THREE.Object3D): boolean => {
  let visible = true

  let maybeObject: THREE.Object3D | null = object

  while (maybeObject) {
    if (!maybeObject.visible) {
      visible = false
      break
    }
    maybeObject = maybeObject.parent
  }

  return visible
}
