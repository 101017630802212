import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, HTMLTable, Menu, MenuItem } from "@blueprintjs/core"
import { ContextMenu2, Tooltip2 } from "@blueprintjs/popover2"
import copy from "copy-to-clipboard"
import { GlobalWorkerOptions } from "pdfjs-dist"
import workerURL from "pdfjs-dist/build/pdf.worker.js?url"

import { Compensation, Dimension, ExplicitMoveKindEnum } from "src/client-axios"
import { getDimDescription } from "src/pages/DrawingViewer/description"
import { getDimsScoring } from "src/pages/DrawingViewer/difficulty"
import { DifficultyGroup, ShapeData } from "src/pages/DrawingViewer/interfaces"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { InspectionIcon } from "../InspectionIcon/InspectionIcon"
import { getSortedShapes, useGetDimsData } from "../useGetDimsData"

import styles from "./CalloutTable.module.css"

GlobalWorkerOptions.workerSrc = workerURL

export type ToolLabel = string
export interface InspectionData {
  [dimLabel: string]: { [opLabel: string]: Set<ToolLabel> }
}

export const CalloutTable: FC<{
  setHoveredShape?: React.Dispatch<React.SetStateAction<number | undefined>>
  hideTable?: boolean
  inDialog?: boolean
  showInspection?: boolean
  allGreenCheckMarks?: boolean
  onSelect?: (shape: ShapeData) => void
  className?: string
}> = ({ setHoveredShape, inDialog, showInspection, allGreenCheckMarks, onSelect, className }) => {
  const { drawingId: drawingIdFromParams } = useParams<{
    drawingId?: string
  }>()

  const params = new URLSearchParams(window.location.search)
  const paramsSelectedShape = params.get("selectedShape")

  const [justCopied, setJustCopied] = useState<string | undefined>()
  const [defaultSort, setDefaultSort] = useState<boolean>(true)
  const { allShapes, filteredShapes } = useGetDimsData({
    drawingId: drawingIdFromParams,
    filters: { includeBasicDims: true, includeReferenceDims: true },
  })

  useEffect(() => {
    if (justCopied) setTimeout(() => setJustCopied(undefined), 3000)
  }, [justCopied])

  const selectedRow = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    return () => {
      const params = new URLSearchParams(window.location.search)
      if (params.get("selectedShape")) {
        params.delete("selectedShape")
      }
      window.history.replaceState({}, "", `?${params.toString()}`)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copyJson = (id: string, val: any) => {
    const copyText = JSON.stringify(val, undefined, 2)
    copy(copyText)
    setJustCopied(id)
  }

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  const operations = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperations(state, activePlanId)
  )

  const dimDifficultyGroups = useMemo(() => {
    const result: Record<string, DifficultyGroup> = {}
    allShapes.forEach(shape => {
      result[shape.label] = shape.scoring.difficultyGroup
    })
    return result
  }, [allShapes])

  const dimInspectionData: InspectionData = useMemo(() => {
    const result: InspectionData = {}
    operations?.forEach(op => {
      if (!op.probing?.strategy.inspections) return
      op.probing?.strategy.inspections.forEach(inspection => {
        inspection.steps.forEach(step => {
          if (step.kind === ExplicitMoveKindEnum.ExplicitMove) return
          const dimLabel = step.tolerances?.label
          if (!dimLabel) return
          const existingDimData = result[dimLabel] ?? {}
          const existingDimOpData = existingDimData[op.label] ?? new Set()

          step.pointCompensations?.compensations.forEach(compensation => {
            existingDimOpData.add(getToolCompensationLabel(compensation))
          })
          existingDimData[op.label] = existingDimOpData
          result[dimLabel] = existingDimData
        })
      })
    })
    return result
  }, [operations])

  return (
    <div className={`${styles.dimInfo} ${inDialog ? styles.dimInfoDialog : ""} ${className || ""}`}>
      <HTMLTable bordered condensed interactive className={styles.dimTable}>
        <thead>
          <tr>
            <th className={styles.dimColumn}>
              Dim
              <Button
                small={true}
                minimal={true}
                rightIcon="sort"
                intent={defaultSort ? "none" : "warning"}
                onClick={() => setDefaultSort(!defaultSort)}
              />
            </th>
            <th>Requirement</th>
            {showInspection && (
              <th className={styles.onMachineInspectionColumn}>On-Machine Inspection</th>
            )}
          </tr>
        </thead>
        <tbody>
          {(defaultSort ? getSortedShapes(filteredShapes) : filteredShapes)
            .flatMap((shape, shapeIndex) => shape.dims.map(dim => ({ dim, shape, shapeIndex })))
            .sort((aaa, bbb) => {
              if (defaultSort) return 0
              const aaaDim = aaa.dim?.dimCode
              const bbbDim = bbb.dim?.dimCode

              if (!aaaDim || !bbbDim) return 0

              const aaaDimNum = Number(aaaDim)
              const bbbDimNum = Number(bbbDim)
              if (isNaN(aaaDimNum) && isNaN(bbbDimNum)) {
                return aaaDim.localeCompare(bbbDim)
              }
              return aaaDimNum - bbbDimNum
            })
            .map(({ dim, shape, shapeIndex }) => {
              const { shapeId } = shape
              const { safetyFactor, difficultyGroup } = getDimsScoring([dim])
              return (
                dim.dimCode && (
                  <tr
                    ref={shapeId === Number(paramsSelectedShape) ? selectedRow : undefined}
                    onClick={() => {
                      if (Number(paramsSelectedShape) === shapeId) {
                        const params = new URLSearchParams(window.location.search)
                        params.delete("selectedShape")
                        window.history.replaceState({}, "", `?${params.toString()}`)
                      } else {
                        onSelect?.(shape)
                        const params = new URLSearchParams(window.location.search)
                        params.set("selectedShape", shapeId + "")
                        window.history.replaceState({}, "", `?${params.toString()}`)
                      }
                    }}
                    className={
                      shapeId === Number(paramsSelectedShape)
                        ? styles.highlightRow
                        : shapeIndex % 2 === 0
                        ? styles.dimTableEven
                        : styles.dimTableOdd
                    }
                    key={dim.dimCode}
                    onMouseEnter={() => {
                      setHoveredShape?.(shapeId)
                    }}
                    onMouseLeave={() => {
                      setHoveredShape?.(undefined)
                    }}
                  >
                    <td>
                      <Tooltip2
                        content={`Safety Factor: ${safetyFactor.toFixed(3)}`}
                        openOnTargetFocus={false}
                      >
                        <ContextMenu2
                          content={
                            <Menu>
                              <MenuItem
                                text={"Copy HighQA Data as JSON"}
                                onClick={(e: React.MouseEvent) => {
                                  e.preventDefault()
                                  // e.stopPropagation()
                                  if (dim.dimCode) {
                                    copyJson(dim.dimCode, { dim, shape })
                                  }
                                }}
                              />
                            </Menu>
                          }
                        >
                          {dim.dimCode}
                          {difficultyGroup !== DifficultyGroup.gray && (
                            <span className={`${styles.dimCodeTable} ${styles[difficultyGroup]}`} />
                          )}
                        </ContextMenu2>
                      </Tooltip2>
                    </td>
                    <td>
                      <DimRequirement dims={[dim]} />
                    </td>
                    {showInspection && (
                      <td>
                        <InspectionIcon
                          allGreenCheckMarks={allGreenCheckMarks}
                          inspectionData={dimInspectionData}
                          difficultyGroups={dimDifficultyGroups}
                          shapeTitle={dim?.shapeTitle ?? ""}
                          tooltip
                        />
                      </td>
                    )}
                  </tr>
                )
              )
            })}
        </tbody>
      </HTMLTable>
    </div>
  )
}

export const DimRequirement: FC<{
  dims: Dimension[]
  className?: string
}> = ({ dims, className }) => {
  return (
    <span className={`${styles.dimRequirement} ${className ?? ""}`}>
      {dims.map(val => (
        <React.Fragment key={val.dimCode}>
          <Tooltip2 content={getDimDescription(val)} openOnTargetFocus={false}>
            {val.dimRequirement}
          </Tooltip2>
          <br />
        </React.Fragment>
      ))}
    </span>
  )
}

const getToolCompensationLabel = ({ toolNumber, offsetKind }: Compensation): string => {
  return `T${toolNumber}${
    offsetKind === 0 ? "N" : offsetKind === 1 ? "L" : offsetKind === 2 ? "R" : ""
  }`
}
