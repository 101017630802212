import React, { FC, useState } from "react"
import { Vector3 } from "three"

import { createRequiredOptionalContext } from "./requiredOptionalContext"

export enum CylinderOrientation {
  Forward = 0,
  Reversed = 1,
  Internal = 2,
  External = 3,
}

/* eslint-disable camelcase */
export interface CylinderData {
  face_idx: number
  direction: [number, number, number]
  location: [number, number, number]
  diameter: number
  min_angle: number
  max_angle: number
  height: number
  area: number
  orientation: CylinderOrientation
}

export interface SelectedPoint {
  point: [number, number, number]
  intersection: THREE.Intersection
  faceNormal?: Vector3
  isPart: boolean
  isStock: boolean
  cylinder?: CylinderData
}

type InspectorContextProps = {
  selectedPoint1?: SelectedPoint | undefined
  selectedPoint2?: SelectedPoint | undefined
  setSelectedPoint1: React.Dispatch<React.SetStateAction<SelectedPoint | undefined>>
  setSelectedPoint2: React.Dispatch<React.SetStateAction<SelectedPoint | undefined>>
}

export const [InspectorContext, useInspectorContext] = createRequiredOptionalContext<
  InspectorContextProps
>("InspectorContext")

export const InspectorContextProvider: FC = ({ children }) => {
  const [selectedPoint1, setSelectedPoint1] = useState<SelectedPoint | undefined>()
  const [selectedPoint2, setSelectedPoint2] = useState<SelectedPoint | undefined>()

  return (
    <InspectorContext.Provider
      value={{ selectedPoint1, selectedPoint2, setSelectedPoint1, setSelectedPoint2 }}
    >
      {children}
    </InspectorContext.Provider>
  )
}
