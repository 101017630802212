import React, { FC, useMemo } from "react"
import { a, to, useSpring } from "@react-spring/three"
import { GroupProps } from "@react-three/fiber"
import * as THREE from "three"

import { useAnimationContext } from "../hooks/useAnimationContext"

const _IDENTITY_POSITION: [x: number, y: number, z: number] = [0, 0, 0]
const _IDENTITY_AXIS_ANGLE: [x: number, y: number, z: number] = [0, 0, 0]

const _EPSILON = Math.sqrt(Number.EPSILON)

const _T = new THREE.Vector3()
const _Q = new THREE.Quaternion()
const _S = new THREE.Vector3()
const _E = new THREE.Euler()

/**
 * Note: This component only performs animation if the AnimationContext has a non-undefined
 * value for the animation config
 */
export const AnimatedMatrixGroup: FC<GroupProps & { matrix: THREE.Matrix4 | undefined }> = ({
  matrix: targetMatrix,
  ...props
}) => {
  const { config } = useAnimationContext()

  if (!config) return <group matrixAutoUpdate={false} matrix={targetMatrix} {...props} />

  return <WithAnimationMatrixGroup matrix={targetMatrix} {...props} />
}

const WithAnimationMatrixGroup: FC<GroupProps & { matrix: THREE.Matrix4 | undefined }> = ({
  matrix: targetMatrix,
  ...props
}) => {
  const { onChange, config } = useAnimationContext()

  const [targetPosition, targetAxisAngle] = useMemo(() => {
    if (!targetMatrix) return [_IDENTITY_POSITION, _IDENTITY_AXIS_ANGLE]
    targetMatrix.decompose(_T, _Q, _S)

    const position = [_T.x, _T.y, _T.z]

    const denominator = Math.sqrt(1 - _Q.w * _Q.w)
    if (denominator < _EPSILON) return [position, _IDENTITY_AXIS_ANGLE]

    const angle = 2 * Math.acos(_Q.w)
    const multiplier = angle / denominator

    const axisAngle = [multiplier * _Q.x, multiplier * _Q.y, multiplier * _Q.z]

    return [position, axisAngle]
  }, [targetMatrix])

  const [{ animatedPosition, animatedAxisAngle }] = useSpring(
    {
      to: { animatedPosition: targetPosition, animatedAxisAngle: targetAxisAngle },
      onChange,
      config,
    },
    [targetPosition, targetAxisAngle]
  )

  const animatedRotation = to([animatedAxisAngle], ([x, y, z]) => {
    _T.set(x, y, z)
    const angle = _T.length()
    const axis = _T.normalize()

    _Q.setFromAxisAngle(axis, angle)
    _E.setFromQuaternion(_Q)
    return [_E.x, _E.y, _E.z]
  })

  return (
    <a.group
      position={(animatedPosition as unknown) as [x: number, y: number, z: number]}
      rotation={(animatedRotation as unknown) as [x: number, y: number, z: number]}
      {...props}
    />
  )
}
