import React, { FC, Suspense, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { BucketType } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { activeProbingSelectors } from "src/store/cam/active"
import { AnimatedGltfUrlModel } from "../../../SceneItems/GltfUrlModel"
import { getPartMaterial } from "../materials"

export const ProbeHolder: FC = () => {
  const activeProbeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)
  if (!activeProbeRecord) return null
  return (
    <>
      {activeProbeRecord.holderModelStems.map((stem, i) => (
        <ProbeHolderBody key={`${i}-${stem}`} stem={stem} />
      ))}
    </>
  )
}

export const ProbeHolderBody: FC<{ stem: string }> = ({ stem }) => {
  const { planchangerApi } = useApi()
  const [url, setUrl] = useState<string | undefined>()

  useEffect(() => {
    planchangerApi
      .urlFor_getFile(`holder_${stem}.glb`, undefined, true, BucketType.Config)
      .then(result => {
        setUrl(result.toString())
      })

    return () => {
      setUrl(undefined)
    }
  }, [stem, planchangerApi])

  if (url) {
    const material = Object.assign({}, getPartMaterial(false))
    material.wireColor = new THREE.Color(0x353535).convertSRGBToLinear()
    return (
      <Suspense fallback={<></>}>
        <AnimatedGltfUrlModel
          url={url}
          flatShading={false}
          userData={{ ignoreIntersection: true }}
          material={material}
          // {...props}
        />
      </Suspense>
    )
  }
  return null
}
