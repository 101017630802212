import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { ConfigApi, ProbeRecord } from "src/client-axios"
import { RootState } from "src/store/rootStore"
import { activeActions } from "../cam/active"

/**
 * Slice
 */
const adapter = createEntityAdapter<ProbeRecord>({ selectId: item => item.probeId })
const slice = createSlice({
  name: "PROBES",
  initialState: adapter.getInitialState(),
  reducers: {
    addMany: adapter.addMany,
    upsert: adapter.upsertOne,
    remove: adapter.removeOne,
  },
})

/**
 * Reducer
 */
export const { reducer: probesConfigReducer } = slice

/**
 * Actions
 */
const { actions: probesSliceActions } = slice

/**
 * Selectors
 */
export const probesSelectors = adapter.getSelectors<RootState>(state => state.config.probesConfig)

/**
 * Thunks
 */
const fetchProbesConfig = createAsyncThunk(
  "config/fixtures/fetchFixturesConfig",
  async ({ configApi }: { configApi: ConfigApi }, thunkAPI) => {
    try {
      const probesConfig = (await configApi.getProbesConfig()).data
      thunkAPI.dispatch(probesSliceActions.addMany(probesConfig.probes))
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch probes config: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const probesThunks = {
  fetchProbesConfig,
}
