import React, { FC, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, Pre, ProgressBar } from "@blueprintjs/core"
import { AxiosError } from "axios"

import { AlignmentWcsKind } from "src/client-axios"
import { sendEvent } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { ProbingValidation } from "./ManufacturingConfirm/ManufacturingCheckResults"
import { PublishConfirm } from "./ManufacturingConfirm/PublishConfirm"
import { ReleaseConfirm } from "./ManufacturingConfirm/ReleaseConfirm/ReleaseConfirm"

import styles from "./ManufacturingDialog.module.css"

export const ManufacturingDialog: FC<{
  handleRequestClose: () => void
  vericutValidated: boolean
  probingValidation: ProbingValidation
  planId: string
  operationIdx: number
  refetchMesPublishes: () => void
  forManufacturing: boolean
  isProveout: boolean
  version: { current?: string; next: string }
  noPublishChanges: boolean
  noReleaseChanges: boolean
}> = ({
  vericutValidated,
  probingValidation,
  handleRequestClose,
  planId,
  operationIdx,
  refetchMesPublishes,
  forManufacturing,
  isProveout,
  version,
  noPublishChanges,
  noReleaseChanges,
}) => {
  const { planchangerApi } = useApi()
  const toaster = useToaster()
  const [isPublishing, setIsPublishing] = useState(false)
  const [releaseAsProveout, setReleaseAsProveout] = useState(isProveout)

  const [machinePreCheck, setMachinePreCheck] = useState(true)
  const [machinePostCheck, setMachinePostCheck] = useState(true)
  const [squarenessPreCheck, setSquarenessPreCheck] = useState(false)
  const [squarenessPostCheck, setSquarenessPostCheck] = useState(false)
  const [clampAxesAlignments, setClampAxesAlignments] = useState(false)

  const probing = useSelector((state: RootState) =>
    storedOperationSelectors.selectProbing(state, planId, operationIdx)
  )
  const machineId = useSelector(
    storedOperationSelectors.createSelectMachineId(planId, operationIdx)
  )

  const noAlignments = useMemo(() => {
    const alignments = probing?.strategy.alignments
    return (
      alignments?.filter(
        alignment =>
          alignment.wcsKind === AlignmentWcsKind.Alignment ||
          alignment.wcsKind === AlignmentWcsKind.AlignmentAndG54 ||
          alignment.wcsKind === AlignmentWcsKind.AlignmentAndRefinement
      ).length || 0
    )
  }, [probing])

  const [includeCutting, setIncludeCutting] = useState(true)
  const [includeAlignments, setIncludeAlignments] = useState(noAlignments > 0)
  const [includeInspections, setIncludeInspections] = useState(true)
  const [includeMachineOffsets, setIncludeMachineOffsets] = useState(true)
  const [includeMachineOffsetsLogging, setIncludeMachineOffsetsLogging] = useState(false)
  const [enableCycleDprint, setEnableCycleDprint] = useState(false)

  const errorToast = useNetworkErrorToast()

  const handlePublishClick = useCallback(() => {
    setIsPublishing(true)
    sendEvent("publishToMes", { planId, operationIdx, vericutValidated })

    const title = forManufacturing ? "Releasing" : "Publishing"
    const key = toaster.show({
      message: (
        <>
          {title}:
          <ProgressBar />
        </>
      ),
    })

    // noinspection UnnecessaryLocalVariableJS
    const updateMinorOnPublish = !forManufacturing
    planchangerApi
      .publishToMes(
        planId,
        operationIdx,
        updateMinorOnPublish,
        true,
        releaseAsProveout,
        machinePreCheck,
        machinePostCheck,
        squarenessPreCheck,
        squarenessPostCheck,
        clampAxesAlignments,
        !includeCutting,
        !includeAlignments,
        !includeInspections,
        !includeMachineOffsets,
        !includeMachineOffsetsLogging,
        enableCycleDprint,
        // Needs to be `null` as it's an optional
        // TODO: Can we fix this so we don't need to disable eslint?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        null as any
      )
      .then(
        manifestLocations => {
          toaster.show(
            {
              message: (
                <>
                  Published to the following locations:
                  <Pre>
                    {manifestLocations.data.map((val, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {val}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </Pre>
                </>
              ),
              intent: "primary",
            },
            key
          )

          setIsPublishing(false)
          refetchMesPublishes()
          handleRequestClose()
        },
        (error: AxiosError) => {
          errorToast(error, "Error Publishing", key)
          setIsPublishing(false)
          handleRequestClose()
        }
      )
  }, [
    planId,
    operationIdx,
    vericutValidated,
    toaster,
    forManufacturing,
    planchangerApi,
    releaseAsProveout,
    machinePreCheck,
    machinePostCheck,
    squarenessPreCheck,
    squarenessPostCheck,
    clampAxesAlignments,
    includeCutting,
    includeAlignments,
    includeInspections,
    includeMachineOffsets,
    includeMachineOffsetsLogging,
    enableCycleDprint,
    refetchMesPublishes,
    handleRequestClose,
    errorToast,
  ])

  const actionText = forManufacturing ? "Release" : "Publish"
  const title = forManufacturing ? `${actionText} for Manufacturing` : `${actionText} CAM`
  const currentVersion = version.current && (isProveout ? "x" : "v") + version.current
  const nextVersion = (releaseAsProveout ? "x" : "v") + version.next

  return (
    <Dialog
      // icon={"info-sign"}
      onClose={handleRequestClose}
      title={title}
      isOpen={!isPublishing}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
      className={styles.dialog}
    >
      {forManufacturing ? (
        <ReleaseConfirm
          vericutValidated={vericutValidated}
          probingValidation={probingValidation}
          planId={planId}
          operationIdx={operationIdx}
          releaseOptions={{
            manufacturingStatus: {
              isProveout: releaseAsProveout,
              changedFromCurrent: isProveout !== releaseAsProveout,
            },
            currentVersion,
            nextVersion,
            machinePreCheck,
            machinePostCheck,
            squarenessPreCheck,
            squarenessPostCheck,
            clampAxesAlignments: machineId?.includes("dvf") ? clampAxesAlignments : undefined,
            includeCutting,
            includeInspections,
            includeMachineOffsets,
            includeMachineOffsetsLogging,
            enableCycleDprint,
            alignments: {
              allowSelection: noAlignments === 1,
              include: includeAlignments,
            },
          }}
          noPendingChanges={noReleaseChanges}
          handleRequestClose={handleRequestClose}
          handleSetReleaseAsProveout={setReleaseAsProveout}
          handleSetIncludeAlignments={setIncludeAlignments}
          handleSetMachinePreCheck={setMachinePreCheck}
          handleSetMachinePostCheck={setMachinePostCheck}
          handleSetSquarenessPreCheck={setSquarenessPreCheck}
          handleSetSquarenessPostCheck={setSquarenessPostCheck}
          handleSetClampAxesAlignments={setClampAxesAlignments}
          handleSetIncludeCutting={setIncludeCutting}
          handleSetIncludeInspections={setIncludeInspections}
          handleSetIncludeMachineOffsets={setIncludeMachineOffsets}
          handleSetIncludeMachineOffsetsLogging={setIncludeMachineOffsetsLogging}
          handleEnableCycleDprint={setEnableCycleDprint}
          handleReleaseClick={handlePublishClick}
        />
      ) : (
        <PublishConfirm
          vericutValidated={vericutValidated}
          planId={planId}
          operationIdx={operationIdx}
          currentVersion={currentVersion}
          nextVersion={nextVersion}
          noPendingChanges={noPublishChanges}
          handleRequestClose={handleRequestClose}
          handlePublishClick={handlePublishClick}
        />
      )}
    </Dialog>
  )
}
