import React, { FC, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Menu, MenuItem } from "@blueprintjs/core"
import { ContextMenu2 } from "@blueprintjs/popover2"

import { GuardrailModal } from "src/components/Cam/GuardrailModal/GuardrailModal"
import { useCamPlanJobQuery } from "src/graphql/generated"
import { useOperationArchiveDownloader } from "src/hooks/useArchiveDownloader"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

export const CamButton: FC<{ planId: string; operationIdx: number }> = ({
  planId,
  operationIdx,
}) => {
  const [guardrailModalIsOpen, setGuardrailModalIsOpen] = useState<boolean>(false)

  const { data: jobData } = useCamPlanJobQuery({ variables: { planId } })
  const plan = useSelector((state: RootState) => storedPlansSelectors.selectPlan(state, planId))

  const jobLabel = useMemo(() => jobData?.camPlan?.job?.label, [jobData])
  const planLabel = plan?.label
  const opLabel = plan?.operations?.[operationIdx]?.label

  const generateArchiveForRemoteShop = useOperationArchiveDownloader({
    planId,
    operationIdx,
  })
  const generateArchiveForCompanionApp = useOperationArchiveDownloader({
    planId,
    operationIdx,
  })

  const canDownloadCAMFiles = !!(jobLabel && planLabel && opLabel)

  return (
    <>
      <ContextMenu2
        content={
          <Menu>
            <MenuItem
              onClick={generateArchiveForCompanionApp}
              disabled={!canDownloadCAMFiles}
              text={"Open in Companion App"}
            />
            <MenuItem
              onClick={() => setGuardrailModalIsOpen(true)}
              text={"Report simulation issue"}
              icon="warning-sign"
            />
          </Menu>
        }
      >
        <Button
          icon={"download"}
          onClick={generateArchiveForRemoteShop}
          disabled={!canDownloadCAMFiles}
        >
          Download Setup
        </Button>
      </ContextMenu2>

      <GuardrailModal
        planId={planId}
        isOpen={guardrailModalIsOpen}
        operationIdx={operationIdx}
        close={() => setGuardrailModalIsOpen(false)}
      />
    </>
  )
}
