import React, { FC } from "react"

import { Modal } from "src/components/Generic/Modal/Modal"
import { useApi } from "src/hooks/useApi"

export const DeleteOperationModal: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  close: () => void
}> = ({ planId, operationIdx, isOpen, close }) => {
  const { plansApi } = useApi()
  const handleDelete = () => {
    plansApi.deleteOperation(planId, operationIdx).then(result => {
      console.log("handleDelete -> result", result)
    })
  }
  const handleCancel = () => {
    close()
  }
  const handleConfirm = () => {
    close()
    handleDelete()
  }
  return (
    <Modal
      isOpen={isOpen}
      headerText="Delete Operation?"
      bodyText="Are you sure you want to delete this operation?"
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleRequestClose={close}
    />
  )
}
