import { useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import {
  AlignmentKindEnum,
  InspectionKindEnum,
  PointsFeatureMoveKindEnum,
  PointsFeaturePointKindEnum,
} from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { useSelectedStepStartPoint } from "src/hooks/useSelectedStepStartPoint"
import { activeProbingSelectors, activeSelectors } from "src/store/cam/active"
import { machineSelectors } from "src/store/cam/machine"
import { DEFAULT_APPROACH_OFFSET, probingStepDetailSelectors } from "src/store/cam/probing"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { useAppDispatch } from "src/store/rootStore"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const useHandleAddMove: () => {
  handleAddMove: (
    selectedProbeStep: WcsProbingStep | ProbingStep | undefined,
    addToEnd?: boolean,
    isPoint?: boolean | undefined,
    offset?: number | undefined
  ) => void
} = () => {
  const dispatch = useAppDispatch()
  const selectedSectionIndex = useSelector(activeProbingSelectors.selectActiveProbingSectionIndex)
  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const activeOperationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const { planchangerApi } = useApi()
  const contact = useSelector(probingStepDetailSelectors.selectContact)
  const coords = useSelector(machineSelectors.selectCoords)
  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const selectedStepStartPoint = useSelectedStepStartPoint()

  const handleAddMove: (
    selectedProbeStep: WcsProbingStep | ProbingStep | undefined,
    addToEnd?: boolean,
    isPoint?: boolean | undefined,
    offset?: number | undefined
  ) => void = (selectedProbeStep, addToEnd = true, isPoint?: boolean, offset?: number) => {
    if (
      !(
        selectedSection?.kind === AlignmentKindEnum.Alignment ||
        selectedSection?.kind === InspectionKindEnum.Inspection
      ) ||
      selectedSectionIndex === undefined ||
      !activePlanId ||
      activeOperationIdx === undefined
    )
      return

    let step

    if (isPoint && contact) {
      const [nx, ny, nz] = contact.normal
      step = {
        id: uuidv4(),
        kind: PointsFeaturePointKindEnum.PointsFeaturePoint,
        position: {
          point: {
            x: contact.point[0] || 0,
            y: contact.point[1] || 0,
            z: contact.point[2] || 0,
          },
          extraCoords: coords,
        },
        normalApproachDistance: DEFAULT_APPROACH_OFFSET,
        normal: { x: nx, y: ny, z: nz },
      }
    } else {
      step = {
        id: uuidv4(),
        kind: PointsFeatureMoveKindEnum.PointsFeatureMove,
        position: {
          point: {
            x: selectedStepStartPoint[0],
            y: selectedStepStartPoint[1],
            z: selectedStepStartPoint[2],
          },
          extraCoords: coords,
        },
      }
    }

    dispatch(
      storedPlanThunks.createProbingSteps({
        planId: activePlanId,
        operationIdx: activeOperationIdx,
        planchangerApi,
        sectionKind: selectedSection.kind,
        sectionIdx: selectedSectionIndex,
        newSteps: [step],
        sectionStepId: addToEnd ? selectedProbeStep?.id : undefined,

        // when adding move after selected sub point
        probeStepId: addToEnd ? undefined : selectedProbeStep?.id,
        offset: offset !== undefined ? offset : addToEnd ? undefined : 1,
      })
    ).then()
  }
  return { handleAddMove }
}
