import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Icon, Intent } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { getIntentStyle } from "src/pages/ViewerPage/MachineCoordsPanel/MachineCoordsPanel"
import { activeSelectors } from "src/store/cam/active"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { currentStockMeshLoaded, useCuttingSimDisplayStore } from "src/store/zustandCuttingSim"

import styles from "./StockSimWarningBanner.module.css"

export const StockSimWarningBanner: FC<{ locked?: boolean }> = ({ locked }) => {
  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)

  const cuttingSimDisplayMode = useSelector(viewOptionsSelectors.cuttingSimDisplayMode)
  const [stockMeshLoadFailed, setStockMeshLoadFailed] = useState<boolean>()
  const [showStockSimWarningBanner, setShowStockSimWarningBanner] = useState<boolean>(true)

  const state = useCuttingSimDisplayStore()
  const { tasksApi } = useApi()
  const toaster = useToaster()

  const handleClickGenerateCuttingSimulation = () => {
    if (planId && operationIdx !== undefined) {
      const stockMeshes = state.stockMeshes
      stockMeshes.forEach(mesh => {
        mesh.loaded = false
      })
      tasksApi.simulateStockGcodeMapping(planId, operationIdx, true, true)
    } else {
      toaster.show({ message: "Failed to generate cutting simulation", intent: "danger" })
    }
  }

  useEffect(() => {
    return useCuttingSimDisplayStore.subscribe(
      currentStockMeshLoaded,
      meshLoaded => {
        setStockMeshLoadFailed(meshLoaded === undefined)
      },
      { fireImmediately: true }
    )
  })

  useEffect(() => {
    if (stockMeshLoadFailed && cuttingSimDisplayMode !== DisplayMode.Hidden) {
      setShowStockSimWarningBanner(true)
    } else {
      setShowStockSimWarningBanner(false)
    }
  }, [cuttingSimDisplayMode, stockMeshLoadFailed])

  const bannerIntent = Intent.WARNING

  let headerClassName = `${styles.headerContainer}`
  headerClassName = `${headerClassName} ${getIntentStyle(bannerIntent, styles)}`

  let iconClassName = `${styles.closeButton}`
  iconClassName = `${iconClassName} ${getIntentStyle(bannerIntent, styles)}`
  const text = "Cutting stock model could not be loaded, please run cutting simulation."

  return (
    <>
      {showStockSimWarningBanner && (
        <div className={headerClassName}>
          <div className={styles.runSimulation}>{text}</div>
          <Tooltip2
            placement="top-start"
            content="Generate/Regenerate cutting simulation. Requires that this operation be Vericut simulated at least once"
            openOnTargetFocus={false}
          >
            <Button
              disabled={locked}
              onClick={() => handleClickGenerateCuttingSimulation()}
              intent="primary"
            >
              Simulate
            </Button>
          </Tooltip2>
          <Icon
            icon="cross"
            className={iconClassName}
            onClick={() => setShowStockSimWarningBanner(false)}
          />
        </div>
      )}
    </>
  )
}
