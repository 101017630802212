import React, { FC, useEffect, useState } from "react"
import ReactPaginate from "react-paginate"

import { PartCard } from "./PartCard/PartCard"
import allParts from "./parts.json"

import styles from "./PartLibrary.module.css"

export interface LibraryPart {
  id: string
  x: number
  y: number
  z: number
  volume: number
  bboxVolume: number
}

const PartLibrary: FC = () => {
  const [rows, setRows] = useState<LibraryPart[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState("")

  const partsPerPage = 10

  useEffect(() => {
    let parts: LibraryPart[] = allParts.slice().sort((a, b) => a.bboxVolume - b.bboxVolume)
    if (query !== "") {
      parts = parts.filter(({ id }) => id.includes(query))
    }
    setPageCount(Math.ceil(parts.length / partsPerPage))
    parts = parts.slice(page * partsPerPage, (page + 1) * partsPerPage)
    setRows(parts)
  }, [query, page])

  const updatePage = () => {
    const params = new URLSearchParams(window.location.search)
    const paramsPage = params.get("page")
    if (paramsPage !== null) {
      const targetPage = Number.parseInt(paramsPage) - 1
      setPage(targetPage)
    }
  }

  useEffect(() => {
    updatePage()
    window.addEventListener("popstate", () => {
      updatePage()
    })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const paramsPage = params.get("page")
    if (paramsPage === null || Number.parseInt(paramsPage) - 1 !== page) {
      window.history.pushState({}, "", `?page=${page + 1}`)
    }
  }, [page])

  const handlePageClick = (data: { selected: number }) => {
    setPage(data.selected)
  }

  return (
    <div className={styles.container}>
      <h3>Parts Library</h3>
      <div className="bp3-input-group .modifier">
        <span className="bp3-icon bp3-icon-search" />
        <input
          className="bp3-input"
          type="search"
          placeholder="Search input"
          dir="auto"
          value={query}
          onChange={e => {
            setPage(0)
            setQuery(e.target.value)
          }}
        />
      </div>

      <ReactPaginate
        previousLabel={page > 0 ? "previous" : null}
        nextLabel={page < pageCount - 1 ? "next" : null}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        forcePage={page}
        pageClassName={styles.page}
        activeClassName={"active"}
      />

      {rows?.map(part => {
        return <PartCard {...{ part }} key={part.id} />
      })}

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        forcePage={page}
        pageClassName={styles.page}
        activeClassName={"active"}
      />
    </div>
  )
}

export default PartLibrary
