import React, { useContext } from "react"
import { DRACOLoader, GLTFLoader } from "three-stdlib"

export const defaultGTLFLoader = (dracoLoaderPath?: string): GLTFLoader => {
  const dracoLoader = new DRACOLoader()
  if (dracoLoaderPath !== undefined) {
    dracoLoader.setDecoderPath(dracoLoaderPath)
  }
  return new GLTFLoader().setDRACOLoader(dracoLoader)
}

export const GLTFLoaderContext = React.createContext(defaultGTLFLoader())

export const useGltfLoaderContext = () => useContext(GLTFLoaderContext)
