import React, { FC, useMemo } from "react"
import * as THREE from "three"

import { OpSceneGeometry } from "../../components/OpScene/OpSceneGeometry"
import { ViewCallback, Visibility } from "../../components/OpScene/OpSceneViewState"
import { AttributeBucket } from "../../generated/bindings/AttributeBucket"
import { Node as SceneNode } from "../../generated/bindings/Node"
import { convertToMatrix } from "../../util/geometry"
import { MATERIALS } from "../../util/materialsLibrary"
import { AnimatedMatrixGroup } from "../AnimatedMatrixGroup"
import { Obj3dCallback } from "./types"

export const NodeShapeModel: FC<{
  node: SceneNode
  attributes: AttributeBucket
  view: ViewCallback
  obj3dCallback?: Obj3dCallback
}> = ({ node, attributes, view, obj3dCallback }) => {
  const visible = (view(node) ?? Visibility.visible) !== Visibility.hidden

  const { meshMaterial, wireMaterial } = useNodeMaterials(node, attributes, view)
  const shape = node.shape
  const transform = shape?.transform

  const matrix = useMemo(() => {
    if (!transform) return undefined
    return convertToMatrix(transform)
  }, [transform])

  if (!shape) return null

  return (
    <AnimatedMatrixGroup matrix={matrix} visible={visible}>
      <OpSceneGeometry
        geometry={shape.geometry}
        meshMaterial={meshMaterial}
        wireMaterial={wireMaterial}
        obj3dCallback={obj3dCallback}
      />
    </AnimatedMatrixGroup>
  )
}

const useNodeMaterials = (
  node: SceneNode,
  attributes: AttributeBucket,
  view: ViewCallback
): { meshMaterial: THREE.Material; wireMaterial: THREE.Material } => {
  const transparent = view(node) === Visibility.transparent

  const isMillable = (attributes.is_millable[node.id] ?? "No") !== "No"
  // const isDisabled = attributes.is_disabled[node.id] ?? false

  const { mesh, wire } = useMemo(() => {
    switch (node.object?.kind) {
      case "design": {
        return MATERIALS.design(transparent)
      }
      case "stock": {
        return MATERIALS.stock(transparent)
      }
      case "fixture": {
        return MATERIALS.fixture(transparent, isMillable)
      }
      default:
        return MATERIALS.other(transparent)
    }
  }, [node.object?.kind, transparent, isMillable])

  return { meshMaterial: mesh, wireMaterial: wire }
}
