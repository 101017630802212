import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { AnchorButton, Classes, H6, HTMLSelect, Pre, ProgressBar } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { format } from "date-fns"
import { Maybe } from "graphql/jsutils/Maybe"

import { UnlockQCCardButton } from "src/components/Cam/UnlockButton/UnlockQCCardButton"
import { QualityControlPlanUpload } from "src/components/QualityControl/QualityControlPlanUpload"
import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  QualityControlPlanPublishNodeFragment,
  useCamJobDetailsQuery,
  useCamPlanControlPlanInfoQuery,
  useQualityControlPlanChangesSincePublishLazyQuery,
  useQualityControlPlanFilesLazyQuery,
  useQualityControlPlanHistoryLazyQuery,
  useQualityControlPlanPublishingsLazyQuery,
  useUpdateCamPlanControlPlanRevisionMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useGetControlPlanData } from "src/hooks/useGetControlPlanData"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { RootState } from "src/store/rootStore"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { QualityControlPlan } from "./QualityControlPlan/QualityControlPlan"
import { QualityControlPlanFiles } from "./QualityControlPlan/QualityControlPlanFiles"

import styles from "./QualityControlCard.module.css"

interface PublishInfo {
  version: string
  revision: number
  timestamp: Date
  createdAt: string
}

interface VersionInfo {
  currentPublish?: PublishInfo
  nextVersion: string
}

const getNextVersion = (currentVersion: string): string => {
  let nextVersion = "1.1"

  if (currentVersion) {
    if (currentVersion.match(/^[0-9]+\.[0-9]+\.[0-9]+$/g)) {
      const major = currentVersion.split(".")[0]
      const minor = currentVersion.split(".")[1]
      nextVersion = `${major}.${Number.parseInt(minor) + 1}`
    }
  }

  return nextVersion
}

const getVersionInfo = (publishes: QualityControlPlanPublishNodeFragment[]): VersionInfo => {
  if (publishes.length === 0) {
    const nextVersion = "1.1"
    return {
      currentPublish: undefined,
      nextVersion,
    }
  }
  const sortedPublishes = [...publishes].sort(
    ({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime()
  )

  function getPublishInfo({
    version,
    createdAt,
    controlPlanRevision,
  }: {
    version: string
    createdAt: string
    controlPlanRevision: number
  }): PublishInfo {
    return { version, timestamp: new Date(createdAt), createdAt, revision: controlPlanRevision }
  }

  let currentPublish: PublishInfo | undefined
  for (const publish of sortedPublishes) {
    if (
      publish.version.match(/^[0-9]+\.[0-9]+$/g) ||
      publish.version.match(/^[0-9]+\.[0-9]+.0$/g)
    ) {
      currentPublish = getPublishInfo(publish)
      currentPublish.version = currentPublish.version.split(".").slice(0, 2).join(".")
      break
    }
  }

  const nextVersion = getNextVersion(sortedPublishes[0].version)

  return {
    currentPublish,
    nextVersion,
  }
}

const VersionInfoDisplay: FC<{
  publishInfo?: PublishInfo
}> = ({ publishInfo }) => {
  return (
    <>
      {publishInfo ? (
        <div title={format(publishInfo.timestamp, "MMM d h:mma")}>
          x{publishInfo.version} published {format(publishInfo.timestamp, "M/d")}
        </div>
      ) : (
        <div className={Classes.TEXT_MUTED}>Not yet published</div>
      )}
    </>
  )
}

export const QualityControlCard: FC<{
  jobId: string
  camPlanId: string
  locked?: boolean
  assignees?: Maybe<string[]> | undefined
}> = ({ jobId, camPlanId, locked, assignees }) => {
  const { planchangerApi } = useApi()
  const toaster = useToaster()
  const errorToast = useNetworkErrorToast()

  const forceLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.controlPlanLocked(state)
  )

  const editLocked = locked || forceLocked

  const { data: jobDetails } = useCamJobDetailsQuery({
    variables: { jobId: jobId || "" },
  })

  const { controlPlan, refetchControlPlan, refetchControlPlanData } = useGetControlPlanData(jobId)

  const [
    getControlPlanChanges,
    { data: changesQuery, refetch: refetchControlPlanChanges },
  ] = useQualityControlPlanChangesSincePublishLazyQuery()

  const [
    getControlPlanPublishings,
    { data: controlPlanPublishesData, refetch: refetchControlPlanPublishes },
  ] = useQualityControlPlanPublishingsLazyQuery()

  const [
    getControlPlanHistory,
    { data: controlPlanHistory, refetch: refetchControlPlanHistory },
  ] = useQualityControlPlanHistoryLazyQuery()

  const {
    data: camPlanControlPlan,
    refetch: refetchCamPlanControlPlan,
  } = useCamPlanControlPlanInfoQuery({ variables: { planId: camPlanId } })

  const [updateCamPlanQcPlanRevision] = useUpdateCamPlanControlPlanRevisionMutation()

  const controlPlanId = controlPlan.id

  useEffect(() => {
    if (controlPlanId) {
      getControlPlanChanges({ variables: { camPlanId, controlPlanId } })
      getControlPlanPublishings({ variables: { camPlanId, controlPlanId } })
      getControlPlanHistory({ variables: { planId: controlPlanId } })
    }
  }, [
    controlPlanId,
    getControlPlanChanges,
    camPlanId,
    getControlPlanPublishings,
    getControlPlanHistory,
  ])

  const [revisions, setRevisions] = useState<Array<number | undefined>>([])

  const currentRevision =
    camPlanControlPlan?.camPlanControlPlanInfo.controlPlanId === controlPlanId
      ? camPlanControlPlan?.camPlanControlPlanInfo.controlPlanRevision ?? undefined
      : undefined

  const [
    getControlPlanFiles,
    { refetch: refetchControlPlanFiles },
  ] = useQualityControlPlanFilesLazyQuery()

  useEffect(() => {
    let controlPlanRevisions = controlPlanHistory?.qualityControlPlanPatchRecords?.nodes
    controlPlanRevisions = controlPlanRevisions ? [...controlPlanRevisions] : []
    controlPlanRevisions.reverse()

    setRevisions(controlPlanRevisions.map(r => r.revision))
  }, [controlPlanHistory])

  useEffect(() => {
    if (controlPlanId && currentRevision) {
      getControlPlanFiles({ variables: { controlPlanId, controlPlanRevision: currentRevision } })
    }
  }, [controlPlanId, currentRevision, getControlPlanFiles])

  useWebsocketMessageListener(wsMessage => {
    if (
      wsMessage.type === "TaskProgress" &&
      wsMessage.jobId === jobId &&
      wsMessage.name.endsWith("parse_drawing") &&
      wsMessage.status === "Success"
    ) {
      refetchControlPlan()
    }
  })

  const handleUpdateCamPlanQcRevision = (revision: number) => {
    if (controlPlanId) {
      updateCamPlanQcPlanRevision({
        variables: {
          camPlanId,
          controlPlanId,
          controlPlanRevision: revision,
        },
      }).then(() => {
        refetchCamPlanControlPlan().then(() => {
          refetchControlPlanData(revision)
        })
      })
    }
  }

  const controlPlanPublishes: QualityControlPlanPublishNodeFragment[] = useMemo(
    () => controlPlanPublishesData?.qualityControlPlanPublishes?.nodes ?? [],
    [controlPlanPublishesData]
  )

  const versionInfo = useMemo(() => {
    return getVersionInfo(controlPlanPublishes)
  }, [controlPlanPublishes])

  const hasPublish = !!versionInfo.currentPublish?.revision
  const noPublishChanges =
    changesQuery && !changesQuery.qualityControlPlanChangesSincePublish.hasChanges
  const noControlPlan =
    !camPlanControlPlan?.camPlanControlPlanInfo.controlPlanId ||
    camPlanControlPlan?.camPlanControlPlanInfo.controlPlanId !== controlPlanId ||
    !camPlanControlPlan?.camPlanControlPlanInfo.controlPlanRevision

  const handlePublishClick = useCallback(() => {
    const key = toaster.show({
      message: (
        <>
          Publishing:
          <ProgressBar />
        </>
      ),
    })

    planchangerApi
      .publishQualityControlPlan(camPlanId, true)
      .then(
        manifestLocations => {
          toaster.show(
            {
              message: (
                <>
                  Published to the following locations:
                  <Pre>
                    {manifestLocations.data.map((val, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {val}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </Pre>
                </>
              ),
              intent: "primary",
            },
            key
          )
        },
        (error: AxiosError) => {
          errorToast(error, "Error Publishing", key)
        }
      )
      .then(() => {
        refetchControlPlanPublishes({ camPlanId, controlPlanId })
        refetchControlPlanChanges({ camPlanId, controlPlanId })
      })
  }, [
    toaster,
    planchangerApi,
    camPlanId,
    controlPlanId,
    errorToast,
    refetchControlPlanPublishes,
    refetchControlPlanChanges,
  ])

  return (
    <>
      <div className={styles.container}>
        <>
          <div className={styles.rightCardIcons}>
            <UnlockQCCardButton locked={locked} assignees={assignees} />
          </div>

          <div className={styles.labelRow}>
            {controlPlan && (
              <H6>
                {jobDetails?.camJob?.label}-{controlPlan.data?.summary.partNumber}-
                {controlPlan.data?.summary.partRevisionLevel}
              </H6>
            )}
          </div>

          <div className={styles.topButtonsRow}>
            <QualityControlPlanUpload
              jobId={jobId}
              camPlanId={camPlanId}
              controlPlanId={controlPlanId}
              controlPlanRevision={currentRevision}
              locked={locked}
              onPlanUpload={() => {
                refetchControlPlan({ jobId })
                refetchControlPlanHistory({ planId: controlPlanId })
                refetchControlPlanChanges({ camPlanId, controlPlanId })
              }}
              onFileUpload={() => {
                refetchControlPlanFiles({
                  controlPlanId,
                  controlPlanRevision: currentRevision,
                })
              }}
            />
            <span>
              <span>Revision:</span>
              <HTMLSelect
                value={currentRevision || ""}
                onChange={e => {
                  handleUpdateCamPlanQcRevision(Number(e.target.value))
                }}
                disabled={editLocked}
              >
                {!currentRevision && <option value="">Select</option>}
                {revisions.map((val, idx) => (
                  <option disabled={val == currentRevision} value={val} key={idx}>
                    {val}
                  </option>
                ))}
              </HTMLSelect>
            </span>
          </div>

          <div className={styles.cardBottom}>
            <div className={styles.cardItems}>
              <QualityControlPlan
                jobId={jobId}
                revision={currentRevision}
                latestRevision={
                  currentRevision !== undefined && revisions.length - 1 === currentRevision
                }
                locked={locked}
                assignees={assignees}
                onUpdateControlPlan={() => {
                  refetchControlPlanHistory({ planId: controlPlanId })
                  refetchControlPlanChanges({ camPlanId, controlPlanId })
                }}
              />
              {controlPlanId && currentRevision && (
                <QualityControlPlanFiles
                  controlPlanId={controlPlanId}
                  controlPlanRevision={currentRevision}
                  locked={locked}
                />
              )}
            </div>
            <div className={styles.bottomButtonsRow}>
              <div className={styles.bottomLeftButton}>
                <AnchorButton
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if ((!noPublishChanges && !noControlPlan) || event.ctrlKey) {
                      handlePublishClick()
                    }
                  }}
                  className={
                    noPublishChanges || noControlPlan || editLocked ? Classes.DISABLED : ""
                  }
                >
                  {noPublishChanges && hasPublish
                    ? "Published"
                    : `Publish ${versionInfo.nextVersion}`}
                </AnchorButton>
                <VersionInfoDisplay publishInfo={versionInfo.currentPublish} />
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}
