import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Classes, Dialog, Intent } from "@blueprintjs/core"

import { MachineOffset, OffsetStateKind } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { getIntentStyle } from "src/pages/ViewerPage/MachineCoordsPanel/MachineCoordsPanel"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { RootState, useAppDispatch } from "src/store/rootStore"

import styles from "./OffsetsWarningBanner.module.css"

export const OffsetsWarningBanner: FC = () => {
  const { planchangerApi } = useApi()

  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const dispatch = useAppDispatch()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )
  const opOffsets = operation?.offsets ?? []
  const invalidOffsets = opOffsets.filter(value => value.currentState === OffsetStateKind.INVALID)

  const [bannerIntent, setBannerIntent] = useState<Intent>(Intent.NONE)
  const [showInvalidOffsets, setShowInvalidOffsets] = useState(false)

  let headerClassName = `${styles.headerContainer}`
  headerClassName = `${headerClassName} ${getIntentStyle(bannerIntent, styles)}`

  useEffect(() => {
    setBannerIntent(Intent.DANGER)
  }, [])

  const handleIgnoreAll = () => {
    const acknowledgedOffsets = opOffsets.map((value: MachineOffset) => {
      return {
        ...value,
        currentState:
          value.currentState === OffsetStateKind.VALID
            ? value.currentState
            : OffsetStateKind.ACKNOWLEDGED,
      }
    })
    dispatch(
      storedPlanThunks.setOffsets({
        planId,
        operationIdx,
        planchangerApi,
        offsets: acknowledgedOffsets,
      })
    )
    setShowInvalidOffsets(false)
  }

  return (
    <div>
      {showInvalidOffsets && (
        <Dialog
          icon={"error"}
          onClose={() => {
            setShowInvalidOffsets(false)
          }}
          title={"Invalid Offsets"}
          isOpen
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            The following offsets were detected as outdated:
            <ul>
              {invalidOffsets.map((value, i) => {
                return (
                  <li key={i}>
                    {value.kind} offset {value.previousTool ?? "None"}-{value.currentTool}-
                    {value.nextTool ?? "None"}-{value.instanceIndex}-{value.instanceCount}
                    {value.machineInstance.length > 0 && " for " + value.machineInstance}
                  </li>
                )
              })}
            </ul>
            <br />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={handleIgnoreAll}>Ok</Button>
            </div>
          </div>
        </Dialog>
      )}
      {invalidOffsets.length > 0 && (
        <div className={headerClassName}>
          <div className={styles.overTravel}>
            There are {invalidOffsets.length} machine controls that are no longer valid.
          </div>
          <Button onClick={() => setShowInvalidOffsets(true)} intent="primary">
            Fix Offsets
          </Button>
        </div>
      )}
    </div>
  )
}
