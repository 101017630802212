import { useEffect, useMemo } from "react"
import { v4 as uuidv4 } from "uuid"

import { useMainCanvas } from "src/hooks/transferCanvas/useMainCanvas"
import { useTransferCanvas } from "src/hooks/transferCanvas/useTransferCanvas"

export function useTransferCanvasResizeListener(resizeHandler: () => void): void {
  const { resizeHandlers } = useMainCanvas()
  const { ctx } = useTransferCanvas()
  const uuid = useMemo(() => {
    return uuidv4()
  }, [])

  useEffect(() => {
    const handlers = resizeHandlers.current
    if (!(ctx.canvas.id in handlers)) {
      handlers[ctx.canvas.id] = {}
    }

    const handler = handlers[ctx.canvas.id]
    if (handler === null) return
    handler[uuid] = resizeHandler
    return () => {
      delete handler[uuid]
    }
  }, [ctx, resizeHandlers, uuid, resizeHandler])
}
