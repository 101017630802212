import { useEffect, useMemo, useState } from "react"

import { PointsFeatureKindEnum, PointsFeatureMove, PointsFeaturePoint } from "src/client-axios"
import { useHandleAddSteps } from "src/hooks/useHandleAddSteps"
import { activeActions } from "src/store/cam/active"
import { probingActions } from "src/store/cam/probing"
import { useAppDispatch } from "src/store/rootStore"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const usePreviousNextProbeStep: () => {
  onPreviousNextProbeStep: (
    selectedProbeStep: WcsProbingStep | ProbingStep | undefined,
    offset?: number
  ) => void
} = () => {
  const { selectedSection } = useHandleAddSteps()
  const dispatch = useAppDispatch()
  const [probingStepsAndSubSteps, setProbingStepsAndSubSteps] = useState<
    (ProbingStep | WcsProbingStep | PointsFeatureMove | PointsFeaturePoint)[]
  >([])
  const probingSteps: (ProbingStep | WcsProbingStep)[] = useMemo(
    () => selectedSection?.steps ?? [],
    [selectedSection]
  )

  useEffect(() => {
    let probingStepsAndSubSteps: (
      | ProbingStep
      | WcsProbingStep
      | PointsFeatureMove
      | PointsFeaturePoint
    )[] = []
    probingSteps.forEach(step => {
      probingStepsAndSubSteps.push(step)
      if (step.kind === PointsFeatureKindEnum.PointsFeature && step?.steps?.length) {
        probingStepsAndSubSteps = [...probingStepsAndSubSteps, ...step.steps]
      }
    })

    setProbingStepsAndSubSteps(probingStepsAndSubSteps)
  }, [probingSteps])

  const onPreviousNextProbeStep = (
    selectedProbeStep: WcsProbingStep | ProbingStep | undefined,
    offset = -1
  ) => {
    if (!selectedProbeStep || probingStepsAndSubSteps.length < 2) return
    const selectedProbeStepIndex = probingStepsAndSubSteps.findIndex(
      step => step.id === selectedProbeStep.id
    )
    if (!probingStepsAndSubSteps[selectedProbeStepIndex + offset]) return
    dispatch(
      activeActions.setActiveProbeStepId(
        probingStepsAndSubSteps[selectedProbeStepIndex + offset].id
      )
    )
    dispatch(probingActions.setIsSliderChange(true))
  }
  return { onPreviousNextProbeStep }
}
