import { PartialVector, ToolpathProject } from "src/client-axios"
import {
  AlignmentWcsKind,
  ExplicitMoveKindEnum,
  PrecisionStrategy,
  Probing,
  WcsBoreBossKindEnum,
  WcsEdgeSurfaceKindEnum,
  WcsSpecifyCoordsKindEnum,
  WcsSurfaceDirection,
  WcsWebPocketKindEnum,
} from "src/client-axios/api"
import { WcsProbingStep } from "src/util/cam/probing/probingTypes"

/**
 * - Fields should be structured such that there is only a problem if the value is true
 * - All fields should be treated as false if not specified
 */
export interface PrePublishCheckResults {
  isCuttingUndefined: boolean
}

export interface ProbingValidation {
  simulated: boolean
  issuesResolved: boolean
}

export const getPrePublishCheckResults = (
  toolpathProject: ToolpathProject | undefined
): PrePublishCheckResults => {
  // Fundamental data presence checks
  const checkResults = {
    isCuttingUndefined: toolpathProject === undefined,
  }

  return checkResults
}

export const getArePublishIssuesPresent = (
  checkResults: PrePublishCheckResults,
  vericutValidated: boolean
): boolean => {
  if (!vericutValidated) return true

  return checkResults.isCuttingUndefined
}

/**
 * - Fields should be structured such that there is only a problem if the value is true
 * - All fields should be treated as false if not specified
 */
export interface PreReleaseCheckResults extends PrePublishCheckResults {
  // Overall probing
  isProbingUndefined: boolean

  // WCS
  isG54Missing: boolean
  hasMultipleG54s: boolean
  isG54Unconstrained: boolean

  // Alignments
  isExpectingMissingAlignment: boolean
  hasMultipleActiveAlignments: boolean

  // Inspections
  isExpectingMissingInspection: boolean
  hasMultipleActiveInspections: boolean
  isInspectionMissingCutterComps: boolean
}

const getDefaultReleaseCheckResults = (): PreReleaseCheckResults => {
  return {
    isProbingUndefined: false,
    isCuttingUndefined: false,

    isG54Missing: false,
    hasMultipleG54s: false,
    isG54Unconstrained: false,

    isExpectingMissingAlignment: false,
    hasMultipleActiveAlignments: false,

    isExpectingMissingInspection: false,
    hasMultipleActiveInspections: false,
    isInspectionMissingCutterComps: false,
  }
}

export const getAxesFromProbingSection = (steps: Array<WcsProbingStep>): PartialVector => {
  const value: PartialVector = {}
  steps.forEach(step => {
    if (step.kind === WcsSpecifyCoordsKindEnum.SpecifyCoords) {
      if (step.x !== undefined) {
        value.x = (value.x ?? 0) + step.x
      }
      if (step.y !== undefined) {
        value.y = (value.y ?? 0) + step.y
      }
      if (step.z !== undefined) {
        value.z = (value.z ?? 0) + step.z
      }
    } else if (step.kind === WcsBoreBossKindEnum.BoreBoss) {
      value.x = step.start.x
      value.y = step.start.y
    } else if (step.kind === WcsWebPocketKindEnum.WebPocket) {
      if (step.xSize !== undefined) {
        value.x = step.start.x
      }
      if (step.ySize !== undefined) {
        value.y = step.start.y
      }
    } else if (step.kind === WcsEdgeSurfaceKindEnum.EdgeSurface) {
      if (step.direction in [WcsSurfaceDirection.XNeg, WcsSurfaceDirection.XPos]) {
        value.x = step.contact.x
      } else if (step.direction in [WcsSurfaceDirection.YNeg, WcsSurfaceDirection.YPos]) {
        value.y = step.contact.y
      } else if (step.direction === WcsSurfaceDirection.ZNeg) {
        value.z = step.contact.z
      }
    }
  })

  return value
}

export const getPreReleaseCheckResults = (
  toolpathProject: ToolpathProject | undefined,
  probing: Probing | undefined,
  shouldBeAligned: boolean,
  shouldBeInspected: boolean
): PreReleaseCheckResults => {
  const checkResults = getDefaultReleaseCheckResults()

  const strategy: PrecisionStrategy | undefined = probing?.strategy

  // Fundamental data presence checks
  checkResults.isProbingUndefined = strategy === undefined
  checkResults.isCuttingUndefined = toolpathProject === undefined
  if (strategy === undefined) {
    return checkResults
  }

  // WCS checks
  checkResults.isG54Missing = true
  strategy?.wcsProbing.forEach(section => {
    if (section.disabled) return

    if (!checkResults.isG54Missing) {
      // If isG54Missing is false, that means another enabled G54 was already encountered
      checkResults.hasMultipleG54s = true
    }

    checkResults.isG54Missing = false
    const probingAxes = getAxesFromProbingSection(section.steps)
    if (probingAxes.x === undefined || probingAxes.y === undefined || probingAxes.z === undefined) {
      checkResults.isG54Unconstrained = true
    }
  })
  strategy?.alignments.forEach(section => {
    // Also look for WCS probing in the alignments
    if (section.disabled) return
    if (
      section.wcsKind !== AlignmentWcsKind.G54 &&
      section.wcsKind !== AlignmentWcsKind.AlignmentAndG54
    )
      return // Only consider alignments used for WCS probing here

    if (!checkResults.isG54Missing) {
      // If isG54Missing is false, that means another enabled G54 was already encountered
      checkResults.hasMultipleG54s = true
    }

    checkResults.isG54Missing = false
  })

  // Alignments checks
  let nActiveAlignments = 0
  if (shouldBeAligned) {
    checkResults.isExpectingMissingAlignment = true
  }
  strategy?.alignments.forEach(alignment => {
    if (alignment.disabled) return
    if (
      alignment.wcsKind === AlignmentWcsKind.G54 ||
      alignment.wcsKind === AlignmentWcsKind.Refinement
    )
      return // This will only update the G54, not do anything with an alignment

    nActiveAlignments++
    checkResults.isExpectingMissingAlignment = false
  })
  if (nActiveAlignments > 1) {
    checkResults.hasMultipleActiveAlignments = true
  }

  // Inspection checks:
  let nActiveInspections = 0
  let nInspectionStepsMissingCutterComps = 0
  if (shouldBeInspected) {
    checkResults.isExpectingMissingInspection = true
  }

  strategy?.inspections.forEach(inspection => {
    if (inspection.disabled) return

    nActiveInspections++
    checkResults.isExpectingMissingInspection = false

    inspection.steps.forEach(step => {
      if (step.kind === ExplicitMoveKindEnum.ExplicitMove) return

      if (!step.pointCompensations || step.pointCompensations.compensations.length === 0) {
        nInspectionStepsMissingCutterComps++
      }
    })
  })

  if (nActiveInspections > 1) {
    checkResults.hasMultipleActiveInspections = true
  }

  checkResults.isInspectionMissingCutterComps = nInspectionStepsMissingCutterComps > 0

  return checkResults
}

// Note: It would be nice if this function was somehow merged with the PrePublishFeedback component
// instead of duplicating the logic...
export const getAreReleaseIssuesPresent = (
  checkResults: PreReleaseCheckResults,
  vericutValidated: boolean,
  probingValidation: ProbingValidation
): boolean => {
  const arePublishIssuesPresent = getArePublishIssuesPresent(checkResults, vericutValidated)

  if (arePublishIssuesPresent) return true

  if (!probingValidation.simulated || !probingValidation.issuesResolved) return true

  if (checkResults.isG54Missing) return true
  if (checkResults.isProbingUndefined) return true
  if (checkResults.hasMultipleG54s) return true
  if (checkResults.isG54Unconstrained) return true
  if (checkResults.isExpectingMissingAlignment) return true
  if (checkResults.hasMultipleActiveAlignments) return true
  if (checkResults.isExpectingMissingInspection) return true
  if (checkResults.hasMultipleActiveInspections) return true
  if (checkResults.isInspectionMissingCutterComps) return true

  return false
}
