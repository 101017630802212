import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Intent } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2/lib/esm/tooltip2"

import { MachineRecord } from "src/client-axios"
import { activeOperationSelectors } from "src/store/cam/active"
import {
  ControllerState,
  MachineCoords,
  TravelLimitIntents,
  useMachineCoordsStore,
  useTravelLimitsStore,
} from "src/store/zustandMachine"

import styles from "./MachineCoordsPanel.module.css"

export const MachineCoordsPanel: FC<{
  showMachineCoords: boolean
}> = ({ showMachineCoords }) => {
  const [coords, setCoords] = useState<MachineCoords>()
  const machine = useSelector(activeOperationSelectors.selectActiveMachineRecord)
  const [travelLimitIntents, setTravelLimitIntents] = useState<Record<string, Intent>>({})
  const extraCoords = useMemo(() => {
    const extraCoords =
      coords &&
      Object.entries(coords.extraCoords).filter(extraCoord =>
        machine?.kinematics.axes.includes(extraCoord[0])
      )
    return extraCoords
  }, [coords, machine])

  useEffect(() => {
    return useTravelLimitsStore.subscribe(
      (state: TravelLimitIntents) => state.axes,
      (axes: Record<string, Intent>) => {
        setTravelLimitIntents(axes)
      },
      { fireImmediately: true }
    )
  }, [])

  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state.currentCoords,
      (currentCoords: MachineCoords) => {
        setCoords(currentCoords)
      },
      { fireImmediately: true }
    )
  }, [])

  if (!showMachineCoords || !coords || !extraCoords) return <></>

  return (
    <div className={styles.machineCoordsContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.machineCoordsHeader}>Machine Coordinates</div>
        <table className={styles.table}>
          <tbody>
            <tr className={getIntentStyle(travelLimitIntents.x, styles)}>
              <td>
                <Tooltip2
                  position="left"
                  content={getTravelLimitsDescription(machine, "x")}
                  openOnTargetFocus={false}
                >
                  X:
                </Tooltip2>
              </td>

              <td className={styles.coordValue}>{`${coords.x.toFixed(3)}`}</td>
            </tr>
            <tr className={getIntentStyle(travelLimitIntents.y, styles)}>
              <td>
                <Tooltip2
                  position="left"
                  content={getTravelLimitsDescription(machine, "y")}
                  openOnTargetFocus={false}
                >
                  Y:
                </Tooltip2>
              </td>
              <td className={styles.coordValue}>{`${coords.y.toFixed(3)}`}</td>
            </tr>
            <tr className={getIntentStyle(travelLimitIntents.z, styles)}>
              <td>
                <Tooltip2
                  position="left"
                  content={getTravelLimitsDescription(machine, "z")}
                  openOnTargetFocus={false}
                >
                  Z:
                </Tooltip2>
              </td>
              <td className={styles.coordValue}>{`${coords.z.toFixed(3)}`}</td>
            </tr>
            {extraCoords.map(([key, val]) => {
              return (
                <tr key={key} className={getIntentStyle(travelLimitIntents[key], styles)}>
                  <td>
                    <Tooltip2
                      position="left"
                      content={getTravelLimitsDescription(machine, key)}
                      openOnTargetFocus={false}
                    >
                      <>{key.toUpperCase()}:</>
                    </Tooltip2>
                  </td>
                  <td className={styles.coordValue}>{val.toFixed(3)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.blur} />
      <div className={styles.transparent} />
    </div>
  )
}

export const getIntentStyle = (intent: Intent, styles: CSSModuleClasses): string => {
  switch (intent) {
    case Intent.DANGER:
      return styles.danger
    case Intent.WARNING:
      return styles.warning
  }
  return ""
}

const getTravelLimitsDescription = (
  machine: MachineRecord | undefined,
  axisLabel: string
): string => {
  const axesTravelLimits = machine?.kinematics.travelLimits
  if (!axesTravelLimits) return ""
  const limits = axesTravelLimits[axisLabel]
  if (!limits) return ""
  return `Travel Limits: ${limits.min.toFixed(0)} to ${limits.max.toFixed(0)}`
}
