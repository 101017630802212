import React, { FC, useEffect, useState } from "react"
import { Button, NonIdealState } from "@blueprintjs/core"
import axios from "axios"

import { ProxiedJiraAuthRequest } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"

import styles from "./JiraCallbackPage2.module.css"

export const JiraCallbackPage2: FC = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const code = searchParams.get("code")
  const { jiraApi } = useApi()

  const { setSettings } = useLocalStorageSettings()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const options = {
      headers: {
        "content-type": "application/json",
      },
    }

    if (code) {
      const req: ProxiedJiraAuthRequest = {
        host: window.location.origin,
        grantType: "authorization_code",
        code,
      }

      jiraApi.getJiraToken(req, options).then(response => {
        setSettings({
          jiraAccessToken: response.data.accessToken,
          jiraRefreshToken: response.data.refreshToken,
        })

        const options = {
          headers: {
            Authorization: `Bearer ${response.data.accessToken}`,
            Accept: "application/json",
          },
        }

        axios
          .get(`https://api.atlassian.com/oauth/token/accessible-resources`, options)
          .then((response: { data: { id: string }[] }) => {
            setSettings({
              cloudId: response.data?.[0]?.id,
            })
            setIsAuthenticated(true)
          })
      })
    }
  }, [code, setSettings, jiraApi])

  return (
    <div className={styles.container}>
      {isAuthenticated ? (
        <NonIdealState
          title={"Connected to Jira"}
          description={
            <>
              You may now <Button onClick={() => window.close()}>close this tab</Button>
            </>
          }
        ></NonIdealState>
      ) : (
        <></>
      )}
    </div>
  )
}
