import React, { FC, useState } from "react"
import { Button, Switch } from "@blueprintjs/core"

import { ParametricStockKindEnum, ParametricStockShapeRectangularKindEnum } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { defaultTransform } from "src/util/geometry/transforms"
import { ProductStockSelector } from "../../../PlanTreeItem/ProductStockItem/ProductStockSelector/ProductStockSelector"
import { DefaultTolerances } from "../InputStockConfigPanel/InputStockConfigPanel"

import styles from "./OutputStockConfigPanel.module.css"

export const OutputStockConfigPanel: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
}> = ({ planId, operationIdx, locked }) => {
  const [showOverride, setShowOverride] = useState(false)
  const { tasksApi } = useApi()
  const toaster = useToaster()

  if (operationIdx === 0) {
    console.error("Output Stock config on the first operation, this is invalid!")
    return <></>
  }

  const onSubmit = () => {
    tasksApi.filterOutputStockFloatingComponents(planId, operationIdx).catch(err => {
      console.error(err)
      toaster.show({ message: `Filtering output stock: ${err}`, intent: "danger" })
    })
  }

  return (
    <div className={styles.container}>
      <p>
        <Button disabled={locked} icon="filter" onClick={onSubmit}>
          Filter floating output stock components
        </Button>
      </p>
      <Switch
        checked={showOverride}
        disabled={locked}
        label="Reset Input Stock"
        onChange={() => {
          setShowOverride(!showOverride)
        }}
      />

      {showOverride === false ? (
        <>
          <p>
            This operation&apos;s input stock is currently the output of the previous operation.
          </p>
          <p>Enable the switch above to instead use a new, unmilled piece of stock.</p>
        </>
      ) : (
        <ProductStockSelector
          planId={planId}
          operationIdx={operationIdx}
          locked={locked}
          inputStock={{
            kind: ParametricStockKindEnum.Parametric,
            shape: {
              kind: ParametricStockShapeRectangularKindEnum.Rectangular,
              x: 0,
              y: 0,
            },
            cutLength: 0,
            xMinTolerance: DefaultTolerances.xMinTolerance,
            xMaxTolerance: DefaultTolerances.xMaxTolerance,
            yMinTolerance: DefaultTolerances.yMinTolerance,
            yMaxTolerance: DefaultTolerances.yMaxTolerance,
            cutLengthMinTolerance: DefaultTolerances.cutLengthMinTolerance,
            cutLengthMaxTolerance: DefaultTolerances.cutLengthMaxTolerance,
            diameterMinTolerance: DefaultTolerances.diameterMinTolerance,
            diameterMaxTolerance: DefaultTolerances.diameterMaxTolerance,
            transform: defaultTransform(),
          }}
        />
      )}
    </div>
  )
}
