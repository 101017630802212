import { v4 as uuidv4 } from "uuid"

import {
  BoreFeature,
  BoreFeatureKindEnum,
  BossFeature,
  BossFeatureKindEnum,
  EmptyToolchangeKindEnum,
  ExplicitMove,
  ExplicitMoveKindEnum,
  MultiLevelBoreFeature,
  MultiLevelBoreFeatureKindEnum,
  MultiLevelBossFeature,
  MultiLevelBossFeatureKindEnum,
  PointsFeature,
  PointsFeatureKind,
  PointsFeatureKindEnum,
  ProbeToolchangeKindEnum,
  ReferencePositionMoveKindEnum,
  SphereFeature,
  SphereFeatureKindEnum,
  WcsBoreBossKindEnum,
  WcsBossWebParams,
  WcsEdgeSurfaceKindEnum,
  WcsProbingMoveKindEnum,
  WcsSpecifyCoordsKindEnum,
  WcsSurfaceDirection,
  WcsWebPocketKindEnum,
} from "src/client-axios"
import { DEFAULT_APPROACH_OFFSET } from "src/store/cam/probing"
import { FeatureKind, WcsFeatureKind, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const getDefaultStep = ({
  kind,
  subkind,
  contactPoint,
  coords,
}: {
  kind: FeatureKind
  subkind?: PointsFeatureKind
  contactPoint?: [number, number, number]
  coords: {
    [x: string]: number
  }
}):
  | BoreFeature
  | BossFeature
  | MultiLevelBoreFeature
  | MultiLevelBossFeature
  | SphereFeature
  | ExplicitMove
  | PointsFeature => {
  const id = uuidv4()
  switch (kind) {
    case PointsFeatureKindEnum.PointsFeature:
      return {
        id,
        kind,
        subkind: subkind || PointsFeatureKind.Circle,
        steps: [],
      }

    case ExplicitMoveKindEnum.ExplicitMove: {
      const [x, y, z] = contactPoint || [0, 0, 0]
      return {
        kind,
        id,
        position: {
          point: { x, y, z },
          extraCoords: coords,
        },
      }
    }

    case BoreFeatureKindEnum.BoreFeature:
      return {
        kind,
        id,
        position: {
          point: { x: 0, y: 0, z: 0 },
          extraCoords: coords,
        },
        approach: { point: { x: 0, y: 0, z: 5 }, extraCoords: coords },
        diameter: 0,
      }

    case BossFeatureKindEnum.BossFeature:
      return {
        kind,
        id,
        position: {
          point: { x: 0, y: 0, z: 0 },
          extraCoords: coords,
        },
        approach: { point: { x: 0, y: 0, z: 5 }, extraCoords: coords },
        diameter: 0,
      }

    case MultiLevelBoreFeatureKindEnum.MultiLevelBoreFeature:
      return {
        kind,
        id,
        position: {
          point: { x: 0, y: 0, z: 0 },
          extraCoords: coords,
        },
        approach: { point: { x: 0, y: 0, z: 5 }, extraCoords: coords },
        diameter: 0,
        nLevels: 1,
        levelSpacing: 1,
      }

    case MultiLevelBossFeatureKindEnum.MultiLevelBossFeature:
      return {
        kind,
        id,
        position: {
          point: { x: 0, y: 0, z: 0 },
          extraCoords: coords,
        },
        approach: { point: { x: 0, y: 0, z: 5 }, extraCoords: coords },
        diameter: 0,
        nLevels: 1,
        levelSpacing: 1,
      }

    case SphereFeatureKindEnum.SphereFeature:
      return {
        kind,
        id,
        position: {
          point: { x: 0, y: 0, z: 0 },
          extraCoords: coords,
        },
        approach: { point: { x: 0, y: 0, z: 5 }, extraCoords: coords },
        diameter: 0,
        t: 9,
      }
  }
}

export const getNewWcsProbingStep = ({
  kind,
  contactPoint,
  diameter,
  direction,
  params,
  xSize,
  ySize,
}: {
  kind: WcsFeatureKind
  contactPoint?: [number, number, number]
  diameter?: number
  direction?: WcsSurfaceDirection
  params?: WcsBossWebParams
  xSize?: number
  ySize?: number
}): WcsProbingStep => {
  const id = uuidv4()

  const [x, y, z] = contactPoint || [0, 0, 0]

  switch (kind) {
    case WcsSpecifyCoordsKindEnum.SpecifyCoords:
      return {
        id,
        kind,
        x: undefined,
        y: undefined,
        z: undefined,
      }
    case WcsProbingMoveKindEnum.WcsExplicitMove:
      return {
        id,
        kind,
        point: { x, y, z },
      }

    case WcsBoreBossKindEnum.BoreBoss:
      return {
        id,
        kind,
        start: { x, y, z },
        diameter: diameter ?? 0,
        nIterations: 1,
        boss: params,
      }

    case WcsWebPocketKindEnum.WebPocket:
      return {
        id,
        kind,
        start: { x, y, z },
        web: params,
        ...(xSize !== undefined && { xSize }),
        ...(ySize !== undefined && { ySize }),
      }

    case WcsEdgeSurfaceKindEnum.EdgeSurface:
      return {
        id,
        kind,
        contact: { x, y, z },
        offset: DEFAULT_APPROACH_OFFSET,
        direction: direction || WcsSurfaceDirection.XNeg,
      }
    case ProbeToolchangeKindEnum.ProbeToolchange:
      return {
        id,
        kind,
      }
    case EmptyToolchangeKindEnum.EmptyToolchange:
      return {
        id,
        kind,
      }
    case ReferencePositionMoveKindEnum.ReferencePositionMove:
      return {
        id,
        kind,
        axes: {},
      }
  }
}
