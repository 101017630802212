import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Icon,
  PopoverPosition,
  Switch,
  Tag,
} from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist"
import workerURL from "pdfjs-dist/build/pdf.worker.js?url"
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/display/api"

import { Compensation, ExplicitMoveKindEnum } from "src/client-axios"
import { Dimension } from "src/client-axios"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { TaskStatus } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useEventListener } from "src/hooks/useEventListener"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { getDimDescription } from "src/pages/DrawingViewer/description"
import { Bubble, DifficultyGroup, ShapeData } from "src/pages/DrawingViewer/interfaces"
import { dimLabel } from "src/pages/DrawingViewer/util/dim"
import { bboxKey, getBubble } from "src/pages/DrawingViewer/util/dimCollection"
import { downloadBubbledPdf } from "src/pages/DrawingViewer/util/pdf"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { CalloutTable } from "./CalloutTable/CalloutTable"
import { InspectionIcon } from "./InspectionIcon/InspectionIcon"
import { getSortedShapes, useGetDimsData } from "./useGetDimsData"

import styles from "./DrawingViewer.module.css"

GlobalWorkerOptions.workerSrc = workerURL

const ZOOM_CHOICES = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4]

export type ToolLabel = string
export interface InspectionData {
  [dimLabel: string]: { [opLabel: string]: Set<ToolLabel> }
}

export const DrawingViewer: FC<{
  hideTable?: boolean
  inDialog?: boolean
  showInspection?: boolean
  allGreenCheckMarks?: boolean
  onSelect?: (shape: ShapeData) => void
  showControls?: boolean
}> = ({ hideTable, inDialog, showInspection, showControls, allGreenCheckMarks, onSelect }) => {
  const { drawingId: drawingIdFromParams } = useParams<{
    drawingId?: string
  }>()

  const params = new URLSearchParams(window.location.search)
  const paramPage = params.get("page")
  const paramsSelectedShape = params.get("selectedShape")
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { planchangerApi } = useApi()

  const [zoom, setZoom] = useState(1.0)
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | undefined>()
  const [pdfPage, setPdfPage] = useState<PDFPageProxy | undefined>()
  const [curPageIndex, setPageIndex] = useState(paramPage !== null ? +paramPage - 1 : 0)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [showDimCodes, setShowDimCodes] = useState(true)
  const [showBasicDims, setShowBasicDims] = useState(false)
  const [showReferenceDims, setShowReferenceDims] = useState(false)
  const [justCopied, setJustCopied] = useState<string | undefined>()
  const {
    allShapes,
    filteredShapes,
    drawingFileId,
    drawingLocator,
    jobLabel,
    loading,
    refetch,
    drawingId,
  } = useGetDimsData({
    drawingId: drawingIdFromParams,
    filters: { includeBasicDims: showBasicDims, includeReferenceDims: showReferenceDims },
  })

  const filename = useMemo(() => getBubbledDrawingFilename(jobLabel, drawingLocator), [
    jobLabel,
    drawingLocator,
  ])

  useEffect(() => {
    if (justCopied) setTimeout(() => setJustCopied(undefined), 3000)
  }, [justCopied])

  // useEffect
  const bubbles: Bubble[] = useMemo(() => {
    return filteredShapes.flatMap(shape => {
      const bubble = getBubble(shape)
      return bubble ? [bubble] : []
    })
  }, [filteredShapes])

  const [dimScale, setScale] = useState(1.0)
  const [hoveredShape, setHoveredShape] = useState<number | undefined>()

  const [isRendering, setRendering] = useState(false)

  const selectedRow = useRef<HTMLTableRowElement>(null)
  const selectedBBox = useRef<HTMLDivElement>(null)

  const toaster = useToaster()

  useEffect(() => {
    planchangerApi.urlFor_getFile(undefined, drawingFileId).then(result => {
      setDownloadUrl(result.toString())
    })
  }, [drawingFileId, planchangerApi])

  useEffect(() => {
    selectedRow.current?.scrollIntoView({
      block: "center",
    })
    selectedBBox.current?.scrollIntoView({
      block: "center",
    })
  }, [paramsSelectedShape, isRendering])

  // Jump to a shape's page on selection
  useEffect(() => {
    const shape = filteredShapes.find(shape => shape.shapeId === Number(paramsSelectedShape))
    if (shape && curPageIndex !== shape.location.pageIndex) {
      setPageIndex(shape.location.pageIndex)
    }
  }, [curPageIndex, filteredShapes, paramsSelectedShape])

  const renderPdf = useCallback(() => {
    const canvas = canvasRef.current

    if (canvas && pdfPage && !isRendering && canvas.parentElement) {
      const viewport = pdfPage.getViewport({ scale: 1 / zoom })
      const scale = canvas.parentElement.clientWidth / viewport.width

      canvas.width = viewport.width * scale * zoom
      canvas.height = viewport.height * scale * zoom

      // HighQA is in 96dpi..., PdfJS is in 72dpi....
      setScale(scale / 1.3333)

      setRendering(true)
      pdfPage
        .render({
          // eslint-disable-next-line
          canvasContext: canvas.getContext("2d") as any,
          viewport: pdfPage.getViewport({ scale }),
        })
        .promise.then(() => {
          setRendering(false)
        })
    }
  }, [isRendering, pdfPage, zoom])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const page = params.get("page")

    if (page === null || +page !== curPageIndex + 1) {
      params.set("page", curPageIndex + 1 + "")

      window.history.replaceState({}, "", `?${params.toString()}`)
    }
  }, [curPageIndex])

  useEffect(() => {
    return () => {
      const params = new URLSearchParams(window.location.search)
      if (params.get("selectedShape")) {
        params.delete("selectedShape")
      }
      window.history.replaceState({}, "", `?${params.toString()}`)
    }
  }, [])

  useEffect(() => {
    renderPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfPage, zoom])

  useEffect(() => {
    if (pdfDoc) {
      const loadPage = async () => {
        const page = await pdfDoc.getPage(curPageIndex + 1)
        setPdfPage(page)
      }

      loadPage().catch(err => {
        console.error(err)
      })
    }
  }, [pdfDoc, curPageIndex])

  useEventListener("resize", renderPdf)
  useEventListener("wheel", event => {
    const pdfDrawingElement = document.getElementById("pdf-drawing-element")
    const pdfDrawingCanvas = document.getElementById("pdf-drawing-canvas")
    const pdfDrawingElementRect = pdfDrawingElement?.getBoundingClientRect()
    const pdfDrawingCanvasRect = pdfDrawingCanvas?.getBoundingClientRect()

    const e = event as WheelEvent

    if (e.deltaY > 0) {
      if (
        pdfDrawingElementRect &&
        pdfDrawingCanvasRect &&
        e.clientX > pdfDrawingElementRect.left &&
        e.clientY < pdfDrawingElementRect.right &&
        pdfDrawingCanvasRect.bottom < pdfDrawingElementRect.bottom
      ) {
        nextPage()
      }
    } else if (e.deltaY < 0) {
      if (
        pdfDrawingElementRect &&
        pdfDrawingCanvasRect &&
        e.clientX > pdfDrawingElementRect.left &&
        e.clientY < pdfDrawingElementRect.right &&
        pdfDrawingCanvasRect.top >= pdfDrawingElementRect.top
      ) {
        prevPage()
      }
    }
  })

  const removeSelectedShapeParam = () => {
    const params = new URLSearchParams(window.location.search)
    params.delete("selectedShape")
    window.history.replaceState({}, "", `?${params.toString()}`)
  }

  const nextPage = () => {
    if (pdfDoc) {
      const nextPageIndex = curPageIndex + 1
      if (nextPageIndex < pdfDoc.numPages) {
        removeSelectedShapeParam()
        setPageIndex(nextPageIndex)
      }
    }
  }

  const prevPage = () => {
    if (pdfDoc) {
      const prevPageIndex = curPageIndex - 1
      if (prevPageIndex >= 0) {
        removeSelectedShapeParam()
        setPageIndex(prevPageIndex)
      }
    }
  }

  const reorderDims = () => {
    setIsRefreshing(true)
    if (drawingId === undefined) return

    const sortedShapes = getSortedShapes(allShapes)

    const remapping: { [key: string]: string } = {}
    sortedShapes.forEach((shape, shapeIndex) => {
      // Note: if we wanted to sort the subdims, we could compute individual dim safety factors
      shape.dims.forEach((dim, subIndex) => {
        const subDimCode = shape.dims.length > 1 ? `.${subIndex + 1}` : ``
        const newDimCode = `${shapeIndex + 1}${subDimCode}`
        const dimCode = dim.dimCode
        if (dimCode && newDimCode !== dimCode) {
          remapping[dimCode] = newDimCode
        }
      })
    })

    planchangerApi
      .remapDims(drawingId, remapping)
      .then(val => {
        const progress = val.data

        if (progress.status !== TaskStatus.Success) {
          toaster.show({
            message: `Error Reordering dims: ${progress.message}`,
            intent: "danger",
          })
          setIsRefreshing(false)
        } else {
          refetch?.().then(() => {
            toaster.show({ message: "Reordered dims!", intent: "success" })
            setIsRefreshing(false)
          })
        }
      })
      .catch((error: AxiosError) => {
        errorToast(error, "Failed to reorder dims")
        setIsRefreshing(false)
      })
  }

  const refreshDims = () => {
    setIsRefreshing(true)
    if (drawingId === undefined) return

    planchangerApi
      .refreshDrawing(drawingId)
      .then(val => {
        const progress = val.data

        if (progress.status !== TaskStatus.Success) {
          toaster.show({
            message: `Error refreshing dims: ${progress.message}`,
            intent: "danger",
          })
          setIsRefreshing(false)
        } else {
          refetch?.().then(() => {
            toaster.show({ message: "Refreshed dims!", intent: "success" })
            setIsRefreshing(false)
          })
        }
      })
      .catch((error: AxiosError) => {
        errorToast(error, "Refresh dims error")
        setIsRefreshing(false)
      })
  }

  const errorToast = useNetworkErrorToast()
  const activeJobId = useSelector(activeSelectors.selectActiveJobId)
  const uploadDrawing = () => {
    if (activeJobId !== undefined) {
      const key = toaster.show({
        message: "Publishing Drawing...",
        timeout: 0,
        icon: "export",
      })
      planchangerApi
        .publishDrawing(activeJobId)
        .then(val => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const manifest: any = val.data

          toaster.show(
            {
              message: (
                <>
                  Drawing Published!
                  <pre className={"bp3-code-block"}>{manifest.uri}</pre>
                </>
              ),
              intent: "success",
              icon: "export",
              timeout: 20000,
            },
            key
          )
        })
        .catch(e => {
          errorToast(e, "Error publishing drawing", key)
        })
    } else {
      console.warn("No JobID")
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current

    if (canvas && drawingFileId) {
      const loadDoc = async () => {
        const url = await planchangerApi.urlFor_getFile(undefined, drawingFileId)
        const pdf = await getDocument(url).promise
        setPdfDoc(pdf)
      }

      loadDoc().catch(val => {
        console.error(val)
      })
    }
  }, [planchangerApi, drawingFileId])

  const incrementZoom = () => {
    const newZoom = ZOOM_CHOICES.find(zoomValue => zoomValue > zoom)
    newZoom && setZoom(newZoom)
  }

  const decrementZoom = () => {
    const newZoom = [...ZOOM_CHOICES].reverse().find(zoomValue => zoomValue < zoom)
    newZoom && setZoom(newZoom)
  }
  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  const operations = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperations(state, activePlanId)
  )

  const settingsPopover = (
    <div className={styles.settings}>
      {showControls && (
        <>
          <Tooltip2
            hoverOpenDelay={300}
            content={"Reorder the dims based on difficulty, and synchronize with HighQA"}
            openOnTargetFocus={false}
          >
            <AnchorButton
              icon="sort-desc"
              minimal
              disabled={isRefreshing || loading || isRendering}
              onClick={reorderDims}
            >
              Reorder Dims
            </AnchorButton>
          </Tooltip2>
          <Tooltip2
            hoverOpenDelay={300}
            position={PopoverPosition.BOTTOM}
            content={"Retrieve any new changes made in HighQA"}
            openOnTargetFocus={false}
          >
            <AnchorButton
              icon="refresh"
              minimal
              disabled={isRefreshing || loading || isRendering}
              onClick={refreshDims}
            >
              Refresh Dims
            </AnchorButton>
          </Tooltip2>
        </>
      )}
      <Switch
        className={styles.dimCodesSwitch}
        label="Show Dim Codes"
        checked={showDimCodes}
        onChange={() => {
          setShowDimCodes(!showDimCodes)
        }}
      />
      <Switch
        label="Show Basic Dims"
        checked={showBasicDims}
        onChange={() => {
          setShowBasicDims(!showBasicDims)
        }}
      />
      <Switch
        label="Show Reference Dims"
        checked={showReferenceDims}
        onChange={() => {
          setShowReferenceDims(!showReferenceDims)
        }}
      />
      <AnchorButton
        className={styles.publishIcon}
        icon="export"
        minimal
        onClick={() => uploadDrawing()}
      >
        Publish Drawing
      </AnchorButton>
    </div>
  )

  const dimDifficultyGroups = useMemo(() => {
    const result: Record<string, DifficultyGroup> = {}
    allShapes.forEach(shape => {
      result[shape.label] = shape.scoring.difficultyGroup
    })
    return result
  }, [allShapes])

  const dimInspectionData: InspectionData = useMemo(() => {
    const result: InspectionData = {}
    operations?.forEach(op => {
      if (!op.probing?.strategy.inspections) return
      op.probing?.strategy.inspections.forEach(inspection => {
        inspection.steps.forEach(step => {
          if (step.kind === ExplicitMoveKindEnum.ExplicitMove) return
          const dimLabel = step.tolerances?.label
          if (!dimLabel) return
          const existingDimData = result[dimLabel] ?? {}
          const existingDimOpData = existingDimData[op.label] ?? new Set()

          step.pointCompensations?.compensations.forEach(compensation => {
            existingDimOpData.add(getToolCompensationLabel(compensation))
          })
          existingDimData[op.label] = existingDimOpData
          result[dimLabel] = existingDimData
        })
      })
    })
    return result
  }, [operations])

  return (
    <div className={styles.drawingViewer}>
      <div className={styles.toolbar}>
        <span>
          <ButtonGroup minimal={true}>
            <Button
              title={"Previous Page"}
              disabled={pdfDoc === undefined || pdfDoc.numPages === 1}
              icon={"arrow-left"}
              onClick={prevPage}
            />
            <FormulaInput
              className={styles.pageNumberInput}
              commitOnBlur={true}
              onValueChange={value => setPageIndex(value - 1)}
              value={curPageIndex + 1}
              min={1}
              max={pdfDoc?.numPages}
              buttonPosition="none"
            />
            <div className={styles.slash}>/</div>
            <div className={styles.numPages}>{pdfDoc?.numPages}</div>
            <Button
              title={"Next Page"}
              disabled={pdfDoc === undefined || pdfDoc.numPages === 1}
              rightIcon={"arrow-right"}
              onClick={nextPage}
            />
            <Button
              className={styles.zoomOut}
              title={"Zoom Out"}
              disabled={zoom <= 0.25 || isRendering}
              rightIcon={"zoom-out"}
              onClick={decrementZoom}
            />
            <FormulaInput
              className={styles.zoomPercentInput}
              commitOnBlur={true}
              onValueChange={value => setZoom(value / 100)}
              value={zoom * 100}
              min={25}
              max={400}
              buttonPosition={"none"}
            />
            <Button
              title={"Zoom In"}
              disabled={zoom >= 4 || isRendering}
              rightIcon={"zoom-in"}
              onClick={incrementZoom}
            />
          </ButtonGroup>
        </span>

        <div>
          <Tooltip2
            content={"Download"}
            openOnTargetFocus={false}
            position={PopoverPosition.BOTTOM}
          >
            <Button
              className={styles.downloadIcon}
              icon={"import"}
              minimal
              onClick={() => downloadBubbledPdf(downloadUrl, filename, bubbles)}
            />
          </Tooltip2>

          <Popover2 position={PopoverPosition.LEFT_BOTTOM} content={settingsPopover}>
            <Tooltip2
              content={"Settings"}
              position={PopoverPosition.BOTTOM}
              openOnTargetFocus={false}
            >
              <Icon icon="cog" className={styles.cogIcon} />
            </Tooltip2>
          </Popover2>
        </div>
      </div>
      <div className={styles.drawingHorizontal}>
        {!hideTable && (
          <CalloutTable
            hideTable={hideTable}
            inDialog={inDialog}
            showInspection={showInspection}
            allGreenCheckMarks={allGreenCheckMarks}
            onSelect={onSelect}
            setHoveredShape={setHoveredShape}
          />
        )}
        <div className={styles.drawing} id="pdf-drawing-element">
          <div className={styles.pdfCanvasParent}>
            <canvas className={styles.pdfCanvas} ref={canvasRef} id="pdf-drawing-canvas" />
          </div>
          {filteredShapes
            .filter(shape => shape.location.pageIndex === curPageIndex)
            .map(shape => (
              <div
                key={bboxKey(shape.location)}
                ref={shape.shapeId === Number(paramsSelectedShape) ? selectedBBox : undefined}
                onMouseEnter={() => {
                  setHoveredShape(shape.shapeId)
                }}
                onMouseLeave={() => {
                  setHoveredShape(undefined)
                }}
                onClick={() => {
                  if (Number(paramsSelectedShape) === shape.shapeId) {
                    removeSelectedShapeParam()
                  } else {
                    onSelect?.(shape)
                    const params = new URLSearchParams(window.location.search)
                    params.set("selectedShape", shape.shapeId + "")
                    window.history.replaceState({}, "", `?${params.toString()}`)
                  }
                }}
                style={{
                  top: shape.location.topLeft.y * dimScale,
                  left: shape.location.topLeft.x * dimScale,
                  minWidth: shape.location.extents.x * dimScale,
                  minHeight: shape.location.extents.y * dimScale,
                }}
                className={getShapeBboxStyle(shape, Number(paramsSelectedShape), hoveredShape)}
              >
                {showDimCodes && (
                  <div className={styles.bboxInner}>
                    <div
                      style={{ display: "flex" }}
                      className={getDimLabelStyle(
                        shape.scoring.difficultyGroup,
                        shape.shapeId === Number(paramsSelectedShape)
                      )}
                    >
                      {dimLabel(shape.dims)}
                      {showInspection && (
                        <InspectionIcon
                          inspectionData={dimInspectionData}
                          difficultyGroups={dimDifficultyGroups}
                          shapeTitle={shape.label}
                          inPdf
                          tooltip
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export const DimRequirement: FC<{
  dims: Dimension[]
  className?: string
}> = ({ dims, className }) => {
  return (
    <span className={`${styles.dimRequirement} ${className ?? ""}`}>
      {dims.map(val => (
        <React.Fragment key={val.dimCode}>
          <Tooltip2 content={getDimDescription(val)} openOnTargetFocus={false}>
            {val.dimRequirement}
          </Tooltip2>
          <br />
        </React.Fragment>
      ))}
    </span>
  )
}

export const DimRequirementTag: FC<{
  group: DifficultyGroup | "dbox-danger" | "dbox-danger-alignment"
  label: string
  className?: string
}> = ({ group, label, className }) => {
  return <Tag className={`${styles[group]} ${className ?? ""}`}>{label}</Tag>
}

const getShapeBboxStyle = (
  shape: ShapeData,
  selectedShapeId: number | undefined,
  hoveredShapeId: number | undefined
): string => {
  let className = `${styles.bbox}`
  const {
    shapeId,
    scoring: { difficultyGroup },
  } = shape

  const stylesRecord = (styles as unknown) as Record<string, string>

  const isARequirement = !(shape.isBasic || shape.isReference)

  if (isARequirement && difficultyGroup !== DifficultyGroup.gray) {
    className += ` ${stylesRecord["box-" + difficultyGroup]}`
    className += ` ${stylesRecord["dbox-" + difficultyGroup]}`
  }

  if (shapeId === hoveredShapeId) {
    className += ` ${styles.hovered}`
    if (isARequirement && difficultyGroup === DifficultyGroup.gray) {
      className += ` ${stylesRecord["box-gray-selected"]}`
    }
  }

  if (shapeId === selectedShapeId) {
    className += ` ${styles.selected}`
    if (isARequirement && difficultyGroup === DifficultyGroup.gray) {
      className += ` ${stylesRecord["box-gray-selected"]}`
    }
  } else if (selectedShapeId !== undefined) {
    className += ` ${styles.notSelected}`
  }
  return className
}

const getDimLabelStyle = (group: DifficultyGroup, isSelected: boolean): string => {
  let className = `${styles.dimCode} ${styles[group]}`
  if (isSelected) {
    className += ` ${styles.selectedDimCode}`
  }
  return className
}

const getBubbledDrawingFilename = (
  jobLabel: string | undefined,
  drawingLocator: string | undefined
) => {
  let stem = jobLabel ?? "drawing" // If the job label can't be found, just use the stem "drawing"

  const locatorComponents = (drawingLocator ?? "").split("/")
  const basename = locatorComponents[locatorComponents.length - 1]
  const basenameComponents = basename.split(".")
  if (basenameComponents.length === 1) {
    if (basenameComponents[0].length > 0) {
      stem = `${stem}-${basenameComponents[0]}`
    }
  } else {
    if (basenameComponents[basenameComponents.length - 1].toLowerCase() === "pdf") {
      basenameComponents.pop()
    }
    stem = `${stem}-${basenameComponents.join(".")}`
  }

  return `${stem}-bubbled.pdf`
}

const getToolCompensationLabel = ({ toolNumber, offsetKind }: Compensation): string => {
  return `T${toolNumber}${
    offsetKind === 0 ? "N" : offsetKind === 1 ? "L" : offsetKind === 2 ? "R" : ""
  }`
}
