import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { batch, useSelector } from "react-redux"
import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core"
import { H6 } from "@blueprintjs/core/lib/esm/components"
import { ContextMenu2, Tooltip2 } from "@blueprintjs/popover2"
import { useAuth, useCurrentUserData } from "@subscale/formlogic-auth-ts"
import copy from "copy-to-clipboard"
import { format } from "date-fns"
import { Maybe } from "graphql/jsutils/Maybe"
import { v4 as uuidv4 } from "uuid"

import {
  ClipboardSetupLocator,
  ClipboardSetupLocatorKindEnum,
  ProbingIssue,
} from "src/client-axios/api"
import { ProvidedCreateOperationMenuItems } from "src/components/Cam/CreateOperationMenuItems"
import { DeleteOperationModal } from "src/components/Cam/DeleteOperationModal/DeleteOperationModal"
import { OperationLabel, OperationTimeLabel } from "src/components/Cam/Labels/Labels"
import { NotesOperationModal } from "src/components/Cam/NotesOperationModal/NotesOperationModal"
import { OpChangesIcon } from "src/components/Cam/OpChangesIcon/OpChangesIcon"
import { OpControlsIcon } from "src/components/Cam/OpControlsIcon/OpControlsIcon"
import { ConfigPanelSelect } from "src/components/Cam/TreeNavigator/ConfigPanel/props"
import { NcProgramUpload } from "src/components/Cam/TreeNavigator/PlanTreeItem/OperationTreeItem/Items/NcProgramsTreeItem/NcProgramUpload/NcProgramUpload"
import { UnlockButton } from "src/components/Cam/UnlockButton/UnlockButton"
import { TransferCanvas } from "src/components/Canvas/TransferCanvas/TransferCanvas"
import { OperationCardScene } from "src/components/Canvas/Viewer/Scene/Cam/CamScene/OperationCardScene"
import { PasteMenuItem } from "src/components/Generic/Clipboard/PasteMenuItem/PasteMenuItem"
import { AnnotationButtons } from "src/components/Shared/AnnotationButtons/AnnotationButtons"
import { sendEvent } from "src/components/Websocket/Websocket"
import {
  MesPublishNodeFragment,
  PlanGenerationFeedback,
  SceneModelFragment,
  useCamJobDetailsQuery,
  useCamPlanChangesSincePublishLazyQuery,
  useMesPublishingsQuery,
  useRevertCamPlanToRevisionMutation,
  useVericutFeedbackQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useGetProbingFeedback } from "src/hooks/useGetProbingFeedback"
import { SceneClickData } from "src/hooks/useSceneClickEventHandler"
import { useToaster } from "src/hooks/useToaster"
import { ManufacturingDialog } from "src/pages/JobOverview/OperationCard/ManufacturingDialog/ManufacturingDialog"
import { activeActions } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode, viewerModalSelectors } from "src/store/ui/viewerModal"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { CamButton } from "./CamButton/CamButton"
import { GuardrailInfo } from "./GuardrailInfo/GuardrailInfo"
import { NcProgramsInfo } from "./NcProgramInfo/NcProgramInfo"

import styles from "./OperationCard.module.css"

interface PublishInfo {
  version: string
  revision: number
  timestamp: Date
  isProveout: boolean
}

const getNextVersions = ({
  version: currentVersion,
  isProveout,
}: {
  version?: string
  isProveout: boolean
}): {
  nextCamVersion: string
  nextManufacturingVersion: string
  nextCamIsProveout: boolean
  nextManufacturingIsProveout: boolean
} => {
  let nextCamVersion = "1.1"
  let nextManufacturingVersion = "1.1.1"
  const nextCamIsProveout = true
  let nextManufacturingIsProveout = true

  if (currentVersion) {
    if (currentVersion.match(/^[0-9]+\.[0-9]+$/g)) {
      const major = currentVersion.split(".")[0]
      const minor = currentVersion.split(".")[1]
      nextCamVersion = `${major}.${Number.parseInt(minor) + 1}`
      nextManufacturingVersion = `${major}.${minor}.1`
    } else if (currentVersion.match(/^[0-9]+\.[0-9]+\.[0-9]+$/g)) {
      const major = currentVersion.split(".")[0]
      const minor = currentVersion.split(".")[1]
      const patch = currentVersion.split(".")[2]
      nextCamVersion = `${major}.${Number.parseInt(minor) + 1}`
      nextManufacturingVersion = `${major}.${minor}.${Number.parseInt(patch) + 1}`
      nextManufacturingIsProveout = isProveout
    }
  }

  return {
    nextCamVersion,
    nextManufacturingVersion,
    nextCamIsProveout,
    nextManufacturingIsProveout,
  }
}

interface VersionInfo {
  currentCam?: PublishInfo
  currentManufacturing?: PublishInfo
  nextCamVersion: string
  nextManufacturingVersion: string
  nextCamIsProveout: boolean
  nextManufacturingIsProveout: boolean
}

const getVersionInfo = (publishes: MesPublishNodeFragment[]): VersionInfo => {
  if (publishes.length === 0) {
    const nextCamVersion = "1.1"
    const nextManufacturingVersion = "1.1.1"
    return {
      currentCam: undefined,
      currentManufacturing: undefined,
      nextCamVersion,
      nextManufacturingVersion,
      nextCamIsProveout: true,
      nextManufacturingIsProveout: true,
    }
  }
  const sortedPublishes = [...publishes].sort(
    ({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime()
  )

  function getPublishInfo({
    version,
    createdAt,
    revision,
    isProveout,
  }: {
    version: string
    createdAt: string
    revision: number
    isProveout: boolean
  }): PublishInfo {
    return { version, timestamp: new Date(createdAt), revision, isProveout }
  }

  let currentManufacturing: PublishInfo | undefined
  for (const publish of sortedPublishes) {
    if (publish.version.match(/^[0-9]+\.[0-9]+.[0-9]+$/g)) {
      const patch = publish.version.split(".")[2]
      if (patch !== "0") {
        currentManufacturing = getPublishInfo(publish)
        break
      }
    }
  }

  let currentCam: PublishInfo | undefined
  for (const publish of sortedPublishes) {
    if (
      publish.version.match(/^[0-9]+\.[0-9]+$/g) ||
      publish.version.match(/^[0-9]+\.[0-9]+.0$/g)
    ) {
      currentCam = getPublishInfo(publish)
      currentCam.version = currentCam.version.split(".").slice(0, 2).join(".")
      break
    }
  }

  const nextVersionsInfo = getNextVersions(sortedPublishes[0])

  return {
    currentCam,
    currentManufacturing,
    ...nextVersionsInfo,
  }
}

const VersionInfoDisplay: FC<{
  publishInfo?: PublishInfo
}> = ({ publishInfo }) => {
  return (
    <>
      {publishInfo ? (
        <div title={format(publishInfo.timestamp, "MMM d h:mma")}>
          {publishInfo.isProveout ? "x" : "v"}
          {publishInfo.version} published {format(publishInfo.timestamp, "M/d")}
        </div>
      ) : (
        <div className={Classes.TEXT_MUTED}>Not yet published</div>
      )}
    </>
  )
}

export const OperationCard: FC<{
  jobId: string
  partModel?: SceneModelFragment
  planId: string
  operationIdx: number
  showEdit?: boolean
  showNcProgram?: boolean
  showActions?: boolean
  editableLabel?: boolean
  pauseRender?: boolean
  feedbackMode?: boolean
  revision?: number | undefined
  generatedFeedback?: PlanGenerationFeedback | undefined
  getSidebarData?: () => void
  locked?: boolean
  jobUri?: string
  assignees?: Maybe<string[]> | undefined
}> = ({
  jobId,
  partModel,
  planId,
  operationIdx,
  showEdit = true,
  showNcProgram = true,
  editableLabel = true,
  pauseRender,
  feedbackMode,
  generatedFeedback,
  revision,
  getSidebarData,
  locked,
  jobUri,
  assignees,
}) => {
  const dispatch = useAppDispatch()
  const toaster = useToaster()
  const { plansApi } = useApi()
  const { getAccessTokenSilently, isAuthenticated } = useAuth()

  const contextMenuId = useMemo(() => uuidv4(), [])
  const handleSceneClickDataUpdateRef = useRef<
    ((data: SceneClickData | undefined) => void) | undefined
  >()

  const [deleteOperationModalIsOpen, setDeleteOperationModalIsOpen] = useState<boolean>(false)
  const [notesOperationModalIsOpen, setNotesOperationModalIsOpen] = useState<boolean>(false)
  const [revertModalIsOpen, setRevertModalIsOpen] = useState<boolean>(false)
  const [viewAllPermissionEnabled, setViewAllPermissionEnabled] = useState<boolean>()
  const currentUserData = useCurrentUserData()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )
  const operationLabel = useSelector(
    storedOperationSelectors.selectOperationLabel(planId, operationIdx)
  )

  const operationId = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx)?.id
  )
  const forceLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.operationLocked(state, operationId)
  )
  const editingLocked = locked || forceLocked

  const modalOpen = useSelector(viewerModalSelectors.selectIsOpen)

  const [
    getChanges,
    { data: changesQuery, refetch: refetchChanges },
  ] = useCamPlanChangesSincePublishLazyQuery()

  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )

  const currentPlanRevision = useSelector((state: RootState) =>
    storedPlansSelectors.selectPlanRevision(state, planId)
  )

  const selectIssueAcknowledgement = useSelector(
    useMemo(() => storedOperationSelectors.createSelectIssueAcknowledgement(planId, operationIdx), [
      planId,
      operationIdx,
    ])
  )

  const [forManufacturing, setForManufacturing] = useState(false)
  const [mesModalIsOpen, setMesModalIsOpen] = useState(false)
  const { data: mesPublishesData, refetch: refetchMesPublishes } = useMesPublishingsQuery({
    variables: { planId },
  })

  const mesPublishes: MesPublishNodeFragment[] = useMemo(
    () => mesPublishesData?.mesPublishes?.nodes ?? [],
    [mesPublishesData]
  )

  const versionInfo = useMemo(() => {
    return getVersionInfo(mesPublishes.filter(val => val.operationLabel === operationLabel))
  }, [mesPublishes, operationLabel])

  const { data: vericutFeedback } = useVericutFeedbackQuery({ variables: { planId, revision } })
  const { operationProbingFeedback } = useGetProbingFeedback({ planId, operationIdx })

  const { data } = useCamJobDetailsQuery({
    variables: { jobId },
  })

  useEffect(() => {
    setViewAllPermissionEnabled(currentUserData?.permissions.includes("view:all_jobs"))
  }, [isAuthenticated, getAccessTokenSilently, currentUserData])

  useEffect(() => {
    getChanges({
      variables: {
        planId,
        operationLabel: operationLabel ?? "",
      },
    })
  }, [getChanges, operationLabel, planId])

  useEffect(() => {
    if (!modalOpen && !mesModalIsOpen && refetchChanges) {
      refetchChanges({ planId, operationLabel: operationLabel ?? "" })
    }
  }, [modalOpen, mesModalIsOpen, refetchChanges, planId, operationLabel])

  const noPublishChanges =
    (changesQuery && !changesQuery.camPlanChangesSincePublish.hasCamChanges) ||
    (versionInfo.currentCam?.revision ?? 0) === (currentPlanRevision ?? 0)

  const noReleaseChanges =
    !noPublishChanges ||
    (changesQuery &&
      !changesQuery.camPlanChangesSincePublish.hasManufacturingChanges &&
      !!versionInfo.currentManufacturing?.version &&
      (versionInfo.currentManufacturing?.revision ?? 0) >=
        (versionInfo.currentCam?.revision ?? 0)) ||
    (versionInfo.currentManufacturing?.revision ?? 0) === (currentPlanRevision ?? 0)

  const hasPublish = !!versionInfo.currentCam?.revision
  const hasRelease = !!versionInfo.currentManufacturing?.revision

  const camJob = data?.camJob || undefined

  const vericutValidated =
    (toolpathProject !== undefined &&
      vericutFeedback?.validVericutFeedback.some(
        val =>
          !val.invalidationReason &&
          val.feedback.task?.status === "SUCCESS" &&
          val.feedback.operationIdx === operationIdx &&
          val.feedback.ncFileLocator === toolpathProject?.ncFileLocator
      )) ??
    false

  const probingValidation = useMemo(
    () => ({
      simulated:
        (!!(operationProbingFeedback?.length ?? false) &&
          operationProbingFeedback?.every(
            probingFeedback => !probingFeedback.invalidationReason
          )) ??
        false,
      issuesResolved:
        operationProbingFeedback?.every(probingFeedback => {
          const probingIssues = (probingFeedback.feedback.issues as unknown) as ProbingIssue[]
          return probingIssues.every(issue => !!selectIssueAcknowledgement(issue))
        }) ?? false,
    }),
    [operationProbingFeedback, selectIssueAcknowledgement]
  )

  const handleClick3dView = (activeConfigPanelKind?: string) => {
    sendEvent("open3dView", { jobId, planId, operationIdx })

    batch(() => {
      dispatch(activeActions.setActivePlanId(String(planId)))
      dispatch(activeActions.setActiveOperationIdx(Number(operationIdx)))

      dispatch(viewerModalActions.setViewerModalIsOpen(true))
      dispatch(viewerModalActions.setViewerModalFilterPlans(true))
      dispatch(viewerModalActions.setViewerModalModelId(camJob?.model?.id))
      dispatch(viewerModalActions.setViewerModalMode(ViewerModalMode.Setup))
      dispatch(viewerModalActions.setViewerModalJobId(camJob?.id))
      dispatch(viewerModalActions.setViewerModalAssignees(assignees))

      if (activeConfigPanelKind) {
        const configPanel: ConfigPanelSelect = {
          planId: String(planId),
          operationIdx: Number(operationIdx),
          kind: activeConfigPanelKind,
          ...(activeConfigPanelKind === "coordinates" && { type: "MCS" }),
        } as ConfigPanelSelect
        dispatch(activeActions.setActiveConfigPanel(configPanel))
      }
    })
  }

  const locator: ClipboardSetupLocator = {
    planId,
    operationIdx,
    revision,
    kind: ClipboardSetupLocatorKindEnum.Setup,
  }

  const copySetup = () => {
    const copyText = JSON.stringify(locator, null, 2)
    copy(copyText)
    toaster.show({ message: `Copied setup details`, icon: "clipboard", intent: "primary" })
  }

  const pasteSetup = (pastedText: string) => {
    const source = parseClipboardSetupLocator(pastedText)
    if (!source) {
      toaster.show({
        message: "The clipboard contents did not reference a RemoteShop setup",
        icon: "error",
        intent: "warning",
      })
      return
    }

    const target = locator
    plansApi
      .copySetup({ source, target })
      .then(() => toaster.show({ message: "Pasted setup", icon: "clipboard", intent: "success" }))
      .catch(() =>
        toaster.show({ message: "Failed to paste setup", icon: "clipboard", intent: "danger" })
      )
  }

  const pasteMirroredSetup = (pastedText: string) => {
    const source = parseClipboardSetupLocator(pastedText)
    if (!source) {
      toaster.show({
        message: "The clipboard contents did not reference a RemoteShop setup",
        icon: "error",
        intent: "warning",
      })
      return
    }

    const target = locator
    plansApi
      .copyMirroredSetup({ source, target })
      .then(() =>
        toaster.show({ message: "Attempting to paste mirrored setup...", icon: "clipboard" })
      )
      .catch(() =>
        toaster.show({ message: "Failed to paste setup", icon: "clipboard", intent: "danger" })
      )
  }

  const contextMenu = (
    <Menu>
      <MenuItem
        text={"Revert to a Previous Publish"}
        onClick={() => setRevertModalIsOpen(true)}
        disabled={editingLocked}
      />
      <MenuDivider />
      <MenuItem text={"Copy Setup"} onClick={() => copySetup()} disabled={editingLocked} />
      <PasteMenuItem text={"Paste Setup"} onPaste={pasteSetup} disabled={editingLocked} />
      <MenuDivider />
      <PasteMenuItem
        text={"Paste Mirrored Setup"}
        onPaste={pasteMirroredSetup}
        disabled={editingLocked}
      />
    </Menu>
  )

  return (
    <div className={styles.container}>
      <div onContextMenu={event => event.preventDefault()}>
        <ContextMenu2 content={contextMenu}>
          <div id={contextMenuId} />
        </ContextMenu2>
      </div>
      <div className={styles.leftCardIcons}>
        <OperationTimeLabel planId={planId} operationIdx={operationIdx} />
      </div>
      {showEdit && (
        <div className={styles.rightCardIcons}>
          {!editingLocked && (
            <Tooltip2 placement={"top"} content={"Delete this operation"} openOnTargetFocus={false}>
              <AnchorButton
                minimal
                icon={"cross"}
                onClick={() => setDeleteOperationModalIsOpen(true)}
              />
            </Tooltip2>
          )}
          {(!editingLocked || (operation?.notes ?? "").length > 0) && (
            <Tooltip2
              placement={"top"}
              content={<pre>{operation?.notes}</pre>}
              openOnTargetFocus={false}
            >
              <AnchorButton
                disabled={editingLocked}
                minimal
                icon={(operation?.notes ?? "").length > 0 ? "manually-entered-data" : "annotation"}
                onClick={() => setNotesOperationModalIsOpen(true)}
              />
            </Tooltip2>
          )}
          {/* <Button icon={editingLocked ? "lock" : "unlock"} minimal onClick={handleVerifyClickLock} /> */}
          <UnlockButton operationId={operationId} locked={locked} assignees={assignees} />
        </div>
      )}
      <div className={styles.animatedCamSceneLoaderContainer}>
        {partModel && (
          <TransferCanvas pauseRender={pauseRender}>
            <OperationCardScene
              {...{
                contextMenuId,
                handleSceneClickDataUpdateRef,
                planId,
                operationIdx,
                partModel,
                handleClick3dView,
              }}
            />
          </TransferCanvas>
        )}
      </div>

      <div className={styles.labelRow}>
        <H6>
          <OperationLabel
            planId={planId}
            operationIdx={operationIdx}
            editableLabel={editableLabel && !editingLocked}
            revision={revision}
            showTimestamp={true}
          />
        </H6>

        <OpChangesIcon operationIdx={operationIdx} planId={planId} locked={editingLocked} />
        <OpControlsIcon operationIdx={operationIdx} planId={planId} />
      </div>

      {showNcProgram && (
        <>
          <div className={styles.topButtonsRow}>
            <div className={styles.topButton}>
              <CamButton planId={planId} operationIdx={operationIdx} />
            </div>

            <div className={styles.topButton}>
              {!locked && (
                <NcProgramUpload
                  planId={planId}
                  operationIdx={operationIdx}
                  locked={editingLocked}
                />
              )}
            </div>
          </div>
          <div className={styles.cardBottom}>
            <NcProgramsInfo
              planId={planId}
              operationIdx={operationIdx}
              locked={editingLocked}
              revision={revision}
            />
            <div className={styles.bottomButtonsRow}>
              <H6 style={{ marginBottom: "5px" }}>CAM</H6>
              {viewAllPermissionEnabled && <H6 style={{ marginBottom: "5px" }}>Manufacturing</H6>}
            </div>
            <div className={styles.bottomButtonsRow}>
              <Tooltip2
                disabled={locked || noPublishChanges || vericutValidated}
                content={"Releasing requires a valid Vericut simulation"}
                openOnTargetFocus={false}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <AnchorButton
                    disabled={locked}
                    className={
                      noPublishChanges || !vericutValidated || editingLocked ? Classes.DISABLED : ""
                    }
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      if ((!noPublishChanges && vericutValidated) || event.ctrlKey) {
                        setMesModalIsOpen(true)
                        setForManufacturing(false)
                      }
                    }}
                  >
                    {noPublishChanges && hasPublish
                      ? "Published"
                      : `Publish ${versionInfo.nextCamVersion}`}
                  </AnchorButton>
                  <VersionInfoDisplay publishInfo={versionInfo.currentCam} />
                </div>
              </Tooltip2>
              {!locked && viewAllPermissionEnabled && (
                <div className={styles.bottomRightButtons}>
                  <GuardrailInfo
                    planId={planId}
                    operationIdx={operationIdx}
                    jobUri={jobUri}
                    locked={editingLocked}
                  />
                  <Tooltip2
                    disabled={
                      vericutValidated &&
                      probingValidation.simulated &&
                      probingValidation.issuesResolved
                    }
                    content={"Releasing requires a valid Vericut and Probing simulation"}
                    openOnTargetFocus={false}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <AnchorButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          if (
                            noPublishChanges ||
                            event.ctrlKey ||
                            (event.shiftKey && event.altKey)
                          ) {
                            setMesModalIsOpen(true)
                            setForManufacturing(true)
                          }
                        }}
                        className={!noPublishChanges || editingLocked ? Classes.DISABLED : ""}
                        intent="primary"
                        rightIcon="share"
                      >
                        Release
                      </AnchorButton>
                      <VersionInfoDisplay publishInfo={versionInfo.currentManufacturing} />
                    </div>
                  </Tooltip2>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {feedbackMode && getSidebarData && (
        <AnnotationButtons
          {...{
            generatedFeedback,
            planId,
            revision,
            component: `op${operationIdx}`,
            getSidebarData,
          }}
        />
      )}

      {mesModalIsOpen && (
        <ManufacturingDialog
          planId={planId}
          vericutValidated={vericutValidated}
          probingValidation={probingValidation}
          operationIdx={operationIdx}
          handleRequestClose={() => setMesModalIsOpen(false)}
          refetchMesPublishes={refetchMesPublishes}
          forManufacturing={forManufacturing}
          isProveout={
            forManufacturing
              ? versionInfo.nextManufacturingIsProveout
              : versionInfo.nextCamIsProveout
          }
          version={
            forManufacturing
              ? {
                  current: versionInfo.currentManufacturing?.version,
                  next: versionInfo.nextManufacturingVersion,
                }
              : { current: versionInfo.currentCam?.version, next: versionInfo.nextCamVersion }
          }
          noPublishChanges={noPublishChanges && hasPublish}
          noReleaseChanges={noReleaseChanges && hasRelease}
        />
      )}

      {revertModalIsOpen && (
        <RevertOperationDialog
          planId={planId}
          operationIdx={operationIdx}
          isOpen={revertModalIsOpen}
          handleRequestClose={() => {
            setRevertModalIsOpen(false)
          }}
        />
      )}

      <NotesOperationModal
        planId={planId}
        isOpen={notesOperationModalIsOpen}
        operationIdx={operationIdx}
        close={() => setNotesOperationModalIsOpen(false)}
      />

      <DeleteOperationModal
        planId={planId}
        isOpen={deleteOperationModalIsOpen}
        operationIdx={operationIdx}
        close={() => setDeleteOperationModalIsOpen(false)}
      />
    </div>
  )
}

const parseClipboardSetupLocator = (text: string): ClipboardSetupLocator | undefined => {
  try {
    const parsed = JSON.parse(text) as Record<string, unknown>
    const { kind, planId, operationIdx, revision } = parsed

    if (kind !== ClipboardSetupLocatorKindEnum.Setup) return undefined
    if (typeof planId !== "string") return undefined
    if (typeof operationIdx !== "number") return undefined
    if (revision !== undefined && typeof revision !== "number") return undefined
    return { kind, planId, operationIdx, revision }
  } catch (err) {
    return undefined
  }
}

export const NoOperationsCard: FC<{
  jobId: string
  partModel?: SceneModelFragment
  planId: string
  pauseRender?: boolean
}> = ({ partModel, planId, pauseRender }) => {
  const contextMenuId = useMemo(() => uuidv4(), [])
  const handleSceneClickDataUpdateRef = useRef<
    ((data: SceneClickData | undefined) => void) | undefined
  >()

  return (
    <div className={styles.container}>
      <div className={styles.animatedCamSceneLoaderContainer}>
        {partModel && (
          <TransferCanvas pauseRender={pauseRender}>
            <OperationCardScene
              {...{ contextMenuId, handleSceneClickDataUpdateRef, planId, partModel }}
            />
          </TransferCanvas>
        )}
      </div>
      <h6 className={"bp3-heading"}>No Operations</h6>
      <p>No operations exist on this plan.</p>
      <Menu>
        <ProvidedCreateOperationMenuItems
          planId={planId}
          operationIdx={0}
          isBefore
          intent="success"
        />
      </Menu>
    </div>
  )
}

const RevertOperationDialog: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  handleRequestClose: () => void
}> = ({ planId, operationIdx, isOpen, handleRequestClose }) => {
  const { data } = useMesPublishingsQuery({ variables: { planId } })
  const toaster = useToaster()
  const opLabel = useSelector(storedOperationSelectors.selectOperationLabel(planId, operationIdx))

  const [revertToRevision] = useRevertCamPlanToRevisionMutation()

  const { refetch } = useVericutFeedbackQuery({
    variables: { planId },
  })

  const revert = (revision: number, version: string) => {
    revertToRevision({
      variables: {
        planId,
        revision,
        operationIdx,
      },
    }).then(() => {
      refetch().then(() => {
        handleRequestClose()
      })
      toaster.show({
        message: `The state of ${opLabel} has been reverted to match v${version}`,
        intent: "primary",
      })
    })
  }

  const publishings =
    data?.mesPublishes?.nodes.filter(
      val => val.operationIdx === operationIdx && val.version !== "0"
    ) ?? []

  return (
    <Dialog
      icon={"undo"}
      onClose={handleRequestClose}
      title={"Revert to a Previous Publish"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        {publishings.map(val => {
          return (
            <p key={val.version}>
              <Button
                intent="primary"
                onClick={() => {
                  revert(val.revision, val.version)
                }}
              >
                Revert to v{val.version}
              </Button>{" "}
              - Simulated at {format(new Date(val.createdAt), "MMM d h:mma")}
            </p>
          )
        })}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose} intent="primary">
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
