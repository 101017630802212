import React, { FC, useMemo, useRef } from "react"
import { PrimitiveProps } from "@react-three/fiber"

/**
 * The purpose of this component is to handle some strange bugs with the <primitive> tag related
 * to how it handles disposal of old objects. This approach ensures the old object is disposed and
 * a new one is created every time the object prop changes.
 *
 * I suspect it may be a good idea to replace the use of <primitive ...> with this <Primitive ...>
 * everywhere it occurs in our codebase, but I'm not sure if there might end up being some performance penalty.
 * (I don't think there is a penalty anywhere the <primitive ...> behavior is leading to bugs though.)
 */
export const Primitive: FC<PrimitiveProps> = ({ object, ...properties }) => {
  const keyRef = useRef(0)

  // Increment the key every time `object` changes; this ensures the old object is properly disposed
  const key = useMemo(() => {
    keyRef.current = keyRef.current + 1
    return keyRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object])

  return (
    <group>
      {}
      <primitive key={key} object={object} {...properties} />
    </group>
  )
}
