import * as THREE from "three"

import { AppModelsGeometryTransform as Transform, Direction, Point } from "src/client-axios/api"
import { PcdmisSimAnalysisData, pcdmisSimAnalysisFieldResult } from "src/store/zustandCmm"
import { transformToMatrix } from "src/util/geometry/transforms"
import { featureColor } from "src/util/ui/toolColor"

export interface CmmPointMarkersMeshData {
  featureToolResultIndex: Array<number>
  pointIndex: Array<number>
  mesh: THREE.InstancedMesh
}

export function createMarkerGeometry(
  radius: number,
  height: number,
  slices: number
): THREE.BufferGeometry {
  const position = []
  const normal = []
  const parameter = []

  const beta = Math.atan(radius / height)
  const cB = Math.cos(beta)
  const cA = -Math.sin(beta)

  const dtheta = (2.0 * Math.PI) / slices
  for (let i = 0; i < slices; ++i) {
    const theta = i * dtheta
    const vx = Math.cos(theta) * radius
    const vy = Math.sin(theta) * radius
    position.push(0.0, 0.0, 0.0)
    position.push(vx, vy, height)
    position.push(vx, vy, height)

    const nx = Math.cos(theta) * cB
    const ny = Math.sin(theta) * cA
    normal.push(nx, ny, cA)
    normal.push(nx, ny, cA)
    normal.push(0.0, 0.0, 1.0)

    parameter.push(0.0, 0.5, 0.5)
  }

  position.push(0.0, 0.0, height)
  normal.push(0.0, 0.0, 1.0)
  parameter.push(1.0)

  const indices = []
  for (let i = 0; i < slices; ++i) {
    indices.push(3 * i)
    if (i === slices - 1) {
      indices.push(1)
    } else {
      indices.push(3 * (i + 1) + 1)
    }
    indices.push(3 * i + 1)
  }

  const geometry = new THREE.BufferGeometry()
  geometry.setAttribute("position", new THREE.BufferAttribute(new Float32Array(position), 3))
  geometry.setAttribute("normal", new THREE.BufferAttribute(new Float32Array(normal), 3))
  geometry.setAttribute("parameter", new THREE.BufferAttribute(new Float32Array(parameter), 1))
  geometry.setIndex(indices)
  return geometry
}

function modifyMaterial(material: THREE.Material) {
  material.onBeforeCompile = (shader: THREE.Shader) => {
    shader.vertexShader = shader.vertexShader.replace(
      "#include <common>",
      `
                attribute float parameter;
        
                varying float v_parameter;
                #include <common>
            `
    )

    shader.vertexShader = shader.vertexShader.replace(
      "#include <fog_vertex>",
      `
                v_parameter = parameter;
                #include <fog_vertex>
            `
    )

    // shader.vertexShader = shader.vertexShader.replace(
    //     "#include <color_vertex>",
    //     `
    //     #include <color_vertex>
    //     #ifdef USE_INSTANCING_COLOR
    //     #error "xx"
    //     #endif
    //     `
    // )
    // console.log(shader.vertexShader)

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <common>",
      `
                varying float v_parameter;
        
                #include <common>
            `
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <dithering_fragment>",
      `
                const vec3 edge_color = vec3(1.0);
                const float edge_width = 0.01;
                const float edge_fade = edge_width / 5.0;
                float dist_from_edge = abs(v_parameter - 0.5);
                float edge_mix = smoothstep(
                    edge_width - edge_fade,
                    edge_width + edge_fade,
                    dist_from_edge
                );
                
                float t = abs(sin(v_parameter * 3.14159 * 9.0));
                float tt = exp(v_parameter * 3.5 - 2.0);
                float t_mix = smoothstep(
                    0.03 + tt,
                    0.07 + tt,
                    t
                );
                vec3 c_base = mix(vec3(0.92), totalDiffuse, t_mix);
                c_base = mix(c_base, totalDiffuse, float(v_parameter > 0.5));

                // vec3 c = mix(edge_color, c_base, edge_mix);
                vec3 c = c_base;

                #include <dithering_fragment>
                gl_FragColor.rgb = totalDiffuse;
                gl_FragColor.rgb = vec3(v_parameter);
                gl_FragColor.rgb = c;
                //   gl_FragColor.rgb = vColor.rgb;
                //   gl_FragColor.rgb = totalSpecular;
                //   gl_FragColor.rgb = totalEmissiveRadiance;
                //   gl_FragColor = diffuseColor;
          `
    )
    // shader.fragmentShader = shader.fragmentShader.replace(
    //     "#include <tonemapping_fragment>",
    //     ""
    // )
    shader.fragmentShader = shader.fragmentShader.replace("#include <encodings_fragment>", "")
    // shader.fragmentShader = shader.fragmentShader.replace(
    //     "#include <fog_fragment>",
    //     ""
    // )
    // shader.fragmentShader = shader.fragmentShader.replace(
    //     "#include <premultiplied_alpha_fragment>",
    //     ""
    // )
    // shader.fragmentShader = shader.fragmentShader.replace(
    //     "#include <dithering_fragment>",
    //     ""
    // )
    // console.log(shader.fragmentShader)
  }
}

const Z = new THREE.Vector3(0, 0, 1)

export function createCmmPointMarkers(
  pcdmisSimAnalysis: PcdmisSimAnalysisData,
  features: Array<string>,
  geometry: THREE.BufferGeometry
): CmmPointMarkersMeshData {
  const pointIndex: Array<number> = []
  const featureToolResultIndex: Array<number> = []
  const transforms: Array<Transform> = []
  const points: Array<Point> = []
  const dirs: Array<Direction> = []

  const fieldResult = pcdmisSimAnalysisFieldResult(pcdmisSimAnalysis)
  features.forEach(name => {
    const idx = fieldResult.feature_tool_results.findIndex(res => res.feature_name === name)
    if (idx >= 0) {
      const res = fieldResult.feature_tool_results[idx]
      res.points.forEach((p, i) => {
        pointIndex.push(i)
        featureToolResultIndex.push(idx)
        points.push(p)
        dirs.push(res.directions[i])
        transforms.push(res.feature_to_part)
      })
    }
  })

  const material = new THREE.MeshStandardMaterial({
    color: new THREE.Color(1.0, 1.0, 1.0),
    metalness: 0.88,
    roughness: 0.7,
    depthWrite: true,
    flatShading: true,
    side: THREE.DoubleSide,
  })
  modifyMaterial(material)

  const mesh = new THREE.InstancedMesh(geometry, material, points.length)
  const matrix = new THREE.Matrix4()
  const dir = new THREE.Vector3()
  const color = new THREE.Color()
  const q = new THREE.Quaternion()
  const orient = new THREE.Matrix4()

  points.forEach((p, i) => {
    // Get orientation of marker to point along
    // marker's ijk direction
    dir.set(dirs[i].x, dirs[i].y, dirs[i].z)
    q.setFromUnitVectors(Z, dir)
    orient.makeRotationFromQuaternion(q)

    // Reset matrix
    matrix.identity()
    matrix.setPosition(p.x, p.y, p.z)

    // matrix * trans * orient
    const trans = transformToMatrix(transforms[i])
    matrix.multiply(trans)
    matrix.multiply(orient)
    mesh.setMatrixAt(i, matrix)

    color.set(featureColor(featureToolResultIndex[i]))
    mesh.setColorAt(i, color)
  })

  mesh.userData.isCmmMarkers = true

  return {
    featureToolResultIndex,
    pointIndex,
    mesh,
  }
}
