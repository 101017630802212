import React, { FC, useState } from "react"
import { Button, Icon, IconName, Intent, Menu, MenuItem, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"

import {
  CamJobDetailsFragment,
  CamJobOverviewFragment,
  CamJobSummaryFragment,
  Priority,
  useUpdateCamJobPriorityMutation,
} from "src/graphql/generated"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"

import styles from "./PrioritySelector.module.css"

export const PrioritySelector: FC<{
  camJob: CamJobDetailsFragment | CamJobSummaryFragment | CamJobOverviewFragment | undefined
  label?: string
  readOnly?: boolean
}> = ({ camJob, label, readOnly: forceReadOnly }) => {
  const [updating, setUpdating] = useState<boolean>(false)

  const [updateCamJobPriority] = useUpdateCamJobPriorityMutation()

  const {
    settings: { enablePriorityEditing },
  } = useLocalStorageSettings()
  const readOnly = forceReadOnly || !enablePriorityEditing

  const handleChange = (priority: Priority) => {
    if (!camJob) return

    setUpdating(true)
    updateCamJobPriority({
      variables: { id: camJob.id, priority },
      refetchQueries: ["overview"],
    }).then(() => {
      setUpdating(false)
    })
  }

  const iconMap: { [key in Priority]: { icon?: IconName; intent: Intent } } = {
    [Priority.OnHold]: { icon: undefined, intent: "none" },
    [Priority.Low]: { icon: "arrow-down", intent: "none" },
    [Priority.Standard]: { icon: "arrow-right", intent: "none" },
    [Priority.High]: { icon: "arrow-up", intent: "danger" },
  }

  const labelMap: { [key in Priority]: string } = {
    [Priority.OnHold]: "On Hold",
    [Priority.Low]: "Low",
    [Priority.Standard]: "Standard",
    [Priority.High]: "High",
  }

  return (
    <>
      {camJob?.priority && (
        <>
          {!readOnly && (
            <>
              <Popover2
                position={Position.LEFT}
                usePortal={false}
                content={
                  <Menu className={styles.menu}>
                    {Object.entries(Priority)
                      .reverse()
                      .map(([key, value]) => {
                        return (
                          <MenuItem
                            key={key}
                            text={
                              <div className={styles.menuItem}>
                                <Icon
                                  className={`${styles.icon} ${
                                    value === Priority.Low ? styles.iconGrey : ""
                                  }`}
                                  icon={iconMap[value]?.icon}
                                  iconSize={14}
                                  intent={iconMap[value]?.intent}
                                />
                                <div>{labelMap[value]}</div>
                              </div>
                            }
                            onClick={() => handleChange(value)}
                          />
                        )
                      })}
                  </Menu>
                }
              >
                <Button disabled={updating} className={styles.button}>
                  <div className={styles.buttonLayout}>
                    {label && <div className={styles.readOnlyLabel}>{label}</div>}
                    <Icon
                      icon={iconMap[camJob.priority].icon}
                      intent={iconMap[camJob.priority]?.intent}
                      iconSize={14}
                      className={styles.icon}
                    />
                  </div>
                </Button>
              </Popover2>
            </>
          )}
          {readOnly && (
            <div className={styles.readOnlyContainer}>
              {label && <div className={styles.readOnlyLabel}>{label}</div>}
              <Icon
                icon={camJob?.priority ? iconMap[camJob.priority].icon : undefined}
                intent={camJob?.priority ? iconMap[camJob.priority]?.intent : undefined}
                iconSize={14}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
