import React, { FC } from "react"

import { Scene } from "../../generated/bindings/Scene"
import { OpSceneNode } from "./OpSceneNode"
import { ViewCallback } from "./OpSceneViewState"
import { NodeObj3dCallback } from "./types"

export const OpScene: FC<{
  scene: Scene
  view: ViewCallback
  nodeObj?: NodeObj3dCallback
}> = ({ scene, view, nodeObj }) => {
  const rootNode = scene.nodes[scene.root_node]

  return (
    <>
      <OpSceneNode
        key={scene.root_node}
        node={rootNode}
        scene={scene}
        view={view}
        nodeObj={nodeObj}
      />
    </>
  )
}
