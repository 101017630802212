import React, { FC, useCallback, useEffect, useState } from "react"
import { Controller, SpringValue, useSpring } from "@react-spring/three"

import {
  configureOrthographicFrustrum,
  initializeCamera,
} from "src/components/Canvas/Viewer/Camera/camera"
import { FLOrbitControls } from "src/components/Canvas/Viewer/Camera/FocusOrbitControls/FLOrbitControls"
import { useMainCanvas } from "src/hooks/transferCanvas/useMainCanvas"
import {
  useTransferCanvas,
  useTransferInvalidate,
} from "src/hooks/transferCanvas/useTransferCanvas"
import { useTransferCanvasResizeListener } from "src/hooks/transferCanvas/useTransferCanvasResizeListener"
import { useCameraControls } from "src/hooks/useCameraControls"
import { useEventListener } from "src/hooks/useEventListener"
import { TRANSITION_CONFIG } from "src/util/animation/springConfig"
import { isOrthographicCamera } from "src/util/threeUtils"

interface TransferAnimatedOrbitControlsProps {
  zoomOnMouse?: boolean

  enabled?: boolean
  enableDamping?: boolean
  dampingFactor?: number

  resetCallbackRef?: React.MutableRefObject<(() => void) | undefined>
}

export const TransferAnimatedOrbitControls: FC<TransferAnimatedOrbitControlsProps> = ({
  zoomOnMouse,
  enabled,
  enableDamping,
  dampingFactor,
  resetCallbackRef,
}) => {
  const { camera, controlsRef } = useTransferCanvas()
  const { transferInvalidate } = useTransferInvalidate()

  const { props: mainCanvasProps } = useMainCanvas()
  const gl = mainCanvasProps?.gl

  const [initialized, setInitialized] = useState(false)

  const cameraControls = useCameraControls()
  const { ctx } = useTransferCanvas()
  // Note: the from values should ALWAYS be provided to the setX animation functions
  // TODO: This form of onChange call will bug with react-spring 9.0.0-rc4; the fix will be to just use unwrapping
  const [, controlsTargetSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ target: [number, number, number] }>,
      spring: Controller<{ target: [number, number, number] }>
    ) => {
      const target = spring.get().target
      controlsRef.current?.target.fromArray(target)
      transferInvalidate()
    }
    return {
      from: {
        target: controlsRef.current?.target.toArray() ?? [0, 0, 0],
      },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [controlsRef, transferInvalidate])

  const [, cameraPositionSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ position: [number, number, number] }>,
      spring: Controller<{ position: [number, number, number] }>
    ) => {
      const camera = controlsRef.current?.object
      if (!camera) return
      if (!isOrthographicCamera(camera)) throw new Error("Expected orthographic camera")

      const position = spring.get().position
      camera.position.fromArray(position)
      transferInvalidate()
    }
    return {
      from: { position: camera.position.toArray() as [number, number, number] },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [camera, transferInvalidate])

  const [, controlsAzimuthalAngleSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ azimuthalAngle: number }>,
      spring: Controller<{ azimuthalAngle: number }>
    ) => {
      const azimuthalAngle = spring.get().azimuthalAngle
      controlsRef.current?.setAzimuthalAngle(azimuthalAngle)
      transferInvalidate()
    }
    return {
      from: { azimuthalAngle: controlsRef.current?.getAzimuthalAngle() ?? 0 },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [controlsRef, transferInvalidate])

  const [, controlsPolarAngleSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ polarAngle: number }>,
      spring: Controller<{ polarAngle: number }>
    ) => {
      const polarAngle = spring.get().polarAngle
      controlsRef.current?.setPolarAngle(polarAngle)
      transferInvalidate()
    }
    return {
      from: { polarAngle: controlsRef.current?.getPolarAngle() ?? 0 },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [controlsRef, transferInvalidate])

  const [, zoomSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ zoom: number }>,
      spring: Controller<{ zoom: number }>
    ) => {
      const camera = controlsRef.current?.object
      if (!camera) return
      if (!isOrthographicCamera(camera)) throw new Error("Expected orthographic camera")

      camera.zoom = spring.get().zoom
      camera.updateProjectionMatrix()
      transferInvalidate()
    }

    return {
      from: { zoom: 1.0 },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [camera, transferInvalidate])

  const [, frustrumWidthSpringRef] = useSpring(() => {
    const onChange = (
      _result: SpringValue<{ frustrumWidth: number }>,
      spring: Controller<{ frustrumWidth: number }>
    ) => {
      const camera = controlsRef.current?.object
      if (!camera) return
      if (!isOrthographicCamera(camera)) throw new Error("Expected orthographic camera")

      const frustrumWidth = spring.get().frustrumWidth
      if (!Number.isFinite(frustrumWidth) || !gl) return
      configureOrthographicFrustrum(gl, camera, frustrumWidth, ctx.canvas)
      cameraControls.lastSetFrustrumWidth = frustrumWidth
      transferInvalidate()
    }

    return {
      from: { frustrumWidth: 500.0 },
      onChange,
      config: TRANSITION_CONFIG,
    }
  }, [camera, cameraControls, ctx.canvas, gl, transferInvalidate])

  const cancelAnimations = useCallback(() => {
    zoomSpringRef.stop(true)
    frustrumWidthSpringRef.stop(true)
    cameraPositionSpringRef.stop(true)
    controlsTargetSpringRef.stop(true)
    controlsPolarAngleSpringRef.stop(true)
    controlsAzimuthalAngleSpringRef.stop(true)
  }, [
    zoomSpringRef,
    frustrumWidthSpringRef,
    cameraPositionSpringRef,
    controlsTargetSpringRef,
    controlsPolarAngleSpringRef,
    controlsAzimuthalAngleSpringRef,
  ])

  useEffect(() => {
    cameraControls.cancel = cancelAnimations
    return cancelAnimations
  }, [cameraControls, cancelAnimations])

  // Initialize the camera
  useEffect(() => {
    setInitialized(false)
  }, [camera])
  useEffect(() => {
    if (initialized || !gl) return

    initializeCamera(camera, 5_000, 1.0)
    configureOrthographicFrustrum(gl, camera, cameraControls.lastSetFrustrumWidth, ctx.canvas)
    controlsRef.current?.saveState()
    setInitialized(true)
  }, [gl, camera, cameraControls, controlsRef, initialized, ctx])

  // Window size changes
  const resizeHandler = useCallback(() => {
    cameraControls.updateFrustrumWidth()
  }, [cameraControls])
  useEventListener("resize", resizeHandler)
  useTransferCanvasResizeListener(resizeHandler)

  const resetCallback = useCallback(() => {
    cameraControls.reset()
  }, [cameraControls])

  useEffect(() => {
    if (resetCallbackRef) {
      resetCallbackRef.current = resetCallback
    }
  }, [resetCallback, resetCallbackRef])

  useEffect(() => {
    if (!initialized || !gl) return

    if (!controlsRef.current) {
      console.error("Failed to call cameraControls.setController")
      return
    }

    cameraControls.setController({
      gl,
      camera,
      canvas: ctx.canvas,
      controls: controlsRef.current,
      setControlsTarget: controlsTargetSpringRef.start.bind(controlsTargetSpringRef),
      setControlsAzimuthalAngle: controlsAzimuthalAngleSpringRef.start.bind(
        controlsAzimuthalAngleSpringRef
      ),
      setControlsPolarAngle: controlsPolarAngleSpringRef.start.bind(controlsPolarAngleSpringRef),
      setCameraPosition: cameraPositionSpringRef.start.bind(cameraPositionSpringRef),
      setZoom: zoomSpringRef.start.bind(zoomSpringRef),
      setFrustrumWidth: frustrumWidthSpringRef.start.bind(frustrumWidthSpringRef),
    })
    return () => {
      cameraControls.setController(undefined)
    }
  }, [
    ctx.canvas,
    initialized,
    controlsRef,
    cameraControls,
    gl,
    camera,
    controlsTargetSpringRef,
    cameraPositionSpringRef,
    zoomSpringRef,
    frustrumWidthSpringRef,
    controlsAzimuthalAngleSpringRef,
    controlsPolarAngleSpringRef,
  ])

  return (
    <FLOrbitControls
      ref={controlsRef}
      camera={camera}
      elem={ctx.canvas}
      enabled={enabled}
      enableDamping={enableDamping}
      dampingFactor={dampingFactor}
      lockMouseOnOrthoZoom={zoomOnMouse}
    />
  )
}
