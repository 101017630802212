import React, { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as THREE from "three"

import { TransferCanvas } from "src/components/Canvas/TransferCanvas/TransferCanvas"
import { TransferAnimatedOrbitControls } from "src/components/Canvas/Viewer/Camera/TransferAnimatedOrbitControls"
import { PointLights } from "src/components/Canvas/Viewer/Lighting/PointLights"
import { useApi } from "src/hooks/useApi"
// import { MainScene, remoteshopLoader, Scene } from "@subscale/formlogic-opscene"
import { MainScene } from "src/opscene/lib/components/MainScene"
import { remoteshopLoader } from "src/opscene/lib/components/OpScene/GltfUrlComponent"
import { Node } from "src/opscene/lib/generated/bindings/Node"
import { Scene } from "src/opscene/lib/generated/bindings/Scene"
import { CoordinateSystemContext } from "src/opscene/lib/hooks/useCoordinateSystemContext"

export const OpSceneView: FC = () => {
  const { planchangerApi } = useApi()
  const { planId, operationIdx: operationParam } = useParams<{
    planId: string
    operationIdx: string
  }>()
  const operationIdx = Number(operationParam)

  console.log("~ planId", planId)
  console.log("~ operationIdx", operationIdx)

  const [scene, setScene] = useState<Scene>()

  useEffect(() => {
    planchangerApi.getOpscene(planId, operationIdx).then(val => {
      console.log("~ val", val)

      setScene((val.data as unknown) as Scene)
    })
  }, [planId, operationIdx, planchangerApi])

  const nodeObj = useCallback((node: Node, obj3d: THREE.Object3D | null) => {
    console.log(node, obj3d)
  }, [])

  const s = 2.0
  return (
    <>
      <h1>OpSceneView</h1>
      <TransferCanvas>
        {scene && (
          <CoordinateSystemContext.Provider
            value={{
              maxSize: 12,
              hideLabel: true,
              textScale: 0.2,
              shaftRadius: 0.02 * s,
              shaftLength: 0.85 * s,
              headRadius: 0.05 * s,
              headLength: 0.15 * s,
            }}
          >
            <MainScene scene={scene} urlLoader={remoteshopLoader} nodeObj={nodeObj}>
              <PointLights distance={600} target={new THREE.Vector3(0, 0, 0)} />
            </MainScene>
          </CoordinateSystemContext.Provider>
        )}
        <TransferAnimatedOrbitControls zoomOnMouse={true} enableDamping={false} />
      </TransferCanvas>
    </>
  )
}
