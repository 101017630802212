import React, { FC, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"

import { MachineKind, MachineRecord } from "src/client-axios"
import { useProbingGaugeLength } from "src/components/Canvas/Viewer/Scene/Cam/ProbingUtils"
import {
  useTransferCanvas,
  useTransferInvalidate,
} from "src/hooks/transferCanvas/useTransferCanvas"
import { useSelectedStepStartPoint } from "src/hooks/useSelectedStepStartPoint"
import { activeProbingSelectors } from "src/store/cam/active"
import { machineSelectors } from "src/store/cam/machine"
import { probingStepDetailSelectors } from "src/store/cam/probing"
import { selectMachineRecord } from "src/store/config/machines"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewOptionsActions } from "src/store/ui/viewOptions"
import {
  ControllerState,
  defaultState,
  setMachineCurrentCoords,
  setMachineTargetCoords,
  updateCoords,
  useMachineCoordsStore,
  useTravelLimitsListener,
} from "src/store/zustandMachine"
import { MachineNode } from "./MachineNode"

// The coordinates are currently stored in a few different places and aggregatd here:
// 1. machine.ts: These are machine coordinates and AFAICT are set as absolute machine
//                coordinates where gauge length shouldn't be accounted for since they're
//                used for setting machine home position
//
//                Further, the probing doesn't set rotart coords directly, but uses the
//                machine.ts store to set rotary coordinates only
//
// 2. probing.ts These are set as the contact points or current selected step of a probing
//               section.  The coordinates are in RTCP form (except for WCS, which isn't
//               used anymore)

const MachineStoreListener: FC = () => {
  // TODO: Shouldn't be using probe directly. Instead we should be setting tool length on tool change.
  const probeRecord = useSelector(activeProbingSelectors.selectActiveProbeRecord)
  const toolLength = useProbingGaugeLength()

  useEffect(() => {
    useMachineCoordsStore.setState({ toolLength })
  }, [toolLength])

  const coords = useSelector(machineSelectors.selectCoords)
  const contact = useSelector(probingStepDetailSelectors.selectContact)
  const selectedProbeStep = useSelector(activeProbingSelectors.selectActiveProbingStep)
  const contactVerificationMode = useSelector(
    probingStepDetailSelectors.selectContactVerificationMode
  )
  const issueViewMode = useSelector(probingStepDetailSelectors.selectIssueViewMode)
  const selectedStepStartPoint = useSelectedStepStartPoint(contactVerificationMode)
  const rtcpEnabled = useMachineCoordsStore(state => state.rtcpEnabled)
  const probingActive = !!selectedProbeStep?.kind

  useEffect(() => {
    const currentCoords = {
      x: coords.x,
      y: coords.y,
      z: coords.z,
      extraCoords: {} as Record<string, number>,
    }
    if ("a" in coords) {
      currentCoords.extraCoords.a = coords.a
    }
    if ("b" in coords) {
      currentCoords.extraCoords.b = coords.b
    }
    if ("c" in coords) {
      currentCoords.extraCoords.c = coords.c
    }
    let isRtcp: boolean | undefined
    if (probingActive) {
      isRtcp = contact?.isRtcp
      if (issueViewMode && contact) {
        currentCoords.x = contact.point[0]
        currentCoords.y = contact.point[1]
        currentCoords.z = contact.point[2]
      } else {
        currentCoords.x = selectedStepStartPoint[0]
        currentCoords.y = selectedStepStartPoint[1]
        currentCoords.z = selectedStepStartPoint[2]
      }
    }
    setMachineTargetCoords(currentCoords, isRtcp, !probingActive)
  }, [
    coords,
    contact,
    probeRecord,
    selectedProbeStep,
    contactVerificationMode,
    issueViewMode,
    rtcpEnabled,
    probingActive,
    selectedStepStartPoint,
  ])

  return null
}

const MachineMover: FC = () => {
  const { transferInvalidate } = useTransferInvalidate()
  const stateRef = useRef(defaultState())

  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state,
      (state: ControllerState) => {
        // If you don't use a ref and capture the value directly, you can end up in a bad place if you change the
        // target quickly such that it gets events faster than they're processed
        stateRef.current = state
        // Use setTimeout to ensure this update logic doesn't block the rest of the app
        // Use timeout 0 to ensure the animation still runs at the highest framerate possible
        setTimeout(() => {
          updateCoords(stateRef.current)
          transferInvalidate()
        }, 0)
      },
      { fireImmediately: true }
    )
  }, [transferInvalidate])

  return null
}

const useMachineCameraConfiguration = (kind: MachineKind): void => {
  const { setCameraSetup } = useTransferCanvas()

  useEffect(() => {
    switch (kind) {
      case MachineKind.DoosanDvf5000:
        setCameraSetup({ up: [0, 0, 1], position: [500, -500, 200] })
        break
      case MachineKind.DoosanNhm6300:
        setCameraSetup({ up: [0, 1, 0], position: [-400, 300, -200] })
        break
      case MachineKind.GrobG350T:
      case MachineKind.GrobG350A:
      case MachineKind.GrobG550:
        setCameraSetup({ up: [0, 1, 0], position: [-300, 300, -300] })
        break
    }
  }, [setCameraSetup, kind])
}

const useSetInitialMachinePosition = (kind: MachineKind): void => {
  const machineRecord = useSelector((state: RootState) => selectMachineRecord(state, kind))
  const referencePosition = useMemo(
    () => machineRecord?.kinematics.referencePositions[0]?.coords ?? {},
    [machineRecord]
  )

  useEffect(() => {
    const { x, y, z, ...extraCoords } = referencePosition
    const coords = { x: x ?? 0, y: y ?? 0, z: z ?? 0, extraCoords }
    setMachineCurrentCoords(coords, false, true)
  }, [referencePosition])
}

export const MachineScene: FC<{
  machine: MachineRecord
  attachments: Record<string, JSX.Element | undefined>
}> = ({ machine, attachments }) => {
  const dispatch = useAppDispatch()

  useMachineCameraConfiguration(machine.kind)
  useSetInitialMachinePosition(machine.kind)
  useTravelLimitsListener(machine.kinematics)

  useEffect(() => {
    dispatch(viewOptionsActions.setMachineDisplayMode(machine.visibilities[0]))
  }, [dispatch, machine.visibilities])

  return (
    <>
      <MachineMover />
      <MachineStoreListener />
      {machine.nodes?.map(node => (
        <MachineNode node={node} key={node.name} attachments={attachments} />
      ))}
    </>
  )
}
