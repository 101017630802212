import { useSelector } from "react-redux"

import { activeActions, activeProbingSelectors } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const useHandleCopyProbeFeature: () => {
  handleCopyProbeFeature: (selectedProbeStep?: WcsProbingStep | ProbingStep | undefined) => void
} = () => {
  const dispatch = useAppDispatch()
  const multipleSelectedProbeSteps = useSelector(
    activeProbingSelectors.selectMultipleSelectedProbeSteps
  )

  const handleCopyProbeFeature: (
    selectedProbeStep?: WcsProbingStep | ProbingStep | undefined
  ) => void = (selectedProbeStep?: WcsProbingStep | ProbingStep | undefined) => {
    if (selectedProbeStep) {
      dispatch(activeActions.setActiveCopiedProbeSteps([selectedProbeStep]))
    } else {
      dispatch(activeActions.setActiveCopiedProbeSteps(multipleSelectedProbeSteps))
    }
  }

  return { handleCopyProbeFeature }
}
