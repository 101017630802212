import { useCallback, useMemo } from "react"
import * as THREE from "three"

import { MachineBodyNode, MachineRecord } from "src/client-axios"
import { evaluateComposedTransforms } from "src/util/geometry/evaluateMatrix"

export type RtcpState = {
  toolTransforms: Array<string>
  attachTransforms: Array<string>
  partToTable: THREE.Matrix4
}

export type MachineRecordNodeTree = {
  nodeRecord: Record<string, MachineBodyNode>
  nodeParents: Record<string, string>
}

function machineNodeRecord(
  machineBodyNodes: Array<MachineBodyNode>
): Record<string, MachineBodyNode> {
  const record: Record<string, MachineBodyNode> = {}

  function visitNodes(nodes: Array<MachineBodyNode>): void {
    nodes.forEach(node => {
      record[node.name] = node
      if (node.children) {
        visitNodes(node.children)
      }
    })
  }

  visitNodes(machineBodyNodes)

  return record
}

function machineNodeParents(nodes: Record<string, MachineBodyNode>): Record<string, string> {
  const parents: Record<string, string> = {}
  Object.values(nodes).forEach(node => {
    if (node.children) {
      node.children.forEach(subNode => {
        parents[subNode.name] = node.name
      })
    }
  })
  return parents
}

export function createMachineRecordNodeTree(machine: MachineRecord): MachineRecordNodeTree {
  const nodeRecord = machineNodeRecord(machine.nodes)
  return {
    nodeRecord,
    nodeParents: machineNodeParents(nodeRecord),
  }
}

export function machineNodeTransforms(
  nodeTree: MachineRecordNodeTree,
  name: string
): Array<string> {
  let node = nodeTree.nodeRecord[name]
  const transforms = [node.transformFormula]
  /* eslint-disable no-prototype-builtins */
  while (nodeTree.nodeParents.hasOwnProperty(node.name)) {
    node = nodeTree.nodeRecord[nodeTree.nodeParents[node.name]]
    transforms.push(node.transformFormula)
  }
  return transforms.reverse()
}

// TODO: May want to extend the valid choices of nodeName in the future
export const useGetMachineTransform = (
  machine: MachineRecord | undefined,
  nodeName: "Attach" | "Tool",
  // relativeTo can be used to ensure the returned transform is relative to a home position
  relativeTo?: Record<string, number>
): ((coords: Record<string, number>) => THREE.Matrix4) => {
  const { attachTransforms, invertedBaseTransform } = useMemo(() => {
    if (!machine) {
      return { attachTransforms: [], invertedBaseTransform: undefined }
    }

    const nodeTree = createMachineRecordNodeTree(machine)
    const attachTransforms = machineNodeTransforms(nodeTree, nodeName)
    const invertedBaseTransform =
      relativeTo && evaluateComposedTransforms(attachTransforms, relativeTo).invert()
    return { attachTransforms, invertedBaseTransform }
  }, [machine, nodeName, relativeTo])

  // const baseCoords = Object.fromEntries(machine.kinematics.axes.map(x => [x, 0]))
  return useCallback(
    (coords: Record<string, number>) => {
      const result = evaluateComposedTransforms(attachTransforms, coords)
      if (invertedBaseTransform) {
        result.multiply(invertedBaseTransform)
      }
      return result
    },
    [attachTransforms, invertedBaseTransform]
  )
}

export const getMachineTransform = (
  machine: MachineRecord | undefined,
  nodeName: "Attach" | "Tool",
  coords: Record<string, number>,
  // relativeTo can be used to ensure the returned transform is relative to a home position
  relativeTo?: Record<string, number>
): THREE.Matrix4 => {
  let attachTransforms: string[]
  let invertedBaseTransform: THREE.Matrix4 | undefined
  if (!machine) {
    attachTransforms = []
    invertedBaseTransform = undefined
  } else {
    const nodeTree = createMachineRecordNodeTree(machine)
    attachTransforms = machineNodeTransforms(nodeTree, nodeName)
    invertedBaseTransform =
      relativeTo && evaluateComposedTransforms(attachTransforms, relativeTo).invert()
  }

  const result = evaluateComposedTransforms(attachTransforms, coords)
  if (invertedBaseTransform) {
    result.multiply(invertedBaseTransform)
  }
  return result
}
