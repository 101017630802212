import React, { FC, MutableRefObject, useRef } from "react"

import { createRequiredOptionalContext } from "src/hooks/requiredOptionalContext"

export interface ZoomToFitSettings {
  includeStock: boolean
  includePart: boolean
  includeFixtures: boolean
  includeAllVisibleMeshes: boolean
}

export const [ViewCubeCallbackContext, useViewCubeCallbackContext] = createRequiredOptionalContext<{
  orientCallbackRef: MutableRefObject<((orientation: [number, number, number]) => void) | undefined>
  zoomCallbackRef: MutableRefObject<((zoomToFitSettings: ZoomToFitSettings) => void) | undefined>
}>("ViewCubeCallbackContext")

export const ViewCubeCallbackProvider: FC = ({ children }) => {
  const orientCallbackRef = useRef<(orientation: [number, number, number]) => void>()
  const zoomCallbackRef = useRef<(zoomToFitSettings: ZoomToFitSettings) => void>()

  return (
    <ViewCubeCallbackContext.Provider value={{ orientCallbackRef, zoomCallbackRef }}>
      {children}
    </ViewCubeCallbackContext.Provider>
  )
}
