import React, { FC, useEffect, useState } from "react"
import { AnchorButton, Button, Checkbox, Divider, FormGroup, HTMLSelect } from "@blueprintjs/core"
import { useAuth } from "@subscale/formlogic-auth-ts"

import { ExportCoordinates, JiraClientData } from "src/client-axios"
import { useApi } from "src/hooks/useApi"
import { LocalStorageSettings, useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import styles from "src/pages/Account/Account.module.css"
import { TokenView } from "src/pages/Account/TokenView/TokenView"
import { getConnectToJiraUrl } from "../ViewerPage/IssuesPanel/IssuesPanel"
import { useIssues } from "../ViewerPage/useIssues"

declare const RS_VERSION: string

export const Account: FC = () => {
  const { user } = useAuth()

  const [buildDetails, setBuildDetails] = useState<string | undefined>()

  useEffect(() => {
    if (buildDetails !== undefined) return

    fetch("/build.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(response =>
      response.json().then(json => {
        const sourceCommit = json.source_commit as unknown

        if (typeof sourceCommit !== "string") {
          console.log("Error")
        } else {
          setBuildDetails(sourceCommit)
        }
      })
    )
  }, [buildDetails])

  return (
    <>
      <h1>Account</h1>

      <div>
        {user && (
          <>
            <img src={`${user.picture}`} alt={`${user.name}`} />
            <p>Name: {user.name}</p>
            <p>Email: {user.email}</p>
          </>
        )}
        <SettingsCheckboxes />
        <TokenView />
        <div style={{ marginTop: "20px" }}>Version: {RS_VERSION}</div>
        <div style={{ marginTop: "20px" }}>Build: {buildDetails ?? "Development"}</div>
      </div>
    </>
  )
}

const SettingsCheckboxes: FC = () => {
  const {
    settings,
    settings: {
      showArchivedJobs,
      showAnalysisJobs,
      showUnassignedJobs,
      showJobsAssignedToOthers,
      showDebugLogs,
      enablePriorityEditing,
      exportCoordinates,
    },
    setSettings,
  } = useLocalStorageSettings()

  const { jiraAccessToken, cloudId, setJiraAccessToken } = useIssues()

  const handleCheckboxChange = (settingsKey: keyof LocalStorageSettings, checked: boolean) => {
    setSettings({ [settingsKey]: checked })
  }

  const { jiraApi } = useApi()
  const [clientData, setClientData] = useState<JiraClientData>()

  useEffect(() => {
    jiraApi
      .getJiraClientData(window.location.host)
      .then(({ data }) => {
        setClientData(data)
      })
      .catch(e => {
        console.error(e)
      })
  }, [jiraApi])

  const handleDisconnectJira = () => {
    setJiraAccessToken(undefined)
    setSettings({
      ...settings,
      jiraAccessToken: undefined,
      jiraRefreshToken: undefined,
      cloudId: undefined,
    })
  }

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.checkboxesContainer}>
        <h3>CAM Export Settings</h3>
        <FormGroup
          contentClassName={styles.select}
          label={"Global Coordinate System in Exported CAM Files"}
        >
          <HTMLSelect
            value={exportCoordinates}
            onChange={e =>
              setSettings({
                exportCoordinates: (e.currentTarget.value as unknown) as ExportCoordinates,
              })
            }
          >
            <option value={ExportCoordinates.Part}>Part</option>
            <option value={ExportCoordinates.Table}>Table</option>
            <option value={ExportCoordinates.Wcs}>WCS</option>
          </HTMLSelect>
        </FormGroup>
      </div>
      <div className={styles.checkboxesContainer}>
        <h3>Jira Connection</h3>

        {jiraAccessToken && cloudId ? (
          <div className={`${styles.jiraButtonContainer} ${styles.connected}`}>
            Connected. <Button onClick={handleDisconnectJira}>Disconnect from Jira</Button>
          </div>
        ) : (
          <div className={styles.jiraButtonContainer}>
            Not Connected.{" "}
            {clientData && (
              <AnchorButton
                href={getConnectToJiraUrl(clientData)}
                target={"_blank"}
                text={`Connect to Jira`}
              />
            )}
          </div>
        )}
      </div>

      <div className={styles.checkboxesContainer}>
        <h3>CAM Jobs Display Settings</h3>
        <Checkbox
          label="Show unassigned jobs"
          checked={showUnassignedJobs}
          onChange={() => handleCheckboxChange("showUnassignedJobs", !showUnassignedJobs)}
        />
        <Checkbox
          label="Show jobs assigned to others"
          checked={showJobsAssignedToOthers}
          onChange={() =>
            handleCheckboxChange("showJobsAssignedToOthers", !showJobsAssignedToOthers)
          }
        />
        <Divider />
        <Checkbox
          label="Show archived jobs"
          checked={showArchivedJobs}
          onChange={() => handleCheckboxChange("showArchivedJobs", !showArchivedJobs)}
        />
        <Checkbox
          label="Show analysis jobs"
          checked={showAnalysisJobs}
          onChange={() => handleCheckboxChange("showAnalysisJobs", !showAnalysisJobs)}
        />
        <Divider />
        <Checkbox
          label="Enable priority editing"
          checked={enablePriorityEditing}
          onChange={() => handleCheckboxChange("enablePriorityEditing", !enablePriorityEditing)}
        />
        <Divider />
        <Checkbox
          label="Show debug logs in console"
          checked={showDebugLogs}
          onChange={() => handleCheckboxChange("showDebugLogs", !showDebugLogs)}
        />
      </div>
    </div>
  )
}
