import { useEffect, useMemo, useState } from "react"
import * as CBOR from "cbor-web"

import {
  CutInfoToolPathData,
  GcodeOutput,
  MachinedParametricStockSimInfo,
  OpCamFeatureInfo,
  ParametricStockSimInfo,
  PlanchangerApi,
  StockAnalysisToolPathData,
} from "src/client-axios"
import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import {
  outputStockInfo,
  resetState,
  StockMeshInfo,
  toolChangeStockInfo,
  useCuttingSimDisplayStore,
} from "src/store/zustandCuttingSim"
import { BaseManifest, ManifestDoc } from "src/util/plm"
import { PlanOperation } from "./CamScene/sharedTypes"
import { useNcEventIntervals } from "./NcEventIntervals"
import { NcEventIntervals } from "./stockSimDefs"
import { useSimulationManifest } from "./useSimulationManifest"

function getLocatorUrl(locator: string): string {
  return `/api/rs/v1/manifests/doc${encodeURIComponent(locator)}`
}

function getCbor<T>(planchangerApi: PlanchangerApi, locator: string): Promise<T> {
  const requestConfig = { responseType: "arraybuffer" }
  return planchangerApi.getDoc(locator, requestConfig).then(res => {
    const buffer = (res.data as unknown) as string

    const cbor: T = CBOR.decode(buffer)
    return cbor
  })
}

function hasAnyCutInfo(stockAnalyses: Array<StockAnalysisToolPathData>): boolean {
  for (let i = 0; i < stockAnalyses.length; ++i) {
    if (stockAnalyses[i].cut_info) {
      return true
    }
  }
  return false
}

function concatenateCutInfo(stockAnalyses: Array<StockAnalysisToolPathData>): CutInfoToolPathData {
  const volume: number[][] = []
  const maxDepthOfCut: number[][] = []
  const leftEngaged: boolean[][] = []
  const leftMinWidth: number[][] = []
  const leftMaxWidth: number[][] = []
  const rightEngaged: boolean[][] = []
  const rightMinWidth: number[][] = []
  const rightMaxWidth: number[][] = []
  const widthOfCut: number[][] = []

  for (let i = 0; i < stockAnalyses.length; ++i) {
    const cutInfo = stockAnalyses[i].cut_info
    if (cutInfo) {
      volume.push(cutInfo.volume)
      maxDepthOfCut.push(cutInfo.max_depth_of_cut)
      leftEngaged.push(cutInfo.left_engaged)
      leftMinWidth.push(cutInfo.left_min_width)
      leftMaxWidth.push(cutInfo.left_max_width)
      rightEngaged.push(cutInfo.right_engaged)
      rightMinWidth.push(cutInfo.right_min_width)
      rightMaxWidth.push(cutInfo.right_max_width)
      widthOfCut.push(cutInfo.width_of_cut)
    } else {
      const length = stockAnalyses[i].cut_number.length
      volume.push(new Array(length).fill(0))
      maxDepthOfCut.push(new Array(length).fill(0))
      leftEngaged.push(new Array(length).fill(false))
      leftMinWidth.push(new Array(length).fill(0))
      leftMaxWidth.push(new Array(length).fill(0))
      rightEngaged.push(new Array(length).fill(false))
      rightMinWidth.push(new Array(length).fill(0))
      rightMaxWidth.push(new Array(length).fill(0))
      widthOfCut.push(new Array(length).fill(0))
    }
  }

  return {
    volume: ([] as number[]).concat(...volume),
    max_depth_of_cut: ([] as number[]).concat(...maxDepthOfCut),
    left_engaged: ([] as boolean[]).concat(...leftEngaged),
    left_min_width: ([] as number[]).concat(...leftMinWidth),
    left_max_width: ([] as number[]).concat(...leftMaxWidth),
    right_engaged: ([] as boolean[]).concat(...rightEngaged),
    right_min_width: ([] as number[]).concat(...rightMinWidth),
    right_max_width: ([] as number[]).concat(...rightMaxWidth),
    width_of_cut: ([] as number[]).concat(...widthOfCut),
  }
}

function concatenateStockAnalyses(
  stockAnalyses: Array<StockAnalysisToolPathData>
): StockAnalysisToolPathData {
  if (stockAnalyses.length === 1) {
    return stockAnalyses[0]
  }

  const cutNumber: number[][] = []
  const gcodeLine: number[][] = []
  const toolChange: number[][] = []
  const toolPosition: number[][] = []
  const toolAxis: number[][] = []

  for (let i = 0; i < stockAnalyses.length; ++i) {
    cutNumber.push(stockAnalyses[i].cut_number)
    gcodeLine.push(stockAnalyses[i].gcode_line)
    toolChange.push(stockAnalyses[i].tool_change)
    toolPosition.push(stockAnalyses[i].tool_position)
    toolAxis.push(stockAnalyses[i].tool_axis)
  }

  const stockAnalysis: StockAnalysisToolPathData = {
    cut_number: ([] as number[]).concat(...cutNumber),
    gcode_line: ([] as number[]).concat(...gcodeLine),
    tool_change: ([] as number[]).concat(...toolChange),
    tool_position: ([] as number[]).concat(...toolPosition),
    tool_axis: ([] as number[]).concat(...toolAxis),
  }

  if (hasAnyCutInfo(stockAnalyses)) {
    stockAnalysis.cut_info = concatenateCutInfo(stockAnalyses)
  }

  stockAnalysis.coordinate_system = stockAnalyses[0]?.coordinate_system

  return stockAnalysis
}

type StockMeshResource = StockMeshInfo & { url?: string }

interface StockSimulationData {
  simManifest?: BaseManifest
  ncEventIntervals: NcEventIntervals
  stockGltfUrl?: string
  stockAnalysisData?: StockAnalysisToolPathData
  stockParamSurfData?: ParametricStockSimInfo
  stockMachinedParamSurfData?: MachinedParametricStockSimInfo
  toolChangeStocks?: Array<StockMeshResource>
  gcodeOutput?: GcodeOutput
}

function useGcodeOutput(
  planchangerApi: PlanchangerApi,
  doc?: ManifestDoc
): GcodeOutput | undefined {
  const [gcodeOutput, setGcodeOutput] = useState<GcodeOutput>()

  useEffect(() => {
    if (doc !== undefined) {
      const requestConfig = { responseType: "arraybuffer" }
      planchangerApi.getDoc(doc.uri, requestConfig).then(res => {
        const buffer = (res.data as unknown) as string

        const data: GcodeOutput = CBOR.decode(buffer)
        setGcodeOutput(data)
      })
    }
  }, [planchangerApi, doc])

  return gcodeOutput
}

function findToolChangeGltfDocs(docs?: ManifestDoc[]): ManifestDoc[] | undefined {
  return docs?.filter(doc => doc.kind === "stock_glb" && doc.tool_change_idx !== undefined)
}

function findStockGltfDoc(docs?: ManifestDoc[]): ManifestDoc | undefined {
  return docs?.find(doc => doc.kind === "stock_glb" && doc.tool_change_idx === undefined)
}

function findDoc(kind: string, docs?: ManifestDoc[]): ManifestDoc | undefined {
  return docs?.find(doc => doc.kind === kind)
}

function useDocUrl(doc?: ManifestDoc): string | undefined {
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (doc === undefined) {
      setUrl(undefined)
    } else {
      setUrl(getLocatorUrl(doc.uri))
    }
  }, [doc])

  return url
}

function useParametricSurfaces(
  planchangerApi: PlanchangerApi,
  doc?: ManifestDoc
): ParametricStockSimInfo | undefined {
  const [parametricStockSimInfo, setParametricStockSimInfo] = useState<ParametricStockSimInfo>()

  useEffect(() => {
    if (doc !== undefined) {
      getCbor<ParametricStockSimInfo>(planchangerApi, doc.uri).then(data =>
        setParametricStockSimInfo(data)
      )
    }
  }, [planchangerApi, doc])

  return parametricStockSimInfo
}

function useOpCamFeatureInfo(
  planchangerApi: PlanchangerApi,
  doc?: ManifestDoc
): OpCamFeatureInfo | undefined {
  const [opCamFeatureInfo, setOpCamFeatureInfo] = useState<OpCamFeatureInfo>()

  useEffect(() => {
    if (doc !== undefined) {
      planchangerApi.getDoc(doc.uri).then(res => {
        setOpCamFeatureInfo((res.data as unknown) as OpCamFeatureInfo)
      })
    }
  }, [planchangerApi, doc])

  return opCamFeatureInfo
}

function useMachinedParametricSurfaces(
  planchangerApi: PlanchangerApi,
  doc?: ManifestDoc
): MachinedParametricStockSimInfo | undefined {
  const [machinedParametricStockSimInfo, setMachinedParametricStockSimInfo] = useState<
    MachinedParametricStockSimInfo
  >()

  useEffect(() => {
    if (doc !== undefined) {
      getCbor<MachinedParametricStockSimInfo>(planchangerApi, doc.uri).then(data =>
        setMachinedParametricStockSimInfo(data)
      )
    }
  }, [planchangerApi, doc])

  return machinedParametricStockSimInfo
}

function getStockAnalysisFromDocs(
  planchangerApi: PlanchangerApi,
  stockAnalysisDoc: ManifestDoc,
  cutInfoDoc?: ManifestDoc
): Promise<StockAnalysisToolPathData> {
  return getCbor<StockAnalysisToolPathData>(planchangerApi, stockAnalysisDoc.uri).then(
    stockAnalysis => {
      if (cutInfoDoc) {
        return getCbor<CutInfoToolPathData>(planchangerApi, cutInfoDoc.uri).then(cutInfo => {
          stockAnalysis.cut_info = cutInfo
          return stockAnalysis
        })
      } else {
        return stockAnalysis
      }
    }
  )
}

function useStockAnalysis(
  planchangerApi: PlanchangerApi,
  manifest?: BaseManifest
): StockAnalysisToolPathData | undefined {
  const [stockAnalysis, setStockAnalysis] = useState<StockAnalysisToolPathData>()

  useEffect(() => {
    const docs = manifest?.docs
    if (docs !== undefined) {
      const stockAnalysisDocs = docs.filter(doc => doc.kind === "stock_analysis_cbor")

      const cutInfoDocsByToolChange: Record<string, ManifestDoc> = {}
      docs
        .filter(doc => doc.kind === "cut_info_cbor")
        .forEach(doc => {
          const toolChangeIdx = doc.tool_change_idx!
          cutInfoDocsByToolChange[toolChangeIdx] = doc
        })

      Promise.all(
        stockAnalysisDocs.map(doc => {
          const toolChangeIdx = doc.tool_change_idx!
          const cutInfoDoc = cutInfoDocsByToolChange[toolChangeIdx]
          return getStockAnalysisFromDocs(planchangerApi, doc, cutInfoDoc)
        })
      ).then(stockAnalyses => {
        setStockAnalysis(concatenateStockAnalyses(stockAnalyses))
      })
    }
  }, [planchangerApi, manifest, setStockAnalysis])

  return stockAnalysis
}

export function useStockSimulationData(planOperation: PlanOperation): StockSimulationData {
  const { planchangerApi } = useApi()
  const [updateCount, setUpdateCount] = useState(0)

  const simManifest = useSimulationManifest(
    planOperation.planId,
    planOperation.operationIdx,
    updateCount
  )

  const ncEventIntervals = useNcEventIntervals(planOperation)

  useEffect(() => {
    useCuttingSimDisplayStore.setState(resetState())
  }, [planOperation.operationIdx])

  const stockGltfDoc = useMemo(() => findStockGltfDoc(simManifest?.docs), [simManifest])
  const toolChangeGltfDocs = useMemo(() => findToolChangeGltfDocs(simManifest?.docs), [simManifest])
  const parametricInfoDoc = useMemo(() => findDoc("parametric_info_cbor", simManifest?.docs), [
    simManifest,
  ])
  const machinedParametricInfoDoc = useMemo(
    () => findDoc("machined_parametric_info_cbor", simManifest?.docs),
    [simManifest]
  )
  const gcodeLogDoc = useMemo(() => findDoc("gcode_log", simManifest?.docs), [simManifest])
  const opCamFeatureInfoDoc = useMemo(() => findDoc("operation_cam_features", simManifest?.docs), [
    simManifest,
  ])

  const stockGltfUrl = useDocUrl(stockGltfDoc)
  const stockParamSurfData = useParametricSurfaces(planchangerApi, parametricInfoDoc)
  const stockMachinedParamSurfData = useMachinedParametricSurfaces(
    planchangerApi,
    machinedParametricInfoDoc
  )
  const stockAnalysisData = useStockAnalysis(planchangerApi, simManifest)
  const gcodeOutput = useGcodeOutput(planchangerApi, gcodeLogDoc)
  const toolChangeStocks = useMemo(() => {
    if (toolChangeGltfDocs) {
      const docsByToolChangeIds: Record<string, ManifestDoc> = {}
      toolChangeGltfDocs.forEach(doc => {
        const toolChangeIdx = doc.tool_change_idx!
        docsByToolChangeIds[toolChangeIdx] = doc
      })

      // ncEventIntervals.toolChanges.map contains an event
      // for every tool change.  There should be (buy may not be)
      // a ManifestDoc for every tool change.  In cases where there
      // isn't a manifest doc, we set the url to undefined
      // Stock models are setup so that
      // idx 0 = starting stock (this usually doesn't exist)
      // idx 1 = stock after tool change 0 / before tool change 1
      // ...
      // idx undefined = the output stock
      // Expect #Tool Changes - 2 meshes
      return ncEventIntervals.toolChanges
        .map((_, idx) => {
          // Expect the tool change index of the documents to
          // range fron [0, N - 1] where N is the # of tool changes.
          // idx ranges from [0, N-2]
          const doc = docsByToolChangeIds[idx]
          if (idx === 0 && !doc) {
            // If there's not initial stock mesh, ignore
            return undefined
          }
          const info = toolChangeStockInfo(idx)
          if (doc) {
            return {
              ...info,
              url: getLocatorUrl(doc.uri),
            }
          }
          return info
        })
        .filter(v => v !== undefined) as StockMeshResource[]
    }
    return undefined
  }, [toolChangeGltfDocs, ncEventIntervals.toolChanges])

  const stockMeshes = useMemo(() => {
    const stockMeshes: Array<StockMeshInfo> = []
    if (stockGltfDoc) {
      stockMeshes.push(outputStockInfo())
    }

    if (toolChangeStocks) {
      return stockMeshes.concat(toolChangeStocks)
    }

    return stockMeshes
  }, [stockGltfDoc, toolChangeStocks])

  const opCamFeatureInfo = useOpCamFeatureInfo(planchangerApi, opCamFeatureInfoDoc)

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ stockMeshes })
  }, [stockMeshes])

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ toolPathCbor: stockAnalysisData })
  }, [stockAnalysisData])

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ stockMachinedParamSurfaces: stockMachinedParamSurfData })
  }, [stockMachinedParamSurfData])

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ stockParamSurfaces: stockParamSurfData })
  }, [stockParamSurfData])

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ opCamFeatureInfo })
  }, [opCamFeatureInfo])

  useWebsocketMessageListener(msg => {
    if (
      (msg.type === "TaskProgress" &&
        msg.name.endsWith("simulate_stock_gcode_mapping_task") &&
        msg.status === "Success") ||
      (msg.type === "TaskProgress" &&
        msg.name.endsWith("simulate_stock_gcode_mapping_task") &&
        msg.message === "Saved stock simulation mesh")
    ) {
      setUpdateCount(updateCount + 1)
    }
  })

  return {
    simManifest,
    ncEventIntervals,
    stockGltfUrl,
    stockAnalysisData,
    stockParamSurfData,
    stockMachinedParamSurfData,
    toolChangeStocks,
    gcodeOutput,
  }
}
