import React, { FC, Suspense, useEffect, useState } from "react"

import { BucketType } from "src/client-axios"
import { getPartMaterial } from "src/components/Canvas/Viewer/Scene/Cam/materials"
import { AnimatedGltfUrlModel } from "src/components/Canvas/Viewer/SceneItems/GltfUrlModel"
import { useApi } from "src/hooks/useApi"

export const HolderBody: FC<{ holderBody: string; getHolderModelKey?: () => string }> = ({
  holderBody,
  getHolderModelKey,
}) => {
  const { planchangerApi } = useApi()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    const modelKey = getHolderModelKey?.()
    if (modelKey || holderBody) {
      planchangerApi
        .urlFor_getFile(modelKey ?? `holder_${holderBody}`, undefined, true, BucketType.Config)
        .then(holderUrl => setUrl(holderUrl.toString()))
    }
    return () => setUrl(undefined)
  }, [getHolderModelKey, holderBody, planchangerApi])

  if (!url) return null

  return (
    <Suspense fallback={<></>}>
      <AnimatedGltfUrlModel url={url} flatShading={false} material={getPartMaterial(false)} />
    </Suspense>
  )
}
