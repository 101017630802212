import * as THREE from "three"

import { StockAnalysisToolPathData } from "src/client-axios"
import { scalarAttribute, vector3Attribute } from "./visUtils"

function arange(startOrEnd: number, end?: number): number[] {
  let start = startOrEnd
  if (end === undefined) {
    start = 0
    end = startOrEnd
  }
  const index = []
  for (let i = start; i < end; ++i) {
    index.push(i)
  }
  return index
}

export function createToolPathIndices(
  toolPaths: StockAnalysisToolPathData
): Array<[number, number] | undefined> {
  const indices: Array<[number, number] | undefined> = []
  let prevToolChangeIndex = 0
  for (let i = 1; i < toolPaths.tool_change.length; ++i) {
    if (toolPaths.tool_change[prevToolChangeIndex] !== toolPaths.tool_change[i]) {
      const gap = toolPaths.tool_change[i] - toolPaths.tool_change[i - 1]
      indices.push([prevToolChangeIndex, i])
      prevToolChangeIndex = i
      for (let j = 1; j < gap; ++j) {
        indices.push(undefined)
      }
    }
  }
  indices.push([prevToolChangeIndex, toolPaths.tool_change.length])
  return indices
}

function createToolPathGeometry(
  toolPaths: StockAnalysisToolPathData,
  startIdx: number,
  endIdx: number
): THREE.BufferGeometry {
  const gcodeLine = toolPaths.gcode_line.slice(startIdx, endIdx)
  const positions = toolPaths.tool_position.slice(startIdx * 3, endIdx * 3)

  const geometry = new THREE.BufferGeometry()
  geometry.setAttribute("position", vector3Attribute(positions))
  geometry.setAttribute("gcode_line", scalarAttribute(gcodeLine))
  geometry.setAttribute("index", scalarAttribute(arange(endIdx - startIdx)))
  return geometry
}

export function createToolPathLines(
  toolPaths: StockAnalysisToolPathData,
  startIdx: number,
  endIdx: number
): THREE.Line {
  const geometry = createToolPathGeometry(toolPaths, startIdx, endIdx)
  const material = new THREE.LineBasicMaterial({
    color: new THREE.Color("#001DF0"),
    transparent: true,
    blending: THREE.NormalBlending,
  })
  return new THREE.Line(geometry, material)
}

export function createToolPathLine(
  toolPaths: StockAnalysisToolPathData,
  range: [number, number] | undefined
): THREE.Line | undefined {
  if (range === undefined) {
    return undefined
  }
  const line = createToolPathLines(toolPaths, range[0], range[1])
  line.userData.ignoreIntersection = true
  line.userData.maxIndex = range[1] - range[0]
  return line
}

export function createOperationToolPathLines(
  toolPaths: StockAnalysisToolPathData
): Array<THREE.Line | undefined> {
  const indices = createToolPathIndices(toolPaths)
  const lines: Array<THREE.Line | undefined> = []
  for (let i = 0; i < indices.length; ++i) {
    const range = indices[i]
    if (range === undefined) {
      lines.push(undefined)
    } else {
      const line = createToolPathLines(toolPaths, range[0], range[1])
      line.userData.ignoreIntersection = true
      line.userData.maxIndex = range[1] - range[0]
      lines.push(line)
    }
  }
  return lines
}

function createToolPathBackplotColorRamp(): THREE.DataTexture {
  const width = 3
  const height = 1
  const size = width * height
  const data = new Uint8Array(4 * size)
  const colors = ["#0095E6", "#001DF0", "#510ACD"]
  colors.forEach((c, i) => {
    const color = new THREE.Color(c)
    data[4 * i] = color.r * 255
    data[4 * i + 1] = color.g * 255
    data[4 * i + 2] = color.b * 255
    data[4 * i + 3] = 255
  })
  const texture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat)
  texture.needsUpdate = true
  return texture
}

export function onBeforeCompile(shader: THREE.Shader, maxIndex: number): void {
  shader.vertexShader = shader.vertexShader.replace(
    "#include <common>",
    `
        attribute float index;
        attribute float gcode_line;
        varying float v_index;
        varying float v_gcode_line;
        #include <common>
        `
  )

  shader.vertexShader = shader.vertexShader.replace(
    "#include <fog_vertex>",
    `
        v_index = index;
        v_gcode_line = gcode_line;
        #include <fog_vertex>
    `
  )

  shader.fragmentShader = shader.fragmentShader.replace(
    "#include <common>",
    `
        varying float v_index;
        varying float v_gcode_line;
        uniform sampler2D color_ramp;
        uniform float max_index;
        #include <common>
    `
  )

  shader.fragmentShader = shader.fragmentShader.replace(
    "#include <dithering_fragment>",
    `
        #include <dithering_fragment>
        float t = v_index / max_index;
        vec3 ramp_color = texture2D(color_ramp, vec2(t, 0.)).rgb;
        gl_FragColor.rgb = ramp_color;
    `
  )
  shader.uniforms.color_ramp = new THREE.Uniform(createToolPathBackplotColorRamp())
  shader.uniforms.max_index = new THREE.Uniform(maxIndex)
}
