import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { TransferCanvas } from "src/components/Canvas/TransferCanvas/TransferCanvas"
import { TransferAnimatedOrbitControls } from "src/components/Canvas/Viewer/Camera/TransferAnimatedOrbitControls"
import { quat2euler } from "src/components/Canvas/Viewer/geometry"
import { PointLights } from "src/components/Canvas/Viewer/Lighting/PointLights"
import { PartScene } from "src/components/Canvas/Viewer/Scene/Cam/PartScene"
import { StocksScene } from "src/components/Canvas/Viewer/Scene/Cam/StockScene"
import { SceneModelFragment, useModelByIdLazyQuery } from "src/graphql/generated"
import { TransferCanvasContext } from "src/hooks/transferCanvas/useTransferCanvas"
import { useCameraControls } from "src/hooks/useCameraControls"
import { SceneClickData, useSceneClickEventHandler } from "src/hooks/useSceneClickEventHandler"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { fixturesSelectors } from "src/store/config/fixtures"
import { RootState } from "src/store/rootStore"
import { DisplayMode, DisplayStock, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { CanvasContextMenuListener } from "../../../ClickHandlers/CanvasContextMenu/CanvasContextMenuListener"
import {
  getInverseFocusFixtureTransformMatrix,
  getInverseMcsTransformMatrix,
} from "../operationUtil"

import styles from "./InputStockSceneViewer.module.css"

interface InputStockSceneViewerProps {
  planId?: string
  modelId?: string
  operationIdx?: number
  hideStock?: boolean
  pauseRender?: boolean
  handleClick3dView: () => void
  contextMenuId?: string
  handleSceneClickDataUpdateRef?: React.MutableRefObject<
    ((data: SceneClickData | undefined) => void) | undefined
  >
}

export const InputStockSceneViewer: FC<InputStockSceneViewerProps> = ({
  planId,
  modelId,
  operationIdx,
  hideStock = false,
  pauseRender,
  handleClick3dView,
  contextMenuId,
  handleSceneClickDataUpdateRef,
}) => {
  const [getModel, { data }] = useModelByIdLazyQuery()

  useEffect(() => {
    if (modelId) {
      getModel({ variables: { id: String(modelId) } })
    }
  }, [modelId, getModel])

  const viewerContainerClasses = `${styles.viewerContainer} ${styles.viewerContainerSmall}`

  return (
    <div className={viewerContainerClasses}>
      <TransferCanvas pauseRender={pauseRender}>
        <InputStockSceneLoader
          {...{
            planId,
            operationIdx,
            partModel: data?.model3D ?? undefined,
            hideStock,
            handleClick3dView,
            contextMenuId,
            handleSceneClickDataUpdateRef,
          }}
        />
      </TransferCanvas>
    </div>
  )
}

interface InputStockSceneLoaderProps {
  planId?: string
  operationIdx?: number
  partModel?: SceneModelFragment
  onLoad?: () => void
  hideStock?: boolean
  handleClick3dView: () => void
  contextMenuId?: string
  handleSceneClickDataUpdateRef?: React.MutableRefObject<
    ((data: SceneClickData | undefined) => void) | undefined
  >
}

const InputStockSceneLoader: FC<InputStockSceneLoaderProps> = ({
  partModel,
  planId,
  operationIdx,
  onLoad,
  children,
  hideStock = false,
  handleClick3dView,
  contextMenuId,
  handleSceneClickDataUpdateRef,
}) => {
  const cameraControls = useCameraControls()

  const inputStock = useSelector((state: RootState) =>
    storedOperationSelectors.selectInputStock(state, planId, operationIdx)
  )

  const sceneRef = useRef<THREE.Group>(new THREE.Group())

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )
  const coordinates = useSelector(viewOptionsSelectors.coordinates)
  const fixturesConfig = useSelector(fixturesSelectors.selectFixturesConfig)

  const [sceneEuler, setSceneEuler] = useState<THREE.Euler>()
  const [scenePosition, setScenePosition] = useState<THREE.Vector3>()

  const onClick = () => {
    handleClick3dView && handleClick3dView()
  }

  useSceneClickEventHandler(onClick)

  useEffect(() => {
    if (partModel) {
      const showAuxiliary = false
      let sceneTransformMatrix: THREE.Matrix4

      if (coordinates === "machine" && operation) {
        if (!showAuxiliary && fixturesConfig) {
          sceneTransformMatrix = getInverseFocusFixtureTransformMatrix(
            operation.mcs,
            operation.fixtures,
            fixturesConfig
          )
        } else {
          sceneTransformMatrix = getInverseMcsTransformMatrix(operation.mcs)
        }
      } else {
        sceneTransformMatrix = new THREE.Matrix4()
      }
      const min = new THREE.Vector3(partModel.minX, partModel.minY, partModel.minZ)
      const max = new THREE.Vector3(partModel.maxX, partModel.maxY, partModel.maxZ)
      const sceneBox = new THREE.Box3(min, max).clone().applyMatrix4(sceneTransformMatrix)
      if (sceneBox) {
        cameraControls.setSceneBox({ box: sceneBox }, { immediate: true })
      }
    }
  }, [partModel, cameraControls, coordinates, fixturesConfig, operation])

  useEffect(() => {
    let sceneTransformMatrix: THREE.Matrix4

    if (coordinates === "machine" && operation) {
      if (fixturesConfig) {
        sceneTransformMatrix = getInverseFocusFixtureTransformMatrix(
          operation.mcs,
          operation.fixtures,
          fixturesConfig
        )
      } else {
        sceneTransformMatrix = getInverseMcsTransformMatrix(operation.mcs)
      }
    } else {
      sceneTransformMatrix = new THREE.Matrix4()
    }

    const sceneEuler = quat2euler(
      new THREE.Quaternion().setFromRotationMatrix(sceneTransformMatrix)
    )
    const scenePosition = new THREE.Vector3().setFromMatrixPosition(sceneTransformMatrix)

    setSceneEuler(sceneEuler)
    setScenePosition(scenePosition)
  }, [coordinates, operation, fixturesConfig])

  const transferContext = useContext(TransferCanvasContext)

  // This function only updates the state sceneBox if the provided input differs by a minimum threshold
  return (
    <>
      <group ref={sceneRef} position={scenePosition} rotation={sceneEuler}>
        <PointLights distance={500} target={new THREE.Vector3(0, 0, 0)} />
        <PartScene model={partModel} onLoad={onLoad} />
        {!hideStock && (
          <StocksScene
            inputStock={inputStock}
            displayStock={DisplayStock.Input}
            displayMode={DisplayMode.Transparent}
            noAnimate
          />
        )}
        {transferContext && (
          <TransferAnimatedOrbitControls zoomOnMouse={false} enabled={true} enableDamping={false} />
        )}
      </group>
      {children}
      {contextMenuId && handleSceneClickDataUpdateRef && (
        <CanvasContextMenuListener
          contextMenuId={contextMenuId}
          handleSceneClickDataUpdateRef={handleSceneClickDataUpdateRef}
        />
      )}
    </>
  )
}
