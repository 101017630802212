import React from "react"
import { useSelector } from "react-redux"
import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  AddPatchOperationOpEnum,
  Alignment,
  AlignmentKindEnum,
  BoreFeature,
  BossFeature,
  EmptyToolchange,
  ExplicitMove,
  Inspection,
  InspectionKindEnum,
  IssueAcknowledgement,
  MachineOffset,
  MultiLevelBoreFeature,
  MultiLevelBossFeature,
  PlanchangerApi,
  PointsFeature,
  PointsFeatureKindEnum,
  PointsFeatureMove,
  PointsFeatureMoveKindEnum,
  PointsFeaturePoint,
  PointsFeaturePointKindEnum,
  ProbeToolchange,
  ProbingIssue,
  ProbingStepsIssueSelector,
  ProbingStepsIssueSelectorKindEnum,
  ReferencePositionMove,
  RemovePatchOperationOpEnum,
  ReplacePatchOperationOpEnum,
  SphereFeature,
  WcsBoreBoss,
  WcsEdgeSurface,
  WcsProbing,
  WcsProbingMove,
  WcsSpecifyCoords,
  WcsWebPocket,
} from "src/client-axios"
import { createRequiredOptionalContext } from "src/hooks/requiredOptionalContext"
import { useApi } from "src/hooks/useApi"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { probesSelectors } from "src/store/config/probes"
import { AppDispatch, RootState, useAppDispatch } from "src/store/rootStore"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import {
  PatchOperation,
  plansActions,
  storedOperationSelectors,
  storedPlansSelectors,
} from "./storedPlans"

const getDefaultProbingOperation = (state: RootState, planId: string, operationIdx: number) => {
  const machineRecord = storedOperationSelectors.selectMachineRecord(planId, operationIdx)(state)
  const firstMachineProbeId = machineRecord?.probes[0]
  const firstProbeId = `${probesSelectors.selectIds(state)[0]}`
  const probeId = firstMachineProbeId ?? firstProbeId

  return {
    op: AddPatchOperationOpEnum.Add,
    path: `/operations/${operationIdx}/probing`,
    value: {
      probeId: probeId,
      strategy: {
        toolsetting: [],
        wcsProbing: [],
        alignments: [],
        inspections: [],
      },
    },
  }
}

/**
 * Thunks
 */

export interface ProbingThunkProps {
  planId: string | undefined
  operationIdx: number | undefined
  planchangerApi: PlanchangerApi
}

export const [ProbingThunkContext, useProbingThunkContext] = createRequiredOptionalContext<
  ProbingThunkProps & { dispatch: AppDispatch }
>("ProbingThunkContext")

export const ActiveProbingThunkContextProvider: React.FC = ({ children }) => {
  const { planchangerApi } = useApi()
  const dispatch = useAppDispatch()
  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  return (
    <ProbingThunkContext.Provider value={{ planId, operationIdx, planchangerApi, dispatch }}>
      {children}
    </ProbingThunkContext.Provider>
  )
}

const fetchPlanById = createAsyncThunk(
  "storedPlans/fetchPlanById",
  async (
    {
      planId,
      planchangerApi,
      revision,
    }: { planId: string; planchangerApi: PlanchangerApi; revision?: number },
    thunkAPI
  ) => {
    try {
      const response = await planchangerApi.getPlanchangerPlan(planId, revision)
      thunkAPI.dispatch(
        plansActions.upsert({
          id: planId,
          plan: response.data,
          revision: +response.headers["planchanger-revision"],
          noUpdates: revision !== undefined,
        })
      )
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch plan: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

const fetchPlansByIds = createAsyncThunk(
  "storedPlans/fetchPlanById",
  async (
    {
      planIds,
      planchangerApi,
      revision,
    }: { planIds: string[]; planchangerApi: PlanchangerApi; revision?: number },
    thunkAPI
  ) => {
    try {
      const responsePromises = planIds.map(planId => {
        return planchangerApi.getPlanchangerPlan(planId, revision)
      })
      Promise.all(responsePromises).then(responses => {
        const storedPlans = responses.map((response, i) => {
          return {
            id: planIds[i],
            plan: response.data,
            revision: +response.headers["planchanger-revision"],
            noUpdates: revision !== undefined,
          }
        })
        thunkAPI.dispatch(plansActions.addMany(storedPlans))
      })
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch plan: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

const setProbingProgramProbeId = createAsyncThunk(
  "setProbingProgramProbeId",
  async (
    { planId, operationIdx, planchangerApi, probeId }: ProbingThunkProps & { probeId: string },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) return

    const changeToken = activeSelectors.selectChangeToken(state)

    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path: `/operations/${operationIdx}/probing/probeId`,
        value: probeId,
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set probe ID: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const setSafeMovesOn = createAsyncThunk(
  "setSafeMovesOn",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      safeMovesOn,
    }: ProbingThunkProps & { safeMovesOn: boolean },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) return

    const changeToken = activeSelectors.selectChangeToken(state)

    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path: `/operations/${operationIdx}/probing/useMspSafeMoves`,
        value: safeMovesOn,
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set use safe moves to ${safeMovesOn}: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const setSpindleTravelLimitMove = createAsyncThunk(
  "setSpindleTravelLimitMove",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      spindleTravelLimitMove,
    }: ProbingThunkProps & { spindleTravelLimitMove: number },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) return

    const changeToken = activeSelectors.selectChangeToken(state)

    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path: `/operations/${operationIdx}/probing/spindleTravelLimitMove`,
        value: spindleTravelLimitMove,
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set use safe moves to ${spindleTravelLimitMove}: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const setProbingIssueAcknowledgements = createAsyncThunk(
  "setProbingIssueAcknowledgements",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      userEmail,
      issuesToAck,
    }: ProbingThunkProps & {
      userEmail: string
      issuesToAck: Array<ProbingIssue & { acknowledged: boolean }>
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined || !(issuesToAck.length > 0)) return
    const state = (await thunkAPI.getState()) as RootState

    const operation = storedPlansSelectors.selectOperation(state, planId, operationIdx)
    const opIssueAcks = operation?.issueAcknowledgements ?? []

    const changeToken = activeSelectors.selectChangeToken(state)

    const issueAcksToRemove = issuesToAck.filter(issue => !issue.acknowledged)

    const issueAcks = opIssueAcks.filter(
      issueAck =>
        issueAck.selector.kind !== ProbingStepsIssueSelectorKindEnum.ProbingSteps ||
        !issueAcksToRemove.some(issueAckToRemove => {
          const issueAckSelector = issueAck.selector as ProbingStepsIssueSelector
          return (
            (issueAckToRemove.stepId === issueAckSelector.probingStepId ||
              issueAckToRemove.stepId === "None") &&
            issueAckToRemove.tag === issueAckSelector.issueTag
          )
        })
    )

    const timestamp = new Date().toJSON()
    const issueAcksToAdd: Array<IssueAcknowledgement> = issuesToAck
      .filter(issue => issue.acknowledged && issue.tag)
      .map(issue => ({
        user: userEmail,
        timestamp,
        selector: {
          kind: ProbingStepsIssueSelectorKindEnum.ProbingSteps,
          issueTag: issue.tag || "",
          probingStepId: issue.stepId === "None" ? undefined : issue.stepId,
        },
      }))

    const operations = [
      {
        op: AddPatchOperationOpEnum.Add,
        path: `/operations/${operationIdx}/issueAcknowledgements`,
        value: [...issueAcks, ...issueAcksToAdd],
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set issue acknowledgements: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const setOffsets = createAsyncThunk(
  "setOffsets",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      offsets,
    }: ProbingThunkProps & {
      offsets: MachineOffset[]
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState

    const changeToken = activeSelectors.selectChangeToken(state)

    const operations = [
      {
        op: AddPatchOperationOpEnum.Add,
        path: `/operations/${operationIdx}/offsets`,
        value: offsets,
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set machine controls: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const setMachineKindUnacknowledged = createAsyncThunk(
  "setMachineKindUnacknowledged",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      machineKindUnacknowledged,
    }: ProbingThunkProps & {
      machineKindUnacknowledged: boolean
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState

    const changeToken = activeSelectors.selectChangeToken(state)

    const operations = [
      {
        op: AddPatchOperationOpEnum.Add,
        path: `/operations/${operationIdx}/machineKindUnacknowledged`,
        value: machineKindUnacknowledged,
      },
    ]
    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to set machine kind acknowledgement: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const deleteProbingSteps = createAsyncThunk(
  "deleteProbingSteps",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      idsToRemove,
    }: ProbingThunkProps & { idsToRemove: Set<string> },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const strategy = storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy

    const alignments = strategy?.alignments
    const inspections = strategy?.inspections
    const wcsProbings = strategy?.wcsProbing

    let stepsWithRemoved: (
      | BoreFeature
      | BossFeature
      | MultiLevelBoreFeature
      | MultiLevelBossFeature
      | SphereFeature
      | ExplicitMove
      | PointsFeature
      | WcsSpecifyCoords
      | WcsProbingMove
      | WcsBoreBoss
      | WcsWebPocket
      | WcsEdgeSurface
      | ProbeToolchange
      | EmptyToolchange
      | ReferencePositionMove
    )[] = []

    const operations: {
      op: RemovePatchOperationOpEnum
      path: string
    }[] = []

    alignments?.forEach((alignment, sectionIdx) => {
      const alignmentStepsToRemove = alignment?.steps.flatMap((step, i) =>
        idsToRemove.has(step.id) ? [i] : []
      )

      if (alignmentStepsToRemove.length) {
        stepsWithRemoved = alignment.steps
      }

      const alignmentOperations = alignmentStepsToRemove
        ?.sort()
        .reverse()
        .map(stepIdx => ({
          op: RemovePatchOperationOpEnum.Remove,
          path: `/operations/${operationIdx}/probing/strategy/alignments/${sectionIdx}/steps/${stepIdx}`,
        }))

      alignments.length && operations.push(...alignmentOperations)
    })

    inspections?.forEach((inspection, sectionIdx) => {
      const inspectionStepsToRemove = inspection?.steps.flatMap((step, i) =>
        idsToRemove.has(step.id) ? [i] : []
      )

      if (inspectionStepsToRemove.length) {
        stepsWithRemoved = inspection.steps
      }

      const inspectionOperations = inspectionStepsToRemove
        ?.sort()
        .reverse()
        .map(stepIdx => ({
          op: RemovePatchOperationOpEnum.Remove,
          path: `/operations/${operationIdx}/probing/strategy/inspections/${sectionIdx}/steps/${stepIdx}`,
        }))

      inspections.length && operations.push(...inspectionOperations)
    })

    wcsProbings?.forEach((wcsProbing, sectionIdx) => {
      const wcsProbingStepsToRemove = wcsProbing?.steps.flatMap((step, i) =>
        idsToRemove.has(step.id) ? [i] : []
      )

      if (wcsProbingStepsToRemove.length) {
        stepsWithRemoved = wcsProbing.steps
      }

      const wcsProbingOperations = wcsProbingStepsToRemove
        ?.sort()
        .reverse()
        .map(stepIdx => ({
          op: RemovePatchOperationOpEnum.Remove,
          path: `/operations/${operationIdx}/probing/strategy/wcsProbing/${sectionIdx}/steps/${stepIdx}`,
        }))

      wcsProbingOperations.length && operations.push(...wcsProbingOperations)
    })

    const changeToken = activeSelectors.selectChangeToken(state)

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to delete probing steps: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    } finally {
      const removedId = [...idsToRemove][0]
      const removedIndex = stepsWithRemoved.findIndex(step => step.id === removedId)

      let newActiveProbeStepId

      if (stepsWithRemoved?.[removedIndex - 1]) {
        newActiveProbeStepId = stepsWithRemoved?.[removedIndex - 1].id
      } else if (stepsWithRemoved?.[removedIndex + 1]) {
        newActiveProbeStepId = stepsWithRemoved?.[removedIndex + 1].id
      }
      newActiveProbeStepId &&
        thunkAPI.dispatch(activeActions.setActiveProbeStepId(newActiveProbeStepId))
    }
  }
)

const deleteSubProbingSteps = createAsyncThunk(
  "deleteSubProbingSteps",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      sectionName,
      sectionIdx,
      idsToRemove,
    }: ProbingThunkProps & {
      idsToRemove: Set<string>
      sectionName: "alignments" | "inspections"
      sectionIdx: number
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const steps =
      storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy[sectionName]?.[
        sectionIdx
      ]?.steps ?? []

    let pointsFeatureWithRemoved: PointsFeature | undefined

    let stepIdx: number
    const subStepIndexes: number[] = []
    steps.forEach((step, i) => {
      if (step.kind === PointsFeatureKindEnum.PointsFeature) {
        step.steps.forEach((subStep, j) => {
          if (idsToRemove.has(subStep.id)) {
            stepIdx = i
            pointsFeatureWithRemoved = step
            subStepIndexes.push(j)
          }
        })
      }
    })

    const operations = subStepIndexes
      .map(subStepIndex => {
        return {
          op: RemovePatchOperationOpEnum.Remove,
          path: `/operations/${operationIdx}/probing/strategy/${sectionName}/${sectionIdx}/steps/${stepIdx}/steps/${subStepIndex}`,
        }
      })
      .reverse()

    const changeToken = activeSelectors.selectChangeToken(state)

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to delete probing steps: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    } finally {
      const removedId = [...idsToRemove][0]
      const subSteps = pointsFeatureWithRemoved?.steps
      const removedIndex = subSteps?.findIndex(step => step.id === removedId)

      let newActiveProbeStepId

      if (removedIndex !== undefined) {
        if (subSteps?.[removedIndex - 1]) {
          newActiveProbeStepId = subSteps?.[removedIndex - 1].id
        } else if (subSteps?.[removedIndex + 1]) {
          newActiveProbeStepId = subSteps?.[removedIndex + 1].id
        } else {
          newActiveProbeStepId = pointsFeatureWithRemoved?.id
        }
        newActiveProbeStepId &&
          thunkAPI.dispatch(activeActions.setActiveProbeStepId(newActiveProbeStepId))
      }
    }
  }
)

const deleteProbingSection = createAsyncThunk(
  "deleteProbingSection",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      sectionName,
      sectionIdx,
    }: ProbingThunkProps & {
      sectionName: "alignments" | "inspections"
      sectionIdx: number
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState

    const operation = {
      op: RemovePatchOperationOpEnum.Remove,
      path: `/operations/${operationIdx}/probing/strategy/${sectionName}/${sectionIdx}`,
    }

    const changeToken = activeSelectors.selectChangeToken(state)

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations: [operation] }))
      await planchangerApi.applyPatch(planId, [operation], changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to delete probing section: ${err}`,
          intent: "danger",
        })
      )
      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const createProbingSteps = createAsyncThunk(
  "createProbingSteps",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      sectionKind,
      sectionIdx,
      sectionStepId,
      probeStepId,
      newSteps,
      offset = 0,
    }: ProbingThunkProps & {
      sectionStepId?: string | undefined
      probeStepId?: string | undefined
      newSteps: (PointsFeatureMove | PointsFeaturePoint | ProbingStep)[]
      offset?: number
      sectionKind: AlignmentKindEnum.Alignment | InspectionKindEnum.Inspection
      sectionIdx: number
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const operations: PatchOperation[] = []
    const state = (await thunkAPI.getState()) as RootState

    const changeToken = activeSelectors.selectChangeToken(state)
    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) {
      operations.push(getDefaultProbingOperation(state, planId, operationIdx))
    }

    let sectionName: "alignments" | "inspections"
    if (sectionKind === AlignmentKindEnum.Alignment) {
      sectionName = "alignments"
    } else {
      sectionName = "inspections"
    }
    const sectionSteps = probing?.strategy?.[sectionName]?.[sectionIdx]?.steps ?? []

    let sectionStepIdx
    let probeStepIdx

    if (sectionStepId) {
      sectionStepIdx = sectionSteps.findIndex(step => step.id === sectionStepId)
    } else {
      sectionStepIdx = sectionSteps.findIndex(sectionStep => {
        if (!(sectionStep.kind === PointsFeatureKindEnum.PointsFeature)) return false
        return sectionStep.steps.some((step, i) => {
          if (step.id === probeStepId) {
            probeStepIdx = i + offset
            return step.id === probeStepId
          } else {
            return false
          }
        })
      })
    }

    if (probeStepId === undefined) {
      probeStepIdx = (sectionSteps[sectionStepIdx] as PointsFeature).steps.length
    }

    const path = `/operations/${operationIdx}/probing/strategy/${sectionName}/${sectionIdx}/steps/${sectionStepIdx}/steps/${probeStepIdx}`
    operations.push(
      ...newSteps
        .slice(0)
        .reverse()
        .map(value => ({
          op: AddPatchOperationOpEnum.Add,
          path,
          value,
        }))
    )

    try {
      thunkAPI.dispatch(
        plansActions.applyPatch({
          id: planId,
          operations: JSON.parse(JSON.stringify(operations)) as PatchOperation[],
        })
      )
      !!newSteps.length &&
        thunkAPI.dispatch(activeActions.setActiveProbeStepId(newSteps[newSteps.length - 1].id))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add probing steps: ${err}`,
          intent: "danger",
        })
      )
      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const createWcsProbingSection = createAsyncThunk(
  "createWcsProbingSection",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      section,
    }: ProbingThunkProps & {
      section: WcsProbing
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const operations: PatchOperation[] = []
    const state = (await thunkAPI.getState()) as RootState

    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) {
      operations.push(getDefaultProbingOperation(state, planId, operationIdx))
    }

    const currentSteps = probing?.strategy.wcsProbing ?? []
    const changeToken = activeSelectors.selectChangeToken(state)
    const stepIdx = currentSteps?.length
    const path = `/operations/${operationIdx}/probing/strategy/wcsProbing/${stepIdx}`
    operations.push({
      op: AddPatchOperationOpEnum.Add,
      path,
      value: section,
    })

    try {
      thunkAPI.dispatch(
        plansActions.applyPatch({
          id: planId,
          operations: JSON.parse(JSON.stringify(operations)) as PatchOperation[],
        })
      )
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add WcsProbing: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const createAlignmentSection = createAsyncThunk(
  "createAlignmentSection",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      section,
    }: ProbingThunkProps & {
      section: Alignment
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const operations: PatchOperation[] = []
    const state = (await thunkAPI.getState()) as RootState

    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) {
      operations.push(getDefaultProbingOperation(state, planId, operationIdx))
    }

    const currentSteps = probing?.strategy.alignments ?? []
    const changeToken = activeSelectors.selectChangeToken(state)
    const stepIdx = currentSteps?.length
    const path = `/operations/${operationIdx}/probing/strategy/alignments/${stepIdx}`
    operations.push({
      op: AddPatchOperationOpEnum.Add,
      path,
      value: section,
    })

    try {
      thunkAPI.dispatch(
        plansActions.applyPatch({
          id: planId,
          operations: JSON.parse(JSON.stringify(operations)) as PatchOperation[],
        })
      )
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add alignment: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const createInspectionSection = createAsyncThunk(
  "createInspectionSection",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      section,
    }: ProbingThunkProps & {
      section: Inspection
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const operations: PatchOperation[] = []
    const state = (await thunkAPI.getState()) as RootState

    const probing = storedOperationSelectors.selectProbing(state, planId, operationIdx)
    if (!probing) {
      operations.push(getDefaultProbingOperation(state, planId, operationIdx))
    }

    const currentSteps = probing?.strategy.inspections ?? []
    const changeToken = activeSelectors.selectChangeToken(state)
    const stepIdx = currentSteps?.length
    const path = `/operations/${operationIdx}/probing/strategy/inspections/${stepIdx}`
    operations.push({
      op: AddPatchOperationOpEnum.Add,
      path,
      value: section,
    })

    try {
      thunkAPI.dispatch(
        plansActions.applyPatch({
          id: planId,
          operations: JSON.parse(JSON.stringify(operations)) as PatchOperation[],
        })
      )
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add Inspection: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const updateProbingStep = createAsyncThunk(
  "updateProbingStep",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      sectionName,
      sectionIdx,
      updatedStep,
    }: ProbingThunkProps & {
      updatedStep: PointsFeatureMove | PointsFeaturePoint | ProbingStep | WcsProbingStep
      sectionName: "alignments" | "inspections"
      // | "wcsProbing"
      sectionIdx: number
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const currentSteps =
      storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy[sectionName]?.[
        sectionIdx
      ]?.steps ?? []
    const changeToken = activeSelectors.selectChangeToken(state)

    let path
    if (
      updatedStep.kind === PointsFeatureMoveKindEnum.PointsFeatureMove ||
      updatedStep.kind === PointsFeaturePointKindEnum.PointsFeaturePoint
    ) {
      let probeIdx
      const stepIdx = currentSteps.findIndex(sectionStep => {
        if (!(sectionStep.kind === PointsFeatureKindEnum.PointsFeature)) return false

        return sectionStep.steps.some((step, i) => {
          if (step.id === updatedStep.id) {
            probeIdx = i
            return step.id === updatedStep.id
          } else {
            return false
          }
        })
      })

      path = `/operations/${operationIdx}/probing/strategy/${sectionName}/${sectionIdx}/steps/${stepIdx}/steps/${probeIdx}`
    } else {
      const stepIdx = currentSteps.findIndex(step => step.id === updatedStep.id)

      if (stepIdx === -1) {
        thunkAPI.dispatch(
          activeActions.setActiveToastMessage({
            message: `Updated step was not already present`,
            intent: "danger",
          })
        )

        return
      }
      path = `/operations/${operationIdx}/probing/strategy/${sectionName}/${sectionIdx}/steps/${stepIdx}`
    }
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedStep,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add update probing step: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const updateWcsProbing = createAsyncThunk(
  "updateWcsProbing",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      updatedWcsProbing,
    }: ProbingThunkProps & { updatedWcsProbing: WcsProbing },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const currentWcsProbings =
      storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy.wcsProbing ?? []
    const changeToken = activeSelectors.selectChangeToken(state)

    const wcsProbingIdx = currentWcsProbings.findIndex(
      alignment => alignment.id === updatedWcsProbing.id
    )
    if (wcsProbingIdx === -1) {
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Updated WcsProbing was not already present`,
          intent: "danger",
        })
      )

      return
    }
    const path = `/operations/${operationIdx}/probing/strategy/wcsProbing/${wcsProbingIdx}`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedWcsProbing,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add updated wcsProbing: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const updateAlignment = createAsyncThunk(
  "updateAlignment",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      updatedAlignment,
    }: ProbingThunkProps & { updatedAlignment: Alignment },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const currentAlignments =
      storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy.alignments ?? []
    const changeToken = activeSelectors.selectChangeToken(state)

    const alignmentIdx = currentAlignments.findIndex(
      alignment => alignment.id === updatedAlignment.id
    )
    if (alignmentIdx === -1) {
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Updated alignment was not already present`,
          intent: "danger",
        })
      )

      return
    }
    const path = `/operations/${operationIdx}/probing/strategy/alignments/${alignmentIdx}`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedAlignment,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add updated alignment: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)
const updateAlignments = createAsyncThunk(
  "updateAlignments",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      updatedAlignments,
    }: ProbingThunkProps & {
      updatedAlignments: Alignment[]
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const changeToken = activeSelectors.selectChangeToken(state)
    const path = `/operations/${operationIdx}/probing/strategy/alignments`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedAlignments,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to update alignments: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)
const updateInspections = createAsyncThunk(
  "updateInspections",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      updatedInspections,
    }: ProbingThunkProps & {
      updatedInspections: Inspection[]
    },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const changeToken = activeSelectors.selectChangeToken(state)
    const path = `/operations/${operationIdx}/probing/strategy/inspections`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedInspections,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to update inspections: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

const updateInspection = createAsyncThunk(
  "updateInspection",
  async (
    {
      planId,
      operationIdx,
      planchangerApi,
      updatedInspection,
    }: ProbingThunkProps & { updatedInspection: Inspection },
    thunkAPI
  ) => {
    if (!planId || operationIdx === undefined) return

    const state = (await thunkAPI.getState()) as RootState
    const currentInspections =
      storedOperationSelectors.selectProbing(state, planId, operationIdx)?.strategy.inspections ??
      []
    const changeToken = activeSelectors.selectChangeToken(state)

    const inspectionIdx = currentInspections.findIndex(
      inspectionIdx => inspectionIdx.id === updatedInspection.id
    )
    if (inspectionIdx === -1) {
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Updated Inspection was not already present`,
          intent: "danger",
        })
      )

      return
    }
    const path = `/operations/${operationIdx}/probing/strategy/inspections/${inspectionIdx}`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedInspection,
      },
    ]

    try {
      thunkAPI.dispatch(plansActions.applyPatch({ id: planId, operations }))
      await planchangerApi.applyPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to add updated Inspection: ${err}`,
          intent: "danger",
        })
      )

      thunkAPI.dispatch(fetchPlanById({ planId, planchangerApi }))
    }
  }
)

export const storedPlanThunks = {
  fetchPlanById,
  fetchPlansByIds,
  createProbingSteps,
  createWcsProbingSection,
  createAlignmentSection,
  createInspectionSection,
  updateProbingStep,
  updateAlignment,
  updateAlignments,
  updateWcsProbing,
  updateInspection,
  updateInspections,
  deleteProbingSteps,
  deleteProbingSection,
  deleteSubProbingSteps,
  setProbingProgramProbeId,
  setSafeMovesOn,
  setProbingIssueAcknowledgements,
  setOffsets,
  setMachineKindUnacknowledged,
  setSpindleTravelLimitMove,
}
