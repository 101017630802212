import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { PopoverInteractionKind, Position } from "@blueprintjs/core"
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import { isEqual } from "lodash-es"

import { GuardrailFeedback, GuardrailFeedbackLevel, StageEnum } from "src/client-axios"
import {
  useFileByLocatorQuery,
  useFilesByIDsQuery,
  useGuardrailRunsByPlanIdQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { guardrailInfoIcon } from "src/pages/GCodeSetup/guardrailInfoIcon"
import { guardrailProgressIcon } from "src/pages/GCodeSetup/guardrailProgressIcon"
import { stagesEnumMap } from "src/pages/GCodeSetup/GuardrailStagesPipeline/GuardrailStagesPipeline"
import {
  CurrentOperationSimPanel,
  SimTaskRunRequiredCheck,
} from "src/pages/ViewerPage/CuttingSim/SimTaskRunRequired"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

import styles from "./GuardrailInfo.module.css"

export const GuardrailInfo: FC<{
  planId: string
  operationIdx: number
  jobUri?: string
  locked?: boolean
}> = ({ planId, operationIdx, jobUri, locked }) => {
  const { tasksApi, plansApi } = useApi()

  const [simRequiredCheckIsOpen, setSimRequiredCheckIsOpen] = useState(false)
  const [simRequiredReasons, setSimRequiredReasons] = useState<string[]>([])
  const [simPanelIsOpen, setSimPanelIsOpen] = useState(false)
  const [feedbacks, setFeedbacks] = useState<GuardrailFeedback[] | undefined>(undefined)

  const selectToolpathProject = useMemo(
    () => storedOperationSelectors.createSelectToolpathProject(planId, operationIdx),
    [planId, operationIdx]
  )

  const toolpathProject = useSelector(selectToolpathProject)

  const { data: toolpathProjectNcFile } = useFileByLocatorQuery({
    variables: {
      locator: toolpathProject?.ncFileLocator ?? "",
    },
  })

  const { data: toolpathProjectDocumentFile } = useFileByLocatorQuery({
    variables: {
      locator: toolpathProject?.documentFileLocator ?? "",
    },
  })

  const fileIds = []
  if (toolpathProjectNcFile?.fileByLocator?.id) fileIds.push(toolpathProjectNcFile.fileByLocator.id)
  if (toolpathProjectDocumentFile?.fileByLocator?.id)
    fileIds.push(toolpathProjectDocumentFile.fileByLocator.id)

  const { data: filesData } = useFilesByIDsQuery({
    variables: {
      ids: fileIds,
    },
  })

  const cuttingNcFile = filesData?.files?.nodes.filter(
    node => node.locator === toolpathProject?.ncFileLocator
  )[0]

  const { data: guardrailRuns } = useGuardrailRunsByPlanIdQuery({
    variables: {
      planId,
    },
    pollInterval: 1000,
  })

  const operation = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx),
    isEqual
  )

  const guardrailRunsByOperationId = useMemo(() => {
    return guardrailRuns?.guardrailRuns?.nodes
      .filter(node => {
        return node.operationId === operation?.id
      })
      .sort((a, b) => {
        return b.planRevision - a.planRevision
      })
  }, [guardrailRuns, operation])

  useEffect(() => {
    const manifestUri = guardrailRunsByOperationId?.[0]?.manifestUri
    if (manifestUri !== undefined && manifestUri !== null) {
      plansApi.getGuardrailsManifestData(manifestUri).then(val => {
        setFeedbacks(val.data)
      })
    }
  }, [guardrailRunsByOperationId, plansApi])

  const groupedFeedbacks: [StageEnum, GuardrailFeedback[]][] = useMemo(
    () =>
      [
        ...(feedbacks
          ?.filter(feedback => {
            return [
              StageEnum.Probing,
              StageEnum.PreSimulation,
              StageEnum.Simulation,
              StageEnum.PostSimulation,
            ].includes(feedback.stage)
          })
          ?.reduce((acc: Map<StageEnum, GuardrailFeedback[]>, feedback) => {
            const group: StageEnum = feedback.stage
            acc.set(group, [...(acc.get(group) ?? []), feedback])
            return acc
          }, new Map())
          .entries() ?? []),
      ].sort((a, b) => {
        return (
          preferredGuardrailStagesOrder.indexOf(a[0]) - preferredGuardrailStagesOrder.indexOf(b[0])
        )
      }),
    [feedbacks]
  )

  const runGuardrail = () => {
    if (locked) {
      return
    }
    plansApi.camSinceLastRunChanges(planId, operationIdx).then(({ data: changes }) => {
      if (changes.length <= 0) {
        tasksApi.guardrailsRunChecks(planId, operationIdx)
      } else {
        setSimRequiredCheckIsOpen(true)
        setSimRequiredReasons(changes)
      }
    })
  }

  const popoverContent = (
    <div className={styles.popoverContainer}>
      <div className={styles.tableHeader}>Guardrail Run Details</div>
      {groupedFeedbacks?.map(([stage, group]) => {
        return (
          <div className={styles.tableRow} key={stage}>
            <div className={styles.tableRowLeft}>
              <div className={styles.tableRowIcon}>
                <Link
                  to={`/nc-setup/${planId}/${operationIdx}/${cuttingNcFile?.id}/${jobUri}?stageName=${stage}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {guardrailProgressIcon(group)}
                </Link>
              </div>
              <div>{stagesEnumMap[stage]}</div>
            </div>
          </div>
        )
      })}

      <div className={styles.bottomButtons}>
        <Tooltip2 position="left" content={"Run Guardrails"} openOnTargetFocus={false}>
          <Icon
            icon="play"
            intent={"none"}
            onClick={runGuardrail}
            className={styles.runGuardrailsButton}
            color={locked ? "rgba(95, 107, 124, 0.6)" : undefined}
          />
        </Tooltip2>

        {groupedFeedbacks && (
          <div className={styles.viewMore}>
            <Link
              to={`/nc-setup/${planId}/${operationIdx}/${cuttingNcFile?.id}/${jobUri}`}
              target={"_blank"}
              rel="noreferrer"
            >
              View Details
            </Link>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      <Popover2
        minimal
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.TOP}
        content={popoverContent}
      >
        <div className={styles.guardrailIcons}>
          <GuardrailStatusIcon feedbacks={feedbacks} camIsEqual={!simRequiredCheckIsOpen} />
        </div>
      </Popover2>

      {simRequiredCheckIsOpen && (
        <SimTaskRunRequiredCheck
          isOpen={simRequiredCheckIsOpen}
          onSubmit={() => {
            setSimRequiredCheckIsOpen(false)
            setSimRequiredReasons([])
            setSimPanelIsOpen(true)
          }}
          onClose={() => {
            setSimRequiredCheckIsOpen(false)
            setSimRequiredReasons([])
          }}
          reasons={simRequiredReasons}
        />
      )}
      {simPanelIsOpen && (
        <CurrentOperationSimPanel
          isOpen={simPanelIsOpen}
          onClose={() => {
            setSimPanelIsOpen(false)
          }}
        />
      )}
    </div>
  )
}

export const preferredGuardrailStagesOrder = [
  StageEnum.Plan,
  StageEnum.Setup,
  StageEnum.Probing,
  StageEnum.PreSimulation,
  StageEnum.Simulation,
  StageEnum.PostSimulation,
]

export const GuardrailStatusIcon: FC<{
  feedbacks?: GuardrailFeedback[]
  camIsEqual?: boolean
}> = ({ feedbacks, camIsEqual }) => {
  if (feedbacks === undefined || feedbacks.length === 0) {
    return (
      <Tooltip2 position="left" content={"Guardrails haven't run"} openOnTargetFocus={false}>
        <Icon icon="info-sign" iconSize={16} />
      </Tooltip2>
    )
  }

  if (!camIsEqual) {
    return (
      <Tooltip2
        position="left"
        content={
          "Operation setup has changed such that a Vericut simulation is required to properly simulate guardrails."
        }
        openOnTargetFocus={false}
      >
        <Icon icon="info-sign" iconSize={16} />
      </Tooltip2>
    )
  }

  return <>{guardrailInfoIcon(feedbacks)}</>
}

export const guardrailStatusIcon: (status: GuardrailFeedbackLevel) => JSX.Element | null = (
  status: GuardrailFeedbackLevel
) => {
  if (status === GuardrailFeedbackLevel.Failure) {
    return (
      <Tooltip2 content={"Failure"} openOnTargetFocus={false}>
        <Icon icon="error" intent={"danger"} iconSize={16} />
      </Tooltip2>
    )
  } else if (status === GuardrailFeedbackLevel.Error) {
    return (
      <Tooltip2 content={"Error"} openOnTargetFocus={false}>
        <Icon icon="error" intent={"danger"} iconSize={16} />
      </Tooltip2>
    )
  } else if (status === GuardrailFeedbackLevel.Success) {
    return (
      <Tooltip2 content={"Success"} openOnTargetFocus={false}>
        <Icon icon="tick-circle" intent={"success"} iconSize={16} />
      </Tooltip2>
    )
  } else if (status === GuardrailFeedbackLevel.Warning) {
    return (
      <Tooltip2 content={"Warning"} openOnTargetFocus={false}>
        <Icon icon="warning-sign" intent={"warning"} iconSize={16} />
      </Tooltip2>
    )
  } else return null
}
