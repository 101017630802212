import React, { FC, useEffect, useMemo, useState } from "react"
import { useThree } from "@react-three/fiber"

import { Node as SceneNode } from "../..//generated/bindings/Node"
import { Scene } from "../../generated/bindings/Scene"
import { convertToMatrix } from "../../util/geometry"
import { AnimatedMatrixGroup } from "../AnimatedMatrixGroup"
import { CoordinateSystem } from "./CoordinateSystem"
import { NodeShapeModel } from "./NodeShapeModel"
import { ViewCallback } from "./OpSceneViewState"
import { NodeObj3dCallback } from "./types"

export const OpSceneNode: FC<{
  scene: Scene
  view: ViewCallback
  node: SceneNode
  nodeObj?: NodeObj3dCallback
}> = ({ scene, node, view, nodeObj }) => {
  const matrix = useMemo(() => convertToMatrix(node.transform), [node.transform])
  const { camera } = useThree()
  const [obj3d, setObj3d] = useState<THREE.Object3D | null>(null)

  useEffect(() => {
    if (nodeObj) {
      nodeObj(node, obj3d)
    }
  }, [nodeObj, node, obj3d])

  return (
    <AnimatedMatrixGroup matrix={matrix}>
      <NodeShapeModel
        node={node}
        attributes={scene.node_attributes}
        view={view}
        obj3dCallback={setObj3d}
      />
      {node.object?.kind === "coords" && (
        <CoordinateSystem label={node.id} camera={camera} obj3dCallback={setObj3d} />
      )}
      <NodeChildren scene={scene} view={view} node={node} nodeObj={nodeObj} />
    </AnimatedMatrixGroup>
  )
}

const NodeChildren: FC<{
  scene: Scene
  view: ViewCallback
  node: SceneNode
  nodeObj?: NodeObj3dCallback
}> = ({ scene, view, node, nodeObj }) => {
  return (
    <>
      {node.children?.map(id => {
        const node = scene.nodes[id]

        if (node) {
          return <OpSceneNode key={id} scene={scene} view={view} node={node} nodeObj={nodeObj} />
        } else {
          console.warn("No Node found with id", id)
          return null
        }
      })}
    </>
  )
}
