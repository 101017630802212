import * as React from "react"
import { FC } from "react"
import { a, to } from "@react-spring/three"

import { PartialVector } from "src/client-axios"
import { MaybeFluidValue } from "src/util/animation/mergeValues"

interface AnimatedMovementNodeProps {
  deltaParameter?: MaybeFluidValue<number>
  movement?: PartialVector
}

export const AnimatedMovementNode: FC<AnimatedMovementNodeProps> = ({
  deltaParameter,
  movement,
  children,
}) => {
  const [x, y, z] = [movement?.x ?? 0, movement?.y ?? 0, movement?.z ?? 0]

  let position: MaybeFluidValue<[number, number, number]>
  if (deltaParameter === undefined) {
    position = [0, 0, 0]
  } else if (typeof deltaParameter === "number") {
    position = [deltaParameter * x, deltaParameter * y, deltaParameter * z]
  } else {
    position = to([deltaParameter], o => [o * x, o * y, o * z])
  }

  return (
    <a.group position={(position as unknown) as [x: number, y: number, z: number]}>
      {children}
    </a.group>
  )
}
