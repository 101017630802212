import { useEffect, useState } from "react"

import { useApi } from "src/hooks/useApi"
import { BaseManifest } from "src/util/plm"

export function useSimulationManifestUri(
  planId: string | undefined,
  operationIdx: number | undefined,
  count: number
): string | undefined {
  const [manifestUri, setManifestUri] = useState<string>()

  const { filesApi } = useApi()
  useEffect(() => {
    if (planId !== undefined && operationIdx !== undefined) {
      filesApi
        .getManifestUris(planId, operationIdx)
        .then(val => {
          const simulationUri = val.data.simulation
          setManifestUri(simulationUri)
        })
        .catch(() => {
          setManifestUri(undefined)
        })
    }
  }, [filesApi, planId, operationIdx, setManifestUri, count])

  return manifestUri
}

export function useSimulationManifest(
  planId: string | undefined,
  operationIdx: number | undefined,
  count: number
): BaseManifest | undefined {
  const [manifest, setManifest] = useState<BaseManifest>()

  const { planchangerApi, filesApi } = useApi()
  useEffect(() => {
    if (planId !== undefined && operationIdx !== undefined) {
      filesApi
        .getManifestUris(planId, operationIdx)
        .then(val => {
          const simulationUri = val.data.simulation
          if (simulationUri) {
            planchangerApi.getManifest(simulationUri).then(val => {
              setManifest((val?.data as unknown) as BaseManifest)
            })
          }
        })
        .catch(() => {
          setManifest(undefined)
        })
    }
  }, [planchangerApi, filesApi, planId, operationIdx, setManifest, count])

  return manifest
}
