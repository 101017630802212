import React, { FC, useEffect, useState } from "react"
import { EditableText, Menu, MenuItem } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { ContextTarget } from "src/components/Generic/ContextTarget/ContextTarget"
import {
  useCamJobDetailsQuery,
  useUpdateCamJobMaterialDisplayNameMutation,
} from "src/graphql/generated"

import styles from "./MaterialLabel.module.css"

const MaterialLabel: FC<{
  jobId: string
}> = ({ jobId }) => {
  const { data } = useCamJobDetailsQuery({
    variables: { jobId },
  })

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [material, setMaterial] = useState<string | undefined>()
  const [updateCamJobMaterialDisplayNameMutation] = useUpdateCamJobMaterialDisplayNameMutation()

  useEffect(() => {
    setMaterial(data?.camJob?.materialDisplayName)
  }, [data])

  const handleEditMaterial = () => {
    setIsEditing(true)
  }

  const isMaterialSpecified = data?.camJob?.materialDisplayName?.length

  const handleMaterialChange = (material: string) => {
    setMaterial(material)
  }

  const handleUpdateMaterial = (material: string) => {
    if (!material.length || material === data?.camJob?.materialDisplayName) return

    updateCamJobMaterialDisplayNameMutation({
      variables: { id: jobId, materialDisplayName: material },
    })
    setIsEditing(false)
  }

  return (
    <div>
      <ContextTarget
        menu={
          <Menu>
            <MenuItem text={`Edit Material`} onClick={() => handleEditMaterial()} />
          </Menu>
        }
      >
        <div className={styles.materialLabel}>
          Material:{" "}
          {isMaterialSpecified ? (
            <EditableText
              isEditing={isEditing}
              disabled={!isEditing}
              className={styles.labelTitle}
              value={material}
              onChange={handleMaterialChange}
              onConfirm={handleUpdateMaterial}
              placeholder={"Enter Material"}
              selectAllOnFocus
            />
          ) : (
            <Tooltip2
              placement={"bottom"}
              content={
                "The material name was not specified as part of the order. If a drawing exists, it may be specified there."
              }
              openOnTargetFocus={false}
            >
              <EditableText
                className={styles.labelTitle}
                value={material}
                onChange={handleMaterialChange}
                onConfirm={handleUpdateMaterial}
                placeholder={"Enter Material"}
                selectAllOnFocus
              />
            </Tooltip2>
          )}
        </div>
      </ContextTarget>
    </div>
  )
}

export default MaterialLabel
