import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  AnchorButton,
  Callout,
  Classes,
  Dialog,
  Divider,
  EditableText,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Spinner,
  Text,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"
import { isEqual, omit, pick } from "lodash-es"
import { validate } from "uuid"

import { Dimension, PlanSummary, TaskStatus } from "src/client-axios"
import { UnlockQCCardButton } from "src/components/Cam/UnlockButton/UnlockQCCardButton"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { useDeleteQualityControlPlanMutation } from "src/graphql/generated"
import { Maybe } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useGetControlPlanData } from "src/hooks/useGetControlPlanData"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import {
  getDimDescription,
  getDimDesignator,
  getDimSource,
  getDimTool,
} from "src/pages/DrawingViewer/description"
import { DrawingViewer } from "src/pages/DrawingViewer/DrawingViewer"
import { DimDesignatorType, DimToolType, DimType } from "src/pages/DrawingViewer/interfaces"
import { QualityControlPlanHistory } from "src/pages/PlanHistory/QualityControlPlanHistory"
import { storedControlPlanThunks } from "src/store/qualityControl/storedControlPlanThunks"
import { RootState } from "src/store/rootStore"
import { useAppDispatch } from "src/store/rootStore"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"

import styles from "./QualityControlPlan.module.css"

const dimProperties = [
  "dimCode",
  "shapeLocation",
  "dimRequirement",
  "dimType",
  "dimData",
  "dimUpperTol",
  "dimLowerTol",
  "dimDesignator",
  "dimTool",
  "dimSource",
  "dimText",
]

const dimNotesProperties = ["dimCode", "shapeLocation", "dimRequirement"]

export const QualityControlPlan: FC<{
  jobId: string
  revision?: number
  latestRevision?: boolean
  locked?: boolean
  assignees?: Maybe<string[]> | undefined
  onUpdateControlPlan: () => void
}> = ({ jobId, revision, latestRevision, locked, assignees, onUpdateControlPlan }) => {
  const { planchangerApi } = useApi()
  const dispatch = useAppDispatch()

  const {
    controlPlan,
    loading,
    refetchControlPlan,
    refetchControlPlanData,
  } = useGetControlPlanData(jobId)
  const [removeControlPlan] = useDeleteQualityControlPlanMutation()

  const [dimensions, setDimensions] = useState<Dimension[]>([])
  const [summary, setSummary] = useState<PlanSummary>()

  const toaster = useToaster()
  const errorToast = useNetworkErrorToast()

  const controlPlanId = controlPlan.id
  const controlPlanData = controlPlan.data
  const controlPlanDrawingId = controlPlan.drawingId

  useEffect(() => {
    refetchControlPlanData(revision)
  }, [refetchControlPlanData, revision])

  useEffect(() => {
    const dimensions = controlPlanData?.dimensions ?? []
    setDimensions(dimensions)

    const summary = controlPlanData?.summary
    setSummary(summary)
  }, [controlPlanData])

  const updateDimension = (dim: Dimension) => {
    const updateDimensions = dimensions.map(d => (d.dimCode === dim.dimCode ? { ...d, ...dim } : d))
    setDimensions(updateDimensions)
  }

  const updateControlPlanDimensions = () => {
    if (controlPlanId && dimensions) {
      const updatedDimensions = dimensions.filter(
        dim =>
          !isEqual(
            dim,
            controlPlanData?.dimensions.find(d => d.dimCode === dim.dimCode)
          )
      )
      const updatedDimIndices = updatedDimensions
        .map(dim => controlPlanData?.dimensions.findIndex(d => d.dimCode === dim.dimCode))
        .filter(idx => idx !== undefined) as number[]

      dispatch(
        storedControlPlanThunks.updateDimensions({
          planId: controlPlanId,
          planchangerApi,
          updatedDimensions,
          updatedDimIndices,
        })
      ).then(() => {
        onUpdateControlPlan()
      })
    }
  }

  const refreshControlPlanDimensions = (refreshFromHighQA: boolean = false) => {
    if (controlPlanDrawingId === undefined) return

    if (refreshFromHighQA) {
      planchangerApi
        .refreshDrawing(controlPlanDrawingId)
        .then(val => {
          const progress = val.data

          if (progress.status !== TaskStatus.Success) {
            toaster.show({
              message: `Error refreshing dims: ${progress.message}`,
              intent: "danger",
            })
          } else {
            toaster.show({ message: "Refreshed dims!", intent: "success" })
          }
        })
        .then(() => refetchControlPlanData(revision))
        .catch((error: AxiosError) => {
          errorToast(error, "Refresh dims error")
        })
    } else {
      refetchControlPlanData(revision)
    }
  }

  const repatchControlPlanDimensions = () => {
    if (!controlPlanDrawingId) return

    const indexedDims = Object.fromEntries(dimensions.map(dim => [dim.dimCode ?? "null", dim]))
    planchangerApi
      .repatchDims(controlPlanDrawingId, indexedDims)
      .then(val => {
        const progress = val.data

        if (progress.status !== TaskStatus.Success) {
          toaster.show({
            message: `Error Repatching dims: ${progress.message}`,
            intent: "danger",
          })
        } else {
          toaster.show({ message: "Repatched dims!", intent: "success" })
        }
      })
      .catch((error: AxiosError) => {
        errorToast(error, "Failed to repatch dims")
      })
  }

  const associateDrawing = (partId: string) => {
    const drawingFileId = controlPlan.drawingFileId

    if (!drawingFileId) return

    planchangerApi
      .associateDrawing(drawingFileId, partId)
      .then(() => refetchControlPlanData(revision))
      .catch((error: AxiosError) => {
        errorToast(error, `Error associating drawing`)
      })
  }

  const deleteControlPlan = () => {
    const controlPlanId = controlPlan.id

    if (!controlPlanId) return

    removeControlPlan({ variables: { controlPlanId } }).then(() => {
      refetchControlPlan({ jobId }).then(() => {
        refetchControlPlanData(revision)
      })
    })
  }

  const sortedDimensions = dimensions
    .map(d => pick(d, dimProperties))
    .sort((aaa, bbb) => {
      const aaaDim = aaa.dimCode
      const bbbDim = bbb.dimCode

      if (!aaaDim || !bbbDim) return 0

      const aaaDimNum = Number(aaaDim)
      const bbbDimNum = Number(bbbDim)
      if (isNaN(aaaDimNum) && isNaN(bbbDimNum)) {
        return aaaDim.localeCompare(bbbDim)
      }
      return aaaDimNum - bbbDimNum
    })

  const filteredSummary = omit(summary, ["partId"])

  const highQAFileName = controlPlan?.highQAFileName

  return (
    <>
      {loading && <Spinner />}
      {controlPlanId && (
        <QualityControlPlanInfo
          controlPlanId={controlPlanId}
          controlPlanFileName={highQAFileName}
          controlPlanDrawingId={controlPlanDrawingId}
          dimensions={sortedDimensions}
          summary={filteredSummary}
          updateDimension={updateDimension}
          updateControlPlanDimensions={updateControlPlanDimensions}
          refreshControlPlanDimensions={refreshControlPlanDimensions}
          repatchControlPlanDimensions={repatchControlPlanDimensions}
          associateDrawing={associateDrawing}
          deleteControlPlan={deleteControlPlan}
          latestRevision={latestRevision}
          locked={locked}
          assignees={assignees}
        />
      )}
    </>
  )
}

const QualityControlPlanInfo: FC<{
  controlPlanId: string
  controlPlanFileName?: string
  controlPlanDrawingId?: string
  dimensions: Dimension[]
  summary: PlanSummary
  updateDimension: (dim: Dimension) => void
  updateControlPlanDimensions: () => void
  refreshControlPlanDimensions: (refreshFromHighQA?: boolean) => void
  repatchControlPlanDimensions: () => void
  associateDrawing: (partId: string) => void
  deleteControlPlan: () => void
  latestRevision?: boolean
  locked?: boolean
  assignees?: Maybe<string[]> | undefined
}> = ({
  controlPlanId,
  controlPlanFileName,
  controlPlanDrawingId,
  dimensions: dimensions,
  summary,
  updateDimension,
  updateControlPlanDimensions,
  refreshControlPlanDimensions,
  repatchControlPlanDimensions,
  associateDrawing,
  deleteControlPlan,
  latestRevision,
  locked,
  assignees,
}) => {
  const forceLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.controlPlanLocked(state)
  )

  const editLocked = !latestRevision || locked || forceLocked

  const [showControlPlanData, setShowControlPlanData] = useState(false)
  const [showControlPlanSummary, setShowControlPlanSummary] = useState(false)
  const [showControlPlanDiff, setShowControlPlanDiff] = useState(false)
  const [showDrawing, setShowDrawing] = useState(false)
  const [showConfirmDeleteControlPlan, setShowConfirmDeleteControlPlan] = useState<
    boolean | undefined
  >()

  const [partId, setPartId] = useState<string>("")

  const partDataHeaders = summary ? Object.keys(summary).map(d => d.replace("part", "")) : []

  const dimensionsNotes = dimensions
    .filter(d => d.dimType === DimType.note)
    .map(d => pick(d, dimNotesProperties))
    .sort((aaa, bbb) => {
      const aaaDimReq = aaa.dimRequirement
      const bbbDimReq = bbb.dimRequirement

      if (!aaaDimReq || !bbbDimReq) return 0

      const aaaDimReqDatum = +aaaDimReq.toLocaleLowerCase().includes("datum")
      const bbbDimReqDatum = +bbbDimReq.toLocaleLowerCase().includes("datum")

      return aaaDimReqDatum - bbbDimReqDatum
    })

  const dimNotesHeaders =
    dimensionsNotes?.length > 0
      ? Object.keys(dimensionsNotes[0]).map(d =>
          d.replace(/(?:dim|shape)/g, "").replace(/(?:Code)/g, "Note")
        )
      : []

  const dimensionsMeasured = dimensions.filter(d => d.dimType !== DimType.note)
  const dimMeasuredHeaders =
    dimensionsMeasured?.length > 0
      ? Object.keys(dimensionsMeasured[0]).map(d =>
          d
            .replace(/(?:dim|shape|Data)/g, matched => (matched === "Data" ? "Value" : ""))
            .replace(/(?:Code)/g, "Dim")
        )
      : []

  return (
    <div className={styles.controlPlanFileItem}>
      <div className={styles.controlPlanFileLeft}>
        {controlPlanFileName && (
          <Tooltip2 content={controlPlanFileName} openOnTargetFocus={false}>
            <Text className={styles.labelText}>
              {controlPlanFileName.length > 19
                ? `${controlPlanFileName.slice(0, 19)}…`
                : controlPlanFileName}
            </Text>
          </Tooltip2>
        )}
      </div>
      <div className={styles.controlPlanFileRight}>
        <AnchorButton
          minimal
          intent={"primary"}
          icon={"info-sign"}
          onClick={() => {
            setShowControlPlanSummary(true)
          }}
        />
        <AnchorButton
          minimal
          intent={"primary"}
          icon={"code"}
          onClick={() => {
            setShowControlPlanData(true)
          }}
        />
        <AnchorButton
          minimal
          intent={"primary"}
          icon={"exchange"}
          onClick={() => {
            setShowControlPlanDiff(true)
          }}
        />
        <AnchorButton
          minimal
          intent={"primary"}
          icon={"document"}
          onClick={() => {
            setShowDrawing(true)
          }}
        />
        <Tooltip2 content={`Delete control plan`} openOnTargetFocus={false}>
          <AnchorButton
            minimal
            icon={"delete"}
            intent="primary"
            onClick={() => {
              setShowConfirmDeleteControlPlan(true)
            }}
            disabled={locked}
          />
        </Tooltip2>
      </div>
      {showControlPlanData && (
        <Dialog
          icon="code"
          title={"Control Plan Data"}
          onClose={() => {
            setShowControlPlanData(false)
            refreshControlPlanDimensions()
          }}
          isOpen={showControlPlanData}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          style={{ width: "1500px", height: "800px", overflow: "scroll" }}
        >
          <div className={Classes.DIALOG_BODY}>
            {(!latestRevision || locked) && (
              <Callout
                icon={"warning-sign"}
                intent={"warning"}
                title={`Locked`}
                style={{ marginBottom: "20px" }}
              >
                <div style={{ display: "flex" }}>
                  {"Cannot edit control plan, select latest rervision to allow editing"}
                </div>
              </Callout>
            )}
            <AnchorButton
              disabled={controlPlanDrawingId === undefined || editLocked}
              onClick={() => {
                refreshControlPlanDimensions(true)
              }}
            >
              Refresh from HighQA
            </AnchorButton>
            <AnchorButton
              disabled={controlPlanDrawingId === undefined || editLocked}
              onClick={repatchControlPlanDimensions}
            >
              Publish to HighQA
            </AnchorButton>
            <div className={styles.unlockButton}>
              <UnlockQCCardButton locked={!latestRevision || locked} assignees={assignees} />
            </div>
            <HTMLTable
              bordered={true}
              interactive={true}
              striped={true}
              contentEditable={!editLocked}
              style={{ marginBottom: "20px" }}
            >
              <thead>
                <tr>
                  {dimNotesHeaders.map((val, i) => (
                    <th style={{ minWidth: "120px" }} key={i}>
                      {" "}
                      {val}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dimensionsNotes.map((dim, i) => (
                  <tr key={i}>
                    {Object.entries(dim).map(([key, value]) => (
                      <td key={key}>
                        <EditableText
                          value={value as string}
                          placeholder={`${editLocked ? "Unlock" : "Click"} to Edit`}
                          multiline
                          onChange={val =>
                            updateDimension({ dimCode: dim.dimCode, [`${key}`]: val })
                          }
                          selectAllOnFocus
                          className={`${styles.dimRequirement}`}
                          disabled={editLocked}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
            <Divider />
            <HTMLTable
              bordered={true}
              interactive={true}
              striped={true}
              contentEditable={!editLocked}
            >
              <thead>
                <tr>
                  {dimMeasuredHeaders.map((val, i) => (
                    <th style={{ minWidth: "120px" }} key={i}>
                      {" "}
                      {val}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dimensionsMeasured.map((dim, i) => (
                  <tr key={i}>
                    {Object.entries(dim).map(([key, value]) => (
                      <td key={key}>
                        {key === "dimData" || key === "dimUpperTol" || key === "dimLowerTol" ? (
                          <FormulaInput
                            fill
                            commitOnBlur={true}
                            reset={false}
                            value={value}
                            onValueChange={val => {
                              updateDimension({ dimCode: dim.dimCode, [`${key}`]: val })
                            }}
                            disabled={editLocked}
                          />
                        ) : key === "dimTool" ? (
                          <HTMLSelect
                            value={dim.dimTool}
                            style={{ width: "130px" }}
                            onChange={e =>
                              updateDimension({
                                dimCode: dim.dimCode,
                                dimTool: parseInt(e.target.value),
                              })
                            }
                            disabled={editLocked}
                          >
                            {Object.entries(DimToolType)
                              .filter(([key, _]) => {
                                return isNaN(parseInt(key))
                              })
                              .map(([key, value]) => {
                                return (
                                  <option value={value} key={key}>
                                    {getDimTool({ dimTool: parseInt(value.toString()) })}
                                  </option>
                                )
                              })}
                          </HTMLSelect>
                        ) : key === "dimDesignator" ? (
                          <HTMLSelect
                            value={dim.dimDesignator}
                            style={{ width: "130px" }}
                            onChange={e =>
                              updateDimension({
                                dimCode: dim.dimCode,
                                dimDesignator: parseInt(e.target.value),
                              })
                            }
                            disabled={editLocked}
                          >
                            {Object.entries(DimDesignatorType)
                              .filter(([key, _]) => {
                                return isNaN(parseInt(key))
                              })
                              .map(([key, value]) => {
                                return (
                                  <option value={value} key={key}>
                                    {getDimDesignator({
                                      dimDesignator: parseInt(value.toString()),
                                    })}
                                  </option>
                                )
                              })}
                          </HTMLSelect>
                        ) : key === "dimType" ? (
                          <HTMLSelect
                            value={dim.dimType}
                            style={{ width: "130px" }}
                            onChange={e =>
                              updateDimension({
                                dimCode: dim.dimCode,
                                dimType: parseInt(e.target.value),
                              })
                            }
                            disabled={editLocked}
                          >
                            {Object.entries(DimType)
                              .filter(([key, _]) => {
                                return isNaN(parseInt(key))
                              })
                              .map(([key, value]) => {
                                return (
                                  <option value={value} key={key}>
                                    {getDimDescription(
                                      { ...dim, dimType: parseInt(value.toString()) },
                                      value == dim.dimType
                                    )}
                                  </option>
                                )
                              })}
                          </HTMLSelect>
                        ) : key === "dimText" || key === "dimRequirement" ? (
                          <EditableText
                            value={value}
                            placeholder={`${editLocked ? "Unlock" : "Click"} to Edit`}
                            multiline={key === "dimText" ? true : false}
                            onChange={val =>
                              updateDimension({ dimCode: dim.dimCode, [`${key}`]: val })
                            }
                            selectAllOnFocus
                            className={`${styles.dimRequirement}`}
                            disabled={editLocked}
                          />
                        ) : key === "dimSource" ? (
                          <span className={`${styles.dimRequirement}`}>{getDimSource(dim)}</span>
                        ) : (
                          <span className={`${styles.dimRequirement}`}>{value}</span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton
                onClick={() => {
                  updateControlPlanDimensions()
                }}
                disabled={editLocked}
              >
                Save
              </AnchorButton>
              <AnchorButton
                onClick={() => {
                  setShowControlPlanData(false)
                }}
              >
                Close
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
      {showControlPlanSummary && (
        <Dialog
          icon="info-sign"
          title={"Control Plan Summary"}
          onClose={() => {
            setShowControlPlanSummary(false)
          }}
          isOpen={showControlPlanSummary}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          style={{ width: "fit-content" }}
        >
          <div className={Classes.DIALOG_BODY}>
            <div style={{ margin: "5px" }}>
              Associate Part ID:
              <InputGroup
                leftIcon={"clipboard"}
                value={partId}
                onChange={e => {
                  setPartId(e.currentTarget.value)
                }}
                placeholder={"Paste Part ID here"}
                rightElement={
                  <AnchorButton
                    disabled={!validate(partId)}
                    icon="arrow-right"
                    minimal
                    onClick={() => {
                      associateDrawing(partId)
                      setPartId("")
                    }}
                  />
                }
              />
            </div>
            <HTMLTable bordered={true} condensed={true} interactive={true} striped={true}>
              <thead>
                <tr>
                  {partDataHeaders.map((val, i) => (
                    <th key={i}> {val} </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {summary &&
                    Object.entries(summary).map(([key, value]) => (
                      <td key={key}>
                        <span> {value} </span>
                      </td>
                    ))}
                </tr>
              </tbody>
            </HTMLTable>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton
                onClick={() => {
                  setShowControlPlanSummary(false)
                }}
              >
                Close
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
      {showControlPlanDiff && (
        <Dialog
          icon="exchange"
          title={"Control Plan Diff"}
          onClose={() => {
            setShowControlPlanDiff(false)
          }}
          isOpen={showControlPlanDiff}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          style={{ width: "1500px", height: "800px", overflow: "scroll" }}
        >
          <div className={Classes.DIALOG_BODY}>
            <QualityControlPlanHistory controlPlanId={controlPlanId} />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton
                onClick={() => {
                  setShowControlPlanDiff(false)
                }}
              >
                Close
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
      {showDrawing && (
        <Dialog
          icon={"info-sign"}
          onClose={() => setShowDrawing(false)}
          title={"Drawing"}
          isOpen={showDrawing}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          style={{ width: "1200px", height: "750px", overflow: "scroll" }}
        >
          <div>
            <DrawingViewer inDialog />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton onClick={() => setShowDrawing(false)} intent="primary">
                Close
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
      {showConfirmDeleteControlPlan && (
        <Dialog
          icon={"error"}
          onClose={() => {
            setShowConfirmDeleteControlPlan(false)
          }}
          title={"Deleting Control plan"}
          isOpen
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            Are you sure you want to delete the control plan, this will remove the control plan for
            all manufacturing plans?
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton onClick={() => setShowConfirmDeleteControlPlan(false)}>
                Cancel
              </AnchorButton>
              <AnchorButton
                onClick={() => {
                  deleteControlPlan()
                  setShowConfirmDeleteControlPlan(false)
                }}
                intent="danger"
              >
                Delete
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
