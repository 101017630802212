import React, { FC, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useSelector } from "react-redux"

import { HolderBody } from "src/components/Canvas/Viewer/Scene/Cam/Tool/HolderBody"
import { Model } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { machineSelectors } from "src/store/cam/machine"
import { RootState } from "src/store/rootStore"

export const ModelHolderScene: FC<{
  modelHolder: Model
  hideHolderModel?: boolean
  holderModelKeyLookup?: (model: Readonly<Model>) => string
}> = ({ modelHolder, hideHolderModel, holderModelKeyLookup, children }) => {
  const { transferInvalidate } = useTransferInvalidate()

  const currentToolId = useSelector((state: RootState) => machineSelectors.selectToolId(state))

  const holderModelFilename = modelHolder.filename
  const holderBody = `${holderModelFilename?.split(".").slice(0, -1).join(".")}.glb`

  useEffect(() => {
    transferInvalidate()
  }, [currentToolId, transferInvalidate])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ErrorFallback = ({ error }: any) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  if (!holderModelFilename) return null

  const getHolderModelKey = holderModelKeyLookup && (() => holderModelKeyLookup(modelHolder))
  return (
    <>
      <ErrorBoundary fallbackRender={ErrorFallback}>
        {/* Note: this 75° rotation might be DVF5000-specific */}
        <group rotation={[0, 0, ((75 / 90) * Math.PI) / 2]} position-z={modelHolder.origin.z}>
          {!hideHolderModel && (
            <HolderBody holderBody={holderBody} getHolderModelKey={getHolderModelKey} />
          )}
          {children}
        </group>
      </ErrorBoundary>
    </>
  )
}
