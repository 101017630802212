import React, { FC, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { getPartMaterial } from "src/components/Canvas/Viewer/Scene/Cam/materials"
import { GltfFullModel, GltfIdModel } from "src/components/Canvas/Viewer/SceneItems/GltfModelUtils"
import { AnimatedGltfModelProps } from "src/components/Canvas/Viewer/SceneItems/GltfUrlModel"
import { SceneModelFragment } from "src/graphql/generated"
import { useTransferInvalidateOnChange } from "src/hooks/transferCanvas/useTransferCanvas"
import { activeActions } from "src/store/cam/active"
import { useAppDispatch } from "src/store/rootStore"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"

type Without<T, K> = Pick<T, Exclude<keyof T, K>>

interface PartSceneProps extends Without<AnimatedGltfModelProps, "material"> {
  model: SceneModelFragment | undefined
  transparent?: boolean
}

export const PartScene: FC<PartSceneProps> = ({ model, transparent, ...props }) => {
  const dispatch = useAppDispatch()
  const partDisplayMode = useSelector(viewOptionsSelectors.partDisplayMode)
  useTransferInvalidateOnChange([partDisplayMode])

  const userData = useMemo(() => {
    return {
      isPart: true,
    }
  }, [])

  const material = useMemo(() => {
    return getPartMaterial(transparent === true || partDisplayMode === DisplayMode.Transparent)
  }, [partDisplayMode, transparent])

  const sceneCallback = useCallback(
    (group: THREE.Group) => {
      const bounds = new THREE.Box3().setFromObject(group)
      dispatch(activeActions.setActivePartBoundingBox(bounds))
    },
    [dispatch]
  )

  if (!model || partDisplayMode === DisplayMode.Hidden) return null

  return (
    <GltfFullModel
      userData={userData}
      model={model}
      sceneCallback={sceneCallback}
      {...props}
      material={material}
    />
  )
}

interface DesignSceneProps extends Without<AnimatedGltfModelProps, "material"> {
  modelId?: string
}

export const DesignScene: FC<DesignSceneProps> = ({ modelId, ...props }) => {
  const partDisplayMode = useSelector(viewOptionsSelectors.partDisplayMode)
  useTransferInvalidateOnChange([partDisplayMode])

  const userData = useMemo(() => {
    return {
      isPart: true,
    }
  }, [])

  const material = useMemo(() => {
    return getPartMaterial(partDisplayMode === DisplayMode.Transparent)
  }, [partDisplayMode])

  if (!modelId || partDisplayMode === DisplayMode.Hidden) return null

  return <GltfIdModel modelId={modelId} userData={userData} {...props} material={material} />
}
