import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { batch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ApolloQueryResult } from "@apollo/client"
import {
  AnchorButton,
  Button,
  Callout,
  Checkbox,
  Classes,
  ControlGroup,
  Dialog,
  Divider,
  FormGroup,
  HTMLTable,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Radio,
  Spinner,
  Switch,
  Text,
} from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import { format } from "date-fns"
import { isEqual } from "lodash-es"

import {
  MachineKind,
  SimulationKind,
  SimulationMode,
  VericutManifestData,
  Wsec,
} from "src/client-axios"
import { FeedbackButton } from "src/components/Cam/FeedbackIcon/FeedbackButton"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { sendEvent, useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  Exact,
  FileNodeFragment,
  Maybe,
  PostProcessFragment,
  Taskstatus,
  useFileByLocatorQuery,
  useFilesByIDsQuery,
  useGuardrailRunsByPlanIdQuery,
  useNcFileRevisionsQuery,
  usePostProcessFilesQuery,
  useVericutFeedbackQuery,
  useVericutLocksQuery,
  useWorkerStatsQuery,
  VericutFeedbackNodeFragment,
  VericutFeedbackQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { useToaster } from "src/hooks/useToaster"
import { activeActions } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { Annotation, fileModalActions, FileModalFile } from "src/store/ui/fileModal"
import { viewerModalActions, ViewerModalMode } from "src/store/ui/viewerModal"
import { downloadFromUrl } from "src/util/files"
import { CutConfigPopover } from "./CutConfigPopover/CutConfigPopover"

import styles from "./NcProgramInfo.module.css"

export const NcProgramsInfo: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
  revision?: number
}> = ({ planId, operationIdx, locked, revision }) => {
  const selectToolpathProject = useMemo(
    () => storedOperationSelectors.createSelectToolpathProject(planId, operationIdx),
    [planId, operationIdx]
  )
  const toolpathProject = useSelector(selectToolpathProject)
  const toaster = useToaster()

  const [error, setError] = useState<string>()

  const { data: toolpathProjectNcFile } = useFileByLocatorQuery({
    variables: {
      locator: toolpathProject?.ncFileLocator ?? "",
    },
  })

  const { data: toolpathProjectDocumentFile } = useFileByLocatorQuery({
    variables: {
      locator: toolpathProject?.documentFileLocator ?? "",
    },
  })

  const fileIds = []
  if (toolpathProjectNcFile?.fileByLocator?.id) fileIds.push(toolpathProjectNcFile.fileByLocator.id)
  if (toolpathProjectDocumentFile?.fileByLocator?.id)
    fileIds.push(toolpathProjectDocumentFile.fileByLocator.id)

  const { data: filesData, loading: loadingFilesData } = useFilesByIDsQuery({
    variables: {
      ids: fileIds,
    },
  })
  const { data: vericutFeedback, refetch: refetchVericutFeedback } = useVericutFeedbackQuery({
    variables: { planId, revision },
  })

  const {
    data: postProcessFiles,
    loading: postProcessLoading,
    refetch: refetchPostFiles,
  } = usePostProcessFilesQuery({
    variables: {
      planId,
      operationIdx,
    },
    onCompleted: () => {
      setError(undefined)
    },
    onError: err => {
      try {
        const message = JSON.parse(err.message) as { detail?: string }
        toaster.show({
          intent: "danger",
          message: message.detail ?? err.message,
          icon: "warning-sign",
        })
        setError(message.detail ?? err.message)
      } catch (e) {
        toaster.show({ intent: "danger", message: err.message, icon: "warning-sign" })
        setError(err.message)
      }
    },
  })

  useEffect(() => {
    refetchPostFiles()
  }, [toolpathProject, refetchPostFiles])

  useEffect(() => {
    if (refetchVericutFeedback) {
      refetchVericutFeedback()
    }
  }, [refetchVericutFeedback])

  useWebsocketMessageListener(wsMessage => {
    if (
      wsMessage.type === "TaskProgress" &&
      wsMessage.planId === planId &&
      wsMessage.name.endsWith("run_vericut_simulation")
    ) {
      refetchVericutFeedback()
      refetchPostFiles()
    }
  })

  const getPostProcessFiles = useMemo(() => postProcessFiles?.getPostProcessFiles ?? [], [
    postProcessFiles,
  ])

  const cuttingNcFile = filesData?.files?.nodes.filter(
    node => node.locator === toolpathProject?.ncFileLocator
  )[0]
  const cuttingCamProject = filesData?.files?.nodes.filter(
    node => node.locator === toolpathProject?.documentFileLocator
  )[0]

  const probingNcFilesById: { [key: string]: FileNodeFragment } = {}
  for (const fileData of filesData?.files?.nodes ?? []) {
    probingNcFilesById[fileData.id] = fileData
  }

  const filteredFeedback = useMemo(
    () =>
      vericutFeedback?.validVericutFeedback.filter(
        feedback => feedback.feedback.operationIdx === operationIdx
      ) ?? [],
    [vericutFeedback, operationIdx]
  )

  const getFeedback = useCallback(
    (
      locator: string | undefined
    ):
      | { invalidationReason: string | undefined; feedback: VericutFeedbackNodeFragment }
      | undefined => {
      if (!locator) return undefined

      const feedback = filteredFeedback.find(f => f.feedback.ncFileLocator === locator)
      if (!feedback) return undefined

      return {
        invalidationReason: feedback.invalidationReason ?? undefined,
        feedback: feedback.feedback,
      }
    },
    [filteredFeedback]
  )

  const { data: guardrailRuns } = useGuardrailRunsByPlanIdQuery({
    variables: {
      planId,
    },
    pollInterval: 1000,
  })

  const operation = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx),
    isEqual
  )

  const guardrailRunsByOperationId = useMemo(() => {
    return guardrailRuns?.guardrailRuns?.nodes
      .filter(node => {
        return node.operationId === operation?.id
      })
      .sort((a, b) => {
        return b.planRevision - a.planRevision
      })
  }, [guardrailRuns, operation])

  const cuttingFeedback = useMemo(() => getFeedback(cuttingNcFile?.locator), [
    cuttingNcFile,
    getFeedback,
  ])

  return (
    <div className={styles.ncProgramItems}>
      {cuttingNcFile && (
        <>
          <NcProgramInfo
            planId={planId}
            operationIdx={operationIdx}
            simulationKind={SimulationKind.CutInputStock}
            ncFile={cuttingNcFile}
            invalidationReason={cuttingFeedback?.invalidationReason ?? undefined}
            feedback={cuttingFeedback?.feedback}
            manifestUri={guardrailRunsByOperationId?.[0]?.manifestUri}
            loadingFilesData={loadingFilesData}
            locked={locked}
            refetchVericutFeedback={refetchVericutFeedback}
          />
          <ToolpathProjectInfo camDocument={cuttingCamProject} />
          {getPostProcessFiles.length > 0 && <Divider />}
          {error && (
            <>
              <Divider />
              <div className={styles.ncProgramError}>{error}</div>
            </>
          )}
          {postProcessLoading && <Spinner />}
          {getPostProcessFiles.map(val => {
            const probingFeedback = getFeedback(val.fileId)

            return (
              <PostProgramInfo
                key={val.fileId}
                feedback={probingFeedback?.feedback}
                invalidationReason={probingFeedback?.invalidationReason}
                postProcessLoading={postProcessLoading}
                operationIdx={operationIdx}
                planId={planId}
                postProgram={val}
                locked={locked}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

const PostProgramInfo: FC<{
  planId: string
  operationIdx: number
  postProgram: PostProcessFragment
  feedback: VericutFeedbackNodeFragment | undefined
  invalidationReason: string | undefined
  locked?: boolean
  postProcessLoading?: boolean
}> = ({ planId, operationIdx, postProgram, locked }) => {
  const dispatch = useAppDispatch()

  const onEditClick = () => {
    batch(() => {
      dispatch(viewerModalActions.setViewerModalIsOpen(true))
      dispatch(viewerModalActions.setViewerModalMode(ViewerModalMode.Setup))
      dispatch(activeActions.setActivePlanId(String(planId)))
      dispatch(activeActions.setActiveOperationIdx(Number(operationIdx)))
      dispatch(
        activeActions.setActiveConfigPanel({
          kind: "wcs",
          planId,
          operationIdx,
        })
      )
    })
  }

  return (
    <div className={styles.ncProgramItem}>
      <div className={styles.manufacturingProgramLeft}>
        <Tooltip2 content={postProgram.basename} openOnTargetFocus={false}>
          <Text className={styles.labelText}>
            {postProgram.basename.length > 19
              ? `${postProgram.basename.slice(0, 19)}…`
              : postProgram.basename}
          </Text>
        </Tooltip2>
      </div>
      <div className={styles.ncProgramRight}>
        <Link
          to={`/nc-edit/${planId}/${operationIdx}/${postProgram.fileId}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Button minimal intent={"primary"} icon={"code"} />
        </Link>
        {!locked && (
          <Button
            minimal
            onClick={onEditClick}
            icon={postProgram.kind === "CBT" ? "cog" : "edit"}
          />
        )}
      </div>
    </div>
  )
}

const NcProgramInfo: FC<{
  planId: string
  operationIdx: number
  simulationKind: SimulationKind
  ncFile: FileNodeFragment | undefined
  feedback: VericutFeedbackNodeFragment | undefined
  manifestUri: string | undefined | null
  invalidationReason: string | undefined
  locked?: boolean
  loadingFilesData?: boolean
  refetchVericutFeedback?: (
    variables?:
      | Partial<
          Exact<{
            planId: string
            revision?: Maybe<number> | undefined
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<VericutFeedbackQuery>>
}> = ({
  planId,
  operationIdx,
  ncFile,
  simulationKind,
  feedback,
  manifestUri,
  invalidationReason,
  locked,
  loadingFilesData,
  refetchVericutFeedback,
}) => {
  const toaster = useToaster()
  const dispatch = useAppDispatch()
  const { submissionApi } = useApi()
  const { plansApi } = useApi()

  const [compareRevisionsIsOpen, setCompareRevisionsIsOpen] = useState(false)
  const [simulateModalIsOpen, setSimulateModalIsOpen] = useState(false)
  const [manifestData, setManifestData] = useState<VericutManifestData | undefined>(undefined)

  const machineSelector = useMemo(
    () => storedOperationSelectors.selectMachineRecord(planId, operationIdx),
    [planId, operationIdx]
  )
  const machine = useSelector(machineSelector)
  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )

  useEffect(() => {
    if (manifestUri !== undefined && manifestUri !== null) {
      plansApi.getVericutManifestData(manifestUri).then(val => {
        setManifestData(val.data.manifestData)
      })
    }
  }, [manifestUri, plansApi])

  const allFiles: FileModalFile[] = useMemo(() => {
    const allFiles: FileModalFile[] = []
    if (ncFile) {
      let annotations: Annotation[] = []

      if (manifestData) {
        annotations = annotations.concat(
          manifestData.vericut.vericutLogOutput.info?.errors.map(err => {
            return {
              row: err.lineNumber - 1,
              text: err.error,
              type: "error",
            }
          }) ?? []
        )
        annotations = annotations.concat(
          manifestData.vericut.vericutLogOutput.info?.warnings.map(war => {
            return {
              row: war.lineNumber - 1,
              text: war.error,
              type: "warning",
            }
          }) ?? []
        )
      }
      allFiles.push({
        locator: ncFile?.locator,
        labelText: ncFile?.basename,
        language: "gcode",
        annotations,
      })
    }

    for (const file of [
      feedback?.log,
      feedback?.fileByForceData,
      feedback?.fileByForceDataOptimized,
      feedback?.fileByOptimizedNc,
    ]) {
      if (file?.locator) {
        allFiles.push({ locator: file.locator, labelText: file?.basename })
      }
    }
    return allFiles
  }, [feedback, ncFile, manifestData])

  const fileModalProps = { files: allFiles }

  const files = fileModalProps.files

  const otherFiles = fileModalProps.files.filter(file => file.locator !== ncFile?.locator)

  const handleOpenFile = useCallback(
    (locator: string | undefined) => {
      if (!locator) return

      const targetFile = files.find(file => file.locator === locator)
      const targetFiles = targetFile ? [targetFile] : []
      const otherFiles = files.filter(file => file.locator !== locator)

      dispatch(fileModalActions.setFileModalProps({ files: [...targetFiles, ...otherFiles] }))
      dispatch(fileModalActions.setFileModalIsOpen(true))
    },
    [dispatch, files]
  )

  const campletePostDisabled = useMemo(() => {
    const filteredMachines = new Set([MachineKind.DoosanDvf5000, MachineKind.GrobG350A])

    return (
      (toolpathProject?.cuttingFileLocators?.length ?? 0) <= 0 ||
      machine?.kind === undefined ||
      !filteredMachines.has(machine?.kind)
    )
  }, [toolpathProject, machine])

  const buildIconIntent =
    feedback?.task?.status === Taskstatus.Success ? Intent.NONE : Intent.SUCCESS

  return (
    <div className={styles.ncProgramItem}>
      <div className={styles.ncProgramLeft}>
        <FeedbackButton
          planId={planId}
          operationIdx={operationIdx}
          taskStatus={undefined}
          feedback={feedback}
          loadingFilesData={loadingFilesData}
          invalidationReason={invalidationReason}
          locked={locked}
          refetchVericutFeedback={refetchVericutFeedback}
        />
        {ncFile ? (
          <Tooltip2
            content={
              <>
                <div>{ncFile.basename}</div>
                <div>
                  {`Uploaded ${format(new Date(ncFile.createdAt), "MMM d")} at ${format(
                    new Date(ncFile.createdAt),
                    "h:mma"
                  )}`}
                </div>
              </>
            }
            openOnTargetFocus={false}
          >
            <Text className={styles.labelText}>
              {ncFile.basename.length > 19 ? `${ncFile.basename.slice(0, 19)}…` : ncFile.basename}
            </Text>
          </Tooltip2>
        ) : (
          <Text className={styles.labelText}>{"<No NC file uploaded>"}</Text>
        )}
      </div>
      <div className={styles.ncProgramRight}>
        {ncFile && (
          <>
            {!locked && (
              <>
                <CutConfigPopover planId={planId} operationIdx={operationIdx} />
                <Tooltip2 placement={"top"} content={"Simulate operation"}>
                  <Button
                    minimal
                    intent={buildIconIntent}
                    onClick={() => setSimulateModalIsOpen(true)}
                    icon={"build"}
                  />
                </Tooltip2>
                {simulateModalIsOpen && (
                  <SimulationMenu
                    planId={planId}
                    operationIdx={operationIdx}
                    isOpen={simulateModalIsOpen}
                    simulationKind={simulationKind}
                    ncProgramLocator={ncFile.locator}
                    onClose={() => {
                      setSimulateModalIsOpen(false)
                    }}
                    manifestUri={manifestUri}
                  />
                )}
              </>
            )}
            <Link
              to={`/nc-edit/${planId}/${operationIdx}/${ncFile.id}`}
              target={"_blank"}
              rel="noreferrer"
            >
              <Tooltip2 placement={"top"} content={"Open NC file"}>
                <Button minimal intent={"primary"} icon={"code"} />
              </Tooltip2>
            </Link>
          </>
        )}
        <Popover2
          modifiers={{
            preventOverflow: { enabled: false },
          }}
          content={
            <Menu>
              {simulationKind === SimulationKind.CutInputStock && (
                <MenuItem
                  text={`Compare Revisions`}
                  onClick={() => {
                    setCompareRevisionsIsOpen(true)
                  }}
                />
              )}
              {otherFiles.length > 0 && <Divider />}
              {otherFiles.map(file => {
                const locator = file.locator
                if (locator === undefined) return null
                return (
                  <MenuItem
                    text={`View ${file.labelText ?? locator}`}
                    key={locator}
                    onClick={() => handleOpenFile(locator)}
                  />
                )
              })}
              <MenuDivider />
              <Tooltip2
                content={
                  "Disabled for non-DVF5000 machines and without uploaded POF CAM Project files."
                }
                openOnTargetFocus={false}
                disabled={!campletePostDisabled}
              >
                <MenuItem
                  text={"Post Camplete CAM project"}
                  disabled={campletePostDisabled}
                  onClick={() => {
                    submissionApi
                      .runCampletePost(planId, operationIdx)
                      .then(() => {
                        sendEvent("submitCamplete", {
                          planId,
                          operationIdx,
                        })
                      })
                      .catch(err => {
                        console.error(err)
                        toaster.show({
                          message: `Error submitting to Camplete: ${err?.response?.data?.detail}`,
                          intent: "danger",
                        })
                      })
                  }}
                />
              </Tooltip2>
              <MenuItem text={"Delete file (not yet implemented)"} disabled={true} />
            </Menu>
          }
        >
          <Button minimal icon="more" className={styles.ncProgramDownloadIcon} />
        </Popover2>
      </div>
      {compareRevisionsIsOpen && (
        <CompareNcRevisions
          planId={planId}
          operationIdx={operationIdx}
          isOpen={compareRevisionsIsOpen}
          handleRequestClose={() => {
            setCompareRevisionsIsOpen(false)
          }}
        />
      )}
    </div>
  )
}

const ToolpathProjectInfo: FC<{
  camDocument: FileNodeFragment | undefined
}> = ({ camDocument }) => {
  // const dispatch = useAppDispatch()
  const { planchangerApi } = useApi()

  return (
    <div className={styles.ncProgramItem}>
      <div className={styles.ncProgramLeft}>
        <div style={{ width: "30px", height: "30px" }} />
        {camDocument ? (
          <Tooltip2
            content={
              <>
                <div>{camDocument.basename}</div>
                <div>
                  {`Uploaded ${format(new Date(camDocument.createdAt), "MMM d")} at ${format(
                    new Date(camDocument.createdAt),
                    "h:mma"
                  )}`}
                </div>
              </>
            }
            openOnTargetFocus={false}
          >
            <Text className={styles.labelText}>
              {camDocument.basename.length > 19
                ? `${camDocument.basename.slice(0, 19)}…`
                : camDocument.basename}
            </Text>
          </Tooltip2>
        ) : (
          <Text className={styles.missingLabelText}>{"No CAM Project Uploaded"}</Text>
        )}
      </div>
      <div className={styles.ncProgramRight}>
        {camDocument ? (
          <Tooltip2 content={`Download CAM Project`} openOnTargetFocus={false}>
            <AnchorButton
              minimal
              icon={"download"}
              onClick={() => {
                planchangerApi
                  .urlFor_getFile(camDocument.locator)
                  .then(url => downloadFromUrl(url.toString()))
              }}
            />
          </Tooltip2>
        ) : (
          <Tooltip2 content={"No CAM project has been uploaded"} openOnTargetFocus={false}>
            <AnchorButton minimal icon={"download"} disabled />
          </Tooltip2>
        )}
      </div>
    </div>
  )
}

export const SimulationMenu: FC<{
  planId: string
  operationIdx: number
  ncProgramLocator: string
  simulationKind: SimulationKind
  isOpen: boolean
  onClose: () => void
  manifestUri?: string | undefined | null
}> = ({ planId, operationIdx, ncProgramLocator, simulationKind, isOpen, onClose, manifestUri }) => {
  const { submissionApi } = useApi()
  const { plansApi } = useApi()
  const toaster = useToaster()

  const [taskId, setTaskId] = useState("")
  const [showAdvancedOptions, setShowAdvanced] = useState(false)
  const [includeGcodeTextLog, setIncludeGcodeTextLog] = useState(true)
  const [cutterResolutionText, setCutterResolutionText] = useState("0.4")
  const [holderStockNearMiss, setHolderStockNearMiss] = useState(0.25)
  const [holderFixtureNearMiss, setHolderFixtureNearMiss] = useState(0.25)
  const [cutterFixtureNearMiss, setCutterFixtureNearMiss] = useState(0.25)
  const [shankFixtureNearMiss, setShankFixtureNearMiss] = useState(0.25)
  const [shankStockDist, setShankStockDist] = useState(0.01)

  useEffect(() => {
    if (manifestUri) {
      plansApi.getVericutManifestData(manifestUri).then(val => {
        const lastSubmissionData = val.data.submissionData
        if (!lastSubmissionData) return
        setIncludeGcodeTextLog(lastSubmissionData.include_gcode_text_log)
        setCutterResolutionText(lastSubmissionData.cutting_resolution.toString())
        setHolderStockNearMiss(lastSubmissionData?.holder_stock_near_miss)
        setHolderFixtureNearMiss(lastSubmissionData.holder_fixture_near_miss)
        setCutterFixtureNearMiss(lastSubmissionData.cutter_fixture_near_miss)
        setShankFixtureNearMiss(lastSubmissionData.shank_fixture_near_miss)
        setShankStockDist(lastSubmissionData.shank_stock_dist)
      })
    }
  }, [manifestUri, plansApi])

  const { data: workerData } = useWorkerStatsQuery({
    pollInterval: 1000,
    variables: { taskName: "run_vericut_simulation" },
  })
  const { data: vericutLocks } = useVericutLocksQuery({ pollInterval: 1000 })

  const cutterResolutionVal = +cutterResolutionText
  const validResolution = cutterResolutionVal >= 0.1 && cutterResolutionVal <= 1.0

  const [wsec, setWsec] = useState<Wsec>({ x: 0, y: 0, z: 0, a: 0, b: 0, c: 0 })
  const [enableWsec, setEnableWsec] = useState(false)

  const submit = () => {
    submissionApi
      .submitCamOperation(
        planId,
        operationIdx,
        ncProgramLocator,
        SimulationMode.Default,
        simulationKind,
        undefined,
        enableVcreview,
        includeGcodeTextLog,
        cutterResolutionVal,
        holderStockNearMiss,
        holderFixtureNearMiss,
        cutterFixtureNearMiss,
        shankFixtureNearMiss,
        shankStockDist,
        undefined,
        undefined,
        enableWsec ? wsec : undefined
      )
      .then(task => {
        setTaskId(task.data.taskId)
      })
      .then(() => {
        sendEvent("submitVericut", {
          planId,
          operationIdx,
          ncProgramLocator,
          simulationMode: SimulationMode.Default,
        })
      })
      .catch(err => {
        console.error(err)
        toaster.show({
          message: `Error submitting to Vericut: ${err?.response?.data?.detail}`,
          intent: "danger",
        })
      })
  }

  useWebsocketMessageListener(msg => {
    if (
      msg.type === "TaskProgress" &&
      msg.id === taskId &&
      msg.name.endsWith("run_vericut_simulation")
    ) {
      onClose()
    }
  })

  const { x, y, z, a, b, c } = wsec

  const WsecPositionInput: FC<React.ComponentProps<typeof FormulaInput>> = props => {
    return <FormulaInput {...props} stepSize={0.01} majorStepSize={0.1} />
  }

  const WsecRotationInput: FC<React.ComponentProps<typeof FormulaInput>> = props => {
    return <FormulaInput {...props} stepSize={0.1} majorStepSize={1} />
  }

  const {
    settings,
    settings: { enableVcreview },
    setSettings,
  } = useLocalStorageSettings()

  return (
    <Dialog
      className={styles.dialog}
      icon={"info-sign"}
      onClose={onClose}
      title={"Run Simulation"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <Switch
          checked={showAdvancedOptions}
          onChange={() => {
            setShowAdvanced(!showAdvancedOptions)
          }}
          label={"Show Advanced Options"}
        />

        <FormGroup contentClassName={styles.generateReviewFile}>
          <Checkbox
            id="generateReviewFileCheckbox"
            className={styles.generateReviewFileCheckbox}
            checked={enableVcreview}
            onChange={() => {
              setSettings({
                ...settings,
                enableVcreview: !enableVcreview,
              })
            }}
          />
          <label htmlFor="generateReviewFileCheckbox">Generate VC Review file</label>
          <Tooltip2 content={`Vericut runs substantially slower when generating a reviewer file`}>
            <Icon icon="info-sign" className={styles.generateReviewFileInfo} />
          </Tooltip2>
        </FormGroup>
        {showAdvancedOptions && (
          <>
            <div className={styles.dialogRow}>
              <FormGroup label="Include GCode Text Log">
                <Checkbox
                  checked={includeGcodeTextLog}
                  label="Enabled"
                  onChange={() => {
                    setIncludeGcodeTextLog(!includeGcodeTextLog)
                  }}
                />
              </FormGroup>
            </div>
            <FormGroup label="Cutting Resolution (mm)">
              <FormulaInput
                commitOnBlur={true}
                intent={!validResolution ? "danger" : undefined}
                value={cutterResolutionText}
                onValueChange={(_num, val) => setCutterResolutionText(val)}
                min={0.1}
                max={1.0}
                stepSize={0.1}
                minorStepSize={0.01}
              />
            </FormGroup>
            <div className={styles.dialogRow}>
              <FormGroup label="Holder stock near miss (mm)">
                <FormulaInput
                  commitOnBlur={true}
                  intent={!validResolution ? "danger" : undefined}
                  value={holderStockNearMiss}
                  onValueChange={_num => setHolderStockNearMiss(_num)}
                  stepSize={0.1}
                  minorStepSize={0.01}
                />
              </FormGroup>
              <FormGroup label="Holder fixture near miss (mm)">
                <FormulaInput
                  commitOnBlur={true}
                  intent={!validResolution ? "danger" : undefined}
                  value={holderFixtureNearMiss}
                  onValueChange={_num => setHolderFixtureNearMiss(_num)}
                  stepSize={0.1}
                  minorStepSize={0.01}
                />
              </FormGroup>
            </div>
            <div className={styles.dialogRow}>
              <FormGroup label="Cutter fixture near miss (mm)">
                <FormulaInput
                  commitOnBlur={true}
                  intent={!validResolution ? "danger" : undefined}
                  value={cutterFixtureNearMiss}
                  onValueChange={_num => setCutterFixtureNearMiss(_num)}
                  stepSize={0.1}
                  minorStepSize={0.01}
                />
              </FormGroup>
              <FormGroup label="Shank fixture near miss (mm)">
                <FormulaInput
                  commitOnBlur={true}
                  intent={!validResolution ? "danger" : undefined}
                  value={shankFixtureNearMiss}
                  onValueChange={_num => setShankFixtureNearMiss(_num)}
                  stepSize={0.1}
                  minorStepSize={0.01}
                />
              </FormGroup>
            </div>
            <div className={styles.dialogRow}>
              <FormGroup label="Shank stock distance (mm)">
                <FormulaInput
                  commitOnBlur={true}
                  intent={!validResolution ? "danger" : undefined}
                  value={shankStockDist}
                  onValueChange={_num => setShankStockDist(_num)}
                  stepSize={0.1}
                  minorStepSize={0.01}
                />
              </FormGroup>
            </div>
            <Divider className={styles.wsecDivider} />
            <Switch
              checked={enableWsec}
              onChange={() => {
                setEnableWsec(!enableWsec)
              }}
              label={"Set WSEC (G54.4)"}
            />
            {enableWsec && (
              <>
                <FormGroup
                  inline
                  className={styles.ncProgramLeft}
                  label={
                    <>
                      X<br />Y<br />Z<br />A<br />B<br />C
                    </>
                  }
                  labelFor={"form-wsec-g54.4"}
                >
                  <ControlGroup id={"form-wsec-g54.4"} vertical fill>
                    <ControlGroup>
                      <WsecPositionInput
                        commitOnBlur={true}
                        value={x}
                        onValueChange={v => setWsec({ x: v, y, z, a, b, c })}
                      />
                    </ControlGroup>
                    <ControlGroup>
                      <WsecPositionInput
                        commitOnBlur={true}
                        value={y}
                        onValueChange={v => setWsec({ x, y: v, z, a, b, c })}
                      />
                    </ControlGroup>
                    <ControlGroup>
                      <WsecPositionInput
                        commitOnBlur={true}
                        value={z}
                        onValueChange={v => setWsec({ x, y, z: v, a, b, c })}
                      />
                    </ControlGroup>
                    <ControlGroup>
                      <WsecRotationInput
                        commitOnBlur={true}
                        value={a}
                        onValueChange={v => setWsec({ x, y, z, a: v, b, c })}
                      />
                    </ControlGroup>
                    <ControlGroup>
                      <WsecRotationInput
                        commitOnBlur={true}
                        value={b}
                        onValueChange={v => setWsec({ x, y, z, a, b: v, c })}
                      />
                    </ControlGroup>
                    <ControlGroup>
                      <WsecRotationInput
                        commitOnBlur={true}
                        value={c}
                        onValueChange={v => setWsec({ x, y, z, a, b, c: v })}
                      />
                    </ControlGroup>
                  </ControlGroup>
                </FormGroup>
              </>
            )}
          </>
        )}
        {workerData === undefined || vericutLocks === undefined ? (
          <Spinner size={30} />
        ) : (
          <QueueStats lockStats={vericutLocks.vericutLocks} workerStats={workerData.workerStats} />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={"primary"} disabled={!validResolution} onClick={submit}>
            Simulate
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}

interface LockStat {
  name: string
  locks: string[]
}

interface WorkerStat {
  name: string
}

const QueueStats: FC<{
  lockStats: LockStat[]
  workerStats: WorkerStat[]
}> = ({ lockStats, workerStats }) => {
  if (lockStats.length === 0 && workerStats.length === 0) {
    return (
      <Callout intent="warning" title="No Vericut Workers Running">
        <p>Please ensure that vericut workers are running on the camplete machine</p>
        <AnchorButton href="/workers-status" target="_blank" intent="warning">
          Open Vericut Status
        </AnchorButton>
      </Callout>
    )
  }

  if (lockStats.some(val => val.name === "Interactive")) {
    return (
      <Callout intent="warning" title="Interactive Session">
        <p>
          An Interactive Session is currently open. Newly submitted tasks will wait until this
          session is closed
          <br />
          You can send a slack message to #remoteshop to request more details
        </p>
        <AnchorButton href="/workers-status" target="_blank" intent="warning">
          Open Vericut Status
        </AnchorButton>
      </Callout>
    )
  }

  const totalTasks = lockStats.reduce((a, b) => a + b.locks.length, 0)

  if (totalTasks > 0) {
    return (
      <Callout intent="primary" title="Currently In Use">
        <p>
          There are currently {totalTasks} tasks queued. Newly submitted tasks will be added at the
          end of the queue
        </p>
        <AnchorButton href="/workers-status" target="_blank" intent="primary">
          Open Vericut Status
        </AnchorButton>
      </Callout>
    )
  }

  return <></>
}

const CompareNcRevisions: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  handleRequestClose: () => void
}> = ({ planId, operationIdx, isOpen, handleRequestClose }) => {
  const { data } = useNcFileRevisionsQuery({ variables: { planId, operationIdx } })

  const [left, setLeft] = useState(1)
  const [right, setRight] = useState(0)

  const length = data && data.getNcFileRevisions.length

  const hasValues = length && left < length && right < length

  const onCompare = () => {
    const uriLeft = `${data?.getNcFileRevisions[left].file.id}`
    const uriRight = `${data?.getNcFileRevisions[right].file.id}`

    const tab = window.open(
      `/nc-diff?from=${encodeURIComponent(uriLeft)}&to=${encodeURIComponent(uriRight)}`,
      "_blank"
    )
    tab?.focus()
  }

  return (
    <Dialog
      icon={"exchange"}
      onClose={handleRequestClose}
      title={"Compare Revisions"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <HTMLTable striped bordered condensed className={styles.compareTable}>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Created At</th>
              <th>Version</th>
              <th>Left</th>
              <th>Right</th>
            </tr>
          </thead>
          <tbody>
            {data?.getNcFileRevisions.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val.file.basename}</td>
                  <td>{format(new Date(val.createdAt), "MMM d h:mma")}</td>
                  <td>{val.publishVersion}</td>
                  <td>
                    <Radio
                      checked={left === i}
                      onClick={() => {
                        setLeft(i)
                      }}
                    />
                  </td>
                  <td>
                    <Radio
                      checked={right === i}
                      onClick={() => {
                        setRight(i)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </HTMLTable>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose}>Close</Button>
          <Button onClick={onCompare} disabled={!hasValues} intent="primary">
            Compare
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
