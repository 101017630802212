import {
  ArcSpec as OSArcSpec,
  PathSection as OSPathSection,
  Point2 as OSPoint2,
  ToolShape,
} from "@subscale/formlogic-opscene"

import { ToolInfo } from "src/graphql/generated"

function isPoint2(section: OSPoint2 | OSArcSpec): section is OSPoint2 {
  return "x" in section
}

export function translateZ(section: OSPathSection, offset: number): OSPathSection {
  if (isPoint2(section)) {
    return { ...section, z: section.z + offset }
  } else {
    return {
      ...section,
      center: {
        x: section.center.x,
        z: section.center.z + offset,
      },
    }
  }
}

export function profileOffsetZ(profile: Readonly<OSPathSection[]>): number {
  const firstSection = profile[0]
  return firstSection ? (isPoint2(firstSection) ? firstSection.z : firstSection.center.z) : 0.0
}

export function profileTranslateZ(
  profile: Readonly<OSPathSection[]>,
  offsetZ: number
): OSPathSection[] {
  return profile.map(section => translateZ(section, offsetZ))
}

export function toolShapeFromInfo(info: ToolInfo): ToolShape {
  // Ensure the tip of a ToolShape is at Z=0
  return {
    profile: profileTranslateZ(info.profile, -profileOffsetZ(info.profile)),
    flute_length: info.fluteLength,
    alternate: info.alternate
      ? profileTranslateZ(info.alternate, -profileOffsetZ(info.alternate))
      : null,
  }
}
