import { useSelector } from "react-redux"

import {
  Alignment,
  AlignmentKindEnum,
  BoreFeature,
  BossFeature,
  ExplicitMove,
  ExplicitMoveKindEnum,
  Inspection,
  InspectionKindEnum,
  MultiLevelBoreFeature,
  MultiLevelBossFeature,
  PointsFeature,
  PointsFeatureKindEnum,
  PointsFeatureMoveKindEnum,
  PointsFeaturePointKindEnum,
  SphereFeature,
  WcsProbing,
  WcsProbingKindEnum,
  WcsProbingMoveKindEnum,
} from "src/client-axios"
import {
  updateAlignment,
  updateInspection,
  updateWcsProbing,
} from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/updateProductionControls"
import { useSelectedStepStartPoint } from "src/hooks/useSelectedStepStartPoint"
import { useHandleAddMove } from "src/pages/ViewerPage/Probing/ProbingProgramPanel/ProbingProgramTreeLabel/useHandleAddMove"
import { activeProbingSelectors } from "src/store/cam/active"
import { machineSelectors } from "src/store/cam/machine"
import { useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { useAppDispatch } from "src/store/rootStore"
import { getDefaultStep, getNewWcsProbingStep } from "src/util/cam/probing/createStep"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export const useHandleAddSteps = (): {
  contactPoint?: [number, number, number]
  coords: {
    [x: string]: number
  }

  handleAddMove: (selectedProbeStep: WcsProbingStep | ProbingStep | undefined) => void

  handleAddSteps: (
    steps: (
      | BoreFeature
      | BossFeature
      | MultiLevelBoreFeature
      | MultiLevelBossFeature
      | SphereFeature
      | ExplicitMove
      | PointsFeature
    )[],
    insertBeforeId: string | undefined,
    offset?: number,
    selectedSectionKind?: string
  ) => void

  handleAddWcsProbingSteps: (
    steps: WcsProbingStep[],
    insertBeforeId: string | undefined,
    offset: number
  ) => void
  selectedSection: WcsProbing | Alignment | Inspection | undefined
} => {
  const dispatch = useAppDispatch()
  const probingThunkContext = useProbingThunkContext()
  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const coords = useSelector(machineSelectors.selectCoords)
  const { handleAddMove: handleAddSubMove } = useHandleAddMove()
  const contactPoint = useSelectedStepStartPoint()

  const handleAddMove = (selectedProbeStep: WcsProbingStep | ProbingStep | undefined) => {
    if (selectedSection?.kind === WcsProbingKindEnum.WcsProbing) {
      handleAddWcsProbingSteps(
        [getNewWcsProbingStep({ kind: WcsProbingMoveKindEnum.WcsExplicitMove, contactPoint })],
        selectedProbeStep?.id,
        1
      )
    } else {
      if (
        selectedProbeStep?.kind === PointsFeatureMoveKindEnum.PointsFeatureMove ||
        selectedProbeStep?.kind === PointsFeaturePointKindEnum.PointsFeaturePoint
      ) {
        handleAddSubMove(selectedProbeStep, false)
      } else {
        handleAddSteps(
          [getDefaultStep({ kind: ExplicitMoveKindEnum.ExplicitMove, contactPoint, coords })],
          selectedProbeStep?.id,
          1
        )
      }
    }
  }

  const handleAddSteps = (
    steps: (
      | BoreFeature
      | BossFeature
      | MultiLevelBoreFeature
      | MultiLevelBossFeature
      | SphereFeature
      | ExplicitMove
      | PointsFeature
    )[],
    insertBeforeId: string | undefined,
    offset = 0
  ) => {
    switch (selectedSection?.kind) {
      case AlignmentKindEnum.Alignment: {
        let newSteps = selectedSection?.steps.slice() ?? []
        if (insertBeforeId !== undefined) {
          const index = newSteps.findIndex(step => {
            return (
              step.id === insertBeforeId ||
              (step.kind === PointsFeatureKindEnum.PointsFeature &&
                step.steps.some(step => step.id === insertBeforeId))
            )
          })
          newSteps.splice(index + offset || 0, 0, ...steps)
        } else {
          newSteps = [...newSteps, ...steps]
        }
        updateAlignment(
          {
            alignment: selectedSection,
            thunkInputs: { ...probingThunkContext, dispatch },
            updates: { steps: newSteps },
          },
          steps[steps.length - 1].id
        )
        break
      }

      case InspectionKindEnum.Inspection: {
        let newSteps = selectedSection?.steps.slice() ?? []
        if (insertBeforeId !== undefined) {
          const index = newSteps.findIndex(step => step.id === insertBeforeId)
          newSteps.splice(index + offset || 0, 0, ...steps)
        } else {
          newSteps = [...newSteps, ...steps]
        }
        updateInspection(
          {
            inspection: selectedSection,
            thunkInputs: { ...probingThunkContext, dispatch },
            updates: { steps: newSteps },
          },
          steps[steps.length - 1].id
        )
        break
      }
    }
  }

  const handleAddWcsProbingSteps = (
    steps: WcsProbingStep[],
    insertBeforeId: string | undefined,
    offset = 0
  ) => {
    let newSteps = (selectedSection as WcsProbing)?.steps.slice() ?? []

    if (insertBeforeId !== undefined) {
      const index = newSteps.findIndex(step => step.id === insertBeforeId)
      newSteps.splice(index + offset || 0, 0, ...steps)
    } else {
      newSteps = [...newSteps, ...steps]
    }

    updateWcsProbing({
      wcsProbing: selectedSection as WcsProbing,
      thunkInputs: { ...probingThunkContext, dispatch },
      params: { steps: newSteps },
      newSelectedStep: steps[steps.length - 1],
    })
  }

  return {
    handleAddSteps,
    handleAddWcsProbingSteps,
    handleAddMove,
    contactPoint,
    coords,
    selectedSection,
  }
}
