import * as React from "react"
import { FC } from "react"
import { a, useSpring } from "@react-spring/three"

import { PartialTransform } from "src/client-axios"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { TRANSITION_CONFIG } from "src/util/animation/springConfig"
import { transformToSpringInputs } from "src/util/geometry/transforms"

interface AnimatedPartialTransformNodeProps {
  transform?: PartialTransform
  visible?: boolean
}

export const AnimatedPartialTransformNode: FC<AnimatedPartialTransformNodeProps> = ({
  transform,
  visible = true,
  children,
}) => {
  const { transferInvalidate } = useTransferInvalidate()

  const { position: targetPosition, rotation: targetRotation } = transformToSpringInputs(transform)

  const [{ position, rotation }] = useSpring(
    {
      to: { position: targetPosition, rotation: targetRotation },
      onChange: () => transferInvalidate(),
      config: TRANSITION_CONFIG,
    },
    [targetPosition, targetRotation, transferInvalidate]
  )

  return (
    <a.group
      position={(position as unknown) as [x: number, y: number, z: number]}
      rotation={(rotation as unknown) as [x: number, y: number, z: number]}
      visible={visible}
    >
      {children}
    </a.group>
  )
}
