import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useSelector } from "react-redux"
import { a } from "@react-spring/three"
import { isEqual } from "lodash-es"
import * as THREE from "three"

import {
  FixturesConfig,
  FixtureStock,
  MachineRecord,
  ModelStock,
  Operation,
  OperationOutputStock,
  ParametricStock,
  ParametricStockShapeRectangularKindEnum,
  ParametricStockShapeRoundKindEnum,
} from "src/client-axios"
import { AnimatedSizedZPlaneGrid } from "src/components/Canvas/Viewer/Grid/SizedZPlaneGrid"
import { PointLights } from "src/components/Canvas/Viewer/Lighting/PointLights"
import { CmmScene } from "src/components/Canvas/Viewer/Scene/Cam/CmmScene"
import { FixtureChoicesScene } from "src/components/Canvas/Viewer/Scene/Cam/FixtureChoiceScene"
import { DesignScene, PartScene } from "src/components/Canvas/Viewer/Scene/Cam/PartScene"
import { StocksScene } from "src/components/Canvas/Viewer/Scene/Cam/StockScene"
import { WcsScene } from "src/components/Canvas/Viewer/Scene/Cam/WcsScene"
import { SceneModelFragment, useSceneModelsByIDsQuery } from "src/graphql/generated"
import { useCameraControls } from "src/hooks/useCameraControls"
import { activeSelectors } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { fixturesSelectors } from "src/store/config/fixtures"
import { RootState } from "src/store/rootStore"
import { useCuttingSimDisplayStore } from "src/store/zustandCuttingSim"
import { useMachineOperation } from "src/store/zustandMachine"
import { MaybeFluidValue } from "src/util/animation/mergeValues"
import { ProbeScene } from "../../../ClickHandlers/Probe/ProbeScene"
import { MachineScene } from "../Machine/MachineScene"
import { SpindleScene } from "../McsScene"
import { getMcsTransformMatrix } from "../operationUtil"
import { StockSimParamSurfScene } from "../StockSimParamSurfScene"
import { StockSimScene } from "../StockSimScene"
import { PlanOperation } from "./sharedTypes"

interface CamSceneOpacities {
  part?: MaybeFluidValue<number>
  fixtures?: MaybeFluidValue<number>
  partGrid?: MaybeFluidValue<number>
  mcsGrid?: MaybeFluidValue<number>
}

interface SplitRotation {
  x: MaybeFluidValue<number>
  y: MaybeFluidValue<number>
  z: MaybeFluidValue<number>
}

interface Kinematics {
  rotY: MaybeFluidValue<number>
  rotZ: MaybeFluidValue<number>
}

interface CamSceneLoaderProps {
  partModel: SceneModelFragment | undefined
  planId?: string
  operationIdx?: number

  position: MaybeFluidValue<number[]>
  rotation: SplitRotation
  kinematics?: Kinematics

  opacities: CamSceneOpacities
  fixturesOpening: MaybeFluidValue<number>

  onLoad?: () => void
  minimal?: boolean
  noMachine?: boolean
  sceneRef: MutableRefObject<THREE.Group | undefined>
}

type InputStock = FixtureStock | ModelStock | OperationOutputStock | ParametricStock

interface OperationSetupProps {
  machine: MachineRecord | undefined
  partModel: SceneModelFragment | undefined
  planOperation: PlanOperation
  position: MaybeFluidValue<number[]>
  rotation: SplitRotation

  opacities: CamSceneOpacities
  fixturesOpening: MaybeFluidValue<number>

  minimal?: boolean

  // TODO: is there a way to remove these?
  onLoad?: () => void
  sceneRef: MutableRefObject<THREE.Group | undefined>
}

function operationStockModelIds(
  operationIdx: number,
  inputStock?: InputStock,
  operations?: Operation[],
  fixturesConfig?: FixturesConfig | null
): string[] {
  if (inputStock?.kind === "model") {
    return [inputStock.modelId]
  } else if (inputStock?.kind === "operation_output") {
    const id = operations?.find(op => op.id === inputStock.operationId)?.outputStock?.modelId
    return id ? [id] : []
  } else if (inputStock === undefined && operationIdx >= 1) {
    const prevOperationIdx = operationIdx - 1
    const id = operations?.[prevOperationIdx].outputStock?.modelId
    return id ? [id] : []
  } else if (inputStock?.kind === "fixture") {
    const fixture = fixturesConfig?.fixtures.find(
      f => inputStock?.kind === "fixture" && inputStock.fixtureId === f.id
    )
    if (fixture?.bodies) {
      const bodies = Object.values(fixture.bodies)
      const millableBodies = bodies.filter(body => body.isCustomMillable)
      return millableBodies.map(body => body.model)
    }
  }

  return []
}

interface StockBounds {
  min: THREE.Vector3
  max: THREE.Vector3
}

interface StockModelNode {
  minX: number
  minY: number
  minZ: number
  maxX: number
  maxY: number
  maxZ: number
}

function defaultStockBounds(): StockBounds {
  return {
    min: new THREE.Vector3(-100, -100, -100),
    max: new THREE.Vector3(100, 100, 100),
  }
}

function parametricStockBounds(inputStock: ParametricStock): StockBounds {
  if (inputStock.shape.kind === ParametricStockShapeRectangularKindEnum.Rectangular) {
    return {
      min: new THREE.Vector3(
        -inputStock.shape.x / 2.0,
        -inputStock.shape.y / 2.0,
        -inputStock.cutLength / 2.0
      ),
      max: new THREE.Vector3(
        inputStock.shape.x / 2.0,
        inputStock.shape.y / 2.0,
        inputStock.cutLength / 2.0
      ),
    }
  } else if (inputStock.shape.kind === ParametricStockShapeRoundKindEnum.Round) {
    return {
      min: new THREE.Vector3(
        -inputStock.shape.diameter / 2.0,
        -inputStock.shape.diameter / 2.0,
        -inputStock.cutLength / 2.0
      ),
      max: new THREE.Vector3(
        inputStock.shape.diameter / 2.0,
        inputStock.shape.diameter / 2.0,
        inputStock.cutLength / 2.0
      ),
    }
  } else {
    return defaultStockBounds()
  }
}

function stockModelNodesBounds(stockModelsNodes: StockModelNode[]): StockBounds {
  let { minX, minY, minZ, maxX, maxY, maxZ } = stockModelsNodes[0]
  stockModelsNodes.forEach(model => {
    minX = Math.min(model.minX, minX)
    minY = Math.min(model.minY, minY)
    minZ = Math.min(model.minZ, minZ)
    maxX = Math.max(model.maxX, maxX)
    maxY = Math.max(model.maxY, maxY)
    maxZ = Math.max(model.maxZ, maxZ)
  })
  return {
    min: new THREE.Vector3(minX, minY, minZ),
    max: new THREE.Vector3(maxX, maxY, maxZ),
  }
}

const OperationSetup: FC<OperationSetupProps> = ({
  machine,
  partModel,
  planOperation,
  position,
  rotation,
  opacities,
  fixturesOpening,
  minimal,
  onLoad,
  sceneRef,
}) => {
  const operations = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperations(state, planOperation.planId)
  )
  const operation = planOperation.operation
  const fixturesConfig = useSelector(fixturesSelectors.selectFixturesConfig)
  const [loadingPart, setLoadingPart] = useState(true)
  const [focusedOnStock, setFocusedOnStock] = useState(false)

  const prevOperation = useSelector(
    (state: RootState) =>
      storedPlansSelectors.selectOperation(
        state,
        planOperation.planId,
        planOperation.operationIdx - 1
      ),
    isEqual
  )

  const inputStock = useSelector(
    (state: RootState) =>
      storedOperationSelectors.selectInputStock(
        state,
        planOperation.planId,
        planOperation.operationIdx
      ),
    isEqual
  )
  const operationId = inputStock?.kind === "operation_output" ? inputStock.operationId : undefined
  const operationOutputStock = useSelector(
    (state: RootState) =>
      storedOperationSelectors.selectOutputStock(state, planOperation.planId, operationId),
    (left, right) => left === right
  )
  const inputStockModelIds = useMemo(() => {
    return operationStockModelIds(
      planOperation.operationIdx,
      inputStock,
      operations,
      fixturesConfig
    )
  }, [inputStock, operations, planOperation, fixturesConfig])

  const { data: inputStockModels } = useSceneModelsByIDsQuery({
    variables: { ids: inputStockModelIds },
  })
  const inputStockModelsNodes = useMemo(() => inputStockModels?.model3Ds?.nodes ?? [], [
    inputStockModels,
  ])

  const keyedWcs = { wcs: operation.wcs, mcs: operation.mcs, identifier: operation.id }

  const cameraControls = useCameraControls()
  const groupRef = useRef(new THREE.Group())

  const focusOnStock = useCallback(() => {
    if (!inputStock || !groupRef.current) return

    groupRef.current.updateWorldMatrix(true, true)

    const { min, max } = (() => {
      if (inputStock.kind === "parametric") {
        return parametricStockBounds(inputStock)
      } else if (inputStockModelsNodes.length > 0) {
        return stockModelNodesBounds(inputStockModelsNodes)
      } else {
        return defaultStockBounds()
      }
    })()

    // Ensure the focus box isn't set too small
    let minAxisSize = 50
    if (min.length() === 1e-3 && max.length() < 1e-3) {
      // This likely means that the relevant model hasn't been "analyzed" properly; we'll use a larger minAxisSize
      // in this case, since the computed focus box is probably off by a lot
      minAxisSize = 200
    }

    if (max.x - min.x < minAxisSize) {
      max.setX((max.x + min.x) / 2 + minAxisSize / 2)
      min.setX((max.x + min.x) / 2 - minAxisSize / 2)
    }
    if (max.y - min.y < minAxisSize) {
      max.setY((max.y + min.y) / 2 + minAxisSize / 2)
      min.setY((max.y + min.y) / 2 - minAxisSize / 2)
    }
    if (max.z - min.z < minAxisSize) {
      max.setZ((max.z + min.z) / 2 + minAxisSize / 2)
      min.setZ((max.z + min.z) / 2 - minAxisSize / 2)
    }

    const box = new THREE.Box3(min, max)
      .applyMatrix4(getMcsTransformMatrix(inputStock?.transform))
      .applyMatrix4(groupRef.current.matrixWorld)
    const size = new THREE.Vector3()
    box.getSize(size)

    if (size.length()) {
      cameraControls.setSceneBox(
        { box, scale: minimal ? 1.0 : 0.5, preserveCameraOrientation: false },
        { immediate: true }
      )
      setFocusedOnStock(true)
    }
  }, [cameraControls, inputStock, inputStockModelsNodes, minimal])

  useEffect(() => {
    if (loadingPart || focusedOnStock) return
    focusOnStock()
  }, [loadingPart, focusedOnStock, focusOnStock])

  useMachineOperation(machine, operation)

  const cuttingSimState = useCuttingSimDisplayStore()
  const stockSimScene = useMemo(() => {
    if (cuttingSimState.showInputStock && prevOperation) {
      const prevPlanOperation = {
        planId: planOperation.planId,
        operationIdx: planOperation.operationIdx - 1,
        operation: prevOperation,
      }
      return <StockSimScene planOperation={prevPlanOperation} />
    } else {
      return <StockSimScene planOperation={planOperation} />
    }
  }, [cuttingSimState.showInputStock, planOperation, prevOperation])

  return (
    <group>
      <a.group
        rotation-x={rotation.x}
        rotation-y={rotation.y}
        rotation-z={rotation.z}
        position={(position as unknown) as [x: number, y: number, z: number]}
        ref={groupRef}
      >
        {!minimal && operation.outputStock && stockSimScene}
        {!minimal && operation.outputStock && <CmmScene />}
        {!minimal && operation.outputStock && <StockSimParamSurfScene />}
        {operation.designId ? (
          <DesignScene
            modelId={operation?.designId}
            opacity={opacities.part}
            onLoad={onLoad}
            sceneRef={sceneRef}
          />
        ) : (
          <PartScene
            model={partModel}
            opacity={opacities.part}
            onLoad={() => {
              setLoadingPart(false)
              onLoad?.()
            }}
            sceneRef={sceneRef}
          />
        )}
        <WcsScene wcs={keyedWcs} minimal={minimal} />
        <StocksScene
          operationIdx={planOperation.operationIdx}
          opacity={opacities.part}
          inputStock={inputStock}
          outputStock={operation?.outputStock}
          operationOutputStock={operationOutputStock}
        />
      </a.group>

      <SpindleScene />
      <AnimatedSizedZPlaneGrid size={500} opacity={opacities.mcsGrid} />

      <FixtureChoicesScene
        operation={operation}
        opacity={opacities.fixtures}
        opening={fixturesOpening}
        offset={undefined}
        minimal={minimal}
      />
    </group>
  )
}

export const CamSceneLoader: FC<CamSceneLoaderProps> = ({
  partModel,
  planId,
  operationIdx,
  position,
  rotation,
  opacities,
  fixturesOpening,
  onLoad,
  minimal,
  noMachine,
  sceneRef,
}) => {
  const activeConfigTab = useSelector(activeSelectors.selectActiveConfigTab)

  const operation = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx),
    isEqual
  )
  const machineSelector = useMemo(
    () => storedOperationSelectors.selectMachineRecord(planId, operationIdx),
    [planId, operationIdx]
  )
  const machine = useSelector(machineSelector)

  const planOperation = useMemo(() => {
    if (planId && operationIdx !== undefined && operation) {
      return { planId, operationIdx, operation }
    }
    return null
  }, [planId, operationIdx, operation])

  const attachments = useMemo(() => {
    const attachments: Record<string, JSX.Element> = {}
    if (activeConfigTab === "production") {
      attachments.Tool = <ProbeScene showHolder={true} />
    }

    if (planOperation) {
      const attachComponent = (
        <OperationSetup
          machine={machine}
          partModel={partModel}
          planOperation={planOperation}
          position={position}
          rotation={rotation}
          opacities={opacities}
          fixturesOpening={fixturesOpening}
          minimal={minimal}
          onLoad={onLoad}
          sceneRef={sceneRef}
        />
      )
      attachments.Attach = attachComponent
    }
    return attachments
  }, [
    activeConfigTab,
    fixturesOpening,
    machine,
    minimal,
    onLoad,
    opacities,
    partModel,
    planOperation,
    position,
    rotation,
    sceneRef,
  ])

  return (
    <>
      <PointLights distance={600} target={new THREE.Vector3(0, 0, 0)} />
      {!noMachine && machine ? (
        <MachineScene machine={machine} attachments={attachments} />
      ) : (
        attachments.Attach
      )}
    </>
  )
}
