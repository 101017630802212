import React, { FC, useCallback, useEffect, useState } from "react"
import { Button, Menu, MenuDivider, MenuItem } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { startCase, toLower } from "lodash-es"

import { Modal } from "src/components/Generic/Modal/Modal"
import {
  CamJobOverviewFragment,
  Camjobstatus,
  useOverviewQuery,
  useUpdateCamJobStatusMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useJobBackupDownloader } from "src/hooks/useArchiveDownloader"
import { useToaster } from "src/hooks/useToaster"

export const JobActions: FC<{
  camJob: CamJobOverviewFragment
}> = ({ camJob }) => {
  const toaster = useToaster()

  const [
    updateCamJobStatus,
    { loading: updatingStatus, error: statusUpdateError },
  ] = useUpdateCamJobStatusMutation()

  const [showPurge, setShowPurge] = useState(false)
  const [addModal, setAddModal] = useState(false)

  const generateJobBackup = useJobBackupDownloader()

  const handleStatusChange = useCallback(
    (status: Camjobstatus) => {
      updateCamJobStatus({
        variables: { id: camJob.id, status },
        refetchQueries: ["overview"],
      })
      toaster.show({
        message: `Updating job ${camJob.label}, status: ${startCase(toLower(status))}`,
        intent: "primary",
      })
    },
    [toaster, camJob, updateCamJobStatus]
  )

  useEffect(() => {
    if (statusUpdateError) {
      toaster.show({
        message: `Error updating status of job: ${camJob.label}`,
        intent: "danger",
      })
    }
  }, [statusUpdateError, toaster, camJob])

  const backupJob = useCallback(() => {
    generateJobBackup(camJob.id)
  }, [camJob, generateJobBackup])

  return (
    <>
      <Popover2
        usePortal={false}
        content={
          <Menu>
            <MenuItem text="Move Job to">
              {Object.entries(Camjobstatus).map(
                ([key, value]) =>
                  value !== camJob.status && (
                    <MenuItem
                      key={key}
                      text={startCase(key)}
                      onClick={() => handleStatusChange(value)}
                    />
                  )
              )}
            </MenuItem>
            <MenuDivider />
            {camJob.status === Camjobstatus.Archived && (
              <MenuItem
                text={"Permanently Delete"}
                onClick={() => {
                  setAddModal(true)
                  setShowPurge(true)
                }}
              />
            )}
            <MenuItem text={"Backup Job"} onClick={backupJob} />
          </Menu>
        }
      >
        <Button minimal icon="more" disabled={updatingStatus} />
      </Popover2>
      {addModal && (
        <PurgeJobModal isOpen={showPurge} close={() => setShowPurge(false)} jobId={camJob.id} />
      )}
    </>
  )
}

const PurgeJobModal: FC<{
  jobId: string
  isOpen: boolean
  close: () => void
}> = ({ jobId, isOpen, close }) => {
  const { jobsApi } = useApi()

  const { refetch } = useOverviewQuery()

  const handleDelete = () => {
    jobsApi.purge(jobId).then(() => {
      refetch().then(() => {
        close()
      })
    })
  }
  const handleCancel = () => {
    close()
  }
  const handleConfirm = () => {
    close()
    handleDelete()
  }
  return (
    <Modal
      iconName={"high-priority"}
      isOpen={isOpen}
      headerText="Permanently Delete Job"
      bodyText="This will completely erase the job and model from the database.  This action is irreversible. Are you sure you want to permanently delete this job?"
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleRequestClose={close}
    />
  )
}
