/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration"
import globalAxios, { AxiosPromise, AxiosInstance } from "axios"
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base"

/**
 *
 * @export
 * @interface AddPatchOperation
 */
export interface AddPatchOperation {
  /**
   *
   * @type {string}
   * @memberof AddPatchOperation
   */
  op: AddPatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof AddPatchOperation
   */
  path: string
  /**
   *
   * @type {string | boolean | number | Array<any> | object}
   * @memberof AddPatchOperation
   */
  value: string | boolean | number | Array<any> | object
}

/**
 * @export
 * @enum {string}
 */
export enum AddPatchOperationOpEnum {
  Add = "add",
}

/**
 *
 * @export
 * @interface AddressInfo
 */
export interface AddressInfo {
  /**
   *
   * @type {number}
   * @memberof AddressInfo
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  address1?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  address2?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  attention?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  business_name?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  country?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  phone_ext?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  postal_code?: string
  /**
   *
   * @type {string}
   * @memberof AddressInfo
   */
  state?: string
}
/**
 *
 * @export
 * @interface Alignment
 */
export interface Alignment {
  /**
   *
   * @type {string}
   * @memberof Alignment
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Alignment
   */
  kind: AlignmentKindEnum
  /**
   *
   * @type {number}
   * @memberof Alignment
   */
  alignmentNumber: number
  /**
   *
   * @type {Array<AlignmentAxis>}
   * @memberof Alignment
   */
  lockedAxes: Array<AlignmentAxis>
  /**
   *
   * @type {Array<BoreFeature | BossFeature | ExplicitMove | MultiLevelBoreFeature | MultiLevelBossFeature | PointsFeature | SphereFeature>}
   * @memberof Alignment
   */
  steps: Array<
    | BoreFeature
    | BossFeature
    | ExplicitMove
    | MultiLevelBoreFeature
    | MultiLevelBossFeature
    | PointsFeature
    | SphereFeature
  >
  /**
   *
   * @type {AlignmentWcsKind}
   * @memberof Alignment
   */
  wcsKind?: AlignmentWcsKind
  /**
   *
   * @type {Vector}
   * @memberof Alignment
   */
  wcsExperienceCorrection?: Vector
  /**
   *
   * @type {boolean}
   * @memberof Alignment
   */
  isIterative?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Alignment
   */
  generateAlignmentInspection?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Alignment
   */
  disabled?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Alignment
   */
  skipConstraintsCheck?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Alignment
   */
  skipApproachCheck?: boolean
  /**
   *
   * @type {Array<ProbingIssue>}
   * @memberof Alignment
   */
  issues?: Array<ProbingIssue>
  /**
   *
   * @type {number}
   * @memberof Alignment
   */
  toolChange?: number
}

/**
 * @export
 * @enum {string}
 */
export enum AlignmentKindEnum {
  Alignment = "Alignment",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AlignmentAxis {
  A = "A",
  B = "B",
  C = "C",
  X = "X",
  Y = "Y",
  Z = "Z",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AlignmentItemKind {
  ExplicitMove = "explicit_move",
  BoreFeature = "bore_feature",
  BossFeature = "boss_feature",
  SphereFeature = "sphere_feature",
  PointsFeature = "points_feature",
  MultiLevelBoreFeature = "multi_level_bore_feature",
  MultiLevelBossFeature = "multi_level_boss_feature",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AlignmentWcsKind {
  Alignment = "Alignment",
  G54 = "G54",
  AlignmentAndG54 = "AlignmentAndG54",
  Refinement = "Refinement",
  AlignmentAndRefinement = "AlignmentAndRefinement",
}

/**
 *
 * @export
 * @interface AnnotationSidebarItem
 */
export interface AnnotationSidebarItem {
  /**
   *
   * @type {string}
   * @memberof AnnotationSidebarItem
   */
  jobId: string
  /**
   *
   * @type {string}
   * @memberof AnnotationSidebarItem
   */
  planId: string
  /**
   *
   * @type {number}
   * @memberof AnnotationSidebarItem
   */
  revision: number
  /**
   *
   * @type {Array<LabelCount>}
   * @memberof AnnotationSidebarItem
   */
  labelCounts: Array<LabelCount>
}
/**
 *
 * @export
 * @interface AppModelsGeometryTransform
 */
export interface AppModelsGeometryTransform {
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  y: number
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  z: number
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  i: number
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  j: number
  /**
   *
   * @type {number}
   * @memberof AppModelsGeometryTransform
   */
  k: number
}
/**
 *
 * @export
 * @interface AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
 */
export interface AppModelsInspectionSchemaGeneratedInspectionSchemaTransform {
  /**
   *
   * @type {Array<number>}
   * @memberof AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
   */
  translate?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
   */
  x_axis?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
   */
  y_axis?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
   */
  z_axis?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
   */
  scale?: Array<number>
}
/**
 *
 * @export
 * @interface ArcAngles
 */
export interface ArcAngles {
  /**
   *
   * @type {number}
   * @memberof ArcAngles
   */
  start: number
  /**
   *
   * @type {number}
   * @memberof ArcAngles
   */
  end: number
}
/**
 *
 * @export
 * @interface Attachment
 */
export interface Attachment {
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  identifier: string
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  element: string
}
/**
 *
 * @export
 * @interface AttachmentPointRecord
 */
export interface AttachmentPointRecord {
  /**
   *
   * @type {PartialTransform}
   * @memberof AttachmentPointRecord
   */
  transform: PartialTransform
  /**
   *
   * @type {PartialVector}
   * @memberof AttachmentPointRecord
   */
  movement?: PartialVector
  /**
   *
   * @type {boolean}
   * @memberof AttachmentPointRecord
   */
  excludeFromTemplates?: boolean
}
/**
 *
 * @export
 * @interface AxisTravelLimit
 */
export interface AxisTravelLimit {
  /**
   *
   * @type {number}
   * @memberof AxisTravelLimit
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof AxisTravelLimit
   */
  max: number
}
/**
 *
 * @export
 * @interface BaseStockOnlyFeature
 */
export interface BaseStockOnlyFeature {
  /**
   *
   * @type {string}
   * @memberof BaseStockOnlyFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof BaseStockOnlyFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof BaseStockOnlyFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof BaseStockOnlyFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof BaseStockOnlyFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof BaseStockOnlyFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof BaseStockOnlyFeature
   */
  result_type: BaseStockOnlyFeatureResultTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum BaseStockOnlyFeatureResultTypeEnum {
  AllPointsAreBaseStock = "AllPointsAreBaseStock",
}

/**
 *
 * @export
 * @interface BodyAssociateXdfApiRsV1AssociateXdfPost
 */
export interface BodyAssociateXdfApiRsV1AssociateXdfPost {
  /**
   *
   * @type {any}
   * @memberof BodyAssociateXdfApiRsV1AssociateXdfPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyCreateDocumentFileApiV1FilesDocumentFilePost
 */
export interface BodyCreateDocumentFileApiV1FilesDocumentFilePost {
  /**
   *
   * @type {any}
   * @memberof BodyCreateDocumentFileApiV1FilesDocumentFilePost
   */
  document_file: any
}
/**
 *
 * @export
 * @interface BodyCreateNcProgramApiV1FilesNcProgramPost
 */
export interface BodyCreateNcProgramApiV1FilesNcProgramPost {
  /**
   *
   * @type {string}
   * @memberof BodyCreateNcProgramApiV1FilesNcProgramPost
   */
  plan_id: string
  /**
   *
   * @type {number}
   * @memberof BodyCreateNcProgramApiV1FilesNcProgramPost
   */
  operation_idx: number
  /**
   *
   * @type {any}
   * @memberof BodyCreateNcProgramApiV1FilesNcProgramPost
   */
  file: any
  /**
   *
   * @type {any}
   * @memberof BodyCreateNcProgramApiV1FilesNcProgramPost
   */
  document_file?: any
}
/**
 *
 * @export
 * @interface BodyCreateUserModelApiV1FilesUserModelPost
 */
export interface BodyCreateUserModelApiV1FilesUserModelPost {
  /**
   *
   * @type {any}
   * @memberof BodyCreateUserModelApiV1FilesUserModelPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
 */
export interface BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost {
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfaceCylinder | MachinedStockSimSurfacePlane | MachinedStockSimSurfaceSphere | MachinedStockSimSurfaceTorus>}
   * @memberof BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
   */
  surfaces: Array<
    | MachinedStockSimSurfaceCone
    | MachinedStockSimSurfaceCylinder
    | MachinedStockSimSurfacePlane
    | MachinedStockSimSurfaceSphere
    | MachinedStockSimSurfaceTorus
  >
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
   */
  transform: AppModelsGeometryTransform
}
/**
 *
 * @export
 * @interface BodyImportVericutProjectApiV1FilesVericutProjectPost
 */
export interface BodyImportVericutProjectApiV1FilesVericutProjectPost {
  /**
   *
   * @type {any}
   * @memberof BodyImportVericutProjectApiV1FilesVericutProjectPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyNcProgramApiRsV1NcProgramPost
 */
export interface BodyNcProgramApiRsV1NcProgramPost {
  /**
   *
   * @type {any}
   * @memberof BodyNcProgramApiRsV1NcProgramPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyNewFileApiRsV1FilePost
 */
export interface BodyNewFileApiRsV1FilePost {
  /**
   *
   * @type {ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload}
   * @memberof BodyNewFileApiRsV1FilePost
   */
  upload_file_type: ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload
  /**
   *
   * @type {any}
   * @memberof BodyNewFileApiRsV1FilePost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyNewModelApiV1FilesModelPost
 */
export interface BodyNewModelApiV1FilesModelPost {
  /**
   *
   * @type {any}
   * @memberof BodyNewModelApiV1FilesModelPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyNewModelForJobApiV1FilesModelForJobPost
 */
export interface BodyNewModelForJobApiV1FilesModelForJobPost {
  /**
   *
   * @type {any}
   * @memberof BodyNewModelForJobApiV1FilesModelForJobPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyNewPartApiRsV1PartPost
 */
export interface BodyNewPartApiRsV1PartPost {
  /**
   *
   * @type {any}
   * @memberof BodyNewPartApiRsV1PartPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyRestoreJobBackupApiV1FilesRestorePost
 */
export interface BodyRestoreJobBackupApiV1FilesRestorePost {
  /**
   *
   * @type {any}
   * @memberof BodyRestoreJobBackupApiV1FilesRestorePost
   */
  file: any
}
/**
 *
 * @export
 * @interface BodyRestorePlanBackupApiV1FilesRestorePlanPost
 */
export interface BodyRestorePlanBackupApiV1FilesRestorePlanPost {
  /**
   *
   * @type {any}
   * @memberof BodyRestorePlanBackupApiV1FilesRestorePlanPost
   */
  file: any
}
/**
 *
 * @export
 * @interface BoreFeature
 */
export interface BoreFeature {
  /**
   *
   * @type {string}
   * @memberof BoreFeature
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof BoreFeature
   */
  kind: BoreFeatureKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof BoreFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof BoreFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof BoreFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof BoreFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof BoreFeature
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof BoreFeature
   */
  nPoints?: number
  /**
   *
   * @type {ProbingTouchType}
   * @memberof BoreFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof BoreFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof BoreFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof BoreFeature
   */
  featureWeight?: number
}

/**
 * @export
 * @enum {string}
 */
export enum BoreFeatureKindEnum {
  BoreFeature = "bore_feature",
}

/**
 *
 * @export
 * @interface BossFeature
 */
export interface BossFeature {
  /**
   *
   * @type {string}
   * @memberof BossFeature
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof BossFeature
   */
  kind: BossFeatureKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof BossFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof BossFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof BossFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof BossFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof BossFeature
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof BossFeature
   */
  nPoints?: number
  /**
   *
   * @type {ProbingTouchType}
   * @memberof BossFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof BossFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof BossFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof BossFeature
   */
  featureWeight?: number
}

/**
 * @export
 * @enum {string}
 */
export enum BossFeatureKindEnum {
  BossFeature = "boss_feature",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum BucketType {
  Remoteshop = "Remoteshop",
  Planning = "Planning",
  Manifest = "Manifest",
  Config = "Config",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CamDataCoordinateSystem {
  Attach = "Attach",
  Machine = "Machine",
}

/**
 *
 * @export
 * @interface CamFeatureInfo
 */
export interface CamFeatureInfo {
  /**
   *
   * @type {string}
   * @memberof CamFeatureInfo
   */
  id: string
  /**
   *
   * @type {Array<GcodeLineRange>}
   * @memberof CamFeatureInfo
   */
  gcode_lines: Array<GcodeLineRange>
  /**
   *
   * @type {number}
   * @memberof CamFeatureInfo
   */
  tool_change: number
  /**
   *
   * @type {Direction}
   * @memberof CamFeatureInfo
   */
  tool_axis?: Direction
  /**
   *
   * @type {string}
   * @memberof CamFeatureInfo
   */
  position: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof CamFeatureInfo
   */
  fcs: AppModelsGeometryTransform
}
/**
 * Reference the Raptor RWP-001 1.5\" Dovetail Preparation Specifications for a diagram
 * @export
 * @interface ClampedDovetailParams
 */
export interface ClampedDovetailParams {
  /**
   *
   * @type {string}
   * @memberof ClampedDovetailParams
   */
  kind: ClampedDovetailParamsKindEnum
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  height: number
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  squareHeight: number
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  measurementOverPins: number
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  pinDiameter: number
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  angleDegrees: number
  /**
   *
   * @type {number}
   * @memberof ClampedDovetailParams
   */
  cornerRadius: number
  /**
   *
   * @type {Array<DovetailKeySlotParams>}
   * @memberof ClampedDovetailParams
   */
  keySlots: Array<DovetailKeySlotParams>
  /**
   *
   * @type {PartialVector}
   * @memberof ClampedDovetailParams
   */
  minStockExtent: PartialVector
  /**
   *
   * @type {PartialVector}
   * @memberof ClampedDovetailParams
   */
  maxStockExtent: PartialVector
}

/**
 * @export
 * @enum {string}
 */
export enum ClampedDovetailParamsKindEnum {
  ClampedDovetail = "clamped_dovetail",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ClampingKind {
  CustomFixturePlate = "custom_fixture_plate",
  ClampedDovetail = "clamped_dovetail",
  FlatJaws = "flat_jaws",
  SerratedJaws = "serrated_jaws",
  SoftJaws = "soft_jaws",
  PrismStockClamping = "prism_stock_clamping",
  RoundStockClamping = "round_stock_clamping",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ClampingMode {
  PART = "PART",
  STOCK = "STOCK",
}

/**
 *
 * @export
 * @interface ClipboardSetupLocator
 */
export interface ClipboardSetupLocator {
  /**
   *
   * @type {string}
   * @memberof ClipboardSetupLocator
   */
  kind: ClipboardSetupLocatorKindEnum
  /**
   *
   * @type {string}
   * @memberof ClipboardSetupLocator
   */
  planId: string
  /**
   *
   * @type {number}
   * @memberof ClipboardSetupLocator
   */
  operationIdx: number
  /**
   *
   * @type {number}
   * @memberof ClipboardSetupLocator
   */
  revision?: number
}

/**
 * @export
 * @enum {string}
 */
export enum ClipboardSetupLocatorKindEnum {
  Setup = "setup",
}

/**
 *
 * @export
 * @interface ClipboardStockLocator
 */
export interface ClipboardStockLocator {
  /**
   *
   * @type {string}
   * @memberof ClipboardStockLocator
   */
  kind: ClipboardStockLocatorKindEnum
  /**
   *
   * @type {string}
   * @memberof ClipboardStockLocator
   */
  planId: string
  /**
   *
   * @type {number}
   * @memberof ClipboardStockLocator
   */
  operationIdx: number
  /**
   *
   * @type {number}
   * @memberof ClipboardStockLocator
   */
  revision?: number
}

/**
 * @export
 * @enum {string}
 */
export enum ClipboardStockLocatorKindEnum {
  Stock = "stock",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CommandType {
  AsmeTolerance = "AsmeTolerance",
  AsmeSize = "AsmeSize",
  Other = "Other",
}

/**
 * If we ever want to do something like supporting \"multiple compensations\" for a single touch point, we should instead modify this data structure to capture whatever information we want in a single object associated with the particular point.
 * @export
 * @interface Compensation
 */
export interface Compensation {
  /**
   *
   * @type {number}
   * @memberof Compensation
   */
  toolNumber: number
  /**
   *
   * @type {CompensationKind}
   * @memberof Compensation
   */
  offsetKind: CompensationKind
  /**
   *
   * @type {string | number}
   * @memberof Compensation
   */
  offsetId: string | number
  /**
   *
   * @type {number}
   * @memberof Compensation
   */
  updateMultiplier?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CompensationKind {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
}

/**
 *
 * @export
 * @interface ComponentChild
 */
export interface ComponentChild {
  /**
   *
   * @type {number}
   * @memberof ComponentChild
   */
  child_id?: number
  /**
   *
   * @type {number}
   * @memberof ComponentChild
   */
  quantity?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ComponentType {
  Assembled = "assembled",
  Manufactured = "manufactured",
  Purchased = "purchased",
}

/**
 *
 * @export
 * @interface ControlPlanUpload
 */
export interface ControlPlanUpload {
  /**
   *
   * @type {string}
   * @memberof ControlPlanUpload
   */
  kind: ControlPlanUploadKindEnum
  /**
   *
   * @type {string}
   * @memberof ControlPlanUpload
   */
  controlPlanId: string
}

/**
 * @export
 * @enum {string}
 */
export enum ControlPlanUploadKindEnum {
  ControlPlan = "control_plan",
}

/**
 *
 * @export
 * @interface CoordinateSystemOrigins
 */
export interface CoordinateSystemOrigins {
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof CoordinateSystemOrigins
   */
  table: AppModelsGeometryTransform
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof CoordinateSystemOrigins
   */
  machine: AppModelsGeometryTransform
}
/**
 *
 * @export
 * @interface CopyPatchOperation
 */
export interface CopyPatchOperation {
  /**
   *
   * @type {string}
   * @memberof CopyPatchOperation
   */
  op: CopyPatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof CopyPatchOperation
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof CopyPatchOperation
   */
  path: string
}

/**
 * @export
 * @enum {string}
 */
export enum CopyPatchOperationOpEnum {
  Copy = "copy",
}

/**
 *
 * @export
 * @interface CustomFixturePlateParams
 */
export interface CustomFixturePlateParams {
  /**
   *
   * @type {string}
   * @memberof CustomFixturePlateParams
   */
  kind: CustomFixturePlateParamsKindEnum
  /**
   *
   * @type {number}
   * @memberof CustomFixturePlateParams
   */
  maxDepth: number
  /**
   *
   * @type {number}
   * @memberof CustomFixturePlateParams
   */
  xExtent: number
  /**
   *
   * @type {number}
   * @memberof CustomFixturePlateParams
   */
  yExtent: number
  /**
   *
   * @type {Array<string>}
   * @memberof CustomFixturePlateParams
   */
  bites: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum CustomFixturePlateParamsKindEnum {
  CustomFixturePlate = "custom_fixture_plate",
}

/**
 *
 * @export
 * @interface CutConfig
 */
export interface CutConfig {
  /**
   *
   * @type {boolean}
   * @memberof CutConfig
   */
  performPreActivation?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CutConfig
   */
  clampAxes?: boolean
}
/**
 *
 * @export
 * @interface CutInfoToolPathData
 */
export interface CutInfoToolPathData {
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  volume: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  max_depth_of_cut: Array<number>
  /**
   *
   * @type {Array<boolean>}
   * @memberof CutInfoToolPathData
   */
  left_engaged: Array<boolean>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  left_min_width: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  left_max_width: Array<number>
  /**
   *
   * @type {Array<boolean>}
   * @memberof CutInfoToolPathData
   */
  right_engaged: Array<boolean>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  right_min_width: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  right_max_width: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof CutInfoToolPathData
   */
  width_of_cut: Array<number>
}
/**
 *
 * @export
 * @interface CutterComp
 */
export interface CutterComp {
  /**
   *
   * @type {CutterCompKind}
   * @memberof CutterComp
   */
  k: CutterCompKind
  /**
   *
   * @type {number}
   * @memberof CutterComp
   */
  l: number
  /**
   *
   * @type {number}
   * @memberof CutterComp
   */
  e: number
  /**
   *
   * @type {number}
   * @memberof CutterComp
   */
  v: number
  /**
   *
   * @type {number}
   * @memberof CutterComp
   */
  t: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CutterCompKind {
  Length = "Length",
  Left = "Left",
  Right = "Right",
}

/**
 *
 * @export
 * @interface CuttingBasedLength
 */
export interface CuttingBasedLength {
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  feed: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  speed: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  depthY: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  depthZRoughing?: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  depthZFinishing?: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedLength
   */
  nFinishingPasses?: number
}
/**
 *
 * @export
 * @interface CuttingBasedRadius
 */
export interface CuttingBasedRadius {
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  feed: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  speed: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  depthZ: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  depthYRoughing?: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  depthYFinishing?: number
  /**
   *
   * @type {number}
   * @memberof CuttingBasedRadius
   */
  nFinishingPasses?: number
}
/**
 *
 * @export
 * @interface DashboardJob
 */
export interface DashboardJob {
  /**
   *
   * @type {Job}
   * @memberof DashboardJob
   */
  job: Job
  /**
   *
   * @type {Array<OpSummary>}
   * @memberof DashboardJob
   */
  opSummaries?: Array<OpSummary>
}
/**
 *
 * @export
 * @interface DatumReferenceFrame
 */
export interface DatumReferenceFrame {
  /**
   *
   * @type {Array<string>}
   * @memberof DatumReferenceFrame
   */
  datums: Array<string>
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof DatumReferenceFrame
   */
  sku_coordinate_system: AppModelsGeometryTransform
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof DatumReferenceFrame
   */
  unit_coordinate_system: AppModelsGeometryTransform
  /**
   *
   * @type {string}
   * @memberof DatumReferenceFrame
   */
  ref_dim?: string
}
/**
 *
 * @export
 * @interface Dimension
 */
export interface Dimension {
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimTool?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimCode?: string
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimMultiplier?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimType?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimRequirement?: string
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimData?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimDataPrec?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimLowerTol?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimUpperTol?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimText?: string
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimSource?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimDesignator?: number
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimTolType?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatum1?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatum2?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatum3?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimTolMod1?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimTolMod2?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimTolMod3?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatMod1?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatMod2?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimDatMod3?: string
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimUnits?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  dimSort?: string
  /**
   *
   * @type {number}
   * @memberof Dimension
   */
  dimDrawingId?: number
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  shapeLocation?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  shapeCenter?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  shapePoints?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  shapeOffset?: string
  /**
   *
   * @type {string}
   * @memberof Dimension
   */
  shapeTitle?: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DimensionType {
  Angular = "Angular",
  Angularity = "Angularity",
  ArcLength = "ArcLength",
  BilateralX = "BilateralX",
  BilateralY = "BilateralY",
  BilateralZ = "BilateralZ",
  Chamfer = "Chamfer",
  CircularRunout = "CircularRunout",
  Circularity = "Circularity",
  Concentricity = "Concentricity",
  ConicalTaper = "ConicalTaper",
  Coordinate = "Coordinate",
  CounterBore = "CounterBore",
  CounterSink = "CounterSink",
  CsDist = "CsDist",
  CustomBilateral = "CustomBilateral",
  CustomPassFail = "CustomPassFail",
  Cylindricity = "Cylindricity",
  Depth = "Depth",
  Diameter = "Diameter",
  Distance2d = "Distance2d",
  Distance3d = "Distance3d",
  Edge = "Edge",
  Fastener = "Fastener",
  FlagNote = "FlagNote",
  Flatness = "Flatness",
  General = "General",
  LineProfile = "LineProfile",
  Linear = "Linear",
  Location = "Location",
  Note = "Note",
  Parallelism = "Parallelism",
  Perpendicularity = "Perpendicularity",
  Point = "Point",
  PointProfile = "PointProfile",
  Polar = "Polar",
  PolarAngular = "PolarAngular",
  PolarRadial = "PolarRadial",
  Radial = "Radial",
  Symmetry = "Symmetry",
  Slope = "Slope",
  SphericalDiameter = "SphericalDiameter",
  SphericalRadius = "SphericalRadius",
  SpotFace = "SpotFace",
  Square = "Square",
  Straightness = "Straightness",
  SurfaceFinish = "SurfaceFinish",
  SurfaceProfile = "SurfaceProfile",
  Thread = "Thread",
  TotalRunout = "TotalRunout",
  TruePosition = "TruePosition",
  Unknown = "Unknown",
  Welding = "Welding",
  Width = "Width",
}

/**
 *
 * @export
 * @interface Direction
 */
export interface Direction {
  /**
   *
   * @type {number}
   * @memberof Direction
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof Direction
   */
  y: number
  /**
   *
   * @type {number}
   * @memberof Direction
   */
  z: number
}
/**
 *
 * @export
 * @interface DiscreteParameter
 */
export interface DiscreteParameter {
  /**
   *
   * @type {Array<number>}
   * @memberof DiscreteParameter
   */
  values: Array<number>
  /**
   *
   * @type {number}
   * @memberof DiscreteParameter
   */
  tolerance: number
}
/**
 *
 * @export
 * @interface DovetailKeySlotParams
 */
export interface DovetailKeySlotParams {
  /**
   *
   * @type {number}
   * @memberof DovetailKeySlotParams
   */
  slotWidth: number
  /**
   *
   * @type {number}
   * @memberof DovetailKeySlotParams
   */
  minXOffset: number
  /**
   *
   * @type {number}
   * @memberof DovetailKeySlotParams
   */
  yOffset: number
}
/**
 *
 * @export
 * @interface Drawing
 */
export interface Drawing {
  /**
   *
   * @type {number}
   * @memberof Drawing
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingNumber?: string
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingRevisionLevel?: string
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingSheetNo?: string
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingSheetName?: string
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingPageNo?: string
  /**
   *
   * @type {string}
   * @memberof Drawing
   */
  drawingComments?: string
}
/**
 *
 * @export
 * @interface DuplicateJobResponse
 */
export interface DuplicateJobResponse {
  /**
   *
   * @type {string}
   * @memberof DuplicateJobResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DuplicateJobResponse
   */
  uri: string
}
/**
 *
 * @export
 * @interface EmptyToolchange
 */
export interface EmptyToolchange {
  /**
   *
   * @type {string}
   * @memberof EmptyToolchange
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof EmptyToolchange
   */
  kind: EmptyToolchangeKindEnum
}

/**
 * @export
 * @enum {string}
 */
export enum EmptyToolchangeKindEnum {
  EmptyToolchange = "empty_toolchange",
}

/**
 *
 * @export
 * @interface ExplicitMove
 */
export interface ExplicitMove {
  /**
   *
   * @type {string}
   * @memberof ExplicitMove
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ExplicitMove
   */
  kind: ExplicitMoveKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof ExplicitMove
   */
  position: RtcpPosition
  /**
   *
   * @type {boolean}
   * @memberof ExplicitMove
   */
  generated?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum ExplicitMoveKindEnum {
  ExplicitMove = "explicit_move",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ExportCoordinates {
  Part = "part",
  Table = "table",
  Wcs = "wcs",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum FeatOrigin {
  Constructed = "Constructed",
  Contact = "Contact",
  Unknown = "Unknown",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum FeatureType {
  Unknown = "Unknown",
  Plane = "Plane",
  Cylinder = "Cylinder",
  Circle = "Circle",
  Line = "Line",
  Cone = "Cone",
  Sphere = "Sphere",
  Point = "Point",
  Width = "Width",
  Slot = "Slot",
  Freeform = "Freeform",
  LineSegment = "LineSegment",
  OffsetLine = "OffsetLine",
  Dimension = "Dimension",
  CylinderBestFit = "CylinderBestFit",
  PlaneBestFit = "PlaneBestFit",
  CastPlane = "CastPlane",
  TangentPlane = "TangentPlane",
}

/**
 *
 * @export
 * @interface FieldToolResult
 */
export interface FieldToolResult {
  /**
   *
   * @type {boolean}
   * @memberof FieldToolResult
   */
  use_cad_transform: boolean
  /**
   *
   * @type {Array<PointlessFeature | MissingIntersectionsFeature | BaseStockOnlyFeature | MultitoolFeature | HasBaseStockFeature | SuccessFeature>}
   * @memberof FieldToolResult
   */
  feature_tool_results: Array<
    | PointlessFeature
    | MissingIntersectionsFeature
    | BaseStockOnlyFeature
    | MultitoolFeature
    | HasBaseStockFeature
    | SuccessFeature
  >
  /**
   *
   * @type {Array<DatumReferenceFrame>}
   * @memberof FieldToolResult
   */
  drfs: Array<DatumReferenceFrame>
}
/**
 *
 * @export
 * @interface FixtureChoice
 */
export interface FixtureChoice {
  /**
   *
   * @type {string}
   * @memberof FixtureChoice
   */
  rootElementId: string
  /**
   *
   * @type {Array<FixtureChoiceElement>}
   * @memberof FixtureChoice
   */
  elements: Array<FixtureChoiceElement>
}
/**
 *
 * @export
 * @interface FixtureChoiceElement
 */
export interface FixtureChoiceElement {
  /**
   *
   * @type {string}
   * @memberof FixtureChoiceElement
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FixtureChoiceElement
   */
  fixture: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof FixtureChoiceElement
   */
  transform?: AppModelsGeometryTransform
  /**
   *
   * @type {number}
   * @memberof FixtureChoiceElement
   */
  selectedParameter?: number
  /**
   *
   * @type {string}
   * @memberof FixtureChoiceElement
   */
  customModelId?: string
  /**
   *
   * @type {string}
   * @memberof FixtureChoiceElement
   */
  customOperationId?: string
  /**
   *
   * @type {boolean}
   * @memberof FixtureChoiceElement
   */
  allowCutting?: boolean
  /**
   *
   * @type {Array<Attachment>}
   * @memberof FixtureChoiceElement
   */
  children: Array<Attachment>
}
/**
 * See https://www.miteebite.com/products/fixture-clamps/ for explanation of field values, other fields that may be worth tracking, and actual values for specific products  All values should be millimeters, whether the source config lists millimeters or inches  The produced models are centered in the y axis, expect z=0 to be the surface plane of the milled fixture, and expect to contact the rest stock in the xz base plane.
 * @export
 * @interface FixtureClamp
 */
export interface FixtureClamp {
  /**
   *
   * @type {string}
   * @memberof FixtureClamp
   */
  kind: FixtureClampKindEnum
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  a: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  b: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  c: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  d: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  e: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  f: number
  /**
   *
   * @type {number}
   * @memberof FixtureClamp
   */
  g: number
}

/**
 * @export
 * @enum {string}
 */
export enum FixtureClampKindEnum {
  FixtureClamp = "fixture_clamp",
}

/**
 *
 * @export
 * @interface FixtureParameterData
 */
export interface FixtureParameterData {
  /**
   *
   * @type {string}
   * @memberof FixtureParameterData
   */
  label: string
  /**
   *
   * @type {number}
   * @memberof FixtureParameterData
   */
  initial: number
  /**
   *
   * @type {number}
   * @memberof FixtureParameterData
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof FixtureParameterData
   */
  max: number
}
/**
 *
 * @export
 * @interface FixtureRecord
 */
export interface FixtureRecord {
  /**
   *
   * @type {string}
   * @memberof FixtureRecord
   */
  id: string
  /**
   *
   * @type {Array<string>}
   * @memberof FixtureRecord
   */
  products?: Array<string>
  /**
   *
   * @type {string}
   * @memberof FixtureRecord
   */
  label?: string
  /**
   *
   * @type {{ [key: string]: PlacedBodyRecord; }}
   * @memberof FixtureRecord
   */
  bodies: { [key: string]: PlacedBodyRecord }
  /**
   *
   * @type {FixtureParameterData}
   * @memberof FixtureRecord
   */
  parameter?: FixtureParameterData
  /**
   *
   * @type {StockSupport}
   * @memberof FixtureRecord
   */
  stock?: StockSupport
  /**
   *
   * @type {FixtureClamp | Series9Clamp | UniforceClamp}
   * @memberof FixtureRecord
   */
  biteParams?: FixtureClamp | Series9Clamp | UniforceClamp
  /**
   *
   * @type {boolean}
   * @memberof FixtureRecord
   */
  isAuxiliary: boolean
  /**
   *
   * @type {boolean}
   * @memberof FixtureRecord
   */
  isTransformAllowed: boolean
  /**
   *
   * @type {boolean}
   * @memberof FixtureRecord
   */
  isUnavailable: boolean
  /**
   *
   * @type {{ [key: string]: AttachmentPointRecord; }}
   * @memberof FixtureRecord
   */
  points?: { [key: string]: AttachmentPointRecord }
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof FixtureRecord
   */
  attachments?: { [key: string]: Array<string> }
  /**
   *
   * @type {boolean}
   * @memberof FixtureRecord
   */
  excludeFromTemplates?: boolean
  /**
   *
   * @type {Vector}
   * @memberof FixtureRecord
   */
  dimensions?: Vector
}
/**
 *
 * @export
 * @interface FixtureStock
 */
export interface FixtureStock {
  /**
   *
   * @type {string}
   * @memberof FixtureStock
   */
  kind: FixtureStockKindEnum
  /**
   *
   * @type {string}
   * @memberof FixtureStock
   */
  fixtureId: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof FixtureStock
   */
  transform: AppModelsGeometryTransform
  /**
   *
   * @type {number}
   * @memberof FixtureStock
   */
  parameter?: number
}

/**
 * @export
 * @enum {string}
 */
export enum FixtureStockKindEnum {
  Fixture = "fixture",
}

/**
 *
 * @export
 * @interface FixtureStockData
 */
export interface FixtureStockData {
  /**
   *
   * @type {string}
   * @memberof FixtureStockData
   */
  kind: FixtureStockDataKindEnum
  /**
   *
   * @type {string}
   * @memberof FixtureStockData
   */
  fixtureId: string
}

/**
 * @export
 * @enum {string}
 */
export enum FixtureStockDataKindEnum {
  Fixture = "fixture",
}

/**
 *
 * @export
 * @interface FixtureStockRecord
 */
export interface FixtureStockRecord {
  /**
   *
   * @type {string}
   * @memberof FixtureStockRecord
   */
  stockId: string
  /**
   *
   * @type {string}
   * @memberof FixtureStockRecord
   */
  materialId: string
  /**
   *
   * @type {boolean}
   * @memberof FixtureStockRecord
   */
  isUnavailable?: boolean
  /**
   *
   * @type {FixtureStockData}
   * @memberof FixtureStockRecord
   */
  data: FixtureStockData
}
/**
 *
 * @export
 * @interface FixtureTemplate
 */
export interface FixtureTemplate {
  /**
   *
   * @type {MachineKind}
   * @memberof FixtureTemplate
   */
  machine: MachineKind
  /**
   *
   * @type {ClampingKind}
   * @memberof FixtureTemplate
   */
  kind: ClampingKind
  /**
   *
   * @type {string}
   * @memberof FixtureTemplate
   */
  label: string
  /**
   *
   * @type {FixtureChoice}
   * @memberof FixtureTemplate
   */
  choice: FixtureChoice
}
/**
 *
 * @export
 * @interface FixturesConfig
 */
export interface FixturesConfig {
  /**
   *
   * @type {Array<FixtureTemplate>}
   * @memberof FixturesConfig
   */
  templates: Array<FixtureTemplate>
  /**
   *
   * @type {Array<FixtureRecord>}
   * @memberof FixturesConfig
   */
  fixtures: Array<FixtureRecord>
  /**
   *
   * @type {Array<RenderMaterial>}
   * @memberof FixturesConfig
   */
  materials: Array<RenderMaterial>
}
/**
 *
 * @export
 * @interface FlatJawsParams
 */
export interface FlatJawsParams {
  /**
   *
   * @type {string}
   * @memberof FlatJawsParams
   */
  kind: FlatJawsParamsKindEnum
  /**
   *
   * @type {Vector}
   * @memberof FlatJawsParams
   */
  extents: Vector
}

/**
 * @export
 * @enum {string}
 */
export enum FlatJawsParamsKindEnum {
  FlatJaws = "flat_jaws",
}

/**
 *
 * @export
 * @interface FullCircle
 */
export interface FullCircle {
  /**
   *
   * @type {boolean}
   * @memberof FullCircle
   */
  closed?: FullCircleClosedEnum
}

/**
 * @export
 * @enum {string}
 */
export enum FullCircleClosedEnum {
  True = "true",
}

/**
 * The range is not inclusive [start, end)
 * @export
 * @interface GcodeLineRange
 */
export interface GcodeLineRange {
  /**
   *
   * @type {number}
   * @memberof GcodeLineRange
   */
  start: number
  /**
   *
   * @type {number}
   * @memberof GcodeLineRange
   */
  end: number
}
/**
 *
 * @export
 * @interface GcodeLinesIssueSelector
 */
export interface GcodeLinesIssueSelector {
  /**
   *
   * @type {string}
   * @memberof GcodeLinesIssueSelector
   */
  kind: GcodeLinesIssueSelectorKindEnum
  /**
   *
   * @type {string}
   * @memberof GcodeLinesIssueSelector
   */
  issueTag: string
  /**
   *
   * @type {number}
   * @memberof GcodeLinesIssueSelector
   */
  gcodeLineStart: number
  /**
   *
   * @type {number}
   * @memberof GcodeLinesIssueSelector
   */
  gcodeLineEnd?: number
}

/**
 * @export
 * @enum {string}
 */
export enum GcodeLinesIssueSelectorKindEnum {
  GcodeLines = "gcode_lines",
}

/**
 *
 * @export
 * @interface GcodeOutput
 */
export interface GcodeOutput {
  /**
   *
   * @type {Array<PackedToolPath>}
   * @memberof GcodeOutput
   */
  tool_paths: Array<PackedToolPath>
  /**
   *
   * @type {Array<CutterComp>}
   * @memberof GcodeOutput
   */
  comps: Array<CutterComp>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GdtFont {
  Unknown = "Unknown",
  Asme = "Asme",
  Iso = "Iso",
}

/**
 *
 * @export
 * @interface GougeEvent
 */
export interface GougeEvent {
  /**
   *
   * @type {string}
   * @memberof GougeEvent
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof GougeEvent
   */
  moveNumber: number
  /**
   *
   * @type {number}
   * @memberof GougeEvent
   */
  moveTime: number
  /**
   *
   * @type {string}
   * @memberof GougeEvent
   */
  kind: GougeEventKindEnum
  /**
   *
   * @type {Array<number>}
   * @memberof GougeEvent
   */
  gougePoint: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum GougeEventKindEnum {
  Gouge = "gouge",
}

/**
 *
 * @export
 * @interface GuardrailFeedback
 */
export interface GuardrailFeedback {
  /**
   *
   * @type {string}
   * @memberof GuardrailFeedback
   */
  name: string
  /**
   *
   * @type {StageEnum}
   * @memberof GuardrailFeedback
   */
  stage: StageEnum
  /**
   *
   * @type {GuardrailFeedbackLevel}
   * @memberof GuardrailFeedback
   */
  level: GuardrailFeedbackLevel
  /**
   *
   * @type {string}
   * @memberof GuardrailFeedback
   */
  output: string
  /**
   *
   * @type {string}
   * @memberof GuardrailFeedback
   */
  ncFileLocator?: string
  /**
   *
   * @type {number}
   * @memberof GuardrailFeedback
   */
  gcodeLine?: number
  /**
   *
   * @type {string}
   * @memberof GuardrailFeedback
   */
  planPath?: string
  /**
   *
   * @type {RtcpPosition}
   * @memberof GuardrailFeedback
   */
  position?: RtcpPosition
  /**
   *
   * @type {any}
   * @memberof GuardrailFeedback
   */
  visualization?: any
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GuardrailFeedbackLevel {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
  Failure = "Failure",
}

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>
}
/**
 *
 * @export
 * @interface HasBaseStockFeature
 */
export interface HasBaseStockFeature {
  /**
   *
   * @type {string}
   * @memberof HasBaseStockFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof HasBaseStockFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof HasBaseStockFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof HasBaseStockFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof HasBaseStockFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof HasBaseStockFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof HasBaseStockFeature
   */
  result_type: HasBaseStockFeatureResultTypeEnum
  /**
   *
   * @type {StockCutData}
   * @memberof HasBaseStockFeature
   */
  stock_cut_data: StockCutData
  /**
   *
   * @type {number}
   * @memberof HasBaseStockFeature
   */
  tool_id: number
  /**
   *
   * @type {Array<number>}
   * @memberof HasBaseStockFeature
   */
  hits_base_stock_indices: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum HasBaseStockFeatureResultTypeEnum {
  HasBaseStockHits = "HasBaseStockHits",
}

/**
 *
 * @export
 * @interface HeightRange
 */
export interface HeightRange {
  /**
   *
   * @type {number}
   * @memberof HeightRange
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof HeightRange
   */
  max: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum HitType {
  Theoretical = "Theoretical",
  Measured = "Measured",
}

/**
 *
 * @export
 * @interface HolderStockClashEvent
 */
export interface HolderStockClashEvent {
  /**
   *
   * @type {string}
   * @memberof HolderStockClashEvent
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof HolderStockClashEvent
   */
  moveNumber: number
  /**
   *
   * @type {number}
   * @memberof HolderStockClashEvent
   */
  moveTime: number
  /**
   *
   * @type {string}
   * @memberof HolderStockClashEvent
   */
  kind: HolderStockClashEventKindEnum
  /**
   *
   * @type {string}
   * @memberof HolderStockClashEvent
   */
  holderSolidName: string
}

/**
 * @export
 * @enum {string}
 */
export enum HolderStockClashEventKindEnum {
  HolderStockClash = "holder_stock_clash",
}

/**
 *
 * @export
 * @interface Inspection
 */
export interface Inspection {
  /**
   *
   * @type {string}
   * @memberof Inspection
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Inspection
   */
  kind: InspectionKindEnum
  /**
   *
   * @type {Array<BoreFeature | BossFeature | ExplicitMove | MultiLevelBoreFeature | MultiLevelBossFeature | PointsFeature | SphereFeature>}
   * @memberof Inspection
   */
  steps: Array<
    | BoreFeature
    | BossFeature
    | ExplicitMove
    | MultiLevelBoreFeature
    | MultiLevelBossFeature
    | PointsFeature
    | SphereFeature
  >
  /**
   *
   * @type {boolean}
   * @memberof Inspection
   */
  preMachining?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Inspection
   */
  disabled?: boolean
  /**
   *
   * @type {Array<ProbingIssue>}
   * @memberof Inspection
   */
  issues?: Array<ProbingIssue>
  /**
   *
   * @type {boolean}
   * @memberof Inspection
   */
  skipApproachCheck?: boolean
  /**
   *
   * @type {number}
   * @memberof Inspection
   */
  toolChange?: number
}

/**
 * @export
 * @enum {string}
 */
export enum InspectionKindEnum {
  Inspection = "Inspection",
}

/**
 *
 * @export
 * @interface IssueAcknowledgement
 */
export interface IssueAcknowledgement {
  /**
   *
   * @type {string}
   * @memberof IssueAcknowledgement
   */
  user: string
  /**
   *
   * @type {string}
   * @memberof IssueAcknowledgement
   */
  timestamp: string
  /**
   *
   * @type {GcodeLinesIssueSelector | ProbingStepsIssueSelector | TagSelector}
   * @memberof IssueAcknowledgement
   */
  selector: GcodeLinesIssueSelector | ProbingStepsIssueSelector | TagSelector
}
/**
 *
 * @export
 * @interface JiraClientData
 */
export interface JiraClientData {
  /**
   *
   * @type {string}
   * @memberof JiraClientData
   */
  clientId: string
  /**
   *
   * @type {string}
   * @memberof JiraClientData
   */
  redirectUri: string
}
/**
 *
 * @export
 * @interface JiraTokenResponse
 */
export interface JiraTokenResponse {
  /**
   *
   * @type {string}
   * @memberof JiraTokenResponse
   */
  accessToken: string
  /**
   *
   * @type {string}
   * @memberof JiraTokenResponse
   */
  refreshToken: string
  /**
   *
   * @type {string}
   * @memberof JiraTokenResponse
   */
  scope: string
  /**
   *
   * @type {number}
   * @memberof JiraTokenResponse
   */
  expiresIn: number
  /**
   *
   * @type {string}
   * @memberof JiraTokenResponse
   */
  tokenType: string
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {string}
   * @memberof Job
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  activePlanId?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  modelFileId: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  customerCodename: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  dateReceived: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  camProgrammer?: string
  /**
   *
   * @type {Priority}
   * @memberof Job
   */
  priority: Priority
}
/**
 *
 * @export
 * @interface JobUpload
 */
export interface JobUpload {
  /**
   *
   * @type {string}
   * @memberof JobUpload
   */
  kind: JobUploadKindEnum
  /**
   *
   * @type {string}
   * @memberof JobUpload
   */
  jobId: string
  /**
   *
   * @type {boolean}
   * @memberof JobUpload
   */
  isModel?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum JobUploadKindEnum {
  Job = "job",
}

/**
 *
 * @export
 * @interface LabelCount
 */
export interface LabelCount {
  /**
   *
   * @type {string}
   * @memberof LabelCount
   */
  label?: string
  /**
   *
   * @type {number}
   * @memberof LabelCount
   */
  count: number
}
/**
 *
 * @export
 * @interface LaserToolsetting
 */
export interface LaserToolsetting {
  /**
   *
   * @type {number}
   * @memberof LaserToolsetting
   */
  radiusDepthZ?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum LineSegmentMode {
  Lines = "Lines",
  LineStrip = "LineStrip",
}

/**
 *
 * @export
 * @interface LineSegmentNetwork
 */
export interface LineSegmentNetwork {
  /**
   *
   * @type {LineSegmentMode}
   * @memberof LineSegmentNetwork
   */
  mode: LineSegmentMode
  /**
   *
   * @type {Array<number>}
   * @memberof LineSegmentNetwork
   */
  indices?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof LineSegmentNetwork
   */
  vertices: Array<number>
}
/**
 *
 * @export
 * @interface LocationResult
 */
export interface LocationResult {
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  nominal?: number
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  upper_tol?: number
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  lower_tol?: number
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  deviation?: number
  /**
   *
   * @type {number}
   * @memberof LocationResult
   */
  out_tol?: number
  /**
   *
   * @type {boolean}
   * @memberof LocationResult
   */
  is_out_tol?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationResult
   */
  axis?: string
}
/**
 *
 * @export
 * @interface MachineBodyNode
 */
export interface MachineBodyNode {
  /**
   *
   * @type {Array<string>}
   * @memberof MachineBodyNode
   */
  keys?: Array<string>
  /**
   *
   * @type {string}
   * @memberof MachineBodyNode
   */
  name: string
  /**
   *
   * @type {Array<string>}
   * @memberof MachineBodyNode
   */
  visibility?: Array<string>
  /**
   *
   * @type {string}
   * @memberof MachineBodyNode
   */
  transformFormula: string
  /**
   *
   * @type {Array<MachineBodyNode>}
   * @memberof MachineBodyNode
   */
  children?: Array<MachineBodyNode>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MachineKind {
  DoosanDvf5000 = "doosan-dvf5000",
  DoosanNhm6300 = "doosan-nhm6300",
  GrobG350T = "grob-g350T",
  GrobG350A = "grob-g350A",
  GrobG550 = "grob-g550",
}

/**
 *
 * @export
 * @interface MachineKinematics
 */
export interface MachineKinematics {
  /**
   *
   * @type {Array<string>}
   * @memberof MachineKinematics
   */
  axes: Array<string>
  /**
   *
   * @type {{ [key: string]: AxisTravelLimit; }}
   * @memberof MachineKinematics
   */
  travelLimits: { [key: string]: AxisTravelLimit }
  /**
   *
   * @type {Array<ReferencePosition>}
   * @memberof MachineKinematics
   */
  referencePositions: Array<ReferencePosition>
  /**
   *
   * @type {string}
   * @memberof MachineKinematics
   */
  toolchangeReferencePositionLabel: string
  /**
   *
   * @type {string}
   * @memberof MachineKinematics
   */
  tableToSpindleFormula: string
  /**
   *
   * @type {string}
   * @memberof MachineKinematics
   */
  spindleTransformFormula: string
  /**
   *
   * @type {string}
   * @memberof MachineKinematics
   */
  toolTransformFormula: string
  /**
   *
   * @type {Array<string>}
   * @memberof MachineKinematics
   */
  tableTransformFormulas: Array<string>
}
/**
 *
 * @export
 * @interface MachineOffset
 */
export interface MachineOffset {
  /**
   *
   * @type {number}
   * @memberof MachineOffset
   */
  previousTool?: number
  /**
   *
   * @type {number}
   * @memberof MachineOffset
   */
  currentTool: number
  /**
   *
   * @type {number}
   * @memberof MachineOffset
   */
  nextTool?: number
  /**
   *
   * @type {number}
   * @memberof MachineOffset
   */
  instanceIndex: number
  /**
   *
   * @type {number}
   * @memberof MachineOffset
   */
  instanceCount: number
  /**
   *
   * @type {OffsetKind}
   * @memberof MachineOffset
   */
  kind: OffsetKind
  /**
   *
   * @type {MachineKind}
   * @memberof MachineOffset
   */
  machineKind: MachineKind
  /**
   *
   * @type {string}
   * @memberof MachineOffset
   */
  machineInstance: string
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof MachineOffset
   */
  offsets: { [key: string]: number }
  /**
   *
   * @type {OffsetStateKind}
   * @memberof MachineOffset
   */
  currentState: OffsetStateKind
  /**
   *
   * @type {boolean}
   * @memberof MachineOffset
   */
  disabled?: boolean
}
/**
 *
 * @export
 * @interface MachineRecord
 */
export interface MachineRecord {
  /**
   *
   * @type {MachineKind}
   * @memberof MachineRecord
   */
  kind: MachineKind
  /**
   *
   * @type {string}
   * @memberof MachineRecord
   */
  label: string
  /**
   *
   * @type {CoordinateSystemOrigins}
   * @memberof MachineRecord
   */
  coordinateSystems: CoordinateSystemOrigins
  /**
   *
   * @type {MachineKinematics}
   * @memberof MachineRecord
   */
  kinematics: MachineKinematics
  /**
   *
   * @type {Array<string>}
   * @memberof MachineRecord
   */
  visibilities: Array<string>
  /**
   *
   * @type {Array<MachineBodyNode>}
   * @memberof MachineRecord
   */
  nodes: Array<MachineBodyNode>
  /**
   *
   * @type {Array<string>}
   * @memberof MachineRecord
   */
  rootFixtures: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof MachineRecord
   */
  probes: Array<string>
}
/**
 *
 * @export
 * @interface MachinedParametricStockSimInfo
 */
export interface MachinedParametricStockSimInfo {
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfaceCylinder | MachinedStockSimSurfacePlane | MachinedStockSimSurfaceSphere | MachinedStockSimSurfaceTorus>}
   * @memberof MachinedParametricStockSimInfo
   */
  surfaces: Array<
    | MachinedStockSimSurfaceCone
    | MachinedStockSimSurfaceCylinder
    | MachinedStockSimSurfacePlane
    | MachinedStockSimSurfaceSphere
    | MachinedStockSimSurfaceTorus
  >
  /**
   *
   * @type {Array<MachinedStockEndmillFeatureSlot | MachinedStockEndmillFeatureThreeAxis | MachinedStockHoleFeature>}
   * @memberof MachinedParametricStockSimInfo
   */
  features: Array<
    | MachinedStockEndmillFeatureSlot
    | MachinedStockEndmillFeatureThreeAxis
    | MachinedStockHoleFeature
  >
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof MachinedParametricStockSimInfo
   */
  transform: AppModelsGeometryTransform
  /**
   *
   * @type {LineSegmentNetwork}
   * @memberof MachinedParametricStockSimInfo
   */
  surfaces_boundary: LineSegmentNetwork
}
/**
 *
 * @export
 * @interface MachinedStockEndmillFeatureSlot
 */
export interface MachinedStockEndmillFeatureSlot {
  /**
   *
   * @type {string}
   * @memberof MachinedStockEndmillFeatureSlot
   */
  kind: MachinedStockEndmillFeatureSlotKindEnum
  /**
   *
   * @type {number}
   * @memberof MachinedStockEndmillFeatureSlot
   */
  width: number
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfaceCylinder | MachinedStockSimSurfacePlane | MachinedStockSimSurfaceSphere | MachinedStockSimSurfaceTorus>}
   * @memberof MachinedStockEndmillFeatureSlot
   */
  wallSurfaces: Array<
    | MachinedStockSimSurfaceCone
    | MachinedStockSimSurfaceCylinder
    | MachinedStockSimSurfacePlane
    | MachinedStockSimSurfaceSphere
    | MachinedStockSimSurfaceTorus
  >
  /**
   *
   * @type {Array<number>}
   * @memberof MachinedStockEndmillFeatureSlot
   */
  wallFaces: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockEndmillFeatureSlotKindEnum {
  Slot = "slot",
}

/**
 *
 * @export
 * @interface MachinedStockEndmillFeatureThreeAxis
 */
export interface MachinedStockEndmillFeatureThreeAxis {
  /**
   *
   * @type {string}
   * @memberof MachinedStockEndmillFeatureThreeAxis
   */
  kind: MachinedStockEndmillFeatureThreeAxisKindEnum
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfaceCylinder | MachinedStockSimSurfacePlane | MachinedStockSimSurfaceSphere | MachinedStockSimSurfaceTorus>}
   * @memberof MachinedStockEndmillFeatureThreeAxis
   */
  surfaces: Array<
    | MachinedStockSimSurfaceCone
    | MachinedStockSimSurfaceCylinder
    | MachinedStockSimSurfacePlane
    | MachinedStockSimSurfaceSphere
    | MachinedStockSimSurfaceTorus
  >
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockEndmillFeatureThreeAxisKindEnum {
  ThreeAxis = "three_axis",
}

/**
 *
 * @export
 * @interface MachinedStockHoleFeature
 */
export interface MachinedStockHoleFeature {
  /**
   *
   * @type {string}
   * @memberof MachinedStockHoleFeature
   */
  kind: MachinedStockHoleFeatureKindEnum
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCylinder>}
   * @memberof MachinedStockHoleFeature
   */
  body: Array<MachinedStockSimSurfaceCylinder>
  /**
   *
   * @type {Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfacePlane>}
   * @memberof MachinedStockHoleFeature
   */
  bottom?: Array<MachinedStockSimSurfaceCone | MachinedStockSimSurfacePlane>
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockHoleFeatureKindEnum {
  Hole = "hole",
}

/**
 *
 * @export
 * @interface MachinedStockSimSurfaceCone
 */
export interface MachinedStockSimSurfaceCone {
  /**
   *
   * @type {string}
   * @memberof MachinedStockSimSurfaceCone
   */
  kind: MachinedStockSimSurfaceConeKindEnum
  /**
   *
   * @type {MachiningInfo}
   * @memberof MachinedStockSimSurfaceCone
   */
  machiningInfo: MachiningInfo
  /**
   *
   * @type {Point}
   * @memberof MachinedStockSimSurfaceCone
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfaceCone
   */
  axis: Direction
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfaceCone
   */
  refAxis: Direction
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceCone
   */
  halfAngle: number
  /**
   *
   * @type {HeightRange}
   * @memberof MachinedStockSimSurfaceCone
   */
  heightRange: HeightRange
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceCone
   */
  totalAngle: number
  /**
   *
   * @type {ArcAngles | FullCircle}
   * @memberof MachinedStockSimSurfaceCone
   */
  angles: ArcAngles | FullCircle
  /**
   *
   * @type {MachinedStockSimSurfaceOrientation}
   * @memberof MachinedStockSimSurfaceCone
   */
  orientation: MachinedStockSimSurfaceOrientation
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfaceConeKindEnum {
  Cone = "cone",
}

/**
 *
 * @export
 * @interface MachinedStockSimSurfaceCylinder
 */
export interface MachinedStockSimSurfaceCylinder {
  /**
   *
   * @type {string}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  kind: MachinedStockSimSurfaceCylinderKindEnum
  /**
   *
   * @type {MachiningInfo}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  machiningInfo: MachiningInfo
  /**
   *
   * @type {Point}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  axis: Direction
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  refAxis: Direction
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  radius: number
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  height: number
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  totalAngle: number
  /**
   *
   * @type {ArcAngles | FullCircle}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  angles: ArcAngles | FullCircle
  /**
   *
   * @type {MachinedStockSimSurfaceOrientation}
   * @memberof MachinedStockSimSurfaceCylinder
   */
  orientation: MachinedStockSimSurfaceOrientation
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfaceCylinderKindEnum {
  Cylinder = "cylinder",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfaceOrientation {
  Forward = "forward",
  Reversed = "reversed",
}

/**
 *
 * @export
 * @interface MachinedStockSimSurfacePlane
 */
export interface MachinedStockSimSurfacePlane {
  /**
   *
   * @type {string}
   * @memberof MachinedStockSimSurfacePlane
   */
  kind: MachinedStockSimSurfacePlaneKindEnum
  /**
   *
   * @type {MachiningInfo}
   * @memberof MachinedStockSimSurfacePlane
   */
  machiningInfo: MachiningInfo
  /**
   *
   * @type {Point}
   * @memberof MachinedStockSimSurfacePlane
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfacePlane
   */
  axis: Direction
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfacePlane
   */
  refAxis: Direction
  /**
   *
   * @type {PlanarSizes}
   * @memberof MachinedStockSimSurfacePlane
   */
  lengths: PlanarSizes
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfacePlaneKindEnum {
  Plane = "plane",
}

/**
 *
 * @export
 * @interface MachinedStockSimSurfaceSphere
 */
export interface MachinedStockSimSurfaceSphere {
  /**
   *
   * @type {string}
   * @memberof MachinedStockSimSurfaceSphere
   */
  kind: MachinedStockSimSurfaceSphereKindEnum
  /**
   *
   * @type {MachiningInfo}
   * @memberof MachinedStockSimSurfaceSphere
   */
  machiningInfo: MachiningInfo
  /**
   *
   * @type {Point}
   * @memberof MachinedStockSimSurfaceSphere
   */
  position: Point
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceSphere
   */
  radius: number
  /**
   *
   * @type {MachinedStockSimSurfaceOrientation}
   * @memberof MachinedStockSimSurfaceSphere
   */
  orientation: MachinedStockSimSurfaceOrientation
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfaceSphereKindEnum {
  Sphere = "sphere",
}

/**
 *
 * @export
 * @interface MachinedStockSimSurfaceTorus
 */
export interface MachinedStockSimSurfaceTorus {
  /**
   *
   * @type {string}
   * @memberof MachinedStockSimSurfaceTorus
   */
  kind: MachinedStockSimSurfaceTorusKindEnum
  /**
   *
   * @type {MachiningInfo}
   * @memberof MachinedStockSimSurfaceTorus
   */
  machiningInfo: MachiningInfo
  /**
   *
   * @type {Point}
   * @memberof MachinedStockSimSurfaceTorus
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof MachinedStockSimSurfaceTorus
   */
  axis: Direction
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceTorus
   */
  rMinor: number
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceTorus
   */
  rMajor: number
  /**
   *
   * @type {HeightRange}
   * @memberof MachinedStockSimSurfaceTorus
   */
  heightRange: HeightRange
  /**
   *
   * @type {number}
   * @memberof MachinedStockSimSurfaceTorus
   */
  totalAngle: number
  /**
   *
   * @type {ArcAngles | FullCircle}
   * @memberof MachinedStockSimSurfaceTorus
   */
  angles: ArcAngles | FullCircle
  /**
   *
   * @type {MachinedStockSimSurfaceOrientation}
   * @memberof MachinedStockSimSurfaceTorus
   */
  orientation: MachinedStockSimSurfaceOrientation
}

/**
 * @export
 * @enum {string}
 */
export enum MachinedStockSimSurfaceTorusKindEnum {
  Torus = "torus",
}

/**
 *
 * @export
 * @interface MachinesConfig
 */
export interface MachinesConfig {
  /**
   *
   * @type {Array<MachineRecord>}
   * @memberof MachinesConfig
   */
  machines: Array<MachineRecord>
}
/**
 *
 * @export
 * @interface MachiningInfo
 */
export interface MachiningInfo {
  /**
   *
   * @type {number}
   * @memberof MachiningInfo
   */
  tool_change: number
  /**
   *
   * @type {number}
   * @memberof MachiningInfo
   */
  tool_id: number
  /**
   *
   * @type {Direction}
   * @memberof MachiningInfo
   */
  tool_axis: Direction
  /**
   *
   * @type {number}
   * @memberof MachiningInfo
   */
  surface_index: number
}
/**
 *
 * @export
 * @interface ManifestURIs
 */
export interface ManifestURIs {
  /**
   *
   * @type {string}
   * @memberof ManifestURIs
   */
  setup?: string
  /**
   *
   * @type {string}
   * @memberof ManifestURIs
   */
  simulation?: string
  /**
   *
   * @type {string}
   * @memberof ManifestURIs
   */
  part?: string
  /**
   *
   * @type {string}
   * @memberof ManifestURIs
   */
  drawing?: string
}
/**
 *
 * @export
 * @interface MaterialRecord
 */
export interface MaterialRecord {
  /**
   *
   * @type {string}
   * @memberof MaterialRecord
   */
  materialId: string
  /**
   *
   * @type {string}
   * @memberof MaterialRecord
   */
  vericutId: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum McsStrategy {
  CURRENT = "CURRENT",
  FLIPPREVIOUSOPERATIONZ = "FLIP_PREVIOUS_OPERATION_Z",
  FLIPNEXTOPERATIONZ = "FLIP_NEXT_OPERATION_Z",
  AUTO = "AUTO",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MeasSource {
  Unknown = "Unknown",
  PcDmis = "PcDmis",
  Msp = "Msp",
  HighQa = "HighQa",
}

/**
 *
 * @export
 * @interface MesPublish
 */
export interface MesPublish {
  /**
   *
   * @type {string}
   * @memberof MesPublish
   */
  planId: string
  /**
   *
   * @type {string}
   * @memberof MesPublish
   */
  version: string
  /**
   *
   * @type {number}
   * @memberof MesPublish
   */
  operationIdx: number
  /**
   *
   * @type {number}
   * @memberof MesPublish
   */
  revision: number
  /**
   *
   * @type {string}
   * @memberof MesPublish
   */
  publishPath: string
  /**
   *
   * @type {boolean}
   * @memberof MesPublish
   */
  isProveout: boolean
}
/**
 *
 * @export
 * @interface MissingIntersectionsFeature
 */
export interface MissingIntersectionsFeature {
  /**
   *
   * @type {string}
   * @memberof MissingIntersectionsFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof MissingIntersectionsFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof MissingIntersectionsFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof MissingIntersectionsFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof MissingIntersectionsFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof MissingIntersectionsFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof MissingIntersectionsFeature
   */
  result_type: MissingIntersectionsFeatureResultTypeEnum
  /**
   *
   * @type {StockCutData}
   * @memberof MissingIntersectionsFeature
   */
  stock_cut_data: StockCutData
  /**
   *
   * @type {Array<number>}
   * @memberof MissingIntersectionsFeature
   */
  hits_without_intersection: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof MissingIntersectionsFeature
   */
  hits_base_stock_indices?: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum MissingIntersectionsFeatureResultTypeEnum {
  NotAllPointsHaveIntersections = "NotAllPointsHaveIntersections",
}

/**
 *
 * @export
 * @interface ModelStock
 */
export interface ModelStock {
  /**
   *
   * @type {string}
   * @memberof ModelStock
   */
  kind: ModelStockKindEnum
  /**
   *
   * @type {string}
   * @memberof ModelStock
   */
  modelId: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof ModelStock
   */
  transform: AppModelsGeometryTransform
}

/**
 * @export
 * @enum {string}
 */
export enum ModelStockKindEnum {
  Model = "model",
}

/**
 *
 * @export
 * @interface MovePatchOperation
 */
export interface MovePatchOperation {
  /**
   *
   * @type {string}
   * @memberof MovePatchOperation
   */
  op: MovePatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof MovePatchOperation
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof MovePatchOperation
   */
  path: string
}

/**
 * @export
 * @enum {string}
 */
export enum MovePatchOperationOpEnum {
  Move = "move",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MspMaterialCondition {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
}

/**
 *
 * @export
 * @interface MultiLevelBoreFeature
 */
export interface MultiLevelBoreFeature {
  /**
   *
   * @type {string}
   * @memberof MultiLevelBoreFeature
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  nLevels: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  levelSpacing: number
  /**
   *
   * @type {RtcpPosition}
   * @memberof MultiLevelBoreFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof MultiLevelBoreFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  nPoints?: number
  /**
   *
   * @type {ProbingTouchType}
   * @memberof MultiLevelBoreFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof MultiLevelBoreFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof MultiLevelBoreFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof MultiLevelBoreFeature
   */
  featureWeight?: number
  /**
   *
   * @type {string}
   * @memberof MultiLevelBoreFeature
   */
  kind: MultiLevelBoreFeatureKindEnum
}

/**
 * @export
 * @enum {string}
 */
export enum MultiLevelBoreFeatureKindEnum {
  MultiLevelBoreFeature = "multi_level_bore_feature",
}

/**
 *
 * @export
 * @interface MultiLevelBossFeature
 */
export interface MultiLevelBossFeature {
  /**
   *
   * @type {string}
   * @memberof MultiLevelBossFeature
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  nLevels: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  levelSpacing: number
  /**
   *
   * @type {RtcpPosition}
   * @memberof MultiLevelBossFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof MultiLevelBossFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  nPoints?: number
  /**
   *
   * @type {ProbingTouchType}
   * @memberof MultiLevelBossFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof MultiLevelBossFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof MultiLevelBossFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof MultiLevelBossFeature
   */
  featureWeight?: number
  /**
   *
   * @type {string}
   * @memberof MultiLevelBossFeature
   */
  kind: MultiLevelBossFeatureKindEnum
}

/**
 * @export
 * @enum {string}
 */
export enum MultiLevelBossFeatureKindEnum {
  MultiLevelBossFeature = "multi_level_boss_feature",
}

/**
 *
 * @export
 * @interface MultitoolFeature
 */
export interface MultitoolFeature {
  /**
   *
   * @type {string}
   * @memberof MultitoolFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof MultitoolFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof MultitoolFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof MultitoolFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof MultitoolFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof MultitoolFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof MultitoolFeature
   */
  result_type: MultitoolFeatureResultTypeEnum
  /**
   *
   * @type {StockCutData}
   * @memberof MultitoolFeature
   */
  stock_cut_data: StockCutData
  /**
   *
   * @type {Array<number>}
   * @memberof MultitoolFeature
   */
  hits_base_stock_indices?: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum MultitoolFeatureResultTypeEnum {
  NotAllPointsHaveSameTool = "NotAllPointsHaveSameTool",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OffsetKind {
  POSITION = "POSITION",
  TOOL = "TOOL",
  TOOLRENUMBER = "TOOL_RENUMBER",
  FEEDANDSPEED = "FEEDANDSPEED",
  WCS = "WCS",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OffsetStateKind {
  VALID = "VALID",
  INVALID = "INVALID",
  ACKNOWLEDGED = "ACKNOWLEDGED",
}

/**
 *
 * @export
 * @interface OpCamFeatureInfo
 */
export interface OpCamFeatureInfo {
  /**
   *
   * @type {Array<CamFeatureInfo>}
   * @memberof OpCamFeatureInfo
   */
  infos: Array<CamFeatureInfo>
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof OpCamFeatureInfo
   */
  groups: Array<Array<string>>
  /**
   *
   * @type {Array<WorkPlaneTransform>}
   * @memberof OpCamFeatureInfo
   */
  work_plane_transforms: Array<WorkPlaneTransform>
}
/**
 *
 * @export
 * @interface OpSummary
 */
export interface OpSummary {
  /**
   *
   * @type {string}
   * @memberof OpSummary
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof OpSummary
   */
  ncCode?: string
  /**
   *
   * @type {VericutFeedback}
   * @memberof OpSummary
   */
  vericut?: VericutFeedback
  /**
   *
   * @type {boolean}
   * @memberof OpSummary
   */
  passedVericut: boolean
  /**
   *
   * @type {MesPublish}
   * @memberof OpSummary
   */
  publishing?: MesPublish
}
/**
 *
 * @export
 * @interface Operation
 */
export interface Operation {
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  notes: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  designId?: string
  /**
   *
   * @type {MachineKind}
   * @memberof Operation
   */
  machineId?: MachineKind
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  toolCribId?: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  holderVersion?: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  toolLibraryVersion?: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  controllerVersion?: string
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  machineVersion?: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof Operation
   */
  wcs: AppModelsGeometryTransform
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof Operation
   */
  mcs: AppModelsGeometryTransform
  /**
   *
   * @type {FixtureChoice}
   * @memberof Operation
   */
  fixtures?: FixtureChoice
  /**
   *
   * @type {FixtureStock | ModelStock | OperationOutputStock | ParametricStock}
   * @memberof Operation
   */
  inputStock?: FixtureStock | ModelStock | OperationOutputStock | ParametricStock
  /**
   *
   * @type {ToolpathProject}
   * @memberof Operation
   */
  toolpathProject?: ToolpathProject
  /**
   *
   * @type {Probing}
   * @memberof Operation
   */
  probing?: Probing
  /**
   *
   * @type {OutputStock}
   * @memberof Operation
   */
  outputStock?: OutputStock
  /**
   *
   * @type {string}
   * @memberof Operation
   */
  manifestUri?: string
  /**
   *
   * @type {Array<IssueAcknowledgement>}
   * @memberof Operation
   */
  issueAcknowledgements?: Array<IssueAcknowledgement>
  /**
   *
   * @type {Array<MachineOffset>}
   * @memberof Operation
   */
  offsets?: Array<MachineOffset>
  /**
   *
   * @type {boolean}
   * @memberof Operation
   */
  machineKindUnacknowledged?: boolean
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OperationCategory {
  Material = "material",
  Operation = "operation",
}

/**
 *
 * @export
 * @interface OperationFixtureBody
 */
export interface OperationFixtureBody {
  /**
   *
   * @type {string}
   * @memberof OperationFixtureBody
   */
  fixture_locator: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof OperationFixtureBody
   */
  transform: AppModelsGeometryTransform
  /**
   *
   * @type {boolean}
   * @memberof OperationFixtureBody
   */
  allow_cutting: boolean
  /**
   *
   * @type {string}
   * @memberof OperationFixtureBody
   */
  fixture_choice_element_id: string
  /**
   *
   * @type {string}
   * @memberof OperationFixtureBody
   */
  fixture_identifier: string
  /**
   *
   * @type {string}
   * @memberof OperationFixtureBody
   */
  body_label: string
  /**
   *
   * @type {boolean}
   * @memberof OperationFixtureBody
   */
  is_custom: boolean
  /**
   *
   * @type {boolean}
   * @memberof OperationFixtureBody
   */
  is_millable: boolean
  /**
   *
   * @type {Vector}
   * @memberof OperationFixtureBody
   */
  movement: Vector
}
/**
 *
 * @export
 * @interface OperationGenerationConfig
 */
export interface OperationGenerationConfig {
  /**
   *
   * @type {StockGenerationConfig}
   * @memberof OperationGenerationConfig
   */
  stock?: StockGenerationConfig
  /**
   *
   * @type {OperationStrategy}
   * @memberof OperationGenerationConfig
   */
  strategy?: OperationStrategy
  /**
   *
   * @type {FixtureChoice}
   * @memberof OperationGenerationConfig
   */
  lockedFixtureChoice?: FixtureChoice
  /**
   *
   * @type {McsStrategy}
   * @memberof OperationGenerationConfig
   */
  mcsStrategy: McsStrategy
  /**
   *
   * @type {WcsStrategy}
   * @memberof OperationGenerationConfig
   */
  wcsStrategy: WcsStrategy
  /**
   *
   * @type {ClampingMode}
   * @memberof OperationGenerationConfig
   */
  clampingMode: ClampingMode
}
/**
 *
 * @export
 * @interface OperationOutputStock
 */
export interface OperationOutputStock {
  /**
   *
   * @type {string}
   * @memberof OperationOutputStock
   */
  kind: OperationOutputStockKindEnum
  /**
   *
   * @type {string}
   * @memberof OperationOutputStock
   */
  operationId: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof OperationOutputStock
   */
  transform: AppModelsGeometryTransform
}

/**
 * @export
 * @enum {string}
 */
export enum OperationOutputStockKindEnum {
  OperationOutput = "operation_output",
}

/**
 *
 * @export
 * @interface OperationQuantity
 */
export interface OperationQuantity {
  /**
   *
   * @type {number}
   * @memberof OperationQuantity
   */
  price?: number
  /**
   *
   * @type {number}
   * @memberof OperationQuantity
   */
  manual_price?: number
  /**
   *
   * @type {number}
   * @memberof OperationQuantity
   */
  lead_time?: number
  /**
   *
   * @type {number}
   * @memberof OperationQuantity
   */
  manual_lead_time?: number
  /**
   *
   * @type {number}
   * @memberof OperationQuantity
   */
  quantity?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OperationStrategy {
  SERRATEDJAWS = "SERRATED_JAWS",
  FLATJAWS = "FLAT_JAWS",
  DOVETAIL = "DOVETAIL",
  SOFTJAWS = "SOFT_JAWS",
  FIXTUREPLATE = "FIXTURE_PLATE",
  PLATE = "PLATE",
  CHUCK = "CHUCK",
}

/**
 *
 * @export
 * @interface OperationUpload
 */
export interface OperationUpload {
  /**
   *
   * @type {string}
   * @memberof OperationUpload
   */
  kind: OperationUploadKindEnum
  /**
   *
   * @type {string}
   * @memberof OperationUpload
   */
  planId: string
  /**
   *
   * @type {number}
   * @memberof OperationUpload
   */
  operationIdx: number
  /**
   *
   * @type {boolean}
   * @memberof OperationUpload
   */
  isModel?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum OperationUploadKindEnum {
  Operation = "operation",
}

/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {AddressInfo}
   * @memberof Order
   */
  billing_info?: AddressInfo
  /**
   *
   * @type {string}
   * @memberof Order
   */
  created?: string
  /**
   *
   * @type {OrderContact}
   * @memberof Order
   */
  contact?: OrderContact
  /**
   *
   * @type {OrderCustomer}
   * @memberof Order
   */
  customer?: OrderCustomer
  /**
   *
   * @type {string}
   * @memberof Order
   */
  deliver_by?: string
  /**
   *
   * @type {SalesPerson}
   * @memberof Order
   */
  estimator?: SalesPerson
  /**
   *
   * @type {number}
   * @memberof Order
   */
  number?: number
  /**
   *
   * @type {Array<OrderItem>}
   * @memberof Order
   */
  order_items?: Array<OrderItem>
  /**
   *
   * @type {OrderPaymentDetails}
   * @memberof Order
   */
  payment_details?: OrderPaymentDetails
  /**
   *
   * @type {string}
   * @memberof Order
   */
  private_notes?: string
  /**
   *
   * @type {number}
   * @memberof Order
   */
  quote_number?: number
  /**
   *
   * @type {number}
   * @memberof Order
   */
  quote_revision_number?: number
  /**
   *
   * @type {SalesPerson}
   * @memberof Order
   */
  sales_person?: SalesPerson
  /**
   *
   * @type {Array<OrderShipment>}
   * @memberof Order
   */
  shipments?: Array<OrderShipment>
  /**
   *
   * @type {AddressInfo}
   * @memberof Order
   */
  shipping_info?: AddressInfo
  /**
   *
   * @type {OrderShippingOption}
   * @memberof Order
   */
  shipping_option?: OrderShippingOption
  /**
   *
   * @type {string}
   * @memberof Order
   */
  ships_on?: string
  /**
   *
   * @type {OrderStatus}
   * @memberof Order
   */
  status?: OrderStatus
}
/**
 *
 * @export
 * @interface OrderAddOn
 */
export interface OrderAddOn {
  /**
   *
   * @type {boolean}
   * @memberof OrderAddOn
   */
  is_required?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderAddOn
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OrderAddOn
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof OrderAddOn
   */
  price?: number
  /**
   *
   * @type {number}
   * @memberof OrderAddOn
   */
  quantity?: number
  /**
   *
   * @type {Array<OrderCostingVariable>}
   * @memberof OrderAddOn
   */
  costing_variables?: Array<OrderCostingVariable>
}
/**
 *
 * @export
 * @interface OrderCompany
 */
export interface OrderCompany {
  /**
   *
   * @type {string}
   * @memberof OrderCompany
   */
  business_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderCompany
   */
  erp_code?: string
}
/**
 *
 * @export
 * @interface OrderComponent
 */
export interface OrderComponent {
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  id?: string
  /**
   *
   * @type {Array<number>}
   * @memberof OrderComponent
   */
  child_ids?: Array<number>
  /**
   *
   * @type {Array<ComponentChild>}
   * @memberof OrderComponent
   */
  children?: Array<ComponentChild>
  /**
   *
   * @type {number}
   * @memberof OrderComponent
   */
  deliver_quantity?: number
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  description?: string
  /**
   *
   * @type {boolean}
   * @memberof OrderComponent
   */
  export_controlled?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof OrderComponent
   */
  finishes?: Array<string>
  /**
   *
   * @type {number}
   * @memberof OrderComponent
   */
  innate_quantity?: number
  /**
   *
   * @type {boolean}
   * @memberof OrderComponent
   */
  is_root_component?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderComponent
   */
  make_quantity?: number
  /**
   *
   * @type {OrderMaterial}
   * @memberof OrderComponent
   */
  material?: OrderMaterial
  /**
   *
   * @type {Array<OrderOperation>}
   * @memberof OrderComponent
   */
  material_operations?: Array<OrderOperation>
  /**
   *
   * @type {Array<number>}
   * @memberof OrderComponent
   */
  parent_ids?: Array<number>
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  part_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  part_number?: string
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  part_url?: string
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  part_uuid?: string
  /**
   *
   * @type {OrderProcess}
   * @memberof OrderComponent
   */
  process?: OrderProcess
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  revision?: string
  /**
   *
   * @type {Array<OrderOperation>}
   * @memberof OrderComponent
   */
  shop_operations?: Array<OrderOperation>
  /**
   *
   * @type {Array<OrderSupportingFile>}
   * @memberof OrderComponent
   */
  supporting_files?: Array<OrderSupportingFile>
  /**
   *
   * @type {string}
   * @memberof OrderComponent
   */
  thumbnail_url?: string
  /**
   *
   * @type {ComponentType}
   * @memberof OrderComponent
   */
  type?: ComponentType
}
/**
 *
 * @export
 * @interface OrderContact
 */
export interface OrderContact {
  /**
   *
   * @type {OrderContactAccount}
   * @memberof OrderContact
   */
  account?: OrderContactAccount
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof OrderContact
   */
  phone_ext?: string
}
/**
 *
 * @export
 * @interface OrderContactAccount
 */
export interface OrderContactAccount {
  /**
   *
   * @type {string}
   * @memberof OrderContactAccount
   */
  erp_code?: string
  /**
   *
   * @type {number}
   * @memberof OrderContactAccount
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderContactAccount
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof OrderContactAccount
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OrderContactAccount
   */
  payment_terms?: string
  /**
   *
   * @type {string}
   * @memberof OrderContactAccount
   */
  payment_terms_period?: string
}
/**
 *
 * @export
 * @interface OrderCostingVariable
 */
export interface OrderCostingVariable {
  /**
   *
   * @type {string}
   * @memberof OrderCostingVariable
   */
  label?: string
  /**
   *
   * @type {VariableClass}
   * @memberof OrderCostingVariable
   */
  variable_class?: VariableClass
  /**
   *
   * @type {VariableValueType}
   * @memberof OrderCostingVariable
   */
  value_type?: VariableValueType
  /**
   *
   * @type {any}
   * @memberof OrderCostingVariable
   */
  value?: any
  /**
   *
   * @type {any}
   * @memberof OrderCostingVariable
   */
  row?: any
  /**
   *
   * @type {Array<any>}
   * @memberof OrderCostingVariable
   */
  options?: Array<any>
  /**
   *
   * @type {string}
   * @memberof OrderCostingVariable
   */
  type?: string
}
/**
 *
 * @export
 * @interface OrderCustomer
 */
export interface OrderCustomer {
  /**
   *
   * @type {OrderCompany}
   * @memberof OrderCustomer
   */
  company?: OrderCompany
  /**
   *
   * @type {string}
   * @memberof OrderCustomer
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof OrderCustomer
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderCustomer
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderCustomer
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof OrderCustomer
   */
  phone_ext?: string
}
/**
 *
 * @export
 * @interface OrderDetailsRequest
 */
export interface OrderDetailsRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof OrderDetailsRequest
   */
  orderNumbers: Array<number>
}
/**
 *
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  id?: number
  /**
   *
   * @type {Array<OrderComponent>}
   * @memberof OrderItem
   */
  components?: Array<OrderComponent>
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  expedite_revenue?: number
  /**
   *
   * @type {boolean}
   * @memberof OrderItem
   */
  export_controlled?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  filename?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  lead_days?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  markup_1_price?: number
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  markup_1_name?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  markup_2_price?: number
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  markup_2_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  private_notes?: string
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  public_notes?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  quantity?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  quantity_outstanding?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  quote_item_id?: number
  /**
   *
   * @type {QuoteItemType}
   * @memberof OrderItem
   */
  quote_item_type?: QuoteItemType
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  root_component_id?: number
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  ships_on?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  total_price?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  unit_price?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  base_price?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  add_on_fees?: number
  /**
   *
   * @type {Array<OrderAddOn>}
   * @memberof OrderItem
   */
  ordered_add_ons?: Array<OrderAddOn>
}
/**
 *
 * @export
 * @interface OrderMaterial
 */
export interface OrderMaterial {
  /**
   *
   * @type {number}
   * @memberof OrderMaterial
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderMaterial
   */
  display_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderMaterial
   */
  family?: string
  /**
   *
   * @type {string}
   * @memberof OrderMaterial
   */
  material_class?: string
  /**
   *
   * @type {string}
   * @memberof OrderMaterial
   */
  name?: string
}
/**
 *
 * @export
 * @interface OrderOperation
 */
export interface OrderOperation {
  /**
   *
   * @type {number}
   * @memberof OrderOperation
   */
  id?: number
  /**
   *
   * @type {OperationCategory}
   * @memberof OrderOperation
   */
  category?: OperationCategory
  /**
   *
   * @type {number}
   * @memberof OrderOperation
   */
  cost?: number
  /**
   *
   * @type {Array<OrderCostingVariable>}
   * @memberof OrderOperation
   */
  costing_variables?: Array<OrderCostingVariable>
  /**
   *
   * @type {Array<OperationQuantity>}
   * @memberof OrderOperation
   */
  quantities?: Array<OperationQuantity>
  /**
   *
   * @type {boolean}
   * @memberof OrderOperation
   */
  is_finish?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderOperation
   */
  is_outside_service?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderOperation
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OrderOperation
   */
  operation_definition_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderOperation
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof OrderOperation
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof OrderOperation
   */
  runtime?: number
  /**
   *
   * @type {number}
   * @memberof OrderOperation
   */
  setup_time?: number
}
/**
 *
 * @export
 * @interface OrderPaymentDetails
 */
export interface OrderPaymentDetails {
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  card_brand?: string
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  card_last4?: string
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  net_payout?: number
  /**
   *
   * @type {OrderPaymentDetailsPaymentType}
   * @memberof OrderPaymentDetails
   */
  payment_type?: OrderPaymentDetailsPaymentType
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  purchase_order_number?: string
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  purchasing_dept_contact_email?: string
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  purchasing_dept_contact_name?: string
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  shipping_cost?: number
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  subtotal?: number
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  tax_cost?: number
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  tax_rate?: number
  /**
   *
   * @type {string}
   * @memberof OrderPaymentDetails
   */
  payment_terms?: string
  /**
   *
   * @type {number}
   * @memberof OrderPaymentDetails
   */
  total_price?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OrderPaymentDetailsPaymentType {
  CreditCard = "credit_card",
  PurchaseOrder = "purchase_order",
}

/**
 *
 * @export
 * @interface OrderProcess
 */
export interface OrderProcess {
  /**
   *
   * @type {number}
   * @memberof OrderProcess
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderProcess
   */
  external_name?: string
  /**
   *
   * @type {string}
   * @memberof OrderProcess
   */
  name?: string
}
/**
 *
 * @export
 * @interface OrderShipment
 */
export interface OrderShipment {
  /**
   *
   * @type {number}
   * @memberof OrderShipment
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderShipment
   */
  pickup_recipient?: string
  /**
   *
   * @type {string}
   * @memberof OrderShipment
   */
  shipment_date?: string
  /**
   *
   * @type {Array<OrderShipmentItem>}
   * @memberof OrderShipment
   */
  shipment_items?: Array<OrderShipmentItem>
  /**
   *
   * @type {number}
   * @memberof OrderShipment
   */
  shipping_cost?: number
  /**
   *
   * @type {string}
   * @memberof OrderShipment
   */
  tracking_number?: string
}
/**
 *
 * @export
 * @interface OrderShipmentItem
 */
export interface OrderShipmentItem {
  /**
   *
   * @type {number}
   * @memberof OrderShipmentItem
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof OrderShipmentItem
   */
  order_item_id?: number
  /**
   *
   * @type {number}
   * @memberof OrderShipmentItem
   */
  quantity?: number
}
/**
 *
 * @export
 * @interface OrderShippingOption
 */
export interface OrderShippingOption {
  /**
   *
   * @type {string}
   * @memberof OrderShippingOption
   */
  customers_account_number?: string
  /**
   *
   * @type {OrderShippingOptionCustomersCarrier}
   * @memberof OrderShippingOption
   */
  customers_carrier?: OrderShippingOptionCustomersCarrier
  /**
   *
   * @type {ShippingMethod}
   * @memberof OrderShippingOption
   */
  shipping_method?: ShippingMethod
  /**
   *
   * @type {ShippingOptionType}
   * @memberof OrderShippingOption
   */
  type?: ShippingOptionType
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OrderShippingOptionCustomersCarrier {
  Ups = "ups",
  Fedex = "fedex",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OrderStatus {
  Pending = "pending",
  Confirmed = "confirmed",
  OnHold = "on_hold",
  InProcess = "in_process",
  Completed = "completed",
  Cancelled = "cancelled",
}

/**
 *
 * @export
 * @interface OrderSupportingFile
 */
export interface OrderSupportingFile {
  /**
   *
   * @type {string}
   * @memberof OrderSupportingFile
   */
  filename?: string
  /**
   *
   * @type {string}
   * @memberof OrderSupportingFile
   */
  url?: string
}
/**
 *
 * @export
 * @interface OutputStock
 */
export interface OutputStock {
  /**
   *
   * @type {StockKind}
   * @memberof OutputStock
   */
  kind: StockKind
  /**
   *
   * @type {string}
   * @memberof OutputStock
   */
  modelId: string
}
/**
 *
 * @export
 * @interface PPPartFileRecord
 */
export interface PPPartFileRecord {
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  fileType?: string
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  filename?: string
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  standardizedUrl?: string
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  thumbnailUrl?: string
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof PPPartFileRecord
   */
  partUuid?: string
}
/**
 *
 * @export
 * @interface PPPartInformationRecord
 */
export interface PPPartInformationRecord {
  /**
   *
   * @type {Array<PPPartFileRecord>}
   * @memberof PPPartInformationRecord
   */
  partFiles: Array<PPPartFileRecord>
}
/**
 *
 * @export
 * @interface PPPartLibraryRecord
 */
export interface PPPartLibraryRecord {
  /**
   *
   * @type {PPPrimaryFileRecord}
   * @memberof PPPartLibraryRecord
   */
  primaryFile: PPPrimaryFileRecord
  /**
   *
   * @type {string}
   * @memberof PPPartLibraryRecord
   */
  uuid: string
}
/**
 *
 * @export
 * @interface PPPartLibraryResponse
 */
export interface PPPartLibraryResponse {
  /**
   *
   * @type {number}
   * @memberof PPPartLibraryResponse
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof PPPartLibraryResponse
   */
  next?: string
  /**
   *
   * @type {string}
   * @memberof PPPartLibraryResponse
   */
  prev?: string
  /**
   *
   * @type {Array<PPPartLibraryRecord>}
   * @memberof PPPartLibraryResponse
   */
  results: Array<PPPartLibraryRecord>
}
/**
 *
 * @export
 * @interface PPPrimaryFileRecord
 */
export interface PPPrimaryFileRecord {
  /**
   *
   * @type {string}
   * @memberof PPPrimaryFileRecord
   */
  fileType?: string
  /**
   *
   * @type {string}
   * @memberof PPPrimaryFileRecord
   */
  filename?: string
  /**
   *
   * @type {string}
   * @memberof PPPrimaryFileRecord
   */
  thumbnailUrl?: string
}
/**
 *
 * @export
 * @interface PPTechnicalDataPackage
 */
export interface PPTechnicalDataPackage {
  /**
   *
   * @type {Array<PPPartInformationRecord>}
   * @memberof PPTechnicalDataPackage
   */
  partInformation: Array<PPPartInformationRecord>
}
/**
 *
 * @export
 * @interface PackedToolPath
 */
export interface PackedToolPath {
  /**
   *
   * @type {number}
   * @memberof PackedToolPath
   */
  tool_id: number
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  points: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  feed_rate: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  spindle_speed: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  gcode_lines: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  centers: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  center_indices: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  axis_motions?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof PackedToolPath
   */
  axis_motion_indices?: Array<number>
}
/**
 *
 * @export
 * @interface ParametricStock
 */
export interface ParametricStock {
  /**
   *
   * @type {string}
   * @memberof ParametricStock
   */
  kind: ParametricStockKindEnum
  /**
   *
   * @type {ParametricStockShapeRectangular | ParametricStockShapeRound}
   * @memberof ParametricStock
   */
  shape: ParametricStockShapeRectangular | ParametricStockShapeRound
  /**
   *
   * @type {string}
   * @memberof ParametricStock
   */
  materialId?: string
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  cutLength: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  xMinTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  xMaxTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  yMinTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  yMaxTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  cutLengthMinTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  cutLengthMaxTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  diameterMinTolerance: number
  /**
   *
   * @type {number}
   * @memberof ParametricStock
   */
  diameterMaxTolerance: number
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof ParametricStock
   */
  transform: AppModelsGeometryTransform
}

/**
 * @export
 * @enum {string}
 */
export enum ParametricStockKindEnum {
  Parametric = "parametric",
}

/**
 *
 * @export
 * @interface ParametricStockShapeRectangular
 */
export interface ParametricStockShapeRectangular {
  /**
   *
   * @type {string}
   * @memberof ParametricStockShapeRectangular
   */
  kind: ParametricStockShapeRectangularKindEnum
  /**
   *
   * @type {number}
   * @memberof ParametricStockShapeRectangular
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof ParametricStockShapeRectangular
   */
  y: number
}

/**
 * @export
 * @enum {string}
 */
export enum ParametricStockShapeRectangularKindEnum {
  Rectangular = "rectangular",
}

/**
 *
 * @export
 * @interface ParametricStockShapeRound
 */
export interface ParametricStockShapeRound {
  /**
   *
   * @type {string}
   * @memberof ParametricStockShapeRound
   */
  kind: ParametricStockShapeRoundKindEnum
  /**
   *
   * @type {number}
   * @memberof ParametricStockShapeRound
   */
  diameter: number
}

/**
 * @export
 * @enum {string}
 */
export enum ParametricStockShapeRoundKindEnum {
  Round = "round",
}

/**
 *
 * @export
 * @interface ParametricStockSimInfo
 */
export interface ParametricStockSimInfo {
  /**
   *
   * @type {Array<StockSimSurfaceCone | StockSimSurfaceCylinder | StockSimSurfacePlane | StockSimSurfaceSphere | StockSimSurfaceTorus>}
   * @memberof ParametricStockSimInfo
   */
  surfaces: Array<
    | StockSimSurfaceCone
    | StockSimSurfaceCylinder
    | StockSimSurfacePlane
    | StockSimSurfaceSphere
    | StockSimSurfaceTorus
  >
  /**
   *
   * @type {Array<number>}
   * @memberof ParametricStockSimInfo
   */
  facet_surface: Array<number>
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof ParametricStockSimInfo
   */
  transform: AppModelsGeometryTransform
}
/**
 *
 * @export
 * @interface ParentQuote
 */
export interface ParentQuote {
  /**
   *
   * @type {number}
   * @memberof ParentQuote
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof ParentQuote
   */
  number?: number
  /**
   *
   * @type {string}
   * @memberof ParentQuote
   */
  status?: string
}
/**
 *
 * @export
 * @interface ParentSupplierOrder
 */
export interface ParentSupplierOrder {
  /**
   *
   * @type {number}
   * @memberof ParentSupplierOrder
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof ParentSupplierOrder
   */
  number?: number
  /**
   *
   * @type {string}
   * @memberof ParentSupplierOrder
   */
  status?: string
}
/**
 *
 * @export
 * @interface PartialTransform
 */
export interface PartialTransform {
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  x?: number
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  y?: number
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  z?: number
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  i?: number
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  j?: number
  /**
   *
   * @type {number}
   * @memberof PartialTransform
   */
  k?: number
}
/**
 *
 * @export
 * @interface PartialVector
 */
export interface PartialVector {
  /**
   *
   * @type {number}
   * @memberof PartialVector
   */
  x?: number
  /**
   *
   * @type {number}
   * @memberof PartialVector
   */
  y?: number
  /**
   *
   * @type {number}
   * @memberof PartialVector
   */
  z?: number
}
/**
 *
 * @export
 * @interface PasteSetupRequest
 */
export interface PasteSetupRequest {
  /**
   *
   * @type {ClipboardSetupLocator}
   * @memberof PasteSetupRequest
   */
  source: ClipboardSetupLocator
  /**
   *
   * @type {ClipboardSetupLocator}
   * @memberof PasteSetupRequest
   */
  target: ClipboardSetupLocator
}
/**
 *
 * @export
 * @interface PasteStockRequest
 */
export interface PasteStockRequest {
  /**
   *
   * @type {ClipboardStockLocator}
   * @memberof PasteStockRequest
   */
  source: ClipboardStockLocator
  /**
   *
   * @type {ClipboardStockLocator}
   * @memberof PasteStockRequest
   */
  target: ClipboardStockLocator
}
/**
 *
 * @export
 * @interface PlacedBodyRecord
 */
export interface PlacedBodyRecord {
  /**
   *
   * @type {string}
   * @memberof PlacedBodyRecord
   */
  model: string
  /**
   *
   * @type {PartialTransform}
   * @memberof PlacedBodyRecord
   */
  transform?: PartialTransform
  /**
   *
   * @type {PartialVector}
   * @memberof PlacedBodyRecord
   */
  movement?: PartialVector
  /**
   *
   * @type {string}
   * @memberof PlacedBodyRecord
   */
  material?: string
  /**
   *
   * @type {boolean}
   * @memberof PlacedBodyRecord
   */
  isCustomMillable: boolean
  /**
   *
   * @type {boolean}
   * @memberof PlacedBodyRecord
   */
  allowCutting: boolean
}
/**
 *
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  label: string
  /**
   *
   * @type {number}
   * @memberof Plan
   */
  jobRevision: number
  /**
   *
   * @type {Array<Operation>}
   * @memberof Plan
   */
  operations: Array<Operation>
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  notes?: string
}
/**
 *
 * @export
 * @interface PlanChangerUploadedModelResponse
 */
export interface PlanChangerUploadedModelResponse {
  /**
   *
   * @type {string}
   * @memberof PlanChangerUploadedModelResponse
   */
  id: string
  /**
   *
   * @type {UploadedFileResponse}
   * @memberof PlanChangerUploadedModelResponse
   */
  source: UploadedFileResponse
}
/**
 *
 * @export
 * @interface PlanGenerationConfig
 */
export interface PlanGenerationConfig {
  /**
   *
   * @type {StockGenerationConfig}
   * @memberof PlanGenerationConfig
   */
  stock?: StockGenerationConfig
  /**
   *
   * @type {OperationStrategy}
   * @memberof PlanGenerationConfig
   */
  initialStrategy?: OperationStrategy
  /**
   *
   * @type {OperationStrategy}
   * @memberof PlanGenerationConfig
   */
  finalStrategy?: OperationStrategy
}
/**
 *
 * @export
 * @interface PlanSummary
 */
export interface PlanSummary {
  /**
   *
   * @type {number}
   * @memberof PlanSummary
   */
  partId?: number
  /**
   *
   * @type {string}
   * @memberof PlanSummary
   */
  partName?: string
  /**
   *
   * @type {string}
   * @memberof PlanSummary
   */
  partNumber?: string
  /**
   *
   * @type {string}
   * @memberof PlanSummary
   */
  partRevisionLevel?: string
  /**
   *
   * @type {number}
   * @memberof PlanSummary
   */
  partUnits?: number
}
/**
 *
 * @export
 * @interface PlanarSizes
 */
export interface PlanarSizes {
  /**
   *
   * @type {number}
   * @memberof PlanarSizes
   */
  width: number
  /**
   *
   * @type {number}
   * @memberof PlanarSizes
   */
  length: number
}
/**
 *
 * @export
 * @interface PlansToBackup
 */
export interface PlansToBackup {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof PlansToBackup
   */
  revisions?: { [key: string]: number }
}
/**
 *
 * @export
 * @interface Point
 */
export interface Point {
  /**
   *
   * @type {number}
   * @memberof Point
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof Point
   */
  y: number
  /**
   *
   * @type {number}
   * @memberof Point
   */
  z: number
}
/**
 * If only one compensation is present in the list, it should be used for all points in the feature.  Otherwise, the list should be in one-to-one correspondence with the touch points of the feature. This correspondence should be based on the order that the points are touched.  If there is a differing number of compensations and touch points, an error should be raised when attempting to make use of this data.
 * @export
 * @interface PointCompensations
 */
export interface PointCompensations {
  /**
   *
   * @type {Array<Compensation>}
   * @memberof PointCompensations
   */
  compensations: Array<Compensation>
  /**
   *
   * @type {boolean}
   * @memberof PointCompensations
   */
  disableCompensationUpdates?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PointCompensations
   */
  overrideCompensations?: boolean
}
/**
 *
 * @export
 * @interface PointlessFeature
 */
export interface PointlessFeature {
  /**
   *
   * @type {string}
   * @memberof PointlessFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof PointlessFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof PointlessFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof PointlessFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof PointlessFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof PointlessFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof PointlessFeature
   */
  result_type: PointlessFeatureResultTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum PointlessFeatureResultTypeEnum {
  FeatureHasNoPoints = "FeatureHasNoPoints",
}

/**
 *
 * @export
 * @interface PointsFeature
 */
export interface PointsFeature {
  /**
   *
   * @type {string}
   * @memberof PointsFeature
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PointsFeature
   */
  kind: PointsFeatureKindEnum
  /**
   *
   * @type {PointsFeatureKind}
   * @memberof PointsFeature
   */
  subkind: PointsFeatureKind
  /**
   *
   * @type {Array<PointsFeatureMove | PointsFeaturePoint>}
   * @memberof PointsFeature
   */
  steps: Array<PointsFeatureMove | PointsFeaturePoint>
  /**
   *
   * @type {SourceFeature}
   * @memberof PointsFeature
   */
  sourceFeature?: SourceFeature
  /**
   *
   * @type {boolean}
   * @memberof PointsFeature
   */
  linkWithoutRetract?: boolean
  /**
   *
   * @type {ProbingTouchType}
   * @memberof PointsFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof PointsFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof PointsFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof PointsFeature
   */
  featureWeight?: number
}

/**
 * @export
 * @enum {string}
 */
export enum PointsFeatureKindEnum {
  PointsFeature = "points_feature",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PointsFeatureKind {
  Plane = "Plane",
  PointGroup = "PointGroup",
  Line = "Line",
  Circle = "Circle",
  Sphere = "Sphere",
  Cylinder = "Cylinder",
  LinearDistance = "LinearDistance",
}

/**
 *
 * @export
 * @interface PointsFeatureMove
 */
export interface PointsFeatureMove {
  /**
   *
   * @type {string}
   * @memberof PointsFeatureMove
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PointsFeatureMove
   */
  kind: PointsFeatureMoveKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof PointsFeatureMove
   */
  position: RtcpPosition
  /**
   *
   * @type {boolean}
   * @memberof PointsFeatureMove
   */
  generated?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum PointsFeatureMoveKindEnum {
  PointsFeatureMove = "points_feature_move",
}

/**
 *
 * @export
 * @interface PointsFeaturePoint
 */
export interface PointsFeaturePoint {
  /**
   *
   * @type {string}
   * @memberof PointsFeaturePoint
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PointsFeaturePoint
   */
  kind: PointsFeaturePointKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof PointsFeaturePoint
   */
  position: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof PointsFeaturePoint
   */
  normalApproachDistance: number
  /**
   *
   * @type {Direction}
   * @memberof PointsFeaturePoint
   */
  normal: Direction
  /**
   *
   * @type {number}
   * @memberof PointsFeaturePoint
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof PointsFeaturePoint
   */
  relativePointWeight?: number
}

/**
 * @export
 * @enum {string}
 */
export enum PointsFeaturePointKindEnum {
  PointsFeaturePoint = "points_feature_point",
}

/**
 *
 * @export
 * @interface PostProcessorLocator
 */
export interface PostProcessorLocator {
  /**
   *
   * @type {string}
   * @memberof PostProcessorLocator
   */
  postprocessor: string
}
/**
 *
 * @export
 * @interface PostProcessorManifest
 */
export interface PostProcessorManifest {
  /**
   *
   * @type {PostProcessorLocator}
   * @memberof PostProcessorManifest
   */
  files: PostProcessorLocator
  /**
   *
   * @type {string}
   * @memberof PostProcessorManifest
   */
  machine: string
  /**
   *
   * @type {string}
   * @memberof PostProcessorManifest
   */
  program: string
  /**
   *
   * @type {string}
   * @memberof PostProcessorManifest
   */
  version: string
}
/**
 *
 * @export
 * @interface PostedInspection
 */
export interface PostedInspection {
  /**
   *
   * @type {string}
   * @memberof PostedInspection
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof PostedInspection
   */
  critical: boolean
  /**
   *
   * @type {boolean}
   * @memberof PostedInspection
   */
  toolComps: boolean
}
/**
 *
 * @export
 * @interface PostedProgram
 */
export interface PostedProgram {
  /**
   *
   * @type {string}
   * @memberof PostedProgram
   */
  basename: string
  /**
   *
   * @type {string}
   * @memberof PostedProgram
   */
  program: string
}
/**
 *
 * @export
 * @interface PrecisionStrategy
 */
export interface PrecisionStrategy {
  /**
   *
   * @type {Array<Toolsetting>}
   * @memberof PrecisionStrategy
   */
  toolsetting: Array<Toolsetting>
  /**
   *
   * @type {Array<WcsProbing>}
   * @memberof PrecisionStrategy
   */
  wcsProbing: Array<WcsProbing>
  /**
   *
   * @type {Array<Alignment>}
   * @memberof PrecisionStrategy
   */
  alignments: Array<Alignment>
  /**
   *
   * @type {Array<Inspection>}
   * @memberof PrecisionStrategy
   */
  inspections: Array<Inspection>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Priority {
  OnHold = "OnHold",
  Low = "Low",
  Standard = "Standard",
  High = "High",
}

/**
 *
 * @export
 * @interface PrismStockClampingParams
 */
export interface PrismStockClampingParams {
  /**
   *
   * @type {string}
   * @memberof PrismStockClampingParams
   */
  kind: PrismStockClampingParamsKindEnum
  /**
   *
   * @type {PartialVector}
   * @memberof PrismStockClampingParams
   */
  minStockExtent?: PartialVector
  /**
   *
   * @type {PartialVector}
   * @memberof PrismStockClampingParams
   */
  maxStockExtent?: PartialVector
  /**
   *
   * @type {DiscreteParameter}
   * @memberof PrismStockClampingParams
   */
  discreteXExtent?: DiscreteParameter
  /**
   *
   * @type {number}
   * @memberof PrismStockClampingParams
   */
  baseScore: number
}

/**
 * @export
 * @enum {string}
 */
export enum PrismStockClampingParamsKindEnum {
  PrismStockClamping = "prism_stock_clamping",
}

/**
 *
 * @export
 * @interface ProbeRecord
 */
export interface ProbeRecord {
  /**
   *
   * @type {string}
   * @memberof ProbeRecord
   */
  probeId: string
  /**
   *
   * @type {number}
   * @memberof ProbeRecord
   */
  toolId?: number
  /**
   *
   * @type {string}
   * @memberof ProbeRecord
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof ProbeRecord
   */
  gaugeLength: number
  /**
   *
   * @type {number}
   * @memberof ProbeRecord
   */
  stylusLength: number
  /**
   *
   * @type {number}
   * @memberof ProbeRecord
   */
  stylusShaftDiameter: number
  /**
   *
   * @type {number}
   * @memberof ProbeRecord
   */
  sphereDiameter: number
  /**
   *
   * @type {Array<string>}
   * @memberof ProbeRecord
   */
  holderModelStems: Array<string>
}
/**
 *
 * @export
 * @interface ProbeToolchange
 */
export interface ProbeToolchange {
  /**
   *
   * @type {string}
   * @memberof ProbeToolchange
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ProbeToolchange
   */
  kind: ProbeToolchangeKindEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ProbeToolchangeKindEnum {
  ProbeToolchange = "probe_toolchange",
}

/**
 *
 * @export
 * @interface ProbesConfig
 */
export interface ProbesConfig {
  /**
   *
   * @type {Array<ProbeRecord>}
   * @memberof ProbesConfig
   */
  probes: Array<ProbeRecord>
}
/**
 *
 * @export
 * @interface Probing
 */
export interface Probing {
  /**
   *
   * @type {string}
   * @memberof Probing
   */
  probeId: string
  /**
   *
   * @type {PrecisionStrategy}
   * @memberof Probing
   */
  strategy: PrecisionStrategy
  /**
   *
   * @type {number}
   * @memberof Probing
   */
  spindleTravelLimitMove?: number
  /**
   *
   * @type {boolean}
   * @memberof Probing
   */
  useMspSafeMoves?: boolean
}
/**
 *
 * @export
 * @interface ProbingCutterCompResult
 */
export interface ProbingCutterCompResult {
  /**
   *
   * @type {Array<SectionCutterCompResult>}
   * @memberof ProbingCutterCompResult
   */
  sectionResults: Array<SectionCutterCompResult>
}
/**
 *
 * @export
 * @interface ProbingFeedbackNode
 */
export interface ProbingFeedbackNode {
  /**
   *
   * @type {string}
   * @memberof ProbingFeedbackNode
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ProbingFeedbackNode
   */
  sectionId: string
  /**
   *
   * @type {string}
   * @memberof ProbingFeedbackNode
   */
  invalidationReason?: string
}
/**
 *
 * @export
 * @interface ProbingIssue
 */
export interface ProbingIssue {
  /**
   *
   * @type {ProbingIssueKind}
   * @memberof ProbingIssue
   */
  kind: ProbingIssueKind
  /**
   *
   * @type {string}
   * @memberof ProbingIssue
   */
  tag?: string
  /**
   *
   * @type {string}
   * @memberof ProbingIssue
   */
  stepId?: string
  /**
   *
   * @type {string}
   * @memberof ProbingIssue
   */
  message: string
  /**
   *
   * @type {TaggedMachinePosition | TaggedRtcpPosition}
   * @memberof ProbingIssue
   */
  position?: TaggedMachinePosition | TaggedRtcpPosition
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ProbingIssueKind {
  Warning = "warning",
  Error = "error",
}

/**
 *
 * @export
 * @interface ProbingPost
 */
export interface ProbingPost {
  /**
   *
   * @type {Array<PostedProgram>}
   * @memberof ProbingPost
   */
  programs: Array<PostedProgram>
  /**
   *
   * @type {string}
   * @memberof ProbingPost
   */
  defaultProgramBasename?: string
  /**
   *
   * @type {Array<PostedInspection>}
   * @memberof ProbingPost
   */
  inspectionSubroutines: Array<PostedInspection>
}
/**
 *
 * @export
 * @interface ProbingPostprocessingConfig
 */
export interface ProbingPostprocessingConfig {
  /**
   *
   * @type {number}
   * @memberof ProbingPostprocessingConfig
   */
  workOffsetNumber?: number
  /**
   *
   * @type {string | number}
   * @memberof ProbingPostprocessingConfig
   */
  defaultApproachDistance?: string | number
  /**
   *
   * @type {string | number}
   * @memberof ProbingPostprocessingConfig
   */
  defaultSearchDistance?: string | number
  /**
   *
   * @type {string | number}
   * @memberof ProbingPostprocessingConfig
   */
  feedrate?: string | number
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  machinePreCheck?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  machinePostCheck?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  squarenessPreCheck?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  squarenessPostCheck?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  clampAxesAlignments?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  skipCutting?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  skipAlignments?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  skipInspections?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  skipMachineOffsets?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  skipMachineOffsetsLogging?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProbingPostprocessingConfig
   */
  enableCycleDprint?: boolean
}
/**
 *
 * @export
 * @interface ProbingStepsIssueSelector
 */
export interface ProbingStepsIssueSelector {
  /**
   *
   * @type {string}
   * @memberof ProbingStepsIssueSelector
   */
  kind: ProbingStepsIssueSelectorKindEnum
  /**
   *
   * @type {string}
   * @memberof ProbingStepsIssueSelector
   */
  issueTag: string
  /**
   *
   * @type {string}
   * @memberof ProbingStepsIssueSelector
   */
  probingStepId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ProbingStepsIssueSelectorKindEnum {
  ProbingSteps = "probing_steps",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ProbingTouchType {
  Default = "Default",
  PartTouch = "PartTouch",
  StockTouch = "StockTouch",
  FixtureTouch = "FixtureTouch",
}

/**
 *
 * @export
 * @interface ProxiedJiraAuthRequest
 */
export interface ProxiedJiraAuthRequest {
  /**
   *
   * @type {string}
   * @memberof ProxiedJiraAuthRequest
   */
  host: string
  /**
   *
   * @type {string}
   * @memberof ProxiedJiraAuthRequest
   */
  grantType: string
  /**
   *
   * @type {string}
   * @memberof ProxiedJiraAuthRequest
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof ProxiedJiraAuthRequest
   */
  refreshToken?: string
}
/**
 *
 * @export
 * @interface QualityControlPlanData
 */
export interface QualityControlPlanData {
  /**
   *
   * @type {PlanSummary}
   * @memberof QualityControlPlanData
   */
  summary: PlanSummary
  /**
   *
   * @type {Array<Dimension>}
   * @memberof QualityControlPlanData
   */
  dimensions: Array<Dimension>
  /**
   *
   * @type {Array<Drawing>}
   * @memberof QualityControlPlanData
   */
  drawings: Array<Drawing>
}
/**
 *
 * @export
 * @interface Quote
 */
export interface Quote {
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  number?: number
  /**
   *
   * @type {SalesPerson}
   * @memberof Quote
   */
  sales_person?: SalesPerson
  /**
   *
   * @type {SalesPerson}
   * @memberof Quote
   */
  estimator?: SalesPerson
  /**
   *
   * @type {QuoteContact}
   * @memberof Quote
   */
  contact?: QuoteContact
  /**
   *
   * @type {QuoteCustomer}
   * @memberof Quote
   */
  customer?: QuoteCustomer
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  tax_rate?: number
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  tax_cost?: number
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  private_notes?: string
  /**
   *
   * @type {Array<QuoteItem>}
   * @memberof Quote
   */
  quote_items?: Array<QuoteItem>
  /**
   *
   * @type {QuoteStatus}
   * @memberof Quote
   */
  status?: QuoteStatus
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  sent_date?: string
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  expired_date?: string
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  quote_notes?: string
  /**
   *
   * @type {boolean}
   * @memberof Quote
   */
  export_controlled?: boolean
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  digital_last_viewed_on?: string
  /**
   *
   * @type {boolean}
   * @memberof Quote
   */
  expired?: boolean
  /**
   *
   * @type {any}
   * @memberof Quote
   */
  request_for_quote?: any
  /**
   *
   * @type {ParentQuote}
   * @memberof Quote
   */
  parent_quote?: ParentQuote
  /**
   *
   * @type {ParentSupplierOrder}
   * @memberof Quote
   */
  parent_supplier_order?: ParentSupplierOrder
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  authenticated_pdf_quote_url?: string
  /**
   *
   * @type {boolean}
   * @memberof Quote
   */
  is_unviewed_drafted_rfq?: boolean
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  created?: string
  /**
   *
   * @type {Array<number>}
   * @memberof Quote
   */
  order_numbers?: Array<number>
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  revision_number?: number
}
/**
 *
 * @export
 * @interface QuoteAddOn
 */
export interface QuoteAddOn {
  /**
   *
   * @type {boolean}
   * @memberof QuoteAddOn
   */
  is_required?: boolean
  /**
   *
   * @type {string}
   * @memberof QuoteAddOn
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteAddOn
   */
  notes?: string
  /**
   *
   * @type {Array<QuoteAddOnQuantity>}
   * @memberof QuoteAddOn
   */
  quantities?: Array<QuoteAddOnQuantity>
  /**
   *
   * @type {Array<QuoteCostingVariable>}
   * @memberof QuoteAddOn
   */
  costing_variables?: Array<QuoteCostingVariable>
}
/**
 *
 * @export
 * @interface QuoteAddOnQuantity
 */
export interface QuoteAddOnQuantity {
  /**
   *
   * @type {number}
   * @memberof QuoteAddOnQuantity
   */
  price?: number
  /**
   *
   * @type {number}
   * @memberof QuoteAddOnQuantity
   */
  manual_price?: number
  /**
   *
   * @type {number}
   * @memberof QuoteAddOnQuantity
   */
  quantity?: number
}
/**
 *
 * @export
 * @interface QuoteComponent
 */
export interface QuoteComponent {
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  id?: string
  /**
   *
   * @type {Array<QuoteAddOn>}
   * @memberof QuoteComponent
   */
  add_ons?: Array<QuoteAddOn>
  /**
   *
   * @type {Array<QuoteQuantity>}
   * @memberof QuoteComponent
   */
  quantities?: Array<QuoteQuantity>
  /**
   *
   * @type {Array<number>}
   * @memberof QuoteComponent
   */
  child_ids?: Array<number>
  /**
   *
   * @type {Array<ComponentChild>}
   * @memberof QuoteComponent
   */
  children?: Array<ComponentChild>
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  description?: string
  /**
   *
   * @type {boolean}
   * @memberof QuoteComponent
   */
  export_controlled?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof QuoteComponent
   */
  finishes?: Array<string>
  /**
   *
   * @type {number}
   * @memberof QuoteComponent
   */
  innate_quantity?: number
  /**
   *
   * @type {boolean}
   * @memberof QuoteComponent
   */
  is_root_component?: boolean
  /**
   *
   * @type {OrderMaterial}
   * @memberof QuoteComponent
   */
  material?: OrderMaterial
  /**
   *
   * @type {Array<QuoteOperation>}
   * @memberof QuoteComponent
   */
  material_operations?: Array<QuoteOperation>
  /**
   *
   * @type {Array<number>}
   * @memberof QuoteComponent
   */
  parent_ids?: Array<number>
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  part_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  part_number?: string
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  part_url?: string
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  part_uuid?: string
  /**
   *
   * @type {OrderProcess}
   * @memberof QuoteComponent
   */
  process?: OrderProcess
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  revision?: string
  /**
   *
   * @type {Array<QuoteOperation>}
   * @memberof QuoteComponent
   */
  shop_operations?: Array<QuoteOperation>
  /**
   *
   * @type {Array<OrderSupportingFile>}
   * @memberof QuoteComponent
   */
  supporting_files?: Array<OrderSupportingFile>
  /**
   *
   * @type {string}
   * @memberof QuoteComponent
   */
  thumbnail_url?: string
  /**
   *
   * @type {ComponentType}
   * @memberof QuoteComponent
   */
  type?: ComponentType
}
/**
 *
 * @export
 * @interface QuoteContact
 */
export interface QuoteContact {
  /**
   *
   * @type {QuoteContactAccount}
   * @memberof QuoteContact
   */
  account?: QuoteContactAccount
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  first_name?: string
  /**
   *
   * @type {number}
   * @memberof QuoteContact
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof QuoteContact
   */
  phone_ext?: string
}
/**
 *
 * @export
 * @interface QuoteContactAccount
 */
export interface QuoteContactAccount {
  /**
   *
   * @type {string}
   * @memberof QuoteContactAccount
   */
  erp_code?: string
  /**
   *
   * @type {number}
   * @memberof QuoteContactAccount
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof QuoteContactAccount
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof QuoteContactAccount
   */
  name?: string
}
/**
 *
 * @export
 * @interface QuoteCostingVariable
 */
export interface QuoteCostingVariable {
  /**
   *
   * @type {string}
   * @memberof QuoteCostingVariable
   */
  label?: string
  /**
   *
   * @type {VariableClass}
   * @memberof QuoteCostingVariable
   */
  variable_class?: VariableClass
  /**
   *
   * @type {VariableValueType}
   * @memberof QuoteCostingVariable
   */
  value_type?: VariableValueType
  /**
   *
   * @type {boolean}
   * @memberof QuoteCostingVariable
   */
  quantity_specific?: boolean
  /**
   *
   * @type {QuoteCostingVariableQuantities | Array<QuoteCostingVariableQuantities>}
   * @memberof QuoteCostingVariable
   */
  quantities?: QuoteCostingVariableQuantities | Array<QuoteCostingVariableQuantities>
  /**
   *
   * @type {string}
   * @memberof QuoteCostingVariable
   */
  type?: string
  /**
   *
   * @type {any}
   * @memberof QuoteCostingVariable
   */
  value?: any
  /**
   *
   * @type {any}
   * @memberof QuoteCostingVariable
   */
  row?: any
}
/**
 *
 * @export
 * @interface QuoteCostingVariableQuantities
 */
export interface QuoteCostingVariableQuantities {
  /**
   *
   * @type {QuoteCostingVariableStringQuotedQuantity1}
   * @memberof QuoteCostingVariableQuantities
   */
  _1?: QuoteCostingVariableStringQuotedQuantity1
  /**
   *
   * @type {QuoteCostingVariableStringQuotedQuantity1}
   * @memberof QuoteCostingVariableQuantities
   */
  _2?: QuoteCostingVariableStringQuotedQuantity1
}
/**
 *
 * @export
 * @interface QuoteCostingVariableStringQuotedQuantity1
 */
export interface QuoteCostingVariableStringQuotedQuantity1 {
  /**
   *
   * @type {any}
   * @memberof QuoteCostingVariableStringQuotedQuantity1
   */
  value?: any
  /**
   *
   * @type {any}
   * @memberof QuoteCostingVariableStringQuotedQuantity1
   */
  row?: any
  /**
   *
   * @type {Array<any>}
   * @memberof QuoteCostingVariableStringQuotedQuantity1
   */
  options?: Array<any>
}
/**
 *
 * @export
 * @interface QuoteCustomer
 */
export interface QuoteCustomer {
  /**
   *
   * @type {string}
   * @memberof QuoteCustomer
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteCustomer
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteCustomer
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof QuoteCustomer
   */
  notes?: string
  /**
   *
   * @type {QuoteCustomerCompany}
   * @memberof QuoteCustomer
   */
  company?: QuoteCustomerCompany
}
/**
 *
 * @export
 * @interface QuoteCustomerCompany
 */
export interface QuoteCustomerCompany {
  /**
   *
   * @type {string}
   * @memberof QuoteCustomerCompany
   */
  notes?: string
  /**
   *
   * @type {QuoteCustomerCompanyMetrics}
   * @memberof QuoteCustomerCompany
   */
  metrics?: QuoteCustomerCompanyMetrics
  /**
   *
   * @type {string}
   * @memberof QuoteCustomerCompany
   */
  business_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteCustomerCompany
   */
  erp_code?: string
}
/**
 *
 * @export
 * @interface QuoteCustomerCompanyMetrics
 */
export interface QuoteCustomerCompanyMetrics {
  /**
   *
   * @type {number}
   * @memberof QuoteCustomerCompanyMetrics
   */
  order_revenue_all_time?: number
  /**
   *
   * @type {number}
   * @memberof QuoteCustomerCompanyMetrics
   */
  order_revenue_last_thirty_days?: number
  /**
   *
   * @type {number}
   * @memberof QuoteCustomerCompanyMetrics
   */
  quotes_sent_all_time?: number
  /**
   *
   * @type {number}
   * @memberof QuoteCustomerCompanyMetrics
   */
  quotes_sent_last_thirty_days?: number
}
/**
 *
 * @export
 * @interface QuoteDetailsRequest
 */
export interface QuoteDetailsRequest {
  /**
   *
   * @type {Array<QuoteIdentifier>}
   * @memberof QuoteDetailsRequest
   */
  identifiers: Array<QuoteIdentifier>
}
/**
 *
 * @export
 * @interface QuoteExpedite
 */
export interface QuoteExpedite {
  /**
   *
   * @type {number}
   * @memberof QuoteExpedite
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof QuoteExpedite
   */
  lead_time?: number
  /**
   *
   * @type {number}
   * @memberof QuoteExpedite
   */
  markup?: number
  /**
   *
   * @type {number}
   * @memberof QuoteExpedite
   */
  unit_price?: number
  /**
   *
   * @type {number}
   * @memberof QuoteExpedite
   */
  total_price?: number
}
/**
 *
 * @export
 * @interface QuoteIdentifier
 */
export interface QuoteIdentifier {
  /**
   *
   * @type {number}
   * @memberof QuoteIdentifier
   */
  quoteNumber: number
  /**
   *
   * @type {number}
   * @memberof QuoteIdentifier
   */
  revisionNumber?: number
}
/**
 *
 * @export
 * @interface QuoteItem
 */
export interface QuoteItem {
  /**
   *
   * @type {number}
   * @memberof QuoteItem
   */
  id?: number
  /**
   *
   * @type {QuoteItemType}
   * @memberof QuoteItem
   */
  type?: QuoteItemType
  /**
   *
   * @type {number}
   * @memberof QuoteItem
   */
  root_component_id?: number
  /**
   *
   * @type {Array<QuoteComponent>}
   * @memberof QuoteItem
   */
  components?: Array<QuoteComponent>
  /**
   *
   * @type {number}
   * @memberof QuoteItem
   */
  position?: number
  /**
   *
   * @type {boolean}
   * @memberof QuoteItem
   */
  export_controlled?: boolean
  /**
   *
   * @type {Array<number>}
   * @memberof QuoteItem
   */
  component_ids?: Array<number>
  /**
   *
   * @type {string}
   * @memberof QuoteItem
   */
  private_notes?: string
  /**
   *
   * @type {string}
   * @memberof QuoteItem
   */
  public_notes?: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum QuoteItemType {
  Automatic = "automatic",
  Manual = "manual",
}

/**
 *
 * @export
 * @interface QuoteOperation
 */
export interface QuoteOperation {
  /**
   *
   * @type {number}
   * @memberof QuoteOperation
   */
  id?: number
  /**
   *
   * @type {OperationCategory}
   * @memberof QuoteOperation
   */
  category?: OperationCategory
  /**
   *
   * @type {number}
   * @memberof QuoteOperation
   */
  cost?: number
  /**
   *
   * @type {Array<QuoteCostingVariable>}
   * @memberof QuoteOperation
   */
  costing_variables?: Array<QuoteCostingVariable>
  /**
   *
   * @type {Array<OperationQuantity>}
   * @memberof QuoteOperation
   */
  quantities?: Array<OperationQuantity>
  /**
   *
   * @type {boolean}
   * @memberof QuoteOperation
   */
  is_finish?: boolean
  /**
   *
   * @type {boolean}
   * @memberof QuoteOperation
   */
  is_outside_service?: boolean
  /**
   *
   * @type {string}
   * @memberof QuoteOperation
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteOperation
   */
  operation_definition_name?: string
  /**
   *
   * @type {string}
   * @memberof QuoteOperation
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof QuoteOperation
   */
  position?: number
  /**
   *
   * @type {number}
   * @memberof QuoteOperation
   */
  runtime?: number
  /**
   *
   * @type {number}
   * @memberof QuoteOperation
   */
  setup_time?: number
}
/**
 *
 * @export
 * @interface QuoteQuantity
 */
export interface QuoteQuantity {
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  quantity?: number
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  markup_1_price?: number
  /**
   *
   * @type {string}
   * @memberof QuoteQuantity
   */
  markup_1_name?: string
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  markup_2_price?: number
  /**
   *
   * @type {string}
   * @memberof QuoteQuantity
   */
  markup_2_name?: string
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  unit_price?: number
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  total_price?: number
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  total_price_with_required_add_ons?: number
  /**
   *
   * @type {number}
   * @memberof QuoteQuantity
   */
  lead_time?: number
  /**
   *
   * @type {Array<QuoteExpedite>}
   * @memberof QuoteQuantity
   */
  expedites?: Array<QuoteExpedite>
}
/**
 *
 * @export
 * @interface QuoteRevisionPair
 */
export interface QuoteRevisionPair {
  /**
   *
   * @type {number}
   * @memberof QuoteRevisionPair
   */
  quote?: number
  /**
   *
   * @type {number}
   * @memberof QuoteRevisionPair
   */
  revision_number?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum QuoteStatus {
  Draft = "draft",
  Outstanding = "outstanding",
  Expired = "expired",
  Cancelled = "cancelled",
  PartiallyAccepted = "partially_accepted",
  Accepted = "accepted",
  Trash = "trash",
  Lost = "lost",
}

/**
 *
 * @export
 * @interface ReferencePosition
 */
export interface ReferencePosition {
  /**
   *
   * @type {string}
   * @memberof ReferencePosition
   */
  label: string
  /**
   *
   * @type {ReferencePositionVariant}
   * @memberof ReferencePosition
   */
  variant: ReferencePositionVariant
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ReferencePosition
   */
  coords: { [key: string]: number }
}
/**
 *
 * @export
 * @interface ReferencePositionMove
 */
export interface ReferencePositionMove {
  /**
   *
   * @type {string}
   * @memberof ReferencePositionMove
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ReferencePositionMove
   */
  kind: ReferencePositionMoveKindEnum
  /**
   *
   * @type {string}
   * @memberof ReferencePositionMove
   */
  label?: string
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof ReferencePositionMove
   */
  axes: { [key: string]: boolean }
}

/**
 * @export
 * @enum {string}
 */
export enum ReferencePositionMoveKindEnum {
  ReferencePositionMove = "reference_position_move",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ReferencePositionVariant {
  G28 = "G28",
  G30 = "G30",
  Manual = "Manual",
}

/**
 *
 * @export
 * @interface RemovePatchOperation
 */
export interface RemovePatchOperation {
  /**
   *
   * @type {string}
   * @memberof RemovePatchOperation
   */
  op: RemovePatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof RemovePatchOperation
   */
  path: string
}

/**
 * @export
 * @enum {string}
 */
export enum RemovePatchOperationOpEnum {
  Remove = "remove",
}

/**
 *
 * @export
 * @interface RenderMaterial
 */
export interface RenderMaterial {
  /**
   *
   * @type {string}
   * @memberof RenderMaterial
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RenderMaterial
   */
  color: string
  /**
   *
   * @type {number}
   * @memberof RenderMaterial
   */
  opacity?: number
  /**
   *
   * @type {number}
   * @memberof RenderMaterial
   */
  roughness?: number
  /**
   *
   * @type {number}
   * @memberof RenderMaterial
   */
  metalness?: number
}
/**
 *
 * @export
 * @interface ReplacePatchOperation
 */
export interface ReplacePatchOperation {
  /**
   *
   * @type {string}
   * @memberof ReplacePatchOperation
   */
  op: ReplacePatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof ReplacePatchOperation
   */
  path: string
  /**
   *
   * @type {string | boolean | number | Array<any> | object}
   * @memberof ReplacePatchOperation
   */
  value: string | boolean | number | Array<any> | object
}

/**
 * @export
 * @enum {string}
 */
export enum ReplacePatchOperationOpEnum {
  Replace = "replace",
}

/**
 *
 * @export
 * @interface RoundStockClampingParams
 */
export interface RoundStockClampingParams {
  /**
   *
   * @type {string}
   * @memberof RoundStockClampingParams
   */
  kind: RoundStockClampingParamsKindEnum
  /**
   *
   * @type {PartialVector}
   * @memberof RoundStockClampingParams
   */
  minStockExtent?: PartialVector
  /**
   *
   * @type {PartialVector}
   * @memberof RoundStockClampingParams
   */
  maxStockExtent?: PartialVector
  /**
   *
   * @type {DiscreteParameter}
   * @memberof RoundStockClampingParams
   */
  discreteDiameter?: DiscreteParameter
  /**
   *
   * @type {number}
   * @memberof RoundStockClampingParams
   */
  baseScore: number
}

/**
 * @export
 * @enum {string}
 */
export enum RoundStockClampingParamsKindEnum {
  RoundStockClamping = "round_stock_clamping",
}

/**
 * RTCP = Rotation Tool Center Point  During probing, the `point` should indicate a position of the center of the probe sphere in the oriented, and possibly corrected, workpiece coordinate system.  The extra_coords should contain any additional coordinates required to specify the orientation of the relevant machine (e.g, rotary coordinates such as a,b,c). These extra coordinates are not validated until generation time.
 * @export
 * @interface RtcpPosition
 */
export interface RtcpPosition {
  /**
   *
   * @type {Point}
   * @memberof RtcpPosition
   */
  point: Point
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof RtcpPosition
   */
  extraCoords: { [key: string]: number }
}
/**
 *
 * @export
 * @interface SalesPerson
 */
export interface SalesPerson {
  /**
   *
   * @type {string}
   * @memberof SalesPerson
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof SalesPerson
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof SalesPerson
   */
  email?: string
}
/**
 *
 * @export
 * @interface SchemaAlignment
 */
export interface SchemaAlignment {
  /**
   *
   * @type {string}
   * @memberof SchemaAlignment
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaAlignment
   */
  command_index?: number
  /**
   *
   * @type {AppModelsInspectionSchemaGeneratedInspectionSchemaTransform}
   * @memberof SchemaAlignment
   */
  cad_to_part?: AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
  /**
   *
   * @type {AppModelsInspectionSchemaGeneratedInspectionSchemaTransform}
   * @memberof SchemaAlignment
   */
  machine_to_part?: AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
}
/**
 *
 * @export
 * @interface SchemaCmmResult
 */
export interface SchemaCmmResult {
  /**
   *
   * @type {Array<TolResult>}
   * @memberof SchemaCmmResult
   */
  results?: Array<TolResult>
  /**
   *
   * @type {ToleranceStatus}
   * @memberof SchemaCmmResult
   */
  overall_tolerance_status?: ToleranceStatus
}
/**
 *
 * @export
 * @interface SchemaConstructedFeature
 */
export interface SchemaConstructedFeature {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaConstructedFeature
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaConstructedFeature
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaConstructedFeature
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaConstructedFeature
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaConstructedFeature
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaConstructedFeature
   */
  feature_meta_type: SchemaConstructedFeatureFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaConstructedFeature
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaConstructedFeature
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaConstructedFeature
   */
  hits?: SchemaHits
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaConstructedFeatureFeatureMetaTypeEnum {
  ConstructedFeature = "ConstructedFeature",
}

/**
 *
 * @export
 * @interface SchemaDim
 */
export interface SchemaDim {
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaDim
   */
  command_index?: number
  /**
   *
   * @type {DimensionType}
   * @memberof SchemaDim
   */
  type: DimensionType
  /**
   *
   * @type {CommandType}
   * @memberof SchemaDim
   */
  command_type?: CommandType
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaDim
   */
  features: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaDim
   */
  datums: Array<string>
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  unit?: string
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  dim?: string
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  instance?: string
  /**
   *
   * @type {SchemaGdt}
   * @memberof SchemaDim
   */
  gdt?: SchemaGdt
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  standard?: string
  /**
   *
   * @type {SchemaShape}
   * @memberof SchemaDim
   */
  shape?: SchemaShape
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  tolerance_zone_type?: string
  /**
   *
   * @type {string}
   * @memberof SchemaDim
   */
  material_condition_modifier?: string
  /**
   *
   * @type {SchemaValue}
   * @memberof SchemaDim
   */
  basic_result?: SchemaValue
  /**
   *
   * @type {Array<ToleranceResult>}
   * @memberof SchemaDim
   */
  tolerance_results?: Array<ToleranceResult>
  /**
   *
   * @type {Array<LocationResult>}
   * @memberof SchemaDim
   */
  location_results?: Array<LocationResult>
  /**
   *
   * @type {Array<SizeResult>}
   * @memberof SchemaDim
   */
  size_results?: Array<SizeResult>
}
/**
 *
 * @export
 * @interface SchemaFeature
 */
export interface SchemaFeature {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeature
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeature
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeature
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeature
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeature
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeature
   */
  feature_meta_type: SchemaFeatureFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeature
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeature
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeature
   */
  sub_features?: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureFeatureMetaTypeEnum {
  Feature = "Feature",
}

/**
 *
 * @export
 * @interface SchemaFeatureCircle
 */
export interface SchemaFeatureCircle {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureCircle
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCircle
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCircle
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCircle
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCircle
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCircle
   */
  feature_meta_type: SchemaFeatureCircleFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureCircle
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureCircle
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureCircle
   */
  sub_features?: Array<string>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCircle
   */
  diameter?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureCircleFeatureMetaTypeEnum {
  Circle = "Circle",
}

/**
 *
 * @export
 * @interface SchemaFeatureCone
 */
export interface SchemaFeatureCone {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureCone
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCone
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCone
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCone
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCone
   */
  feature_meta_type: SchemaFeatureConeFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureCone
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureCone
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureCone
   */
  sub_features?: Array<string>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  diameter?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  height?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  start_angle?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  end_angle?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCone
   */
  angle_vec?: Array<number>
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureCone
   */
  orientation?: boolean
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCone
   */
  angle?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureConeFeatureMetaTypeEnum {
  Cone = "Cone",
}

/**
 *
 * @export
 * @interface SchemaFeatureConstructedBestFitCylinder
 */
export interface SchemaFeatureConstructedBestFitCylinder {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  feature_meta_type: SchemaFeatureConstructedBestFitCylinderFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  hits?: SchemaHits
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  diameter?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  length?: number
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureConstructedBestFitCylinder
   */
  orientation?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureConstructedBestFitCylinderFeatureMetaTypeEnum {
  ConstructedBestFitCylinder = "ConstructedBestFitCylinder",
}

/**
 *
 * @export
 * @interface SchemaFeatureConstructedCircle
 */
export interface SchemaFeatureConstructedCircle {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureConstructedCircle
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedCircle
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedCircle
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedCircle
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedCircle
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedCircle
   */
  feature_meta_type: SchemaFeatureConstructedCircleFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureConstructedCircle
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureConstructedCircle
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureConstructedCircle
   */
  hits?: SchemaHits
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedCircle
   */
  diameter?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureConstructedCircleFeatureMetaTypeEnum {
  ConstructedCircle = "ConstructedCircle",
}

/**
 *
 * @export
 * @interface SchemaFeatureConstructedSphere
 */
export interface SchemaFeatureConstructedSphere {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureConstructedSphere
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedSphere
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedSphere
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedSphere
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureConstructedSphere
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureConstructedSphere
   */
  feature_meta_type: SchemaFeatureConstructedSphereFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureConstructedSphere
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureConstructedSphere
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureConstructedSphere
   */
  hits?: SchemaHits
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureConstructedSphere
   */
  diameter?: number
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureConstructedSphere
   */
  orientation?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureConstructedSphereFeatureMetaTypeEnum {
  ConstructedSphere = "ConstructedSphere",
}

/**
 *
 * @export
 * @interface SchemaFeatureCylinder
 */
export interface SchemaFeatureCylinder {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureCylinder
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCylinder
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCylinder
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCylinder
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCylinder
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureCylinder
   */
  feature_meta_type: SchemaFeatureCylinderFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureCylinder
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureCylinder
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureCylinder
   */
  sub_features?: Array<string>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCylinder
   */
  diameter?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCylinder
   */
  length?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCylinder
   */
  start_angle?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureCylinder
   */
  end_angle?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureCylinder
   */
  angle_vec?: Array<number>
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureCylinder
   */
  orientation?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureCylinderFeatureMetaTypeEnum {
  Cylinder = "Cylinder",
}

/**
 *
 * @export
 * @interface SchemaFeatureLineSegment
 */
export interface SchemaFeatureLineSegment {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureLineSegment
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureLineSegment
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureLineSegment
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureLineSegment
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureLineSegment
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureLineSegment
   */
  feature_meta_type: SchemaFeatureLineSegmentFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureLineSegment
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureLineSegment
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureLineSegment
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureLineSegment
   */
  end_point?: Array<number>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureLineSegment
   */
  length?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureLineSegmentFeatureMetaTypeEnum {
  LineSegment = "LineSegment",
}

/**
 *
 * @export
 * @interface SchemaFeatureOffsetLine
 */
export interface SchemaFeatureOffsetLine {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureOffsetLine
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureOffsetLine
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureOffsetLine
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureOffsetLine
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureOffsetLine
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureOffsetLine
   */
  feature_meta_type: SchemaFeatureOffsetLineFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureOffsetLine
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureOffsetLine
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureOffsetLine
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureOffsetLine
   */
  end_point?: Array<number>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureOffsetLine
   */
  length?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureOffsetLine
   */
  offsets?: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureOffsetLineFeatureMetaTypeEnum {
  OffsetLine = "OffsetLine",
}

/**
 *
 * @export
 * @interface SchemaFeatureSlot
 */
export interface SchemaFeatureSlot {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureSlot
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureSlot
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureSlot
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureSlot
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureSlot
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureSlot
   */
  feature_meta_type: SchemaFeatureSlotFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureSlot
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureSlot
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureSlot
   */
  sub_features?: Array<string>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureSlot
   */
  width?: number
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureSlot
   */
  length?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureSlot
   */
  slot_vec?: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureSlotFeatureMetaTypeEnum {
  Slot = "Slot",
}

/**
 *
 * @export
 * @interface SchemaFeatureSphere
 */
export interface SchemaFeatureSphere {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureSphere
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureSphere
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureSphere
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureSphere
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureSphere
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureSphere
   */
  feature_meta_type: SchemaFeatureSphereFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureSphere
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureSphere
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureSphere
   */
  sub_features?: Array<string>
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureSphere
   */
  diameter?: number
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureSphere
   */
  orientation?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureSphereFeatureMetaTypeEnum {
  Sphere = "Sphere",
}

/**
 *
 * @export
 * @interface SchemaFeatureUnknown
 */
export interface SchemaFeatureUnknown {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureUnknown
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureUnknown
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureUnknown
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureUnknown
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  feature_meta_type: SchemaFeatureUnknownFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureUnknown
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureUnknown
   */
  hits?: SchemaHits
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureUnknown
   */
  sub_features?: Array<string>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  guid?: string
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  uid?: string
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureUnknown
   */
  description?: string
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureUnknown
   */
  is_feature?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SchemaFeatureUnknown
   */
  is_grouped?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureUnknownFeatureMetaTypeEnum {
  UnknownFeature = "UnknownFeature",
}

/**
 *
 * @export
 * @interface SchemaFeatureWidth
 */
export interface SchemaFeatureWidth {
  /**
   *
   * @type {FeatureType}
   * @memberof SchemaFeatureWidth
   */
  type: FeatureType
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureWidth
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureWidth
   */
  command_index?: number
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureWidth
   */
  location?: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof SchemaFeatureWidth
   */
  axis?: Array<number>
  /**
   *
   * @type {string}
   * @memberof SchemaFeatureWidth
   */
  feature_meta_type: SchemaFeatureWidthFeatureMetaTypeEnum
  /**
   *
   * @type {FeatOrigin}
   * @memberof SchemaFeatureWidth
   */
  feature_origin?: FeatOrigin
  /**
   *
   * @type {Array<string>}
   * @memberof SchemaFeatureWidth
   */
  sub_features?: Array<string>
  /**
   *
   * @type {SchemaHits}
   * @memberof SchemaFeatureWidth
   */
  hits?: SchemaHits
  /**
   *
   * @type {number}
   * @memberof SchemaFeatureWidth
   */
  width?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SchemaFeatureWidthFeatureMetaTypeEnum {
  Width = "Width",
}

/**
 *
 * @export
 * @interface SchemaField
 */
export interface SchemaField {
  /**
   *
   * @type {string}
   * @memberof SchemaField
   */
  date?: string
  /**
   *
   * @type {MeasSource}
   * @memberof SchemaField
   */
  source?: MeasSource
  /**
   *
   * @type {boolean}
   * @memberof SchemaField
   */
  is_inspection_data: boolean
  /**
   *
   * @type {string}
   * @memberof SchemaField
   */
  machine?: string
  /**
   *
   * @type {string}
   * @memberof SchemaField
   */
  customer?: string
  /**
   *
   * @type {string}
   * @memberof SchemaField
   */
  sku?: string
  /**
   *
   * @type {string}
   * @memberof SchemaField
   */
  unit?: string
  /**
   *
   * @type {SchemaProgram}
   * @memberof SchemaField
   */
  program?: SchemaProgram
  /**
   *
   * @type {SchemaPlan}
   * @memberof SchemaField
   */
  plan?: SchemaPlan
  /**
   *
   * @type {AppModelsInspectionSchemaGeneratedInspectionSchemaTransform}
   * @memberof SchemaField
   */
  cad_transform?: AppModelsInspectionSchemaGeneratedInspectionSchemaTransform
  /**
   *
   * @type {Array<SchemaAlignment>}
   * @memberof SchemaField
   */
  alignments: Array<SchemaAlignment>
  /**
   *
   * @type {Array<SchemaConstructedFeature | SchemaFeature | SchemaFeatureCircle | SchemaFeatureConstructedCircle | SchemaFeatureCone | SchemaFeatureConstructedBestFitCylinder | SchemaFeatureCylinder | SchemaFeatureLineSegment | SchemaFeatureOffsetLine | SchemaFeatureSlot | SchemaFeatureSphere | SchemaFeatureConstructedSphere | SchemaFeatureUnknown | SchemaFeatureWidth>}
   * @memberof SchemaField
   */
  features: Array<
    | SchemaConstructedFeature
    | SchemaFeature
    | SchemaFeatureCircle
    | SchemaFeatureConstructedCircle
    | SchemaFeatureCone
    | SchemaFeatureConstructedBestFitCylinder
    | SchemaFeatureCylinder
    | SchemaFeatureLineSegment
    | SchemaFeatureOffsetLine
    | SchemaFeatureSlot
    | SchemaFeatureSphere
    | SchemaFeatureConstructedSphere
    | SchemaFeatureUnknown
    | SchemaFeatureWidth
  >
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof SchemaField
   */
  datum_map: { [key: string]: Array<string> }
  /**
   *
   * @type {Array<SchemaDim>}
   * @memberof SchemaField
   */
  dims: Array<SchemaDim>
  /**
   *
   * @type {SchemaCmmResult}
   * @memberof SchemaField
   */
  cmm_result: SchemaCmmResult
}
/**
 *
 * @export
 * @interface SchemaGdt
 */
export interface SchemaGdt {
  /**
   *
   * @type {string}
   * @memberof SchemaGdt
   */
  unicode?: string
  /**
   *
   * @type {GdtFont}
   * @memberof SchemaGdt
   */
  font?: GdtFont
}
/**
 *
 * @export
 * @interface SchemaHits
 */
export interface SchemaHits {
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof SchemaHits
   */
  xyz?: Array<Array<number>>
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof SchemaHits
   */
  ijk?: Array<Array<number>>
  /**
   *
   * @type {HitType}
   * @memberof SchemaHits
   */
  hit_type?: HitType
}
/**
 *
 * @export
 * @interface SchemaPlan
 */
export interface SchemaPlan {
  /**
   *
   * @type {string}
   * @memberof SchemaPlan
   */
  op?: string
  /**
   *
   * @type {string}
   * @memberof SchemaPlan
   */
  version?: string
  /**
   *
   * @type {string}
   * @memberof SchemaPlan
   */
  uri?: string
}
/**
 *
 * @export
 * @interface SchemaProgram
 */
export interface SchemaProgram {
  /**
   *
   * @type {MeasSource}
   * @memberof SchemaProgram
   */
  type?: MeasSource
  /**
   *
   * @type {string}
   * @memberof SchemaProgram
   */
  uri?: string
}
/**
 *
 * @export
 * @interface SchemaShape
 */
export interface SchemaShape {
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  centre?: string
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  location?: string
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  offset?: string
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  points?: string
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  rotate_angle?: string
  /**
   *
   * @type {string}
   * @memberof SchemaShape
   */
  zoom?: string
}
/**
 *
 * @export
 * @interface SchemaValue
 */
export interface SchemaValue {
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  nominal?: number
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  upper_tol?: number
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  lower_tol?: number
  /**
   *
   * @type {string}
   * @memberof SchemaValue
   */
  axis?: string
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  deviation?: number
  /**
   *
   * @type {number}
   * @memberof SchemaValue
   */
  out_tol?: number
  /**
   *
   * @type {boolean}
   * @memberof SchemaValue
   */
  is_out_tol?: boolean
}
/**
 *
 * @export
 * @interface SectionCutterCompResult
 */
export interface SectionCutterCompResult {
  /**
   *
   * @type {string}
   * @memberof SectionCutterCompResult
   */
  sectionId: string
  /**
   *
   * @type {Array<ProbingIssue>}
   * @memberof SectionCutterCompResult
   */
  issues: Array<ProbingIssue>
  /**
   *
   * @type {Array<StepCutterCompResult>}
   * @memberof SectionCutterCompResult
   */
  stepResults: Array<StepCutterCompResult>
}
/**
 * See https://www.miteebite.com/products/series-9-clamps/ for explanation of field values, other fields that may be worth tracking, and actual values for specific products  All values should be millimeters, whether the source config lists millimeters or inches  The produced models are centered in the y axis, expect z=0 to be the surface plane of the milled fixture, and expect to contact the rest stock in the xz base plane.
 * @export
 * @interface Series9Clamp
 */
export interface Series9Clamp {
  /**
   *
   * @type {string}
   * @memberof Series9Clamp
   */
  kind: Series9ClampKindEnum
}

/**
 * @export
 * @enum {string}
 */
export enum Series9ClampKindEnum {
  Series9 = "series_9",
}

/**
 *
 * @export
 * @interface SerratedJawsParams
 */
export interface SerratedJawsParams {
  /**
   *
   * @type {string}
   * @memberof SerratedJawsParams
   */
  kind: SerratedJawsParamsKindEnum
  /**
   *
   * @type {Vector}
   * @memberof SerratedJawsParams
   */
  extents: Vector
}

/**
 * @export
 * @enum {string}
 */
export enum SerratedJawsParamsKindEnum {
  SerratedJaws = "serrated_jaws",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ShippingMethod {
  EarlyAmOvernight = "early_am_overnight",
  NextDayAir = "next_day_air",
  SecondDayAir = "second_day_air",
  Ground = "ground",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ShippingOptionType {
  Pickup = "pickup",
  CustomersShippingAccount = "customers_shipping_account",
  SuppliersShippingAccount = "suppliers_shipping_account",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SimulationKind {
  CutInputStock = "CutInputStock",
  ProbeInputStock = "ProbeInputStock",
  ProbeOutputStock = "ProbeOutputStock",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SimulationMode {
  Default = "Default",
  Analyze = "Analyze",
  Optimize = "Optimize",
  Archive = "Archive",
}

/**
 *
 * @export
 * @interface SimulationUpload
 */
export interface SimulationUpload {
  /**
   *
   * @type {string}
   * @memberof SimulationUpload
   */
  kind: SimulationUploadKindEnum
  /**
   *
   * @type {string}
   * @memberof SimulationUpload
   */
  basePath: string
  /**
   *
   * @type {string}
   * @memberof SimulationUpload
   */
  folderPath?: string
  /**
   *
   * @type {number}
   * @memberof SimulationUpload
   */
  simulationNumber: number
  /**
   *
   * @type {boolean}
   * @memberof SimulationUpload
   */
  isModel?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SimulationUploadKindEnum {
  Simulation = "simulation",
}

/**
 *
 * @export
 * @interface SizeResult
 */
export interface SizeResult {
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  nominal?: number
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  upper_tol?: number
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  lower_tol?: number
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  deviation?: number
  /**
   *
   * @type {number}
   * @memberof SizeResult
   */
  out_tol?: number
  /**
   *
   * @type {boolean}
   * @memberof SizeResult
   */
  is_out_tol?: boolean
  /**
   *
   * @type {string}
   * @memberof SizeResult
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof SizeResult
   */
  axis?: string
  /**
   *
   * @type {SizeSubResult}
   * @memberof SizeResult
   */
  lower?: SizeSubResult
  /**
   *
   * @type {SizeSubResult}
   * @memberof SizeResult
   */
  upper?: SizeSubResult
}
/**
 *
 * @export
 * @interface SizeSubResult
 */
export interface SizeSubResult {
  /**
   *
   * @type {number}
   * @memberof SizeSubResult
   */
  deviation?: number
  /**
   *
   * @type {number}
   * @memberof SizeSubResult
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof SizeSubResult
   */
  out_tol?: number
  /**
   *
   * @type {string}
   * @memberof SizeSubResult
   */
  text?: string
}
/**
 *
 * @export
 * @interface SlackWebhook
 */
export interface SlackWebhook {
  /**
   *
   * @type {string}
   * @memberof SlackWebhook
   */
  hook_id: string
  /**
   *
   * @type {Array<string | boolean | number | Array<any> | object>}
   * @memberof SlackWebhook
   */
  blocks: Array<string | boolean | number | Array<any> | object>
}
/**
 *
 * @export
 * @interface SlackWebhookResponse
 */
export interface SlackWebhookResponse {
  /**
   *
   * @type {boolean}
   * @memberof SlackWebhookResponse
   */
  is_error: boolean
  /**
   *
   * @type {number}
   * @memberof SlackWebhookResponse
   */
  status_code: number
  /**
   *
   * @type {string | boolean | number | Array<any> | object}
   * @memberof SlackWebhookResponse
   */
  content?: string | boolean | number | Array<any> | object
}
/**
 *
 * @export
 * @interface SoftJawsParams
 */
export interface SoftJawsParams {
  /**
   *
   * @type {string}
   * @memberof SoftJawsParams
   */
  kind: SoftJawsParamsKindEnum
  /**
   *
   * @type {Vector}
   * @memberof SoftJawsParams
   */
  extents: Vector
}

/**
 * @export
 * @enum {string}
 */
export enum SoftJawsParamsKindEnum {
  SoftJaws = "soft_jaws",
}

/**
 *
 * @export
 * @interface SourceFeature
 */
export interface SourceFeature {
  /**
   *
   * @type {AlignmentItemKind}
   * @memberof SourceFeature
   */
  kind: AlignmentItemKind
  /**
   *
   * @type {RtcpPosition}
   * @memberof SourceFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof SourceFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof SourceFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof SourceFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof SourceFeature
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof SourceFeature
   */
  nPoints?: number
  /**
   *
   * @type {SphereFeatureT}
   * @memberof SourceFeature
   */
  t?: SphereFeatureT
}
/**
 *
 * @export
 * @interface SphereFeature
 */
export interface SphereFeature {
  /**
   *
   * @type {string}
   * @memberof SphereFeature
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof SphereFeature
   */
  kind: SphereFeatureKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof SphereFeature
   */
  position: RtcpPosition
  /**
   *
   * @type {RtcpPosition}
   * @memberof SphereFeature
   */
  approach: RtcpPosition
  /**
   *
   * @type {number}
   * @memberof SphereFeature
   */
  approachDistance?: number
  /**
   *
   * @type {number}
   * @memberof SphereFeature
   */
  searchDistance?: number
  /**
   *
   * @type {number}
   * @memberof SphereFeature
   */
  diameter: number
  /**
   *
   * @type {SphereFeatureT}
   * @memberof SphereFeature
   */
  t: SphereFeatureT
  /**
   *
   * @type {ProbingTouchType}
   * @memberof SphereFeature
   */
  touchType?: ProbingTouchType
  /**
   *
   * @type {Tolerances}
   * @memberof SphereFeature
   */
  tolerances?: Tolerances
  /**
   *
   * @type {PointCompensations}
   * @memberof SphereFeature
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {number}
   * @memberof SphereFeature
   */
  featureWeight?: number
}

/**
 * @export
 * @enum {string}
 */
export enum SphereFeatureKindEnum {
  SphereFeature = "sphere_feature",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SphereFeatureT {
  NUMBER_9 = 9,
  NUMBER_13 = 13,
  NUMBER_25 = 25,
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum StageEnum {
  Plan = "Plan",
  Setup = "Setup",
  Probing = "Probing",
  PreSimulation = "PreSimulation",
  Simulation = "Simulation",
  PostSimulation = "PostSimulation",
}

/**
 * Used to update the cutter comp data in probing steps
 * @export
 * @interface StepCutterCompResult
 */
export interface StepCutterCompResult {
  /**
   *
   * @type {string}
   * @memberof StepCutterCompResult
   */
  stepId: string
  /**
   *
   * @type {PointCompensations}
   * @memberof StepCutterCompResult
   */
  pointCompensations?: PointCompensations
  /**
   *
   * @type {Array<Compensation>}
   * @memberof StepCutterCompResult
   */
  computedCompensations?: Array<Compensation>
  /**
   *
   * @type {Array<Compensation>}
   * @memberof StepCutterCompResult
   */
  configuredCompensations?: Array<Compensation>
  /**
   *
   * @type {Array<number>}
   * @memberof StepCutterCompResult
   */
  gcodeLines?: Array<number>
}
/**
 *
 * @export
 * @interface StockAnalysisToolPathData
 */
export interface StockAnalysisToolPathData {
  /**
   *
   * @type {Array<number>}
   * @memberof StockAnalysisToolPathData
   */
  cut_number: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockAnalysisToolPathData
   */
  gcode_line: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockAnalysisToolPathData
   */
  tool_change: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockAnalysisToolPathData
   */
  tool_position: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockAnalysisToolPathData
   */
  tool_axis: Array<number>
  /**
   *
   * @type {CutInfoToolPathData}
   * @memberof StockAnalysisToolPathData
   */
  cut_info?: CutInfoToolPathData
  /**
   *
   * @type {GougeEvent | HolderStockClashEvent}
   * @memberof StockAnalysisToolPathData
   */
  events?: GougeEvent | HolderStockClashEvent
  /**
   *
   * @type {ParametricStockSimInfo}
   * @memberof StockAnalysisToolPathData
   */
  parametric_info?: ParametricStockSimInfo
  /**
   *
   * @type {CamDataCoordinateSystem}
   * @memberof StockAnalysisToolPathData
   */
  coordinate_system?: CamDataCoordinateSystem
}
/**
 *
 * @export
 * @interface StockCutData
 */
export interface StockCutData {
  /**
   *
   * @type {Array<number>}
   * @memberof StockCutData
   */
  cut_number: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockCutData
   */
  gcode_line: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockCutData
   */
  tool_change: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockCutData
   */
  tool_axis: Array<number>
}
/**
 *
 * @export
 * @interface StockGenerationConfig
 */
export interface StockGenerationConfig {
  /**
   *
   * @type {number}
   * @memberof StockGenerationConfig
   */
  initialTopPadding?: number
  /**
   *
   * @type {number}
   * @memberof StockGenerationConfig
   */
  initialBottomPadding?: number
}
/**
 * pad_min : Additional padding in the -x, -y, and -z directions. (It is added to the stock tolerance.) pad_max : Additional padding in the +x, +y, and +z directions. (It is added to the stock tolerance.) offset_proportions : If there is a space of points where the stock can be centered while meeting all constraints,     this vector is used to determine the specific location used. All coordinates should be between -1 and 1,     and represent the placement of the part center within the viable placement region. For example, the x     coordinate of this vector should be equal to the desired value of:         (part_center.x - viable_region.center.x) / (viable_region.max.x - viable_region.min.x)     (Vector(x=0, y=0, z=1) is probably the best default choice for this argument.) min_stock_extent : Specifies the minimum extents of the chosen stock, generally for compatibility with     fixtures that have a minimum clamping size. A coordinate with value None means no bound.     Round stocks should fully contain a rectangular box of the specified size. max_stock_extent : Specifies the maximum extents of the chosen stock, generally to prevent the stock     from being unclampable or extending too far beyond the edge of a specified fixture. None means no bound.     Round stocks should be fully contained in a rectangular box of the specified size.
 * @export
 * @interface StockGenerationConstraints
 */
export interface StockGenerationConstraints {
  /**
   *
   * @type {Vector}
   * @memberof StockGenerationConstraints
   */
  padMin: Vector
  /**
   *
   * @type {Vector}
   * @memberof StockGenerationConstraints
   */
  padMax: Vector
  /**
   *
   * @type {Vector}
   * @memberof StockGenerationConstraints
   */
  offsetProportions: Vector
  /**
   *
   * @type {PartialVector}
   * @memberof StockGenerationConstraints
   */
  minStockExtent: PartialVector
  /**
   *
   * @type {PartialVector}
   * @memberof StockGenerationConstraints
   */
  maxStockExtent: PartialVector
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum StockKind {
  ESTIMATED = "ESTIMATED",
  SIMULATED = "SIMULATED",
}

/**
 *
 * @export
 * @interface StockMesh
 */
export interface StockMesh {
  /**
   *
   * @type {Array<number>}
   * @memberof StockMesh
   */
  vertices: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockMesh
   */
  faces: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof StockMesh
   */
  cut_number: Array<number>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum StockShapeRestriction {
  Square = "square",
  Round = "round",
  None = "none",
}

/**
 *
 * @export
 * @interface StockSimSurfaceCone
 */
export interface StockSimSurfaceCone {
  /**
   *
   * @type {string}
   * @memberof StockSimSurfaceCone
   */
  kind: StockSimSurfaceConeKindEnum
  /**
   *
   * @type {Point}
   * @memberof StockSimSurfaceCone
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof StockSimSurfaceCone
   */
  axis: Direction
  /**
   *
   * @type {number}
   * @memberof StockSimSurfaceCone
   */
  halfAngle: number
}

/**
 * @export
 * @enum {string}
 */
export enum StockSimSurfaceConeKindEnum {
  Cone = "cone",
}

/**
 *
 * @export
 * @interface StockSimSurfaceCylinder
 */
export interface StockSimSurfaceCylinder {
  /**
   *
   * @type {string}
   * @memberof StockSimSurfaceCylinder
   */
  kind: StockSimSurfaceCylinderKindEnum
  /**
   *
   * @type {Point}
   * @memberof StockSimSurfaceCylinder
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof StockSimSurfaceCylinder
   */
  axis: Direction
  /**
   *
   * @type {number}
   * @memberof StockSimSurfaceCylinder
   */
  radius: number
}

/**
 * @export
 * @enum {string}
 */
export enum StockSimSurfaceCylinderKindEnum {
  Cylinder = "cylinder",
}

/**
 *
 * @export
 * @interface StockSimSurfacePlane
 */
export interface StockSimSurfacePlane {
  /**
   *
   * @type {string}
   * @memberof StockSimSurfacePlane
   */
  kind: StockSimSurfacePlaneKindEnum
  /**
   *
   * @type {number}
   * @memberof StockSimSurfacePlane
   */
  a: number
  /**
   *
   * @type {number}
   * @memberof StockSimSurfacePlane
   */
  b: number
  /**
   *
   * @type {number}
   * @memberof StockSimSurfacePlane
   */
  c: number
  /**
   *
   * @type {number}
   * @memberof StockSimSurfacePlane
   */
  d: number
}

/**
 * @export
 * @enum {string}
 */
export enum StockSimSurfacePlaneKindEnum {
  Plane = "plane",
}

/**
 *
 * @export
 * @interface StockSimSurfaceSphere
 */
export interface StockSimSurfaceSphere {
  /**
   *
   * @type {string}
   * @memberof StockSimSurfaceSphere
   */
  kind: StockSimSurfaceSphereKindEnum
  /**
   *
   * @type {Point}
   * @memberof StockSimSurfaceSphere
   */
  position: Point
  /**
   *
   * @type {number}
   * @memberof StockSimSurfaceSphere
   */
  radius: number
}

/**
 * @export
 * @enum {string}
 */
export enum StockSimSurfaceSphereKindEnum {
  Sphere = "sphere",
}

/**
 *
 * @export
 * @interface StockSimSurfaceTorus
 */
export interface StockSimSurfaceTorus {
  /**
   *
   * @type {string}
   * @memberof StockSimSurfaceTorus
   */
  kind: StockSimSurfaceTorusKindEnum
  /**
   *
   * @type {Point}
   * @memberof StockSimSurfaceTorus
   */
  position: Point
  /**
   *
   * @type {Direction}
   * @memberof StockSimSurfaceTorus
   */
  axis: Direction
  /**
   *
   * @type {number}
   * @memberof StockSimSurfaceTorus
   */
  rMinor: number
  /**
   *
   * @type {number}
   * @memberof StockSimSurfaceTorus
   */
  rMajor: number
}

/**
 * @export
 * @enum {string}
 */
export enum StockSimSurfaceTorusKindEnum {
  Torus = "torus",
}

/**
 * This type should be generic over params, but I don\'t want to muck about with pydantic generic types
 * @export
 * @interface StockSupport
 */
export interface StockSupport {
  /**
   *
   * @type {string}
   * @memberof StockSupport
   */
  point: string
  /**
   *
   * @type {PartialVector}
   * @memberof StockSupport
   */
  movement?: PartialVector
  /**
   *
   * @type {ClampedDovetailParams | CustomFixturePlateParams | FlatJawsParams | PrismStockClampingParams | RoundStockClampingParams | SerratedJawsParams | SoftJawsParams}
   * @memberof StockSupport
   */
  params:
    | ClampedDovetailParams
    | CustomFixturePlateParams
    | FlatJawsParams
    | PrismStockClampingParams
    | RoundStockClampingParams
    | SerratedJawsParams
    | SoftJawsParams
}
/**
 *
 * @export
 * @interface StocksConfig
 */
export interface StocksConfig {
  /**
   *
   * @type {Array<FixtureStockRecord>}
   * @memberof StocksConfig
   */
  stocks: Array<FixtureStockRecord>
  /**
   *
   * @type {Array<MaterialRecord>}
   * @memberof StocksConfig
   */
  materials: Array<MaterialRecord>
}
/**
 *
 * @export
 * @interface SuccessFeature
 */
export interface SuccessFeature {
  /**
   *
   * @type {string}
   * @memberof SuccessFeature
   */
  feature_name: string
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof SuccessFeature
   */
  feature_to_part: AppModelsGeometryTransform
  /**
   *
   * @type {Array<Point>}
   * @memberof SuccessFeature
   */
  points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof SuccessFeature
   */
  directions: Array<Direction>
  /**
   *
   * @type {Array<Point>}
   * @memberof SuccessFeature
   */
  unit_points: Array<Point>
  /**
   *
   * @type {Array<Direction>}
   * @memberof SuccessFeature
   */
  unit_directions: Array<Direction>
  /**
   *
   * @type {string}
   * @memberof SuccessFeature
   */
  result_type: SuccessFeatureResultTypeEnum
  /**
   *
   * @type {StockCutData}
   * @memberof SuccessFeature
   */
  stock_cut_data: StockCutData
  /**
   *
   * @type {number}
   * @memberof SuccessFeature
   */
  tool_id: number
}

/**
 * @export
 * @enum {string}
 */
export enum SuccessFeatureResultTypeEnum {
  Success = "Success",
}

/**
 *
 * @export
 * @interface TIAxisData
 */
export interface TIAxisData {
  /**
   *
   * @type {number}
   * @memberof TIAxisData
   */
  nominal?: number
  /**
   *
   * @type {number}
   * @memberof TIAxisData
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof TIAxisData
   */
  deviation?: number
}
/**
 *
 * @export
 * @interface TagSelector
 */
export interface TagSelector {
  /**
   *
   * @type {string}
   * @memberof TagSelector
   */
  kind: TagSelectorKindEnum
  /**
   *
   * @type {string}
   * @memberof TagSelector
   */
  issueTag: string
}

/**
 * @export
 * @enum {string}
 */
export enum TagSelectorKindEnum {
  Tag = "tag",
}

/**
 *
 * @export
 * @interface TaggedMachinePosition
 */
export interface TaggedMachinePosition {
  /**
   *
   * @type {string}
   * @memberof TaggedMachinePosition
   */
  kind: TaggedMachinePositionKindEnum
  /**
   *
   * @type {Point}
   * @memberof TaggedMachinePosition
   */
  position: Point
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof TaggedMachinePosition
   */
  extraCoords: { [key: string]: number }
}

/**
 * @export
 * @enum {string}
 */
export enum TaggedMachinePositionKindEnum {
  Machine = "Machine",
}

/**
 *
 * @export
 * @interface TaggedRtcpPosition
 */
export interface TaggedRtcpPosition {
  /**
   *
   * @type {string}
   * @memberof TaggedRtcpPosition
   */
  kind: TaggedRtcpPositionKindEnum
  /**
   *
   * @type {RtcpPosition}
   * @memberof TaggedRtcpPosition
   */
  position: RtcpPosition
}

/**
 * @export
 * @enum {string}
 */
export enum TaggedRtcpPositionKindEnum {
  Rtcp = "Rtcp",
}

/**
 *
 * @export
 * @interface TaskData
 */
export interface TaskData {
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  taskId: string
}
/**
 *
 * @export
 * @interface TaskProgress
 */
export interface TaskProgress {
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  runId?: string
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  jobId?: string
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  planId?: string
  /**
   *
   * @type {number}
   * @memberof TaskProgress
   */
  operationIdx?: number
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof TaskProgress
   */
  progress?: number
  /**
   *
   * @type {TaskStatus}
   * @memberof TaskProgress
   */
  status: TaskStatus
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  message?: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TaskStatus {
  Pending = "Pending",
  Processing = "Processing",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

/**
 *
 * @export
 * @interface TestPatchOperation
 */
export interface TestPatchOperation {
  /**
   *
   * @type {string}
   * @memberof TestPatchOperation
   */
  op: TestPatchOperationOpEnum
  /**
   *
   * @type {string}
   * @memberof TestPatchOperation
   */
  path: string
  /**
   *
   * @type {string | boolean | number | Array<any> | object}
   * @memberof TestPatchOperation
   */
  value: string | boolean | number | Array<any> | object
}

/**
 * @export
 * @enum {string}
 */
export enum TestPatchOperationOpEnum {
  Test = "test",
}

/**
 *
 * @export
 * @interface TolResult
 */
export interface TolResult {
  /**
   *
   * @type {string}
   * @memberof TolResult
   */
  id?: string
  /**
   *
   * @type {ToleranceStatus}
   * @memberof TolResult
   */
  tolerance_status?: ToleranceStatus
}
/**
 *
 * @export
 * @interface ToleranceResult
 */
export interface ToleranceResult {
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  nominal?: number
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  upper_tol?: number
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  lower_tol?: number
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  measured?: number
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  deviation?: number
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  out_tol?: number
  /**
   *
   * @type {boolean}
   * @memberof ToleranceResult
   */
  is_out_tol?: boolean
  /**
   *
   * @type {string}
   * @memberof ToleranceResult
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  bonus?: number
  /**
   *
   * @type {string}
   * @memberof ToleranceResult
   */
  segment_axis?: string
  /**
   *
   * @type {number}
   * @memberof ToleranceResult
   */
  segment_index?: number
  /**
   *
   * @type {{ [key: string]: TIAxisData; }}
   * @memberof ToleranceResult
   */
  axes?: { [key: string]: TIAxisData }
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ToleranceStatus {
  MeasurementRequired = "MeasurementRequired",
  InTolerance = "InTolerance",
  OutOfTolerance = "OutOfTolerance",
}

/**
 *
 * @export
 * @interface Tolerances
 */
export interface Tolerances {
  /**
   *
   * @type {string}
   * @memberof Tolerances
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof Tolerances
   */
  descriptor?: string
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  pointUpper?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  pointLower?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  featureUpper?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  featureLower?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  featureAngularity?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  featurePosition?: number
  /**
   *
   * @type {number}
   * @memberof Tolerances
   */
  featureForm?: number
  /**
   *
   * @type {MspMaterialCondition}
   * @memberof Tolerances
   */
  materialCondition?: MspMaterialCondition
}
/**
 *
 * @export
 * @interface ToolChangeDescriptor
 */
export interface ToolChangeDescriptor {
  /**
   *
   * @type {number}
   * @memberof ToolChangeDescriptor
   */
  toolId: number
  /**
   *
   * @type {VericutToolChangeDetails}
   * @memberof ToolChangeDescriptor
   */
  vericutDetails?: VericutToolChangeDetails
}
/**
 *
 * @export
 * @interface ToolChangeWear
 */
export interface ToolChangeWear {
  /**
   *
   * @type {number}
   * @memberof ToolChangeWear
   */
  toolId: number
  /**
   *
   * @type {number}
   * @memberof ToolChangeWear
   */
  minutes: number
  /**
   *
   * @type {number}
   * @memberof ToolChangeWear
   */
  life: number
}
/**
 *
 * @export
 * @interface ToolChangeWearUpdate
 */
export interface ToolChangeWearUpdate {
  /**
   *
   * @type {number}
   * @memberof ToolChangeWearUpdate
   */
  toolId: number
  /**
   *
   * @type {number}
   * @memberof ToolChangeWearUpdate
   */
  minutes: number
  /**
   *
   * @type {number}
   * @memberof ToolChangeWearUpdate
   */
  life: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ToolMode {
  None = "None",
  Both = "Both",
  Length = "Length",
  Radius = "Radius",
}

/**
 *
 * @export
 * @interface ToolPathData
 */
export interface ToolPathData {
  /**
   *
   * @type {number}
   * @memberof ToolPathData
   */
  tool_change: number
  /**
   *
   * @type {StockMesh}
   * @memberof ToolPathData
   */
  stock_mesh: StockMesh
}
/**
 *
 * @export
 * @interface ToolpathProject
 */
export interface ToolpathProject {
  /**
   *
   * @type {string}
   * @memberof ToolpathProject
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ToolpathProject
   */
  ncFileLocator?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ToolpathProject
   */
  cuttingFileLocators?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ToolpathProject
   */
  documentFileLocator?: string
  /**
   *
   * @type {{ [key: string]: ToolMode; }}
   * @memberof ToolpathProject
   */
  toolsettingConfig?: { [key: string]: ToolMode }
  /**
   *
   * @type {CutConfig}
   * @memberof ToolpathProject
   */
  cutConfig?: CutConfig
  /**
   *
   * @type {boolean}
   * @memberof ToolpathProject
   */
  disableCbt?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ToolpathProject
   */
  enableGrobToolchecks?: boolean
  /**
   *
   * @type {Array<ToolChangeWear>}
   * @memberof ToolpathProject
   */
  toolWear?: Array<ToolChangeWear>
  /**
   *
   * @type {Array<ToolChangeWear>}
   * @memberof ToolpathProject
   */
  toolLifetimes?: Array<ToolChangeWear>
  /**
   *
   * @type {boolean}
   * @memberof ToolpathProject
   */
  enableMachineOffsets?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ToolpathProject
   */
  enableMachineOffsetsLogging?: boolean
}
/**
 *
 * @export
 * @interface ToolsWearUpdate
 */
export interface ToolsWearUpdate {
  /**
   *
   * @type {Array<ToolChangeDescriptor>}
   * @memberof ToolsWearUpdate
   */
  toolChangeDescriptors: Array<ToolChangeDescriptor>
  /**
   *
   * @type {Array<ToolChangeWearUpdate>}
   * @memberof ToolsWearUpdate
   */
  inputs: Array<ToolChangeWearUpdate>
}
/**
 *
 * @export
 * @interface Toolset
 */
export interface Toolset {
  /**
   *
   * @type {string}
   * @memberof Toolset
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof Toolset
   */
  toolId: number
  /**
   *
   * @type {number}
   * @memberof Toolset
   */
  toolRadius: number
  /**
   *
   * @type {LaserToolsetting}
   * @memberof Toolset
   */
  laser: LaserToolsetting
  /**
   *
   * @type {CuttingBasedRadius}
   * @memberof Toolset
   */
  cuttingBasedRadius?: CuttingBasedRadius
  /**
   *
   * @type {CuttingBasedLength}
   * @memberof Toolset
   */
  cuttingBasedLength?: CuttingBasedLength
}
/**
 *
 * @export
 * @interface Toolsetting
 */
export interface Toolsetting {
  /**
   *
   * @type {string}
   * @memberof Toolsetting
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Toolsetting
   */
  kind: ToolsettingKindEnum
  /**
   *
   * @type {Array<Toolset>}
   * @memberof Toolsetting
   */
  tools: Array<Toolset>
  /**
   *
   * @type {number}
   * @memberof Toolsetting
   */
  stockXExtent?: number
  /**
   *
   * @type {number}
   * @memberof Toolsetting
   */
  probingFeed?: number
}

/**
 * @export
 * @enum {string}
 */
export enum ToolsettingKindEnum {
  Toolsetting = "Toolsetting",
}

/**
 *
 * @export
 * @interface ToolsettingConfig
 */
export interface ToolsettingConfig {
  /**
   *
   * @type {string}
   * @memberof ToolsettingConfig
   */
  fileLocator: string
  /**
   *
   * @type {{ [key: string]: ToolMode; }}
   * @memberof ToolsettingConfig
   */
  toolModes: { [key: string]: ToolMode }
  /**
   *
   * @type {boolean}
   * @memberof ToolsettingConfig
   */
  disableCbt: boolean
}
/**
 * See https://www.miteebite.com/products/uniforce-clamps/ for explanation of field values, other fields that may be worth tracking, and actual values for specific products  All values should be millimeters, whether the source config lists millimeters or inches  The produced models are centered in the y axis, expect z=0 to be the surface plane of the milled fixture, and expect to contact the rest stock in the xz base plane.
 * @export
 * @interface UniforceClamp
 */
export interface UniforceClamp {
  /**
   *
   * @type {string}
   * @memberof UniforceClamp
   */
  kind: UniforceClampKindEnum
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  a: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  b: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  b1: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  c: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  d: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  e: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  f: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  holeDiameter: number
  /**
   *
   * @type {number}
   * @memberof UniforceClamp
   */
  holeDepth: number
}

/**
 * @export
 * @enum {string}
 */
export enum UniforceClampKindEnum {
  UniforceClamp = "uniforce_clamp",
}

/**
 *
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
  /**
   *
   * @type {string}
   * @memberof UploadResponse
   */
  id: string
  /**
   *
   * @type {UploadedFileResponse}
   * @memberof UploadResponse
   */
  source: UploadedFileResponse
  /**
   *
   * @type {string}
   * @memberof UploadResponse
   */
  locator: string
}
/**
 *
 * @export
 * @interface UploadedFileResponse
 */
export interface UploadedFileResponse {
  /**
   *
   * @type {string}
   * @memberof UploadedFileResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UploadedFileResponse
   */
  locator: string
}
/**
 *
 * @export
 * @interface UploadedModelResponse
 */
export interface UploadedModelResponse {
  /**
   *
   * @type {string}
   * @memberof UploadedModelResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UploadedModelResponse
   */
  locator: string
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string | number>}
   * @memberof ValidationError
   */
  loc: Array<string | number>
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum VariableClass {
  Basic = "basic",
  DropDown = "drop_down",
  Table = "table",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum VariableValueType {
  String = "string",
  Number = "number",
  Currency = "currency",
  Boolean = "boolean",
}

/**
 *
 * @export
 * @interface Vector
 */
export interface Vector {
  /**
   *
   * @type {number}
   * @memberof Vector
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof Vector
   */
  y: number
  /**
   *
   * @type {number}
   * @memberof Vector
   */
  z: number
}
/**
 *
 * @export
 * @interface VericutErrorLine
 */
export interface VericutErrorLine {
  /**
   *
   * @type {number}
   * @memberof VericutErrorLine
   */
  lineNumber: number
  /**
   *
   * @type {string}
   * @memberof VericutErrorLine
   */
  error: string
}
/**
 *
 * @export
 * @interface VericutFeedback
 */
export interface VericutFeedback {
  /**
   *
   * @type {string}
   * @memberof VericutFeedback
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof VericutFeedback
   */
  taskId: string
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  operationIdx: number
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  revision: number
  /**
   *
   * @type {string}
   * @memberof VericutFeedback
   */
  logId?: string
  /**
   *
   * @type {string}
   * @memberof VericutFeedback
   */
  reportId?: string
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  errorCount?: number
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  warningCount?: number
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  totalSeconds?: number
  /**
   *
   * @type {number}
   * @memberof VericutFeedback
   */
  airtimeSeconds?: number
}
/**
 *
 * @export
 * @interface VericutLog
 */
export interface VericutLog {
  /**
   *
   * @type {number}
   * @memberof VericutLog
   */
  totalSeconds?: number
  /**
   *
   * @type {number}
   * @memberof VericutLog
   */
  airTime?: number
  /**
   *
   * @type {Array<VericutErrorLine>}
   * @memberof VericutLog
   */
  errors: Array<VericutErrorLine>
  /**
   *
   * @type {Array<VericutErrorLine>}
   * @memberof VericutLog
   */
  warnings: Array<VericutErrorLine>
  /**
   *
   * @type {Array<VericutToolChangeDetails>}
   * @memberof VericutLog
   */
  toolchangeDetails: Array<VericutToolChangeDetails>
}
/**
 *
 * @export
 * @interface VericutLogOutput
 */
export interface VericutLogOutput {
  /**
   *
   * @type {VericutLog}
   * @memberof VericutLogOutput
   */
  info?: VericutLog
  /**
   *
   * @type {VericutLogStatus}
   * @memberof VericutLogOutput
   */
  status: VericutLogStatus
}
/**
 *
 * @export
 * @interface VericutLogStatus
 */
export interface VericutLogStatus {
  /**
   *
   * @type {VericutTaskStatus}
   * @memberof VericutLogStatus
   */
  status: VericutTaskStatus
  /**
   *
   * @type {string}
   * @memberof VericutLogStatus
   */
  errorReason?: string
}
/**
 *
 * @export
 * @interface VericutManfiestOutput
 */
export interface VericutManfiestOutput {
  /**
   *
   * @type {string}
   * @memberof VericutManfiestOutput
   */
  projectLocator: string
  /**
   *
   * @type {VericutLogOutput}
   * @memberof VericutManfiestOutput
   */
  vericutLogOutput: VericutLogOutput
  /**
   *
   * @type {VericutSimRunParams}
   * @memberof VericutManfiestOutput
   */
  simRunDetails: VericutSimRunParams
  /**
   *
   * @type {Array<VericutSimOverride>}
   * @memberof VericutManfiestOutput
   */
  simOverrides: Array<VericutSimOverride>
}
/**
 *
 * @export
 * @interface VericutManifestData
 */
export interface VericutManifestData {
  /**
   *
   * @type {VericutManfiestOutput}
   * @memberof VericutManifestData
   */
  vericut: VericutManfiestOutput
}
/**
 *
 * @export
 * @interface VericutManifestDataResponse
 */
export interface VericutManifestDataResponse {
  /**
   *
   * @type {VericutManifestData}
   * @memberof VericutManifestDataResponse
   */
  manifestData?: VericutManifestData
  /**
   *
   * @type {VericutSimParams}
   * @memberof VericutManifestDataResponse
   */
  submissionData?: VericutSimParams
}
/**
 *
 * @export
 * @interface VericutNode
 */
export interface VericutNode {
  /**
   *
   * @type {string}
   * @memberof VericutNode
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof VericutNode
   */
  operationId: string
  /**
   *
   * @type {string}
   * @memberof VericutNode
   */
  invalidationReason?: string
}
/**
 *
 * @export
 * @interface VericutSimOverride
 */
export interface VericutSimOverride {
  /**
   *
   * @type {number}
   * @memberof VericutSimOverride
   */
  lineNumber: number
  /**
   *
   * @type {string}
   * @memberof VericutSimOverride
   */
  content: string
}
/**
 *
 * @export
 * @interface VericutSimParams
 */
export interface VericutSimParams {
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  job_id: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  plan_id: string
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  operation_idx: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  revision: number
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof VericutSimParams
   */
  wcs: AppModelsGeometryTransform
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof VericutSimParams
   */
  part: AppModelsGeometryTransform
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof VericutSimParams
   */
  stock: AppModelsGeometryTransform
  /**
   *
   * @type {Array<OperationFixtureBody>}
   * @memberof VericutSimParams
   */
  fixtures: Array<OperationFixtureBody>
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  part_locator?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  stock_locator: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  nc_program_locator: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  document_locator?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  machine_id: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  stock_id?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  material_id: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  feedback_id: string
  /**
   *
   * @type {Array<string>}
   * @memberof VericutSimParams
   */
  probe_program_locators: Array<string>
  /**
   *
   * @type {{ [key: string]: ProbeRecord; }}
   * @memberof VericutSimParams
   */
  probes?: { [key: string]: ProbeRecord }
  /**
   *
   * @type {boolean}
   * @memberof VericutSimParams
   */
  skip_update_stock: boolean
  /**
   *
   * @type {SimulationMode}
   * @memberof VericutSimParams
   */
  simulation_mode: SimulationMode
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  simulation_number: number
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  holder_version?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  tool_library_version?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  controller_version?: string
  /**
   *
   * @type {string}
   * @memberof VericutSimParams
   */
  machine_version?: string
  /**
   *
   * @type {Wsec}
   * @memberof VericutSimParams
   */
  wsec?: Wsec
  /**
   *
   * @type {boolean}
   * @memberof VericutSimParams
   */
  enable_vcreview: boolean
  /**
   *
   * @type {boolean}
   * @memberof VericutSimParams
   */
  include_gcode_text_log: boolean
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  cutting_resolution: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  holder_stock_near_miss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  holder_fixture_near_miss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  cutter_fixture_near_miss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  shank_fixture_near_miss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  shank_stock_dist: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  model_tolerance: number
  /**
   *
   * @type {number}
   * @memberof VericutSimParams
   */
  interpolation_tolerance: number
}
/**
 *
 * @export
 * @interface VericutSimRunParams
 */
export interface VericutSimRunParams {
  /**
   *
   * @type {Wsec}
   * @memberof VericutSimRunParams
   */
  wsec?: Wsec
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  cuttingResolution: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  interpolationTolerance: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  modelTolerance: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  holderStockNearMiss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  holderFixtureNearMiss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  cutterFixtureNearMiss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  shankFixtureNearMiss: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  shankStockDist: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  shankPlungeTolerance: number
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  tolerance: number
  /**
   *
   * @type {boolean}
   * @memberof VericutSimRunParams
   */
  excess: boolean
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  minimum: number
  /**
   *
   * @type {boolean}
   * @memberof VericutSimRunParams
   */
  gouge: boolean
  /**
   *
   * @type {number}
   * @memberof VericutSimRunParams
   */
  maxErrors: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum VericutTaskStatus {
  Pending = "Pending",
  Processing = "Processing",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

/**
 *
 * @export
 * @interface VericutToolChangeDetails
 */
export interface VericutToolChangeDetails {
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  airTime: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  cutDistance: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  cutterHeight: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  cutterStickout: number
  /**
   *
   * @type {string}
   * @memberof VericutToolChangeDetails
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  distance: number
  /**
   *
   * @type {string}
   * @memberof VericutToolChangeDetails
   */
  ncLine: string
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  record: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  seq: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  time: number
  /**
   *
   * @type {number}
   * @memberof VericutToolChangeDetails
   */
  tool: number
}
/**
 *
 * @export
 * @interface WcsBoreBoss
 */
export interface WcsBoreBoss {
  /**
   *
   * @type {string}
   * @memberof WcsBoreBoss
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsBoreBoss
   */
  kind: WcsBoreBossKindEnum
  /**
   *
   * @type {Point}
   * @memberof WcsBoreBoss
   */
  start: Point
  /**
   *
   * @type {number}
   * @memberof WcsBoreBoss
   */
  diameter: number
  /**
   *
   * @type {number}
   * @memberof WcsBoreBoss
   */
  nIterations?: number
  /**
   *
   * @type {WcsBossWebParams}
   * @memberof WcsBoreBoss
   */
  boss?: WcsBossWebParams
}

/**
 * @export
 * @enum {string}
 */
export enum WcsBoreBossKindEnum {
  BoreBoss = "bore_boss",
}

/**
 *
 * @export
 * @interface WcsBossWebParams
 */
export interface WcsBossWebParams {
  /**
   *
   * @type {number}
   * @memberof WcsBossWebParams
   */
  depth: number
  /**
   *
   * @type {number}
   * @memberof WcsBossWebParams
   */
  clearance: number
}
/**
 *
 * @export
 * @interface WcsEdgeSurface
 */
export interface WcsEdgeSurface {
  /**
   *
   * @type {string}
   * @memberof WcsEdgeSurface
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsEdgeSurface
   */
  kind: WcsEdgeSurfaceKindEnum
  /**
   *
   * @type {Point}
   * @memberof WcsEdgeSurface
   */
  contact: Point
  /**
   *
   * @type {number}
   * @memberof WcsEdgeSurface
   */
  offset: number
  /**
   *
   * @type {WcsSurfaceDirection}
   * @memberof WcsEdgeSurface
   */
  direction: WcsSurfaceDirection
}

/**
 * @export
 * @enum {string}
 */
export enum WcsEdgeSurfaceKindEnum {
  EdgeSurface = "edge_surface",
}

/**
 *
 * @export
 * @interface WcsProbing
 */
export interface WcsProbing {
  /**
   *
   * @type {string}
   * @memberof WcsProbing
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsProbing
   */
  kind: WcsProbingKindEnum
  /**
   *
   * @type {Array<EmptyToolchange | ProbeToolchange | ReferencePositionMove | WcsBoreBoss | WcsEdgeSurface | WcsProbingMove | WcsSpecifyCoords | WcsWebPocket>}
   * @memberof WcsProbing
   */
  steps: Array<
    | EmptyToolchange
    | ProbeToolchange
    | ReferencePositionMove
    | WcsBoreBoss
    | WcsEdgeSurface
    | WcsProbingMove
    | WcsSpecifyCoords
    | WcsWebPocket
  >
  /**
   *
   * @type {number}
   * @memberof WcsProbing
   */
  workOffsetNumber?: number
  /**
   *
   * @type {boolean}
   * @memberof WcsProbing
   */
  lockWcsPosition?: boolean
  /**
   *
   * @type {number}
   * @memberof WcsProbing
   */
  tiltOverride?: number
  /**
   *
   * @type {boolean}
   * @memberof WcsProbing
   */
  disabled?: boolean
  /**
   *
   * @type {Array<ProbingIssue>}
   * @memberof WcsProbing
   */
  issues?: Array<ProbingIssue>
}

/**
 * @export
 * @enum {string}
 */
export enum WcsProbingKindEnum {
  WcsProbing = "WcsProbing",
}

/**
 *
 * @export
 * @interface WcsProbingMove
 */
export interface WcsProbingMove {
  /**
   *
   * @type {string}
   * @memberof WcsProbingMove
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsProbingMove
   */
  kind: WcsProbingMoveKindEnum
  /**
   *
   * @type {Point}
   * @memberof WcsProbingMove
   */
  point: Point
  /**
   *
   * @type {boolean}
   * @memberof WcsProbingMove
   */
  generated?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum WcsProbingMoveKindEnum {
  WcsExplicitMove = "wcs_explicit_move",
}

/**
 *
 * @export
 * @interface WcsSpecifyCoords
 */
export interface WcsSpecifyCoords {
  /**
   *
   * @type {string}
   * @memberof WcsSpecifyCoords
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsSpecifyCoords
   */
  kind: WcsSpecifyCoordsKindEnum
  /**
   *
   * @type {number}
   * @memberof WcsSpecifyCoords
   */
  x?: number
  /**
   *
   * @type {number}
   * @memberof WcsSpecifyCoords
   */
  y?: number
  /**
   *
   * @type {number}
   * @memberof WcsSpecifyCoords
   */
  z?: number
  /**
   *
   * @type {boolean}
   * @memberof WcsSpecifyCoords
   */
  incremental?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum WcsSpecifyCoordsKindEnum {
  SpecifyCoords = "specify_coords",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WcsStrategy {
  CURRENT = "CURRENT",
  AUTO = "AUTO",
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WcsSurfaceDirection {
  XPos = "x_pos",
  XNeg = "x_neg",
  YPos = "y_pos",
  YNeg = "y_neg",
  ZNeg = "z_neg",
}

/**
 *
 * @export
 * @interface WcsWebPocket
 */
export interface WcsWebPocket {
  /**
   *
   * @type {string}
   * @memberof WcsWebPocket
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WcsWebPocket
   */
  kind: WcsWebPocketKindEnum
  /**
   *
   * @type {Point}
   * @memberof WcsWebPocket
   */
  start: Point
  /**
   *
   * @type {number}
   * @memberof WcsWebPocket
   */
  xSize?: number
  /**
   *
   * @type {number}
   * @memberof WcsWebPocket
   */
  ySize?: number
  /**
   *
   * @type {WcsBossWebParams}
   * @memberof WcsWebPocket
   */
  web?: WcsBossWebParams
}

/**
 * @export
 * @enum {string}
 */
export enum WcsWebPocketKindEnum {
  WebPocket = "web_pocket",
}

/**
 *
 * @export
 * @interface WintoolVericutResponse
 */
export interface WintoolVericutResponse {
  /**
   *
   * @type {string}
   * @memberof WintoolVericutResponse
   */
  locator: string
  /**
   *
   * @type {string}
   * @memberof WintoolVericutResponse
   */
  content: string
}
/**
 *
 * @export
 * @interface WorkPlaneTransform
 */
export interface WorkPlaneTransform {
  /**
   *
   * @type {GcodeLineRange}
   * @memberof WorkPlaneTransform
   */
  gcode_line_range: GcodeLineRange
  /**
   *
   * @type {AppModelsGeometryTransform}
   * @memberof WorkPlaneTransform
   */
  transform: AppModelsGeometryTransform
}
/**
 *
 * @export
 * @interface Wsec
 */
export interface Wsec {
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  y: number
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  z: number
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  a: number
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  b: number
  /**
   *
   * @type {number}
   * @memberof Wsec
   */
  c: number
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Log Action
     * @param {string} action
     * @param {{ [key: string]: string; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logAction: async (
      action: string,
      requestBody?: { [key: string]: string },
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'action' is not null or undefined
      if (action === null || action === undefined) {
        throw new RequiredError(
          "action",
          "Required parameter action was null or undefined when calling logAction."
        )
      }
      const localVarPath = `/api/v1/analytics/event`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (action !== undefined) {
        localVarQueryParameter["action"] = action
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof requestBody !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Log Action
     * @param {string} action
     * @param {{ [key: string]: string; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logAction(
      action: string,
      requestBody?: { [key: string]: string },
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AnalyticsApiAxiosParamCreator(configuration).logAction(
        action,
        requestBody,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Log Action
     * @param {string} action
     * @param {{ [key: string]: string; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logAction(
      action: string,
      requestBody?: { [key: string]: string },
      options?: any
    ): AxiosPromise<void> {
      return AnalyticsApiFp(configuration)
        .logAction(action, requestBody, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
  /**
   *
   * @summary Log Action
   * @param {string} action
   * @param {{ [key: string]: string; }} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalyticsApi
   */
  public logAction(action: string, requestBody?: { [key: string]: string }, options?: any) {
    return AnalyticsApiFp(this.configuration)
      .logAction(action, requestBody, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_logAction(action: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/analytics/event`)
    const localVarQueryParameter = {} as any
    if (action !== undefined) {
      localVarQueryParameter["action"] = action
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearCache: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/cache-clear`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStockMaterial: async (
      materialRecord: MaterialRecord,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'materialRecord' is not null or undefined
      if (materialRecord === null || materialRecord === undefined) {
        throw new RequiredError(
          "materialRecord",
          "Required parameter materialRecord was null or undefined when calling createStockMaterial."
        )
      }
      const localVarPath = `/api/v1/config/stock-materials`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof materialRecord !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialRecord !== undefined ? materialRecord : {})
        : materialRecord || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Stock Material
     * @param {string} materialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStockMaterial: async (materialId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'materialId' is not null or undefined
      if (materialId === null || materialId === undefined) {
        throw new RequiredError(
          "materialId",
          "Required parameter materialId was null or undefined when calling deleteStockMaterial."
        )
      }
      const localVarPath = `/api/v1/config/stock-materials/{material_id}`.replace(
        `{${"material_id"}}`,
        encodeURIComponent(String(materialId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Fixtures Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesConfig: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/fixtures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Fixtures Config Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesConfigOld: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/fixtures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Fixtures Yaml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesYaml: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/fixtures-yaml`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Machines Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMachinesConfig: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/machines`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Probes Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProbesConfig: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/probes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Product Stocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStocks: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/product-stocks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     * Need to deploy updated vericut worker before deleting this endpoint
     * @summary Get Product Stocks Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStocksOld: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/config/product-stocks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Fixtures Yaml
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFixturesYaml: async (body: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateFixturesYaml."
        )
      }
      const localVarPath = `/api/v1/config/fixtures-yaml`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStockMaterial: async (
      materialRecord: MaterialRecord,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'materialRecord' is not null or undefined
      if (materialRecord === null || materialRecord === undefined) {
        throw new RequiredError(
          "materialRecord",
          "Required parameter materialRecord was null or undefined when calling updateStockMaterial."
        )
      }
      const localVarPath = `/api/v1/config/stock-materials`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof materialRecord !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialRecord !== undefined ? materialRecord : {})
        : materialRecord || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearCache(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).clearCache(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStockMaterial(
      materialRecord: MaterialRecord,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).createStockMaterial(
        materialRecord,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Delete Stock Material
     * @param {string} materialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStockMaterial(
      materialId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).deleteStockMaterial(
        materialId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Fixtures Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFixturesConfig(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FixturesConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getFixturesConfig(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Fixtures Config Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFixturesConfigOld(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FixturesConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(
        configuration
      ).getFixturesConfigOld(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Fixtures Yaml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFixturesYaml(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getFixturesYaml(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Machines Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMachinesConfig(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachinesConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getMachinesConfig(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Probes Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProbesConfig(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProbesConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getProbesConfig(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Product Stocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductStocks(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocksConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getProductStocks(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Need to deploy updated vericut worker before deleting this endpoint
     * @summary Get Product Stocks Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductStocksOld(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocksConfig>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).getProductStocksOld(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Fixtures Yaml
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFixturesYaml(
      body: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).updateFixturesYaml(
        body,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStockMaterial(
      materialRecord: MaterialRecord,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).updateStockMaterial(
        materialRecord,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Clear Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearCache(options?: any): AxiosPromise<void> {
      return ConfigApiFp(configuration)
        .clearCache(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStockMaterial(materialRecord: MaterialRecord, options?: any): AxiosPromise<void> {
      return ConfigApiFp(configuration)
        .createStockMaterial(materialRecord, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Stock Material
     * @param {string} materialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStockMaterial(materialId: string, options?: any): AxiosPromise<void> {
      return ConfigApiFp(configuration)
        .deleteStockMaterial(materialId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Fixtures Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesConfig(options?: any): AxiosPromise<FixturesConfig> {
      return ConfigApiFp(configuration)
        .getFixturesConfig(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Fixtures Config Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesConfigOld(options?: any): AxiosPromise<FixturesConfig> {
      return ConfigApiFp(configuration)
        .getFixturesConfigOld(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Fixtures Yaml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFixturesYaml(options?: any): AxiosPromise<string> {
      return ConfigApiFp(configuration)
        .getFixturesYaml(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Machines Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMachinesConfig(options?: any): AxiosPromise<MachinesConfig> {
      return ConfigApiFp(configuration)
        .getMachinesConfig(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Probes Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProbesConfig(options?: any): AxiosPromise<ProbesConfig> {
      return ConfigApiFp(configuration)
        .getProbesConfig(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Product Stocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStocks(options?: any): AxiosPromise<StocksConfig> {
      return ConfigApiFp(configuration)
        .getProductStocks(options)
        .then(request => request(axios, basePath))
    },
    /**
     * Need to deploy updated vericut worker before deleting this endpoint
     * @summary Get Product Stocks Old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStocksOld(options?: any): AxiosPromise<StocksConfig> {
      return ConfigApiFp(configuration)
        .getProductStocksOld(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Fixtures Yaml
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFixturesYaml(body: string, options?: any): AxiosPromise<string> {
      return ConfigApiFp(configuration)
        .updateFixturesYaml(body, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Stock Material
     * @param {MaterialRecord} materialRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStockMaterial(materialRecord: MaterialRecord, options?: any): AxiosPromise<void> {
      return ConfigApiFp(configuration)
        .updateStockMaterial(materialRecord, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
  /**
   *
   * @summary Clear Cache
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public clearCache(options?: any) {
    return ConfigApiFp(this.configuration)
      .clearCache(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_clearCache(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/cache-clear`)
  }

  /**
   *
   * @summary Create Stock Material
   * @param {MaterialRecord} materialRecord
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public createStockMaterial(materialRecord: MaterialRecord, options?: any) {
    return ConfigApiFp(this.configuration)
      .createStockMaterial(materialRecord, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createStockMaterial(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/stock-materials`)
  }

  /**
   *
   * @summary Delete Stock Material
   * @param {string} materialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public deleteStockMaterial(materialId: string, options?: any) {
    return ConfigApiFp(this.configuration)
      .deleteStockMaterial(materialId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_deleteStockMaterial(materialId: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/v1/config/stock-materials/{material_id}`.replace(
        `{${"material_id"}}`,
        encodeURIComponent(String(materialId))
      )
    )
  }

  /**
   *
   * @summary Get Fixtures Config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getFixturesConfig(options?: any) {
    return ConfigApiFp(this.configuration)
      .getFixturesConfig(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getFixturesConfig(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/fixtures`)
  }

  /**
   *
   * @summary Get Fixtures Config Old
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getFixturesConfigOld(options?: any) {
    return ConfigApiFp(this.configuration)
      .getFixturesConfigOld(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getFixturesConfigOld(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/fixtures`)
  }

  /**
   *
   * @summary Get Fixtures Yaml
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getFixturesYaml(options?: any) {
    return ConfigApiFp(this.configuration)
      .getFixturesYaml(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getFixturesYaml(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/fixtures-yaml`)
  }

  /**
   *
   * @summary Get Machines Config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getMachinesConfig(options?: any) {
    return ConfigApiFp(this.configuration)
      .getMachinesConfig(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getMachinesConfig(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/machines`)
  }

  /**
   *
   * @summary Get Probes Config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getProbesConfig(options?: any) {
    return ConfigApiFp(this.configuration)
      .getProbesConfig(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getProbesConfig(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/probes`)
  }

  /**
   *
   * @summary Get Product Stocks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getProductStocks(options?: any) {
    return ConfigApiFp(this.configuration)
      .getProductStocks(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getProductStocks(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/product-stocks`)
  }

  /**
   * Need to deploy updated vericut worker before deleting this endpoint
   * @summary Get Product Stocks Old
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getProductStocksOld(options?: any) {
    return ConfigApiFp(this.configuration)
      .getProductStocksOld(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getProductStocksOld(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/product-stocks`)
  }

  /**
   *
   * @summary Update Fixtures Yaml
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public updateFixturesYaml(body: string, options?: any) {
    return ConfigApiFp(this.configuration)
      .updateFixturesYaml(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateFixturesYaml(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/fixtures-yaml`)
  }

  /**
   *
   * @summary Update Stock Material
   * @param {MaterialRecord} materialRecord
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public updateStockMaterial(materialRecord: MaterialRecord, options?: any) {
    return ConfigApiFp(this.configuration)
      .updateStockMaterial(materialRecord, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateStockMaterial(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/config/stock-materials`)
  }
}

/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Docs Proxy
     * @param {string} [redirectPath]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docsProxy: async (redirectPath?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/docs/proxy`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (redirectPath !== undefined) {
        localVarQueryParameter["redirect_path"] = redirectPath
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Docs Proxy
     * @param {string} [redirectPath]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docsProxy(
      redirectPath?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DocsApiAxiosParamCreator(configuration).docsProxy(
        redirectPath,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Docs Proxy
     * @param {string} [redirectPath]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docsProxy(redirectPath?: string, options?: any): AxiosPromise<string> {
      return DocsApiFp(configuration)
        .docsProxy(redirectPath, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI {
  /**
   *
   * @summary Docs Proxy
   * @param {string} [redirectPath]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocsApi
   */
  public docsProxy(redirectPath?: string, options?: any) {
    return DocsApiFp(this.configuration)
      .docsProxy(redirectPath, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_docsProxy(redirectPath?: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/docs/proxy`)
    const localVarQueryParameter = {} as any
    if (redirectPath !== undefined) {
      localVarQueryParameter["redirect_path"] = redirectPath
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Document File
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} documentFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocumentFile: async (
      planId: string,
      operationIdx: number,
      documentFile: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createDocumentFile."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling createDocumentFile."
        )
      }
      // verify required parameter 'documentFile' is not null or undefined
      if (documentFile === null || documentFile === undefined) {
        throw new RequiredError(
          "documentFile",
          "Required parameter documentFile was null or undefined when calling createDocumentFile."
        )
      }
      const localVarPath = `/api/v1/files/document-file`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (documentFile !== undefined) {
        localVarFormParams.append("document_file", documentFile as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Job Backup
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJobBackup: async (
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling createJobBackup."
        )
      }
      const localVarPath = `/api/v1/files/backup`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (includeJobFiles !== undefined) {
        localVarQueryParameter["include_job_files"] = includeJobFiles
      }

      if (includeManifests !== undefined) {
        localVarQueryParameter["include_manifests"] = includeManifests
      }

      if (includeVericutReportFiles !== undefined) {
        localVarQueryParameter["include_vericut_report_files"] = includeVericutReportFiles
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {any} [documentFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNcProgram: async (
      planId: string,
      operationIdx: number,
      file: any,
      documentFile?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createNcProgram."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling createNcProgram."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling createNcProgram."
        )
      }
      const localVarPath = `/api/v1/files/nc-program`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarFormParams.append("plan_id", planId as any)
      }

      if (operationIdx !== undefined) {
        localVarFormParams.append("operation_idx", operationIdx as any)
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      if (documentFile !== undefined) {
        localVarFormParams.append("document_file", documentFile as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Plan Backup
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlanBackup: async (planId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createPlanBackup."
        )
      }
      const localVarPath = `/api/v1/files/backup-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create User Model
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserModel: async (file: any, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling createUserModel."
        )
      }
      const localVarPath = `/api/v1/files/user-model`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Machine Body Model
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMachineBodyModel: async (key: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      if (key === null || key === undefined) {
        throw new RequiredError(
          "key",
          "Required parameter key was null or undefined when calling getMachineBodyModel."
        )
      }
      const localVarPath = `/api/v1/files/machine-body-model/{key}`.replace(
        `{${"key"}}`,
        encodeURIComponent(String(key))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Manifest Uris
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManifestUris: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling getManifestUris."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling getManifestUris."
        )
      }
      const localVarPath = `/api/v1/files/manifest-uris`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Import Vericut Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importVericutProject: async (
      planId: string,
      operationIdx: number,
      file: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling importVericutProject."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling importVericutProject."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling importVericutProject."
        )
      }
      const localVarPath = `/api/v1/files/vericut-project`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary New File From Url
     * @param {string} downloadUrl
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newFileFromUrl: async (
      downloadUrl: string,
      jobId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'downloadUrl' is not null or undefined
      if (downloadUrl === null || downloadUrl === undefined) {
        throw new RequiredError(
          "downloadUrl",
          "Required parameter downloadUrl was null or undefined when calling newFileFromUrl."
        )
      }
      const localVarPath = `/api/v1/files/file-from-url`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (downloadUrl !== undefined) {
        localVarQueryParameter["download_url"] = downloadUrl
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     * NOTE: This function should NOT be used to upload an updated model for an existing part. Instead, see new_model_for_job below.
     * @summary New Model
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModel: async (file: any, internal?: boolean, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling newModel."
        )
      }
      const localVarPath = `/api/v1/files/model`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (internal !== undefined) {
        localVarQueryParameter["internal"] = internal
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary New Model For Job
     * @param {string} jobId
     * @param {any} file
     * @param {boolean} [modelIsAligned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModelForJob: async (
      jobId: string,
      file: any,
      modelIsAligned?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling newModelForJob."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling newModelForJob."
        )
      }
      const localVarPath = `/api/v1/files/model-for-job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (modelIsAligned !== undefined) {
        localVarQueryParameter["model_is_aligned"] = modelIsAligned
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary New Model From Url
     * @param {string} downloadUrl
     * @param {string} [fileName]
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModelFromUrl: async (
      downloadUrl: string,
      fileName?: string,
      internal?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'downloadUrl' is not null or undefined
      if (downloadUrl === null || downloadUrl === undefined) {
        throw new RequiredError(
          "downloadUrl",
          "Required parameter downloadUrl was null or undefined when calling newModelFromUrl."
        )
      }
      const localVarPath = `/api/v1/files/model-from-url`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (downloadUrl !== undefined) {
        localVarQueryParameter["download_url"] = downloadUrl
      }

      if (fileName !== undefined) {
        localVarQueryParameter["file_name"] = fileName
      }

      if (internal !== undefined) {
        localVarQueryParameter["internal"] = internal
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Restore Job Backup
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreJobBackup: async (file: any, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling restoreJobBackup."
        )
      }
      const localVarPath = `/api/v1/files/restore`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Restore Plan Backup
     * @param {string} jobId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restorePlanBackup: async (
      jobId: string,
      file: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling restorePlanBackup."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling restorePlanBackup."
        )
      }
      const localVarPath = `/api/v1/files/restore-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Document File
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} documentFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDocumentFile(
      planId: string,
      operationIdx: number,
      documentFile: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).createDocumentFile(
        planId,
        operationIdx,
        documentFile,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Job Backup
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createJobBackup(
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).createJobBackup(
        jobId,
        includeJobFiles,
        includeManifests,
        includeVericutReportFiles,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {any} [documentFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNcProgram(
      planId: string,
      operationIdx: number,
      file: any,
      documentFile?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).createNcProgram(
        planId,
        operationIdx,
        file,
        documentFile,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Plan Backup
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPlanBackup(
      planId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).createPlanBackup(
        planId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create User Model
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserModel(
      file: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedModelResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).createUserModel(
        file,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Machine Body Model
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMachineBodyModel(
      key: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).getMachineBodyModel(
        key,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Manifest Uris
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManifestUris(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManifestURIs>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).getManifestUris(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Import Vericut Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importVericutProject(
      planId: string,
      operationIdx: number,
      file: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).importVericutProject(
        planId,
        operationIdx,
        file,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary New File From Url
     * @param {string} downloadUrl
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newFileFromUrl(
      downloadUrl: string,
      jobId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).newFileFromUrl(
        downloadUrl,
        jobId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * NOTE: This function should NOT be used to upload an updated model for an existing part. Instead, see new_model_for_job below.
     * @summary New Model
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newModel(
      file: any,
      internal?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedModelResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).newModel(
        file,
        internal,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary New Model For Job
     * @param {string} jobId
     * @param {any} file
     * @param {boolean} [modelIsAligned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newModelForJob(
      jobId: string,
      file: any,
      modelIsAligned?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).newModelForJob(
        jobId,
        file,
        modelIsAligned,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary New Model From Url
     * @param {string} downloadUrl
     * @param {string} [fileName]
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newModelFromUrl(
      downloadUrl: string,
      fileName?: string,
      internal?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedModelResponse>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).newModelFromUrl(
        downloadUrl,
        fileName,
        internal,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Restore Job Backup
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreJobBackup(
      file: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).restoreJobBackup(
        file,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Restore Plan Backup
     * @param {string} jobId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restorePlanBackup(
      jobId: string,
      file: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).restorePlanBackup(
        jobId,
        file,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Create Document File
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} documentFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocumentFile(
      planId: string,
      operationIdx: number,
      documentFile: any,
      options?: any
    ): AxiosPromise<UploadedFileResponse> {
      return FilesApiFp(configuration)
        .createDocumentFile(planId, operationIdx, documentFile, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Job Backup
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJobBackup(
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return FilesApiFp(configuration)
        .createJobBackup(
          jobId,
          includeJobFiles,
          includeManifests,
          includeVericutReportFiles,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {any} [documentFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNcProgram(
      planId: string,
      operationIdx: number,
      file: any,
      documentFile?: any,
      options?: any
    ): AxiosPromise<UploadedFileResponse> {
      return FilesApiFp(configuration)
        .createNcProgram(planId, operationIdx, file, documentFile, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Plan Backup
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlanBackup(planId: string, options?: any): AxiosPromise<void> {
      return FilesApiFp(configuration)
        .createPlanBackup(planId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create User Model
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserModel(file: any, options?: any): AxiosPromise<UploadedModelResponse> {
      return FilesApiFp(configuration)
        .createUserModel(file, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Machine Body Model
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMachineBodyModel(key: string, options?: any): AxiosPromise<void> {
      return FilesApiFp(configuration)
        .getMachineBodyModel(key, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Manifest Uris
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManifestUris(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<ManifestURIs> {
      return FilesApiFp(configuration)
        .getManifestUris(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Import Vericut Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importVericutProject(
      planId: string,
      operationIdx: number,
      file: any,
      options?: any
    ): AxiosPromise<UploadedFileResponse> {
      return FilesApiFp(configuration)
        .importVericutProject(planId, operationIdx, file, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary New File From Url
     * @param {string} downloadUrl
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newFileFromUrl(
      downloadUrl: string,
      jobId?: string,
      options?: any
    ): AxiosPromise<UploadedFileResponse> {
      return FilesApiFp(configuration)
        .newFileFromUrl(downloadUrl, jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     * NOTE: This function should NOT be used to upload an updated model for an existing part. Instead, see new_model_for_job below.
     * @summary New Model
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModel(file: any, internal?: boolean, options?: any): AxiosPromise<UploadedModelResponse> {
      return FilesApiFp(configuration)
        .newModel(file, internal, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary New Model For Job
     * @param {string} jobId
     * @param {any} file
     * @param {boolean} [modelIsAligned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModelForJob(
      jobId: string,
      file: any,
      modelIsAligned?: boolean,
      options?: any
    ): AxiosPromise<TaskData> {
      return FilesApiFp(configuration)
        .newModelForJob(jobId, file, modelIsAligned, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary New Model From Url
     * @param {string} downloadUrl
     * @param {string} [fileName]
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newModelFromUrl(
      downloadUrl: string,
      fileName?: string,
      internal?: boolean,
      options?: any
    ): AxiosPromise<UploadedModelResponse> {
      return FilesApiFp(configuration)
        .newModelFromUrl(downloadUrl, fileName, internal, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Restore Job Backup
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreJobBackup(file: any, options?: any): AxiosPromise<void> {
      return FilesApiFp(configuration)
        .restoreJobBackup(file, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Restore Plan Backup
     * @param {string} jobId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restorePlanBackup(jobId: string, file: any, options?: any): AxiosPromise<void> {
      return FilesApiFp(configuration)
        .restorePlanBackup(jobId, file, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @summary Create Document File
   * @param {string} planId
   * @param {number} operationIdx
   * @param {any} documentFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createDocumentFile(
    planId: string,
    operationIdx: number,
    documentFile: any,
    options?: any
  ) {
    return FilesApiFp(this.configuration)
      .createDocumentFile(planId, operationIdx, documentFile, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createDocumentFile(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/document-file`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Job Backup
   * @param {string} jobId
   * @param {boolean} [includeJobFiles]
   * @param {boolean} [includeManifests]
   * @param {boolean} [includeVericutReportFiles]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createJobBackup(
    jobId: string,
    includeJobFiles?: boolean,
    includeManifests?: boolean,
    includeVericutReportFiles?: boolean,
    options?: any
  ) {
    return FilesApiFp(this.configuration)
      .createJobBackup(jobId, includeJobFiles, includeManifests, includeVericutReportFiles, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createJobBackup(
    jobId: string,
    includeJobFiles?: boolean,
    includeManifests?: boolean,
    includeVericutReportFiles?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/backup`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (includeJobFiles !== undefined) {
      localVarQueryParameter["include_job_files"] = includeJobFiles
    }
    if (includeManifests !== undefined) {
      localVarQueryParameter["include_manifests"] = includeManifests
    }
    if (includeVericutReportFiles !== undefined) {
      localVarQueryParameter["include_vericut_report_files"] = includeVericutReportFiles
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Nc Program
   * @param {string} planId
   * @param {number} operationIdx
   * @param {any} file
   * @param {any} [documentFile]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createNcProgram(
    planId: string,
    operationIdx: number,
    file: any,
    documentFile?: any,
    options?: any
  ) {
    return FilesApiFp(this.configuration)
      .createNcProgram(planId, operationIdx, file, documentFile, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createNcProgram(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/files/nc-program`)
  }

  /**
   *
   * @summary Create Plan Backup
   * @param {string} planId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createPlanBackup(planId: string, options?: any) {
    return FilesApiFp(this.configuration)
      .createPlanBackup(planId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createPlanBackup(planId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/backup-plan`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create User Model
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createUserModel(file: any, options?: any) {
    return FilesApiFp(this.configuration)
      .createUserModel(file, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createUserModel(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/files/user-model`)
  }

  /**
   *
   * @summary Get Machine Body Model
   * @param {string} key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getMachineBodyModel(key: string, options?: any) {
    return FilesApiFp(this.configuration)
      .getMachineBodyModel(key, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getMachineBodyModel(key: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/v1/files/machine-body-model/{key}`.replace(
        `{${"key"}}`,
        encodeURIComponent(String(key))
      )
    )
  }

  /**
   *
   * @summary Get Manifest Uris
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getManifestUris(planId: string, operationIdx: number, options?: any) {
    return FilesApiFp(this.configuration)
      .getManifestUris(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getManifestUris(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/manifest-uris`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Import Vericut Project
   * @param {string} planId
   * @param {number} operationIdx
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public importVericutProject(planId: string, operationIdx: number, file: any, options?: any) {
    return FilesApiFp(this.configuration)
      .importVericutProject(planId, operationIdx, file, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_importVericutProject(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/vericut-project`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary New File From Url
   * @param {string} downloadUrl
   * @param {string} [jobId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public newFileFromUrl(downloadUrl: string, jobId?: string, options?: any) {
    return FilesApiFp(this.configuration)
      .newFileFromUrl(downloadUrl, jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newFileFromUrl(downloadUrl: string, jobId?: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/file-from-url`)
    const localVarQueryParameter = {} as any
    if (downloadUrl !== undefined) {
      localVarQueryParameter["download_url"] = downloadUrl
    }
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   * NOTE: This function should NOT be used to upload an updated model for an existing part. Instead, see new_model_for_job below.
   * @summary New Model
   * @param {any} file
   * @param {boolean} [internal]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public newModel(file: any, internal?: boolean, options?: any) {
    return FilesApiFp(this.configuration)
      .newModel(file, internal, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newModel(internal?: boolean): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/model`)
    const localVarQueryParameter = {} as any
    if (internal !== undefined) {
      localVarQueryParameter["internal"] = internal
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary New Model For Job
   * @param {string} jobId
   * @param {any} file
   * @param {boolean} [modelIsAligned]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public newModelForJob(jobId: string, file: any, modelIsAligned?: boolean, options?: any) {
    return FilesApiFp(this.configuration)
      .newModelForJob(jobId, file, modelIsAligned, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newModelForJob(jobId: string, modelIsAligned?: boolean): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/model-for-job`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (modelIsAligned !== undefined) {
      localVarQueryParameter["model_is_aligned"] = modelIsAligned
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary New Model From Url
   * @param {string} downloadUrl
   * @param {string} [fileName]
   * @param {boolean} [internal]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public newModelFromUrl(
    downloadUrl: string,
    fileName?: string,
    internal?: boolean,
    options?: any
  ) {
    return FilesApiFp(this.configuration)
      .newModelFromUrl(downloadUrl, fileName, internal, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newModelFromUrl(
    downloadUrl: string,
    fileName?: string,
    internal?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/model-from-url`)
    const localVarQueryParameter = {} as any
    if (downloadUrl !== undefined) {
      localVarQueryParameter["download_url"] = downloadUrl
    }
    if (fileName !== undefined) {
      localVarQueryParameter["file_name"] = fileName
    }
    if (internal !== undefined) {
      localVarQueryParameter["internal"] = internal
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Restore Job Backup
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public restoreJobBackup(file: any, options?: any) {
    return FilesApiFp(this.configuration)
      .restoreJobBackup(file, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_restoreJobBackup(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/files/restore`)
  }

  /**
   *
   * @summary Restore Plan Backup
   * @param {string} jobId
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public restorePlanBackup(jobId: string, file: any, options?: any) {
    return FilesApiFp(this.configuration)
      .restorePlanBackup(jobId, file, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_restorePlanBackup(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/files/restore-plan`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * GuardrailApi - axios parameter creator
 * @export
 */
export const GuardrailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Update Guardrail Test Case
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuardrailTestCase: async (
      planId: string,
      operationIdx: number,
      description: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateGuardrailTestCase."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateGuardrailTestCase."
        )
      }
      // verify required parameter 'description' is not null or undefined
      if (description === null || description === undefined) {
        throw new RequiredError(
          "description",
          "Required parameter description was null or undefined when calling updateGuardrailTestCase."
        )
      }
      const localVarPath = `/api/v1/guardrail/update-guardrail-test-case`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (description !== undefined) {
        localVarQueryParameter["description"] = description
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuardrailApi - functional programming interface
 * @export
 */
export const GuardrailApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Update Guardrail Test Case
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuardrailTestCase(
      planId: string,
      operationIdx: number,
      description: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await GuardrailApiAxiosParamCreator(
        configuration
      ).updateGuardrailTestCase(planId, operationIdx, description, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * GuardrailApi - factory interface
 * @export
 */
export const GuardrailApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Update Guardrail Test Case
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuardrailTestCase(
      planId: string,
      operationIdx: number,
      description: string,
      options?: any
    ): AxiosPromise<void> {
      return GuardrailApiFp(configuration)
        .updateGuardrailTestCase(planId, operationIdx, description, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * GuardrailApi - object-oriented interface
 * @export
 * @class GuardrailApi
 * @extends {BaseAPI}
 */
export class GuardrailApi extends BaseAPI {
  /**
   *
   * @summary Update Guardrail Test Case
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} description
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuardrailApi
   */
  public updateGuardrailTestCase(
    planId: string,
    operationIdx: number,
    description: string,
    options?: any
  ) {
    return GuardrailApiFp(this.configuration)
      .updateGuardrailTestCase(planId, operationIdx, description, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateGuardrailTestCase(
    planId: string,
    operationIdx: number,
    description: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/guardrail/update-guardrail-test-case`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (description !== undefined) {
      localVarQueryParameter["description"] = description
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * JiraApi - axios parameter creator
 * @export
 */
export const JiraApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Jira Client Data
     * @param {string} host
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJiraClientData: async (host: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'host' is not null or undefined
      if (host === null || host === undefined) {
        throw new RequiredError(
          "host",
          "Required parameter host was null or undefined when calling getJiraClientData."
        )
      }
      const localVarPath = `/api/v1/jira/jira-client-data`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (host !== undefined) {
        localVarQueryParameter["host"] = host
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Jira Token
     * @param {ProxiedJiraAuthRequest} proxiedJiraAuthRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJiraToken: async (
      proxiedJiraAuthRequest: ProxiedJiraAuthRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'proxiedJiraAuthRequest' is not null or undefined
      if (proxiedJiraAuthRequest === null || proxiedJiraAuthRequest === undefined) {
        throw new RequiredError(
          "proxiedJiraAuthRequest",
          "Required parameter proxiedJiraAuthRequest was null or undefined when calling getJiraToken."
        )
      }
      const localVarPath = `/api/v1/jira/jira-token`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof proxiedJiraAuthRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(proxiedJiraAuthRequest !== undefined ? proxiedJiraAuthRequest : {})
        : proxiedJiraAuthRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JiraApi - functional programming interface
 * @export
 */
export const JiraApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Jira Client Data
     * @param {string} host
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJiraClientData(
      host: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraClientData>> {
      const localVarAxiosArgs = await JiraApiAxiosParamCreator(configuration).getJiraClientData(
        host,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Jira Token
     * @param {ProxiedJiraAuthRequest} proxiedJiraAuthRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJiraToken(
      proxiedJiraAuthRequest: ProxiedJiraAuthRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraTokenResponse>> {
      const localVarAxiosArgs = await JiraApiAxiosParamCreator(configuration).getJiraToken(
        proxiedJiraAuthRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * JiraApi - factory interface
 * @export
 */
export const JiraApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get Jira Client Data
     * @param {string} host
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJiraClientData(host: string, options?: any): AxiosPromise<JiraClientData> {
      return JiraApiFp(configuration)
        .getJiraClientData(host, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Jira Token
     * @param {ProxiedJiraAuthRequest} proxiedJiraAuthRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJiraToken(
      proxiedJiraAuthRequest: ProxiedJiraAuthRequest,
      options?: any
    ): AxiosPromise<JiraTokenResponse> {
      return JiraApiFp(configuration)
        .getJiraToken(proxiedJiraAuthRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * JiraApi - object-oriented interface
 * @export
 * @class JiraApi
 * @extends {BaseAPI}
 */
export class JiraApi extends BaseAPI {
  /**
   *
   * @summary Get Jira Client Data
   * @param {string} host
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraApi
   */
  public getJiraClientData(host: string, options?: any) {
    return JiraApiFp(this.configuration)
      .getJiraClientData(host, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getJiraClientData(host: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jira/jira-client-data`)
    const localVarQueryParameter = {} as any
    if (host !== undefined) {
      localVarQueryParameter["host"] = host
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Jira Token
   * @param {ProxiedJiraAuthRequest} proxiedJiraAuthRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraApi
   */
  public getJiraToken(proxiedJiraAuthRequest: ProxiedJiraAuthRequest, options?: any) {
    return JiraApiFp(this.configuration)
      .getJiraToken(proxiedJiraAuthRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getJiraToken(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/jira/jira-token`)
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Duplicate
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicate: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling duplicate."
        )
      }
      const localVarPath = `/api/v1/jobs/duplicate-job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Purge
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purge: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling purge."
        )
      }
      const localVarPath = `/api/v1/jobs/purge-job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Job Drawing
     * @param {string} jobId
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobDrawing: async (
      jobId: string,
      drawingId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling updateJobDrawing."
        )
      }
      // verify required parameter 'drawingId' is not null or undefined
      if (drawingId === null || drawingId === undefined) {
        throw new RequiredError(
          "drawingId",
          "Required parameter drawingId was null or undefined when calling updateJobDrawing."
        )
      }
      const localVarPath = `/api/v1/jobs/update-drawing`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (drawingId !== undefined) {
        localVarQueryParameter["drawing_id"] = drawingId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Job Material
     * @param {string} jobId
     * @param {string} materialDisplayName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobMaterial: async (
      jobId: string,
      materialDisplayName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling updateJobMaterial."
        )
      }
      // verify required parameter 'materialDisplayName' is not null or undefined
      if (materialDisplayName === null || materialDisplayName === undefined) {
        throw new RequiredError(
          "materialDisplayName",
          "Required parameter materialDisplayName was null or undefined when calling updateJobMaterial."
        )
      }
      const localVarPath = `/api/v1/jobs/update-material`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (materialDisplayName !== undefined) {
        localVarQueryParameter["material_display_name"] = materialDisplayName
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Job Model
     * @param {string} jobId
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobModel: async (
      jobId: string,
      modelId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling updateJobModel."
        )
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          "modelId",
          "Required parameter modelId was null or undefined when calling updateJobModel."
        )
      }
      const localVarPath = `/api/v1/jobs/update-model`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (modelId !== undefined) {
        localVarQueryParameter["model_id"] = modelId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Job Notes
     * @param {string} jobId
     * @param {string} notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobNotes: async (
      jobId: string,
      notes: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling updateJobNotes."
        )
      }
      // verify required parameter 'notes' is not null or undefined
      if (notes === null || notes === undefined) {
        throw new RequiredError(
          "notes",
          "Required parameter notes was null or undefined when calling updateJobNotes."
        )
      }
      const localVarPath = `/api/v1/jobs/update-notes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Duplicate
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicate(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DuplicateJobResponse>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).duplicate(
        jobId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Purge
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purge(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).purge(jobId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Job Drawing
     * @param {string} jobId
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateJobDrawing(
      jobId: string,
      drawingId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).updateJobDrawing(
        jobId,
        drawingId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Job Material
     * @param {string} jobId
     * @param {string} materialDisplayName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateJobMaterial(
      jobId: string,
      materialDisplayName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).updateJobMaterial(
        jobId,
        materialDisplayName,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Job Model
     * @param {string} jobId
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateJobModel(
      jobId: string,
      modelId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).updateJobModel(
        jobId,
        modelId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Job Notes
     * @param {string} jobId
     * @param {string} notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateJobNotes(
      jobId: string,
      notes: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).updateJobNotes(
        jobId,
        notes,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Duplicate
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicate(jobId: string, options?: any): AxiosPromise<DuplicateJobResponse> {
      return JobsApiFp(configuration)
        .duplicate(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Purge
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purge(jobId: string, options?: any): AxiosPromise<void> {
      return JobsApiFp(configuration)
        .purge(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Job Drawing
     * @param {string} jobId
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobDrawing(jobId: string, drawingId: string, options?: any): AxiosPromise<void> {
      return JobsApiFp(configuration)
        .updateJobDrawing(jobId, drawingId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Job Material
     * @param {string} jobId
     * @param {string} materialDisplayName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobMaterial(
      jobId: string,
      materialDisplayName: string,
      options?: any
    ): AxiosPromise<void> {
      return JobsApiFp(configuration)
        .updateJobMaterial(jobId, materialDisplayName, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Job Model
     * @param {string} jobId
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobModel(jobId: string, modelId: string, options?: any): AxiosPromise<void> {
      return JobsApiFp(configuration)
        .updateJobModel(jobId, modelId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Job Notes
     * @param {string} jobId
     * @param {string} notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJobNotes(jobId: string, notes: string, options?: any): AxiosPromise<void> {
      return JobsApiFp(configuration)
        .updateJobNotes(jobId, notes, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary Duplicate
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public duplicate(jobId: string, options?: any) {
    return JobsApiFp(this.configuration)
      .duplicate(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_duplicate(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/duplicate-job`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Purge
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public purge(jobId: string, options?: any) {
    return JobsApiFp(this.configuration)
      .purge(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_purge(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/purge-job`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Job Drawing
   * @param {string} jobId
   * @param {string} drawingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public updateJobDrawing(jobId: string, drawingId: string, options?: any) {
    return JobsApiFp(this.configuration)
      .updateJobDrawing(jobId, drawingId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateJobDrawing(jobId: string, drawingId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/update-drawing`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (drawingId !== undefined) {
      localVarQueryParameter["drawing_id"] = drawingId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Job Material
   * @param {string} jobId
   * @param {string} materialDisplayName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public updateJobMaterial(jobId: string, materialDisplayName: string, options?: any) {
    return JobsApiFp(this.configuration)
      .updateJobMaterial(jobId, materialDisplayName, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateJobMaterial(jobId: string, materialDisplayName: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/update-material`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (materialDisplayName !== undefined) {
      localVarQueryParameter["material_display_name"] = materialDisplayName
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Job Model
   * @param {string} jobId
   * @param {string} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public updateJobModel(jobId: string, modelId: string, options?: any) {
    return JobsApiFp(this.configuration)
      .updateJobModel(jobId, modelId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateJobModel(jobId: string, modelId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/update-model`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (modelId !== undefined) {
      localVarQueryParameter["model_id"] = modelId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Job Notes
   * @param {string} jobId
   * @param {string} notes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public updateJobNotes(jobId: string, notes: string, options?: any) {
    return JobsApiFp(this.configuration)
      .updateJobNotes(jobId, notes, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateJobNotes(jobId: string, notes: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/jobs/update-notes`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (notes !== undefined) {
      localVarQueryParameter["notes"] = notes
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * MockApi - axios parameter creator
 * @export
 */
export const MockApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Field Tool Result
     * @param {FieldToolResult} fieldToolResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fieldToolResult: async (
      fieldToolResult: FieldToolResult,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fieldToolResult' is not null or undefined
      if (fieldToolResult === null || fieldToolResult === undefined) {
        throw new RequiredError(
          "fieldToolResult",
          "Required parameter fieldToolResult was null or undefined when calling fieldToolResult."
        )
      }
      const localVarPath = `/api/v1/mock/field-tool-result`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof fieldToolResult !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fieldToolResult !== undefined ? fieldToolResult : {})
        : fieldToolResult || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gcode Output
     * @param {GcodeOutput} gcodeOutput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gcodeOutput: async (gcodeOutput: GcodeOutput, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'gcodeOutput' is not null or undefined
      if (gcodeOutput === null || gcodeOutput === undefined) {
        throw new RequiredError(
          "gcodeOutput",
          "Required parameter gcodeOutput was null or undefined when calling gcodeOutput."
        )
      }
      const localVarPath = `/api/v1/mock/gcode_output`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof gcodeOutput !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(gcodeOutput !== undefined ? gcodeOutput : {})
        : gcodeOutput || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Machined Parametric Stock Sim Info
     * @param {MachinedParametricStockSimInfo} machinedParametricStockSimInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    machinedParametricStockSimInfo: async (
      machinedParametricStockSimInfo: MachinedParametricStockSimInfo,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'machinedParametricStockSimInfo' is not null or undefined
      if (machinedParametricStockSimInfo === null || machinedParametricStockSimInfo === undefined) {
        throw new RequiredError(
          "machinedParametricStockSimInfo",
          "Required parameter machinedParametricStockSimInfo was null or undefined when calling machinedParametricStockSimInfo."
        )
      }
      const localVarPath = `/api/v1/mock/machined-parametric-stock-sim-info`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof machinedParametricStockSimInfo !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            machinedParametricStockSimInfo !== undefined ? machinedParametricStockSimInfo : {}
          )
        : machinedParametricStockSimInfo || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Op Cam Feature Info
     * @param {OpCamFeatureInfo} opCamFeatureInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opCamFeatureInfo: async (
      opCamFeatureInfo: OpCamFeatureInfo,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'opCamFeatureInfo' is not null or undefined
      if (opCamFeatureInfo === null || opCamFeatureInfo === undefined) {
        throw new RequiredError(
          "opCamFeatureInfo",
          "Required parameter opCamFeatureInfo was null or undefined when calling opCamFeatureInfo."
        )
      }
      const localVarPath = `/api/v1/mock/op-cam-feature-info`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof opCamFeatureInfo !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(opCamFeatureInfo !== undefined ? opCamFeatureInfo : {})
        : opCamFeatureInfo || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Probing Cutter Comp Result
     * @param {ProbingCutterCompResult} probingCutterCompResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    probingCutterCompResult: async (
      probingCutterCompResult: ProbingCutterCompResult,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'probingCutterCompResult' is not null or undefined
      if (probingCutterCompResult === null || probingCutterCompResult === undefined) {
        throw new RequiredError(
          "probingCutterCompResult",
          "Required parameter probingCutterCompResult was null or undefined when calling probingCutterCompResult."
        )
      }
      const localVarPath = `/api/v1/mock/probing-cutter-comp-result`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof probingCutterCompResult !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(probingCutterCompResult !== undefined ? probingCutterCompResult : {})
        : probingCutterCompResult || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Schema Field
     * @param {SchemaField} schemaField
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schemaField: async (schemaField: SchemaField, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'schemaField' is not null or undefined
      if (schemaField === null || schemaField === undefined) {
        throw new RequiredError(
          "schemaField",
          "Required parameter schemaField was null or undefined when calling schemaField."
        )
      }
      const localVarPath = `/api/v1/mock/schema-field`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof schemaField !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(schemaField !== undefined ? schemaField : {})
        : schemaField || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Stock Analysis Tool Path Data
     * @param {StockAnalysisToolPathData} stockAnalysisToolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockAnalysisToolPathData: async (
      stockAnalysisToolPathData: StockAnalysisToolPathData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'stockAnalysisToolPathData' is not null or undefined
      if (stockAnalysisToolPathData === null || stockAnalysisToolPathData === undefined) {
        throw new RequiredError(
          "stockAnalysisToolPathData",
          "Required parameter stockAnalysisToolPathData was null or undefined when calling stockAnalysisToolPathData."
        )
      }
      const localVarPath = `/api/v1/mock/stock-analysis-tool-path-data`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof stockAnalysisToolPathData !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(stockAnalysisToolPathData !== undefined ? stockAnalysisToolPathData : {})
        : stockAnalysisToolPathData || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Tool Path Data
     * @param {ToolPathData} toolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolPathData: async (toolPathData: ToolPathData, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'toolPathData' is not null or undefined
      if (toolPathData === null || toolPathData === undefined) {
        throw new RequiredError(
          "toolPathData",
          "Required parameter toolPathData was null or undefined when calling toolPathData."
        )
      }
      const localVarPath = `/api/v1/mock/tool-path-data`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof toolPathData !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(toolPathData !== undefined ? toolPathData : {})
        : toolPathData || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MockApi - functional programming interface
 * @export
 */
export const MockApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Field Tool Result
     * @param {FieldToolResult} fieldToolResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fieldToolResult(
      fieldToolResult: FieldToolResult,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(configuration).fieldToolResult(
        fieldToolResult,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Gcode Output
     * @param {GcodeOutput} gcodeOutput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gcodeOutput(
      gcodeOutput: GcodeOutput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(configuration).gcodeOutput(
        gcodeOutput,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Machined Parametric Stock Sim Info
     * @param {MachinedParametricStockSimInfo} machinedParametricStockSimInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async machinedParametricStockSimInfo(
      machinedParametricStockSimInfo: MachinedParametricStockSimInfo,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(
        configuration
      ).machinedParametricStockSimInfo(machinedParametricStockSimInfo, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Op Cam Feature Info
     * @param {OpCamFeatureInfo} opCamFeatureInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opCamFeatureInfo(
      opCamFeatureInfo: OpCamFeatureInfo,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(configuration).opCamFeatureInfo(
        opCamFeatureInfo,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Probing Cutter Comp Result
     * @param {ProbingCutterCompResult} probingCutterCompResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async probingCutterCompResult(
      probingCutterCompResult: ProbingCutterCompResult,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(
        configuration
      ).probingCutterCompResult(probingCutterCompResult, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Schema Field
     * @param {SchemaField} schemaField
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schemaField(
      schemaField: SchemaField,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(configuration).schemaField(
        schemaField,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Stock Analysis Tool Path Data
     * @param {StockAnalysisToolPathData} stockAnalysisToolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockAnalysisToolPathData(
      stockAnalysisToolPathData: StockAnalysisToolPathData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(
        configuration
      ).stockAnalysisToolPathData(stockAnalysisToolPathData, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Tool Path Data
     * @param {ToolPathData} toolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async toolPathData(
      toolPathData: ToolPathData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await MockApiAxiosParamCreator(configuration).toolPathData(
        toolPathData,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * MockApi - factory interface
 * @export
 */
export const MockApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Field Tool Result
     * @param {FieldToolResult} fieldToolResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fieldToolResult(fieldToolResult: FieldToolResult, options?: any): AxiosPromise<any> {
      return MockApiFp(configuration)
        .fieldToolResult(fieldToolResult, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Gcode Output
     * @param {GcodeOutput} gcodeOutput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gcodeOutput(gcodeOutput: GcodeOutput, options?: any): AxiosPromise<any> {
      return MockApiFp(configuration)
        .gcodeOutput(gcodeOutput, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Machined Parametric Stock Sim Info
     * @param {MachinedParametricStockSimInfo} machinedParametricStockSimInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    machinedParametricStockSimInfo(
      machinedParametricStockSimInfo: MachinedParametricStockSimInfo,
      options?: any
    ): AxiosPromise<any> {
      return MockApiFp(configuration)
        .machinedParametricStockSimInfo(machinedParametricStockSimInfo, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Op Cam Feature Info
     * @param {OpCamFeatureInfo} opCamFeatureInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opCamFeatureInfo(opCamFeatureInfo: OpCamFeatureInfo, options?: any): AxiosPromise<any> {
      return MockApiFp(configuration)
        .opCamFeatureInfo(opCamFeatureInfo, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Probing Cutter Comp Result
     * @param {ProbingCutterCompResult} probingCutterCompResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    probingCutterCompResult(
      probingCutterCompResult: ProbingCutterCompResult,
      options?: any
    ): AxiosPromise<any> {
      return MockApiFp(configuration)
        .probingCutterCompResult(probingCutterCompResult, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Schema Field
     * @param {SchemaField} schemaField
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schemaField(schemaField: SchemaField, options?: any): AxiosPromise<any> {
      return MockApiFp(configuration)
        .schemaField(schemaField, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Stock Analysis Tool Path Data
     * @param {StockAnalysisToolPathData} stockAnalysisToolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockAnalysisToolPathData(
      stockAnalysisToolPathData: StockAnalysisToolPathData,
      options?: any
    ): AxiosPromise<any> {
      return MockApiFp(configuration)
        .stockAnalysisToolPathData(stockAnalysisToolPathData, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Tool Path Data
     * @param {ToolPathData} toolPathData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolPathData(toolPathData: ToolPathData, options?: any): AxiosPromise<any> {
      return MockApiFp(configuration)
        .toolPathData(toolPathData, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * MockApi - object-oriented interface
 * @export
 * @class MockApi
 * @extends {BaseAPI}
 */
export class MockApi extends BaseAPI {
  /**
   *
   * @summary Field Tool Result
   * @param {FieldToolResult} fieldToolResult
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public fieldToolResult(fieldToolResult: FieldToolResult, options?: any) {
    return MockApiFp(this.configuration)
      .fieldToolResult(fieldToolResult, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_fieldToolResult(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/field-tool-result`)
  }

  /**
   *
   * @summary Gcode Output
   * @param {GcodeOutput} gcodeOutput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public gcodeOutput(gcodeOutput: GcodeOutput, options?: any) {
    return MockApiFp(this.configuration)
      .gcodeOutput(gcodeOutput, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_gcodeOutput(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/gcode_output`)
  }

  /**
   *
   * @summary Machined Parametric Stock Sim Info
   * @param {MachinedParametricStockSimInfo} machinedParametricStockSimInfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public machinedParametricStockSimInfo(
    machinedParametricStockSimInfo: MachinedParametricStockSimInfo,
    options?: any
  ) {
    return MockApiFp(this.configuration)
      .machinedParametricStockSimInfo(machinedParametricStockSimInfo, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_machinedParametricStockSimInfo(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/machined-parametric-stock-sim-info`)
  }

  /**
   *
   * @summary Op Cam Feature Info
   * @param {OpCamFeatureInfo} opCamFeatureInfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public opCamFeatureInfo(opCamFeatureInfo: OpCamFeatureInfo, options?: any) {
    return MockApiFp(this.configuration)
      .opCamFeatureInfo(opCamFeatureInfo, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_opCamFeatureInfo(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/op-cam-feature-info`)
  }

  /**
   *
   * @summary Probing Cutter Comp Result
   * @param {ProbingCutterCompResult} probingCutterCompResult
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public probingCutterCompResult(probingCutterCompResult: ProbingCutterCompResult, options?: any) {
    return MockApiFp(this.configuration)
      .probingCutterCompResult(probingCutterCompResult, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_probingCutterCompResult(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/probing-cutter-comp-result`)
  }

  /**
   *
   * @summary Schema Field
   * @param {SchemaField} schemaField
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public schemaField(schemaField: SchemaField, options?: any) {
    return MockApiFp(this.configuration)
      .schemaField(schemaField, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_schemaField(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/schema-field`)
  }

  /**
   *
   * @summary Stock Analysis Tool Path Data
   * @param {StockAnalysisToolPathData} stockAnalysisToolPathData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public stockAnalysisToolPathData(
    stockAnalysisToolPathData: StockAnalysisToolPathData,
    options?: any
  ) {
    return MockApiFp(this.configuration)
      .stockAnalysisToolPathData(stockAnalysisToolPathData, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_stockAnalysisToolPathData(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/stock-analysis-tool-path-data`)
  }

  /**
   *
   * @summary Tool Path Data
   * @param {ToolPathData} toolPathData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MockApi
   */
  public toolPathData(toolPathData: ToolPathData, options?: any) {
    return MockApiFp(this.configuration)
      .toolPathData(toolPathData, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_toolPathData(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/mock/tool-path-data`)
  }
}

/**
 * PaperlessPartsApi - axios parameter creator
 * @export
 */
export const PaperlessPartsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear Orders Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOrdersCache: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/paperless-parts/orders/clear-cache`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Clear Quotes Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearQuotesCache: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/paperless-parts/quotes/clear-cache`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get New Order Numbers
     * @param {number} [lastOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewOrderNumbers: async (lastOrder?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/paperless-parts/orders/new`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (lastOrder !== undefined) {
        localVarQueryParameter["last_order"] = lastOrder
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get New Quote Numbers
     * @param {number} [lastQuote]
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewQuoteNumbers: async (
      lastQuote?: number,
      revision?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/paperless-parts/quotes/new`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (lastQuote !== undefined) {
        localVarQueryParameter["last_quote"] = lastQuote
      }

      if (revision !== undefined) {
        localVarQueryParameter["revision"] = revision
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Order Details
     * @param {OrderDetailsRequest} orderDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderDetails: async (
      orderDetailsRequest: OrderDetailsRequest,
      resetCache?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderDetailsRequest' is not null or undefined
      if (orderDetailsRequest === null || orderDetailsRequest === undefined) {
        throw new RequiredError(
          "orderDetailsRequest",
          "Required parameter orderDetailsRequest was null or undefined when calling getOrderDetails."
        )
      }
      const localVarPath = `/api/v1/paperless-parts/orders/details`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (resetCache !== undefined) {
        localVarQueryParameter["reset_cache"] = resetCache
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof orderDetailsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(orderDetailsRequest !== undefined ? orderDetailsRequest : {})
        : orderDetailsRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Part Library
     * @param {number} page
     * @param {string} [search]
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartLibrary: async (
      page: number,
      search?: string,
      ppToken?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError(
          "page",
          "Required parameter page was null or undefined when calling getPartLibrary."
        )
      }
      const localVarPath = `/api/v1/paperless-parts/part_library/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page
      }

      if (search !== undefined) {
        localVarQueryParameter["search"] = search
      }

      if (ppToken !== undefined) {
        localVarQueryParameter["pp_token"] = ppToken
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Part Library Technical Data Package
     * @param {string} uuid
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartLibraryTechnicalDataPackage: async (
      uuid: string,
      ppToken?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      if (uuid === null || uuid === undefined) {
        throw new RequiredError(
          "uuid",
          "Required parameter uuid was null or undefined when calling getPartLibraryTechnicalDataPackage."
        )
      }
      const localVarPath = `/api/v1/paperless-parts/part_library/technical_data_package/{uuid}`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (ppToken !== undefined) {
        localVarQueryParameter["pp_token"] = ppToken
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Quote Details
     * @param {QuoteDetailsRequest} quoteDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteDetails: async (
      quoteDetailsRequest: QuoteDetailsRequest,
      resetCache?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'quoteDetailsRequest' is not null or undefined
      if (quoteDetailsRequest === null || quoteDetailsRequest === undefined) {
        throw new RequiredError(
          "quoteDetailsRequest",
          "Required parameter quoteDetailsRequest was null or undefined when calling getQuoteDetails."
        )
      }
      const localVarPath = `/api/v1/paperless-parts/quotes/details`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (resetCache !== undefined) {
        localVarQueryParameter["reset_cache"] = resetCache
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof quoteDetailsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(quoteDetailsRequest !== undefined ? quoteDetailsRequest : {})
        : quoteDetailsRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PaperlessPartsApi - functional programming interface
 * @export
 */
export const PaperlessPartsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear Orders Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearOrdersCache(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).clearOrdersCache(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Clear Quotes Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearQuotesCache(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).clearQuotesCache(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get New Order Numbers
     * @param {number} [lastOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewOrderNumbers(
      lastOrder?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getNewOrderNumbers(lastOrder, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get New Quote Numbers
     * @param {number} [lastQuote]
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewQuoteNumbers(
      lastQuote?: number,
      revision?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuoteRevisionPair>>
    > {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getNewQuoteNumbers(lastQuote, revision, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Order Details
     * @param {OrderDetailsRequest} orderDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderDetails(
      orderDetailsRequest: OrderDetailsRequest,
      resetCache?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getOrderDetails(orderDetailsRequest, resetCache, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Part Library
     * @param {number} page
     * @param {string} [search]
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartLibrary(
      page: number,
      search?: string,
      ppToken?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PPPartLibraryResponse>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getPartLibrary(page, search, ppToken, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Part Library Technical Data Package
     * @param {string} uuid
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartLibraryTechnicalDataPackage(
      uuid: string,
      ppToken?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PPTechnicalDataPackage>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getPartLibraryTechnicalDataPackage(uuid, ppToken, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Quote Details
     * @param {QuoteDetailsRequest} quoteDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuoteDetails(
      quoteDetailsRequest: QuoteDetailsRequest,
      resetCache?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Quote>>> {
      const localVarAxiosArgs = await PaperlessPartsApiAxiosParamCreator(
        configuration
      ).getQuoteDetails(quoteDetailsRequest, resetCache, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PaperlessPartsApi - factory interface
 * @export
 */
export const PaperlessPartsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Clear Orders Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOrdersCache(options?: any): AxiosPromise<void> {
      return PaperlessPartsApiFp(configuration)
        .clearOrdersCache(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Clear Quotes Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearQuotesCache(options?: any): AxiosPromise<void> {
      return PaperlessPartsApiFp(configuration)
        .clearQuotesCache(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get New Order Numbers
     * @param {number} [lastOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewOrderNumbers(lastOrder?: number, options?: any): AxiosPromise<Array<number>> {
      return PaperlessPartsApiFp(configuration)
        .getNewOrderNumbers(lastOrder, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get New Quote Numbers
     * @param {number} [lastQuote]
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewQuoteNumbers(
      lastQuote?: number,
      revision?: number,
      options?: any
    ): AxiosPromise<Array<QuoteRevisionPair>> {
      return PaperlessPartsApiFp(configuration)
        .getNewQuoteNumbers(lastQuote, revision, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Order Details
     * @param {OrderDetailsRequest} orderDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderDetails(
      orderDetailsRequest: OrderDetailsRequest,
      resetCache?: boolean,
      options?: any
    ): AxiosPromise<Array<Order>> {
      return PaperlessPartsApiFp(configuration)
        .getOrderDetails(orderDetailsRequest, resetCache, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Part Library
     * @param {number} page
     * @param {string} [search]
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartLibrary(
      page: number,
      search?: string,
      ppToken?: string,
      options?: any
    ): AxiosPromise<PPPartLibraryResponse> {
      return PaperlessPartsApiFp(configuration)
        .getPartLibrary(page, search, ppToken, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Part Library Technical Data Package
     * @param {string} uuid
     * @param {string} [ppToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartLibraryTechnicalDataPackage(
      uuid: string,
      ppToken?: string,
      options?: any
    ): AxiosPromise<PPTechnicalDataPackage> {
      return PaperlessPartsApiFp(configuration)
        .getPartLibraryTechnicalDataPackage(uuid, ppToken, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Quote Details
     * @param {QuoteDetailsRequest} quoteDetailsRequest
     * @param {boolean} [resetCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteDetails(
      quoteDetailsRequest: QuoteDetailsRequest,
      resetCache?: boolean,
      options?: any
    ): AxiosPromise<Array<Quote>> {
      return PaperlessPartsApiFp(configuration)
        .getQuoteDetails(quoteDetailsRequest, resetCache, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * PaperlessPartsApi - object-oriented interface
 * @export
 * @class PaperlessPartsApi
 * @extends {BaseAPI}
 */
export class PaperlessPartsApi extends BaseAPI {
  /**
   *
   * @summary Clear Orders Cache
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public clearOrdersCache(options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .clearOrdersCache(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_clearOrdersCache(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/paperless-parts/orders/clear-cache`)
  }

  /**
   *
   * @summary Clear Quotes Cache
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public clearQuotesCache(options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .clearQuotesCache(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_clearQuotesCache(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/paperless-parts/quotes/clear-cache`)
  }

  /**
   *
   * @summary Get New Order Numbers
   * @param {number} [lastOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getNewOrderNumbers(lastOrder?: number, options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .getNewOrderNumbers(lastOrder, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getNewOrderNumbers(lastOrder?: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/paperless-parts/orders/new`)
    const localVarQueryParameter = {} as any
    if (lastOrder !== undefined) {
      localVarQueryParameter["last_order"] = lastOrder
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get New Quote Numbers
   * @param {number} [lastQuote]
   * @param {number} [revision]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getNewQuoteNumbers(lastQuote?: number, revision?: number, options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .getNewQuoteNumbers(lastQuote, revision, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getNewQuoteNumbers(lastQuote?: number, revision?: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/paperless-parts/quotes/new`)
    const localVarQueryParameter = {} as any
    if (lastQuote !== undefined) {
      localVarQueryParameter["last_quote"] = lastQuote
    }
    if (revision !== undefined) {
      localVarQueryParameter["revision"] = revision
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Order Details
   * @param {OrderDetailsRequest} orderDetailsRequest
   * @param {boolean} [resetCache]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getOrderDetails(
    orderDetailsRequest: OrderDetailsRequest,
    resetCache?: boolean,
    options?: any
  ) {
    return PaperlessPartsApiFp(this.configuration)
      .getOrderDetails(orderDetailsRequest, resetCache, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getOrderDetails(resetCache?: boolean): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/paperless-parts/orders/details`)
    const localVarQueryParameter = {} as any
    if (resetCache !== undefined) {
      localVarQueryParameter["reset_cache"] = resetCache
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Part Library
   * @param {number} page
   * @param {string} [search]
   * @param {string} [ppToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getPartLibrary(page: number, search?: string, ppToken?: string, options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .getPartLibrary(page, search, ppToken, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPartLibrary(
    page: number,
    search?: string,
    ppToken?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/paperless-parts/part_library/`)
    const localVarQueryParameter = {} as any
    if (page !== undefined) {
      localVarQueryParameter["page"] = page
    }
    if (search !== undefined) {
      localVarQueryParameter["search"] = search
    }
    if (ppToken !== undefined) {
      localVarQueryParameter["pp_token"] = ppToken
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Part Library Technical Data Package
   * @param {string} uuid
   * @param {string} [ppToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getPartLibraryTechnicalDataPackage(uuid: string, ppToken?: string, options?: any) {
    return PaperlessPartsApiFp(this.configuration)
      .getPartLibraryTechnicalDataPackage(uuid, ppToken, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPartLibraryTechnicalDataPackage(
    uuid: string,
    ppToken?: string
  ): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/v1/paperless-parts/part_library/technical_data_package/{uuid}`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid))
      )
    )
    const localVarQueryParameter = {} as any
    if (ppToken !== undefined) {
      localVarQueryParameter["pp_token"] = ppToken
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Quote Details
   * @param {QuoteDetailsRequest} quoteDetailsRequest
   * @param {boolean} [resetCache]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaperlessPartsApi
   */
  public getQuoteDetails(
    quoteDetailsRequest: QuoteDetailsRequest,
    resetCache?: boolean,
    options?: any
  ) {
    return PaperlessPartsApiFp(this.configuration)
      .getQuoteDetails(quoteDetailsRequest, resetCache, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getQuoteDetails(resetCache?: boolean): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/paperless-parts/quotes/details`)
    const localVarQueryParameter = {} as any
    if (resetCache !== undefined) {
      localVarQueryParameter["reset_cache"] = resetCache
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * PlanchangerApi - axios parameter creator
 * @export
 */
export const PlanchangerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyPatch: async (
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling applyPatch."
        )
      }
      // verify required parameter 'addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation' is not null or undefined
      if (
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ===
          null ||
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ===
          undefined
      ) {
        throw new RequiredError(
          "addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation",
          "Required parameter addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation was null or undefined when calling applyPatch."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (changeToken !== undefined) {
        localVarQueryParameter["change_token"] = changeToken
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation !==
          "string" || localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation !==
              undefined
              ? addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
              : {}
          )
        : addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ||
          ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Apply Quality Control Plan Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyQualityControlPlanPatch: async (
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling applyQualityControlPlanPatch."
        )
      }
      // verify required parameter 'addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation' is not null or undefined
      if (
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ===
          null ||
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ===
          undefined
      ) {
        throw new RequiredError(
          "addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation",
          "Required parameter addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation was null or undefined when calling applyQualityControlPlanPatch."
        )
      }
      const localVarPath = `/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (changeToken !== undefined) {
        localVarQueryParameter["change_token"] = changeToken
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation !==
          "string" || localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation !==
              undefined
              ? addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
              : {}
          )
        : addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation ||
          ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Associate Drawing
     * @param {string} jobFileId
     * @param {string} highqaPartId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associateDrawing: async (
      jobFileId: string,
      highqaPartId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobFileId' is not null or undefined
      if (jobFileId === null || jobFileId === undefined) {
        throw new RequiredError(
          "jobFileId",
          "Required parameter jobFileId was null or undefined when calling associateDrawing."
        )
      }
      // verify required parameter 'highqaPartId' is not null or undefined
      if (highqaPartId === null || highqaPartId === undefined) {
        throw new RequiredError(
          "highqaPartId",
          "Required parameter highqaPartId was null or undefined when calling associateDrawing."
        )
      }
      const localVarPath = `/api/rs/v1/associate-drawing/{job_file_id}`.replace(
        `{${"job_file_id"}}`,
        encodeURIComponent(String(jobFileId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (highqaPartId !== undefined) {
        localVarQueryParameter["highqa_part_id"] = highqaPartId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Associate Xdf
     * @param {string} jobId
     * @param {any} file
     * @param {string} [jobFileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associateXdf: async (
      jobId: string,
      file: any,
      jobFileId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling associateXdf."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling associateXdf."
        )
      }
      const localVarPath = `/api/rs/v1/associate-xdf`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (jobFileId !== undefined) {
        localVarQueryParameter["job_file_id"] = jobFileId
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Clear Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearToolsWear: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling clearToolsWear."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling clearToolsWear."
        )
      }
      const localVarPath = `/api/rs/v1/clear-tools-wear/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Annotation Sidebar Data
     * @param {string} [planCreatedAfter]
     * @param {string} [jobLabelContains]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationSidebarData: async (
      planCreatedAfter?: string,
      jobLabelContains?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/rs/v1/annotation/sidebar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planCreatedAfter !== undefined) {
        localVarQueryParameter["planCreatedAfter"] =
          (planCreatedAfter as any) instanceof Date
            ? (planCreatedAfter as any).toISOString()
            : planCreatedAfter
      }

      if (jobLabelContains !== undefined) {
        localVarQueryParameter["jobLabelContains"] = jobLabelContains
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Doc
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDoc: async (filename: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'filename' is not null or undefined
      if (filename === null || filename === undefined) {
        throw new RequiredError(
          "filename",
          "Required parameter filename was null or undefined when calling getDoc."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/doc{filename}`.replace(
        `{${"filename"}}`,
        encodeURIComponent(String(filename))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get File
     * @param {string} [key]
     * @param {string} [fileId]
     * @param {boolean} [inline]
     * @param {BucketType} [bucketType]
     * @param {boolean} [ncFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFile: async (
      key?: string,
      fileId?: string,
      inline?: boolean,
      bucketType?: BucketType,
      ncFormatted?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/rs/v1/file`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (key !== undefined) {
        localVarQueryParameter["key"] = key
      }

      if (fileId !== undefined) {
        localVarQueryParameter["file_id"] = fileId
      }

      if (inline !== undefined) {
        localVarQueryParameter["inline"] = inline
      }

      if (bucketType !== undefined) {
        localVarQueryParameter["bucket_type"] = bucketType
      }

      if (ncFormatted !== undefined) {
        localVarQueryParameter["nc_formatted"] = ncFormatted
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Locations
     * @param {BucketType} bucket
     * @param {string} prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocations: async (
      bucket: BucketType,
      prefix: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucket' is not null or undefined
      if (bucket === null || bucket === undefined) {
        throw new RequiredError(
          "bucket",
          "Required parameter bucket was null or undefined when calling getLocations."
        )
      }
      // verify required parameter 'prefix' is not null or undefined
      if (prefix === null || prefix === undefined) {
        throw new RequiredError(
          "prefix",
          "Required parameter prefix was null or undefined when calling getLocations."
        )
      }
      const localVarPath = `/api/rs/v1/locations/{bucket}/{prefix}`
        .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)))
        .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Manifest
     * @param {string} uri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManifest: async (uri: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'uri' is not null or undefined
      if (uri === null || uri === undefined) {
        throw new RequiredError(
          "uri",
          "Required parameter uri was null or undefined when calling getManifest."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/manifest`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (uri !== undefined) {
        localVarQueryParameter["uri"] = uri
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Opscene
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpscene: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling getOpscene."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling getOpscene."
        )
      }
      const localVarPath = `/api/rs/v1/opscene/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerJobs: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/rs/v1/jobs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerPlan: async (
      id: string,
      revision?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPlanchangerPlan."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (revision !== undefined) {
        localVarQueryParameter["revision"] = revision
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Plan History
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerPlanHistory: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPlanchangerPlanHistory."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}/history`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Quality Control Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerQualityControlPlan: async (
      id: string,
      revision?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPlanchangerQualityControlPlan."
        )
      }
      const localVarPath = `/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (revision !== undefined) {
        localVarQueryParameter["revision"] = revision
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Valid Probing Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerValidProbingFeedback: async (
      id: string,
      operationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPlanchangerValidProbingFeedback."
        )
      }
      // verify required parameter 'operationId' is not null or undefined
      if (operationId === null || operationId === undefined) {
        throw new RequiredError(
          "operationId",
          "Required parameter operationId was null or undefined when calling getPlanchangerValidProbingFeedback."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}/probing/valid/{operation_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"operation_id"}}`, encodeURIComponent(String(operationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Planchanger Valid Vericut Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerValidVericutFeedback: async (
      id: string,
      operationId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPlanchangerValidVericutFeedback."
        )
      }
      // verify required parameter 'operationId' is not null or undefined
      if (operationId === null || operationId === undefined) {
        throw new RequiredError(
          "operationId",
          "Required parameter operationId was null or undefined when calling getPlanchangerValidVericutFeedback."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}/feedback/valid/{operation_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"operation_id"}}`, encodeURIComponent(String(operationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Postprocessor Manifest
     * @param {string} camProgram
     * @param {string} machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostprocessorManifest: async (
      camProgram: string,
      machine: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'camProgram' is not null or undefined
      if (camProgram === null || camProgram === undefined) {
        throw new RequiredError(
          "camProgram",
          "Required parameter camProgram was null or undefined when calling getPostprocessorManifest."
        )
      }
      // verify required parameter 'machine' is not null or undefined
      if (machine === null || machine === undefined) {
        throw new RequiredError(
          "machine",
          "Required parameter machine was null or undefined when calling getPostprocessorManifest."
        )
      }
      const localVarPath = `/api/rs/v1/{cam_program}/{machine}/postprocessor-manifest`
        .replace(`{${"cam_program"}}`, encodeURIComponent(String(camProgram)))
        .replace(`{${"machine"}}`, encodeURIComponent(String(machine)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Postprocessors Manifest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostprocessorsManifest: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/rs/v1/postprocessors-manifest`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Tool Operations
     * @param {number} tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolOperations: async (tool: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tool' is not null or undefined
      if (tool === null || tool === undefined) {
        throw new RequiredError(
          "tool",
          "Required parameter tool was null or undefined when calling getToolOperations."
        )
      }
      const localVarPath = `/api/rs/v1/tool_operations/{tool}`.replace(
        `{${"tool"}}`,
        encodeURIComponent(String(tool))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Tools
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTools: async (application: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'application' is not null or undefined
      if (application === null || application === undefined) {
        throw new RequiredError(
          "application",
          "Required parameter application was null or undefined when calling getTools."
        )
      }
      const localVarPath = `/api/rs/v1/{application}/tools`.replace(
        `{${"application"}}`,
        encodeURIComponent(String(application))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Tools Locator
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsLocator: async (application: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'application' is not null or undefined
      if (application === null || application === undefined) {
        throw new RequiredError(
          "application",
          "Required parameter application was null or undefined when calling getToolsLocator."
        )
      }
      const localVarPath = `/api/rs/v1/{application}/tools-locator`.replace(
        `{${"application"}}`,
        encodeURIComponent(String(application))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {boolean} [clearExistingFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ncProgram: async (
      planId: string,
      operationIdx: number,
      file: any,
      clearExistingFiles?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling ncProgram."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling ncProgram."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling ncProgram."
        )
      }
      const localVarPath = `/api/rs/v1/nc-program`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (clearExistingFiles !== undefined) {
        localVarQueryParameter["clear_existing_files"] = clearExistingFiles
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary New File
     * @param {ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload} uploadFileType
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newFile: async (
      uploadFileType: ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload,
      file: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uploadFileType' is not null or undefined
      if (uploadFileType === null || uploadFileType === undefined) {
        throw new RequiredError(
          "uploadFileType",
          "Required parameter uploadFileType was null or undefined when calling newFile."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling newFile."
        )
      }
      const localVarPath = `/api/rs/v1/file`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (uploadFileType !== undefined) {
        localVarFormParams.append("upload_file_type", uploadFileType as any)
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary New Part
     * @param {string} customer
     * @param {string} partId
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newPart: async (
      customer: string,
      partId: string,
      file: any,
      internal?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customer' is not null or undefined
      if (customer === null || customer === undefined) {
        throw new RequiredError(
          "customer",
          "Required parameter customer was null or undefined when calling newPart."
        )
      }
      // verify required parameter 'partId' is not null or undefined
      if (partId === null || partId === undefined) {
        throw new RequiredError(
          "partId",
          "Required parameter partId was null or undefined when calling newPart."
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling newPart."
        )
      }
      const localVarPath = `/api/rs/v1/part`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (customer !== undefined) {
        localVarQueryParameter["customer"] = customer
      }

      if (partId !== undefined) {
        localVarQueryParameter["part_id"] = partId
      }

      if (internal !== undefined) {
        localVarQueryParameter["internal"] = internal
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Parse Drawing
     * @param {string} camJobFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    parseDrawing: async (camJobFileId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'camJobFileId' is not null or undefined
      if (camJobFileId === null || camJobFileId === undefined) {
        throw new RequiredError(
          "camJobFileId",
          "Required parameter camJobFileId was null or undefined when calling parseDrawing."
        )
      }
      const localVarPath = `/api/rs/v1/parse-drawing/{cam_job_file_id}`.replace(
        `{${"cam_job_file_id"}}`,
        encodeURIComponent(String(camJobFileId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Post Planchanger Plan
     * @param {string} id
     * @param {Plan} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlanchangerPlan: async (
      id: string,
      plan: Plan,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling postPlanchangerPlan."
        )
      }
      // verify required parameter 'plan' is not null or undefined
      if (plan === null || plan === undefined) {
        throw new RequiredError(
          "plan",
          "Required parameter plan was null or undefined when calling postPlanchangerPlan."
        )
      }
      const localVarPath = `/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof plan !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(plan !== undefined ? plan : {})
        : plan || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Post Planchanger Quality Control Plan
     * @param {string} id
     * @param {QualityControlPlanData} qualityControlPlanData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlanchangerQualityControlPlan: async (
      id: string,
      qualityControlPlanData: QualityControlPlanData,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling postPlanchangerQualityControlPlan."
        )
      }
      // verify required parameter 'qualityControlPlanData' is not null or undefined
      if (qualityControlPlanData === null || qualityControlPlanData === undefined) {
        throw new RequiredError(
          "qualityControlPlanData",
          "Required parameter qualityControlPlanData was null or undefined when calling postPlanchangerQualityControlPlan."
        )
      }
      const localVarPath = `/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof qualityControlPlanData !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(qualityControlPlanData !== undefined ? qualityControlPlanData : {})
        : qualityControlPlanData || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Drawing
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishDrawing: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling publishDrawing."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/publish_drawing`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Job
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishJob: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling publishJob."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/publish_job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Output Stock
     * @param {string} runId
     * @param {string} feedbackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishOutputStock: async (
      runId: string,
      feedbackId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'runId' is not null or undefined
      if (runId === null || runId === undefined) {
        throw new RequiredError(
          "runId",
          "Required parameter runId was null or undefined when calling publishOutputStock."
        )
      }
      // verify required parameter 'feedbackId' is not null or undefined
      if (feedbackId === null || feedbackId === undefined) {
        throw new RequiredError(
          "feedbackId",
          "Required parameter feedbackId was null or undefined when calling publishOutputStock."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/publish_output_stock`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      if (feedbackId !== undefined) {
        localVarQueryParameter["feedback_id"] = feedbackId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Quality Control Plan
     * @param {string} camPlanId
     * @param {boolean} [minor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishQualityControlPlan: async (
      camPlanId: string,
      minor?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'camPlanId' is not null or undefined
      if (camPlanId === null || camPlanId === undefined) {
        throw new RequiredError(
          "camPlanId",
          "Required parameter camPlanId was null or undefined when calling publishQualityControlPlan."
        )
      }
      const localVarPath = `/api/rs/v1/publish/quality-control-plan/{cam_plan_id}`.replace(
        `{${"cam_plan_id"}}`,
        encodeURIComponent(String(camPlanId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (minor !== undefined) {
        localVarQueryParameter["minor"] = minor
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Setup
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishSetup: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling publishSetup."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling publishSetup."
        )
      }
      const localVarPath = `/api/rs/v1/manifests/publish_setup`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish To Mes
     * @param {string} id
     * @param {number} [operation]
     * @param {boolean} [minor]
     * @param {boolean} [markPublished]
     * @param {boolean} [isProveout]
     * @param {boolean} [machinePreCheck]
     * @param {boolean} [machinePostCheck]
     * @param {boolean} [squarenessPreCheck]
     * @param {boolean} [squarenessPostCheck]
     * @param {boolean} [clampAxesAlignments]
     * @param {boolean} [skipCutting]
     * @param {boolean} [skipAlignments]
     * @param {boolean} [skipInspections]
     * @param {boolean} [skipMachineOffsets]
     * @param {boolean} [skipMachineOffsetsLogging]
     * @param {boolean} [enableCycleDprint]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishToMes: async (
      id: string,
      operation?: number,
      minor?: boolean,
      markPublished?: boolean,
      isProveout?: boolean,
      machinePreCheck?: boolean,
      machinePostCheck?: boolean,
      squarenessPreCheck?: boolean,
      squarenessPostCheck?: boolean,
      clampAxesAlignments?: boolean,
      skipCutting?: boolean,
      skipAlignments?: boolean,
      skipInspections?: boolean,
      skipMachineOffsets?: boolean,
      skipMachineOffsetsLogging?: boolean,
      enableCycleDprint?: boolean,
      requestBody?: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling publishToMes."
        )
      }
      const localVarPath = `/api/rs/v1/publish/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (operation !== undefined) {
        localVarQueryParameter["operation"] = operation
      }

      if (minor !== undefined) {
        localVarQueryParameter["minor"] = minor
      }

      if (markPublished !== undefined) {
        localVarQueryParameter["mark_published"] = markPublished
      }

      if (isProveout !== undefined) {
        localVarQueryParameter["is_proveout"] = isProveout
      }

      if (machinePreCheck !== undefined) {
        localVarQueryParameter["machine_pre_check"] = machinePreCheck
      }

      if (machinePostCheck !== undefined) {
        localVarQueryParameter["machine_post_check"] = machinePostCheck
      }

      if (squarenessPreCheck !== undefined) {
        localVarQueryParameter["squareness_pre_check"] = squarenessPreCheck
      }

      if (squarenessPostCheck !== undefined) {
        localVarQueryParameter["squareness_post_check"] = squarenessPostCheck
      }

      if (clampAxesAlignments !== undefined) {
        localVarQueryParameter["clamp_axes_alignments"] = clampAxesAlignments
      }

      if (skipCutting !== undefined) {
        localVarQueryParameter["skip_cutting"] = skipCutting
      }

      if (skipAlignments !== undefined) {
        localVarQueryParameter["skip_alignments"] = skipAlignments
      }

      if (skipInspections !== undefined) {
        localVarQueryParameter["skip_inspections"] = skipInspections
      }

      if (skipMachineOffsets !== undefined) {
        localVarQueryParameter["skip_machine_offsets"] = skipMachineOffsets
      }

      if (skipMachineOffsetsLogging !== undefined) {
        localVarQueryParameter["skip_machine_offsets_logging"] = skipMachineOffsetsLogging
      }

      if (enableCycleDprint !== undefined) {
        localVarQueryParameter["enable_cycle_dprint"] = enableCycleDprint
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof requestBody !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Refresh Drawing
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshDrawing: async (drawingId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'drawingId' is not null or undefined
      if (drawingId === null || drawingId === undefined) {
        throw new RequiredError(
          "drawingId",
          "Required parameter drawingId was null or undefined when calling refreshDrawing."
        )
      }
      const localVarPath = `/api/rs/v1/refresh-drawing/{drawing_id}`.replace(
        `{${"drawing_id"}}`,
        encodeURIComponent(String(drawingId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Remap Dims
     * @param {string} drawingId
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remapDims: async (
      drawingId: string,
      requestBody: { [key: string]: string },
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'drawingId' is not null or undefined
      if (drawingId === null || drawingId === undefined) {
        throw new RequiredError(
          "drawingId",
          "Required parameter drawingId was null or undefined when calling remapDims."
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          "requestBody",
          "Required parameter requestBody was null or undefined when calling remapDims."
        )
      }
      const localVarPath = `/api/rs/v1/remap-dims`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (drawingId !== undefined) {
        localVarQueryParameter["drawing_id"] = drawingId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof requestBody !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Repatch Dims
     * @param {string} drawingId
     * @param {{ [key: string]: Dimension; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    repatchDims: async (
      drawingId: string,
      requestBody: { [key: string]: Dimension },
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'drawingId' is not null or undefined
      if (drawingId === null || drawingId === undefined) {
        throw new RequiredError(
          "drawingId",
          "Required parameter drawingId was null or undefined when calling repatchDims."
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          "requestBody",
          "Required parameter requestBody was null or undefined when calling repatchDims."
        )
      }
      const localVarPath = `/api/rs/v1/repatch-dims`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (drawingId !== undefined) {
        localVarQueryParameter["drawing_id"] = drawingId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof requestBody !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Tools Geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolsGeometry: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/rs/v1/tools-geometry`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Toolsetting
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolsetting: async (
      toolsettingConfig: ToolsettingConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'toolsettingConfig' is not null or undefined
      if (toolsettingConfig === null || toolsettingConfig === undefined) {
        throw new RequiredError(
          "toolsettingConfig",
          "Required parameter toolsettingConfig was null or undefined when calling toolsetting."
        )
      }
      const localVarPath = `/api/rs/v1/toolsetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof toolsettingConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(toolsettingConfig !== undefined ? toolsettingConfig : {})
        : toolsettingConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Fixture Choice
     * @param {string} planId
     * @param {number} operationIdx
     * @param {FixtureChoice} fixtureChoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFixtureChoice: async (
      planId: string,
      operationIdx: number,
      fixtureChoice: FixtureChoice,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateFixtureChoice."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateFixtureChoice."
        )
      }
      // verify required parameter 'fixtureChoice' is not null or undefined
      if (fixtureChoice === null || fixtureChoice === undefined) {
        throw new RequiredError(
          "fixtureChoice",
          "Required parameter fixtureChoice was null or undefined when calling updateFixtureChoice."
        )
      }
      const localVarPath = `/api/rs/v1/plan/{plan_id}/fixture-choice`.replace(
        `{${"plan_id"}}`,
        encodeURIComponent(String(planId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof fixtureChoice !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fixtureChoice !== undefined ? fixtureChoice : {})
        : fixtureChoice || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsWearUpdate} toolsWearUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateToolsWear: async (
      planId: string,
      operationIdx: number,
      toolsWearUpdate: ToolsWearUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateToolsWear."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateToolsWear."
        )
      }
      // verify required parameter 'toolsWearUpdate' is not null or undefined
      if (toolsWearUpdate === null || toolsWearUpdate === undefined) {
        throw new RequiredError(
          "toolsWearUpdate",
          "Required parameter toolsWearUpdate was null or undefined when calling updateToolsWear."
        )
      }
      const localVarPath = `/api/rs/v1/update-tools-wear/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof toolsWearUpdate !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(toolsWearUpdate !== undefined ? toolsWearUpdate : {})
        : toolsWearUpdate || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Toolsetting
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateToolsetting: async (
      planId: string,
      operationIdx: number,
      toolsettingConfig: ToolsettingConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateToolsetting."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateToolsetting."
        )
      }
      // verify required parameter 'toolsettingConfig' is not null or undefined
      if (toolsettingConfig === null || toolsettingConfig === undefined) {
        throw new RequiredError(
          "toolsettingConfig",
          "Required parameter toolsettingConfig was null or undefined when calling updateToolsetting."
        )
      }
      const localVarPath = `/api/rs/v1/update/toolsetting/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof toolsettingConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(toolsettingConfig !== undefined ? toolsettingConfig : {})
        : toolsettingConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlanchangerApi - functional programming interface
 * @export
 */
export const PlanchangerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyPatch(
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).applyPatch(
        id,
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
        changeToken,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Apply Quality Control Plan Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyQualityControlPlanPatch(
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityControlPlanData>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).applyQualityControlPlanPatch(
        id,
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
        changeToken,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Associate Drawing
     * @param {string} jobFileId
     * @param {string} highqaPartId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async associateDrawing(
      jobFileId: string,
      highqaPartId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).associateDrawing(jobFileId, highqaPartId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Associate Xdf
     * @param {string} jobId
     * @param {any} file
     * @param {string} [jobFileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async associateXdf(
      jobId: string,
      file: any,
      jobFileId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).associateXdf(
        jobId,
        file,
        jobFileId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Clear Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearToolsWear(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).clearToolsWear(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Annotation Sidebar Data
     * @param {string} [planCreatedAfter]
     * @param {string} [jobLabelContains]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationSidebarData(
      planCreatedAfter?: string,
      jobLabelContains?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationSidebarItem>>
    > {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getAnnotationSidebarData(planCreatedAfter, jobLabelContains, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Doc
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDoc(
      filename: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getDoc(
        filename,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get File
     * @param {string} [key]
     * @param {string} [fileId]
     * @param {boolean} [inline]
     * @param {BucketType} [bucketType]
     * @param {boolean} [ncFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFile(
      key?: string,
      fileId?: string,
      inline?: boolean,
      bucketType?: BucketType,
      ncFormatted?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getFile(
        key,
        fileId,
        inline,
        bucketType,
        ncFormatted,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Locations
     * @param {BucketType} bucket
     * @param {string} prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocations(
      bucket: BucketType,
      prefix: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getLocations(
        bucket,
        prefix,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Manifest
     * @param {string} uri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManifest(
      uri: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getManifest(
        uri,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Opscene
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpscene(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getOpscene(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerJobs(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardJob>>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerJobs(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerPlan(
      id: string,
      revision?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerPlan(id, revision, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Plan History
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerPlanHistory(
      id: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        Array<
          | AddPatchOperation
          | CopyPatchOperation
          | MovePatchOperation
          | RemovePatchOperation
          | ReplacePatchOperation
          | TestPatchOperation
        >
      >
    > {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerPlanHistory(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Quality Control Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerQualityControlPlan(
      id: string,
      revision?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityControlPlanData>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerQualityControlPlan(id, revision, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Valid Probing Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerValidProbingFeedback(
      id: string,
      operationId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProbingFeedbackNode>>
    > {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerValidProbingFeedback(id, operationId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Planchanger Valid Vericut Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanchangerValidVericutFeedback(
      id: string,
      operationId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VericutNode>>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPlanchangerValidVericutFeedback(id, operationId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Postprocessor Manifest
     * @param {string} camProgram
     * @param {string} machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPostprocessorManifest(
      camProgram: string,
      machine: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProcessorManifest>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPostprocessorManifest(camProgram, machine, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Postprocessors Manifest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPostprocessorsManifest(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostProcessorManifest>>
    > {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getPostprocessorsManifest(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Tool Operations
     * @param {number} tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToolOperations(
      tool: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getToolOperations(tool, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Tools
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTools(
      application: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).getTools(
        application,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Tools Locator
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToolsLocator(
      application: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).getToolsLocator(application, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {boolean} [clearExistingFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ncProgram(
      planId: string,
      operationIdx: number,
      file: any,
      clearExistingFiles?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).ncProgram(
        planId,
        operationIdx,
        file,
        clearExistingFiles,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary New File
     * @param {ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload} uploadFileType
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newFile(
      uploadFileType: ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload,
      file: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).newFile(
        uploadFileType,
        file,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary New Part
     * @param {string} customer
     * @param {string} partId
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newPart(
      customer: string,
      partId: string,
      file: any,
      internal?: boolean,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanChangerUploadedModelResponse>
    > {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).newPart(
        customer,
        partId,
        file,
        internal,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Parse Drawing
     * @param {string} camJobFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async parseDrawing(
      camJobFileId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileResponse>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).parseDrawing(
        camJobFileId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Post Planchanger Plan
     * @param {string} id
     * @param {Plan} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlanchangerPlan(
      id: string,
      plan: Plan,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).postPlanchangerPlan(id, plan, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Post Planchanger Quality Control Plan
     * @param {string} id
     * @param {QualityControlPlanData} qualityControlPlanData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlanchangerQualityControlPlan(
      id: string,
      qualityControlPlanData: QualityControlPlanData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityControlPlanData>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).postPlanchangerQualityControlPlan(id, qualityControlPlanData, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish Drawing
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishDrawing(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).publishDrawing(
        jobId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish Job
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishJob(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).publishJob(
        jobId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish Output Stock
     * @param {string} runId
     * @param {string} feedbackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishOutputStock(
      runId: string,
      feedbackId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).publishOutputStock(runId, feedbackId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish Quality Control Plan
     * @param {string} camPlanId
     * @param {boolean} [minor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishQualityControlPlan(
      camPlanId: string,
      minor?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).publishQualityControlPlan(camPlanId, minor, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish Setup
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishSetup(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).publishSetup(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Publish To Mes
     * @param {string} id
     * @param {number} [operation]
     * @param {boolean} [minor]
     * @param {boolean} [markPublished]
     * @param {boolean} [isProveout]
     * @param {boolean} [machinePreCheck]
     * @param {boolean} [machinePostCheck]
     * @param {boolean} [squarenessPreCheck]
     * @param {boolean} [squarenessPostCheck]
     * @param {boolean} [clampAxesAlignments]
     * @param {boolean} [skipCutting]
     * @param {boolean} [skipAlignments]
     * @param {boolean} [skipInspections]
     * @param {boolean} [skipMachineOffsets]
     * @param {boolean} [skipMachineOffsetsLogging]
     * @param {boolean} [enableCycleDprint]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishToMes(
      id: string,
      operation?: number,
      minor?: boolean,
      markPublished?: boolean,
      isProveout?: boolean,
      machinePreCheck?: boolean,
      machinePostCheck?: boolean,
      squarenessPreCheck?: boolean,
      squarenessPostCheck?: boolean,
      clampAxesAlignments?: boolean,
      skipCutting?: boolean,
      skipAlignments?: boolean,
      skipInspections?: boolean,
      skipMachineOffsets?: boolean,
      skipMachineOffsetsLogging?: boolean,
      enableCycleDprint?: boolean,
      requestBody?: Array<string>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).publishToMes(
        id,
        operation,
        minor,
        markPublished,
        isProveout,
        machinePreCheck,
        machinePostCheck,
        squarenessPreCheck,
        squarenessPostCheck,
        clampAxesAlignments,
        skipCutting,
        skipAlignments,
        skipInspections,
        skipMachineOffsets,
        skipMachineOffsetsLogging,
        enableCycleDprint,
        requestBody,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Refresh Drawing
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshDrawing(
      drawingId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskProgress>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).refreshDrawing(
        drawingId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Remap Dims
     * @param {string} drawingId
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async remapDims(
      drawingId: string,
      requestBody: { [key: string]: string },
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskProgress>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).remapDims(
        drawingId,
        requestBody,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Repatch Dims
     * @param {string} drawingId
     * @param {{ [key: string]: Dimension; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async repatchDims(
      drawingId: string,
      requestBody: { [key: string]: Dimension },
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskProgress>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).repatchDims(
        drawingId,
        requestBody,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Tools Geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async toolsGeometry(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).toolsGeometry(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Toolsetting
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async toolsetting(
      toolsettingConfig: ToolsettingConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(configuration).toolsetting(
        toolsettingConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Fixture Choice
     * @param {string} planId
     * @param {number} operationIdx
     * @param {FixtureChoice} fixtureChoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFixtureChoice(
      planId: string,
      operationIdx: number,
      fixtureChoice: FixtureChoice,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).updateFixtureChoice(planId, operationIdx, fixtureChoice, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsWearUpdate} toolsWearUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateToolsWear(
      planId: string,
      operationIdx: number,
      toolsWearUpdate: ToolsWearUpdate,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).updateToolsWear(planId, operationIdx, toolsWearUpdate, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Toolsetting
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateToolsetting(
      planId: string,
      operationIdx: number,
      toolsettingConfig: ToolsettingConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlanchangerApiAxiosParamCreator(
        configuration
      ).updateToolsetting(planId, operationIdx, toolsettingConfig, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PlanchangerApi - factory interface
 * @export
 */
export const PlanchangerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Apply Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyPatch(
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options?: any
    ): AxiosPromise<Plan> {
      return PlanchangerApiFp(configuration)
        .applyPatch(
          id,
          addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
          changeToken,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Apply Quality Control Plan Patch
     * @param {string} id
     * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
     * @param {string} [changeToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyQualityControlPlanPatch(
      id: string,
      addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >,
      changeToken?: string,
      options?: any
    ): AxiosPromise<QualityControlPlanData> {
      return PlanchangerApiFp(configuration)
        .applyQualityControlPlanPatch(
          id,
          addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
          changeToken,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Associate Drawing
     * @param {string} jobFileId
     * @param {string} highqaPartId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associateDrawing(jobFileId: string, highqaPartId: string, options?: any): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .associateDrawing(jobFileId, highqaPartId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Associate Xdf
     * @param {string} jobId
     * @param {any} file
     * @param {string} [jobFileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associateXdf(
      jobId: string,
      file: any,
      jobFileId?: string,
      options?: any
    ): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .associateXdf(jobId, file, jobFileId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Clear Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearToolsWear(planId: string, operationIdx: number, options?: any): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .clearToolsWear(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Annotation Sidebar Data
     * @param {string} [planCreatedAfter]
     * @param {string} [jobLabelContains]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationSidebarData(
      planCreatedAfter?: string,
      jobLabelContains?: string,
      options?: any
    ): AxiosPromise<Array<AnnotationSidebarItem>> {
      return PlanchangerApiFp(configuration)
        .getAnnotationSidebarData(planCreatedAfter, jobLabelContains, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Doc
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDoc(filename: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getDoc(filename, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get File
     * @param {string} [key]
     * @param {string} [fileId]
     * @param {boolean} [inline]
     * @param {BucketType} [bucketType]
     * @param {boolean} [ncFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFile(
      key?: string,
      fileId?: string,
      inline?: boolean,
      bucketType?: BucketType,
      ncFormatted?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getFile(key, fileId, inline, bucketType, ncFormatted, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Locations
     * @param {BucketType} bucket
     * @param {string} prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocations(bucket: BucketType, prefix: string, options?: any): AxiosPromise<Array<string>> {
      return PlanchangerApiFp(configuration)
        .getLocations(bucket, prefix, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Manifest
     * @param {string} uri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManifest(uri: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getManifest(uri, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Opscene
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpscene(planId: string, operationIdx: number, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getOpscene(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerJobs(options?: any): AxiosPromise<Array<DashboardJob>> {
      return PlanchangerApiFp(configuration)
        .getPlanchangerJobs(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerPlan(id: string, revision?: number, options?: any): AxiosPromise<Plan> {
      return PlanchangerApiFp(configuration)
        .getPlanchangerPlan(id, revision, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Plan History
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerPlanHistory(
      id: string,
      options?: any
    ): AxiosPromise<
      Array<
        | AddPatchOperation
        | CopyPatchOperation
        | MovePatchOperation
        | RemovePatchOperation
        | ReplacePatchOperation
        | TestPatchOperation
      >
    > {
      return PlanchangerApiFp(configuration)
        .getPlanchangerPlanHistory(id, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Quality Control Plan
     * @param {string} id
     * @param {number} [revision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerQualityControlPlan(
      id: string,
      revision?: number,
      options?: any
    ): AxiosPromise<QualityControlPlanData> {
      return PlanchangerApiFp(configuration)
        .getPlanchangerQualityControlPlan(id, revision, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Valid Probing Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerValidProbingFeedback(
      id: string,
      operationId: string,
      options?: any
    ): AxiosPromise<Array<ProbingFeedbackNode>> {
      return PlanchangerApiFp(configuration)
        .getPlanchangerValidProbingFeedback(id, operationId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Planchanger Valid Vericut Feedback
     * @param {string} id
     * @param {string} operationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanchangerValidVericutFeedback(
      id: string,
      operationId: string,
      options?: any
    ): AxiosPromise<Array<VericutNode>> {
      return PlanchangerApiFp(configuration)
        .getPlanchangerValidVericutFeedback(id, operationId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Postprocessor Manifest
     * @param {string} camProgram
     * @param {string} machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostprocessorManifest(
      camProgram: string,
      machine: string,
      options?: any
    ): AxiosPromise<PostProcessorManifest> {
      return PlanchangerApiFp(configuration)
        .getPostprocessorManifest(camProgram, machine, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Postprocessors Manifest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostprocessorsManifest(options?: any): AxiosPromise<Array<PostProcessorManifest>> {
      return PlanchangerApiFp(configuration)
        .getPostprocessorsManifest(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Tool Operations
     * @param {number} tool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolOperations(tool: number, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getToolOperations(tool, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Tools
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTools(application: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .getTools(application, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Tools Locator
     * @param {string} application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsLocator(application: string, options?: any): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .getToolsLocator(application, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Nc Program
     * @param {string} planId
     * @param {number} operationIdx
     * @param {any} file
     * @param {boolean} [clearExistingFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ncProgram(
      planId: string,
      operationIdx: number,
      file: any,
      clearExistingFiles?: boolean,
      options?: any
    ): AxiosPromise<UploadedFileResponse> {
      return PlanchangerApiFp(configuration)
        .ncProgram(planId, operationIdx, file, clearExistingFiles, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary New File
     * @param {ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload} uploadFileType
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newFile(
      uploadFileType: ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload,
      file: any,
      options?: any
    ): AxiosPromise<UploadResponse> {
      return PlanchangerApiFp(configuration)
        .newFile(uploadFileType, file, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary New Part
     * @param {string} customer
     * @param {string} partId
     * @param {any} file
     * @param {boolean} [internal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newPart(
      customer: string,
      partId: string,
      file: any,
      internal?: boolean,
      options?: any
    ): AxiosPromise<PlanChangerUploadedModelResponse> {
      return PlanchangerApiFp(configuration)
        .newPart(customer, partId, file, internal, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Parse Drawing
     * @param {string} camJobFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    parseDrawing(camJobFileId: string, options?: any): AxiosPromise<UploadedFileResponse> {
      return PlanchangerApiFp(configuration)
        .parseDrawing(camJobFileId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Post Planchanger Plan
     * @param {string} id
     * @param {Plan} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlanchangerPlan(id: string, plan: Plan, options?: any): AxiosPromise<Plan> {
      return PlanchangerApiFp(configuration)
        .postPlanchangerPlan(id, plan, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Post Planchanger Quality Control Plan
     * @param {string} id
     * @param {QualityControlPlanData} qualityControlPlanData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlanchangerQualityControlPlan(
      id: string,
      qualityControlPlanData: QualityControlPlanData,
      options?: any
    ): AxiosPromise<QualityControlPlanData> {
      return PlanchangerApiFp(configuration)
        .postPlanchangerQualityControlPlan(id, qualityControlPlanData, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Drawing
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishDrawing(jobId: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .publishDrawing(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Job
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishJob(jobId: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .publishJob(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Output Stock
     * @param {string} runId
     * @param {string} feedbackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishOutputStock(runId: string, feedbackId: string, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .publishOutputStock(runId, feedbackId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Quality Control Plan
     * @param {string} camPlanId
     * @param {boolean} [minor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishQualityControlPlan(
      camPlanId: string,
      minor?: boolean,
      options?: any
    ): AxiosPromise<Array<string>> {
      return PlanchangerApiFp(configuration)
        .publishQualityControlPlan(camPlanId, minor, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Setup
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishSetup(planId: string, operationIdx: number, options?: any): AxiosPromise<void> {
      return PlanchangerApiFp(configuration)
        .publishSetup(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Publish To Mes
     * @param {string} id
     * @param {number} [operation]
     * @param {boolean} [minor]
     * @param {boolean} [markPublished]
     * @param {boolean} [isProveout]
     * @param {boolean} [machinePreCheck]
     * @param {boolean} [machinePostCheck]
     * @param {boolean} [squarenessPreCheck]
     * @param {boolean} [squarenessPostCheck]
     * @param {boolean} [clampAxesAlignments]
     * @param {boolean} [skipCutting]
     * @param {boolean} [skipAlignments]
     * @param {boolean} [skipInspections]
     * @param {boolean} [skipMachineOffsets]
     * @param {boolean} [skipMachineOffsetsLogging]
     * @param {boolean} [enableCycleDprint]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishToMes(
      id: string,
      operation?: number,
      minor?: boolean,
      markPublished?: boolean,
      isProveout?: boolean,
      machinePreCheck?: boolean,
      machinePostCheck?: boolean,
      squarenessPreCheck?: boolean,
      squarenessPostCheck?: boolean,
      clampAxesAlignments?: boolean,
      skipCutting?: boolean,
      skipAlignments?: boolean,
      skipInspections?: boolean,
      skipMachineOffsets?: boolean,
      skipMachineOffsetsLogging?: boolean,
      enableCycleDprint?: boolean,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<Array<string>> {
      return PlanchangerApiFp(configuration)
        .publishToMes(
          id,
          operation,
          minor,
          markPublished,
          isProveout,
          machinePreCheck,
          machinePostCheck,
          squarenessPreCheck,
          squarenessPostCheck,
          clampAxesAlignments,
          skipCutting,
          skipAlignments,
          skipInspections,
          skipMachineOffsets,
          skipMachineOffsetsLogging,
          enableCycleDprint,
          requestBody,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Refresh Drawing
     * @param {string} drawingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshDrawing(drawingId: string, options?: any): AxiosPromise<TaskProgress> {
      return PlanchangerApiFp(configuration)
        .refreshDrawing(drawingId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Remap Dims
     * @param {string} drawingId
     * @param {{ [key: string]: string; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remapDims(
      drawingId: string,
      requestBody: { [key: string]: string },
      options?: any
    ): AxiosPromise<TaskProgress> {
      return PlanchangerApiFp(configuration)
        .remapDims(drawingId, requestBody, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Repatch Dims
     * @param {string} drawingId
     * @param {{ [key: string]: Dimension; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    repatchDims(
      drawingId: string,
      requestBody: { [key: string]: Dimension },
      options?: any
    ): AxiosPromise<TaskProgress> {
      return PlanchangerApiFp(configuration)
        .repatchDims(drawingId, requestBody, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Tools Geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolsGeometry(options?: any): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .toolsGeometry(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Toolsetting
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toolsetting(toolsettingConfig: ToolsettingConfig, options?: any): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .toolsetting(toolsettingConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Fixture Choice
     * @param {string} planId
     * @param {number} operationIdx
     * @param {FixtureChoice} fixtureChoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFixtureChoice(
      planId: string,
      operationIdx: number,
      fixtureChoice: FixtureChoice,
      options?: any
    ): AxiosPromise<Plan> {
      return PlanchangerApiFp(configuration)
        .updateFixtureChoice(planId, operationIdx, fixtureChoice, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Tools Wear
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsWearUpdate} toolsWearUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateToolsWear(
      planId: string,
      operationIdx: number,
      toolsWearUpdate: ToolsWearUpdate,
      options?: any
    ): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .updateToolsWear(planId, operationIdx, toolsWearUpdate, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Toolsetting
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ToolsettingConfig} toolsettingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateToolsetting(
      planId: string,
      operationIdx: number,
      toolsettingConfig: ToolsettingConfig,
      options?: any
    ): AxiosPromise<string> {
      return PlanchangerApiFp(configuration)
        .updateToolsetting(planId, operationIdx, toolsettingConfig, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * PlanchangerApi - object-oriented interface
 * @export
 * @class PlanchangerApi
 * @extends {BaseAPI}
 */
export class PlanchangerApi extends BaseAPI {
  /**
   *
   * @summary Apply Patch
   * @param {string} id
   * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
   * @param {string} [changeToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public applyPatch(
    id: string,
    addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
      | AddPatchOperation
      | CopyPatchOperation
      | MovePatchOperation
      | RemovePatchOperation
      | ReplacePatchOperation
      | TestPatchOperation
    >,
    changeToken?: string,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .applyPatch(
        id,
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
        changeToken,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_applyPatch(id: string, changeToken?: string): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
    const localVarQueryParameter = {} as any
    if (changeToken !== undefined) {
      localVarQueryParameter["change_token"] = changeToken
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Apply Quality Control Plan Patch
   * @param {string} id
   * @param {Array<AddPatchOperation | CopyPatchOperation | MovePatchOperation | RemovePatchOperation | ReplacePatchOperation | TestPatchOperation>} addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation
   * @param {string} [changeToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public applyQualityControlPlanPatch(
    id: string,
    addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation: Array<
      | AddPatchOperation
      | CopyPatchOperation
      | MovePatchOperation
      | RemovePatchOperation
      | ReplacePatchOperation
      | TestPatchOperation
    >,
    changeToken?: string,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .applyQualityControlPlanPatch(
        id,
        addPatchOperationCopyPatchOperationMovePatchOperationRemovePatchOperationReplacePatchOperationTestPatchOperation,
        changeToken,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_applyQualityControlPlanPatch(id: string, changeToken?: string): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
    const localVarQueryParameter = {} as any
    if (changeToken !== undefined) {
      localVarQueryParameter["change_token"] = changeToken
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Associate Drawing
   * @param {string} jobFileId
   * @param {string} highqaPartId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public associateDrawing(jobFileId: string, highqaPartId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .associateDrawing(jobFileId, highqaPartId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_associateDrawing(jobFileId: string, highqaPartId: string): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/associate-drawing/{job_file_id}`.replace(
        `{${"job_file_id"}}`,
        encodeURIComponent(String(jobFileId))
      )
    )
    const localVarQueryParameter = {} as any
    if (highqaPartId !== undefined) {
      localVarQueryParameter["highqa_part_id"] = highqaPartId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Associate Xdf
   * @param {string} jobId
   * @param {any} file
   * @param {string} [jobFileId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public associateXdf(jobId: string, file: any, jobFileId?: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .associateXdf(jobId, file, jobFileId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_associateXdf(jobId: string, jobFileId?: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/associate-xdf`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (jobFileId !== undefined) {
      localVarQueryParameter["job_file_id"] = jobFileId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Clear Tools Wear
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public clearToolsWear(planId: string, operationIdx: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .clearToolsWear(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_clearToolsWear(planId: string, operationIdx: number): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/clear-tools-wear/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
    )
  }

  /**
   *
   * @summary Get Annotation Sidebar Data
   * @param {string} [planCreatedAfter]
   * @param {string} [jobLabelContains]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getAnnotationSidebarData(
    planCreatedAfter?: string,
    jobLabelContains?: string,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .getAnnotationSidebarData(planCreatedAfter, jobLabelContains, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getAnnotationSidebarData(
    planCreatedAfter?: string,
    jobLabelContains?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/annotation/sidebar`)
    const localVarQueryParameter = {} as any
    if (planCreatedAfter !== undefined) {
      localVarQueryParameter["planCreatedAfter"] =
        (planCreatedAfter as any) instanceof Date
          ? (planCreatedAfter as any).toISOString()
          : planCreatedAfter
    }
    if (jobLabelContains !== undefined) {
      localVarQueryParameter["jobLabelContains"] = jobLabelContains
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Doc
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getDoc(filename: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getDoc(filename, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getDoc(filename: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/manifests/doc{filename}`.replace(
        `{${"filename"}}`,
        encodeURIComponent(String(filename))
      )
    )
  }

  /**
   *
   * @summary Get File
   * @param {string} [key]
   * @param {string} [fileId]
   * @param {boolean} [inline]
   * @param {BucketType} [bucketType]
   * @param {boolean} [ncFormatted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getFile(
    key?: string,
    fileId?: string,
    inline?: boolean,
    bucketType?: BucketType,
    ncFormatted?: boolean,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .getFile(key, fileId, inline, bucketType, ncFormatted, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getFile(
    key?: string,
    fileId?: string,
    inline?: boolean,
    bucketType?: BucketType,
    ncFormatted?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/file`)
    const localVarQueryParameter = {} as any
    if (key !== undefined) {
      localVarQueryParameter["key"] = key
    }
    if (fileId !== undefined) {
      localVarQueryParameter["file_id"] = fileId
    }
    if (inline !== undefined) {
      localVarQueryParameter["inline"] = inline
    }
    if (bucketType !== undefined) {
      localVarQueryParameter["bucket_type"] = bucketType
    }
    if (ncFormatted !== undefined) {
      localVarQueryParameter["nc_formatted"] = ncFormatted
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Locations
   * @param {BucketType} bucket
   * @param {string} prefix
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getLocations(bucket: BucketType, prefix: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getLocations(bucket, prefix, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getLocations(bucket: BucketType, prefix: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/locations/{bucket}/{prefix}`
        .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)))
        .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)))
    )
  }

  /**
   *
   * @summary Get Manifest
   * @param {string} uri
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getManifest(uri: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getManifest(uri, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getManifest(uri: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/manifests/manifest`)
    const localVarQueryParameter = {} as any
    if (uri !== undefined) {
      localVarQueryParameter["uri"] = uri
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Opscene
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getOpscene(planId: string, operationIdx: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getOpscene(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getOpscene(planId: string, operationIdx: number): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/opscene/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
    )
  }

  /**
   *
   * @summary Get Planchanger Jobs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerJobs(options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerJobs(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerJobs(): Promise<URL> {
    return new URL(`${this.basePath}/api/rs/v1/jobs`)
  }

  /**
   *
   * @summary Get Planchanger Plan
   * @param {string} id
   * @param {number} [revision]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerPlan(id: string, revision?: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerPlan(id, revision, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerPlan(id: string, revision?: number): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
    const localVarQueryParameter = {} as any
    if (revision !== undefined) {
      localVarQueryParameter["revision"] = revision
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Planchanger Plan History
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerPlanHistory(id: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerPlanHistory(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerPlanHistory(id: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}/history`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
  }

  /**
   *
   * @summary Get Planchanger Quality Control Plan
   * @param {string} id
   * @param {number} [revision]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerQualityControlPlan(id: string, revision?: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerQualityControlPlan(id, revision, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerQualityControlPlan(
    id: string,
    revision?: number
  ): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
    const localVarQueryParameter = {} as any
    if (revision !== undefined) {
      localVarQueryParameter["revision"] = revision
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Planchanger Valid Probing Feedback
   * @param {string} id
   * @param {string} operationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerValidProbingFeedback(id: string, operationId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerValidProbingFeedback(id, operationId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerValidProbingFeedback(
    id: string,
    operationId: string
  ): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}/probing/valid/{operation_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"operation_id"}}`, encodeURIComponent(String(operationId)))
    )
  }

  /**
   *
   * @summary Get Planchanger Valid Vericut Feedback
   * @param {string} id
   * @param {string} operationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPlanchangerValidVericutFeedback(id: string, operationId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPlanchangerValidVericutFeedback(id, operationId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPlanchangerValidVericutFeedback(
    id: string,
    operationId: string
  ): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}/feedback/valid/{operation_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"operation_id"}}`, encodeURIComponent(String(operationId)))
    )
  }

  /**
   *
   * @summary Get Postprocessor Manifest
   * @param {string} camProgram
   * @param {string} machine
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPostprocessorManifest(camProgram: string, machine: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPostprocessorManifest(camProgram, machine, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPostprocessorManifest(camProgram: string, machine: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/{cam_program}/{machine}/postprocessor-manifest`
        .replace(`{${"cam_program"}}`, encodeURIComponent(String(camProgram)))
        .replace(`{${"machine"}}`, encodeURIComponent(String(machine)))
    )
  }

  /**
   *
   * @summary Get Postprocessors Manifest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getPostprocessorsManifest(options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getPostprocessorsManifest(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getPostprocessorsManifest(): Promise<URL> {
    return new URL(`${this.basePath}/api/rs/v1/postprocessors-manifest`)
  }

  /**
   *
   * @summary Get Tool Operations
   * @param {number} tool
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getToolOperations(tool: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getToolOperations(tool, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getToolOperations(tool: number): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/tool_operations/{tool}`.replace(
        `{${"tool"}}`,
        encodeURIComponent(String(tool))
      )
    )
  }

  /**
   *
   * @summary Get Tools
   * @param {string} application
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getTools(application: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getTools(application, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getTools(application: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/{application}/tools`.replace(
        `{${"application"}}`,
        encodeURIComponent(String(application))
      )
    )
  }

  /**
   *
   * @summary Get Tools Locator
   * @param {string} application
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public getToolsLocator(application: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .getToolsLocator(application, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getToolsLocator(application: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/{application}/tools-locator`.replace(
        `{${"application"}}`,
        encodeURIComponent(String(application))
      )
    )
  }

  /**
   *
   * @summary Nc Program
   * @param {string} planId
   * @param {number} operationIdx
   * @param {any} file
   * @param {boolean} [clearExistingFiles]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public ncProgram(
    planId: string,
    operationIdx: number,
    file: any,
    clearExistingFiles?: boolean,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .ncProgram(planId, operationIdx, file, clearExistingFiles, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_ncProgram(
    planId: string,
    operationIdx: number,
    clearExistingFiles?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/nc-program`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (clearExistingFiles !== undefined) {
      localVarQueryParameter["clear_existing_files"] = clearExistingFiles
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary New File
   * @param {ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload} uploadFileType
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public newFile(
    uploadFileType: ControlPlanUpload | JobUpload | OperationUpload | SimulationUpload,
    file: any,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .newFile(uploadFileType, file, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newFile(): Promise<URL> {
    return new URL(`${this.basePath}/api/rs/v1/file`)
  }

  /**
   *
   * @summary New Part
   * @param {string} customer
   * @param {string} partId
   * @param {any} file
   * @param {boolean} [internal]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public newPart(customer: string, partId: string, file: any, internal?: boolean, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .newPart(customer, partId, file, internal, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_newPart(customer: string, partId: string, internal?: boolean): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/part`)
    const localVarQueryParameter = {} as any
    if (customer !== undefined) {
      localVarQueryParameter["customer"] = customer
    }
    if (partId !== undefined) {
      localVarQueryParameter["part_id"] = partId
    }
    if (internal !== undefined) {
      localVarQueryParameter["internal"] = internal
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Parse Drawing
   * @param {string} camJobFileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public parseDrawing(camJobFileId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .parseDrawing(camJobFileId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_parseDrawing(camJobFileId: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/parse-drawing/{cam_job_file_id}`.replace(
        `{${"cam_job_file_id"}}`,
        encodeURIComponent(String(camJobFileId))
      )
    )
  }

  /**
   *
   * @summary Post Planchanger Plan
   * @param {string} id
   * @param {Plan} plan
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public postPlanchangerPlan(id: string, plan: Plan, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .postPlanchangerPlan(id, plan, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_postPlanchangerPlan(id: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/patches/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
  }

  /**
   *
   * @summary Post Planchanger Quality Control Plan
   * @param {string} id
   * @param {QualityControlPlanData} qualityControlPlanData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public postPlanchangerQualityControlPlan(
    id: string,
    qualityControlPlanData: QualityControlPlanData,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .postPlanchangerQualityControlPlan(id, qualityControlPlanData, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_postPlanchangerQualityControlPlan(id: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/patches/quality-control-plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
  }

  /**
   *
   * @summary Publish Drawing
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishDrawing(jobId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .publishDrawing(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishDrawing(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/manifests/publish_drawing`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Publish Job
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishJob(jobId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .publishJob(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishJob(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/manifests/publish_job`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Publish Output Stock
   * @param {string} runId
   * @param {string} feedbackId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishOutputStock(runId: string, feedbackId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .publishOutputStock(runId, feedbackId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishOutputStock(runId: string, feedbackId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/manifests/publish_output_stock`)
    const localVarQueryParameter = {} as any
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    if (feedbackId !== undefined) {
      localVarQueryParameter["feedback_id"] = feedbackId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Publish Quality Control Plan
   * @param {string} camPlanId
   * @param {boolean} [minor]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishQualityControlPlan(camPlanId: string, minor?: boolean, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .publishQualityControlPlan(camPlanId, minor, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishQualityControlPlan(camPlanId: string, minor?: boolean): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/publish/quality-control-plan/{cam_plan_id}`.replace(
        `{${"cam_plan_id"}}`,
        encodeURIComponent(String(camPlanId))
      )
    )
    const localVarQueryParameter = {} as any
    if (minor !== undefined) {
      localVarQueryParameter["minor"] = minor
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Publish Setup
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishSetup(planId: string, operationIdx: number, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .publishSetup(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishSetup(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/manifests/publish_setup`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Publish To Mes
   * @param {string} id
   * @param {number} [operation]
   * @param {boolean} [minor]
   * @param {boolean} [markPublished]
   * @param {boolean} [isProveout]
   * @param {boolean} [machinePreCheck]
   * @param {boolean} [machinePostCheck]
   * @param {boolean} [squarenessPreCheck]
   * @param {boolean} [squarenessPostCheck]
   * @param {boolean} [clampAxesAlignments]
   * @param {boolean} [skipCutting]
   * @param {boolean} [skipAlignments]
   * @param {boolean} [skipInspections]
   * @param {boolean} [skipMachineOffsets]
   * @param {boolean} [skipMachineOffsetsLogging]
   * @param {boolean} [enableCycleDprint]
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public publishToMes(
    id: string,
    operation?: number,
    minor?: boolean,
    markPublished?: boolean,
    isProveout?: boolean,
    machinePreCheck?: boolean,
    machinePostCheck?: boolean,
    squarenessPreCheck?: boolean,
    squarenessPostCheck?: boolean,
    clampAxesAlignments?: boolean,
    skipCutting?: boolean,
    skipAlignments?: boolean,
    skipInspections?: boolean,
    skipMachineOffsets?: boolean,
    skipMachineOffsetsLogging?: boolean,
    enableCycleDprint?: boolean,
    requestBody?: Array<string>,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .publishToMes(
        id,
        operation,
        minor,
        markPublished,
        isProveout,
        machinePreCheck,
        machinePostCheck,
        squarenessPreCheck,
        squarenessPostCheck,
        clampAxesAlignments,
        skipCutting,
        skipAlignments,
        skipInspections,
        skipMachineOffsets,
        skipMachineOffsetsLogging,
        enableCycleDprint,
        requestBody,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_publishToMes(
    id: string,
    operation?: number,
    minor?: boolean,
    markPublished?: boolean,
    isProveout?: boolean,
    machinePreCheck?: boolean,
    machinePostCheck?: boolean,
    squarenessPreCheck?: boolean,
    squarenessPostCheck?: boolean,
    clampAxesAlignments?: boolean,
    skipCutting?: boolean,
    skipAlignments?: boolean,
    skipInspections?: boolean,
    skipMachineOffsets?: boolean,
    skipMachineOffsetsLogging?: boolean,
    enableCycleDprint?: boolean
  ): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/publish/plan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
    )
    const localVarQueryParameter = {} as any
    if (operation !== undefined) {
      localVarQueryParameter["operation"] = operation
    }
    if (minor !== undefined) {
      localVarQueryParameter["minor"] = minor
    }
    if (markPublished !== undefined) {
      localVarQueryParameter["mark_published"] = markPublished
    }
    if (isProveout !== undefined) {
      localVarQueryParameter["is_proveout"] = isProveout
    }
    if (machinePreCheck !== undefined) {
      localVarQueryParameter["machine_pre_check"] = machinePreCheck
    }
    if (machinePostCheck !== undefined) {
      localVarQueryParameter["machine_post_check"] = machinePostCheck
    }
    if (squarenessPreCheck !== undefined) {
      localVarQueryParameter["squareness_pre_check"] = squarenessPreCheck
    }
    if (squarenessPostCheck !== undefined) {
      localVarQueryParameter["squareness_post_check"] = squarenessPostCheck
    }
    if (clampAxesAlignments !== undefined) {
      localVarQueryParameter["clamp_axes_alignments"] = clampAxesAlignments
    }
    if (skipCutting !== undefined) {
      localVarQueryParameter["skip_cutting"] = skipCutting
    }
    if (skipAlignments !== undefined) {
      localVarQueryParameter["skip_alignments"] = skipAlignments
    }
    if (skipInspections !== undefined) {
      localVarQueryParameter["skip_inspections"] = skipInspections
    }
    if (skipMachineOffsets !== undefined) {
      localVarQueryParameter["skip_machine_offsets"] = skipMachineOffsets
    }
    if (skipMachineOffsetsLogging !== undefined) {
      localVarQueryParameter["skip_machine_offsets_logging"] = skipMachineOffsetsLogging
    }
    if (enableCycleDprint !== undefined) {
      localVarQueryParameter["enable_cycle_dprint"] = enableCycleDprint
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Refresh Drawing
   * @param {string} drawingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public refreshDrawing(drawingId: string, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .refreshDrawing(drawingId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_refreshDrawing(drawingId: string): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/refresh-drawing/{drawing_id}`.replace(
        `{${"drawing_id"}}`,
        encodeURIComponent(String(drawingId))
      )
    )
  }

  /**
   *
   * @summary Remap Dims
   * @param {string} drawingId
   * @param {{ [key: string]: string; }} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public remapDims(drawingId: string, requestBody: { [key: string]: string }, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .remapDims(drawingId, requestBody, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_remapDims(drawingId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/remap-dims`)
    const localVarQueryParameter = {} as any
    if (drawingId !== undefined) {
      localVarQueryParameter["drawing_id"] = drawingId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Repatch Dims
   * @param {string} drawingId
   * @param {{ [key: string]: Dimension; }} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public repatchDims(drawingId: string, requestBody: { [key: string]: Dimension }, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .repatchDims(drawingId, requestBody, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_repatchDims(drawingId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/rs/v1/repatch-dims`)
    const localVarQueryParameter = {} as any
    if (drawingId !== undefined) {
      localVarQueryParameter["drawing_id"] = drawingId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Tools Geometry
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public toolsGeometry(options?: any) {
    return PlanchangerApiFp(this.configuration)
      .toolsGeometry(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_toolsGeometry(): Promise<URL> {
    return new URL(`${this.basePath}/api/rs/v1/tools-geometry`)
  }

  /**
   *
   * @summary Toolsetting
   * @param {ToolsettingConfig} toolsettingConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public toolsetting(toolsettingConfig: ToolsettingConfig, options?: any) {
    return PlanchangerApiFp(this.configuration)
      .toolsetting(toolsettingConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_toolsetting(): Promise<URL> {
    return new URL(`${this.basePath}/api/rs/v1/toolsetting`)
  }

  /**
   *
   * @summary Update Fixture Choice
   * @param {string} planId
   * @param {number} operationIdx
   * @param {FixtureChoice} fixtureChoice
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public updateFixtureChoice(
    planId: string,
    operationIdx: number,
    fixtureChoice: FixtureChoice,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .updateFixtureChoice(planId, operationIdx, fixtureChoice, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateFixtureChoice(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(
      `${this.basePath}/api/rs/v1/plan/{plan_id}/fixture-choice`.replace(
        `{${"plan_id"}}`,
        encodeURIComponent(String(planId))
      )
    )
    const localVarQueryParameter = {} as any
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Tools Wear
   * @param {string} planId
   * @param {number} operationIdx
   * @param {ToolsWearUpdate} toolsWearUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public updateToolsWear(
    planId: string,
    operationIdx: number,
    toolsWearUpdate: ToolsWearUpdate,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .updateToolsWear(planId, operationIdx, toolsWearUpdate, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateToolsWear(planId: string, operationIdx: number): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/update-tools-wear/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
    )
  }

  /**
   *
   * @summary Update Toolsetting
   * @param {string} planId
   * @param {number} operationIdx
   * @param {ToolsettingConfig} toolsettingConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanchangerApi
   */
  public updateToolsetting(
    planId: string,
    operationIdx: number,
    toolsettingConfig: ToolsettingConfig,
    options?: any
  ) {
    return PlanchangerApiFp(this.configuration)
      .updateToolsetting(planId, operationIdx, toolsettingConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateToolsetting(planId: string, operationIdx: number): Promise<URL> {
    return new URL(
      `${this.basePath}/api/rs/v1/update/toolsetting/{plan_id}/{operation_idx}`
        .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
        .replace(`{${"operation_idx"}}`, encodeURIComponent(String(operationIdx)))
    )
  }
}

/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Associate Pcdmis Extractor With Simulation
     * @param {string} planId
     * @param {string} pcdmisExtractorManifestUri
     * @param {string} simManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associatePcdmisExtractorWithSimulation: async (
      planId: string,
      pcdmisExtractorManifestUri: string,
      simManifestUri: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling associatePcdmisExtractorWithSimulation."
        )
      }
      // verify required parameter 'pcdmisExtractorManifestUri' is not null or undefined
      if (pcdmisExtractorManifestUri === null || pcdmisExtractorManifestUri === undefined) {
        throw new RequiredError(
          "pcdmisExtractorManifestUri",
          "Required parameter pcdmisExtractorManifestUri was null or undefined when calling associatePcdmisExtractorWithSimulation."
        )
      }
      // verify required parameter 'simManifestUri' is not null or undefined
      if (simManifestUri === null || simManifestUri === undefined) {
        throw new RequiredError(
          "simManifestUri",
          "Required parameter simManifestUri was null or undefined when calling associatePcdmisExtractorWithSimulation."
        )
      }
      const localVarPath = `/api/v1/plan/assoc-pcdmis-sim`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (pcdmisExtractorManifestUri !== undefined) {
        localVarQueryParameter["pcdmis_extractor_manifest_uri"] = pcdmisExtractorManifestUri
      }

      if (simManifestUri !== undefined) {
        localVarQueryParameter["sim_manifest_uri"] = simManifestUri
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Cam Since Last Run Changes
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    camSinceLastRunChanges: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling camSinceLastRunChanges."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling camSinceLastRunChanges."
        )
      }
      const localVarPath = `/api/v1/plan/cam-since-last-run-changes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Copy Mirrored Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyMirroredSetup: async (
      pasteSetupRequest: PasteSetupRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'pasteSetupRequest' is not null or undefined
      if (pasteSetupRequest === null || pasteSetupRequest === undefined) {
        throw new RequiredError(
          "pasteSetupRequest",
          "Required parameter pasteSetupRequest was null or undefined when calling copyMirroredSetup."
        )
      }
      const localVarPath = `/api/v1/plan/mirror-setup`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof pasteSetupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pasteSetupRequest !== undefined ? pasteSetupRequest : {})
        : pasteSetupRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Copy Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copySetup: async (
      pasteSetupRequest: PasteSetupRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'pasteSetupRequest' is not null or undefined
      if (pasteSetupRequest === null || pasteSetupRequest === undefined) {
        throw new RequiredError(
          "pasteSetupRequest",
          "Required parameter pasteSetupRequest was null or undefined when calling copySetup."
        )
      }
      const localVarPath = `/api/v1/plan/copy-setup`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof pasteSetupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pasteSetupRequest !== undefined ? pasteSetupRequest : {})
        : pasteSetupRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Copy Stock
     * @param {PasteStockRequest} pasteStockRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyStock: async (
      pasteStockRequest: PasteStockRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'pasteStockRequest' is not null or undefined
      if (pasteStockRequest === null || pasteStockRequest === undefined) {
        throw new RequiredError(
          "pasteStockRequest",
          "Required parameter pasteStockRequest was null or undefined when calling copyStock."
        )
      }
      const localVarPath = `/api/v1/plan/copy-stock`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof pasteStockRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pasteStockRequest !== undefined ? pasteStockRequest : {})
        : pasteStockRequest || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Fixture Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFixtureOperation: async (
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createFixtureOperation."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling createFixtureOperation."
        )
      }
      // verify required parameter 'fixtureChoiceElementId' is not null or undefined
      if (fixtureChoiceElementId === null || fixtureChoiceElementId === undefined) {
        throw new RequiredError(
          "fixtureChoiceElementId",
          "Required parameter fixtureChoiceElementId was null or undefined when calling createFixtureOperation."
        )
      }
      const localVarPath = `/api/v1/plan/create-custom-fixture-operation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      if (fixtureChoiceElementId !== undefined) {
        localVarQueryParameter["fixtureChoiceElementId"] = fixtureChoiceElementId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} [copyOperationIdx]
     * @param {boolean} [transferInputStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperation: async (
      planId: string,
      operationIdx: number,
      copyOperationIdx?: number,
      transferInputStock?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createOperation."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling createOperation."
        )
      }
      const localVarPath = `/api/v1/plan/operation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (copyOperationIdx !== undefined) {
        localVarQueryParameter["copy_operation_idx"] = copyOperationIdx
      }

      if (transferInputStock !== undefined) {
        localVarQueryParameter["transfer_input_stock"] = transferInputStock
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Plan
     * @param {string} jobId
     * @param {string} [label]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlan: async (jobId: string, label?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling createPlan."
        )
      }
      const localVarPath = `/api/v1/plan/create-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId
      }

      if (label !== undefined) {
        localVarQueryParameter["label"] = label
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperation: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling deleteOperation."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling deleteOperation."
        )
      }
      const localVarPath = `/api/v1/plan/operation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Duplicate Plan
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicatePlan: async (planId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling duplicatePlan."
        )
      }
      const localVarPath = `/api/v1/plan/duplicate-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Enable Grob Toolchecks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableGrobToolchecks: async (
      planId: string,
      operationIdx: number,
      enable: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling enableGrobToolchecks."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling enableGrobToolchecks."
        )
      }
      // verify required parameter 'enable' is not null or undefined
      if (enable === null || enable === undefined) {
        throw new RequiredError(
          "enable",
          "Required parameter enable was null or undefined when calling enableGrobToolchecks."
        )
      }
      const localVarPath = `/api/v1/plan/enable-grob-toolchecks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (enable !== undefined) {
        localVarQueryParameter["enable"] = enable
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Enable Machine Offsets
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMachineOffsets: async (
      planId: string,
      operationIdx: number,
      enable: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling enableMachineOffsets."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling enableMachineOffsets."
        )
      }
      // verify required parameter 'enable' is not null or undefined
      if (enable === null || enable === undefined) {
        throw new RequiredError(
          "enable",
          "Required parameter enable was null or undefined when calling enableMachineOffsets."
        )
      }
      const localVarPath = `/api/v1/plan/enable-machine-offsets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (enable !== undefined) {
        localVarQueryParameter["enable"] = enable
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Enable Machine Offsets Logging
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMachineOffsetsLogging: async (
      planId: string,
      operationIdx: number,
      enable: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling enableMachineOffsetsLogging."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling enableMachineOffsetsLogging."
        )
      }
      // verify required parameter 'enable' is not null or undefined
      if (enable === null || enable === undefined) {
        throw new RequiredError(
          "enable",
          "Required parameter enable was null or undefined when calling enableMachineOffsetsLogging."
        )
      }
      const localVarPath = `/api/v1/plan/enable-machine-offsets-logging`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (enable !== undefined) {
        localVarQueryParameter["enable"] = enable
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Guardrails Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuardrailsManifestData: async (
      manifestUri: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'manifestUri' is not null or undefined
      if (manifestUri === null || manifestUri === undefined) {
        throw new RequiredError(
          "manifestUri",
          "Required parameter manifestUri was null or undefined when calling getGuardrailsManifestData."
        )
      }
      const localVarPath = `/api/v1/plan/guardrails-manifest-data`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (manifestUri !== undefined) {
        localVarQueryParameter["manifest_uri"] = manifestUri
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Vericut Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVericutManifestData: async (
      manifestUri: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'manifestUri' is not null or undefined
      if (manifestUri === null || manifestUri === undefined) {
        throw new RequiredError(
          "manifestUri",
          "Required parameter manifestUri was null or undefined when calling getVericutManifestData."
        )
      }
      const localVarPath = `/api/v1/plan/vericut-manifest-data`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (manifestUri !== undefined) {
        localVarQueryParameter["manifest_uri"] = manifestUri
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Postprocess Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ProbingPostprocessingConfig} probingPostprocessingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postprocessProbing: async (
      planId: string,
      operationIdx: number,
      probingPostprocessingConfig: ProbingPostprocessingConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling postprocessProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling postprocessProbing."
        )
      }
      // verify required parameter 'probingPostprocessingConfig' is not null or undefined
      if (probingPostprocessingConfig === null || probingPostprocessingConfig === undefined) {
        throw new RequiredError(
          "probingPostprocessingConfig",
          "Required parameter probingPostprocessingConfig was null or undefined when calling postprocessProbing."
        )
      }
      const localVarPath = `/api/v1/plan/postprocess-probing`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof probingPostprocessingConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            probingPostprocessingConfig !== undefined ? probingPostprocessingConfig : {}
          )
        : probingPostprocessingConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Record Machine Offset Diff
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordMachineOffsetDiff: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling recordMachineOffsetDiff."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling recordMachineOffsetDiff."
        )
      }
      const localVarPath = `/api/v1/plan/record-machine-offset-diff`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Record Vericut Archive
     * @param {string} taskId
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutArchive: async (
      taskId: string,
      jobId: string,
      fileId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          "taskId",
          "Required parameter taskId was null or undefined when calling recordVericutArchive."
        )
      }
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling recordVericutArchive."
        )
      }
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling recordVericutArchive."
        )
      }
      const localVarPath = `/api/v1/plan/record-vericut-archive`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (taskId !== undefined) {
        localVarQueryParameter["task_id"] = taskId
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (fileId !== undefined) {
        localVarQueryParameter["file_id"] = fileId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Record Vericut Reviewer Id
     * @param {string} feedbackId
     * @param {string} vcReviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutReviewerId: async (
      feedbackId: string,
      vcReviewId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'feedbackId' is not null or undefined
      if (feedbackId === null || feedbackId === undefined) {
        throw new RequiredError(
          "feedbackId",
          "Required parameter feedbackId was null or undefined when calling recordVericutReviewerId."
        )
      }
      // verify required parameter 'vcReviewId' is not null or undefined
      if (vcReviewId === null || vcReviewId === undefined) {
        throw new RequiredError(
          "vcReviewId",
          "Required parameter vcReviewId was null or undefined when calling recordVericutReviewerId."
        )
      }
      const localVarPath = `/api/v1/plan/record-vericut-reviewer-id`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (feedbackId !== undefined) {
        localVarQueryParameter["feedback_id"] = feedbackId
      }

      if (vcReviewId !== undefined) {
        localVarQueryParameter["vc_review_id"] = vcReviewId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Record Vericut Sim Manifest
     * @param {string} feedbackId
     * @param {string} newManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutSimManifest: async (
      feedbackId: string,
      newManifestUri: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'feedbackId' is not null or undefined
      if (feedbackId === null || feedbackId === undefined) {
        throw new RequiredError(
          "feedbackId",
          "Required parameter feedbackId was null or undefined when calling recordVericutSimManifest."
        )
      }
      // verify required parameter 'newManifestUri' is not null or undefined
      if (newManifestUri === null || newManifestUri === undefined) {
        throw new RequiredError(
          "newManifestUri",
          "Required parameter newManifestUri was null or undefined when calling recordVericutSimManifest."
        )
      }
      const localVarPath = `/api/v1/plan/record-vericut-sim-manifest`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (feedbackId !== undefined) {
        localVarQueryParameter["feedback_id"] = feedbackId
      }

      if (newManifestUri !== undefined) {
        localVarQueryParameter["new_manifest_uri"] = newManifestUri
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Refresh Pcdmis Extractor Units
     * @param {string} planId
     * @param {string} sku
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshPcdmisExtractorUnits: async (
      planId: string,
      sku: string,
      limit: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling refreshPcdmisExtractorUnits."
        )
      }
      // verify required parameter 'sku' is not null or undefined
      if (sku === null || sku === undefined) {
        throw new RequiredError(
          "sku",
          "Required parameter sku was null or undefined when calling refreshPcdmisExtractorUnits."
        )
      }
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          "limit",
          "Required parameter limit was null or undefined when calling refreshPcdmisExtractorUnits."
        )
      }
      const localVarPath = `/api/v1/plan/refresh-pcdmis-extractor-units`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (sku !== undefined) {
        localVarQueryParameter["sku"] = sku
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Unlink Cam Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkCamProject: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling unlinkCamProject."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling unlinkCamProject."
        )
      }
      const localVarPath = `/api/v1/plan/unlink-cam-project`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Id
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomFixtureTransformById: async (
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      coordinates: ExportCoordinates,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateCustomFixtureTransformById."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateCustomFixtureTransformById."
        )
      }
      // verify required parameter 'fixtureChoiceElementId' is not null or undefined
      if (fixtureChoiceElementId === null || fixtureChoiceElementId === undefined) {
        throw new RequiredError(
          "fixtureChoiceElementId",
          "Required parameter fixtureChoiceElementId was null or undefined when calling updateCustomFixtureTransformById."
        )
      }
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          "coordinates",
          "Required parameter coordinates was null or undefined when calling updateCustomFixtureTransformById."
        )
      }
      const localVarPath = `/api/v1/plan/update-custom-fixture-transform-by-id`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (fixtureChoiceElementId !== undefined) {
        localVarQueryParameter["fixture_choice_element_id"] = fixtureChoiceElementId
      }

      if (coordinates !== undefined) {
        localVarQueryParameter["coordinates"] = coordinates
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Name
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementName
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomFixtureTransformByName: async (
      planId: string,
      operationIdx: number,
      fixtureChoiceElementName: string,
      coordinates: ExportCoordinates,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateCustomFixtureTransformByName."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateCustomFixtureTransformByName."
        )
      }
      // verify required parameter 'fixtureChoiceElementName' is not null or undefined
      if (fixtureChoiceElementName === null || fixtureChoiceElementName === undefined) {
        throw new RequiredError(
          "fixtureChoiceElementName",
          "Required parameter fixtureChoiceElementName was null or undefined when calling updateCustomFixtureTransformByName."
        )
      }
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          "coordinates",
          "Required parameter coordinates was null or undefined when calling updateCustomFixtureTransformByName."
        )
      }
      const localVarPath = `/api/v1/plan/update-custom-fixture-transform-by-name`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (fixtureChoiceElementName !== undefined) {
        localVarQueryParameter["fixture_choice_element_name"] = fixtureChoiceElementName
      }

      if (coordinates !== undefined) {
        localVarQueryParameter["coordinates"] = coordinates
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Cut Config
     * @param {string} planId
     * @param {number} operationIdx
     * @param {CutConfig} [cutConfig]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCutConfig: async (
      planId: string,
      operationIdx: number,
      cutConfig?: CutConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling updateCutConfig."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling updateCutConfig."
        )
      }
      const localVarPath = `/api/v1/plan/update-cut-config`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof cutConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cutConfig !== undefined ? cutConfig : {})
        : cutConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Validate Vericut Import
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVericutImport: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling validateVericutImport."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling validateVericutImport."
        )
      }
      const localVarPath = `/api/v1/plan/validate-vericut-import`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Associate Pcdmis Extractor With Simulation
     * @param {string} planId
     * @param {string} pcdmisExtractorManifestUri
     * @param {string} simManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async associatePcdmisExtractorWithSimulation(
      planId: string,
      pcdmisExtractorManifestUri: string,
      simManifestUri: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).associatePcdmisExtractorWithSimulation(
        planId,
        pcdmisExtractorManifestUri,
        simManifestUri,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Cam Since Last Run Changes
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async camSinceLastRunChanges(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).camSinceLastRunChanges(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Copy Mirrored Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async copyMirroredSetup(
      pasteSetupRequest: PasteSetupRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).copyMirroredSetup(
        pasteSetupRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Copy Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async copySetup(
      pasteSetupRequest: PasteSetupRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).copySetup(
        pasteSetupRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Copy Stock
     * @param {PasteStockRequest} pasteStockRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async copyStock(
      pasteStockRequest: PasteStockRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).copyStock(
        pasteStockRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Fixture Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFixtureOperation(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).createFixtureOperation(planId, operationIdx, fixtureChoiceElementId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} [copyOperationIdx]
     * @param {boolean} [transferInputStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOperation(
      planId: string,
      operationIdx: number,
      copyOperationIdx?: number,
      transferInputStock?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createOperation(
        planId,
        operationIdx,
        copyOperationIdx,
        transferInputStock,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Plan
     * @param {string} jobId
     * @param {string} [label]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPlan(
      jobId: string,
      label?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createPlan(
        jobId,
        label,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Delete Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOperation(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).deleteOperation(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Duplicate Plan
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicatePlan(
      planId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).duplicatePlan(
        planId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Enable Grob Toolchecks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableGrobToolchecks(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).enableGrobToolchecks(
        planId,
        operationIdx,
        enable,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Enable Machine Offsets
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableMachineOffsets(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).enableMachineOffsets(
        planId,
        operationIdx,
        enable,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Enable Machine Offsets Logging
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableMachineOffsetsLogging(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).enableMachineOffsetsLogging(planId, operationIdx, enable, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Guardrails Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuardrailsManifestData(
      manifestUri: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GuardrailFeedback>>
    > {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).getGuardrailsManifestData(manifestUri, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Get Vericut Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVericutManifestData(
      manifestUri: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VericutManifestDataResponse>
    > {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).getVericutManifestData(manifestUri, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Postprocess Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ProbingPostprocessingConfig} probingPostprocessingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postprocessProbing(
      planId: string,
      operationIdx: number,
      probingPostprocessingConfig: ProbingPostprocessingConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProbingPost>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).postprocessProbing(
        planId,
        operationIdx,
        probingPostprocessingConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Record Machine Offset Diff
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordMachineOffsetDiff(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).recordMachineOffsetDiff(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Record Vericut Archive
     * @param {string} taskId
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordVericutArchive(
      taskId: string,
      jobId: string,
      fileId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).recordVericutArchive(
        taskId,
        jobId,
        fileId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Record Vericut Reviewer Id
     * @param {string} feedbackId
     * @param {string} vcReviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordVericutReviewerId(
      feedbackId: string,
      vcReviewId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).recordVericutReviewerId(feedbackId, vcReviewId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Record Vericut Sim Manifest
     * @param {string} feedbackId
     * @param {string} newManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordVericutSimManifest(
      feedbackId: string,
      newManifestUri: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).recordVericutSimManifest(feedbackId, newManifestUri, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Refresh Pcdmis Extractor Units
     * @param {string} planId
     * @param {string} sku
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshPcdmisExtractorUnits(
      planId: string,
      sku: string,
      limit: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).refreshPcdmisExtractorUnits(planId, sku, limit, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Unlink Cam Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlinkCamProject(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).unlinkCamProject(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Id
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomFixtureTransformById(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      coordinates: ExportCoordinates,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).updateCustomFixtureTransformById(
        planId,
        operationIdx,
        fixtureChoiceElementId,
        coordinates,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Name
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementName
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomFixtureTransformByName(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementName: string,
      coordinates: ExportCoordinates,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).updateCustomFixtureTransformByName(
        planId,
        operationIdx,
        fixtureChoiceElementName,
        coordinates,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Update Cut Config
     * @param {string} planId
     * @param {number} operationIdx
     * @param {CutConfig} [cutConfig]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCutConfig(
      planId: string,
      operationIdx: number,
      cutConfig?: CutConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).updateCutConfig(
        planId,
        operationIdx,
        cutConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Validate Vericut Import
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateVericutImport(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PlansApiAxiosParamCreator(
        configuration
      ).validateVericutImport(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Associate Pcdmis Extractor With Simulation
     * @param {string} planId
     * @param {string} pcdmisExtractorManifestUri
     * @param {string} simManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    associatePcdmisExtractorWithSimulation(
      planId: string,
      pcdmisExtractorManifestUri: string,
      simManifestUri: string,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .associatePcdmisExtractorWithSimulation(
          planId,
          pcdmisExtractorManifestUri,
          simManifestUri,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Cam Since Last Run Changes
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    camSinceLastRunChanges(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<Array<string>> {
      return PlansApiFp(configuration)
        .camSinceLastRunChanges(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Copy Mirrored Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyMirroredSetup(pasteSetupRequest: PasteSetupRequest, options?: any): AxiosPromise<TaskData> {
      return PlansApiFp(configuration)
        .copyMirroredSetup(pasteSetupRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Copy Setup
     * @param {PasteSetupRequest} pasteSetupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copySetup(pasteSetupRequest: PasteSetupRequest, options?: any): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .copySetup(pasteSetupRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Copy Stock
     * @param {PasteStockRequest} pasteStockRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyStock(pasteStockRequest: PasteStockRequest, options?: any): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .copyStock(pasteStockRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Fixture Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFixtureOperation(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      options?: any
    ): AxiosPromise<number> {
      return PlansApiFp(configuration)
        .createFixtureOperation(planId, operationIdx, fixtureChoiceElementId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} [copyOperationIdx]
     * @param {boolean} [transferInputStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperation(
      planId: string,
      operationIdx: number,
      copyOperationIdx?: number,
      transferInputStock?: boolean,
      options?: any
    ): AxiosPromise<string> {
      return PlansApiFp(configuration)
        .createOperation(planId, operationIdx, copyOperationIdx, transferInputStock, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Plan
     * @param {string} jobId
     * @param {string} [label]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlan(jobId: string, label?: string, options?: any): AxiosPromise<string> {
      return PlansApiFp(configuration)
        .createPlan(jobId, label, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperation(planId: string, operationIdx: number, options?: any): AxiosPromise<string> {
      return PlansApiFp(configuration)
        .deleteOperation(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Duplicate Plan
     * @param {string} planId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicatePlan(planId: string, options?: any): AxiosPromise<string> {
      return PlansApiFp(configuration)
        .duplicatePlan(planId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Enable Grob Toolchecks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableGrobToolchecks(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .enableGrobToolchecks(planId, operationIdx, enable, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Enable Machine Offsets
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMachineOffsets(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .enableMachineOffsets(planId, operationIdx, enable, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Enable Machine Offsets Logging
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMachineOffsetsLogging(
      planId: string,
      operationIdx: number,
      enable: boolean,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .enableMachineOffsetsLogging(planId, operationIdx, enable, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Guardrails Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuardrailsManifestData(
      manifestUri: string,
      options?: any
    ): AxiosPromise<Array<GuardrailFeedback>> {
      return PlansApiFp(configuration)
        .getGuardrailsManifestData(manifestUri, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get Vericut Manifest Data
     * @param {string} manifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVericutManifestData(
      manifestUri: string,
      options?: any
    ): AxiosPromise<VericutManifestDataResponse> {
      return PlansApiFp(configuration)
        .getVericutManifestData(manifestUri, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Postprocess Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ProbingPostprocessingConfig} probingPostprocessingConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postprocessProbing(
      planId: string,
      operationIdx: number,
      probingPostprocessingConfig: ProbingPostprocessingConfig,
      options?: any
    ): AxiosPromise<ProbingPost> {
      return PlansApiFp(configuration)
        .postprocessProbing(planId, operationIdx, probingPostprocessingConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Record Machine Offset Diff
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordMachineOffsetDiff(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .recordMachineOffsetDiff(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Record Vericut Archive
     * @param {string} taskId
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutArchive(
      taskId: string,
      jobId: string,
      fileId: string,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .recordVericutArchive(taskId, jobId, fileId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Record Vericut Reviewer Id
     * @param {string} feedbackId
     * @param {string} vcReviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutReviewerId(
      feedbackId: string,
      vcReviewId: string,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .recordVericutReviewerId(feedbackId, vcReviewId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Record Vericut Sim Manifest
     * @param {string} feedbackId
     * @param {string} newManifestUri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordVericutSimManifest(
      feedbackId: string,
      newManifestUri: string,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .recordVericutSimManifest(feedbackId, newManifestUri, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Refresh Pcdmis Extractor Units
     * @param {string} planId
     * @param {string} sku
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshPcdmisExtractorUnits(
      planId: string,
      sku: string,
      limit: number,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .refreshPcdmisExtractorUnits(planId, sku, limit, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Unlink Cam Project
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkCamProject(planId: string, operationIdx: number, options?: any): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .unlinkCamProject(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Id
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomFixtureTransformById(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementId: string,
      coordinates: ExportCoordinates,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .updateCustomFixtureTransformById(
          planId,
          operationIdx,
          fixtureChoiceElementId,
          coordinates,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Custom Fixture Transform By Name
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} fixtureChoiceElementName
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomFixtureTransformByName(
      planId: string,
      operationIdx: number,
      fixtureChoiceElementName: string,
      coordinates: ExportCoordinates,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .updateCustomFixtureTransformByName(
          planId,
          operationIdx,
          fixtureChoiceElementName,
          coordinates,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update Cut Config
     * @param {string} planId
     * @param {number} operationIdx
     * @param {CutConfig} [cutConfig]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCutConfig(
      planId: string,
      operationIdx: number,
      cutConfig?: CutConfig,
      options?: any
    ): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .updateCutConfig(planId, operationIdx, cutConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Validate Vericut Import
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVericutImport(planId: string, operationIdx: number, options?: any): AxiosPromise<void> {
      return PlansApiFp(configuration)
        .validateVericutImport(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
  /**
   *
   * @summary Associate Pcdmis Extractor With Simulation
   * @param {string} planId
   * @param {string} pcdmisExtractorManifestUri
   * @param {string} simManifestUri
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public associatePcdmisExtractorWithSimulation(
    planId: string,
    pcdmisExtractorManifestUri: string,
    simManifestUri: string,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .associatePcdmisExtractorWithSimulation(
        planId,
        pcdmisExtractorManifestUri,
        simManifestUri,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_associatePcdmisExtractorWithSimulation(
    planId: string,
    pcdmisExtractorManifestUri: string,
    simManifestUri: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/assoc-pcdmis-sim`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (pcdmisExtractorManifestUri !== undefined) {
      localVarQueryParameter["pcdmis_extractor_manifest_uri"] = pcdmisExtractorManifestUri
    }
    if (simManifestUri !== undefined) {
      localVarQueryParameter["sim_manifest_uri"] = simManifestUri
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Cam Since Last Run Changes
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public camSinceLastRunChanges(planId: string, operationIdx: number, options?: any) {
    return PlansApiFp(this.configuration)
      .camSinceLastRunChanges(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_camSinceLastRunChanges(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/cam-since-last-run-changes`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Copy Mirrored Setup
   * @param {PasteSetupRequest} pasteSetupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public copyMirroredSetup(pasteSetupRequest: PasteSetupRequest, options?: any) {
    return PlansApiFp(this.configuration)
      .copyMirroredSetup(pasteSetupRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_copyMirroredSetup(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/plan/mirror-setup`)
  }

  /**
   *
   * @summary Copy Setup
   * @param {PasteSetupRequest} pasteSetupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public copySetup(pasteSetupRequest: PasteSetupRequest, options?: any) {
    return PlansApiFp(this.configuration)
      .copySetup(pasteSetupRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_copySetup(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/plan/copy-setup`)
  }

  /**
   *
   * @summary Copy Stock
   * @param {PasteStockRequest} pasteStockRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public copyStock(pasteStockRequest: PasteStockRequest, options?: any) {
    return PlansApiFp(this.configuration)
      .copyStock(pasteStockRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_copyStock(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/plan/copy-stock`)
  }

  /**
   *
   * @summary Create Fixture Operation
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} fixtureChoiceElementId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public createFixtureOperation(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementId: string,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .createFixtureOperation(planId, operationIdx, fixtureChoiceElementId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createFixtureOperation(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementId: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/create-custom-fixture-operation`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    if (fixtureChoiceElementId !== undefined) {
      localVarQueryParameter["fixtureChoiceElementId"] = fixtureChoiceElementId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Operation
   * @param {string} planId
   * @param {number} operationIdx
   * @param {number} [copyOperationIdx]
   * @param {boolean} [transferInputStock]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public createOperation(
    planId: string,
    operationIdx: number,
    copyOperationIdx?: number,
    transferInputStock?: boolean,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .createOperation(planId, operationIdx, copyOperationIdx, transferInputStock, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createOperation(
    planId: string,
    operationIdx: number,
    copyOperationIdx?: number,
    transferInputStock?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/operation`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (copyOperationIdx !== undefined) {
      localVarQueryParameter["copy_operation_idx"] = copyOperationIdx
    }
    if (transferInputStock !== undefined) {
      localVarQueryParameter["transfer_input_stock"] = transferInputStock
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Plan
   * @param {string} jobId
   * @param {string} [label]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public createPlan(jobId: string, label?: string, options?: any) {
    return PlansApiFp(this.configuration)
      .createPlan(jobId, label, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createPlan(jobId: string, label?: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/create-plan`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["jobId"] = jobId
    }
    if (label !== undefined) {
      localVarQueryParameter["label"] = label
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Delete Operation
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public deleteOperation(planId: string, operationIdx: number, options?: any) {
    return PlansApiFp(this.configuration)
      .deleteOperation(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_deleteOperation(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/operation`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Duplicate Plan
   * @param {string} planId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public duplicatePlan(planId: string, options?: any) {
    return PlansApiFp(this.configuration)
      .duplicatePlan(planId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_duplicatePlan(planId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/duplicate-plan`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Enable Grob Toolchecks
   * @param {string} planId
   * @param {number} operationIdx
   * @param {boolean} enable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public enableGrobToolchecks(
    planId: string,
    operationIdx: number,
    enable: boolean,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .enableGrobToolchecks(planId, operationIdx, enable, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_enableGrobToolchecks(
    planId: string,
    operationIdx: number,
    enable: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/enable-grob-toolchecks`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (enable !== undefined) {
      localVarQueryParameter["enable"] = enable
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Enable Machine Offsets
   * @param {string} planId
   * @param {number} operationIdx
   * @param {boolean} enable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public enableMachineOffsets(
    planId: string,
    operationIdx: number,
    enable: boolean,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .enableMachineOffsets(planId, operationIdx, enable, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_enableMachineOffsets(
    planId: string,
    operationIdx: number,
    enable: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/enable-machine-offsets`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (enable !== undefined) {
      localVarQueryParameter["enable"] = enable
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Enable Machine Offsets Logging
   * @param {string} planId
   * @param {number} operationIdx
   * @param {boolean} enable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public enableMachineOffsetsLogging(
    planId: string,
    operationIdx: number,
    enable: boolean,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .enableMachineOffsetsLogging(planId, operationIdx, enable, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_enableMachineOffsetsLogging(
    planId: string,
    operationIdx: number,
    enable: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/enable-machine-offsets-logging`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (enable !== undefined) {
      localVarQueryParameter["enable"] = enable
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Guardrails Manifest Data
   * @param {string} manifestUri
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public getGuardrailsManifestData(manifestUri: string, options?: any) {
    return PlansApiFp(this.configuration)
      .getGuardrailsManifestData(manifestUri, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getGuardrailsManifestData(manifestUri: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/guardrails-manifest-data`)
    const localVarQueryParameter = {} as any
    if (manifestUri !== undefined) {
      localVarQueryParameter["manifest_uri"] = manifestUri
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Get Vericut Manifest Data
   * @param {string} manifestUri
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public getVericutManifestData(manifestUri: string, options?: any) {
    return PlansApiFp(this.configuration)
      .getVericutManifestData(manifestUri, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getVericutManifestData(manifestUri: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/vericut-manifest-data`)
    const localVarQueryParameter = {} as any
    if (manifestUri !== undefined) {
      localVarQueryParameter["manifest_uri"] = manifestUri
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Postprocess Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {ProbingPostprocessingConfig} probingPostprocessingConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public postprocessProbing(
    planId: string,
    operationIdx: number,
    probingPostprocessingConfig: ProbingPostprocessingConfig,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .postprocessProbing(planId, operationIdx, probingPostprocessingConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_postprocessProbing(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/postprocess-probing`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Record Machine Offset Diff
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public recordMachineOffsetDiff(planId: string, operationIdx: number, options?: any) {
    return PlansApiFp(this.configuration)
      .recordMachineOffsetDiff(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_recordMachineOffsetDiff(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/record-machine-offset-diff`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Record Vericut Archive
   * @param {string} taskId
   * @param {string} jobId
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public recordVericutArchive(taskId: string, jobId: string, fileId: string, options?: any) {
    return PlansApiFp(this.configuration)
      .recordVericutArchive(taskId, jobId, fileId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_recordVericutArchive(
    taskId: string,
    jobId: string,
    fileId: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/record-vericut-archive`)
    const localVarQueryParameter = {} as any
    if (taskId !== undefined) {
      localVarQueryParameter["task_id"] = taskId
    }
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (fileId !== undefined) {
      localVarQueryParameter["file_id"] = fileId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Record Vericut Reviewer Id
   * @param {string} feedbackId
   * @param {string} vcReviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public recordVericutReviewerId(feedbackId: string, vcReviewId: string, options?: any) {
    return PlansApiFp(this.configuration)
      .recordVericutReviewerId(feedbackId, vcReviewId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_recordVericutReviewerId(
    feedbackId: string,
    vcReviewId: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/record-vericut-reviewer-id`)
    const localVarQueryParameter = {} as any
    if (feedbackId !== undefined) {
      localVarQueryParameter["feedback_id"] = feedbackId
    }
    if (vcReviewId !== undefined) {
      localVarQueryParameter["vc_review_id"] = vcReviewId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Record Vericut Sim Manifest
   * @param {string} feedbackId
   * @param {string} newManifestUri
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public recordVericutSimManifest(feedbackId: string, newManifestUri: string, options?: any) {
    return PlansApiFp(this.configuration)
      .recordVericutSimManifest(feedbackId, newManifestUri, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_recordVericutSimManifest(
    feedbackId: string,
    newManifestUri: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/record-vericut-sim-manifest`)
    const localVarQueryParameter = {} as any
    if (feedbackId !== undefined) {
      localVarQueryParameter["feedback_id"] = feedbackId
    }
    if (newManifestUri !== undefined) {
      localVarQueryParameter["new_manifest_uri"] = newManifestUri
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Refresh Pcdmis Extractor Units
   * @param {string} planId
   * @param {string} sku
   * @param {number} limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public refreshPcdmisExtractorUnits(planId: string, sku: string, limit: number, options?: any) {
    return PlansApiFp(this.configuration)
      .refreshPcdmisExtractorUnits(planId, sku, limit, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_refreshPcdmisExtractorUnits(
    planId: string,
    sku: string,
    limit: number
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/refresh-pcdmis-extractor-units`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (sku !== undefined) {
      localVarQueryParameter["sku"] = sku
    }
    if (limit !== undefined) {
      localVarQueryParameter["limit"] = limit
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Unlink Cam Project
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public unlinkCamProject(planId: string, operationIdx: number, options?: any) {
    return PlansApiFp(this.configuration)
      .unlinkCamProject(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_unlinkCamProject(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/unlink-cam-project`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Custom Fixture Transform By Id
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} fixtureChoiceElementId
   * @param {ExportCoordinates} coordinates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public updateCustomFixtureTransformById(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementId: string,
    coordinates: ExportCoordinates,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .updateCustomFixtureTransformById(
        planId,
        operationIdx,
        fixtureChoiceElementId,
        coordinates,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateCustomFixtureTransformById(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementId: string,
    coordinates: ExportCoordinates
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/update-custom-fixture-transform-by-id`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (fixtureChoiceElementId !== undefined) {
      localVarQueryParameter["fixture_choice_element_id"] = fixtureChoiceElementId
    }
    if (coordinates !== undefined) {
      localVarQueryParameter["coordinates"] = coordinates
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Custom Fixture Transform By Name
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} fixtureChoiceElementName
   * @param {ExportCoordinates} coordinates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public updateCustomFixtureTransformByName(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementName: string,
    coordinates: ExportCoordinates,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .updateCustomFixtureTransformByName(
        planId,
        operationIdx,
        fixtureChoiceElementName,
        coordinates,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateCustomFixtureTransformByName(
    planId: string,
    operationIdx: number,
    fixtureChoiceElementName: string,
    coordinates: ExportCoordinates
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/update-custom-fixture-transform-by-name`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (fixtureChoiceElementName !== undefined) {
      localVarQueryParameter["fixture_choice_element_name"] = fixtureChoiceElementName
    }
    if (coordinates !== undefined) {
      localVarQueryParameter["coordinates"] = coordinates
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Update Cut Config
   * @param {string} planId
   * @param {number} operationIdx
   * @param {CutConfig} [cutConfig]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public updateCutConfig(
    planId: string,
    operationIdx: number,
    cutConfig?: CutConfig,
    options?: any
  ) {
    return PlansApiFp(this.configuration)
      .updateCutConfig(planId, operationIdx, cutConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_updateCutConfig(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/update-cut-config`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Validate Vericut Import
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public validateVericutImport(planId: string, operationIdx: number, options?: any) {
    return PlansApiFp(this.configuration)
      .validateVericutImport(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_validateVericutImport(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/plan/validate-vericut-import`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * SlackApi - axios parameter creator
 * @export
 */
export const SlackApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post Webhook
     * @param {SlackWebhook} slackWebhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postWebhook: async (slackWebhook: SlackWebhook, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'slackWebhook' is not null or undefined
      if (slackWebhook === null || slackWebhook === undefined) {
        throw new RequiredError(
          "slackWebhook",
          "Required parameter slackWebhook was null or undefined when calling postWebhook."
        )
      }
      const localVarPath = `/api/v1/slack/webhook`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof slackWebhook !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(slackWebhook !== undefined ? slackWebhook : {})
        : slackWebhook || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SlackApi - functional programming interface
 * @export
 */
export const SlackApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post Webhook
     * @param {SlackWebhook} slackWebhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postWebhook(
      slackWebhook: SlackWebhook,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlackWebhookResponse>> {
      const localVarAxiosArgs = await SlackApiAxiosParamCreator(configuration).postWebhook(
        slackWebhook,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SlackApi - factory interface
 * @export
 */
export const SlackApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Post Webhook
     * @param {SlackWebhook} slackWebhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postWebhook(slackWebhook: SlackWebhook, options?: any): AxiosPromise<SlackWebhookResponse> {
      return SlackApiFp(configuration)
        .postWebhook(slackWebhook, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * SlackApi - object-oriented interface
 * @export
 * @class SlackApi
 * @extends {BaseAPI}
 */
export class SlackApi extends BaseAPI {
  /**
   *
   * @summary Post Webhook
   * @param {SlackWebhook} slackWebhook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlackApi
   */
  public postWebhook(slackWebhook: SlackWebhook, options?: any) {
    return SlackApiFp(this.configuration)
      .postWebhook(slackWebhook, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_postWebhook(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/slack/webhook`)
  }
}

/**
 * SubmissionApi - axios parameter creator
 * @export
 */
export const SubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Run Camplete Post
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runCampletePost: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling runCampletePost."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling runCampletePost."
        )
      }
      const localVarPath = `/api/v1/submission/run-camplete-post`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Submit Cam Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [ncProgramLocator]
     * @param {SimulationMode} [simulationMode]
     * @param {SimulationKind} [simulationKind]
     * @param {boolean} [allowCutting]
     * @param {boolean} [enableVcreview]
     * @param {boolean} [includeGcodeTextLog]
     * @param {number} [cuttingResolution]
     * @param {number} [holderStockNearMiss]
     * @param {number} [holderFixtureNearMiss]
     * @param {number} [cutterFixtureNearMiss]
     * @param {number} [shankFixtureNearMiss]
     * @param {number} [shankStockDist]
     * @param {number} [modelTolerance]
     * @param {number} [interpolationTolerance]
     * @param {object | Wsec} [objectWsec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitCamOperation: async (
      planId: string,
      operationIdx: number,
      ncProgramLocator?: string,
      simulationMode?: SimulationMode,
      simulationKind?: SimulationKind,
      allowCutting?: boolean,
      enableVcreview?: boolean,
      includeGcodeTextLog?: boolean,
      cuttingResolution?: number,
      holderStockNearMiss?: number,
      holderFixtureNearMiss?: number,
      cutterFixtureNearMiss?: number,
      shankFixtureNearMiss?: number,
      shankStockDist?: number,
      modelTolerance?: number,
      interpolationTolerance?: number,
      objectWsec?: object | Wsec,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling submitCamOperation."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling submitCamOperation."
        )
      }
      const localVarPath = `/api/v1/submission/submit-cam-operation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (ncProgramLocator !== undefined) {
        localVarQueryParameter["nc_program_locator"] = ncProgramLocator
      }

      if (simulationMode !== undefined) {
        localVarQueryParameter["simulation_mode"] = simulationMode
      }

      if (simulationKind !== undefined) {
        localVarQueryParameter["simulation_kind"] = simulationKind
      }

      if (allowCutting !== undefined) {
        localVarQueryParameter["allow_cutting"] = allowCutting
      }

      if (enableVcreview !== undefined) {
        localVarQueryParameter["enable_vcreview"] = enableVcreview
      }

      if (includeGcodeTextLog !== undefined) {
        localVarQueryParameter["include_gcode_text_log"] = includeGcodeTextLog
      }

      if (cuttingResolution !== undefined) {
        localVarQueryParameter["cutting_resolution"] = cuttingResolution
      }

      if (holderStockNearMiss !== undefined) {
        localVarQueryParameter["holder_stock_near_miss"] = holderStockNearMiss
      }

      if (holderFixtureNearMiss !== undefined) {
        localVarQueryParameter["holder_fixture_near_miss"] = holderFixtureNearMiss
      }

      if (cutterFixtureNearMiss !== undefined) {
        localVarQueryParameter["cutter_fixture_near_miss"] = cutterFixtureNearMiss
      }

      if (shankFixtureNearMiss !== undefined) {
        localVarQueryParameter["shank_fixture_near_miss"] = shankFixtureNearMiss
      }

      if (shankStockDist !== undefined) {
        localVarQueryParameter["shank_stock_dist"] = shankStockDist
      }

      if (modelTolerance !== undefined) {
        localVarQueryParameter["model_tolerance"] = modelTolerance
      }

      if (interpolationTolerance !== undefined) {
        localVarQueryParameter["interpolation_tolerance"] = interpolationTolerance
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof objectWsec !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(objectWsec !== undefined ? objectWsec : {})
        : objectWsec || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SubmissionApi - functional programming interface
 * @export
 */
export const SubmissionApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Run Camplete Post
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runCampletePost(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await SubmissionApiAxiosParamCreator(configuration).runCampletePost(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Submit Cam Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [ncProgramLocator]
     * @param {SimulationMode} [simulationMode]
     * @param {SimulationKind} [simulationKind]
     * @param {boolean} [allowCutting]
     * @param {boolean} [enableVcreview]
     * @param {boolean} [includeGcodeTextLog]
     * @param {number} [cuttingResolution]
     * @param {number} [holderStockNearMiss]
     * @param {number} [holderFixtureNearMiss]
     * @param {number} [cutterFixtureNearMiss]
     * @param {number} [shankFixtureNearMiss]
     * @param {number} [shankStockDist]
     * @param {number} [modelTolerance]
     * @param {number} [interpolationTolerance]
     * @param {object | Wsec} [objectWsec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitCamOperation(
      planId: string,
      operationIdx: number,
      ncProgramLocator?: string,
      simulationMode?: SimulationMode,
      simulationKind?: SimulationKind,
      allowCutting?: boolean,
      enableVcreview?: boolean,
      includeGcodeTextLog?: boolean,
      cuttingResolution?: number,
      holderStockNearMiss?: number,
      holderFixtureNearMiss?: number,
      cutterFixtureNearMiss?: number,
      shankFixtureNearMiss?: number,
      shankStockDist?: number,
      modelTolerance?: number,
      interpolationTolerance?: number,
      objectWsec?: object | Wsec,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await SubmissionApiAxiosParamCreator(
        configuration
      ).submitCamOperation(
        planId,
        operationIdx,
        ncProgramLocator,
        simulationMode,
        simulationKind,
        allowCutting,
        enableVcreview,
        includeGcodeTextLog,
        cuttingResolution,
        holderStockNearMiss,
        holderFixtureNearMiss,
        cutterFixtureNearMiss,
        shankFixtureNearMiss,
        shankStockDist,
        modelTolerance,
        interpolationTolerance,
        objectWsec,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SubmissionApi - factory interface
 * @export
 */
export const SubmissionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Run Camplete Post
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runCampletePost(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return SubmissionApiFp(configuration)
        .runCampletePost(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Submit Cam Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [ncProgramLocator]
     * @param {SimulationMode} [simulationMode]
     * @param {SimulationKind} [simulationKind]
     * @param {boolean} [allowCutting]
     * @param {boolean} [enableVcreview]
     * @param {boolean} [includeGcodeTextLog]
     * @param {number} [cuttingResolution]
     * @param {number} [holderStockNearMiss]
     * @param {number} [holderFixtureNearMiss]
     * @param {number} [cutterFixtureNearMiss]
     * @param {number} [shankFixtureNearMiss]
     * @param {number} [shankStockDist]
     * @param {number} [modelTolerance]
     * @param {number} [interpolationTolerance]
     * @param {object | Wsec} [objectWsec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitCamOperation(
      planId: string,
      operationIdx: number,
      ncProgramLocator?: string,
      simulationMode?: SimulationMode,
      simulationKind?: SimulationKind,
      allowCutting?: boolean,
      enableVcreview?: boolean,
      includeGcodeTextLog?: boolean,
      cuttingResolution?: number,
      holderStockNearMiss?: number,
      holderFixtureNearMiss?: number,
      cutterFixtureNearMiss?: number,
      shankFixtureNearMiss?: number,
      shankStockDist?: number,
      modelTolerance?: number,
      interpolationTolerance?: number,
      objectWsec?: object | Wsec,
      options?: any
    ): AxiosPromise<TaskData> {
      return SubmissionApiFp(configuration)
        .submitCamOperation(
          planId,
          operationIdx,
          ncProgramLocator,
          simulationMode,
          simulationKind,
          allowCutting,
          enableVcreview,
          includeGcodeTextLog,
          cuttingResolution,
          holderStockNearMiss,
          holderFixtureNearMiss,
          cutterFixtureNearMiss,
          shankFixtureNearMiss,
          shankStockDist,
          modelTolerance,
          interpolationTolerance,
          objectWsec,
          options
        )
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * SubmissionApi - object-oriented interface
 * @export
 * @class SubmissionApi
 * @extends {BaseAPI}
 */
export class SubmissionApi extends BaseAPI {
  /**
   *
   * @summary Run Camplete Post
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmissionApi
   */
  public runCampletePost(planId: string, operationIdx: number, options?: any) {
    return SubmissionApiFp(this.configuration)
      .runCampletePost(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_runCampletePost(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/submission/run-camplete-post`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Submit Cam Operation
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} [ncProgramLocator]
   * @param {SimulationMode} [simulationMode]
   * @param {SimulationKind} [simulationKind]
   * @param {boolean} [allowCutting]
   * @param {boolean} [enableVcreview]
   * @param {boolean} [includeGcodeTextLog]
   * @param {number} [cuttingResolution]
   * @param {number} [holderStockNearMiss]
   * @param {number} [holderFixtureNearMiss]
   * @param {number} [cutterFixtureNearMiss]
   * @param {number} [shankFixtureNearMiss]
   * @param {number} [shankStockDist]
   * @param {number} [modelTolerance]
   * @param {number} [interpolationTolerance]
   * @param {object | Wsec} [objectWsec]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubmissionApi
   */
  public submitCamOperation(
    planId: string,
    operationIdx: number,
    ncProgramLocator?: string,
    simulationMode?: SimulationMode,
    simulationKind?: SimulationKind,
    allowCutting?: boolean,
    enableVcreview?: boolean,
    includeGcodeTextLog?: boolean,
    cuttingResolution?: number,
    holderStockNearMiss?: number,
    holderFixtureNearMiss?: number,
    cutterFixtureNearMiss?: number,
    shankFixtureNearMiss?: number,
    shankStockDist?: number,
    modelTolerance?: number,
    interpolationTolerance?: number,
    objectWsec?: object | Wsec,
    options?: any
  ) {
    return SubmissionApiFp(this.configuration)
      .submitCamOperation(
        planId,
        operationIdx,
        ncProgramLocator,
        simulationMode,
        simulationKind,
        allowCutting,
        enableVcreview,
        includeGcodeTextLog,
        cuttingResolution,
        holderStockNearMiss,
        holderFixtureNearMiss,
        cutterFixtureNearMiss,
        shankFixtureNearMiss,
        shankStockDist,
        modelTolerance,
        interpolationTolerance,
        objectWsec,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_submitCamOperation(
    planId: string,
    operationIdx: number,
    ncProgramLocator?: string,
    simulationMode?: SimulationMode,
    simulationKind?: SimulationKind,
    allowCutting?: boolean,
    enableVcreview?: boolean,
    includeGcodeTextLog?: boolean,
    cuttingResolution?: number,
    holderStockNearMiss?: number,
    holderFixtureNearMiss?: number,
    cutterFixtureNearMiss?: number,
    shankFixtureNearMiss?: number,
    shankStockDist?: number,
    modelTolerance?: number,
    interpolationTolerance?: number
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/submission/submit-cam-operation`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (ncProgramLocator !== undefined) {
      localVarQueryParameter["nc_program_locator"] = ncProgramLocator
    }
    if (simulationMode !== undefined) {
      localVarQueryParameter["simulation_mode"] = simulationMode
    }
    if (simulationKind !== undefined) {
      localVarQueryParameter["simulation_kind"] = simulationKind
    }
    if (allowCutting !== undefined) {
      localVarQueryParameter["allow_cutting"] = allowCutting
    }
    if (enableVcreview !== undefined) {
      localVarQueryParameter["enable_vcreview"] = enableVcreview
    }
    if (includeGcodeTextLog !== undefined) {
      localVarQueryParameter["include_gcode_text_log"] = includeGcodeTextLog
    }
    if (cuttingResolution !== undefined) {
      localVarQueryParameter["cutting_resolution"] = cuttingResolution
    }
    if (holderStockNearMiss !== undefined) {
      localVarQueryParameter["holder_stock_near_miss"] = holderStockNearMiss
    }
    if (holderFixtureNearMiss !== undefined) {
      localVarQueryParameter["holder_fixture_near_miss"] = holderFixtureNearMiss
    }
    if (cutterFixtureNearMiss !== undefined) {
      localVarQueryParameter["cutter_fixture_near_miss"] = cutterFixtureNearMiss
    }
    if (shankFixtureNearMiss !== undefined) {
      localVarQueryParameter["shank_fixture_near_miss"] = shankFixtureNearMiss
    }
    if (shankStockDist !== undefined) {
      localVarQueryParameter["shank_stock_dist"] = shankStockDist
    }
    if (modelTolerance !== undefined) {
      localVarQueryParameter["model_tolerance"] = modelTolerance
    }
    if (interpolationTolerance !== undefined) {
      localVarQueryParameter["interpolation_tolerance"] = interpolationTolerance
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Configure Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {OperationGenerationConfig} operationGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configureOperation: async (
      planId: string,
      operationIdx: number,
      operationGenerationConfig: OperationGenerationConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling configureOperation."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling configureOperation."
        )
      }
      // verify required parameter 'operationGenerationConfig' is not null or undefined
      if (operationGenerationConfig === null || operationGenerationConfig === undefined) {
        throw new RequiredError(
          "operationGenerationConfig",
          "Required parameter operationGenerationConfig was null or undefined when calling configureOperation."
        )
      }
      const localVarPath = `/api/v1/tasks/configure-operation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof operationGenerationConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(operationGenerationConfig !== undefined ? operationGenerationConfig : {})
        : operationGenerationConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Configure Plan
     * @param {string} planId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configurePlan: async (
      planId: string,
      planGenerationConfig: PlanGenerationConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling configurePlan."
        )
      }
      // verify required parameter 'planGenerationConfig' is not null or undefined
      if (planGenerationConfig === null || planGenerationConfig === undefined) {
        throw new RequiredError(
          "planGenerationConfig",
          "Required parameter planGenerationConfig was null or undefined when calling configurePlan."
        )
      }
      const localVarPath = `/api/v1/tasks/configure-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof planGenerationConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(planGenerationConfig !== undefined ? planGenerationConfig : {})
        : planGenerationConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Job Backup Async
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {PlansToBackup} [plansToBackup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJobBackupAsync: async (
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      plansToBackup?: PlansToBackup,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling createJobBackupAsync."
        )
      }
      const localVarPath = `/api/v1/tasks/backup-async`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      if (includeJobFiles !== undefined) {
        localVarQueryParameter["include_job_files"] = includeJobFiles
      }

      if (includeManifests !== undefined) {
        localVarQueryParameter["include_manifests"] = includeManifests
      }

      if (includeVericutReportFiles !== undefined) {
        localVarQueryParameter["include_vericut_report_files"] = includeVericutReportFiles
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof plansToBackup !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(plansToBackup !== undefined ? plansToBackup : {})
        : plansToBackup || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Plan Backup Async
     * @param {string} planId
     * @param {number} [planRevision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlanBackupAsync: async (
      planId: string,
      planRevision?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling createPlanBackupAsync."
        )
      }
      const localVarPath = `/api/v1/tasks/backup-plan-async`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (planRevision !== undefined) {
        localVarQueryParameter["plan_revision"] = planRevision
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Filter Output Stock Floating Components
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOutputStockFloatingComponents: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling filterOutputStockFloatingComponents."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling filterOutputStockFloatingComponents."
        )
      }
      const localVarPath = `/api/v1/tasks/filter-output-stock-floating-components`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Fit Alignment Transform
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fitAlignmentTransform: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling fitAlignmentTransform."
        )
      }
      const localVarPath = `/api/v1/tasks/fit-alignment-transform`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Custom Fixtures
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateCustomFixtures: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateCustomFixtures."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateCustomFixtures."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-custom-fixtures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Estimated Dovetail Stock
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateEstimatedDovetailStock: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateEstimatedDovetailStock."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateEstimatedDovetailStock."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-estimated-dovetail-stock`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     * Note: identifier can be _either_ a template label _or_ a fixture ID
     * @summary Generate Fixture Stack Archive
     * @param {string} identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateFixtureStackArchive: async (
      identifier: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'identifier' is not null or undefined
      if (identifier === null || identifier === undefined) {
        throw new RequiredError(
          "identifier",
          "Required parameter identifier was null or undefined when calling generateFixtureStackArchive."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-fixture-stack-archive`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (identifier !== undefined) {
        localVarQueryParameter["identifier"] = identifier
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Operation Archive
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateOperationArchive: async (
      planId: string,
      operationIdx: number,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateOperationArchive."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateOperationArchive."
        )
      }
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          "coordinates",
          "Required parameter coordinates was null or undefined when calling generateOperationArchive."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-operation-archive`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      if (coordinates !== undefined) {
        localVarQueryParameter["coordinates"] = coordinates
      }

      if (excludeAuxiliary !== undefined) {
        localVarQueryParameter["excludeAuxiliary"] = excludeAuxiliary
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Param Surface Steps
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} sectionId
     * @param {BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost} bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateParamSurfaceSteps: async (
      planId: string,
      operationIdx: number,
      sectionId: string,
      bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost: BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateParamSurfaceSteps."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateParamSurfaceSteps."
        )
      }
      // verify required parameter 'sectionId' is not null or undefined
      if (sectionId === null || sectionId === undefined) {
        throw new RequiredError(
          "sectionId",
          "Required parameter sectionId was null or undefined when calling generateParamSurfaceSteps."
        )
      }
      // verify required parameter 'bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost' is not null or undefined
      if (
        bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost === null ||
        bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost === undefined
      ) {
        throw new RequiredError(
          "bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost",
          "Required parameter bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost was null or undefined when calling generateParamSurfaceSteps."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/generate-steps-from-surfaces`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      if (sectionId !== undefined) {
        localVarQueryParameter["sectionId"] = sectionId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost !==
          "string" || localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost !==
              undefined
              ? bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
              : {}
          )
        : bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Plan
     * @param {string} jobId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePlan: async (
      jobId: string,
      planGenerationConfig: PlanGenerationConfig,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling generatePlan."
        )
      }
      // verify required parameter 'planGenerationConfig' is not null or undefined
      if (planGenerationConfig === null || planGenerationConfig === undefined) {
        throw new RequiredError(
          "planGenerationConfig",
          "Required parameter planGenerationConfig was null or undefined when calling generatePlan."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-plan`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof planGenerationConfig !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(planGenerationConfig !== undefined ? planGenerationConfig : {})
        : planGenerationConfig || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Plan Archive
     * @param {string} planId
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePlanArchive: async (
      planId: string,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generatePlanArchive."
        )
      }
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          "coordinates",
          "Required parameter coordinates was null or undefined when calling generatePlanArchive."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-plan-archive`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (coordinates !== undefined) {
        localVarQueryParameter["coordinates"] = coordinates
      }

      if (excludeAuxiliary !== undefined) {
        localVarQueryParameter["excludeAuxiliary"] = excludeAuxiliary
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateProbing: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/generate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Proposals
     * @param {string} jobId
     * @param {boolean} [clearExistingProposals]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateProposals: async (
      jobId: string,
      clearExistingProposals?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling generateProposals."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-proposals`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId
      }

      if (clearExistingProposals !== undefined) {
        localVarQueryParameter["clear_existing_proposals"] = clearExistingProposals
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Scene
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateScene: async (modelId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          "modelId",
          "Required parameter modelId was null or undefined when calling generateScene."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-model-scene`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (modelId !== undefined) {
        localVarQueryParameter["model_id"] = modelId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Stock
     * @param {string} planId
     * @param {StockShapeRestriction} shapeRestriction
     * @param {StockGenerationConstraints} stockGenerationConstraints
     * @param {string} [materialId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateStock: async (
      planId: string,
      shapeRestriction: StockShapeRestriction,
      stockGenerationConstraints: StockGenerationConstraints,
      materialId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateStock."
        )
      }
      // verify required parameter 'shapeRestriction' is not null or undefined
      if (shapeRestriction === null || shapeRestriction === undefined) {
        throw new RequiredError(
          "shapeRestriction",
          "Required parameter shapeRestriction was null or undefined when calling generateStock."
        )
      }
      // verify required parameter 'stockGenerationConstraints' is not null or undefined
      if (stockGenerationConstraints === null || stockGenerationConstraints === undefined) {
        throw new RequiredError(
          "stockGenerationConstraints",
          "Required parameter stockGenerationConstraints was null or undefined when calling generateStock."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-stock`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (shapeRestriction !== undefined) {
        localVarQueryParameter["shape_restriction"] = shapeRestriction
      }

      if (materialId !== undefined) {
        localVarQueryParameter["material_id"] = materialId
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof stockGenerationConstraints !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(stockGenerationConstraints !== undefined ? stockGenerationConstraints : {})
        : stockGenerationConstraints || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Generate Wcs
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWcs: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling generateWcs."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling generateWcs."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-wcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Guardrails Run Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} [runPresim]
     * @param {boolean} [runPostsim]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunChecks: async (
      planId: string,
      operationIdx: number,
      runPresim?: boolean,
      runPostsim?: boolean,
      runId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling guardrailsRunChecks."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling guardrailsRunChecks."
        )
      }
      const localVarPath = `/api/v1/tasks/guardrails-run`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (runPresim !== undefined) {
        localVarQueryParameter["run_presim"] = runPresim
      }

      if (runPostsim !== undefined) {
        localVarQueryParameter["run_postsim"] = runPostsim
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Guardrails Run Postsim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunPostsimChecks: async (
      planId: string,
      operationIdx: number,
      runId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling guardrailsRunPostsimChecks."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling guardrailsRunPostsimChecks."
        )
      }
      const localVarPath = `/api/v1/tasks/guardrails-run-postsim`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Guardrails Run Presim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunPresimChecks: async (
      planId: string,
      operationIdx: number,
      runId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling guardrailsRunPresimChecks."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling guardrailsRunPresimChecks."
        )
      }
      const localVarPath = `/api/v1/tasks/guardrails-run-presim`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Link Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkProbing: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling linkProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling linkProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/link`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Move Wcs Preserve Rtcp
     * @param {string} planId
     * @param {number} operationIdx
     * @param {Point} point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveWcsPreserveRtcp: async (
      planId: string,
      operationIdx: number,
      point: Point,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling moveWcsPreserveRtcp."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling moveWcsPreserveRtcp."
        )
      }
      // verify required parameter 'point' is not null or undefined
      if (point === null || point === undefined) {
        throw new RequiredError(
          "point",
          "Required parameter point was null or undefined when calling moveWcsPreserveRtcp."
        )
      }
      const localVarPath = `/api/v1/tasks/move-g54-preserve-rtcp`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof point !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json"
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(point !== undefined ? point : {})
        : point || ""

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Optimize Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optimizeProbing: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling optimizeProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling optimizeProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/optimize`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Place Bites
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeBites: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling placeBites."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling placeBites."
        )
      }
      const localVarPath = `/api/v1/tasks/place-bites`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Refine Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refineProbing: async (
      planId: string,
      operationIdx: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling refineProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling refineProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/refine`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Regenerate Job Scenes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateJobScenes: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tasks/regenerate-job-scenes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Regenerate Thumbnail
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateThumbnail: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling regenerateThumbnail."
        )
      }
      const localVarPath = `/api/v1/tasks/regenerate-thumbnail`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (jobId !== undefined) {
        localVarQueryParameter["job_id"] = jobId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Custom Fixture Model
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} customFixtureElementId
     * @param {string} modelSourceFileId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setCustomFixtureModel: async (
      planId: string,
      operationIdx: number,
      customFixtureElementId: string,
      modelSourceFileId: string,
      coordinates: ExportCoordinates,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling setCustomFixtureModel."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling setCustomFixtureModel."
        )
      }
      // verify required parameter 'customFixtureElementId' is not null or undefined
      if (customFixtureElementId === null || customFixtureElementId === undefined) {
        throw new RequiredError(
          "customFixtureElementId",
          "Required parameter customFixtureElementId was null or undefined when calling setCustomFixtureModel."
        )
      }
      // verify required parameter 'modelSourceFileId' is not null or undefined
      if (modelSourceFileId === null || modelSourceFileId === undefined) {
        throw new RequiredError(
          "modelSourceFileId",
          "Required parameter modelSourceFileId was null or undefined when calling setCustomFixtureModel."
        )
      }
      // verify required parameter 'coordinates' is not null or undefined
      if (coordinates === null || coordinates === undefined) {
        throw new RequiredError(
          "coordinates",
          "Required parameter coordinates was null or undefined when calling setCustomFixtureModel."
        )
      }
      const localVarPath = `/api/v1/tasks/set-custom-fixture-model`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (customFixtureElementId !== undefined) {
        localVarQueryParameter["custom_fixture_element_id"] = customFixtureElementId
      }

      if (modelSourceFileId !== undefined) {
        localVarQueryParameter["model_source_file_id"] = modelSourceFileId
      }

      if (coordinates !== undefined) {
        localVarQueryParameter["coordinates"] = coordinates
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Wcs From Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} workOffsetNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setWcsFromProbing: async (
      planId: string,
      operationIdx: number,
      workOffsetNumber: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling setWcsFromProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling setWcsFromProbing."
        )
      }
      // verify required parameter 'workOffsetNumber' is not null or undefined
      if (workOffsetNumber === null || workOffsetNumber === undefined) {
        throw new RequiredError(
          "workOffsetNumber",
          "Required parameter workOffsetNumber was null or undefined when calling setWcsFromProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/set-wcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      if (workOffsetNumber !== undefined) {
        localVarQueryParameter["work_offset_number"] = workOffsetNumber
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Simulate Stock Gcode Mapping
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} includeCutMetrics
     * @param {boolean} [streamStock]
     * @param {number} [revision]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateStockGcodeMapping: async (
      planId: string,
      operationIdx: number,
      includeCutMetrics: boolean,
      streamStock?: boolean,
      revision?: number,
      runId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling simulateStockGcodeMapping."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling simulateStockGcodeMapping."
        )
      }
      // verify required parameter 'includeCutMetrics' is not null or undefined
      if (includeCutMetrics === null || includeCutMetrics === undefined) {
        throw new RequiredError(
          "includeCutMetrics",
          "Required parameter includeCutMetrics was null or undefined when calling simulateStockGcodeMapping."
        )
      }
      const localVarPath = `/api/v1/tasks/generate-stock-simulation-gcode`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["plan_id"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operation_idx"] = operationIdx
      }

      if (includeCutMetrics !== undefined) {
        localVarQueryParameter["include_cut_metrics"] = includeCutMetrics
      }

      if (streamStock !== undefined) {
        localVarQueryParameter["stream_stock"] = streamStock
      }

      if (revision !== undefined) {
        localVarQueryParameter["revision"] = revision
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Stop Task
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTask: async (taskId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          "taskId",
          "Required parameter taskId was null or undefined when calling stopTask."
        )
      }
      const localVarPath = `/api/v1/tasks/stop-task`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (taskId !== undefined) {
        localVarQueryParameter["task_id"] = taskId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Test Celery
     * @param {string} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testCelery: async (message: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'message' is not null or undefined
      if (message === null || message === undefined) {
        throw new RequiredError(
          "message",
          "Required parameter message was null or undefined when calling testCelery."
        )
      }
      const localVarPath = `/api/v1/tasks/test-celery`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (message !== undefined) {
        localVarQueryParameter["message"] = message
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Validate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProbing: async (
      planId: string,
      operationIdx: number,
      runId?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planId' is not null or undefined
      if (planId === null || planId === undefined) {
        throw new RequiredError(
          "planId",
          "Required parameter planId was null or undefined when calling validateProbing."
        )
      }
      // verify required parameter 'operationIdx' is not null or undefined
      if (operationIdx === null || operationIdx === undefined) {
        throw new RequiredError(
          "operationIdx",
          "Required parameter operationIdx was null or undefined when calling validateProbing."
        )
      }
      const localVarPath = `/api/v1/tasks/probing/validate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId
      }

      if (operationIdx !== undefined) {
        localVarQueryParameter["operationIdx"] = operationIdx
      }

      if (runId !== undefined) {
        localVarQueryParameter["run_id"] = runId
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Configure Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {OperationGenerationConfig} operationGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configureOperation(
      planId: string,
      operationIdx: number,
      operationGenerationConfig: OperationGenerationConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).configureOperation(
        planId,
        operationIdx,
        operationGenerationConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Configure Plan
     * @param {string} planId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configurePlan(
      planId: string,
      planGenerationConfig: PlanGenerationConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).configurePlan(
        planId,
        planGenerationConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Job Backup Async
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {PlansToBackup} [plansToBackup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createJobBackupAsync(
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      plansToBackup?: PlansToBackup,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).createJobBackupAsync(
        jobId,
        includeJobFiles,
        includeManifests,
        includeVericutReportFiles,
        plansToBackup,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Create Plan Backup Async
     * @param {string} planId
     * @param {number} [planRevision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPlanBackupAsync(
      planId: string,
      planRevision?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).createPlanBackupAsync(planId, planRevision, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Filter Output Stock Floating Components
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterOutputStockFloatingComponents(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).filterOutputStockFloatingComponents(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Fit Alignment Transform
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fitAlignmentTransform(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).fitAlignmentTransform(jobId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Custom Fixtures
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateCustomFixtures(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).generateCustomFixtures(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Estimated Dovetail Stock
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateEstimatedDovetailStock(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).generateEstimatedDovetailStock(planId, operationIdx, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Note: identifier can be _either_ a template label _or_ a fixture ID
     * @summary Generate Fixture Stack Archive
     * @param {string} identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateFixtureStackArchive(
      identifier: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).generateFixtureStackArchive(identifier, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Operation Archive
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateOperationArchive(
      planId: string,
      operationIdx: number,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).generateOperationArchive(planId, operationIdx, coordinates, excludeAuxiliary, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Param Surface Steps
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} sectionId
     * @param {BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost} bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateParamSurfaceSteps(
      planId: string,
      operationIdx: number,
      sectionId: string,
      bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost: BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).generateParamSurfaceSteps(
        planId,
        operationIdx,
        sectionId,
        bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Plan
     * @param {string} jobId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePlan(
      jobId: string,
      planGenerationConfig: PlanGenerationConfig,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generatePlan(
        jobId,
        planGenerationConfig,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Plan Archive
     * @param {string} planId
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePlanArchive(
      planId: string,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generatePlanArchive(
        planId,
        coordinates,
        excludeAuxiliary,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateProbing(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generateProbing(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Proposals
     * @param {string} jobId
     * @param {boolean} [clearExistingProposals]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateProposals(
      jobId: string,
      clearExistingProposals?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generateProposals(
        jobId,
        clearExistingProposals,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Scene
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateScene(
      modelId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generateScene(
        modelId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Stock
     * @param {string} planId
     * @param {StockShapeRestriction} shapeRestriction
     * @param {StockGenerationConstraints} stockGenerationConstraints
     * @param {string} [materialId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateStock(
      planId: string,
      shapeRestriction: StockShapeRestriction,
      stockGenerationConstraints: StockGenerationConstraints,
      materialId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generateStock(
        planId,
        shapeRestriction,
        stockGenerationConstraints,
        materialId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Generate Wcs
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateWcs(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).generateWcs(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Guardrails Run Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} [runPresim]
     * @param {boolean} [runPostsim]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guardrailsRunChecks(
      planId: string,
      operationIdx: number,
      runPresim?: boolean,
      runPostsim?: boolean,
      runId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).guardrailsRunChecks(
        planId,
        operationIdx,
        runPresim,
        runPostsim,
        runId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Guardrails Run Postsim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guardrailsRunPostsimChecks(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).guardrailsRunPostsimChecks(planId, operationIdx, runId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Guardrails Run Presim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guardrailsRunPresimChecks(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).guardrailsRunPresimChecks(planId, operationIdx, runId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Link Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkProbing(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).linkProbing(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Move Wcs Preserve Rtcp
     * @param {string} planId
     * @param {number} operationIdx
     * @param {Point} point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async moveWcsPreserveRtcp(
      planId: string,
      operationIdx: number,
      point: Point,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).moveWcsPreserveRtcp(
        planId,
        operationIdx,
        point,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Optimize Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optimizeProbing(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).optimizeProbing(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Place Bites
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeBites(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).placeBites(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Refine Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refineProbing(
      planId: string,
      operationIdx: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).refineProbing(
        planId,
        operationIdx,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Regenerate Job Scenes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async regenerateJobScenes(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskData>>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).regenerateJobScenes(
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Regenerate Thumbnail
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async regenerateThumbnail(
      jobId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).regenerateThumbnail(
        jobId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Set Custom Fixture Model
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} customFixtureElementId
     * @param {string} modelSourceFileId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setCustomFixtureModel(
      planId: string,
      operationIdx: number,
      customFixtureElementId: string,
      modelSourceFileId: string,
      coordinates: ExportCoordinates,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).setCustomFixtureModel(
        planId,
        operationIdx,
        customFixtureElementId,
        modelSourceFileId,
        coordinates,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Set Wcs From Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} workOffsetNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setWcsFromProbing(
      planId: string,
      operationIdx: number,
      workOffsetNumber: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).setWcsFromProbing(
        planId,
        operationIdx,
        workOffsetNumber,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Simulate Stock Gcode Mapping
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} includeCutMetrics
     * @param {boolean} [streamStock]
     * @param {number} [revision]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simulateStockGcodeMapping(
      planId: string,
      operationIdx: number,
      includeCutMetrics: boolean,
      streamStock?: boolean,
      revision?: number,
      runId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).simulateStockGcodeMapping(
        planId,
        operationIdx,
        includeCutMetrics,
        streamStock,
        revision,
        runId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Stop Task
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopTask(
      taskId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).stopTask(
        taskId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Test Celery
     * @param {string} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async testCelery(
      message: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).testCelery(
        message,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Validate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateProbing(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskData>> {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(configuration).validateProbing(
        planId,
        operationIdx,
        runId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Configure Operation
     * @param {string} planId
     * @param {number} operationIdx
     * @param {OperationGenerationConfig} operationGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configureOperation(
      planId: string,
      operationIdx: number,
      operationGenerationConfig: OperationGenerationConfig,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .configureOperation(planId, operationIdx, operationGenerationConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Configure Plan
     * @param {string} planId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configurePlan(
      planId: string,
      planGenerationConfig: PlanGenerationConfig,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .configurePlan(planId, planGenerationConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Job Backup Async
     * @param {string} jobId
     * @param {boolean} [includeJobFiles]
     * @param {boolean} [includeManifests]
     * @param {boolean} [includeVericutReportFiles]
     * @param {PlansToBackup} [plansToBackup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJobBackupAsync(
      jobId: string,
      includeJobFiles?: boolean,
      includeManifests?: boolean,
      includeVericutReportFiles?: boolean,
      plansToBackup?: PlansToBackup,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .createJobBackupAsync(
          jobId,
          includeJobFiles,
          includeManifests,
          includeVericutReportFiles,
          plansToBackup,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create Plan Backup Async
     * @param {string} planId
     * @param {number} [planRevision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlanBackupAsync(
      planId: string,
      planRevision?: number,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .createPlanBackupAsync(planId, planRevision, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Filter Output Stock Floating Components
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterOutputStockFloatingComponents(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .filterOutputStockFloatingComponents(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Fit Alignment Transform
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fitAlignmentTransform(jobId: string, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .fitAlignmentTransform(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Custom Fixtures
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateCustomFixtures(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateCustomFixtures(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Estimated Dovetail Stock
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateEstimatedDovetailStock(
      planId: string,
      operationIdx: number,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateEstimatedDovetailStock(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     * Note: identifier can be _either_ a template label _or_ a fixture ID
     * @summary Generate Fixture Stack Archive
     * @param {string} identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateFixtureStackArchive(identifier: string, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateFixtureStackArchive(identifier, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Operation Archive
     * @param {string} planId
     * @param {number} operationIdx
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateOperationArchive(
      planId: string,
      operationIdx: number,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateOperationArchive(planId, operationIdx, coordinates, excludeAuxiliary, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Param Surface Steps
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} sectionId
     * @param {BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost} bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateParamSurfaceSteps(
      planId: string,
      operationIdx: number,
      sectionId: string,
      bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost: BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateParamSurfaceSteps(
          planId,
          operationIdx,
          sectionId,
          bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Plan
     * @param {string} jobId
     * @param {PlanGenerationConfig} planGenerationConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePlan(
      jobId: string,
      planGenerationConfig: PlanGenerationConfig,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generatePlan(jobId, planGenerationConfig, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Plan Archive
     * @param {string} planId
     * @param {ExportCoordinates} coordinates
     * @param {boolean} [excludeAuxiliary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePlanArchive(
      planId: string,
      coordinates: ExportCoordinates,
      excludeAuxiliary?: boolean,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generatePlanArchive(planId, coordinates, excludeAuxiliary, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateProbing(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateProbing(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Proposals
     * @param {string} jobId
     * @param {boolean} [clearExistingProposals]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateProposals(
      jobId: string,
      clearExistingProposals?: boolean,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateProposals(jobId, clearExistingProposals, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Scene
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateScene(modelId: string, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateScene(modelId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Stock
     * @param {string} planId
     * @param {StockShapeRestriction} shapeRestriction
     * @param {StockGenerationConstraints} stockGenerationConstraints
     * @param {string} [materialId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateStock(
      planId: string,
      shapeRestriction: StockShapeRestriction,
      stockGenerationConstraints: StockGenerationConstraints,
      materialId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateStock(planId, shapeRestriction, stockGenerationConstraints, materialId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Generate Wcs
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWcs(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .generateWcs(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Guardrails Run Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} [runPresim]
     * @param {boolean} [runPostsim]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunChecks(
      planId: string,
      operationIdx: number,
      runPresim?: boolean,
      runPostsim?: boolean,
      runId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .guardrailsRunChecks(planId, operationIdx, runPresim, runPostsim, runId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Guardrails Run Postsim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunPostsimChecks(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .guardrailsRunPostsimChecks(planId, operationIdx, runId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Guardrails Run Presim Checks
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guardrailsRunPresimChecks(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .guardrailsRunPresimChecks(planId, operationIdx, runId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Link Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkProbing(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .linkProbing(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Move Wcs Preserve Rtcp
     * @param {string} planId
     * @param {number} operationIdx
     * @param {Point} point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveWcsPreserveRtcp(
      planId: string,
      operationIdx: number,
      point: Point,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .moveWcsPreserveRtcp(planId, operationIdx, point, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Optimize Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optimizeProbing(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .optimizeProbing(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Place Bites
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeBites(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .placeBites(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Refine Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refineProbing(planId: string, operationIdx: number, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .refineProbing(planId, operationIdx, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Regenerate Job Scenes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateJobScenes(options?: any): AxiosPromise<Array<TaskData>> {
      return TasksApiFp(configuration)
        .regenerateJobScenes(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Regenerate Thumbnail
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateThumbnail(jobId: string, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .regenerateThumbnail(jobId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Set Custom Fixture Model
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} customFixtureElementId
     * @param {string} modelSourceFileId
     * @param {ExportCoordinates} coordinates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setCustomFixtureModel(
      planId: string,
      operationIdx: number,
      customFixtureElementId: string,
      modelSourceFileId: string,
      coordinates: ExportCoordinates,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .setCustomFixtureModel(
          planId,
          operationIdx,
          customFixtureElementId,
          modelSourceFileId,
          coordinates,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Set Wcs From Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {number} workOffsetNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setWcsFromProbing(
      planId: string,
      operationIdx: number,
      workOffsetNumber: number,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .setWcsFromProbing(planId, operationIdx, workOffsetNumber, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Simulate Stock Gcode Mapping
     * @param {string} planId
     * @param {number} operationIdx
     * @param {boolean} includeCutMetrics
     * @param {boolean} [streamStock]
     * @param {number} [revision]
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateStockGcodeMapping(
      planId: string,
      operationIdx: number,
      includeCutMetrics: boolean,
      streamStock?: boolean,
      revision?: number,
      runId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .simulateStockGcodeMapping(
          planId,
          operationIdx,
          includeCutMetrics,
          streamStock,
          revision,
          runId,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Stop Task
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTask(taskId: string, options?: any): AxiosPromise<void> {
      return TasksApiFp(configuration)
        .stopTask(taskId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Test Celery
     * @param {string} message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testCelery(message: string, options?: any): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .testCelery(message, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Validate Probing
     * @param {string} planId
     * @param {number} operationIdx
     * @param {string} [runId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProbing(
      planId: string,
      operationIdx: number,
      runId?: string,
      options?: any
    ): AxiosPromise<TaskData> {
      return TasksApiFp(configuration)
        .validateProbing(planId, operationIdx, runId, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
  /**
   *
   * @summary Configure Operation
   * @param {string} planId
   * @param {number} operationIdx
   * @param {OperationGenerationConfig} operationGenerationConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public configureOperation(
    planId: string,
    operationIdx: number,
    operationGenerationConfig: OperationGenerationConfig,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .configureOperation(planId, operationIdx, operationGenerationConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_configureOperation(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/configure-operation`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Configure Plan
   * @param {string} planId
   * @param {PlanGenerationConfig} planGenerationConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public configurePlan(planId: string, planGenerationConfig: PlanGenerationConfig, options?: any) {
    return TasksApiFp(this.configuration)
      .configurePlan(planId, planGenerationConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_configurePlan(planId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/configure-plan`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Job Backup Async
   * @param {string} jobId
   * @param {boolean} [includeJobFiles]
   * @param {boolean} [includeManifests]
   * @param {boolean} [includeVericutReportFiles]
   * @param {PlansToBackup} [plansToBackup]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public createJobBackupAsync(
    jobId: string,
    includeJobFiles?: boolean,
    includeManifests?: boolean,
    includeVericutReportFiles?: boolean,
    plansToBackup?: PlansToBackup,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .createJobBackupAsync(
        jobId,
        includeJobFiles,
        includeManifests,
        includeVericutReportFiles,
        plansToBackup,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createJobBackupAsync(
    jobId: string,
    includeJobFiles?: boolean,
    includeManifests?: boolean,
    includeVericutReportFiles?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/backup-async`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    if (includeJobFiles !== undefined) {
      localVarQueryParameter["include_job_files"] = includeJobFiles
    }
    if (includeManifests !== undefined) {
      localVarQueryParameter["include_manifests"] = includeManifests
    }
    if (includeVericutReportFiles !== undefined) {
      localVarQueryParameter["include_vericut_report_files"] = includeVericutReportFiles
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Create Plan Backup Async
   * @param {string} planId
   * @param {number} [planRevision]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public createPlanBackupAsync(planId: string, planRevision?: number, options?: any) {
    return TasksApiFp(this.configuration)
      .createPlanBackupAsync(planId, planRevision, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_createPlanBackupAsync(planId: string, planRevision?: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/backup-plan-async`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (planRevision !== undefined) {
      localVarQueryParameter["plan_revision"] = planRevision
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Filter Output Stock Floating Components
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public filterOutputStockFloatingComponents(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .filterOutputStockFloatingComponents(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_filterOutputStockFloatingComponents(
    planId: string,
    operationIdx: number
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/filter-output-stock-floating-components`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Fit Alignment Transform
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public fitAlignmentTransform(jobId: string, options?: any) {
    return TasksApiFp(this.configuration)
      .fitAlignmentTransform(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_fitAlignmentTransform(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/fit-alignment-transform`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Custom Fixtures
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateCustomFixtures(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .generateCustomFixtures(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateCustomFixtures(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-custom-fixtures`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Estimated Dovetail Stock
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateEstimatedDovetailStock(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .generateEstimatedDovetailStock(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateEstimatedDovetailStock(
    planId: string,
    operationIdx: number
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-estimated-dovetail-stock`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   * Note: identifier can be _either_ a template label _or_ a fixture ID
   * @summary Generate Fixture Stack Archive
   * @param {string} identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateFixtureStackArchive(identifier: string, options?: any) {
    return TasksApiFp(this.configuration)
      .generateFixtureStackArchive(identifier, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateFixtureStackArchive(identifier: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-fixture-stack-archive`)
    const localVarQueryParameter = {} as any
    if (identifier !== undefined) {
      localVarQueryParameter["identifier"] = identifier
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Operation Archive
   * @param {string} planId
   * @param {number} operationIdx
   * @param {ExportCoordinates} coordinates
   * @param {boolean} [excludeAuxiliary]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateOperationArchive(
    planId: string,
    operationIdx: number,
    coordinates: ExportCoordinates,
    excludeAuxiliary?: boolean,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .generateOperationArchive(planId, operationIdx, coordinates, excludeAuxiliary, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateOperationArchive(
    planId: string,
    operationIdx: number,
    coordinates: ExportCoordinates,
    excludeAuxiliary?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-operation-archive`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    if (coordinates !== undefined) {
      localVarQueryParameter["coordinates"] = coordinates
    }
    if (excludeAuxiliary !== undefined) {
      localVarQueryParameter["excludeAuxiliary"] = excludeAuxiliary
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Param Surface Steps
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} sectionId
   * @param {BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost} bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateParamSurfaceSteps(
    planId: string,
    operationIdx: number,
    sectionId: string,
    bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost: BodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .generateParamSurfaceSteps(
        planId,
        operationIdx,
        sectionId,
        bodyGenerateParamSurfaceStepsApiV1TasksProbingGenerateStepsFromSurfacesPost,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateParamSurfaceSteps(
    planId: string,
    operationIdx: number,
    sectionId: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/generate-steps-from-surfaces`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    if (sectionId !== undefined) {
      localVarQueryParameter["sectionId"] = sectionId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Plan
   * @param {string} jobId
   * @param {PlanGenerationConfig} planGenerationConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generatePlan(jobId: string, planGenerationConfig: PlanGenerationConfig, options?: any) {
    return TasksApiFp(this.configuration)
      .generatePlan(jobId, planGenerationConfig, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generatePlan(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-plan`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["jobId"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Plan Archive
   * @param {string} planId
   * @param {ExportCoordinates} coordinates
   * @param {boolean} [excludeAuxiliary]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generatePlanArchive(
    planId: string,
    coordinates: ExportCoordinates,
    excludeAuxiliary?: boolean,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .generatePlanArchive(planId, coordinates, excludeAuxiliary, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generatePlanArchive(
    planId: string,
    coordinates: ExportCoordinates,
    excludeAuxiliary?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-plan-archive`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (coordinates !== undefined) {
      localVarQueryParameter["coordinates"] = coordinates
    }
    if (excludeAuxiliary !== undefined) {
      localVarQueryParameter["excludeAuxiliary"] = excludeAuxiliary
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateProbing(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .generateProbing(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateProbing(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/generate`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Proposals
   * @param {string} jobId
   * @param {boolean} [clearExistingProposals]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateProposals(jobId: string, clearExistingProposals?: boolean, options?: any) {
    return TasksApiFp(this.configuration)
      .generateProposals(jobId, clearExistingProposals, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateProposals(
    jobId: string,
    clearExistingProposals?: boolean
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-proposals`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["jobId"] = jobId
    }
    if (clearExistingProposals !== undefined) {
      localVarQueryParameter["clear_existing_proposals"] = clearExistingProposals
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Scene
   * @param {string} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateScene(modelId: string, options?: any) {
    return TasksApiFp(this.configuration)
      .generateScene(modelId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateScene(modelId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-model-scene`)
    const localVarQueryParameter = {} as any
    if (modelId !== undefined) {
      localVarQueryParameter["model_id"] = modelId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Stock
   * @param {string} planId
   * @param {StockShapeRestriction} shapeRestriction
   * @param {StockGenerationConstraints} stockGenerationConstraints
   * @param {string} [materialId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateStock(
    planId: string,
    shapeRestriction: StockShapeRestriction,
    stockGenerationConstraints: StockGenerationConstraints,
    materialId?: string,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .generateStock(planId, shapeRestriction, stockGenerationConstraints, materialId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateStock(
    planId: string,
    shapeRestriction: StockShapeRestriction,
    materialId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-stock`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (shapeRestriction !== undefined) {
      localVarQueryParameter["shape_restriction"] = shapeRestriction
    }
    if (materialId !== undefined) {
      localVarQueryParameter["material_id"] = materialId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Generate Wcs
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public generateWcs(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .generateWcs(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_generateWcs(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-wcs`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Guardrails Run Checks
   * @param {string} planId
   * @param {number} operationIdx
   * @param {boolean} [runPresim]
   * @param {boolean} [runPostsim]
   * @param {string} [runId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public guardrailsRunChecks(
    planId: string,
    operationIdx: number,
    runPresim?: boolean,
    runPostsim?: boolean,
    runId?: string,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .guardrailsRunChecks(planId, operationIdx, runPresim, runPostsim, runId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_guardrailsRunChecks(
    planId: string,
    operationIdx: number,
    runPresim?: boolean,
    runPostsim?: boolean,
    runId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/guardrails-run`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (runPresim !== undefined) {
      localVarQueryParameter["run_presim"] = runPresim
    }
    if (runPostsim !== undefined) {
      localVarQueryParameter["run_postsim"] = runPostsim
    }
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Guardrails Run Postsim Checks
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} [runId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public guardrailsRunPostsimChecks(
    planId: string,
    operationIdx: number,
    runId?: string,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .guardrailsRunPostsimChecks(planId, operationIdx, runId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_guardrailsRunPostsimChecks(
    planId: string,
    operationIdx: number,
    runId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/guardrails-run-postsim`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Guardrails Run Presim Checks
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} [runId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public guardrailsRunPresimChecks(
    planId: string,
    operationIdx: number,
    runId?: string,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .guardrailsRunPresimChecks(planId, operationIdx, runId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_guardrailsRunPresimChecks(
    planId: string,
    operationIdx: number,
    runId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/guardrails-run-presim`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Link Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public linkProbing(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .linkProbing(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_linkProbing(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/link`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Move Wcs Preserve Rtcp
   * @param {string} planId
   * @param {number} operationIdx
   * @param {Point} point
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public moveWcsPreserveRtcp(planId: string, operationIdx: number, point: Point, options?: any) {
    return TasksApiFp(this.configuration)
      .moveWcsPreserveRtcp(planId, operationIdx, point, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_moveWcsPreserveRtcp(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/move-g54-preserve-rtcp`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Optimize Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public optimizeProbing(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .optimizeProbing(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_optimizeProbing(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/optimize`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Place Bites
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public placeBites(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .placeBites(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_placeBites(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/place-bites`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Refine Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public refineProbing(planId: string, operationIdx: number, options?: any) {
    return TasksApiFp(this.configuration)
      .refineProbing(planId, operationIdx, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_refineProbing(planId: string, operationIdx: number): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/refine`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Regenerate Job Scenes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public regenerateJobScenes(options?: any) {
    return TasksApiFp(this.configuration)
      .regenerateJobScenes(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_regenerateJobScenes(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/tasks/regenerate-job-scenes`)
  }

  /**
   *
   * @summary Regenerate Thumbnail
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public regenerateThumbnail(jobId: string, options?: any) {
    return TasksApiFp(this.configuration)
      .regenerateThumbnail(jobId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_regenerateThumbnail(jobId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/regenerate-thumbnail`)
    const localVarQueryParameter = {} as any
    if (jobId !== undefined) {
      localVarQueryParameter["job_id"] = jobId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Set Custom Fixture Model
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} customFixtureElementId
   * @param {string} modelSourceFileId
   * @param {ExportCoordinates} coordinates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public setCustomFixtureModel(
    planId: string,
    operationIdx: number,
    customFixtureElementId: string,
    modelSourceFileId: string,
    coordinates: ExportCoordinates,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .setCustomFixtureModel(
        planId,
        operationIdx,
        customFixtureElementId,
        modelSourceFileId,
        coordinates,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_setCustomFixtureModel(
    planId: string,
    operationIdx: number,
    customFixtureElementId: string,
    modelSourceFileId: string,
    coordinates: ExportCoordinates
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/set-custom-fixture-model`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (customFixtureElementId !== undefined) {
      localVarQueryParameter["custom_fixture_element_id"] = customFixtureElementId
    }
    if (modelSourceFileId !== undefined) {
      localVarQueryParameter["model_source_file_id"] = modelSourceFileId
    }
    if (coordinates !== undefined) {
      localVarQueryParameter["coordinates"] = coordinates
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Set Wcs From Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {number} workOffsetNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public setWcsFromProbing(
    planId: string,
    operationIdx: number,
    workOffsetNumber: number,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .setWcsFromProbing(planId, operationIdx, workOffsetNumber, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_setWcsFromProbing(
    planId: string,
    operationIdx: number,
    workOffsetNumber: number
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/set-wcs`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    if (workOffsetNumber !== undefined) {
      localVarQueryParameter["work_offset_number"] = workOffsetNumber
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Simulate Stock Gcode Mapping
   * @param {string} planId
   * @param {number} operationIdx
   * @param {boolean} includeCutMetrics
   * @param {boolean} [streamStock]
   * @param {number} [revision]
   * @param {string} [runId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public simulateStockGcodeMapping(
    planId: string,
    operationIdx: number,
    includeCutMetrics: boolean,
    streamStock?: boolean,
    revision?: number,
    runId?: string,
    options?: any
  ) {
    return TasksApiFp(this.configuration)
      .simulateStockGcodeMapping(
        planId,
        operationIdx,
        includeCutMetrics,
        streamStock,
        revision,
        runId,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_simulateStockGcodeMapping(
    planId: string,
    operationIdx: number,
    includeCutMetrics: boolean,
    streamStock?: boolean,
    revision?: number,
    runId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/generate-stock-simulation-gcode`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["plan_id"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operation_idx"] = operationIdx
    }
    if (includeCutMetrics !== undefined) {
      localVarQueryParameter["include_cut_metrics"] = includeCutMetrics
    }
    if (streamStock !== undefined) {
      localVarQueryParameter["stream_stock"] = streamStock
    }
    if (revision !== undefined) {
      localVarQueryParameter["revision"] = revision
    }
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Stop Task
   * @param {string} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public stopTask(taskId: string, options?: any) {
    return TasksApiFp(this.configuration)
      .stopTask(taskId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_stopTask(taskId: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/stop-task`)
    const localVarQueryParameter = {} as any
    if (taskId !== undefined) {
      localVarQueryParameter["task_id"] = taskId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Test Celery
   * @param {string} message
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public testCelery(message: string, options?: any) {
    return TasksApiFp(this.configuration)
      .testCelery(message, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_testCelery(message: string): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/test-celery`)
    const localVarQueryParameter = {} as any
    if (message !== undefined) {
      localVarQueryParameter["message"] = message
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }

  /**
   *
   * @summary Validate Probing
   * @param {string} planId
   * @param {number} operationIdx
   * @param {string} [runId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public validateProbing(planId: string, operationIdx: number, runId?: string, options?: any) {
    return TasksApiFp(this.configuration)
      .validateProbing(planId, operationIdx, runId, options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_validateProbing(
    planId: string,
    operationIdx: number,
    runId?: string
  ): Promise<URL> {
    let url = new URL(`${this.basePath}/api/v1/tasks/probing/validate`)
    const localVarQueryParameter = {} as any
    if (planId !== undefined) {
      localVarQueryParameter["planId"] = planId
    }
    if (operationIdx !== undefined) {
      localVarQueryParameter["operationIdx"] = operationIdx
    }
    if (runId !== undefined) {
      localVarQueryParameter["run_id"] = runId
    }
    // authentication OAuth2QueryAndBearer required
    // oauth required
    if (this.configuration && this.configuration.accessToken) {
      const accessToken = this.configuration.accessToken
      const localVarAccessTokenValue =
        typeof accessToken === "function"
          ? await accessToken("OAuth2QueryAndBearer", [])
          : await accessToken
      localVarQueryParameter["access_token"] = localVarAccessTokenValue
    }

    const query = new URLSearchParams(url.search)
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key])
    }
    url.search = new URLSearchParams(query).toString()
    return url
  }
}

/**
 * ToolsApi - axios parameter creator
 * @export
 */
export const ToolsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Tools Wintool Vericut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsWintoolVericut: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tools/wintool-vericut`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com")
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication OAuth2QueryAndBearer required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken("OAuth2QueryAndBearer", [])
            : await configuration.accessToken
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ToolsApi - functional programming interface
 * @export
 */
export const ToolsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Tools Wintool Vericut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToolsWintoolVericut(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WintoolVericutResponse>> {
      const localVarAxiosArgs = await ToolsApiAxiosParamCreator(
        configuration
      ).getToolsWintoolVericut(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ToolsApi - factory interface
 * @export
 */
export const ToolsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get Tools Wintool Vericut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToolsWintoolVericut(options?: any): AxiosPromise<WintoolVericutResponse> {
      return ToolsApiFp(configuration)
        .getToolsWintoolVericut(options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ToolsApi - object-oriented interface
 * @export
 * @class ToolsApi
 * @extends {BaseAPI}
 */
export class ToolsApi extends BaseAPI {
  /**
   *
   * @summary Get Tools Wintool Vericut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolsApi
   */
  public getToolsWintoolVericut(options?: any) {
    return ToolsApiFp(this.configuration)
      .getToolsWintoolVericut(options)
      .then(request => request(this.axios, this.basePath))
  }

  public async urlFor_getToolsWintoolVericut(): Promise<URL> {
    return new URL(`${this.basePath}/api/v1/tools/wintool-vericut`)
  }
}
