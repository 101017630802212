import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
} from "@reduxjs/toolkit"

import { PlanchangerApi } from "src/client-axios"
import { ToolInfo } from "src/graphql/generated"
import { RootState } from "src/store/rootStore"
import { activeActions } from "../cam/active"
import { machineSelectors } from "../cam/machine"

/**
 * Adapters
 */
const toolsAdapter = createEntityAdapter<ToolInfo>({
  selectId: tool => tool.id,
})

/**
 * Slices
 */
const toolsSlice = createSlice({
  name: "TOOLS",
  initialState: toolsAdapter.getInitialState(),
  reducers: {
    addMany: toolsAdapter.addMany,
  },
})

/**
 * Reducers + actions
 */
export const { reducer: toolsConfigReducer } = toolsSlice
const { actions: toolsActions } = toolsSlice

/**
 * Selectors
 */
const toolsAdapterSelectors = toolsAdapter.getSelectors<RootState>(
  state => state.config.toolsConfig
)

const selectToolRecord = (state: RootState, id?: string): ToolInfo | undefined =>
  id === undefined ? undefined : toolsAdapterSelectors.selectById(state, id)

const selectToolRecords = (state: RootState): ToolInfo[] =>
  toolsAdapterSelectors.selectAll(state).slice()

const selectToolRecordsMap = (state: RootState): Dictionary<ToolInfo> =>
  toolsAdapterSelectors.selectEntities(state)

const selectCurrentTool = createSelector(
  [selectToolRecords, (state: RootState) => machineSelectors.selectToolId(state)],
  (toolGeometries, currentToolId) => toolGeometries.find(record => record.id === currentToolId)
)

export const toolsSelectors = {
  selectToolRecord,
  selectToolRecords,
  selectToolRecordsMap,
  selectCurrentTool,
}
/**
 * Thunks
 */
const fetchToolsConfig = createAsyncThunk(
  "config/tools/fetchToolsConfig",
  async ({ planchangerApi }: { planchangerApi: PlanchangerApi }, thunkAPI) => {
    try {
      const toolsConfig = ((await planchangerApi.toolsGeometry()).data as unknown) as ToolInfo[]
      thunkAPI.dispatch(toolsActions.addMany(toolsConfig))
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch tools config: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const toolsThunks = {
  fetchToolsConfig,
}
