import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as THREE from "three"

import {
  Direction,
  GcodeOutput,
  LineSegmentMode,
  LineSegmentNetwork,
  MachinedParametricStockSimInfo,
  MachinedStockSimSurfaceCylinder,
  MachinedStockSimSurfaceCylinderKindEnum,
  MachinedStockSimSurfaceOrientation,
  ParametricStockSimInfo,
  Point,
  StockAnalysisToolPathData,
} from "src/client-axios"
import { KinematicErrorMap } from "src/components/Canvas/Viewer/Scene/Cam/kinematicError"
import { AnimatedGltfUrlModel } from "src/components/Canvas/Viewer/SceneItems/GltfUrlModel"
import { Transform } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import {
  CompensationDisplayMode,
  DisplayMode,
  getSelectedStockParamSurfaces,
  StockMeshInfo,
  StockMeshKind,
  ToolChangeDisplayMode,
  useCuttingSimDisplayStore,
} from "src/store/zustandCuttingSim"
import {
  getTableToSpindleTransformOrientation,
  useMachineCoordsStore,
} from "src/store/zustandMachine"
import { CylinderData, CylinderOrientation } from "src/util/geometry/cylinder"
import { MatrixTransform } from "src/util/geometry/transforms"
import { getPartMaterial } from "./materials"
import {
  augmentStockMesh,
  augmentStockMeshWithGcodeData,
  augmentStockMeshWithKinematicMap,
  customProgramCacheKey as stockMeshCustomProgramCacheKey,
  getStockMeshesParent,
  onBeforeCompile as stockMeshOnBeforeCompile,
} from "./stockMeshVis"
import { NcEventIntervals } from "./stockSimDefs"
import { vector3Attribute } from "./visUtils"

interface StockSimMeshProps {
  gcodeOutput?: GcodeOutput
  toolPathCbor?: StockAnalysisToolPathData
  ncEventIntervals: NcEventIntervals
  cutNumberToIndex?: Record<number, number>
  stockGltfUrl: string
  toolChangeIndex?: number
  parametricSurfaceData?: ParametricStockSimInfo
  machinedParametricSurfaceData?: MachinedParametricStockSimInfo
  kinematicErrorMap?: KinematicErrorMap
}

interface StockMeshGroup {
  stock?: THREE.Mesh
  restStock?: THREE.Mesh
  gougeStock?: THREE.Mesh
}

interface StockDiffMeshGroup {
  restStock: THREE.Mesh
  gougeStock: THREE.Mesh
}

function arrayMinMax(array: ArrayLike<number>): THREE.Vector2 {
  const minMax = new THREE.Vector2(array[0], array[0])
  for (let i = 1; i < array.length; ++i) {
    if (array[i] >= 0) {
      minMax.x = Math.min(array[i], minMax.x)
      minMax.y = Math.max(array[i], minMax.y)
    }
  }
  return minMax
}

function getStockMeshes(group: THREE.Group): StockMeshGroup {
  const simMeshesParent = getStockMeshesParent(group)
  let stock: THREE.Mesh | undefined
  let restStock: THREE.Mesh | undefined
  let gougeStock: THREE.Mesh | undefined
  if (simMeshesParent.children.length === 1) {
    const child = simMeshesParent.children[0]
    if (child instanceof THREE.Mesh) {
      stock = child as THREE.Mesh
    }
  } else {
    for (let i = 0; i < simMeshesParent.children.length; ++i) {
      const child = simMeshesParent.children[i]
      if (child instanceof THREE.Mesh) {
        const mesh = child as THREE.Mesh
        if (mesh.name === "stock") {
          stock = mesh
        } else if (mesh.name === "rest_stock") {
          restStock = mesh
        } else if (mesh.name === "gouge_stock") {
          gougeStock = mesh
        }
      }
    }
  }
  return {
    stock,
    restStock,
    gougeStock,
  }
}

export function partToSetupMatrix(mcs: THREE.Matrix4): THREE.Matrix4 {
  const mat = new THREE.Matrix4()
  mat.extractRotation(mcs)
  mat.invert()

  const tableToSpindle = getTableToSpindleTransformOrientation()
  const partToSetup = new THREE.Matrix4()
  partToSetup.extractRotation(tableToSpindle)
  partToSetup.multiply(mat)
  return partToSetup
}

function setupStockDiffMeshGroupMaterials(stockDiffMeshGroup: StockDiffMeshGroup) {
  if (stockDiffMeshGroup?.restStock) {
    const material = stockDiffMeshGroup.restStock.material as THREE.MeshStandardMaterial
    material.color = new THREE.Color(0.0, 0.3, 0.8)
    material.onBeforeCompile = (_shader: THREE.Shader) => {
      material.color = new THREE.Color(0.0, 0.3, 0.8)
    }
  }

  if (stockDiffMeshGroup?.gougeStock) {
    const material = stockDiffMeshGroup.gougeStock.material as THREE.MeshStandardMaterial
    material.color = new THREE.Color(1.0, 0.2, 0.2)
    material.onBeforeCompile = (_shader: THREE.Shader) => {
      material.color = new THREE.Color(1.0, 0.2, 0.2)
    }
  }
}

interface BasicMaterialProps {
  stockOpacity: number
  metalness: number
  roughness: number
}

function updateStockMaterialDisplay(
  state: BasicMaterialProps,
  material: THREE.MeshStandardMaterial
) {
  material.transparent = state.stockOpacity !== 1.0
  material.opacity = state.stockOpacity
  material.metalness = state.metalness
  material.roughness = state.roughness
}

interface ShaderDisplayProps {
  displayMode: DisplayMode
  toolChangedisplayMode: ToolChangeDisplayMode
  compensationDisplayMode: CompensationDisplayMode
  kinematicErrorThreshold: number
  kinematicWarningThreshold: number
}

function updateStockShaderDisplay(state: ShaderDisplayProps, shader: THREE.Shader) {
  shader.uniforms.kinematic_error_threshold.value = state.kinematicErrorThreshold
  shader.uniforms.kinematic_warning_threshold.value = state.kinematicWarningThreshold

  switch (state.displayMode) {
    case DisplayMode.ToolChange:
      shader.uniforms.display_mode.value = 0.0
      break

    case DisplayMode.CutterComp:
      shader.uniforms.display_mode.value = 1.0
      break

    case DisplayMode.KinematicTolerance:
      shader.uniforms.display_mode.value = 3.0
      break
  }

  switch (state.toolChangedisplayMode) {
    case ToolChangeDisplayMode.ToolChange:
      shader.uniforms.tool_change_type.value = 0.0
      break

    case ToolChangeDisplayMode.Speed:
      shader.uniforms.tool_change_type.value = 1.0
      break

    case ToolChangeDisplayMode.Feed:
      shader.uniforms.tool_change_type.value = 2.0
      break
  }

  switch (state.compensationDisplayMode) {
    case CompensationDisplayMode.Usage:
      shader.uniforms.compensation_type.value = 0.0
      break

    case CompensationDisplayMode.Index:
      shader.uniforms.compensation_type.value = 1.0
      break

    case CompensationDisplayMode.Inspection:
      shader.uniforms.compensation_type.value = 2.0
      break
  }
}

function initializeMeshShader(
  ncEventIntervals: NcEventIntervals,
  simMesh: THREE.Mesh,
  shader: THREE.Shader,
  partToSetup: THREE.Matrix4,
  toolChangeIndex?: number
) {
  stockMeshOnBeforeCompile(shader, ncEventIntervals.toolChanges, partToSetup)
  shader.uniforms.current_tool_change.value = toolChangeIndex != null ? toolChangeIndex - 1 : -1
  if (simMesh.geometry.attributes.speed !== undefined) {
    shader.uniforms.speed_min_max.value = arrayMinMax(simMesh.geometry.attributes.speed.array)
  }
  if (simMesh.geometry.attributes.feed !== undefined) {
    shader.uniforms.feed_min_max.value = arrayMinMax(simMesh.geometry.attributes.feed.array)
  }
}

function initializeKinematicParams(shader: THREE.Shader) {
  useCuttingSimDisplayStore.setState({
    kinematicErrorThreshold: shader.uniforms.kinematic_error_threshold.value as number,
    kinematicWarningThreshold: shader.uniforms.kinematic_warning_threshold.value as number,
  })
}

function isToolChangeMesh(toolChangeIndex: number | undefined) {
  return toolChangeIndex !== undefined
}

function isVisible(
  toolChangeIndex: number | undefined,
  currentStockMeshName: StockMeshInfo
): boolean {
  if (isToolChangeMesh(toolChangeIndex)) {
    return (
      currentStockMeshName.kind === StockMeshKind.ToolChangeStock &&
      toolChangeIndex === currentStockMeshName.index
    )
  } else {
    return currentStockMeshName.kind === StockMeshKind.OutputStock
  }
}

function machinedSurfaceTransformForProbing(machinedSurfaceTransform: Transform): THREE.Matrix4 {
  const partToSurfaceTransform = new MatrixTransform(machinedSurfaceTransform)
  return partToSurfaceTransform.invert().toMatrix()
}

function pointToThreeVector(point: Point): THREE.Vector3 {
  return new THREE.Vector3(point.x, point.y, point.z)
}

function directionToThreeVector(direction: Direction): THREE.Vector3 {
  return new THREE.Vector3(direction.x, direction.y, direction.z)
}

function machinedCyliderToCylinderData(
  machinedSurface: MachinedStockSimSurfaceCylinder,
  transform: THREE.Matrix4,
  quaternion: THREE.Quaternion
): CylinderData {
  const location = pointToThreeVector(machinedSurface.position).applyMatrix4(transform)
  const direction = directionToThreeVector(machinedSurface.axis).applyQuaternion(quaternion)
  return {
    face_idx: machinedSurface.machiningInfo.surface_index,
    direction: [direction.x, direction.y, direction.z],
    location: [location.x, location.y, location.z],
    diameter: machinedSurface.radius * 2.0,
    min_angle: 0.0,
    max_angle: Math.PI * 2.0,
    height: machinedSurface.height,
    area: 1.0,
    orientation:
      machinedSurface.orientation === MachinedStockSimSurfaceOrientation.Forward
        ? CylinderOrientation.Forward
        : CylinderOrientation.Reversed,
  }
}

function createMachinedParametricStockCylinderData(
  machinedParametricSurfaceData: MachinedParametricStockSimInfo
): Array<CylinderData> {
  const surfaceTransform = machinedSurfaceTransformForProbing(
    machinedParametricSurfaceData.transform
  )

  const _translation = new THREE.Vector3()
  const _scale = new THREE.Vector3()
  const surfaceTransformQuaternion = new THREE.Quaternion()
  surfaceTransform.decompose(_translation, surfaceTransformQuaternion, _scale)

  const cylinderData: Array<CylinderData> = []
  for (let i = 0; i < machinedParametricSurfaceData.surfaces.length; ++i) {
    const machinedSurface = machinedParametricSurfaceData.surfaces[i]
    if (machinedSurface.kind === MachinedStockSimSurfaceCylinderKindEnum.Cylinder) {
      cylinderData.push(
        machinedCyliderToCylinderData(machinedSurface, surfaceTransform, surfaceTransformQuaternion)
      )
    }
  }
  return cylinderData
}

function createBoundaryGeometry(lines: LineSegmentNetwork): THREE.BufferGeometry {
  const geometry = new THREE.BufferGeometry()
  const positions = []
  if (lines.indices) {
    for (let i = 0; i < lines.indices.length; ++i) {
      const idx = lines.indices[i] * 3
      positions.push(lines.vertices[idx])
      positions.push(lines.vertices[idx + 1])
      positions.push(lines.vertices[idx + 2])
    }
  }
  geometry.setAttribute("position", vector3Attribute(positions))
  return geometry
}

function createBoundaryLines(lines: LineSegmentNetwork): JSX.Element {
  const geometry = createBoundaryGeometry(lines)
  const material = new THREE.LineBasicMaterial({
    color: new THREE.Color("#000000"),
  })
  if (lines.mode === LineSegmentMode.Lines) {
    const line = new THREE.LineSegments(geometry, material)
    return <primitive object={line} />
  } else if (lines.mode === LineSegmentMode.LineStrip) {
    const line = new THREE.Line(geometry, material)
    return <primitive object={line} />
  }
  return <></>
}

export const StockSimMesh: FC<StockSimMeshProps> = ({
  gcodeOutput,
  toolPathCbor,
  ncEventIntervals,
  cutNumberToIndex,
  stockGltfUrl,
  toolChangeIndex,
  parametricSurfaceData,
  machinedParametricSurfaceData,
  kinematicErrorMap,
}) => {
  const groupRef = useRef<THREE.Group>(null)
  const [stockMesh, setStockMesh] = useState<THREE.Mesh | undefined>(undefined)
  const [material, setMaterial] = useState<THREE.MeshStandardMaterial | undefined>(undefined)
  const [shader, setShader] = useState<THREE.Shader | undefined>(undefined)
  const [stockDiffMeshGroup, setStockDiffMeshGroup] = useState<StockDiffMeshGroup | undefined>(
    undefined
  )
  const [loadMesh, setLoadMesh] = useState<boolean>(toolChangeIndex === undefined)

  const { transferInvalidate } = useTransferInvalidate()
  const state = useCuttingSimDisplayStore()
  const { mcs } = useMachineCoordsStore().rtcpState

  const partToSetup = useMemo(() => {
    return partToSetupMatrix(mcs)
  }, [mcs])

  const sceneCallback = useCallback((group: THREE.Group) => {
    const { stock, restStock, gougeStock } = getStockMeshes(group)
    if (stock !== undefined) {
      setStockMesh(stock)
    }
    if (restStock && gougeStock) {
      setStockDiffMeshGroup({
        restStock,
        gougeStock,
      })
    }
  }, [])

  const cylinderData = useMemo(() => {
    if (machinedParametricSurfaceData) {
      return createMachinedParametricStockCylinderData(machinedParametricSurfaceData)
    }
    return undefined
  }, [machinedParametricSurfaceData])

  useEffect(() => {
    if (stockMesh && cylinderData && parametricSurfaceData) {
      stockMesh.userData.facet_surface = parametricSurfaceData.facet_surface
      stockMesh.userData.cylinderData = cylinderData
    }
  }, [stockMesh, cylinderData, parametricSurfaceData])

  useEffect(() => {
    if (stockMesh) {
      const simMesh = stockMesh
      simMesh.userData.isStockSimMesh = true

      if (
        toolPathCbor &&
        cutNumberToIndex &&
        simMesh.geometry.attributes.cutter_comp === undefined
      ) {
        // Processing happens lazily because stockMesh is only set when the
        // THREE.js resource is created
        augmentStockMesh(
          simMesh,
          ncEventIntervals,
          partToSetup,
          toolPathCbor,
          cutNumberToIndex,
          state?.stockParamSurfaces
        )
      }

      if (gcodeOutput && simMesh.geometry.attributes.speed === undefined) {
        augmentStockMeshWithGcodeData(simMesh, gcodeOutput)
      }

      if (
        gcodeOutput &&
        kinematicErrorMap &&
        simMesh.geometry.attributes.axial_radial_error === undefined
      ) {
        const axialRadialError = augmentStockMeshWithKinematicMap(
          simMesh,
          gcodeOutput,
          kinematicErrorMap
        )
        if (axialRadialError) {
          const values = arrayMinMax(axialRadialError.array)
          useCuttingSimDisplayStore.setState({
            maxKinematicError: values.y * 1000.0,
          })
        }
      }
    }
  }, [
    stockMesh,
    ncEventIntervals,
    partToSetup,
    gcodeOutput,
    toolPathCbor,
    cutNumberToIndex,
    state,
    state.stockOpacity,
    state?.stockParamSurfaces,
    kinematicErrorMap,
  ])

  const materialKey = stockMeshCustomProgramCacheKey(ncEventIntervals.ncEvents)
  const { stockOpacity, metalness, roughness } = state
  useEffect(() => {
    if (stockMesh) {
      const simMesh = stockMesh
      const material = (simMesh.material as THREE.MeshStandardMaterial).clone()
      material.customProgramCacheKey = () => {
        return materialKey
      }
      material.onBeforeCompile = (shader: THREE.Shader) => {
        setShader(shader)
        initializeMeshShader(ncEventIntervals, stockMesh, shader, partToSetup, toolChangeIndex)
      }
      updateStockMaterialDisplay({ stockOpacity, metalness, roughness }, material)

      simMesh.material = material
      setMaterial(material)
    }
  }, [
    stockMesh,
    materialKey,
    ncEventIntervals,
    partToSetup,
    stockOpacity,
    metalness,
    roughness,
    toolChangeIndex,
  ])

  useEffect(() => {
    if (shader) {
      initializeKinematicParams(shader)
    }
  }, [shader])

  useEffect(() => {
    useCuttingSimDisplayStore.setState({ enableRestGougeMaterial: !!stockDiffMeshGroup?.restStock })
    if (stockDiffMeshGroup) {
      setupStockDiffMeshGroupMaterials(stockDiffMeshGroup)
    }
  }, [stockDiffMeshGroup])
  useEffect(() => {
    if (material) {
      updateStockMaterialDisplay(
        {
          stockOpacity: state.stockOpacity,
          metalness: state.metalness,
          roughness: state.roughness,
        },
        material
      )
    }
  }, [material, state.stockOpacity, state.metalness, state.roughness])
  useEffect(() => {
    if (stockDiffMeshGroup) {
      if (stockDiffMeshGroup.gougeStock) {
        stockDiffMeshGroup.gougeStock.visible = state.showRestGougeMaterial
      }
      if (stockDiffMeshGroup.restStock) {
        stockDiffMeshGroup.restStock.visible = state.showRestGougeMaterial
      }
    }
  }, [stockDiffMeshGroup, state.showRestGougeMaterial])
  useEffect(() => {
    if (shader) {
      updateStockShaderDisplay(
        {
          displayMode: state.displayMode,
          toolChangedisplayMode: state.toolChangedisplayMode,
          compensationDisplayMode: state.compensationDisplayMode,
          kinematicErrorThreshold: state.kinematicErrorThreshold,
          kinematicWarningThreshold: state.kinematicWarningThreshold,
        },
        shader
      )
      transferInvalidate()
    }
  }, [
    shader,
    state.displayMode,
    state.toolChangedisplayMode,
    state.compensationDisplayMode,
    state.kinematicErrorThreshold,
    state.kinematicWarningThreshold,
    transferInvalidate,
  ])

  const selectedStockParamSurfaces = getSelectedStockParamSurfaces()
  useEffect(() => {
    const selectedParametricSurface = selectedStockParamSurfaces[0]
    if (shader) {
      shader.uniforms.current_parametric_surface.value =
        selectedParametricSurface >= 0 ? selectedParametricSurface : -1
    }
  }, [selectedStockParamSurfaces, shader])

  // Used to lazy load the mesh
  useEffect(() => {
    if (
      state.currentStockMesh.kind === StockMeshKind.ToolChangeStock &&
      isVisible(toolChangeIndex, state.currentStockMesh)
    ) {
      setLoadMesh(true)
    }
  }, [state.currentStockMesh, toolChangeIndex])

  // Used to enable/disable visibility
  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.visible = isVisible(toolChangeIndex, state.currentStockMesh)
      transferInvalidate()
    }
  }, [state.currentStockMesh, groupRef, toolChangeIndex, transferInvalidate])

  useEffect(() => {
    const stockMeshesInfos = state.stockMeshes
    if (stockMesh && stockMeshesInfos.length > 0) {
      const stockMeshInfo = stockMeshesInfos.find(info => info.index === toolChangeIndex)
      if (stockMeshInfo) {
        stockMeshInfo.loaded = true
      }
    }
  }, [state, toolChangeIndex, stockMesh])

  const boundaryLines = useMemo(() => {
    const surfacesBoundary = machinedParametricSurfaceData?.surfaces_boundary
    if (surfacesBoundary) {
      return createBoundaryLines(surfacesBoundary)
    }
    return undefined
  }, [machinedParametricSurfaceData?.surfaces_boundary])

  if (!loadMesh) {
    return <></>
  }

  return (
    <group ref={groupRef}>
      <AnimatedGltfUrlModel
        url={stockGltfUrl}
        flatShading={true}
        material={getPartMaterial(false)}
        sceneCallback={sceneCallback}
      />
      {boundaryLines}
    </group>
  )
}
