import React, { FC, useCallback, useMemo } from "react"
import * as THREE from "three"

import { useTransferCanvasResizeListener } from "src/hooks/transferCanvas/useTransferCanvasResizeListener"
import { useCameraControls } from "src/hooks/useCameraControls"
import { useEventListener, useThreeEventListener } from "src/hooks/useEventListener"

interface ResizeForCanvasProps {
  camera: THREE.OrthographicCamera
  canvas: HTMLCanvasElement
}

export const Sphere: FC<{
  radius: number
  widthSegments?: number
  heightSegments?: number
  color?: string | number | THREE.Color
  opacity?: number
  position?: [number, number, number] | THREE.Vector3
  visible?: boolean
  resize?: ResizeForCanvasProps
  canBeClicked?: boolean
}> = ({
  radius,
  widthSegments = 20,
  heightSegments = 20,
  color,
  opacity,
  position,
  visible,
  resize,
  canBeClicked = false,
}) => {
  const cameraControls = useCameraControls()

  const rescaleSphere = useCallback(
    (sphere: THREE.Mesh) => {
      if (!resize) return
      const { camera, canvas } = resize
      const canvasWidth = canvas.clientWidth
      sphere.scale.setScalar(
        (2 * radius * (camera.right - camera.left)) / (camera.zoom * canvasWidth)
      )
    },
    [radius, resize]
  )

  const sphere = useMemo(() => {
    const geometry = new THREE.SphereBufferGeometry(radius, widthSegments, heightSegments)
    const material = new THREE.MeshBasicMaterial({
      color: color,
      opacity: opacity ?? 1.0,
      transparent: opacity !== undefined && opacity < 1.0,
    })
    const sphere = new THREE.Mesh(geometry, material)
    sphere.userData.ignoreIntersection = !canBeClicked
    rescaleSphere(sphere)
    return sphere
  }, [canBeClicked, color, heightSegments, opacity, radius, widthSegments, rescaleSphere])

  useThreeEventListener(
    "change",
    () => rescaleSphere(sphere),
    cameraControls.controller?.controls ?? null
  )
  useEventListener("resize", () => rescaleSphere(sphere))
  useTransferCanvasResizeListener(() => rescaleSphere(sphere))

  return <primitive object={sphere} position={position} visible={visible} />
}
