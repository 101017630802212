import React, { FC, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useHistory } from "react-router-dom"
import { Button, Callout, Checkbox, Classes, Dialog, Spinner } from "@blueprintjs/core"
import { useCurrentUserData } from "@subscale/formlogic-auth-ts"
import { v4 as uuidv4 } from "uuid"

import { sendEvent } from "src/components/Websocket/Websocket"
import {
  Camjobstatus,
  useAllUsersQuery,
  useCreateCamJobMutation,
  useCreateUserJobMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { slugify } from "src/util/text"

import styles from "./UploadPartDialog.module.css"

export const UploadPartDialog: FC<{
  handleRequestClose: () => void
  isOpen: boolean
}> = ({ isOpen, handleRequestClose }) => {
  const { tasksApi, planchangerApi } = useApi()
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [isForProduction, setIsForProduction] = useState(false)
  const [createCamJobMutation] = useCreateCamJobMutation()
  const [createUserJob] = useCreateUserJobMutation()
  const history = useHistory()

  const { data: allUsers, loading, error } = useAllUsersQuery()
  const currentUserData = useCurrentUserData()
  const currentUserId = useMemo(() => {
    return allUsers?.users.find(user => user.email === currentUserData?.email)?.id
  }, [allUsers, currentUserData])

  const uploadFile = (file: File) => {
    setIsFileLoading(true)

    const label = file.name.split(".")[0]

    const id = uuidv4()

    planchangerApi.newPart("formlogic", label, file).then(results => {
      const modelId = results.data.id
      const materialDisplayName = `Aluminum 6061`
      createCamJobMutation({
        variables: {
          id,
          modelId: modelId,
          materialDisplayName,
          label,
          uri: slugify(`${id.substring(0, 4)}-${label}`),
          status: isForProduction ? Camjobstatus.Programming : Camjobstatus.Internal,
        },
        refetchQueries: ["primaryCamJobs", "overview", "getOverview"],
      }).then(response => {
        handleRequestClose()
        setIsFileLoading(false)
        const id = response.data?.createCamJob?.camJob?.id
        const uri = response.data?.createCamJob?.camJob?.uri
        if (!currentUserId) return
        if (id) {
          sendEvent("createJob", { jobId: id })
          createUserJob({
            variables: {
              jobId: id,
              userId: currentUserId,
            },
            refetchQueries: ["overview"],
          }).then(_response => {
            tasksApi.generateProposals(id)
            if (uri) {
              history.push(`/jobs/${uri}`)
            }
          })
        }
      })
    })
  }

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()
      reader.onabort = () => console.log("File reading was aborted")
      reader.onerror = () => console.log("File reading has failed")
      reader.onload = () => {
        uploadFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Dialog
      icon={"info-sign"}
      onClose={handleRequestClose}
      title={"Create New Part"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Callout intent={"danger"} icon={null} title={"Failed to retrieve user ID mapping"} />
        ) : !currentUserData ? (
          <Callout intent={"danger"} icon={null} title={"Failed to load user info"} />
        ) : !currentUserId ? (
          <Callout intent={"danger"} icon={null} title={"Failed to load user ID"} />
        ) : (
          <>
            <Checkbox
              checked={isForProduction}
              label="For production"
              onChange={() => setIsForProduction(!isForProduction)}
            />
            <Checkbox
              checked={!isForProduction}
              label="Non production"
              onChange={() => setIsForProduction(!isForProduction)}
            />
            <div {...getRootProps()} className={styles.dropContainer}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag and drop (.step) files here, or click to select files</p>
              )}
            </div>

            {isFileLoading && (
              <div className={styles.loader}>
                <Spinner />
              </div>
            )}
          </>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose} intent="primary">
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
