import React, { FC, useMemo } from "react"
import * as THREE from "three"

import { StockAnalysisToolPathData } from "src/client-axios"
import { useCuttingSimDisplayStore } from "src/store/zustandCuttingSim"
import { createToolPathLine, onBeforeCompile as toolPathOnBeforeCompile } from "./toolPathVis"

interface StockSimToolPathProps {
  toolPathCbor: StockAnalysisToolPathData
  toolPathInterval?: [number, number]
  toolPathIdx: number
}

function initLineMaterial(line?: THREE.Line) {
  if (line && line.material instanceof THREE.Material) {
    const maxIndex = line.userData.maxIndex as unknown
    if (typeof maxIndex !== "number") {
      console.error({ maxIndex })
      throw new Error("maxIndex was not a number")
    }
    line.material.onBeforeCompile = (shader: THREE.Shader) => {
      toolPathOnBeforeCompile(shader, maxIndex)
    }
  }
}

export const StockSimToolPath: FC<StockSimToolPathProps> = ({
  toolPathCbor,
  toolPathInterval,
  toolPathIdx,
}) => {
  const line = useMemo(() => {
    const line = createToolPathLine(toolPathCbor, toolPathInterval)
    initLineMaterial(line)
    if (line) {
      line.visible = false
    }
    return line
  }, [toolPathCbor, toolPathInterval])

  const state = useCuttingSimDisplayStore()
  if (line) {
    const visible = state.selectedToolPaths.find(v => v === toolPathIdx) !== undefined
    line.visible = visible
  }

  return line ? <primitive object={line} /> : <></>
}
