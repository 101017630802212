import React, { FC, useEffect, useRef, useState } from "react"
import ReactMarkdown from "react-markdown"
import { useSelector } from "react-redux"
import { Button, EditableText, Text } from "@blueprintjs/core"
import rehypeRaw from "rehype-raw"

import {
  useCamJobDetailsQuery,
  useUpdateCamJobNotesMutation,
  useUpdatePlanMutation,
} from "src/graphql/generated"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

import styles from "./NotesEditor.module.css"

export const useNotesEditor = (): {
  editable: boolean
  setEditable: React.Dispatch<React.SetStateAction<boolean>>
  onClickRef: React.MutableRefObject<((currentlyEditable: boolean) => void) | undefined>
} => {
  const [editable, setEditable] = useState(false)
  const onClickRef = useRef<(currentlyEditable: boolean) => void>()
  return { editable, setEditable, onClickRef }
}

export const NotesEditor: FC<{
  planId?: string
  jobId?: string
  smallButton?: boolean
  locked?: boolean
}> = ({ planId, jobId, smallButton, locked }) => {
  const { editable, setEditable, onClickRef } = useNotesEditor()

  return (
    <NotesEditorEntry
      planId={planId}
      jobId={jobId}
      onClickRef={onClickRef}
      editable={editable}
      setEditable={setEditable}
    >
      <div className={styles.editNoteButton}>
        <NotesEditorButton
          editable={editable}
          onClick={() => onClickRef.current?.(editable)}
          small={smallButton}
          locked={locked}
        />
      </div>
    </NotesEditorEntry>
  )
}

export const NotesEditorButton: FC<{
  editable: boolean
  onClick: () => void
  small?: boolean
  locked?: boolean
}> = ({ editable, small, onClick, locked }) => {
  const intent = editable ? "primary" : "none"
  const label = editable ? "Save Notes" : "Edit Notes"

  return (
    <Button small={small} onClick={() => onClick()} intent={intent} disabled={locked}>
      {label}
    </Button>
  )
}

export const NotesEditorEntry: FC<{
  planId?: string
  jobId?: string
  onClickRef: React.MutableRefObject<((currentlyEditable: boolean) => void) | undefined>
  editable: boolean
  setEditable: (editable: boolean) => void
}> = ({ planId, jobId, onClickRef, editable, setEditable, children }) => {
  const errorToast = useNetworkErrorToast()
  const [updatePlan] = useUpdatePlanMutation()
  const [updateCamJobNotes] = useUpdateCamJobNotesMutation()

  const planNotes = useSelector((state: RootState) =>
    storedPlansSelectors.selectPlanNotes(state, planId)
  )

  const { data: camJobDetails } = useCamJobDetailsQuery({
    variables: { jobId: jobId ?? "" },
  })

  const [notesText, setNotesText] = useState(
    planId ? planNotes : jobId ? camJobDetails?.camJob?.notes : ""
  )

  useEffect(() => {
    setNotesText(planId ? planNotes : jobId ? camJobDetails?.camJob?.notes : "")
  }, [planId, jobId, planNotes, camJobDetails])

  const handleUpdateNotes = (): void => {
    setEditable(false)
    if (planId) {
      if (notesText === planNotes) return
      updatePlan({
        variables: { planId, notes: notesText },
      }).catch(e => {
        errorToast(e, "Could not update plan notes")
      })
    } else if (jobId) {
      if (notesText === camJobDetails?.camJob?.notes) return
      updateCamJobNotes({
        variables: { id: jobId, notes: notesText ?? "" },
      }).catch(e => {
        errorToast(e, "Could not update job notes")
      })
    }
  }

  onClickRef.current = (currentlyEditable: boolean) => {
    if (currentlyEditable) {
      handleUpdateNotes()
    } else {
      setEditable(true)
    }
  }

  return (
    <div className={styles.noteGroup}>
      <div className={styles.noteLeader}>
        <Text className={styles.notesLabel}>
          <div className={styles.notesType}>{planId ? `Plan` : jobId ? `Part` : ``}</div>
          <div> Notes:</div>
        </Text>
      </div>
      <div className={styles.planNotes}>
        {editable && (
          <EditableText
            multiline
            placeholder={"No notes have been added"}
            className={`${styles.textArea} ${editable ? styles.editableTextArea : ""}`}
            disabled={!editable}
            onChange={value => setNotesText(value)}
            value={notesText ?? ""}
          />
        )}
        {!editable && (
          <ReactMarkdown className={styles.markdown} rehypePlugins={[rehypeRaw]}>
            {notesText ?? ""}
          </ReactMarkdown>
        )}
        {children}
      </div>
    </div>
  )
}
