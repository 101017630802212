export const VERICUT_MATERIAL_IDS = [
  "Alloy-Steel+300M+HRC30",
  "Alloy-Steel+4142+HRC30",
  "Alloy-Steel+516-Grade70+HRC17",
  "Aluminum+2014+HB125",
  "Aluminum+2024+HRB79",
  "Aluminum+2124+HB121",
  "Aluminum+2124+HRB79",
  "Aluminum+2618+HRB79",
  "Aluminum+5083+HRB53",
  "Aluminum+6061-T6+HB95",
  "Aluminum+7050+HRB53",
  "Aluminum+7055+HRB77",
  "Aluminum+7075+HB150",
  "Aluminum+7449+HRB77",
  "Aluminum+7475+HRB77",
  "Armor-Plate+HHA46100+HRC50",
  "Armor-Plate+RHA12560+HRC45",
  "CFRP+Carbon-Fiber-Reinforced-Plastic+HB25",
  "Cast-Iron+60-40-18+HRB87",
  "Cast-Iron+GG25+HRB95",
  "Cobalt+chrome+HRC35",
  "Copper-Tungsten+WCu+HRC15",
  "Ductile-Iron+80-55-06+HRC16",
  "Ductile-iron+100-70-03+HRC22",
  "HRSA+Hastelloy+HRC29",
  "HRSA+Hasteloy+HRC42",
  "HRSA+Haynes-282+HRC29",
  "HRSA+Haynes-286+HRC30",
  "HRSA+IN-100+HRC30",
  "HRSA+Waspaloy+HRC30",
  "Inconel+625+HRC34",
  "Inconel+718+HRC42",
  "Inconel+924+HRC42",
  "Nickel-Alloy+M247+HRC34",
  "Nickel-Iron-Alloy+Invar-36+HRC20",
  "Nickel-alloy+909+HRC42",
  "Nickel-alloy+HN50VMTYUB+HRC45",
  "Nickel-alloy+Rene-41+HRC43",
  "Non-Ferrous+Zirconium+HRB145",
  "Polyethelyene+UHMWPE+SHORE-D61",
  "Rene+108+HRC43",
  "Stainless-Steel+13-8+HRC34",
  "Stainless-Steel+13CR+HRC34",
  "Stainless-Steel+13Cr+HRC40",
  "Stainless-Steel+15-5PH+HRC33",
  "Stainless-Steel+17-4+HRC26",
  "Stainless-Steel+17-4+HRC34",
  "Stainless-Steel+17-4+HRC40",
  "Stainless-Steel+17-7+HRC34",
  "Stainless-Steel+25Cr+HRC25",
  "Stainless-Steel+304+HRC34",
  "Stainless-Steel+316+HRB89",
  "Stainless-Steel+321+HRB84",
  "Stainless-Steel+347+HRC30",
  "Stainless-Steel+410+HRC28",
  "Stainless-Steel+418+HRC28",
  "Stainless-Steel+420+HRC12",
  "Stainless-Steel+Carpenter-465+HRC28",
  "Stainless-Steel+Datalloy-2+HRC60",
  "Stainless-Steel+Duplex-2205+HRC25",
  "Stainless-Steel+M-152+HRC40",
  "Stainless-Steel+Stavax-420M+HRC52",
  "Stainless-Steel+X4CrNiCuMo14-5+HRC34",
  "Steel+1018+HRC25",
  "Steel+1045+HRC25",
  "Steel+4130+HRC30",
  "Steel+4140+HRC30",
  "Steel+4340+HRC30",
  "Steel+4340+HRC45",
  "Steel+4340+HRC55",
  "Steel+5140+HRC30",
  "Steel+8630M+HRC40",
  "Steel+Aermat-100+HRC50",
  "Steel+D6AC+HRC28",
  "Steel+S355J2_Ovako-520M+HRC21",
  "Steel+THG2000+HRC55",
  "Titanium+10-2-3+HRC 40",
  "Titanium+52244+HRC39",
  "Titanium+5553+HRC39",
  "Titanium+5555+HRC39",
  "Titanium+6-4-2-6+HRC36",
  "Titanium+6AL-3.5Mo-1.5Zr-0.3Si+HRC32",
  "Titanium+6Al-1.5Cr-2.5Mo-.5Fe-0.3+HRC36",
  "Titanium+6Al4v+HRC37",
  "Titanium+6al4v+HRC37+HSS",
  "Titanium+BT25Y+HRC34",
  "Tool-Steel+0+HRC17",
  "Tool-Steel+7CrSiMnMoV+HRC17",
  "Tool-Steel+A2+HRC10",
  "Tool-Steel+A2+HRC40",
  "Tool-Steel+A2+HRC50",
  "Tool-Steel+Cr12Mov+HRC60",
  "Tool-Steel+D2+HRC20",
  "Tool-Steel+D2+HRC40",
  "Tool-Steel+D2+HRC60",
  "Tool-Steel+H13+HRC40",
  "Tool-Steel+H13+HRC55",
  "Tool-Steel+P20+HRC26",
  "Tool-Steel+S7+HRC45",
  "Tool-Steel+S7+HRC60",
  "steel+4140+HRC30",
]
