import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { QualityControlPlanData } from "src/client-axios"
import { useGetDrawingLazyQuery, useQualityControlPlansLazyQuery } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { activeSelectors } from "src/store/cam/active"

export const useGetControlPlanData = (jobId?: string) => {
  const { planchangerApi } = useApi()

  const activeJobId = useSelector(activeSelectors.selectActiveJobId)
  const [
    getControlPlans,
    { data: controlPlans, loading, refetch: refetchControlPlan },
  ] = useQualityControlPlansLazyQuery()
  const [getDrawing, { data: drawingData }] = useGetDrawingLazyQuery()

  const [controlPlanId, setControlPlanId] = useState<string | undefined>()
  const [controlPlanDrawingId, setControlPlanDrawingId] = useState<string | undefined>()
  const [controlPlanData, setControlPlanData] = useState<QualityControlPlanData | undefined>()

  useEffect(() => {
    const maybeJob = jobId ?? activeJobId
    if (maybeJob !== undefined) {
      getControlPlans({ variables: { jobId: maybeJob } })
    }
  }, [jobId, activeJobId, getControlPlans])

  useEffect(() => {
    const controlPlanNode = controlPlans?.qualityControlPlans?.nodes[0]
    setControlPlanId(controlPlanNode?.id)
    setControlPlanDrawingId(controlPlanNode?.drawingId)
  }, [controlPlans])

  useEffect(() => {
    if (controlPlanDrawingId !== undefined) {
      getDrawing({ variables: { drawingId: controlPlanDrawingId } })
    }
  }, [controlPlanDrawingId, getDrawing])

  const refetchControlPlanData = useCallback(
    (revision?: number) => {
      if (controlPlanId !== undefined) {
        planchangerApi.getPlanchangerQualityControlPlan(controlPlanId, revision).then(val => {
          setControlPlanData((val?.data as unknown) as QualityControlPlanData)
        })
      }
    },
    [controlPlanId, planchangerApi]
  )

  useEffect(() => {
    refetchControlPlanData()
  }, [controlPlanId, planchangerApi, refetchControlPlanData])

  const highQAFileName = drawingData?.drawing?.xdfFileLocator?.substring(
    drawingData?.drawing?.xdfFileLocator?.lastIndexOf("/") + 1
  )

  const controlPlan = {
    id: controlPlanId,
    data: controlPlanData,
    highQAFileName,
    drawingId: controlPlanDrawingId,
    drawingFileName: drawingData?.drawing?.camJobFile?.file?.basename,
    drawingFileId: drawingData?.drawing?.camJobFile?.fileId,
  }

  return { controlPlan, loading, refetchControlPlan, refetchControlPlanData }
}
