import React, { FC } from "react"
import { Button, Callout, Classes, Dialog } from "@blueprintjs/core"

import { useCancelTaskMutation, useCancelVericutLockMutation } from "src/graphql/generated"

export const StopTaskDialog: FC<{
  forceClose: boolean
  setForceClose: React.Dispatch<React.SetStateAction<boolean>>
  toStop?: string
  setToStop: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({ forceClose, setForceClose, toStop, setToStop }) => {
  const [cancelLock] = useCancelVericutLockMutation()
  const [cancelTask] = useCancelTaskMutation()
  return (
    <Dialog
      icon={"error"}
      onClose={() => {
        setForceClose(false)
        setToStop(undefined)
      }}
      title={"Stopping Task"}
      isOpen={toStop !== undefined}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>Are you sure you want to stop this task?</p>

        {forceClose && (
          <Callout intent="danger">
            Warning: Forcefully closing a task may cause the license to be unavailable for a few
            minutes, meaning other pending tasks can fail.
            <br />
            Instead: Please login and close vericut cleanly
          </Callout>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => setToStop(undefined)}>Cancel</Button>
          <Button
            icon="stop"
            onClick={() => {
              if (toStop) {
                const promise = forceClose ? cancelLock : cancelTask
                promise({ variables: { taskId: toStop } }).then(() => {
                  setToStop(undefined)
                })
              }
            }}
            intent="danger"
          >
            Stop Task
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
