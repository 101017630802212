import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { Button, HTMLSelect, Icon, Label } from "@blueprintjs/core"

import { MachineKind, MachineOffset, OffsetKind } from "src/client-axios"
import { MachineInstanceSelector } from "src/components/Cam/ProductionControls/MachineOffsetsDialog/MachineInstanceSelector/MachineInstanceSelector"
import {
  ALLOW_CUSTOM_OFFSETS,
  defaultOffsets,
} from "src/components/Cam/ProductionControls/MachineOffsetsDialog/utils"
import {
  checkMachineOffsetExists,
  updateMachineOffsets,
} from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/ProductionControlsPanel/updateProductionControls"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { RootState } from "src/store/rootStore"

import styles from "./MachineOffsetSettingsPanel.module.css"

export const MACHINE_OFFSET_VALUES_KEY_COMPARATOR = (a: [string, number], b: [string, number]) => {
  return a[0].localeCompare(b[0], "en", { sensitivity: "base" })
}

export const MachineOffsetSettingsPanel: FC<{
  offset: MachineOffset
  offsetId: string
  planId?: string
  operationIdx?: number
}> = ({ offset, offsetId, planId, operationIdx }) => {
  const probingThunkContext = useProbingThunkContext()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(
      state,
      planId ?? probingThunkContext.planId,
      operationIdx ?? probingThunkContext.operationIdx
    )
  )

  const [offsetExists, setOffsetExists] = useState<boolean>(false)

  const offsets = operation?.offsets ?? []
  const offsetIdx = Number(offsetId)

  const handleCheckOffsetExists = (offset: MachineOffset) =>
    checkMachineOffsetExists(offsets, offset)

  const updateOffset = (offset: MachineOffset) => {
    const updatedOffsets = offsets.slice()
    updatedOffsets.splice(offsetIdx, 1, offset)

    updateMachineOffsets({
      thunkInputs: {
        ...probingThunkContext,
        planId: planId ?? probingThunkContext.planId,
        operationIdx: operationIdx ?? probingThunkContext.operationIdx,
      },
      updatedOffsets,
    })
    setOffsetExists(false)
  }

  const handleSetOffsetKeyValue = (oldKey: string, newKey: string, value: number) => {
    let newOffsets = offset.offsets
    if (oldKey !== newKey) {
      delete newOffsets[oldKey]
    }
    newOffsets = { ...newOffsets, [newKey]: value }
    updateOffset({ ...offset, offsets: newOffsets })
  }

  const handleRemoveOffsetKeyValue = (key: string) => {
    const newOffsets = offset.offsets
    delete newOffsets[key]
    updateOffset({ ...offset, offsets: newOffsets })
  }

  const handleAddOffsetKeyValue = () => {
    updateOffset({ ...offset, offsets: { ...offset.offsets, "<Key>": 0 } })
  }

  const handleKindChange = (offsetKind: OffsetKind) => {
    if (offsetKind !== offset.kind) {
      const updatedOffset = { ...offset, kind: offsetKind, offsets: defaultOffsets(offsetKind) }
      const offsetExists = handleCheckOffsetExists(updatedOffset)
      if (!offsetExists) {
        updateOffset({ ...offset, kind: offsetKind, offsets: defaultOffsets(offsetKind) })
      }
      setOffsetExists(offsetExists)
    }
  }

  const offsetKindFormatted = new Map<OffsetKind, string>([
    [OffsetKind.POSITION, "Position Offset"],
    [OffsetKind.TOOL, "Tool Offset"],
    [OffsetKind.FEEDANDSPEED, "Feed/Speed"],
    [OffsetKind.TOOLRENUMBER, "Tool Renumber"],
    [OffsetKind.WCS, "WCS"],
  ])

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.offsetFields}>
          <div className={styles.offsetField}>Type</div>
          <div className={styles.offsetField}>Machine</div>
          <div className={styles.offsetField}>Values</div>
        </div>

        <div className={styles.offsetValues}>
          <div className={styles.offsetValue}>
            <HTMLSelect
              value={offset.kind}
              onChange={e => handleKindChange(e.target.value as OffsetKind)}
              className={styles.select}
            >
              {Object.entries(OffsetKind).map(([key, value]) => {
                return (
                  <option value={value} key={key}>
                    {offsetKindFormatted.get(value) ?? key}
                  </option>
                )
              })}
            </HTMLSelect>
          </div>
          <div className={styles.offsetValue}>
            <HTMLSelect
              value={offset.machineKind}
              onChange={e => {
                const updatedOffset = { ...offset, machineKind: e.target.value as MachineKind }
                const offsetExists = handleCheckOffsetExists(updatedOffset)
                if (!offsetExists) {
                  updateOffset(updatedOffset)
                }
                setOffsetExists(offsetExists)
              }}
              className={styles.select}
            >
              {Object.entries(MachineKind).map(([key, value]) => {
                return (
                  <option value={value} key={key}>
                    {key}
                  </option>
                )
              })}
            </HTMLSelect>
            <div className={styles.machineInstanceContainer}>
              <Label htmlFor="machineInstance" className={styles.instanceLabel}>
                Instance:
                <MachineInstanceSelector
                  activeItem={offset.machineInstance}
                  onMachineChanged={machineInstance => {
                    const updatedOffset = { ...offset, machineInstance }
                    const offsetExists = handleCheckOffsetExists(updatedOffset)
                    if (!offsetExists) {
                      updateOffset(updatedOffset)
                    }
                    setOffsetExists(offsetExists)
                  }}
                />
              </Label>
            </div>
          </div>
          <div className={styles.offsetValue}>
            {Object.entries(offset.offsets)
              .sort(MACHINE_OFFSET_VALUES_KEY_COMPARATOR)
              .map(([key, value]) => {
                return (
                  <div className={styles.offsetInputRow} key={key}>
                    <div>
                      <input
                        type="text"
                        value={key}
                        className={styles.offsetInputKey}
                        disabled={true}
                        onChange={e => {
                          handleSetOffsetKeyValue(key, e.target.value, value)
                        }}
                      />
                    </div>
                    <div>
                      {key === "wcs" && offset.kind === OffsetKind.WCS && (
                        <select
                          value={value}
                          onChange={e => {
                            handleSetOffsetKeyValue(key, key, parseInt(e.target.value))
                          }}
                        >
                          {(() => {
                            const arr = []
                            for (let i = 55; i <= 59; i++) {
                              arr.push(
                                <option value={i} key={i}>
                                  G{i}
                                </option>
                              )
                            }
                            return arr
                          })()}
                        </select>
                      )}
                      {(key !== "wcs" || offset.kind !== OffsetKind.WCS) && (
                        <FormulaInput
                          fill
                          commitOnBlur={true}
                          reset={false}
                          value={value}
                          onValueChange={v => {
                            handleSetOffsetKeyValue(key, key, v)
                          }}
                          suffix={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? "%"
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? "TC"
                              : "mm"
                          }
                          stepSize={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? 1.0
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? 1
                              : 0.1
                          }
                          minorStepSize={
                            offset.kind === OffsetKind.FEEDANDSPEED
                              ? 0.1
                              : offset.kind === OffsetKind.TOOLRENUMBER
                              ? 1
                              : 0.001
                          }
                        />
                      )}
                    </div>
                    {ALLOW_CUSTOM_OFFSETS ? (
                      <Button onClick={() => handleRemoveOffsetKeyValue(key)}>
                        <Icon icon="cross" />
                      </Button>
                    ) : null}
                  </div>
                )
              })}
            {ALLOW_CUSTOM_OFFSETS ? (
              <Button rightIcon="add" onClick={handleAddOffsetKeyValue}>
                Add offset key value
              </Button>
            ) : null}
          </div>
        </div>
        <div>
          {offsetExists && (
            <Label className={styles.offsetExists}>
              Error updating control, type already exists
            </Label>
          )}
        </div>
      </div>
    </div>
  )
}
