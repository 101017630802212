import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Button, HTMLTable, Icon, Menu, MenuItem } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { Operation, VericutToolChangeDetails } from "src/client-axios"
import { ContextTarget } from "src/components/Generic/ContextTarget/ContextTarget"
import {
  NcEventNodeFragment,
  ToolInfoNodeFragment,
  VericutFeedbackNodeFragment,
} from "src/graphql/generated"
import styles from "src/pages/GCodeEditor/ToolChangeTable/ToolChangeTable.module.css"
import { ToolchangeData, useToolchangesData } from "src/pages/GCodeEditor/useToolchangesData"
import { toolColor } from "src/util/ui/toolColor"
import { MachineOffsetsDialog } from "../../../components/Cam/ProductionControls/MachineOffsetsDialog/MachineOffsetsDialog"

// hasOffsets is optional, because we use ToolChangeTableRowData
// elsewhere (currently ToolWearDialog.tsx)
export interface ToolChangeTableRowData {
  data: ToolchangeData
  highlight: boolean
  hasOffsets?: boolean
  offsetOnly?: boolean
  signature?: string
}

export const PROBE_TOOL_IDS = [0, 59, 60]

// In the following "row" means a line of the text file
export const ToolChangeTable: FC<{
  // **** Table data ****
  tools: ToolInfoNodeFragment[]
  ncEvents: NcEventNodeFragment[]
  feedback?: VericutFeedbackNodeFragment

  // **** Interaction with editor ****
  onRowClick: (row: number) => void

  onHideTable?: () => void
  // The set of rows (lines) currently highlighted/active in the editor
  currentRows: number[]

  // **** Display options ****
  // Reduce the height vertically
  short?: boolean
  // Reduce the width horizontally
  narrow?: boolean
  // Display tool colors used with "contact points" data
  showToolColor?: boolean
  planId?: string
  operation?: Operation
  operationIdx?: number
}> = ({
  tools,
  ncEvents,
  feedback,
  onRowClick,
  currentRows,
  short,
  narrow,
  showToolColor,
  onHideTable,
  planId,
  operation,
  operationIdx,
}) => {
  const toolchangesData = useToolchangesData(tools, ncEvents, feedback)

  const [showTable, setShowTableState] = useState<boolean>(true)
  const [showEditMachineOffsetsDialogRowData, setShowEditMachineOffsetsDialogRowData] = useState<
    ToolChangeTableRowData
  >()

  const setShowTable = (val: boolean) => {
    setShowTableState(val)
    // needs to happen on next render pass
    setTimeout(() => {
      onHideTable?.()
    })
  }

  const nonProbeToolchanges = useMemo(
    () =>
      toolchangesData.filter(
        tc => !new Set<number>(PROBE_TOOL_IDS).has(Number(tc.toolId)) && !isNaN(Number(tc.toolId))
      ),
    [toolchangesData]
  )

  const toolchangeRowsData = useMemo(() => {
    // TODO: should toolId and currentTool have the same type?
    // currently toolId is a string, but currentTool is a number (hence the casting)
    const toolsWithOffsets = new Set(
      operation?.offsets?.map(offset => offset.currentTool?.toString()) ?? []
    )

    const signatureCounts = new Map<string, number>()
    nonProbeToolchanges.forEach((tc, i) => {
      const prevToolchange = nonProbeToolchanges[i - 1]
      const nextToolchange = nonProbeToolchanges[i + 1]
      const toolchangeSignature =
        prevToolchange?.toolId + "-" + tc.toolId + "-" + nextToolchange?.toolId
      signatureCounts.set(toolchangeSignature, (signatureCounts.get(toolchangeSignature) ?? 0) + 1)
    })

    const uniqueOffsetSignatures = new Set(
      operation?.offsets?.map(
        offset =>
          offset.previousTool?.toString() +
          "-" +
          offset.currentTool.toString() +
          "-" +
          offset.nextTool?.toString() +
          "-" +
          offset.instanceIndex.toString() +
          "-" +
          offset.instanceCount.toString()
      ) ?? []
    )

    const offsetRows =
      Array.from(uniqueOffsetSignatures).map(offsetSignature => {
        const signatureIndexes = new Map<string, number>()

        const toolchange = nonProbeToolchanges.find((tc, i) => {
          const prevToolchange = nonProbeToolchanges[i - 1]
          const nextToolchange = nonProbeToolchanges[i + 1]
          const toolchangeSignature =
            prevToolchange?.toolId + "-" + tc.toolId + "-" + nextToolchange?.toolId
          const instanceIndex = signatureIndexes.get(toolchangeSignature) ?? 0
          const instanceCount = signatureCounts.get(toolchangeSignature) ?? 0
          signatureIndexes.set(toolchangeSignature, instanceIndex + 1)
          const fullSignature =
            toolchangeSignature + "-" + instanceIndex.toString() + "-" + instanceCount.toString()

          return fullSignature === offsetSignature
        })
        const data: ToolchangeData = {
          row: toolchange?.row ?? 0,
          toolId: toolchange?.toolId.toString() ?? "Orphaned",
          comment: toolchange?.comment,
          toolInfo: toolchange?.toolInfo,
          vericutDetails: toolchange?.vericutDetails,
          minFeed: toolchange?.minFeed,
          radialCutterComp: toolchange?.radialCutterComp ?? false,
          wcses: toolchange?.wcses ?? [],
        }
        return {
          data,
          highlight: true,
          hasOffsets: true,
          offsetOnly: true,
          signature: offsetSignature,
        }
      }) ?? []

    const toolchangeRows = toolchangesData.map((toolchange, i) => {
      const nextToolchange = toolchangesData[i + 1]
      const highlight = currentRows.some(
        val => val >= toolchange.row && (nextToolchange === undefined || val < nextToolchange.row)
      )

      return {
        data: toolchange,
        highlight,
        hasOffsets: toolsWithOffsets.has(toolchange.toolId),
        offsetOnly: false,
        signature: "",
      }
    })

    return offsetRows.concat(toolchangeRows)
  }, [toolchangesData, nonProbeToolchanges, currentRows, operation])

  // Make the table scrollable if there is too many tool changes
  const shouldScroll = short || toolchangeRowsData.length > 15
  const containerClasses = getTableContainerClassname({
    short,
    narrow,
    shouldScroll,
  })

  // Scroll into view when the current row changes
  const selectedRef = useRef<HTMLTableRowElement>(null)
  useEffect(() => {
    if (shouldScroll) {
      selectedRef.current?.scrollIntoView({
        block: "center",
      })
    }
  }, [shouldScroll, currentRows])

  const offsetToolChanges = nonProbeToolchanges.map(tc => {
    return tc.toolId
  })
  const offsetToolchangeIndex = Number(
    nonProbeToolchanges?.findIndex(tc => {
      return (
        tc.toolId === showEditMachineOffsetsDialogRowData?.data.toolId &&
        tc.row === showEditMachineOffsetsDialogRowData.data.row
      )
    })
  )

  let showEditMachineOffsetsToolChanges: string[] = offsetToolChanges
  if (offsetToolchangeIndex === -1) {
    showEditMachineOffsetsToolChanges = []
    const signatureParts = showEditMachineOffsetsDialogRowData?.signature?.split("-")
    if (signatureParts !== undefined) {
      const count = Number(signatureParts?.at(-1))
      if (!isNaN(count)) {
        for (let idx = 0; idx < count; idx += 1) {
          showEditMachineOffsetsToolChanges = showEditMachineOffsetsToolChanges.concat(
            signatureParts.slice(0, 3)
          )
        }
      }
    }
  }

  return (
    <div className={containerClasses}>
      <HTMLTable striped bordered condensed interactive className={styles.toolTable}>
        <ToolChangeTableHeaders
          setShowTable={setShowTable}
          showTable={showTable}
          narrow={narrow}
          includeVericutColumns={!!feedback}
        />
        {showTable && (
          <ToolChangeTableBody
            rowsData={toolchangeRowsData}
            selectedRef={selectedRef}
            onRowClick={onRowClick}
            narrow={narrow}
            showToolColor={showToolColor}
            shouldScroll={shouldScroll}
            includeVericutColumns={!!feedback}
            setShowEditMachineOffsetsDialogRowData={setShowEditMachineOffsetsDialogRowData}
          />
        )}
      </HTMLTable>
      {showEditMachineOffsetsDialogRowData !== undefined && offsetToolChanges && (
        <MachineOffsetsDialog
          isOpen={showEditMachineOffsetsDialogRowData !== undefined}
          handleRequestClose={() => setShowEditMachineOffsetsDialogRowData(undefined)}
          toolchangeIndex={offsetToolchangeIndex}
          planId={planId}
          operationIdx={operationIdx}
          toolChanges={
            offsetToolchangeIndex === -1 ? showEditMachineOffsetsToolChanges : offsetToolChanges
          }
          signatureFilter={
            offsetToolchangeIndex === -1 ? showEditMachineOffsetsDialogRowData.signature : undefined
          }
        />
      )}
    </div>
  )
}

const getTableContainerClassname = ({
  short,
  narrow,
  shouldScroll,
}: {
  short?: boolean
  narrow?: boolean
  shouldScroll?: boolean
}): string => {
  let className = styles.tableContainer
  if (narrow) {
    className = `${className} ${styles.guardrailModeContainer}`
  } else if (short) {
    className = `${className} ${styles.halfHeight}`
  } else if (shouldScroll) {
    className = `${className} ${styles.tableOverflow}`
  }

  return className
}

const ToolChangeTableHeaders: FC<{
  showTable: boolean
  setShowTable: (val: boolean) => void
  narrow?: boolean
  includeVericutColumns?: boolean
}> = ({ narrow, includeVericutColumns, showTable, setShowTable }) => {
  return (
    <thead>
      <tr>
        <th
          onClick={() => {
            setShowTable(!showTable)
          }}
          className={styles.collapseCell}
        >
          <Icon
            icon={showTable ? "double-chevron-down" : "double-chevron-up"}
            title="Hide/Show the table"
          />
        </th>
        <th>Line #</th>
        <th>Tool ID</th>
        <ToolInfoHeaderCells narrow={narrow} />
        {includeVericutColumns && <VericutHeaderCells />}
        <th title="Minimum Feedrate">Min. Feed</th>
        <th>Finetune</th>
        <th title="Active WCS Coordinate Systems">WCS</th>
        <th title="Cutter Compensation">CC</th>
      </tr>
    </thead>
  )
}

const ToolChangeTableBody: FC<{
  rowsData: ToolChangeTableRowData[]
  selectedRef: React.RefObject<HTMLTableRowElement>
  onRowClick: (row: number) => void
  narrow?: boolean
  includeVericutColumns?: boolean
  showToolColor?: boolean
  shouldScroll?: boolean
  setShowEditMachineOffsetsDialogRowData: React.Dispatch<
    React.SetStateAction<ToolChangeTableRowData | undefined>
  >
}> = ({
  rowsData,
  selectedRef,
  onRowClick,
  narrow,
  includeVericutColumns,
  showToolColor,
  shouldScroll,
  setShowEditMachineOffsetsDialogRowData,
}) => {
  const offsetRowsCount = useMemo(() => {
    return rowsData.filter(data => {
      return data.offsetOnly
    }).length
  }, [rowsData])

  return (
    <tbody>
      {rowsData.map((rowData, i) => {
        return (
          <ToolChangeTableRow
            key={i}
            index={i}
            rowData={rowData}
            onRowClick={onRowClick}
            selectedRef={selectedRef}
            showToolColor={showToolColor}
            narrow={narrow}
            includeVericutColumns={includeVericutColumns}
            shouldScroll={shouldScroll}
            setShowEditMachineOffsetsDialogRowData={setShowEditMachineOffsetsDialogRowData}
            offsetRowsCount={offsetRowsCount}
          />
        )
      })}
    </tbody>
  )
}

const ToolChangeTableRow: FC<{
  index: number
  rowData: ToolChangeTableRowData
  onRowClick: (row: number) => void
  selectedRef: React.RefObject<HTMLTableRowElement>
  showToolColor?: boolean
  narrow?: boolean
  includeVericutColumns?: boolean
  shouldScroll?: boolean
  setShowEditMachineOffsetsDialogRowData: React.Dispatch<
    React.SetStateAction<ToolChangeTableRowData | undefined>
  >
  offsetRowsCount: number
}> = ({
  index,
  rowData,
  onRowClick,
  selectedRef,
  showToolColor,
  narrow,
  includeVericutColumns,
  shouldScroll,
  setShowEditMachineOffsetsDialogRowData,
  offsetRowsCount,
}) => {
  const { highlight, data, hasOffsets } = rowData
  const { row, toolId, comment, toolInfo, vericutDetails, radialCutterComp, minFeed } = data

  const handleEditMachineOffsets = () => {
    setShowEditMachineOffsetsDialogRowData(rowData)
  }

  return (
    <tr
      onClick={() => onRowClick(rowData.data.row)}
      ref={highlight && shouldScroll ? selectedRef : undefined}
      className={highlight ? styles.highlightRow : toolInfo === undefined ? styles.noToolRow : ""}
      key={row}
    >
      <td>{!rowData.offsetOnly && index - offsetRowsCount + 1}</td>
      <td>
        {row + 1} {toolInfo === undefined && <Icon icon="warning-sign" />}
      </td>
      <ContextTarget
        menu={
          <Menu>
            <MenuItem
              disabled={
                new Set<number>(PROBE_TOOL_IDS).has(Number(toolId)) ||
                (isNaN(Number(toolId)) && rowData.hasOffsets !== true)
              }
              text={"Edit Machine Controls"}
              onClick={handleEditMachineOffsets}
            />
          </Menu>
        }
      >
        <td>
          {showToolColor && (
            <span className={styles.toolColor} style={{ backgroundColor: toolColor(+toolId) }} />
          )}
          {toolId}
        </td>
      </ContextTarget>
      <ToolInfoRowCells toolInfo={toolInfo} comment={comment} narrow={narrow} />
      {includeVericutColumns && (
        <VericutRowCells vericutDetails={vericutDetails} highlighted={highlight} />
      )}
      <MinFeedCell minFeed={minFeed} highlighted={highlight} />
      <td className={styles.rel}>
        {hasOffsets ? (
          <div className={styles.offsetIconContainer}>
            <Icon
              icon="tick-circle"
              intent={highlight || toolInfo === undefined ? undefined : "primary"}
              htmlTitle="Present"
              title="Present"
            />
            <Button onClick={handleEditMachineOffsets} small>
              <Icon icon="edit" title="Edit Finetune" htmlTitle="Edit Finetune" />
            </Button>
          </div>
        ) : (
          <div className={styles.offsetRightIconContainer}>
            <span className={styles.srOnly} aria-hidden={false}>
              Absent
            </span>
            <Button onClick={handleEditMachineOffsets} small>
              <Icon icon="small-plus" title="Add Finetune" htmlTitle="Add Finetune" />
            </Button>
          </div>
        )}
      </td>
      <td className={styles.rel}>
        {data.wcses.map((v, i) => {
          return (
            <span
              key={i}
              className={styles.wcs}
              onClick={e => {
                onRowClick(v.row)
                e.stopPropagation()
              }}
            >
              {`G${v.wcs}`}
            </span>
          )
        })}
      </td>
      <td className={styles.rel}>
        {radialCutterComp ? (
          <Icon
            icon="tick-circle"
            intent={highlight || toolInfo === undefined ? undefined : "primary"}
            htmlTitle="Present"
            title="Present"
          />
        ) : (
          <span className={styles.srOnly} aria-hidden={false}>
            Absent
          </span>
        )}
      </td>
    </tr>
  )
}

export const ToolInfoHeaderCells: FC<{ narrow?: boolean }> = ({ narrow }) => {
  return (
    <>
      <th>Name</th>
      {!narrow && (
        <>
          <th>Type</th>
          <th>Dc</th>
          <th>LEN</th>
          <th>EXP</th>
          <th>F_LEN</th>
        </>
      )}
    </>
  )
}

export const ToolInfoRowCells: FC<{
  toolInfo?: ToolInfoNodeFragment
  comment?: string | null
  narrow?: boolean
}> = ({ toolInfo, comment, narrow }) => {
  return (
    <>
      <td>
        <Tooltip2
          disabled={!narrow}
          content={
            <div>
              <div>Type: {toolInfo?.cutterType}</div>
              <div>Dc: {toolInfo?.dc}</div>
              <div>LEN: {toolInfo?.len}</div>
              <div>EXP: {toolInfo?.exp}</div>
              <div>F_LEN: {toolInfo?.fluteLength}</div>
            </div>
          }
          openOnTargetFocus={false}
        >
          <>
            {toolInfo ? toolInfo.partNumber : "No Tool Information Found"}
            {comment ? <span> - {comment}</span> : <></>}
          </>
        </Tooltip2>
      </td>
      {!narrow && (
        <>
          <td>{toolInfo?.cutterType}</td>
          <td>{toolInfo?.dc}</td>
          <td>{toolInfo?.len}</td>
          <td>{toolInfo?.exp}</td>
          <td>{toolInfo?.fluteLength}</td>
        </>
      )}
    </>
  )
}

export const VericutHeaderCells: FC = () => {
  return (
    <>
      <th title="Time">Time</th>
      <th title="Airtime Percentage">Airtime %</th>
    </>
  )
}

export const formatSecondsDuration = (nSeconds: number): string => {
  const hours = Math.floor(nSeconds / 3600)
  const minutes = Math.floor((nSeconds - hours * 3600) / 60)
  const seconds = parseFloat((nSeconds % 60).toFixed(3))

  return nSeconds > 0
    ? [hours, minutes, seconds].map(x => x.toString().padStart(2, "0")).join(":")
    : ""
}

export const VericutRowCells: FC<{
  vericutDetails: VericutToolChangeDetails | undefined
  highlighted: boolean
}> = ({ vericutDetails, highlighted }) => {
  const airTimeSeconds = vericutDetails?.airTime ?? 0
  const timeSeconds = vericutDetails?.time ?? 0

  const time = formatSecondsDuration(timeSeconds)

  return (
    <>
      <td>
        <CellText
          text={time}
          level={timeSeconds > 3600 ? "important" : undefined}
          highlighted={highlighted}
        />
      </td>
      <AirtimePercentageCell
        airtime={airTimeSeconds}
        time={timeSeconds}
        highlighted={highlighted}
      />
    </>
  )
}

export const MinFeedCell: FC<{
  minFeed: number | undefined
  highlighted: boolean
}> = ({ minFeed, highlighted }) => {
  if (minFeed === undefined) return <td />

  let level: "warning" | "danger" | undefined
  if (minFeed < 15) {
    level = "danger"
  } else if (minFeed < 110) {
    level = "warning"
  }

  const labelText = minFeed.toFixed(1)

  return (
    <td>
      <CellText text={labelText} level={level} highlighted={highlighted} />
    </td>
  )
}

export const AirtimePercentageCell: FC<{
  airtime: number | undefined
  time: number | undefined
  highlighted: boolean
}> = ({ airtime, time, highlighted }) => {
  if (!airtime || !time) return <td />

  const airtimeFraction = airtime / Math.max(time, 1)

  let level: "important" | "warning" | "danger" | undefined
  if (time > 300) {
    if (airtimeFraction > 0.8) {
      level = "danger"
    } else if (airtimeFraction > 0.5) {
      level = "warning"
    }
  }

  const labelText = (100.0 * airtimeFraction).toFixed(1) + "%"

  return (
    <td>
      <CellText text={labelText} level={level} highlighted={highlighted} />
    </td>
  )
}

export const CellText: FC<{
  text: string
  level?: "important" | "warning" | "danger"
  highlighted?: boolean
}> = ({ text, level, highlighted }) => {
  let className = ""
  if (highlighted) {
    if (level === "danger") {
      className = styles.highlightedDangerText
    } else if (level === "warning") {
      className = styles.highlightedWarningText
    } else if (level === "important") {
      className = styles.importantText
    }
  } else {
    if (level === "danger") {
      className = styles.dangerText
    } else if (level === "warning") {
      className = styles.warningText
    } else if (level === "important") {
      className = styles.importantText
    }
  }

  return <div className={className}>{text}</div>
}
