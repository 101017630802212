import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Icon } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { groupBy, startCase } from "lodash-es"

import { OffsetKind } from "src/client-axios"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

import styles from "./OpControlsIcon.module.css"

export const OpControlsIcon: FC<{
  operationIdx: number
  planId: string
}> = ({ operationIdx, planId }) => {
  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )

  const controlsByMachine = useMemo(() => {
    const offsets = operation?.offsets ?? []
    return Object.entries(
      groupBy(offsets, ({ kind, machineInstance }) =>
        kind === OffsetKind.POSITION ||
        kind === OffsetKind.TOOL ||
        kind === OffsetKind.FEEDANDSPEED ||
        kind === OffsetKind.TOOLRENUMBER ||
        kind === OffsetKind.WCS
          ? machineInstance
          : ""
      )
    )
  }, [operation?.offsets])

  const hasOffsets = controlsByMachine.length > 0

  return (
    <>
      <Tooltip2
        openOnTargetFocus={false}
        content={
          <div>
            {hasOffsets ? (
              <>
                <div>Offsets:</div>
                <div>
                  {controlsByMachine.map(([instance, controls]) => {
                    const controlsKind = Object.entries(
                      groupBy(controls, control =>
                        control.kind === OffsetKind.FEEDANDSPEED
                          ? "Feed/Speed"
                          : startCase(control.kind.toLocaleLowerCase())
                      )
                    )
                    return (
                      controlsKind.length > 0 && (
                        <div key={instance} className={styles.labelToolTipItem}>
                          {instance || "All"}:{" "}
                          {controlsKind
                            .map(([kind, controls]) => `${kind} (${controls.length})`)
                            .join(", ")}
                        </div>
                      )
                    )
                  })}
                </div>
              </>
            ) : (
              <div>No Offsets</div>
            )}
          </div>
        }
      >
        <Icon
          className={styles.icon}
          icon={"wrench"}
          style={{
            color: hasOffsets ? undefined : "#5c7080",
          }}
          intent={hasOffsets ? "warning" : "none"}
        />
      </Tooltip2>
    </>
  )
}
