import React, { FC } from "react"
import { useSelector } from "react-redux"

import { MachineBodyNode } from "src/client-axios"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { useMachineBodyNodeTransformFormula } from "src/store/zustandMachine"
import { MachineBody } from "./MachineBody"

export const MachineNode: FC<{
  node: MachineBodyNode
  attachments: Record<string, JSX.Element | undefined>
}> = ({ node, attachments }) => {
  const displayMode = useSelector(viewOptionsSelectors.machineDisplayMode)
  const bodiesVisible = node.visibility?.includes(displayMode)
  const groupRef = useMachineBodyNodeTransformFormula(node.transformFormula)
  const attachment = attachments[node.name]
  // Note: this is important for functionality like converting mouse picking
  // to proper RTCP coordinates since the picking routine needs to understand
  // where in the kinematic tree the picking point is located
  groupRef.current.userData.machineNodeName = node.name

  return (
    <group ref={groupRef}>
      {node.keys &&
        node.keys.map((key, i) => {
          return (
            <MachineBody
              nodeName={node.name}
              machineBody={key}
              key={`${key}--${i}`}
              visible={bodiesVisible}
            />
          )
        })}

      {node.children?.map(childNode => (
        <MachineNode node={childNode} key={childNode.name} attachments={attachments} />
      ))}
      {attachment}
    </group>
  )
}
