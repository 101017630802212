import React, { FC } from "react"

import { SceneModelFragment } from "src/graphql/generated"
import { SceneClickData, useSceneClickEventHandler } from "src/hooks/useSceneClickEventHandler"
import { CanvasContextMenuListener } from "../../../ClickHandlers/CanvasContextMenu/CanvasContextMenuListener"
import { AnimatedCamSceneLoader } from "./AnimatedCamSceneLoader"

interface CardSceneProps {
  contextMenuId?: string
  handleSceneClickDataUpdateRef?: React.MutableRefObject<
    ((data: SceneClickData | undefined) => void) | undefined
  >
  partModel?: SceneModelFragment
  planId?: string
  operationIdx?: number
  handleClick3dView?: (activeConfigPanelKind?: string) => void
  locked?: boolean
}

export const OperationCardScene: FC<CardSceneProps> = ({
  contextMenuId,
  handleSceneClickDataUpdateRef,
  partModel,
  planId,
  operationIdx,
  handleClick3dView,
}) => {
  const onClick = () => {
    handleClick3dView && handleClick3dView()
  }

  useSceneClickEventHandler(onClick)

  return (
    <>
      <AnimatedCamSceneLoader
        partModel={partModel}
        planId={planId}
        operationIdx={operationIdx}
        minimal
        noAnimate
        noMachine
        controls={{ zoomOnMouse: false }}
      />
      {contextMenuId && handleSceneClickDataUpdateRef && (
        <CanvasContextMenuListener
          contextMenuId={contextMenuId}
          handleSceneClickDataUpdateRef={handleSceneClickDataUpdateRef}
        />
      )}
    </>
  )
}
