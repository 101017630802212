import React, { FC, useEffect, useState } from "react"

import { SceneClickData, useSceneClickEventHandler } from "src/hooks/useSceneClickEventHandler"

export const CanvasContextMenuListener: FC<{
  contextMenuId: string
  handleSceneClickDataUpdateRef: React.MutableRefObject<
    ((data: SceneClickData | undefined) => void) | undefined
  >
}> = ({ contextMenuId, handleSceneClickDataUpdateRef }) => {
  const [sceneClickData, setSceneClickData] = useState<SceneClickData>()

  useEffect(() => {
    const current = handleSceneClickDataUpdateRef.current
    current?.(sceneClickData)
    return () => {
      current?.(sceneClickData)
    }
  }, [sceneClickData, handleSceneClickDataUpdateRef])

  const onRightClick = (event: CustomEvent<SceneClickData>) => {
    event.preventDefault()
    const contextMenu = document.getElementById(contextMenuId)

    if (!contextMenu) {
      console.error("No context menu present with id", contextMenuId)
      return
    }

    const { clientX, clientY } = event.detail.mouseEvent
    const contextMenuEvent = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: false,
      view: window,
      button: 2,
      buttons: 0,
      clientX,
      clientY,
    })
    contextMenu.dispatchEvent(contextMenuEvent)

    setSceneClickData(event.detail)
  }

  useSceneClickEventHandler(undefined, undefined, onRightClick)

  return null
}
