import React, { FC, useMemo } from "react"
import { batch, useSelector } from "react-redux"
import { Button, Callout, Checkbox, Classes, FormGroup, HTMLSelect } from "@blueprintjs/core"

import { activeActions } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode } from "src/store/ui/viewerModal"
import { ManufacturingIssue } from "../../ManufacturingIssue/ManufacturingIssue"
import {
  getAreReleaseIssuesPresent,
  getPreReleaseCheckResults,
  PreReleaseCheckResults,
  ProbingValidation,
} from "../ManufacturingCheckResults"
import { ManufacturingChecksStatus, ManufacturingConfirm } from "../ManufacturingConfirm"

enum ManufacturingStatus {
  Proveout = "Proveout",
  Production = "Production",
}

export const ReleaseConfirm: FC<{
  vericutValidated: boolean
  probingValidation: ProbingValidation
  planId: string
  operationIdx: number
  releaseOptions: {
    manufacturingStatus: {
      isProveout: boolean
      changedFromCurrent: boolean
    }
    currentVersion?: string
    nextVersion: string
    machinePreCheck: boolean
    machinePostCheck: boolean
    squarenessPreCheck: boolean
    squarenessPostCheck: boolean
    clampAxesAlignments?: boolean
    includeCutting: boolean
    includeInspections: boolean
    includeMachineOffsets: boolean
    includeMachineOffsetsLogging: boolean
    enableCycleDprint: boolean
    alignments: {
      allowSelection: boolean
      include: boolean
    }
  }
  noPendingChanges: boolean
  handleRequestClose: () => void
  handleSetReleaseAsProveout: (isProveout: boolean) => void
  handleSetMachinePreCheck: (preCheck: boolean) => void
  handleSetMachinePostCheck: (postCheck: boolean) => void
  handleSetIncludeAlignments: (includeAlignments: boolean) => void
  handleSetSquarenessPreCheck: (preCheck: boolean) => void
  handleSetSquarenessPostCheck: (postCheck: boolean) => void
  handleSetClampAxesAlignments: (clampAxes: boolean) => void
  handleSetIncludeCutting: (includeCutting: boolean) => void
  handleSetIncludeInspections: (includeInspections: boolean) => void
  handleSetIncludeMachineOffsets: (includeMachineOffsets: boolean) => void
  handleSetIncludeMachineOffsetsLogging: (includeMachineOffsetsLogging: boolean) => void
  handleEnableCycleDprint: (enableCycleDprint: boolean) => void
  handleReleaseClick: () => void
}> = ({
  vericutValidated,
  probingValidation,
  planId,
  operationIdx,
  releaseOptions,
  noPendingChanges,
  handleRequestClose,
  handleSetReleaseAsProveout,
  handleSetMachinePreCheck,
  handleSetMachinePostCheck,
  handleSetIncludeAlignments,
  handleSetSquarenessPreCheck,
  handleSetSquarenessPostCheck,
  handleSetClampAxesAlignments,
  handleSetIncludeCutting,
  handleSetIncludeInspections,
  handleSetIncludeMachineOffsets,
  handleSetIncludeMachineOffsetsLogging,
  handleEnableCycleDprint,
  handleReleaseClick,
}) => {
  const dispatch = useAppDispatch()

  const planOperations = useSelector(
    (state: RootState) => storedPlansSelectors.selectPlan(state, planId)?.operations ?? []
  )
  const probing = useSelector((state: RootState) =>
    storedOperationSelectors.selectProbing(state, planId, operationIdx)
  )

  const { hasPreviousInspection, hasFollowingAlignment } = useMemo(() => {
    let hasPreviousInspection = false
    let hasFollowingAlignment = false
    planOperations.forEach((planOp, planOpIdx) => {
      if (planOpIdx <= operationIdx && planOp.inputStock !== undefined) {
        // Reset the tracking of whether a previous inspection exists every time the input stock changes
        hasPreviousInspection = false
      }

      if (planOpIdx < operationIdx) {
        // Update the hasPreviousInspection to account for this operation
        const planOpHasInspection = planOp.probing?.strategy.inspections.some(x => !x.disabled)
        hasPreviousInspection = planOpHasInspection || hasPreviousInspection
      } else if (planOpIdx === operationIdx + 1) {
        // hasFollowingAlignment stores whether the immediately-following operation has an alignment
        hasFollowingAlignment = planOp.probing?.strategy.alignments.some(x => !x.disabled) ?? false
      }
      // Don't do anything for planOperations with index > operationIdx + 1
    })

    return { hasPreviousInspection, hasFollowingAlignment }
  }, [operationIdx, planOperations])

  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )

  const { checkResults, areIssuesPresent } = useMemo(() => {
    const checkResults = getPreReleaseCheckResults(
      toolpathProject,
      probing,
      hasPreviousInspection,
      hasFollowingAlignment || hasPreviousInspection
    )
    const areIssuesPresent = getAreReleaseIssuesPresent(
      checkResults,
      vericutValidated,
      probingValidation
    )
    return { checkResults, areIssuesPresent }
  }, [
    toolpathProject,
    probing,
    hasPreviousInspection,
    hasFollowingAlignment,
    vericutValidated,
    probingValidation,
  ])

  const handleDisplayIssues = () => {
    handleRequestClose()
    batch(() => {
      dispatch(viewerModalActions.setViewerModalIsOpen(true))
      dispatch(viewerModalActions.setViewerModalMode(ViewerModalMode.Setup))
      dispatch(activeActions.setActivePlanId(String(planId)))
      dispatch(activeActions.setActiveOperationIdx(Number(operationIdx)))
      dispatch(activeActions.setActiveConfigPanel(undefined))
      dispatch(activeActions.setActiveConfigTab("production"))
    })
  }

  const {
    isProveout,
    changedFromCurrent: manufacturingStatusChanged,
  } = releaseOptions.manufacturingStatus

  const {
    machinePreCheck,
    machinePostCheck,
    includeCutting,
    includeInspections,
    includeMachineOffsets,
    includeMachineOffsetsLogging,
    enableCycleDprint,
    squarenessPreCheck,
    squarenessPostCheck,
    clampAxesAlignments,
  } = releaseOptions
  const {
    include: includeAlignments,
    allowSelection: alignmentsAllowSelection,
  } = releaseOptions.alignments
  const { currentVersion, nextVersion } = releaseOptions

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {currentVersion && <p>Current Version: {currentVersion}</p>}
        {
          <FormGroup label={"Manufacturing Options:"}>
            <HTMLSelect
              value={isProveout ? ManufacturingStatus.Proveout : ManufacturingStatus.Production}
              onChange={e =>
                handleSetReleaseAsProveout(e.target.value === ManufacturingStatus.Proveout)
              }
            >
              <option value={ManufacturingStatus.Proveout} key={ManufacturingStatus.Proveout}>
                {ManufacturingStatus.Proveout}
              </option>
              <option value={ManufacturingStatus.Production} key={ManufacturingStatus.Production}>
                {ManufacturingStatus.Production}
              </option>
            </HTMLSelect>
            <Checkbox
              label="Machine pre-check"
              checked={machinePreCheck}
              onChange={() => handleSetMachinePreCheck(!machinePreCheck)}
            />
            <Checkbox
              label="Alignments"
              checked={includeAlignments}
              disabled={!alignmentsAllowSelection}
              onChange={() => handleSetIncludeAlignments(!includeAlignments)}
            >
              {clampAxesAlignments !== undefined && (
                <Checkbox
                  label="Clamp rotary axes"
                  checked={includeAlignments && clampAxesAlignments}
                  disabled={!alignmentsAllowSelection || !includeAlignments}
                  onChange={() => handleSetClampAxesAlignments(!clampAxesAlignments)}
                />
              )}
              <Checkbox
                label="Squareness pre-check"
                checked={includeAlignments && squarenessPreCheck}
                disabled={!alignmentsAllowSelection || !includeAlignments}
                onChange={() => handleSetSquarenessPreCheck(!squarenessPreCheck)}
              />
              <Checkbox
                label="Squareness post-check"
                checked={includeAlignments && squarenessPostCheck}
                disabled={!alignmentsAllowSelection || !includeAlignments}
                onChange={() => handleSetSquarenessPostCheck(!squarenessPostCheck)}
              />
            </Checkbox>
            <Checkbox
              label="Machining"
              checked={includeCutting}
              onChange={() => handleSetIncludeCutting(!includeCutting)}
            />
            <Checkbox
              label="Inspections"
              checked={includeInspections}
              onChange={() => handleSetIncludeInspections(!includeInspections)}
            />
            <Checkbox
              label="Machine Controls"
              checked={includeMachineOffsets}
              onChange={() => handleSetIncludeMachineOffsets(!includeMachineOffsets)}
            />
            <Checkbox
              label="Machine Controls Logging"
              checked={includeMachineOffsetsLogging}
              onChange={() => handleSetIncludeMachineOffsetsLogging(!includeMachineOffsetsLogging)}
            />
            <Checkbox
              label="Machine post-check"
              checked={machinePostCheck}
              onChange={() => handleSetMachinePostCheck(!machinePostCheck)}
            />
            <Checkbox
              label="Enable Cycle Feature Dprint"
              checked={enableCycleDprint}
              onChange={() => handleEnableCycleDprint(!enableCycleDprint)}
            />
          </FormGroup>
        }
        <PreReleaseFeedback
          areIssuesPresent={areIssuesPresent}
          vericutValidated={vericutValidated}
          probingValidation={probingValidation}
          checkResults={checkResults}
          noPendingChanges={noPendingChanges}
          manufacturingStatusChanged={manufacturingStatusChanged}
          handleDisplayIssues={handleDisplayIssues}
        />
      </div>
      <ManufacturingConfirm
        actionText="Release"
        nextVersion={nextVersion}
        showWarning={areIssuesPresent || (noPendingChanges && !manufacturingStatusChanged)}
        disabled={noPendingChanges && !manufacturingStatusChanged}
        handleRequestClose={handleRequestClose}
        handleConfirmClick={handleReleaseClick}
      />
    </>
  )
}

const PreReleaseFeedback: FC<{
  areIssuesPresent: boolean
  vericutValidated: boolean
  probingValidation: ProbingValidation
  checkResults: PreReleaseCheckResults
  noPendingChanges: boolean
  manufacturingStatusChanged: boolean
  handleDisplayIssues: () => void
}> = ({
  areIssuesPresent,
  vericutValidated,
  probingValidation,
  checkResults,
  noPendingChanges,
  manufacturingStatusChanged,
  handleDisplayIssues,
}) => {
  const feedback = (areIssuesPresent && (
    <Callout intent={"danger"} icon={null} title={"Possible problems detected"}>
      <ul>
        <ManufacturingIssue isPresent={noPendingChanges && !manufacturingStatusChanged}>
          No changes have been made to the operation since last release
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.isCuttingUndefined}>
          No cutting program has been submitted for this operation
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={!vericutValidated}>
          Cutting program is not passing simulation
        </ManufacturingIssue>
        {/* Not sure if the following one should be included with the non-manufacturing issues */}
        <ManufacturingIssue isPresent={checkResults.isG54Missing}>
          There is no G54 probing specified
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={!probingValidation.simulated}>
          The probing program has not been simulated.
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={!probingValidation.issuesResolved}>
          The probing program has unresolved simulation issues. These issues must be addressed or
          acknowledged in the probing UI before creating a new release.
          <Button
            minimal
            intent={"primary"}
            text={"Open Probing"}
            onClick={handleDisplayIssues}
            rightIcon={"document-open"}
            style={{ textDecoration: "underline", display: "list-item" }}
          />
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.isProbingUndefined}>
          No probing has been defined for this operation
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.hasMultipleG54s}>
          There are multiple G54 probing programs enabled
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.isG54Unconstrained}>
          One of the G54 probing programs does not constrain all three of the X, Y, and Z axes
        </ManufacturingIssue>
        <ManufacturingIssue
          isPresent={checkResults.isExpectingMissingAlignment}
          kind={"warning"}
          tooltip={
            <>
              This warning was generated because a previous operation with the same stock had an
              inspection program.
              <br />
              <br />
              Once a feature has been inspected, we usually want to ensure that subsequent
              operations are aligned as accurately as possible.
            </>
          }
        >
          An alignment was expected but not present
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.hasMultipleActiveAlignments} kind={"warning"}>
          Multiple alignment programs are active
        </ManufacturingIssue>
        <ManufacturingIssue
          isPresent={checkResults.isExpectingMissingInspection}
          kind={"warning"}
          tooltip={
            <>
              This warning was generated because the following operation has an alignment program.
              <br />
              <br />
              Because we generally align relative to features milled in the previous operation, is a
              good idea to make sure that any surfaces that will be used for alignment in a
              subsequent operation are inspected in the same operation in which they are milled.
            </>
          }
        >
          An inspection program was expected but not present
        </ManufacturingIssue>
        <ManufacturingIssue isPresent={checkResults.hasMultipleActiveInspections} kind={"warning"}>
          Multiple inspection programs are active
        </ManufacturingIssue>
        <ManufacturingIssue
          isPresent={checkResults.isInspectionMissingCutterComps}
          kind={"warning"}
        >
          Cutter compensations have not been specified for all inspection steps
        </ManufacturingIssue>
      </ul>
    </Callout>
  )) ||
    (noPendingChanges &&
      (manufacturingStatusChanged ? (
        <Callout intent={"primary"} icon={null} title={"Manufacturing status changed"}>
          <ul>
            <ManufacturingIssue isPresent={true} kind="warning">
              Releasing to different domain without any changes to operation
            </ManufacturingIssue>
          </ul>
        </Callout>
      ) : (
        <Callout intent={"danger"} icon={null} title={"Possible problems detected"}>
          <ul>
            <ManufacturingIssue isPresent={true}>
              No changes have been made to the operation since last release
            </ManufacturingIssue>
          </ul>
        </Callout>
      ))) || <ManufacturingChecksStatus actionText="Release" />

  return feedback
}
