import React from "react"
import { Icon, Spinner } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { GuardrailFeedback, GuardrailFeedbackLevel } from "src/client-axios"

export const guardrailInfoIcon: (feedbacks: GuardrailFeedback[]) => JSX.Element | null = (
  feedbacks: GuardrailFeedback[]
) => {
  if (
    feedbacks.some(feedback => {
      return feedback.level === GuardrailFeedbackLevel.Failure
    })
  ) {
    return (
      <Tooltip2 position="left" content={"Failure"} openOnTargetFocus={false}>
        <Icon icon="error" intent={"danger"} iconSize={16} />
      </Tooltip2>
    )
  } else if (
    feedbacks.some(feedback => {
      return feedback.level === GuardrailFeedbackLevel.Error
    })
  ) {
    return (
      <Tooltip2 position="left" content={"Error"} openOnTargetFocus={false}>
        <Icon icon="error" intent={"danger"} iconSize={16} />
      </Tooltip2>
    )
  } else if (
    feedbacks.some(feedback => {
      return feedback.level === GuardrailFeedbackLevel.Warning
    })
  ) {
    return (
      <Tooltip2 position="left" content={"Warning"} openOnTargetFocus={false}>
        <Icon icon="warning-sign" intent={"warning"} iconSize={16} />
      </Tooltip2>
    )
  } else if (
    feedbacks.some(feedback => {
      return feedback.level === GuardrailFeedbackLevel.Success
    })
  ) {
    return (
      <Tooltip2 position="left" content={"Success"} openOnTargetFocus={false}>
        <Icon icon="tick-circle" intent={"success"} iconSize={16} />
      </Tooltip2>
    )
  }

  return (
    <Tooltip2 position="left" content={"Guardrails loading"} openOnTargetFocus={false}>
      <Spinner size={16} />
    </Tooltip2>
  )
}
