import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import IntervalTree from "@flatten-js/interval-tree"

import { ExplicitMoveKindEnum, Operation } from "src/client-axios"
import { NcEventKind, NcEventsByUriQuery, useNcEventsByUriLazyQuery } from "src/graphql/generated"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { PlanOperation } from "./CamScene/sharedTypes"
import {
  InspectionCompensation,
  NcEventIntervals,
  OperationNcEvent,
  toolCompensationOffsetId,
} from "./stockSimDefs"

function buildProbeInspectionCompensations(operation: Operation): Array<InspectionCompensation> {
  const inspectionCompensations: Array<InspectionCompensation> = []
  operation?.probing?.strategy.inspections.forEach(inspection => {
    inspection.steps.forEach(step => {
      if (step.kind !== ExplicitMoveKindEnum.ExplicitMove) {
        step.pointCompensations?.compensations.forEach(compensation => {
          inspectionCompensations.push({
            probingStep: step,
            compensation,
          })
        })
      }
    })
  })
  return inspectionCompensations
}

const MAX_GCODE_LINE = 10000000

function sequentialIntervals(events: OperationNcEvent[]): IntervalTree {
  const intervals = new IntervalTree()
  events.forEach((nextEvent, index) => {
    if (index > 0) {
      const event = events[index - 1]
      // TODO: should these be zero or one indexed?  Currently zero indexed
      // TODO: should it be inclusive or exclusive at the end?
      const range: [number, number] = [event.row, nextEvent.row]
      intervals.insert(range, { index, range, event })

      if (index === events.length - 1) {
        // Event continues until end of program, but need a finite value
        const range: [number, number] = [event.row, MAX_GCODE_LINE]
        intervals.insert(range, { index, range, event })
      }
    }
  })
  return intervals
}

function delimitedIntervals(
  events: OperationNcEvent[],
  beginEventKind: NcEventKind,
  endEventKind: NcEventKind
): IntervalTree {
  let i = 0
  const ranges: Array<[number, number]> = []
  while (i < events.length) {
    if (events[i].kind === beginEventKind) {
      const startEvent = events[i]
      i += 1

      let endedInterval = false
      while (i < events.length) {
        if (events[i].kind === endEventKind) {
          const endEvent = events[i]
          ranges.push([startEvent.row, endEvent.row])
          endedInterval = true
          break
        }
        i += 1
      }

      if (!endedInterval) {
        ranges.push([startEvent.row, MAX_GCODE_LINE])
      }
    }
    i += 1
  }

  const intervals = new IntervalTree()
  ranges.forEach((range, index) => {
    intervals.insert(range, { index, range })
  })
  return intervals
}

function getNcEventIntervals(ncEventsQuery: NcEventsByUriQuery | undefined): NcEventIntervals {
  const ncEvents = ncEventsQuery?.getNcEventsByUri ? ncEventsQuery?.getNcEventsByUri : []

  const eventIntervals: Partial<Record<NcEventKind, IntervalTree>> = {}
  ;[NcEventKind.Tool, NcEventKind.Speed, NcEventKind.Feed].forEach(eventKind => {
    const events = ncEvents.filter(event => event.kind === eventKind) ?? []
    eventIntervals[eventKind] = sequentialIntervals(events)
  })

  const toolChanges = ncEvents.filter(event => event.kind === NcEventKind.Tool) ?? []

  const cutterCompEvents = ncEvents.filter(
    event => event.kind === NcEventKind.Comp || event.kind === NcEventKind.CompCancel
  )

  eventIntervals[NcEventKind.Comp] = delimitedIntervals(
    cutterCompEvents,
    NcEventKind.Comp,
    NcEventKind.CompCancel
  )

  const compensationIds: Record<string, boolean> = {}
  eventIntervals[NcEventKind.Comp]?.values.forEach(value => {
    const vals = eventIntervals[NcEventKind.Tool]?.search([value.range[0], value.range[0] + 1])
    vals?.forEach(val => {
      const key = toolCompensationOffsetId(val.event.val)
      compensationIds[key] = false
    })
  })

  return new NcEventIntervals(ncEvents, toolChanges, eventIntervals, compensationIds)
}

export function useNcEventIntervals(planOperation: PlanOperation): NcEventIntervals {
  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(
      planOperation.planId,
      planOperation.operationIdx
    )
  )

  const [getNCEventsByUri, { data: ncEvents }] = useNcEventsByUriLazyQuery()
  const ncEventIntervals = useMemo(() => {
    return getNcEventIntervals(ncEvents)
  }, [ncEvents])

  useEffect(() => {
    if (planOperation && toolpathProject?.ncFileLocator) {
      getNCEventsByUri({
        variables: { uri: toolpathProject.ncFileLocator, rsBucket: true, feedsAndSpeeds: false },
      })
    }
  }, [planOperation, toolpathProject, getNCEventsByUri])

  // Load probing inspection routines
  const inspectionCompensations = useMemo(() => {
    return buildProbeInspectionCompensations(planOperation.operation)
  }, [planOperation.operation])

  // Associate probing data with NC data
  useEffect(() => {
    if (ncEventIntervals) {
      ncEventIntervals.markCompensationsAsProbed(inspectionCompensations)
    }
  }, [ncEventIntervals, inspectionCompensations])

  return ncEventIntervals
}
