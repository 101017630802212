import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Icon, Intent } from "@blueprintjs/core"

import { useApi } from "src/hooks/useApi"
import { getIntentStyle } from "src/pages/ViewerPage/MachineCoordsPanel/MachineCoordsPanel"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { RootState, useAppDispatch } from "src/store/rootStore"

import styles from "./MachineChangeBanner.module.css"

export const MachineChangeBanner: FC = () => {
  const { planchangerApi } = useApi()

  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const dispatch = useAppDispatch()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )

  const [showMachineChangeBanner, setShowMachineChangeBanner] = useState<boolean>(true)

  const bannerIntent = Intent.WARNING

  let headerClassName = `${styles.headerContainer}`
  headerClassName = `${headerClassName} ${getIntentStyle(bannerIntent, styles)}`

  let iconClassName = `${styles.closeButton}`
  iconClassName = `${iconClassName} ${getIntentStyle(bannerIntent, styles)}`

  const handleAcknowledge = () => {
    dispatch(
      storedPlanThunks.setMachineKindUnacknowledged({
        planId,
        operationIdx,
        planchangerApi,
        machineKindUnacknowledged: false,
      })
    )
  }

  return (
    <>
      {operation?.machineKindUnacknowledged && showMachineChangeBanner && (
        <div className={headerClassName}>
          <div className={styles.machineChanged}>
            This operation&apos;s machine kind has changed.
          </div>
          <Button onClick={handleAcknowledge} intent="primary">
            Acknowledge
          </Button>
          <Icon
            icon="cross"
            className={iconClassName}
            onClick={() => setShowMachineChangeBanner(false)}
          />
        </div>
      )}
    </>
  )
}
