import * as THREE from "three"

export function initializeCamera(
  camera: THREE.OrthographicCamera,
  offsetDistance: number,
  zoom: number
): void {
  configureClippingPlanes(camera)
  camera.zoom = zoom
  camera.updateProjectionMatrix()
}

/**
 * Sets the orthographic camera frustrum to have the specified width
 * If not specified, uses the canvas width (helpful for HUDs)
 */
export function configureOrthographicFrustrum(
  gl: THREE.Renderer,
  camera: THREE.OrthographicCamera,
  frustrumWidth?: number,
  canvasProp?: HTMLCanvasElement
): { newCanvasWidth: number; newFrustrumWidth: number } | undefined {
  const canvas = canvasProp || gl.domElement
  // In some cases, this canvas element dismounts before animations can be stopped
  // To prevent this from causing issues, we explicitly check it is not undefined before proceeding
  if (!canvas) return
  const width = canvas.clientWidth
  const height = canvas.clientHeight

  if (canvas.width !== width || canvas.height !== height) {
    // you must pass false for the updateStyle argument or three.js fights the browser
    gl.setSize(width, height, false)
  }

  // TODO: May need to account for change from last configured canvas size
  const right = (frustrumWidth ?? width) / 2
  const top = (right * height) / width

  camera.right = right
  camera.left = -right
  camera.top = top
  camera.bottom = -top
  camera.updateProjectionMatrix()

  return { newCanvasWidth: canvas.width, newFrustrumWidth: right * 2 }
}

/**
 * Right now, we just set the clipping planes very far to ensure that everything gets rendered.
 * For debugging/development purposes, it may be helpful to set the "near" clipping plane to 0.
 */
function configureClippingPlanes(
  camera: THREE.OrthographicCamera,
  near = -100_000,
  far = 100_000
): void {
  // TODO: May want to be more principled about how we set the clipping planes
  camera.near = near
  camera.far = far
}
