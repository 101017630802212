import React, { FC } from "react"
import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, MultiSelect } from "@blueprintjs/select"

import { useAllUsersQuery, UserFragment } from "src/graphql/generated"
import { highlightText } from "src/util/text"

const AssigneeMultiSelect = MultiSelect.ofType<UserFragment>()

const AssigneeMultiSelectMenuItem: ItemRenderer<UserFragment> = (
  assignee,
  { handleClick, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={assignee.id}
      onClick={handleClick}
      text={highlightText(assignee.email, query)}
    />
  )
}

const filterAssignee: ItemPredicate<UserFragment> = (query, assignee) => {
  return assignee.email.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

interface AssigneeMultiSelectorProps {
  camProgrammers?: string[] | undefined
  setSelection: (user: UserFragment) => void
  handleDelete: (userId: string) => void
  className?: string
  viewAllPermissionDisabled?: boolean
  currentUserEmail: string | undefined
}

export const AssigneeMultiSelector: FC<AssigneeMultiSelectorProps> = ({
  camProgrammers,
  setSelection,
  handleDelete,
  className,
  viewAllPermissionDisabled,
  currentUserEmail,
}) => {
  const { data: allUsers } = useAllUsersQuery()
  const users: UserFragment[] | undefined = allUsers?.users

  const renderTag = (user: UserFragment) => user.email

  if (!users) return null

  const handleTagRemove = (value: React.ReactNode) => {
    const userToRemove = users.find(user => {
      return (
        user.email === value?.toString() &&
        (!viewAllPermissionDisabled ||
          (viewAllPermissionDisabled && currentUserEmail !== value?.toString()))
      )
    })
    userToRemove && handleDelete(userToRemove.id)
  }

  return (
    <AssigneeMultiSelect
      items={users.filter(user => {
        return !camProgrammers?.includes(user.email)
      })}
      itemPredicate={filterAssignee}
      itemRenderer={AssigneeMultiSelectMenuItem}
      onItemSelect={item => {
        if (!camProgrammers?.includes(item.email)) {
          setSelection(item)
        }
      }}
      tagRenderer={renderTag}
      tagInputProps={{
        onRemove: handleTagRemove,
      }}
      selectedItems={users.filter(user => {
        return camProgrammers?.includes(user.email)
      })}
      className={className || ""}
      noResults={<MenuItem disabled={true} text="No results." />}
      popoverProps={{ minimal: true, captureDismiss: true }}
    />
  )
}
