import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { batch, useSelector } from "react-redux"
import {
  AnchorButton,
  Button,
  Checkbox,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  H1,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Spinner,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { FixtureStockDataKindEnum, MaterialRecord } from "src/client-axios"
import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { fixturesSelectors, fixturesThunks } from "src/store/config/fixtures"
import { stocksSelectors, stocksThunks } from "src/store/config/stocks"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { VERICUT_MATERIAL_IDS } from "./materialIds"
import { VericutMaterialSelector } from "./VericutMaterialSelector"

import styles from "./StockConfigEditor.module.css"

enum EditMode {
  Create,
  Update,
  Delete,
}

enum EditTarget {
  RectangularStock,
  RoundStock,
  FixtureStock,
  Material,
}

type StocksConfigEditing = (
  | {
      id: undefined
      mode: EditMode.Create
    }
  | {
      id: string
      mode: EditMode.Update
    }
  | {
      id: string
      mode: EditMode.Delete
    }
) & { target: EditTarget }

type SetStocksConfigEditing = (editing: StocksConfigEditing | undefined) => void

export const StockConfigEditor: FC = () => {
  const dispatch = useAppDispatch()
  const toaster = useToaster()
  const { configApi } = useApi()

  const [editing, setEditing] = useState<StocksConfigEditing | undefined>(undefined)
  const [selectedTabId, setSelectedTabId] = useState<TabId>("rectangularStocksTab")

  const fetchStocksAndFixtures = useCallback(() => {
    batch(() => {
      dispatch(stocksThunks.fetchStocksConfig({ configApi }))
      dispatch(fixturesThunks.fetchFixturesConfig({ configApi }))
    })
  }, [dispatch, configApi])

  useWebsocketMessageListener(wsMessage => {
    if (wsMessage.type === "ConfigReload") {
      fetchStocksAndFixtures()
    }
  })

  useEffect(() => {
    fetchStocksAndFixtures()
  }, [fetchStocksAndFixtures, configApi, dispatch, toaster])

  return (
    <div className={styles.stocksConfigContainer}>
      <div className={styles.header}>
        <H1>Stocks Config</H1>
        <div>
          <Tooltip2 content={"Clear server cache"} openOnTargetFocus={false}>
            <AnchorButton
              minimal
              icon={"refresh"}
              onClick={() => {
                configApi
                  .clearCache()
                  .then(() => {
                    toaster.show({ message: "Cache cleared", intent: "success" })
                    dispatch(stocksThunks.fetchStocksConfig({ configApi }))
                  })
                  .catch(err => {
                    console.error(err)
                    toaster.show({ message: "Failed to clear cache" })
                  })
              }}
            />
          </Tooltip2>
        </div>
      </div>
      <Tabs
        renderActiveTabPanelOnly
        id="stockConfigTabs"
        onChange={(tabId: TabId) => {
          setSelectedTabId(tabId)
        }}
        selectedTabId={selectedTabId}
      >
        <Tab
          id="fixtureStocksTab"
          title="Custom Fixture Blanks"
          panel={<FixtureStocksPanel setEditing={v => setEditing(v)} />}
        />
        <Divider className={styles.tabsDivider} />
        <Tab
          id="materialsTab"
          title="Materials"
          panel={<MaterialsPanel setEditing={v => setEditing(v)} />}
        />
      </Tabs>
      <EditModal editing={editing} setEditing={v => setEditing(v)} />
    </div>
  )
}

const FixtureStocksPanel: FC<{ setEditing: SetStocksConfigEditing }> = ({ setEditing }) => {
  const fixtureStocks = useSelector(stocksSelectors.selectFixtureStocks)
  return (
    <>
      <HTMLTable striped bordered condensed interactive className={styles.stocksTable}>
        <thead>
          <tr>
            <th>Stock ID</th>
            <th>Material ID</th>
            <th>Fixture ID</th>
            <th>Is Unavailable</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fixtureStocks.map(stock => (
            <tr key={stock.stockId}>
              <td>{stock.stockId}</td>
              <td>{stock.materialId}</td>
              <td>{stock.data.fixtureId}</td>
              <td>{stock.isUnavailable ? "Yes" : ""}</td>
              <td>
                <Tooltip2 content={"Edit"} placement={"top"} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"edit"}
                    onClick={() =>
                      setEditing({
                        mode: EditMode.Update,
                        target: EditTarget.FixtureStock,
                        id: stock.stockId,
                      })
                    }
                  />
                </Tooltip2>
                <Tooltip2 content={"Delete"} placement={"top"} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"delete"}
                    onClick={() =>
                      setEditing({
                        mode: EditMode.Delete,
                        target: EditTarget.FixtureStock,
                        id: stock.stockId,
                      })
                    }
                  />
                </Tooltip2>
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <Button
        intent={"success"}
        icon={"add"}
        onClick={() =>
          setEditing({ id: undefined, mode: EditMode.Create, target: EditTarget.FixtureStock })
        }
      >
        Add New Custom Fixtures Blank
      </Button>
    </>
  )
}

const MaterialsPanel: FC<{ setEditing: SetStocksConfigEditing }> = ({ setEditing }) => {
  const materials = useSelector(stocksSelectors.selectMaterials)
  return (
    <>
      <HTMLTable striped bordered condensed interactive className={styles.stocksTable}>
        <thead>
          <tr>
            <th>Material</th>
            <th>Vericut Material ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {materials.map(material => (
            <tr key={material.materialId}>
              <td>{material.materialId}</td>
              <td>{material.vericutId}</td>
              <td>
                <Tooltip2 content={"Edit"} placement={"top"} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"edit"}
                    onClick={() =>
                      setEditing({
                        mode: EditMode.Update,
                        target: EditTarget.Material,
                        id: material.materialId,
                      })
                    }
                  />
                </Tooltip2>
                <Tooltip2 content={"Delete"} placement={"top"} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"delete"}
                    onClick={() =>
                      setEditing({
                        mode: EditMode.Delete,
                        target: EditTarget.Material,
                        id: material.materialId,
                      })
                    }
                  />
                </Tooltip2>
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <Button
        intent={"success"}
        icon={"add"}
        onClick={() =>
          setEditing({ id: undefined, mode: EditMode.Create, target: EditTarget.Material })
        }
      >
        Add New Material
      </Button>
    </>
  )
}

type RequestHandlers = {
  onRequest: () => void
  onSuccess: () => void
  onError: (err: unknown) => void
}

const EditModal: FC<{
  editing: StocksConfigEditing | undefined
  setEditing: SetStocksConfigEditing
}> = ({ editing, setEditing }) => {
  const dispatch = useAppDispatch()
  const toaster = useToaster()
  const { configApi } = useApi()

  const onSubmitRef = useRef<() => void>()

  const [loading, setLoading] = useState(false)

  const triggerClose = () => {
    setEditing(undefined)
  }

  const onRequest = () => {
    setLoading(true)
  }

  const onSuccess = () => {
    toaster.show({ message: `Update succeeded`, intent: "success" })
    setLoading(false)
    triggerClose()
    dispatch(stocksThunks.fetchStocksConfig({ configApi }))
  }

  const onError = (err: unknown) => {
    const errorDetail = (err as { response?: { data?: { detail?: string } } })?.response?.data
      ?.detail
    setLoading(false)
    console.error(err)
    toaster.show({
      message: (
        <>
          {`Update failed: ${err}`}
          {errorDetail && (
            <>
              <pre className={"bp3-code-block " + styles.errorMessage}>{errorDetail}</pre>
            </>
          )}
        </>
      ),
      intent: "danger",
    })
  }

  return (
    <Dialog
      onClose={triggerClose}
      title={"Update Stocks Config"}
      isOpen={editing !== undefined}
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      usePortal
    >
      <div className={Classes.DIALOG_BODY}>
        {editing && (
          <EditForm
            editing={editing}
            onSubmitRef={onSubmitRef}
            handlers={{ onRequest, onSuccess, onError }}
          />
        )}
        {loading && <Spinner />}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {editing && <SubmitButton mode={editing?.mode} onClickRef={onSubmitRef} />}
          <Button onClick={triggerClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}

const EditForm: FC<{
  editing: StocksConfigEditing
  onSubmitRef: React.MutableRefObject<(() => void) | undefined>
  handlers: RequestHandlers
}> = ({ editing, onSubmitRef, handlers }) => {
  switch (editing.target) {
    case EditTarget.Material:
      return <EditMaterialForm editing={editing} onSubmitRef={onSubmitRef} handlers={handlers} />
    default:
      return <EditStockForm editing={editing} />
  }
}

const EditStockForm: FC<{
  editing: StocksConfigEditing
}> = ({ editing }) => {
  const { id, target, mode } = editing
  const stock = useSelector((state: RootState) => stocksSelectors.selectStock(state, id))
  const allMaterials = useSelector(stocksSelectors.selectMaterials)

  const customMillableFixtureIds = useSelector(fixturesSelectors.selectCustomMillableFixtureIds)

  const [materialId, setMaterialId] = useState(allMaterials[0]?.materialId ?? "-")

  const [minX, setMinX] = useState<string>()
  const [minY, setMinY] = useState<string>()
  const [x, setX] = useState("10mm")
  const [y, setY] = useState("10mm")
  const [diameter, setDiameter] = useState("10mm")
  const [fixtureId, setFixtureId] = useState(customMillableFixtureIds[0] ?? "-")

  const [tolerance, setTolerance] = useState("2mm")
  const [minCutLength, setMinCutLength] = useState("20mm")
  const [maxCutLength, setMaxCutLength] = useState("60in")
  const [isUnavailable, setIsUnavailable] = useState(false)

  let creationId = "<unspecified>"
  switch (target) {
    case EditTarget.RectangularStock: {
      creationId = `${materialId} - Rect - ${x} x ${y}`
      break
    }
    case EditTarget.RoundStock: {
      creationId = `${materialId} - Round - ${diameter}`
      break
    }
    case EditTarget.FixtureStock: {
      creationId = `Fixture - ${fixtureId} - ${materialId}`
      break
    }
  }

  useEffect(() => {
    if (!stock) return

    setMaterialId(stock.materialId)
    setIsUnavailable(stock.isUnavailable ?? false)

    if (stock.data.kind === FixtureStockDataKindEnum.Fixture) {
      setFixtureId(stock.data.fixtureId)
    }
  }, [stock])

  return (
    <>
      <FormGroup label={<Label>ID:</Label>}>
        {mode === EditMode.Create ? <div>{creationId}</div> : <div>{id}</div>}
      </FormGroup>
      {mode !== EditMode.Delete && (
        <>
          <FormGroup label={<Label>Material ID</Label>}>
            {mode === EditMode.Create ? (
              <HTMLSelect value={materialId} onChange={e => setMaterialId(e.target.value)}>
                {allMaterials.map(material => (
                  <option value={material.materialId} key={material.materialId}>
                    {material.materialId}
                  </option>
                ))}
              </HTMLSelect>
            ) : (
              <>{materialId}</>
            )}
          </FormGroup>
          {target === EditTarget.RectangularStock && (
            <>
              <FormGroup label={<Label>Min Width</Label>}>
                <InputGroup
                  onChange={e => setMinX(e.target.value)}
                  value={minX}
                  placeholder={"0mm"}
                />
              </FormGroup>
              <FormGroup label={<Label>Max Width</Label>}>
                {mode === EditMode.Create ? (
                  <InputGroup onChange={e => setX(e.target.value)} value={x} placeholder={"10mm"} />
                ) : (
                  <>{x}</>
                )}
              </FormGroup>
              <FormGroup label={<Label>Min Length</Label>}>
                <InputGroup
                  onChange={e => setMinY(e.target.value)}
                  value={minY}
                  placeholder={"0mm"}
                />
              </FormGroup>
              <FormGroup label={<Label>Max Length</Label>}>
                {mode === EditMode.Create ? (
                  <InputGroup onChange={e => setY(e.target.value)} value={y} placeholder={"10mm"} />
                ) : (
                  <>{y}</>
                )}
              </FormGroup>
            </>
          )}
          {target === EditTarget.RoundStock && (
            <FormGroup label={<Label>Diameter</Label>}>
              {mode === EditMode.Create ? (
                <InputGroup
                  onChange={e => setDiameter(e.target.value)}
                  value={diameter}
                  placeholder={"10mm"}
                />
              ) : (
                <>{diameter}</>
              )}
            </FormGroup>
          )}
          {target === EditTarget.FixtureStock && (
            <FormGroup label={<Label>Fixture ID</Label>}>
              {mode === EditMode.Create ? (
                <HTMLSelect value={fixtureId} onChange={e => setFixtureId(e.target.value)}>
                  {customMillableFixtureIds.map(id => (
                    <option value={id} key={id}>
                      {id}
                    </option>
                  ))}
                </HTMLSelect>
              ) : (
                <>{fixtureId}</>
              )}
            </FormGroup>
          )}

          {target !== EditTarget.FixtureStock && (
            <>
              <Divider className={styles.formDivider} />
              <FormGroup label={<Label>Tolerance</Label>}>
                <InputGroup
                  onChange={e => setTolerance(e.target.value)}
                  value={tolerance}
                  placeholder={"2mm"}
                />
              </FormGroup>
              <FormGroup label={<Label>Min Height</Label>}>
                <InputGroup
                  onChange={e => setMinCutLength(e.target.value)}
                  value={minCutLength}
                  placeholder={"20mm"}
                />
              </FormGroup>
              <FormGroup label={<Label>Max Height</Label>}>
                <InputGroup
                  onChange={e => setMaxCutLength(e.target.value)}
                  value={maxCutLength}
                  placeholder={"60in"}
                />
              </FormGroup>
            </>
          )}
          <Divider className={styles.formDivider} />
          <Checkbox
            label={"Is Unavailable"}
            checked={isUnavailable}
            onChange={() => setIsUnavailable(v => !v)}
          />
        </>
      )}
    </>
  )
}

const EditMaterialForm: FC<{
  editing: StocksConfigEditing
  onSubmitRef: React.MutableRefObject<(() => void) | undefined>
  handlers: RequestHandlers
}> = ({ editing, onSubmitRef, handlers }) => {
  const toaster = useToaster()
  const { configApi } = useApi()

  const [creationId, setCreationId] = useState("")
  const [vericutId, setVericutId] = useState(VERICUT_MATERIAL_IDS[0])

  const { id, mode } = editing
  const material = useSelector((state: RootState) => stocksSelectors.selectMaterial(state, id))

  useEffect(() => {
    if (!material) return
    setVericutId(material.vericutId)
  }, [material])

  useEffect(() => {
    onSubmitRef.current = () => {
      const materialId = id ?? creationId
      if (materialId.length === 0) {
        toaster.show({ message: "Please specify a material ID", intent: "danger" })
        return
      }

      const { onRequest, onSuccess, onError } = handlers
      onRequest()

      const record: MaterialRecord = { materialId, vericutId }
      switch (mode) {
        case EditMode.Create: {
          configApi.createStockMaterial(record).then(onSuccess).catch(onError)
          break
        }
        case EditMode.Update: {
          if (!id) break
          configApi.updateStockMaterial(record).then(onSuccess).catch(onError)
          break
        }
        case EditMode.Delete: {
          if (!id) break
          configApi.deleteStockMaterial(id).then(onSuccess).catch(onError)
        }
      }
    }
    return () => {
      onSubmitRef.current = undefined
    }
  }, [configApi, onSubmitRef, mode, creationId, id, vericutId, handlers, toaster])

  return (
    <>
      <FormGroup label={<Label>ID:</Label>}>
        {mode === EditMode.Create ? (
          <InputGroup
            intent={creationId.length === 0 ? "danger" : undefined}
            onChange={e => setCreationId(e.target.value)}
            defaultValue={""}
            placeholder={"Enter new ID"}
          />
        ) : (
          <div>{id}</div>
        )}
      </FormGroup>
      {mode !== EditMode.Delete && (
        <>
          <Divider className={styles.formDivider} />
          <FormGroup label={<Label>Vericut Material ID</Label>}>
            <VericutMaterialSelector material={vericutId} setMaterial={m => setVericutId(m)} />
          </FormGroup>
        </>
      )}
    </>
  )
}

const SubmitButton: FC<{
  mode: EditMode
  onClickRef: React.MutableRefObject<(() => void) | undefined>
}> = ({ mode, onClickRef }) => {
  switch (mode) {
    case EditMode.Create:
      return (
        <Button
          intent={"success"}
          onClick={() => {
            onClickRef.current?.()
          }}
        >
          Create
        </Button>
      )
    case EditMode.Update:
      return (
        <Button intent={"primary"} onClick={() => onClickRef.current?.()}>
          Update
        </Button>
      )
    case EditMode.Delete:
      return (
        <Button intent={"danger"} onClick={() => onClickRef.current?.()}>
          Delete
        </Button>
      )
  }
}

const Label: FC = ({ children }) => {
  return <div className={styles.label}>{children}</div>
}
