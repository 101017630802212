import React, { FC, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { ApolloQueryResult } from "@apollo/client"
import { AnchorButton, Icon, Menu, MenuDivider, MenuItem, Spinner } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import copy from "copy-to-clipboard"

import { PdfPreview } from "src/components/Generic/PdfPreview/PdfPreview"
import { fromGraphQLStatus, TaskStatus } from "src/components/Websocket/Websocket"
import {
  Exact,
  Maybe,
  useIdeUrlQuery,
  VericutFeedbackNodeFragment,
  VericutFeedbackQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useOperationArchiveDownloader } from "src/hooks/useArchiveDownloader"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { VericutLauncher } from "src/pages/JobOverview/OperationCard/VericutLauncher/VericutLauncher"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { useAppDispatch } from "src/store/rootStore"
import { fileModalActions } from "src/store/ui/fileModal"
import { downloadFromUrl } from "src/util/files"

enum IconState {
  NoData = "NoData",
  Pending = "Pending",
  Invalidated = "Invalidated",
  Success = "Success",
  Error = "Error",
  Warning = "Warning",
  Loading = "Loading",
}

interface FeedbackIconProps {
  planId: string
  operationIdx: number
  feedback: VericutFeedbackNodeFragment | undefined
  invalidationReason: string | undefined
  taskStatus: TaskStatus | undefined
  iconSize?: number
  locked?: boolean
  loadingFilesData?: boolean
  refetchVericutFeedback?: (
    variables?:
      | Partial<
          Exact<{
            planId: string
            revision?: Maybe<number> | undefined
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<VericutFeedbackQuery>>
  // ncFileId?: string
}

// export const FeedbackButton: FC<FeedbackIconProps> = ({
//   feedbacks,
//   status,
//   invalidationReason,
//   // planId,
//   // operationIdx,
//   // ncFileId,
//   className,
// }) => {
//   let feedbackIconState = "noData"
//   if (feedbacks?.length) {
//     const feedback = feedbacks[0]
//
//     const fbStatus = status ?? fromGraphQLStatus(feedback.task?.status)
//
//     switch (fbStatus) {
//       case TaskStatus.Pending:
//       case TaskStatus.Processing:
//         feedbackIconState = "pending"
//         break
//       case TaskStatus.Success:
//         if (invalidationReason) {
//           feedbackIconState = "invalidated"
//         } else {
//           feedbackIconState = "success"
//         }
//         break
//       case TaskStatus.Error:
//         feedbackIconState = "error"
//         break
//       case TaskStatus.Warning:
//         feedbackIconState = "warning"
//         break
//     }
//   } else {
//     feedbackIconState = "noData"
//   }
//
//   return (
//     <div className={className}>
//       {feedbackIconState === "pending" && <Spinner size={14} />}
//       {feedbackIconState === "success" && (
//         <Icon icon="tick-circle" intent={"success"} iconSize={14} />
//       )}
//       {feedbackIconState === "error" && <Icon icon="info-sign" intent={"danger"} iconSize={14} />}
//       {feedbackIconState === "warning" && (
//         <Icon icon="warning-sign" intent={"warning"} iconSize={14} />
//       )}
//       {feedbackIconState === "invalidated" && (
//         <Icon icon="warning-sign" intent={"primary"} iconSize={14} />
//       )}
//       {feedbackIconState === "noData" && <Icon icon="info-sign" iconSize={14} />}
//     </div>
//   )
// }

export const FeedbackButton: FC<FeedbackIconProps> = ({
  planId,
  operationIdx,
  taskStatus,
  feedback,
  invalidationReason,
  locked,
  loadingFilesData,
  refetchVericutFeedback,
}) => {
  const iconState = getIconState({ taskStatus, feedback, invalidationReason, loadingFilesData })
  const [reportPdfUrl, setReportPdfUrl] = useState<string | undefined>()

  const { data } = useIdeUrlQuery()

  const { planchangerApi } = useApi()

  const errorToast = useNetworkErrorToast()

  const selectToolpathProject = useMemo(
    () => storedOperationSelectors.createSelectToolpathProject(planId, operationIdx),
    [planId, operationIdx]
  )
  const toolpathProject = useSelector(selectToolpathProject)

  const generateVericut = useOperationArchiveDownloader({
    planId,
    operationIdx,
    ncProgramLocator: toolpathProject?.ncFileLocator ?? undefined,
    vericut: true,
  })

  const { filesApi } = useApi()

  const copySetupManifestToClipboard = () => () => {
    filesApi
      .getManifestUris(planId, operationIdx)
      .then(val => {
        copy(val.data.setup ?? "Setup manifest not published yet")
      })
      .catch(err => copy(err.toString()))
  }

  const copySimulationManifestToClipboard = () => () => {
    filesApi
      .getManifestUris(planId, operationIdx)
      .then(val => {
        copy(val.data.simulation ?? "Simulation manifest not published yet")
      })
      .catch(err => copy(err.toString()))
  }

  const copyPartManifestToClipboard = () => () => {
    filesApi
      .getManifestUris(planId, operationIdx)
      .then(val => {
        copy(val.data.part ?? "Part manifest not published yet")
      })
      .catch(err => copy(err.toString()))
  }

  const copyDrawingManifestToClipboard = () => () => {
    filesApi
      .getManifestUris(planId, operationIdx)
      .then(val => {
        copy(val.data.drawing ?? "Drawing manifest not published yet")
      })
      .catch(err => copy(err.toString()))
  }

  const openSimulationManifestInIde = () => {
    filesApi
      .getManifestUris(planId, operationIdx)
      .then(val => {
        console.log(val.data)

        // TODO: get typings for `BaseManifest`
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const manifest: any = val.data.simulation

        const ideUrl = `${data?.ideUrl}/?simulationUri=${manifest.uri}&layout=Simulation`

        window.open(ideUrl, "_blank")
      })
      .catch(err => {
        errorToast(err, "Error getting Simulation Manifest")
      })
  }

  const handleViewReport = () => {
    planchangerApi.urlFor_getFile(feedback?.report?.locator, undefined, true).then(val => {
      console.log(val)
      setReportPdfUrl(val.toString())
    })
  }

  const handleDownloadReviewFile = useCallback(() => {
    planchangerApi
      .urlFor_getFile(feedback?.vcReview?.locator)
      .then(url => downloadFromUrl(url.toString()))
  }, [feedback, planchangerApi])

  const dispatch = useAppDispatch()

  const handleViewLog = () => {
    if (feedback) {
      const logFiles = [
        feedback.log,
        feedback.fileByForceData,
        feedback.fileByForceDataOptimized,
        feedback.fileByOptimizedNc,
      ].filter(file => file?.locator)
      const fileModalProps = {
        files: logFiles.map(file => ({ locator: file?.locator, labelText: file?.basename })),
      }

      dispatch(fileModalActions.setFileModalProps(fileModalProps))
      dispatch(fileModalActions.setFileModalIsOpen(true))
    }
  }

  return (
    <>
      <Popover2
        onOpening={() => refetchVericutFeedback?.()}
        content={
          <Menu>
            <Tooltip2
              disabled={!!feedback?.log}
              content={"No simulation log exists"}
              openOnTargetFocus={false}
            >
              <MenuItem
                disabled={!feedback?.log}
                icon={"code"}
                text={"View Log"}
                onClick={handleViewLog}
              />
            </Tooltip2>
            <div />
            <Tooltip2
              disabled={!!feedback?.report?.locator}
              content={"No simulation report exists"}
              openOnTargetFocus={false}
            >
              <MenuItem
                disabled={!feedback?.report?.locator}
                icon={"document"}
                text={"View Report"}
                onClick={handleViewReport}
              />
            </Tooltip2>
            <div />
            <Tooltip2
              disabled={!!feedback?.vcReview?.locator}
              content={"No Vericut Reviewer file exists"}
              openOnTargetFocus={false}
            >
              <MenuItem
                disabled={!feedback?.vcReview?.locator}
                icon={"eye-open"}
                text={"Download Reviewer File"}
                onClick={handleDownloadReviewFile}
              />
            </Tooltip2>
            <MenuDivider />
            <MenuItem
              onClick={generateVericut}
              text={"Generate Vericut project"}
              disabled={locked}
            />
            <VericutLauncher
              planId={planId}
              operationIdx={operationIdx}
              projectLocator={feedback?.projectLocator ?? undefined}
            />
            <div />
            <MenuDivider />
            <div />
            <Tooltip2
              content={"Open the Simulation Manifest in the IDE"}
              openOnTargetFocus={false}
              fill
            >
              <MenuItem
                icon="wrench"
                onClick={openSimulationManifestInIde}
                text={"Open in IDE"}
                disabled={locked}
              />
            </Tooltip2>
            <MenuItem icon="duplicate" text={"Copy Manifest URI"} disabled={locked}>
              <MenuItem text="Setup" onClick={copySetupManifestToClipboard()} />
              <MenuItem text="Simulation" onClick={copySimulationManifestToClipboard()} />
              <MenuItem text="Part" onClick={copyPartManifestToClipboard()} />
              <MenuItem text="Drawing" onClick={copyDrawingManifestToClipboard()} />
            </MenuItem>
          </Menu>
        }
      >
        <FeedbackIcon iconState={iconState} invalidationReason={invalidationReason} />
      </Popover2>
      <PdfPreview
        url={reportPdfUrl}
        onClose={() => {
          setReportPdfUrl(undefined)
        }}
      />
    </>
  )
}

const getIconState = ({
  feedback,
  taskStatus,
  invalidationReason,
  loadingFilesData = true,
}: {
  feedback: VericutFeedbackNodeFragment | undefined
  taskStatus: TaskStatus | undefined
  invalidationReason: string | undefined
  loadingFilesData?: boolean
}) => {
  if (loadingFilesData) {
    return IconState.Loading
  }

  let iconState = IconState.NoData

  if (feedback) {
    const feedbackStatus = taskStatus ?? fromGraphQLStatus(feedback.task?.status)
    switch (feedbackStatus) {
      case TaskStatus.Pending:
      case TaskStatus.Processing:
        iconState = IconState.Pending
        break
      case TaskStatus.Success:
        if (invalidationReason) {
          iconState = IconState.Invalidated
        } else {
          iconState = IconState.Success
        }
        break
      case TaskStatus.Error:
        iconState = IconState.Error
        break
      case TaskStatus.Warning:
        iconState = IconState.Warning
        break
    }
  }
  return iconState
}

const FeedbackIcon: FC<{
  iconState: IconState
  invalidationReason: string | undefined
  iconSize?: number
}> = ({ iconState, invalidationReason, iconSize = 14 }) => {
  let icon: JSX.Element
  let tooltipText: string | JSX.Element
  switch (iconState) {
    case IconState.Loading:
      icon = <Spinner size={iconSize} />
      tooltipText = "Loading..."
      break
    case IconState.Pending:
      icon = <Spinner size={iconSize} />
      tooltipText = "Simulation in progress..."
      break
    case IconState.NoData:
      icon = <Icon icon="info-sign" iconSize={iconSize} />
      tooltipText = "This program has not yet been simulated"
      break
    case IconState.Success:
      tooltipText = "Simulation succeeded - click to review"
      icon = <Icon icon="tick-circle" intent={"success"} iconSize={iconSize} />
      break
    case IconState.Warning:
      tooltipText = "Simulation completed with warnings - click for details"
      icon = <Icon icon="warning-sign" intent={"warning"} iconSize={iconSize} />
      break
    case IconState.Error:
      tooltipText = "Simulation failed - click for details"
      icon = <Icon icon="info-sign" intent={"danger"} iconSize={iconSize} />
      break
    case IconState.Invalidated:
      tooltipText = `This simulation has been invalidated:`
      icon = <Icon icon="warning-sign" intent={"primary"} iconSize={iconSize} />
      break
  }
  if (invalidationReason) {
    tooltipText = (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{tooltipText}</div>
          {invalidationReason.split("\n").map((x, i) => (
            <div key={i}>{`• ${x}`}</div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <Tooltip2
      modifiers={{
        preventOverflow: { enabled: false },
      }}
      openOnTargetFocus={false}
      content={tooltipText}
    >
      <AnchorButton minimal>{icon}</AnchorButton>
    </Tooltip2>
  )
}
