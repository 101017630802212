import React, { FC } from "react"

import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { fetchAllConfigs } from "src/store/config/config"
import { useAppDispatch } from "src/store/rootStore"

export const PopulateConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { configApi, planchangerApi } = useApi()

  useWebsocketMessageListener(wsMessage => {
    if (wsMessage.type === "ConfigReload") {
      fetchAllConfigs(dispatch, configApi, planchangerApi)
    }
  })

  React.useEffect(() => {
    fetchAllConfigs(dispatch, configApi, planchangerApi)
  }, [dispatch, configApi, planchangerApi])

  return null
}
