import React, { FC } from "react"

import { ModelStock } from "src/client-axios"
import { ProductStockSelector } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/ProductStockSelector/ProductStockSelector"
import { ProductStockTransform } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/ProductStockTransform/ProductStockTransform"

import styles from "./ModelStockConfigPanel.module.css"

export const ModelStockConfigPanel: FC<{
  planId: string
  operationIdx: number
  inputStock: ModelStock
  locked?: boolean
}> = ({ planId, operationIdx, inputStock, locked }) => {
  return (
    <div className={styles.container}>
      <ProductStockSelector planId={planId} operationIdx={operationIdx} inputStock={inputStock} />
      <ProductStockTransform
        planId={planId}
        operationIdx={operationIdx}
        transform={inputStock.transform}
        locked={locked}
      />
    </div>
  )
}
