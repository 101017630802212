import React, { FC, useEffect, useState } from "react"
import { Icon } from "@blueprintjs/core"

import { useModelByIdLazyQuery } from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"

import styles from "./Thumbnail.module.css"

interface ModelFragment {
  id: string
  thumbnail?: SourceFragment | null
}

interface SourceFragment {
  locator?: string | null
  exists?: boolean | null
}

interface ThumbnailProps {
  model?: ModelFragment | null
  large?: boolean
  medium?: boolean
  small?: boolean
}

export const Thumbnail: FC<ThumbnailProps> = ({ model, large, medium, small }) => {
  // Set a default of 1 second if we are polling for updates
  const [pollInterval, setPollInterval] = useState(1000)
  // We don't want to execute a query always as we have already had this info passed in
  const [getModel, { called, data }] = useModelByIdLazyQuery({ pollInterval })

  const { planchangerApi } = useApi()

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined)

  const thumbnailLocator = model?.thumbnail?.locator || data?.model3D?.thumbnail?.locator
  const thumbnailExists = model?.thumbnail?.exists || data?.model3D?.thumbnail?.exists
  const id = model?.id

  useEffect(() => {
    thumbnailLocator &&
      planchangerApi.urlFor_getFile(thumbnailLocator, undefined, true).then(result => {
        setThumbnailUrl(result.toString())
      })
  }, [planchangerApi, setThumbnailUrl, thumbnailLocator])

  // This effect will trigger our lazy query if we don't have an existing thumbnail
  useEffect(() => {
    if (id !== undefined && thumbnailUrl) {
      if (!thumbnailExists) {
        // If the thumbnail doesn't exist and we haven't called for it yet, we execute our lazy query & poll
        // We check !called so we only call `getModel` once
        if (!called) {
          getModel({ variables: { id } })
        }
      } else {
        // If the thumbnail exists we stop polling
        setPollInterval(0)
      }
    }
  }, [id, getModel, thumbnailExists, thumbnailUrl, called])

  if (!thumbnailExists) {
    // TODO: a nicer thumbnail preview
    return <Icon icon="media" color="#84878a" iconSize={60} />
  }

  if (!thumbnailUrl) {
    return <></>
  }

  return (
    <img
      className={`${styles.thumbnail} ${
        large
          ? styles.thumbnailLarge
          : medium
          ? styles.thumbnailMedium
          : small
          ? styles.thumbnailSmall
          : ""
      }`}
      src={thumbnailUrl}
    />
  )
}
