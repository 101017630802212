import React, { FC } from "react"

import styles from "./ItarBar.module.css"

const ITAR_ORIGIN = `itar.formlogic.com`

export const isItar: () => boolean = () => window.location.origin.includes(ITAR_ORIGIN)

export const ItarBar: FC = () => {
  return (
    <>
      {isItar() && (
        <div className={styles.container}>ITAR — DISPLAYED CONTENT MAY BE EXPORT CONTROLLED</div>
      )}
    </>
  )
}
