import React, { FC } from "react"
import { Provider } from "react-redux"
import { Menu, MenuItem, Toaster } from "@blueprintjs/core"
import { AuthProvider } from "@subscale/formlogic-auth-ts"

import { ApiContext, HttpApi, useApi } from "src/hooks/useApi"
import { ToasterContext, useToaster } from "src/hooks/useToaster"
import { rootStore } from "src/store/rootStore"
import { ContextMenuTarget } from "./ContextMenuTarget/contextMenuTarget"

interface RightClickTargetProps {
  menu: React.ReactElement
  onContextMenuClose?: () => void
}

export const EmptyContextMenu: FC = () => {
  return (
    <Menu>
      <MenuItem disabled={true} text={"No actions"} />
    </Menu>
  )
}

export const ContextTarget: FC<RightClickTargetProps> = ({
  menu,
  onContextMenuClose,
  children,
}) => {
  const api = useApi()
  const toaster = useToaster()
  return (
    <RightClickTargetClass
      menu={menu}
      onContextMenuClose={onContextMenuClose}
      api={api}
      toaster={toaster}
    >
      {children}
    </RightClickTargetClass>
  )
}

interface ContextMenuClassProps extends RightClickTargetProps {
  api: HttpApi
  toaster: Toaster
}

const RightClickTargetClass = ContextMenuTarget(
  class RightClickMenuWithContext extends React.Component<ContextMenuClassProps> {
    render() {
      // root element must support `onContextMenu`
      if (React.isValidElement(this.props.children)) {
        return this.props.children
      } else {
        throw new Error("Menu had invalid children")
      }
    }

    renderContextMenu() {
      // return a single element, or nothing to use default browser behavior
      return (
        <Provider store={rootStore}>
          <AuthProvider>
            <ApiContext.Provider value={this.props.api}>
              <ToasterContext.Provider value={this.props.toaster}>
                {this.props.menu}
              </ToasterContext.Provider>
            </ApiContext.Provider>
          </AuthProvider>
        </Provider>
      )
    }

    onContextMenuClose() {
      // Optional method called once the context menu is closed.
      this.props?.onContextMenuClose?.()
    }
  }
)
