import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TextArea } from "@blueprintjs/core"

import { Modal } from "src/components/Generic/Modal/Modal"
import { useUpdateCamOperationNotesMutation } from "src/graphql/generated"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"

export const NotesOperationModal: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  close: () => void
}> = ({ planId, operationIdx, isOpen, close }) => {
  const [updateCamOperationNotesMutation] = useUpdateCamOperationNotesMutation()

  const operation = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )

  const [notes, setNotes] = useState<string>(operation?.notes ?? "")

  useEffect(() => {
    setNotes(operation?.notes ?? "")
  }, [operation?.notes, isOpen])

  const handleNotes = () => {
    updateCamOperationNotesMutation({
      variables: { planId, operationIdx, notes },
      refetchQueries: ["planPatchRecords"],
    })
      .then(result => {
        console.log("updateCamOperationNotesMutation -> result", result)
      })
      .catch(e => {
        console.error("updateCamOperationNotesMutation -> e", e)
      })
  }
  const handleCancel = () => {
    close()
  }
  const handleConfirm = () => {
    handleNotes()
    close()
  }
  return (
    <Modal
      isOpen={isOpen}
      headerText={`Notes Operation ${operationIdx + 1}`}
      bodyComponent={
        <TextArea
          large={true}
          fill={true}
          onChange={e => setNotes(e.currentTarget.value)}
          value={notes}
        />
      }
      bodyText="Keep track of the operation's design here."
      cancelButtonText="Cancel"
      confirmButtonText="Save"
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleRequestClose={close}
    />
  )
}
