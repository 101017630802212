import { createContext, useContext } from "react"
import { SpringConfig } from "@react-spring/three"

type AnimationContextProps = {
  onChange?: () => void
  config?: SpringConfig
}

export const AnimationContext = createContext<AnimationContextProps>({})
export const useAnimationContext = () => useContext(AnimationContext)
