import React, { FC } from "react"
import { Icon, Intent, Position } from "@blueprintjs/core"
import { IconName } from "@blueprintjs/icons"
import { Tooltip2 } from "@blueprintjs/popover2"

import styles from "./ManufacturingIssue.module.css"

export const ManufacturingIssue: FC<{
  isPresent: boolean
  kind?: "error" | "warning"
  tooltip?: string | JSX.Element
}> = ({ isPresent, kind = "error", tooltip, children }) => {
  if (!isPresent) return null

  let icon: IconName
  let intent: Intent

  if (kind === "error") {
    icon = "error"
    intent = "danger"
  } else {
    icon = "warning-sign"
    intent = "warning"
  }

  return (
    <li className={styles.headerContainer}>
      <div className={styles.icon}>
        <Icon icon={icon} intent={intent} iconSize={14} />
      </div>
      <div>{children}</div>
      {tooltip && (
        <div className={styles.tooltip}>
          <Tooltip2
            hoverOpenDelay={300}
            content={tooltip}
            openOnTargetFocus={false}
            position={Position.RIGHT}
          >
            <Icon icon={"help"} iconSize={14} />
          </Tooltip2>
        </div>
      )}
    </li>
  )
}
