import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { StockAnalysisToolPathData, WorkPlaneTransform } from "src/client-axios"
import {
  getKinematicErrorMap,
  KinematicError,
  KinematicErrorMap,
  machineCoordsKinematicError,
} from "src/components/Canvas/Viewer/Scene/Cam/kinematicError"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { SceneClickData, useSceneClickEventHandler } from "src/hooks/useSceneClickEventHandler"
import {
  DisplayMode as CuttingSimDisplayMode,
  viewOptionsSelectors,
} from "src/store/ui/viewOptions"
import {
  pcdmisSimAnalysisFieldResult,
  selectFeatures,
  useCmmDisplayStore,
} from "src/store/zustandCmm"
import {
  CutData,
  CutDataCutInfo,
  CuttingSimDisplayState,
  useCuttingSimDisplayStore,
  useProductionControlsDisplayStore,
} from "src/store/zustandCuttingSim"
import { getWorldToPartTransform, useMachineCoordsStore } from "src/store/zustandMachine"
import { transformToMatrix } from "src/util/geometry/transforms"
import { findNearbyIntersection, getClickedIntersections } from "../../ClickHandlers/util"
import { PlanOperation } from "./CamScene/sharedTypes"
import { Arrow } from "./Arrow"
import { useStockSimulationData } from "./stockSimData"
import { OperationNcEvent } from "./stockSimDefs"
import { StockSimFcs } from "./StockSimFcs"
import { partToSetupMatrix, StockSimMesh } from "./StockSimMesh"
import { StockSimToolPaths } from "./StockSimToolPaths"

interface StockSimSceneProps {
  planOperation: PlanOperation
}

type GeometryAttributes = {
  [name: string]: THREE.BufferAttribute | THREE.InterleavedBufferAttribute
}

const makeColor = (c: [number, number, number]) => {
  const v = [
    Math.floor(Math.pow(c[0] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[1] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[2] / 255, 2.0) * 255),
  ]
  return `rgb(${v[0]}, ${v[1]}, ${v[2]})`
}

function getCutData(
  attributes: GeometryAttributes,
  vertexIndex: number,
  partToSetup: THREE.Matrix4,
  toolChanges: OperationNcEvent[] | undefined,
  toolPathCbor: StockAnalysisToolPathData,
  cutNumberToIndex: Record<number, number>,
  kinematicErrorMap?: KinematicErrorMap
): CutData {
  const cutNumber = attributes._cut_number.array[vertexIndex]
  const idx = cutNumberToIndex[cutNumber]

  const gcodeLine = toolPathCbor.gcode_line[idx]
  const toolChange = toolPathCbor.tool_change[idx]
  const cutterComp = attributes.cutter_comp?.array[vertexIndex]
  const radialComp = attributes.radial_comp?.array[vertexIndex]
  const speed = attributes.speed?.array[vertexIndex]
  const feed = attributes.feed?.array[vertexIndex]
  const toolPosition = new THREE.Vector3(
    toolPathCbor.tool_position[3 * idx],
    toolPathCbor.tool_position[3 * idx + 1],
    toolPathCbor.tool_position[3 * idx + 2]
  )
  const toolAxis = new THREE.Vector3(
    toolPathCbor.tool_axis[3 * idx],
    toolPathCbor.tool_axis[3 * idx + 1],
    toolPathCbor.tool_axis[3 * idx + 2]
  )
  const normalVec = new THREE.Vector3(
    attributes.normal.array[3 * vertexIndex],
    attributes.normal.array[3 * vertexIndex + 1],
    attributes.normal.array[3 * vertexIndex + 2]
  )
  normalVec.applyMatrix4(partToSetup)
  let toolNumber = ""
  if (toolChanges && toolChange >= 0 && toolChange < toolChanges.length) {
    toolNumber = toolChanges[toolChange].val
  }

  let cutInfo: CutDataCutInfo | undefined
  if (toolPathCbor.cut_info) {
    const leftEngaged = toolPathCbor.cut_info.left_engaged[idx]
    const leftMinWidth = toolPathCbor.cut_info.left_min_width[idx]
    const leftMaxWidth = toolPathCbor.cut_info.left_max_width[idx]
    const rightEngaged = toolPathCbor.cut_info.right_engaged[idx]
    const rightMinWidth = toolPathCbor.cut_info.right_min_width[idx]
    const rightMaxWidth = toolPathCbor.cut_info.right_max_width[idx]
    const maxDepthOfCut = toolPathCbor.cut_info.max_depth_of_cut[idx]
    const widthOfCut = toolPathCbor.cut_info.width_of_cut[idx]
    const volume = toolPathCbor.cut_info.volume[idx]
    cutInfo = {
      leftEngaged,
      leftMinWidth,
      leftMaxWidth,
      rightEngaged,
      rightMinWidth,
      rightMaxWidth,
      maxDepthOfCut,
      widthOfCut,
      volume,
    }
  }

  const machineCoordsAttribute = attributes.machine_coords
  let kinematicError: KinematicError | undefined
  if (kinematicErrorMap && machineCoordsAttribute) {
    const A = machineCoordsAttribute.array[vertexIndex * 3]
    const B = machineCoordsAttribute.array[vertexIndex * 3 + 1]
    const C = machineCoordsAttribute.array[vertexIndex * 3 + 2]
    const machineCoords = { A, B, C }
    kinematicError = machineCoordsKinematicError(kinematicErrorMap, machineCoords)
  }

  return {
    vertexIndex,
    cutNumber,
    gcodeLine,
    toolPosition,
    toolAxis,
    normalVec,
    toolChange,
    cutterComp,
    radialComp,
    speed,
    feed,
    toolNumber,
    cutInfo,
    kinematicError,
  }
}

export const StockSimScene: FC<StockSimSceneProps> = ({ planOperation }) => {
  const stockSimData = useStockSimulationData(planOperation)

  const toolPathCbor = stockSimData.stockAnalysisData
  const stockGltfUrl = stockSimData.stockGltfUrl
  const gcodeOutput = stockSimData.gcodeOutput
  const ncEventIntervals = stockSimData.ncEventIntervals

  const [kinematicArrows, setKinematicArrows] = useState<JSX.Element>()
  const [clickIntersection, setClickIntersection] = useState<THREE.Intersection>()
  const [workPlaneTransform, setWorkPlaneTransform] = useState<WorkPlaneTransform>()

  const { transferInvalidate } = useTransferInvalidate()

  const cuttingSimDisplayMode = useSelector(viewOptionsSelectors.cuttingSimDisplayMode)
  const { mcs, wcs } = useMachineCoordsStore().rtcpState
  const { kinematicBenchmark, cutData, opCamFeatureInfo, showFcs } = useCuttingSimDisplayStore()

  const isectCutData = useCuttingSimDisplayStore().cutData

  const cmmState = useCmmDisplayStore()
  const { skuSchemaField, pcdmisSimAnalysis } = cmmState

  const partToSetup = useMemo(() => {
    return partToSetupMatrix(mcs)
  }, [mcs])

  const cutNumberToIndex = useMemo(() => {
    if (toolPathCbor) {
      const map: Record<number, number> = {}
      for (let i = 0; i < toolPathCbor.cut_number.length; ++i) {
        map[toolPathCbor.cut_number[i]] = i
      }
      return map
    }
    return undefined
  }, [toolPathCbor])

  const onClick = (ev: CustomEvent<SceneClickData>) => {
    let newCutData: CutData | undefined
    const intersections = getClickedIntersections(ev.detail)
    const intersection = findNearbyIntersection(intersections, o => o.userData.isStockSimMesh, 1.5)

    if (intersection && intersection.object instanceof THREE.Mesh) {
      const mesh = intersection.object as THREE.Mesh
      const attributes = mesh.geometry.attributes
      const vertexIndex = intersection?.face?.a
      if (
        toolPathCbor &&
        cutNumberToIndex &&
        vertexIndex !== undefined &&
        vertexIndex < attributes._cut_number.count
      ) {
        newCutData = getCutData(
          attributes,
          vertexIndex,
          partToSetup,
          ncEventIntervals.toolChanges,
          toolPathCbor,
          cutNumberToIndex,
          kinematicErrorMap
        )

        if (ev.detail.mouseEvent.button === 0) {
          if (newCutData.toolChange === undefined) {
            setKinematicArrows(undefined)
          } else if (newCutData.kinematicError) {
            const arrows = (
              <KinematicErrorArrows
                kinematicError={newCutData.kinematicError}
                position={intersection.point}
                surfaceNormal={newCutData.normalVec}
              />
            )
            setKinematicArrows(arrows)
          }
          const selectedToolPaths = []
          const toolChange = newCutData?.toolChange
          if (toolChange !== undefined && toolChange >= 0) {
            selectedToolPaths.push(toolChange)
          }
          useCuttingSimDisplayStore.setState({
            cutData: newCutData,
            selectedToolPaths,
          })
          setClickIntersection(intersection)

          const gcodeLine = newCutData?.gcodeLine
          if (opCamFeatureInfo && gcodeLine !== undefined) {
            const wpt = opCamFeatureInfo.work_plane_transforms.find(wpt => {
              return wpt.gcode_line_range.start <= gcodeLine && wpt.gcode_line_range.end > gcodeLine
            })
            setWorkPlaneTransform(wpt)
          }
        } else {
          setClickIntersection(undefined)
          setWorkPlaneTransform(undefined)
        }
      } else {
        setClickIntersection(undefined)
        setWorkPlaneTransform(undefined)
      }
    } else {
      setClickIntersection(undefined)
      setWorkPlaneTransform(undefined)
    }

    useProductionControlsDisplayStore.setState(prev => {
      return {
        toolChangeIndex: newCutData?.toolChange,
        toolId: newCutData?.toolNumber,
        machineOffsetsDialogIsOpen: prev.machineOffsetsDialogIsOpen,
      }
    })
  }
  useSceneClickEventHandler(onClick, onClick, onClick)

  useEffect(() => {
    return useCuttingSimDisplayStore.subscribe(
      (state: CuttingSimDisplayState) => state,
      () => {
        transferInvalidate()
      },
      { fireImmediately: true }
    )
  })

  useEffect(() => {
    const toolIds: Array<string | undefined> = []
    ncEventIntervals.toolChanges.forEach(toolChange => {
      toolIds.push(toolChange.val)
    })
    useCuttingSimDisplayStore.setState({ toolIds })
  }, [ncEventIntervals])

  const kinematicErrorMap = useMemo(() => {
    if (kinematicBenchmark) {
      return getKinematicErrorMap(kinematicBenchmark)
    }
    return undefined
  }, [kinematicBenchmark])

  useEffect(() => {
    useProductionControlsDisplayStore.setState({
      toolChangeIndex: cutData?.toolChange,
      toolId: cutData?.toolNumber,
    })
  }, [cutData])

  const stockSimMeshes = useMemo(() => {
    return stockSimData.toolChangeStocks?.map((stock, idx) => {
      const url = stock.url!
      return (
        <StockSimMesh
          key={idx}
          gcodeOutput={gcodeOutput}
          toolPathCbor={toolPathCbor}
          ncEventIntervals={ncEventIntervals}
          cutNumberToIndex={cutNumberToIndex}
          kinematicErrorMap={kinematicErrorMap}
          stockGltfUrl={url}
          toolChangeIndex={stock.index}
        />
      )
    })
  }, [
    stockSimData.toolChangeStocks,
    gcodeOutput,
    toolPathCbor,
    ncEventIntervals,
    cutNumberToIndex,
    kinematicErrorMap,
  ])

  useEffect(() => {
    if (
      isectCutData &&
      opCamFeatureInfo &&
      skuSchemaField &&
      pcdmisSimAnalysis &&
      clickIntersection &&
      clickIntersection.object instanceof THREE.Mesh
    ) {
      const fieldResult = pcdmisSimAnalysisFieldResult(pcdmisSimAnalysis)
      const info = opCamFeatureInfo.infos.find(info => {
        return info.gcode_lines.find(range => {
          return range.start <= isectCutData.gcodeLine && range.end > isectCutData.gcodeLine
        })
      })
      if (info) {
        const featureToolResult = fieldResult.feature_tool_results.filter(res => {
          if ("stock_cut_data" in res) {
            const stockCutData = res.stock_cut_data
            return info.gcode_lines.find(range => {
              return stockCutData.gcode_line.find(gcodeLine => {
                return range.start <= gcodeLine && range.end > gcodeLine
              })
            })
          }
          return false
        })
        const featureSet = new Set(featureToolResult.map(f => f.feature_name))
        const skuFeatures = skuSchemaField.features.filter(f => featureSet.has(f.name!))
        const subFeatures = new Set()
        skuFeatures.forEach(f => {
          if (f.sub_features) {
            f.sub_features.forEach(name => {
              subFeatures.add(name)
            })
          }
        })
        const skuMainFeatures = skuFeatures.filter(f => !subFeatures.has(f.name!))
        const skuMainFeatureNames = skuMainFeatures.map(f => f.name!)
        selectFeatures(cmmState, skuMainFeatureNames, true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isectCutData, opCamFeatureInfo, skuSchemaField, pcdmisSimAnalysis, clickIntersection])

  if (!stockGltfUrl) {
    return <></>
  }

  let fcsTransform
  let clickFcsTransform
  if (opCamFeatureInfo) {
    const transform = workPlaneTransform?.transform
    const fcs = transform ? transformToMatrix(transform) : wcs
    const position = new THREE.Vector3()
    const quaternion = new THREE.Quaternion()
    const scale = new THREE.Vector3()
    fcs.decompose(position, quaternion, scale)
    fcsTransform = {
      position,
      quaternion,
    }

    if (clickIntersection) {
      if (clickIntersection.object instanceof THREE.Mesh) {
        const mesh = clickIntersection.object
        const geometry = mesh.geometry as THREE.BufferGeometry
        const position = geometry.getAttribute("position")
        const a = clickIntersection.face?.a
        const b = clickIntersection.face?.b
        const c = clickIntersection.face?.c
        if (a !== undefined && b !== undefined && c !== undefined) {
          const x = (position.array[a * 3] + position.array[b * 3] + position.array[c * 3]) / 3.0
          const y =
            (position.array[a * 3 + 1] + position.array[b * 3 + 1] + position.array[c * 3 + 1]) /
            3.0
          const z =
            (position.array[a * 3 + 2] + position.array[b * 3 + 2] + position.array[c * 3 + 2]) /
            3.0

          clickFcsTransform = {
            position: new THREE.Vector3(x, y, z),
            quaternion,
          }
        }
      }
    }
  }

  return (
    <>
      {cuttingSimDisplayMode !== CuttingSimDisplayMode.Hidden && (
        <group>
          {kinematicArrows}
          <StockSimMesh
            gcodeOutput={gcodeOutput}
            toolPathCbor={toolPathCbor}
            ncEventIntervals={ncEventIntervals}
            cutNumberToIndex={cutNumberToIndex}
            kinematicErrorMap={kinematicErrorMap}
            stockGltfUrl={stockGltfUrl}
            parametricSurfaceData={stockSimData.stockParamSurfData}
            machinedParametricSurfaceData={stockSimData.stockMachinedParamSurfData}
          />
          {stockSimMeshes}
          <StockSimToolPaths toolPathCbor={toolPathCbor} ncEventIntervals={ncEventIntervals} />
          {showFcs && fcsTransform && (
            <group position={fcsTransform.position} quaternion={fcsTransform.quaternion}>
              <StockSimFcs showOrigin={false} />
            </group>
          )}
          {showFcs && clickFcsTransform && (
            <group position={clickFcsTransform.position} quaternion={clickFcsTransform.quaternion}>
              <StockSimFcs showOrigin={true} />
            </group>
          )}
        </group>
      )}
    </>
  )
}

const KinematicErrorArrows: FC<{
  kinematicError: KinematicError
  position: THREE.Vector3
  surfaceNormal: THREE.Vector3
}> = ({ kinematicError, position, surfaceNormal }) => {
  const machineToMcs = getWorldToPartTransform()
  const _p = new THREE.Vector3()
  const _q = new THREE.Quaternion()
  const _s = new THREE.Vector3()
  machineToMcs.decompose(_p, _q, _s)
  const { toolVector, radialProjection } = kinematicError
  const toolVector3 = new THREE.Vector3(toolVector[0], toolVector[1], toolVector[2])
  const radialProjection3 = new THREE.Vector3(
    radialProjection[0],
    radialProjection[1],
    radialProjection[2]
  )
  const reverse = surfaceNormal.dot(radialProjection3) < 0

  return (
    <group position={_p} quaternion={_q}>
      <Arrow
        position={position}
        direction={toolVector3}
        color={new THREE.Color(makeColor([221, 79, 14]))}
        radius={0.5}
        length={10}
        offset={0.25}
        reverse={false}
      />
      <Arrow
        position={position}
        direction={radialProjection3}
        color={new THREE.Color(makeColor([75, 49, 221]))}
        radius={0.5}
        length={10}
        offset={0.25}
        reverse={reverse}
      />
    </group>
  )
}
