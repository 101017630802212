import React, { FC, useEffect, useState } from "react"
import { InputGroup, MenuItem } from "@blueprintjs/core"

import { useToaster } from "src/hooks/useToaster"

export const PasteMenuItem: FC<{
  text: string
  onPaste: (pasted: string) => void
  disabled?: boolean
}> = ({ text, onPaste, disabled }) => {
  const [canPaste, setCanPaste] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toaster = useToaster()

  const popoverProps = {
    isOpen,
    onInteraction: (nextOpenState: boolean) => setIsOpen(nextOpenState),
  }

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.permissions.query({ name: "clipboard-read" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") setCanPaste(true)
      })
    } catch (err) {
      console.error(err)
      toaster.show({ message: `${err}`, intent: "danger" })
    }
  })

  if (canPaste)
    return (
      <MenuItem
        text={text}
        onClick={() =>
          navigator.clipboard
            .readText()
            .then(pastedText => onPaste(pastedText))
            .catch(err => {
              console.error(err)
              toaster.show({ message: `${err}`, intent: "danger" })
            })
        }
        disabled={disabled}
      />
    )
  return (
    <MenuItem text={text} popoverProps={popoverProps} disabled={disabled}>
      <InputGroup
        leftIcon={"clipboard"}
        onChange={e => {
          onPaste(e.target.value)
          setIsOpen(false)
        }}
        value={""}
        placeholder={"Paste here"}
      />
    </MenuItem>
  )
}
