import React, { FC } from "react"
import { HTMLTable, Spinner } from "@blueprintjs/core"
import { formatDistanceToNow } from "date-fns"

import { useSessionsQuery } from "src/graphql/generated"

import styles from "./Sessions.module.css"

export const Sessions: FC = () => {
  const { data } = useSessionsQuery({ pollInterval: 2500 })

  if (!data) {
    return <Spinner />
  }

  const group: { [name: string]: { email: string; idle: number; count: number } } = {}

  data.sessions.forEach(val => {
    const grouping = group[val.name]

    if (grouping !== undefined) {
      grouping.count += 1
      if (grouping.idle > val.idleTime) {
        grouping.idle = val.idleTime
      }
    } else {
      group[val.name] = {
        email: val.name,
        idle: val.idleTime,
        count: 1,
      }
    }
  })

  return (
    <div className={styles.statsContainer}>
      <div className={styles.statsDiv}>
        <h1>Active Sessions</h1>
        <HTMLTable className={styles.statsTable} condensed={true} striped>
          <thead>
            <tr>
              <th>Email</th>
              <th>Session Count</th>
              <th>Last Active</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(group)
              .sort((a, b) => a.idle - b.idle)
              .map((session, i) => {
                return (
                  <tr key={i}>
                    <td>{session.email}</td>
                    <td>{session.count}</td>
                    <td title={session.idle + "ms"}>
                      {formatDistanceToNow(new Date(new Date().getTime() - session.idle), {
                        addSuffix: true,
                        includeSeconds: true,
                      })}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </HTMLTable>
      </div>
    </div>
  )
}
