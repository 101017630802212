import { useCallback, useMemo } from "react"
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import * as THREE from "three"

import { AxisTravelLimit, MachineKind, MachineRecord, Point } from "src/client-axios"
import { useGetMachineTransform } from "src/store/machineUtils"
import { RootState } from "src/store/rootStore"
import { RAD2DEG } from "src/util/geometry/transforms"
import { activeOperationSelectors } from "./active"

// visibility
const setMachiningEnvelopeVisible = createAction<boolean>("machine/setMachiningEnvelopeVisible")

// coordinates
const setCoords = createAction<Record<string, number>>("kinematics/setCoords")
const updateCoords = createAction<Record<string, number>>("kinematics/updateCoords")
const setTempCoords = createAction<Record<string, number>>("kinematics/setTempCoords")
const updateTempCoords = createAction<Record<string, number>>("kinematics/updateTempCoords")

// active tool
const setToolId = createAction<string | undefined>("machine/setToolId")

const goHome = createAction<undefined>("machine/goHome")

export const machineActions = {
  setMachiningEnvelopeVisible,
  setCoords,
  updateCoords,
  updateTempCoords,
  setTempCoords,
  setToolId,
  goHome,
}

interface MachineStore {
  // visibility
  machiningEnvelopeVisible: boolean

  // coordinates
  coords: Record<string, number>
  tempCoords?: Record<string, number>
  travelLimits?: AxisTravelLimit

  // active tool
  toolId?: string
  drivenPoint?: Point
}

export const machineReducer = createReducer<MachineStore>(
  {
    // visibility
    machiningEnvelopeVisible: true,

    // coordinates
    coords: {},
    tempCoords: {},

    // active tool
    toolId: undefined,
  },

  builder => {
    // visibility
    builder.addCase(setMachiningEnvelopeVisible, (state, action) => {
      state.machiningEnvelopeVisible = action.payload
    })

    // coordinates
    builder.addCase(setCoords, (state, action) => {
      state.coords = action.payload
    })
    builder.addCase(setTempCoords, (state, action) => {
      state.tempCoords = action.payload
    })
    builder.addCase(updateCoords, (state, action) => {
      state.coords = { ...state.coords, ...action.payload }
    })
    builder.addCase(updateTempCoords, (state, action) => {
      state.tempCoords = { ...state.tempCoords, ...action.payload }
    })

    // active tool
    builder.addCase(setToolId, (state, action) => {
      state.toolId = action.payload
    })
  }
)

const selectMachiningEnvelopeVisible = (state: RootState): boolean => {
  return state.machine.machiningEnvelopeVisible
}

const selectReferencePositions = createSelector(
  activeOperationSelectors.selectActiveMachineRecord,
  record => record?.kinematics.referencePositions ?? []
)

const selectFirstReferencePosition = createSelector(
  activeOperationSelectors.selectActiveMachineRecord,
  record => record?.kinematics.referencePositions[0]?.coords ?? {}
)

const selectCoords = createSelector(
  [selectFirstReferencePosition, state => state.machine.coords],
  (frp, coords) => {
    return { ...frp, ...coords }
  }
)
const selectTempCoords = createSelector(
  [selectFirstReferencePosition, state => state.machine.tempCoords],
  (frp, tempCoords) => {
    return { ...frp, ...tempCoords }
  }
)

const selectToolId = (state: RootState): string | undefined => {
  return state.machine.toolId
}

const selectExceededTravelLimits = createSelector(
  [activeOperationSelectors.selectActiveMachineRecord, selectCoords],
  (machine, coords) => {
    const result: Record<string, { value: number; limits: AxisTravelLimit }> = {}
    const kinematics = machine?.kinematics
    if (!kinematics) return result

    kinematics.axes.forEach(axis => {
      const limits = kinematics.travelLimits[axis]
      const value = coords[axis]
      if (!limits || value === undefined) return
      if (value < limits.min) {
        result[axis] = { value, limits }
      } else if (value > limits.max) {
        result[axis] = { value, limits }
      }
    })
    return result
  }
)
const selectIsOutsideTravelLimits = createSelector(selectExceededTravelLimits, exceeds => {
  return Object.keys(exceeds).length > 0
})

export const machineSelectors = {
  selectMachiningEnvelopeVisible,
  selectCoords,
  selectTempCoords,
  selectToolId,
  selectExceededTravelLimits,
  selectIsOutsideTravelLimits,
  selectFirstReferencePosition,
  selectReferencePositions,
}

const _UP = new THREE.Vector3(0, 0, 1)
const _TRANSLATION = new THREE.Vector3()
const _QUATERNION = new THREE.Quaternion()
const _SCALE = new THREE.Vector3()

/**
 * Should return the direction relative to the unrotated coordinate system that will point up
 * after applying the specified coordinates for the kinematics
 */
export const useGetKinematicsUp = (
  machine: MachineRecord | undefined
): ((coords: Record<string, number>) => [number, number, number]) => {
  const getKinematicsRotation = useGetKinematicsRotation(machine)
  return useCallback(
    (coords: Record<string, number>) => {
      const quaternion = getKinematicsRotation(coords)
      const direction = new THREE.Vector3(0, 0, 1).applyQuaternion(quaternion)
      return [direction.x, direction.y, direction.z]
    },
    [getKinematicsRotation]
  )
}

export const useGetKinematicsRotation = (
  machine: MachineRecord | undefined
): ((coords: Record<string, number>) => THREE.Quaternion) => {
  const baseCoords = useMemo(() => {
    if (!machine) return {}
    return Object.fromEntries(machine.kinematics.axes.map(x => [x, 0]))
  }, [machine])
  const getMachineTransform = useGetMachineTransform(machine, "Attach", baseCoords)
  return useCallback(
    (coords: Record<string, number>) => {
      const fixturesTransform = getMachineTransform(coords)
      fixturesTransform.decompose(_TRANSLATION, _QUATERNION, _SCALE)
      _QUATERNION.invert()
      return _QUATERNION
    },
    [getMachineTransform]
  )
}

export const getExtraCoords = (
  normal: THREE.Vector3,
  machineKind: MachineKind,
  currentExtraCoods: Record<string, number>
): Record<string, number> => {
  if (machineKind === MachineKind.DoosanDvf5000)
    return getDoosanDvf5000ExtraCoords(normal, currentExtraCoods)
  if (machineKind === MachineKind.DoosanNhm6300)
    return getDoosanNhm6300ExtraCoords(normal, currentExtraCoods)
  if (machineKind === MachineKind.GrobG350T)
    return getGrobG350ExtraCoords(normal, currentExtraCoods)
  if (machineKind === MachineKind.GrobG350A)
    return getGrobG350ExtraCoords(normal, currentExtraCoods)
  if (machineKind === MachineKind.GrobG550) return getGrobG350ExtraCoords(normal, currentExtraCoods)
  return {}
}

const getDoosanDvf5000ExtraCoords = (
  normal: THREE.Vector3,
  currentExtraCoods: Record<string, number>
): Record<string, number> => {
  normal.normalize() // shouldn't be necessary, but I can't tell if it was
  _QUATERNION.setFromUnitVectors(normal, _UP)
  const s2 = _QUATERNION.y * _QUATERNION.y + _QUATERNION.x * _QUATERNION.x
  const c2 = _QUATERNION.w * _QUATERNION.w + _QUATERNION.z * _QUATERNION.z
  const s = Math.atan(_QUATERNION.z / _QUATERNION.w)
  const d = Math.atan2(_QUATERNION.x, _QUATERNION.y)
  const rz = s + d
  let ry: number
  if (Math.abs(c2) > 1e-8) {
    ry = 2 * Math.atan(Math.sqrt(s2 / c2))
  } else if (s2 < 0.5) {
    ry = 0
  } else {
    ry = Math.PI
  }

  let b = -ry * RAD2DEG
  let c = -rz * RAD2DEG

  b = parseFloat(b.toFixed(4))
  c = parseFloat(c.toFixed(4))

  b = ((b % 360) + 360) % 360 // ensure between 0 and 360
  b = ((b + 180) % 360) - 180 // ensure between -180 and 180
  c = ((c % 360) + 360) % 360 // ensure between 0 and 360

  // Ensure no more than a 180° tilt
  if (b < -90) {
    b += 180
  } else if (b > 90) {
    b -= 180
  }

  // Ensure within travel limits of DVF5000
  if (b < -30) {
    b = -b
    c = (c + 180) % 360
  }

  // Use C=0 when B=0; this is the most likely orientation to get us
  if (b === 0) {
    c = 0
  }

  // Set c to be the nearest multiple of 360 to the current value
  const currentC = currentExtraCoods.c ?? 0
  const nRotations = Math.round((currentC - c) / 360)
  c += 360 * nRotations

  return { b, c }
}

const getDoosanNhm6300ExtraCoords = (
  normal: THREE.Vector3,
  currentExtraCoods: Record<string, number>
): Record<string, number> => {
  const { a, b } = getGrobG350ExtraCoords(normal, currentExtraCoods)

  const epsilon = 0.001
  if (Math.abs(a) > epsilon) {
    throw new Error("Unable to achieve desired orientation using NHM rotary axes")
  }

  return { b }
}

const getGrobG350ExtraCoords = (
  normal: THREE.Vector3,
  currentExtraCoods: Record<string, number>
): Record<string, number> => {
  normal.normalize() // shouldn't be necessary, but I can't tell if it was
  const am90Normal = new THREE.Vector3(normal.x, normal.z, normal.y)

  _QUATERNION.setFromUnitVectors(am90Normal, _UP)
  if (_QUATERNION.w === 0) {
    return { a: -90.0, b: 0.0 }
  }

  const s2 = _QUATERNION.y * _QUATERNION.y + _QUATERNION.x * _QUATERNION.x
  const c2 = _QUATERNION.w * _QUATERNION.w + _QUATERNION.z * _QUATERNION.z

  const s = Math.atan(_QUATERNION.z / _QUATERNION.w)
  const d = Math.atan2(_QUATERNION.y, _QUATERNION.x)
  const rz = s + d

  let rx: number
  if (Math.abs(c2) > 1e-8) {
    rx = 2 * Math.atan(Math.sqrt(s2 / c2))
  } else if (s2 < 0.5) {
    rx = 0
  } else {
    rx = Math.PI
  }

  let b = -rz * RAD2DEG
  b = parseFloat(b.toFixed(4))
  b = ((b % 360) + 360) % 360 // ensure between 0 and 360

  let a = rx * RAD2DEG
  a = parseFloat(a.toFixed(4))
  a = ((a % 360) + 360) % 360 // ensure between 0 and 360
  a = ((a + 180) % 360) - 180 // ensure between -180 and 180

  // Ensure no more than a 180° tilt
  if (a === 0.0) {
    b = 0.0
  }

  a -= 90
  if (a < -135) {
    a += 180
    b = (b + 180) % 360
  }

  // Set b to be the nearest multiple of 360 to the current value
  const currentB = currentExtraCoods.b ?? 0
  const nRotations = Math.round((currentB - b) / 360)
  b += 360 * nRotations

  return { a, b }
}
