import * as THREE from "three"

import { SceneClickData } from "./useSceneClickEventHandler"

export const getClickedIntersection = (
  clickData: SceneClickData
): THREE.Intersection | undefined => {
  const { raycaster, mouse, camera, scene } = clickData

  raycaster.setFromCamera(mouse, camera)

  return getRaycasterIntersection(raycaster, scene)
}

export const getIntersection = (
  clickData: SceneClickData,
  origin: THREE.Vector3,
  direction: THREE.Vector3
): THREE.Intersection | undefined => {
  const { raycaster, scene } = clickData
  raycaster.set(origin, direction)

  return getRaycasterIntersection(raycaster, scene)
}

const getRaycasterIntersection = (
  raycaster: THREE.Raycaster,
  scene: THREE.Scene
): THREE.Intersection | undefined => {
  const intersects = raycaster.intersectObjects(scene.children, true).filter(inter => {
    if (inter.object instanceof THREE.Mesh) {
      const material = inter.object.material as THREE.Material
      if (material.opacity !== undefined && material.opacity < 1) {
        return false
      }
    }

    return (
      inter.object.type !== "LineSegments" &&
      inter.object.type !== "GridHelper" &&
      inter.object.type !== "Points" &&
      inter.object.type !== "TransformControlsPlane" &&
      inter.object.visible === true &&
      !inter.object.userData.ignoreIntersection
    )
  })

  let target: THREE.Intersection | undefined
  for (const intersection of intersects) {
    if (!isObjectVisible(intersection.object)) continue
    target = intersection

    // Treat clicking on transform controls the same as clicking on nothing
    let itemToCheck: THREE.Object3D | null = target?.object
    while (itemToCheck) {
      if (itemToCheck.userData.isTransformControls) {
        return undefined
      }
      itemToCheck = itemToCheck.parent
    }

    break
  }

  return target
}

const isObjectVisible = (object: THREE.Object3D): boolean => {
  let visible = true

  let maybeObject: THREE.Object3D | null = object

  while (maybeObject) {
    if (!maybeObject.visible) {
      visible = false
      break
    }
    maybeObject = maybeObject.parent
  }

  return visible
}
