import React, { FC, useCallback } from "react"
import { useSelector } from "react-redux"
import { FormGroup } from "@blueprintjs/core"

import { FixtureStock } from "src/client-axios"
import { ProductStockSelector } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/ProductStockSelector/ProductStockSelector"
import { ProductStockTransform } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/ProductStockTransform/ProductStockTransform"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { useUpdateInputStockMutation } from "src/graphql/generated"
import { fixturesSelectors } from "src/store/config/fixtures"
import { RootState } from "src/store/rootStore"

import styles from "./FixtureStockConfigPanel.module.css"

export const FixtureStockConfigPanel: FC<{
  planId: string
  operationIdx: number
  inputStock: FixtureStock
  locked?: boolean
  forCard?: boolean
}> = ({ planId, operationIdx, inputStock, locked, forCard }) => {
  const fixtureChoiceConfig = useSelector((state: RootState) =>
    fixturesSelectors.selectFixtureRecord(
      state,
      inputStock?.kind === "fixture" ? inputStock.fixtureId : undefined
    )
  )

  const [updateInputStock] = useUpdateInputStockMutation()

  const setStockParam = useCallback(
    (param: number) => {
      if (inputStock?.kind === "fixture") {
        const fixture = {
          transform: inputStock.transform,
          fixtureId: inputStock.fixtureId,
          parameter: param,
        }
        updateInputStock({
          variables: {
            planId,
            operationIdx,
            stock: {
              fixture,
            },
          },
        })
      }
    },
    [planId, operationIdx, inputStock, updateInputStock]
  )

  return (
    <div className={styles.container}>
      <ProductStockSelector
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        // disabled={locked}
        locked={locked}
      />

      {fixtureChoiceConfig?.parameter && (
        <FormGroup
          label={`${fixtureChoiceConfig.parameter.label}:`}
          labelInfo={`(${fixtureChoiceConfig.parameter.min}mm - ${fixtureChoiceConfig.parameter.max}mm)`}
        >
          <FormulaInput
            commitOnBlur={true}
            onValueChange={value => setStockParam(value)}
            value={inputStock?.parameter ?? fixtureChoiceConfig.parameter.min}
            min={fixtureChoiceConfig.parameter.min}
            max={fixtureChoiceConfig.parameter.max}
          />
        </FormGroup>
      )}

      {!forCard && (
        <ProductStockTransform
          planId={planId}
          operationIdx={operationIdx}
          transform={inputStock.transform}
          locked={locked}
        />
      )}
    </div>
  )
}
