import { cos, sin } from "mathjs"
import * as THREE from "three"
import { v4 as uuidv4 } from "uuid"

import {
  BoreFeature,
  BoreFeatureKindEnum,
  BossFeature,
  BossFeatureKindEnum,
  ExplicitMove,
  ExplicitMoveKindEnum,
  MultiLevelBoreFeature,
  MultiLevelBossFeature,
  MultiLevelBossFeatureKindEnum,
  Point,
  PointsFeatureMoveKindEnum,
  PointsFeaturePointKindEnum,
  RtcpPosition,
  SphereFeatureKindEnum,
  WcsBoreBossKindEnum,
  WcsBossWebParams,
  WcsEdgeSurfaceKindEnum,
  WcsProbingMoveKindEnum,
  WcsSurfaceDirection,
  WcsWebPocketKindEnum,
} from "src/client-axios"
import { DEFAULT_MOVE_APPROACH_OFFSET } from "src/store/cam/probing"
import { ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"

export interface StepArc {
  line: THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
  stepId: string
  variant: PointVariant
  z: number
  quaternion: THREE.Quaternion
  key: string
  step: BoreFeature | BossFeature
}

interface StepPoint {
  point: THREE.Vector3
  stepId: string
  variant: PointVariant
}

export enum PointVariant {
  Contact,
  Approach,
  Manual,
  Automatic,
  Arc,
}

type BoreOrBossFeature<
  T extends MultiLevelBoreFeature | MultiLevelBossFeature
> = T extends MultiLevelBoreFeature ? BoreFeature : BossFeature

export const getMultiLevelBoreBossSteps = <T extends MultiLevelBoreFeature | MultiLevelBossFeature>(
  step: T,
  getKinematicsRotation: (coords: Record<string, number>) => THREE.Quaternion
): Array<BoreOrBossFeature<T> | ExplicitMove> => {
  const approach = step.approach
  const position = step.position

  const approachPoint = new THREE.Vector3(approach.point.x, approach.point.y, approach.point.z)
  const probePoint = new THREE.Vector3(position.point.x, position.point.y, position.point.z)
  const extraCoords = position.extraCoords

  const isMultiLevelBoss = step.kind === MultiLevelBossFeatureKindEnum.MultiLevelBossFeature
  const kind = isMultiLevelBoss ? BossFeatureKindEnum.BossFeature : BoreFeatureKindEnum.BoreFeature

  const q = getKinematicsRotation(extraCoords)
  const offsetDirection = new THREE.Vector3(0, sin(0), cos(0))
  offsetDirection.applyQuaternion(q)

  const subSteps: Array<BoreOrBossFeature<T> | ExplicitMove> = []

  for (let i = 0; i < step.nLevels; ++i) {
    const subStepApproachPoint =
      isMultiLevelBoss || i === 0 ? approachPoint : subSteps[i - 1].position.point
    const subStepProbePoint = probePoint
      .clone()
      .add(offsetDirection.clone().multiplyScalar(-step.levelSpacing * i))

    const id = uuidv4()
    const subStep = {
      id,
      kind,
      diameter: step.diameter,
      approach: { point: subStepApproachPoint, extraCoords } as RtcpPosition,
      position: { point: subStepProbePoint, extraCoords } as RtcpPosition,
      nPoints: step.nPoints,
      approachDistance: step.approachDistance,
      searchDistance: step.searchDistance,
      featureWeight: step.featureWeight,
      touchType: step.touchType,
    } as BoreOrBossFeature<T>
    subSteps.push(subStep)
  }

  subSteps.push({
    id: uuidv4(),
    kind: ExplicitMoveKindEnum.ExplicitMove,
    position: approach,
  })

  return subSteps
}

export const getStepPoints = (
  getKinematicsRotation: (coords: Record<string, number>) => THREE.Quaternion,
  step: ProbingStep | WcsProbingStep,
  probeSphereDiameter: number,
  defaultApproachDistance: number
): StepPoint[] => {
  const stepId = step.id

  switch (step.kind) {
    case WcsProbingMoveKindEnum.WcsExplicitMove: {
      const { x, y, z } = step.point
      const stepPoint = {
        point: new THREE.Vector3(x, y, z),
        stepId,
        variant: step.generated ? PointVariant.Automatic : PointVariant.Manual,
        approach: DEFAULT_MOVE_APPROACH_OFFSET,
      }
      return [stepPoint]
    }

    case WcsWebPocketKindEnum.WebPocket: {
      const stepPoints = []
      if (step.xSize != null) {
        stepPoints.push(...xCenterCyclePoints(stepId, step.start, step.xSize, step.web))
      }
      if (step.ySize != null) {
        stepPoints.push(...yCenterCyclePoints(stepId, step.start, step.ySize, step.web))
      }
      return stepPoints
    }

    case WcsBoreBossKindEnum.BoreBoss: {
      const stepPoints = []
      stepPoints.push(...xCenterCyclePoints(stepId, step.start, step.diameter, step.boss))
      stepPoints.push(...yCenterCyclePoints(stepId, step.start, step.diameter, step.boss))
      return stepPoints
    }

    case WcsEdgeSurfaceKindEnum.EdgeSurface: {
      const { x, y, z } = step.contact
      const offset = step.offset + probeSphereDiameter / 2
      switch (step.direction) {
        case WcsSurfaceDirection.XNeg: {
          return [
            {
              stepId,
              point: new THREE.Vector3(x + offset, y, z),
              variant: PointVariant.Approach,
            },
            { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
            {
              stepId,
              point: new THREE.Vector3(x + offset, y, z),
              variant: PointVariant.Approach,
            },
          ]
        }
        case WcsSurfaceDirection.YNeg: {
          return [
            {
              stepId,
              point: new THREE.Vector3(x, y + offset, z),
              variant: PointVariant.Approach,
            },
            { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
            {
              stepId,
              point: new THREE.Vector3(x, y + offset, z),
              variant: PointVariant.Approach,
            },
          ]
        }
        case WcsSurfaceDirection.ZNeg: {
          return [
            {
              stepId,
              point: new THREE.Vector3(x, y, z + offset),
              variant: PointVariant.Approach,
            },
            { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
            {
              stepId,
              point: new THREE.Vector3(x, y, z + offset),
              variant: PointVariant.Approach,
            },
          ]
        }
        case WcsSurfaceDirection.XPos: {
          return [
            {
              stepId,
              point: new THREE.Vector3(x - offset, y, z),
              variant: PointVariant.Approach,
            },
            { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
            {
              stepId,
              point: new THREE.Vector3(x - offset, y, z),
              variant: PointVariant.Approach,
            },
          ]
        }
        case WcsSurfaceDirection.YPos: {
          return [
            {
              stepId,
              point: new THREE.Vector3(x, y - offset, z),
              variant: PointVariant.Approach,
            },
            { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
            {
              stepId,
              point: new THREE.Vector3(x, y - offset, z),
              variant: PointVariant.Approach,
            },
          ]
        }
      }
      return []
    }

    case ExplicitMoveKindEnum.ExplicitMove: {
      const { x, y, z } = step.position.point
      const stepPoint = {
        point: new THREE.Vector3(x, y, z),
        stepId,
        variant: step.generated ? PointVariant.Automatic : PointVariant.Manual,
      }
      return [stepPoint]
    }

    case PointsFeatureMoveKindEnum.PointsFeatureMove: {
      const { x, y, z } = step.position.point
      const stepPoint = {
        point: new THREE.Vector3(x, y, z),
        stepId,
        variant: step.generated ? PointVariant.Automatic : PointVariant.Manual,
      }
      return [stepPoint]
    }

    case PointsFeaturePointKindEnum.PointsFeaturePoint: {
      const { x, y, z } = step.position.point
      const approach = step.normalApproachDistance
      const offset = approach + probeSphereDiameter / 2

      const offsetNormal = {
        x: step.normal.x * offset,
        y: step.normal.y * offset,
        z: step.normal.z * offset,
      }
      const normalPoint = {
        x: x + offsetNormal.x,
        y: y + offsetNormal.y,
        z: z + offsetNormal.z,
      }
      return [
        {
          stepId,
          point: new THREE.Vector3(normalPoint.x, normalPoint.y, normalPoint.z),
          variant: PointVariant.Approach,
        },
        { stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Contact },
        {
          stepId,
          point: new THREE.Vector3(normalPoint.x, normalPoint.y, normalPoint.z),
          variant: PointVariant.Approach,
        },
      ]
    }

    case BoreFeatureKindEnum.BoreFeature:
    case BossFeatureKindEnum.BossFeature: {
      const { x: ax, y: ay, z: az } = step.approach.point
      const { x, y, z } = step.position.point
      const approach = new THREE.Vector3(ax, ay, az)
      const center = new THREE.Vector3(x, y, z)

      const approachStepPoint = {
        point: approach,
        stepId,
        variant: PointVariant.Approach,
      }

      const radius = step.diameter / 2
      const probeRadius = probeSphereDiameter / 2
      const approach2Distance = getApproach2Distance(probeRadius, step, defaultApproachDistance)

      const q = getKinematicsRotation(step.position.extraCoords)

      let angle = 0
      let v = new THREE.Vector3(cos(angle), sin(angle), 0)
      v.applyQuaternion(q)

      const approachStepPoint2 = {
        point: approach.clone().add(v.multiplyScalar(approach2Distance)),
        stepId,
        variant: PointVariant.Approach,
      }

      const nPoints = step.nPoints ?? 6

      const arcPoints = getArcPoints(
        stepId,
        approach2Distance,
        radius,
        center,
        getKinematicsRotation,
        step.position.extraCoords,
        nPoints
      )

      angle = ((nPoints - 1) * 2 * Math.PI) / nPoints
      v = new THREE.Vector3(cos(angle), sin(angle), 0)
      v.applyQuaternion(q)

      const approachStepPoint3 = {
        point: approach.clone().add(v.multiplyScalar(approach2Distance)),
        stepId,
        variant: PointVariant.Approach,
      }

      return [
        approachStepPoint,
        approachStepPoint2,
        ...arcPoints,
        approachStepPoint3,
        approachStepPoint,
      ]
    }

    case SphereFeatureKindEnum.SphereFeature: {
      // TODO: Add the contact touches
      const { x: ax, y: ay, z: az } = step.approach.point
      const { x, y, z } = step.position.point
      const approach = new THREE.Vector3(ax, ay, az)
      const center = new THREE.Vector3(x, y, z)
      const approachStepPoint = {
        point: approach,
        stepId,
        variant: PointVariant.Approach,
      }
      // TODO: Make the center an approach point, not a contact point
      const centerStepPoint = {
        point: center,
        stepId,
        variant: PointVariant.Contact,
      }
      return [approachStepPoint, centerStepPoint, approachStepPoint]
    }
  }
  return []
}

export const getStepArcs = (
  getKinematicsRotation: (coords: Record<string, number>) => THREE.Quaternion,
  step: ProbingStep | WcsProbingStep,
  probeSphereDiameter: number,
  defaultApproachDistance: number
): StepArc[] => {
  const stepId = step.id

  if (
    !(
      step.kind === BoreFeatureKindEnum.BoreFeature || step.kind === BossFeatureKindEnum.BossFeature
    )
  )
    return []
  const probeRadius = probeSphereDiameter / 2

  const nPoints = step.nPoints ?? 6
  const approach2Distance = getApproach2Distance(probeRadius, step, defaultApproachDistance)

  const arcs: StepArc[] = []

  const q = getKinematicsRotation(step.position.extraCoords)

  for (let i = 0; i < nPoints - 1; i++) {
    const startAngle = (i * 2 * Math.PI) / nPoints
    const endAngle = ((i + 1) * 2 * Math.PI) / nPoints

    const { x, y, z } = step.position.point
    const v = new THREE.Vector3(x, y, z)
    // v.applyQuaternion(q)

    const curve = new THREE.EllipseCurve(
      0, // xCenter
      0, // yCenter
      approach2Distance, // xRadius
      approach2Distance, // yRadius
      startAngle, // StartAngle
      endAngle, // EndAngle
      false, // Clockwise
      0 // Rotation
    )

    const points = curve.getPoints(50).map(point => {
      return new THREE.Vector3(point.x, point.y, 0).applyQuaternion(q).add(v)
    })

    const geometry = new THREE.BufferGeometry().setFromPoints(points)

    const material = new THREE.LineBasicMaterial({ color: 0xdddd00 })

    const ellipse = new THREE.Line(geometry, material)
    const key = `${stepId}-${i}`

    arcs.push({
      line: ellipse,
      stepId,
      variant: PointVariant.Approach,
      z: step.position.point.z,
      quaternion: q,
      key,
      step,
    })
  }
  return arcs
}

const getApproach2Distance = (
  probeRadius: number,
  step: BoreFeature | BossFeature,
  defaultApproachDistance: number
) => {
  const radius = step.diameter / 2
  let offset = step.approachDistance ?? defaultApproachDistance
  let approach2Distance = radius
  if (step.kind === BoreFeatureKindEnum.BoreFeature) {
    offset = Math.min(offset, 0.9 * (radius - probeRadius))
    approach2Distance -= probeRadius + offset
  } else {
    approach2Distance += probeRadius + offset
  }
  approach2Distance = Math.max(0.0, approach2Distance)
  return approach2Distance
}

export const getThicknessApproach2Distance: (
  probeRadius: number,
  approachDistance: number,
  defaultApproachDistance: number,
  radius: number
) => number = (
  probeRadius: number,
  approachDistance: number,
  defaultApproachDistance: number,
  radius: number
) => {
  let offset = approachDistance ?? defaultApproachDistance
  let approach2Distance = radius
  offset = Math.min(offset, 0.9 * (radius - probeRadius))
  approach2Distance -= probeRadius + offset

  approach2Distance = Math.max(0.0, approach2Distance)
  return approach2Distance
}

export const getThicknessApproach3Distance: (
  probeRadius: number,
  approachDistance: number,
  defaultApproachDistance: number,
  radius: number
) => number = (
  probeRadius: number,
  approachDistance: number,
  defaultApproachDistance: number,
  radius: number
) => {
  const offset = approachDistance ?? defaultApproachDistance
  let approach2Distance = radius

  approach2Distance += probeRadius + offset
  approach2Distance = Math.max(0.0, approach2Distance)
  return approach2Distance
}

const yCenterCyclePoints = (
  stepId: string,
  start: Point,
  width: number,
  params?: WcsBossWebParams
): StepPoint[] => {
  const { x, y } = start
  const points = xCenterCyclePoints(stepId, start, width, params)
  points.forEach(stepPoint => {
    const newX = x + stepPoint.point.y - y
    const newY = y + stepPoint.point.x - x
    stepPoint.point.setX(newX)
    stepPoint.point.setY(newY)
  })
  return points
}

const xCenterCyclePoints = (
  stepId: string,
  start: Point,
  width: number,
  params?: WcsBossWebParams
): StepPoint[] => {
  const { x, y, z } = start

  const points = [{ stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Approach }]

  if (params) {
    points.push(
      {
        stepId,
        point: new THREE.Vector3(x - width / 2 - params.clearance, y, z),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x - width / 2 - params.clearance, y, z - params.depth),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x - width / 2, y, z - params.depth),
        variant: PointVariant.Contact,
      },
      {
        stepId,
        point: new THREE.Vector3(x - width / 2 - params.clearance, y, z - params.depth),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x - width / 2 - params.clearance, y, z),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2 + params.clearance, y, z),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2 + params.clearance, y, z - params.depth),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2, y, z - params.depth),
        variant: PointVariant.Contact,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2 + params.clearance, y, z - params.depth),
        variant: PointVariant.Approach,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2 + params.clearance, y, z),
        variant: PointVariant.Approach,
      }
    )
  } else {
    points.push(
      {
        stepId,
        point: new THREE.Vector3(x - width / 2, y, z),
        variant: PointVariant.Contact,
      },
      {
        stepId,
        point: new THREE.Vector3(x + width / 2, y, z),
        variant: PointVariant.Contact,
      }
    )
  }

  points.push({ stepId, point: new THREE.Vector3(x, y, z), variant: PointVariant.Approach })

  return points
}

export const getArcPoints: (
  stepId: string,
  approach2Distance: number,
  radius: number,
  center: THREE.Vector3,
  getKinematicsRotation: (coords: Record<string, number>) => THREE.Quaternion,
  coords: {
    [key: string]: number
  },
  nPoints: number
) => {
  point: THREE.Vector3
  stepId: string
  variant: PointVariant
}[] = (
  stepId: string,
  approach2Distance: number,
  radius: number,
  center: THREE.Vector3,
  getKinematicsRotation: (coords: Record<string, number>) => THREE.Quaternion,
  coords: {
    [key: string]: number
  },
  nPoints: number
) => {
  const q = getKinematicsRotation(coords)

  const points = []

  for (let i = 0; i < nPoints; i++) {
    const angle = (i * 2 * Math.PI) / nPoints
    const v = new THREE.Vector3(cos(angle), sin(angle), 0)
    v.applyQuaternion(q)

    const arcPoint = {
      point: center.clone().add(v.clone().multiplyScalar(approach2Distance)),
      stepId,
      variant: PointVariant.Arc,
    }

    const contactPoint = {
      point: center.clone().add(v.clone().multiplyScalar(radius)),
      stepId,
      variant: PointVariant.Contact,
    }

    points.push(...[arcPoint, contactPoint, arcPoint])
  }
  return points
}

export const isFeatureDivider = (step: ProbingStep | WcsProbingStep): boolean => {
  return !(
    step?.kind === WcsProbingMoveKindEnum.WcsExplicitMove ||
    step?.kind === ExplicitMoveKindEnum.ExplicitMove ||
    step?.kind === PointsFeaturePointKindEnum.PointsFeaturePoint
  )
}
