import React, { FC, useEffect } from "react"
import { useParams } from "react-router"
import { NonIdealState, Spinner } from "@blueprintjs/core"

import { useApi } from "src/hooks/useApi"

export const Docs: FC = () => {
  const { redirectUri: redirectParam }: { redirectUri?: string } = useParams()

  let redirectUri = redirectParam ?? "/procedures/overview"

  if (!redirectUri.startsWith("/")) {
    redirectUri = "/" + redirectUri
  }

  redirectUri += window.location.search

  const { docsApi } = useApi()

  useEffect(() => {
    docsApi.urlFor_docsProxy(redirectUri).then(val => {
      window.location.href = val.toString()
    })
  }, [docsApi, redirectUri])

  return (
    <NonIdealState
      title={"Authenticating..."}
      icon={<Spinner />}
      description={"You should be redirected to the docs shortly"}
    />
  )
}
