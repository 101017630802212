import React, { FC, useMemo } from "react"
import * as THREE from "three"

import { PathSection } from "../../../generated/bindings/PathSection"
import { Point2 } from "../../../generated/bindings/Point2"
import { ToolShape } from "../../../generated/bindings/ToolShape"

const ARC_POINTS_PER_MM = 2.0
const MAX_ARC_DEGREES_PER_POINT = 15.0
const SHARP_ANGLE_THRESHOLD = (30.0 * Math.PI) / 180
const SEGMENT_COUNT = 64

export const colorMaterial = (color: string) => ({
  transparent: true,
  color: new THREE.Color(color),
  opacity: 1.0,
})

export const TRANSPARENT_MATERIAL = {
  depthWrite: false,
}

function isPt(section: PathSection): section is Point2 {
  return "x" in section
}

/**
 * The flute will not be displayed properly if the provided ToolShape does not have
 * its profile start at z=0 with Z increasing
 */
export const ToolScene: FC<{
  tool: ToolShape
  alternate: boolean
  fluteColor?: string
  shankColor?: string
  sharedMaterial?: THREE.MeshStandardMaterialParameters
}> = ({
  tool,
  alternate = false,
  fluteColor = "#ff9900",
  shankColor = "#c0c0c0",
  sharedMaterial = {
    opacity: 1.0,
    polygonOffset: true,
    polygonOffsetFactor: 1,
    transparent: false,
    metalness: 0.925,
    roughness: 0.7,
    flatShading: true,
  },
}) => {
  const profile = alternate && tool.alternate ? tool.alternate : tool.profile

  const fluteLength = tool.flute_length ?? 0

  const [flutePoints, shankPoints, circlePoints] = useMemo(() => {
    const flutePoints: THREE.Vector2[] = []
    const shankPoints: THREE.Vector2[] = []
    const circlePoints: THREE.Vector2[] = []

    const pushPoint = (point: THREE.Vector2): void => {
      if (point.y <= fluteLength) {
        flutePoints.push(point)
      } else {
        shankPoints.push(point)
      }
    }

    for (let i = 0; i < profile.length; i++) {
      const current = profile[i]
      const arcStart = profile[i - 1]
      const arcEnd = profile[i + 1]

      if (isPt(current)) {
        pushPoint(new THREE.Vector2(current.x, current.z))
      } else if (i > 0 && isPt(arcStart) && isPt(arcEnd)) {
        const arc = current

        const startAngle = Math.atan2(arcStart.z - arc.center.z, arcStart.x - arc.center.x)
        const endAngle = Math.atan2(arcEnd.z - arc.center.z, arcEnd.x - arc.center.x)

        let arcAngleDelta = ((Math.PI + endAngle - startAngle) % (2 * Math.PI)) - Math.PI

        if (arc.direction === "CW" && arcAngleDelta > 0) {
          arcAngleDelta -= 2 * Math.PI
        } else if (arc.direction === "CCW" && arcAngleDelta < 0) {
          arcAngleDelta += 2 * Math.PI
        }

        const radius = getRadius(arc.center, arcStart)
        // TODO: Use a better algorithm for determining number of points based on max linear and angular deviation
        const nArcPoints = Math.max(
          0,
          Math.round(Math.abs(arcAngleDelta) * radius * ARC_POINTS_PER_MM) - 1, // length
          Math.floor(Math.abs(arcAngleDelta) / ((MAX_ARC_DEGREES_PER_POINT * Math.PI) / 180))
        )

        const center = new THREE.Vector2(arc.center.x, arc.center.z)
        for (let i = 0; i < nArcPoints; i++) {
          const angle = startAngle + (arcAngleDelta * (i + 1)) / (nArcPoints + 1)
          const arcPoint = center
            .clone()
            .add(new THREE.Vector2(Math.cos(angle), Math.sin(angle)).multiplyScalar(radius))
          pushPoint(arcPoint)
        }
      } else {
        console.warn(
          `Found weird setup for tool profile at index ${i}, expecting an \`arc\` between two \`pt\`s :`,
          profile
        )
      }
    }

    // Close the top surface by adding a point connecting the profile back to the Z axis
    if (profile.length > 0) {
      const lastSection = profile[profile.length - 1]
      if (isPt(lastSection) && lastSection.x !== 0) {
        pushPoint(new THREE.Vector2(0, lastSection.z))
      }
    }

    if (fluteLength > 0 && flutePoints.length > 0 && shankPoints.length > 0) {
      const lastFlutePoint = flutePoints[flutePoints.length - 1]
      const firstShankPoint = shankPoints[0]
      const proportion = (fluteLength - lastFlutePoint.y) / (firstShankPoint.y - lastFlutePoint.y)
      const separator = lastFlutePoint.clone().lerp(firstShankPoint, proportion)
      circlePoints.push(separator)
      flutePoints.push(separator)
      shankPoints.unshift(separator)
    }

    const handleCirclePoint = (p: THREE.Vector2, i: number, allPoints: THREE.Vector2[]) => {
      if (i === 0 || i === allPoints.length - 1) return

      const previous = allPoints[i - 1]
      const next = allPoints[i + 1]
      const angle = getAngleDiff(previous, p, next)
      if (angle !== undefined && Math.abs(angle - Math.PI) > SHARP_ANGLE_THRESHOLD) {
        circlePoints.push(p)
      }
    }
    flutePoints.forEach(handleCirclePoint)
    shankPoints.forEach(handleCirclePoint)

    return [flutePoints, shankPoints, circlePoints]
  }, [profile, fluteLength])

  const [fluteMesh, shankMesh] = useMemo(() => {
    const fluteGeometry = new THREE.LatheGeometry(flutePoints, SEGMENT_COUNT)
    const shankGeometry = new THREE.LatheGeometry(shankPoints, SEGMENT_COUNT)
    const fluteMaterial = new THREE.MeshStandardMaterial({
      ...sharedMaterial,
      color: new THREE.Color(fluteColor),
    })
    const shankMaterial = new THREE.MeshStandardMaterial({
      ...sharedMaterial,
      color: new THREE.Color(shankColor),
    })
    return [
      new THREE.Mesh(fluteGeometry, fluteMaterial),
      new THREE.Mesh(shankGeometry, shankMaterial),
    ]
  }, [flutePoints, shankPoints, fluteColor, shankColor, sharedMaterial])

  const toolProfile = useMemo(() => {
    const leftVectors: THREE.Vector3[] = []
    const rightVectors: THREE.Vector3[] = []
    const addPoint = (
      point: THREE.Vector2,
      index: number,
      unrepeatedIndices: Set<number>
    ): void => {
      const vLeft = new THREE.Vector3(-point.x, point.y, 0)
      const vRight = new THREE.Vector3(point.x, point.y, 0)
      leftVectors.push(vLeft)
      rightVectors.push(vRight)
      if (!unrepeatedIndices.has(index)) {
        // Repeat the point as the start of the next line segment
        leftVectors.push(vLeft)
        rightVectors.push(vRight)
      }
    }

    const unrepeatedIndices = new Set([0, flutePoints.length + shankPoints.length - 1])
    flutePoints.forEach((p, i) => addPoint(p, i, unrepeatedIndices))
    shankPoints.forEach((p, i) => addPoint(p, i + flutePoints.length, unrepeatedIndices))
    const toolProfileVectors = [...leftVectors.reverse(), ...rightVectors]

    return new THREE.BufferGeometry().setFromPoints(toolProfileVectors)
  }, [flutePoints, shankPoints])

  const circles = useMemo(() => {
    return circlePoints.map(separator => {
      const fluteDividerVectors = []
      const segmentCount = SEGMENT_COUNT
      for (let i = 0; i <= segmentCount; i++) {
        const theta = (i / segmentCount) * Math.PI * 2
        fluteDividerVectors.push(
          new THREE.Vector3(
            Math.cos(theta) * separator.x,
            separator.y,
            Math.sin(theta) * separator.x
          )
        )
      }
      const geometry = new THREE.BufferGeometry().setFromPoints(fluteDividerVectors)
      const material = new THREE.LineBasicMaterial({
        ...colorMaterial("#000000"),
        ...TRANSPARENT_MATERIAL,
      })
      const circleObj = new THREE.Line(geometry, material)
      circleObj.userData = { ignoreIntersection: true }
      return circleObj
    })
  }, [circlePoints])

  return (
    <group rotation={[Math.PI / 2, 0, 0]}>
      <primitive object={shankMesh} />
      <primitive object={fluteMesh} />
      {circles.map((circle, i) => (
        <primitive key={`circle-${i}`} object={circle} />
      ))}
      <lineSegments geometry={toolProfile}>
        <lineBasicMaterial {...colorMaterial("#000000")} {...TRANSPARENT_MATERIAL} />
      </lineSegments>
    </group>
  )
}

const getRadius = (center: Point2, point: Point2) => {
  return Math.sqrt(Math.pow(center.x - point.x, 2) + Math.pow(center.z - point.z, 2))
}

const getAngleDiff = (
  start: THREE.Vector2,
  middle: THREE.Vector2,
  end: THREE.Vector2
): number | undefined => {
  const dy1 = start.y - middle.y
  const dy2 = end.y - middle.y
  const dx1 = start.x - middle.x
  const dx2 = end.x - middle.x
  if ((dy1 === 0 && dx1 === 0) || (dy2 === 0 && dx2 === 0)) {
    // Must have positive deltas or angle is undefined
    return undefined
  }

  const startAngle = Math.atan2(dy1, dx1)
  const endAngle = Math.atan2(dy2, dx2)
  return (2 * Math.PI + endAngle - startAngle) % (2 * Math.PI)
}
