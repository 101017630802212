import React, { FC } from "react"

import useMountCamera from "../hooks/useMountCamera"
import WrappedLighting from "./WrappedLighting"

const AppStage: FC = ({ children }) => {
  useMountCamera()

  return (
    <>
      {children}
      <WrappedLighting />
    </>
  )
}

export default AppStage
