import { all, create } from "mathjs"
import * as THREE from "three"

const config = {}
const math = create(all, config)

const mathEvaluate = math.evaluate
if (!mathEvaluate) throw new Error("math.evaluate undefined")

const _EVALUATED = new THREE.Matrix4()

// TODO: Delete this when it stops being necessary after we resolve the issues it is preventing
const _DEFAULTS = { x: 0, y: 0, z: 0, a: 0, b: 0, c: 0, w: 0, c2: 0 }

export const evaluateMatrix = (
  matrixExpression: string,
  variables: Record<string, number>
): THREE.Matrix4 => {
  const value = mathEvaluate(matrixExpression.trim(), { ..._DEFAULTS, ...variables })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return _EVALUATED.set(...value._data.flatMap(v => v))
}

const _EVALUATED_TRANSFORM = new THREE.Matrix4()
export function evaluateComposedTransforms(
  transforms: Array<string>,
  variables: Record<string, number>
): THREE.Matrix4 {
  _EVALUATED_TRANSFORM.identity()
  transforms.forEach(formula => _EVALUATED_TRANSFORM.multiply(evaluateMatrix(formula, variables)))
  return _EVALUATED_TRANSFORM.clone()
}
