import React, { FC, useMemo } from "react"
import * as THREE from "three"

import { BoxShape } from "../../generated/bindings/BoxShape"
import { CylinderShape } from "../../generated/bindings/CylinderShape"
import { Geometry } from "../../generated/bindings/Geometry"
import { ToolScene } from "./Tool/ToolScene"
import { GltfUrlComponent } from "./GltfUrlComponent"
import { Obj3dCallback } from "./types"

export const OpSceneGeometry: FC<{
  geometry?: Geometry
  meshMaterial: THREE.Material
  wireMaterial: THREE.Material
  obj3dCallback?: Obj3dCallback
}> = ({ geometry, meshMaterial, wireMaterial, obj3dCallback }) => {
  switch (geometry?.kind) {
    case "box":
      return (
        <OpSceneBox
          box={geometry}
          meshMaterial={meshMaterial}
          wireMaterial={wireMaterial}
          obj3dCallback={obj3dCallback}
        />
      )
    case "cylinder":
      return (
        <OpSceneCylinder
          cylinder={geometry}
          meshMaterial={meshMaterial}
          wireMaterial={wireMaterial}
          obj3dCallback={obj3dCallback}
        />
      )
    case "profile":
      return <ToolScene tool={geometry} alternate={false} />
    case "model":
      return (
        <GltfUrlComponent
          url={geometry.uri}
          meshMaterial={meshMaterial}
          wireMaterial={wireMaterial}
          obj3dCallback={obj3dCallback}
        />
      )
    default:
      if (geometry) {
        console.warn("Geometry rendering not implemented for:", geometry)
      }
      return null
  }
}

const OpSceneBox: FC<{
  box: BoxShape
  meshMaterial: THREE.Material
  wireMaterial: THREE.Material
  obj3dCallback?: Obj3dCallback
}> = ({ box, meshMaterial, wireMaterial, obj3dCallback }) => {
  const [boxGeometryArgs, edgesGeometryArgs] = useMemo(() => {
    const { x, y, z } = box
    const boxGeometryArgs: [width: number, height: number, depth: number] = [x, y, z]
    const edgesGeometryArgs: [geometry: THREE.BoxBufferGeometry] = [
      new THREE.BoxBufferGeometry(x, y, z),
    ]
    return [boxGeometryArgs, edgesGeometryArgs]
  }, [box])

  return (
    <group ref={obj3dCallback}>
      <mesh>
        <boxGeometry args={boxGeometryArgs} />
        <primitive object={meshMaterial} attach={"material"} />
      </mesh>
      <lineSegments>
        <edgesGeometry args={edgesGeometryArgs} />
        <primitive object={wireMaterial} attach={"material"} />
      </lineSegments>
    </group>
  )
}

const OpSceneCylinder: FC<{
  cylinder: CylinderShape
  meshMaterial: THREE.Material
  wireMaterial: THREE.Material
  obj3dCallback?: Obj3dCallback
}> = ({ cylinder, meshMaterial, wireMaterial, obj3dCallback }) => {
  const [cylinderGeometryArgs, circleGeometry, lineGeometry] = useMemo(() => {
    const { r, h } = cylinder

    const cylinderGeometryArgs: [
      width: number,
      height: number,
      depth: number,
      radialSegments: number
    ] = [r, r, h, CYLINDER_THETA_SEGMENTS]
    const circleGeometry = new THREE.RingGeometry(r, r, CYLINDER_THETA_SEGMENTS)
    const lineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, -r, -h / 2),
      new THREE.Vector3(0, -r, h / 2),
    ])

    return [cylinderGeometryArgs, circleGeometry, lineGeometry]
  }, [cylinder])

  return (
    <group ref={obj3dCallback}>
      <mesh quaternion={CYLINDER_ORIENTATION}>
        <cylinderGeometry args={cylinderGeometryArgs} />
        <primitive object={meshMaterial} attach={"material"} />
      </mesh>
      <lineLoop geometry={circleGeometry} position={[0, 0, cylinder.h / 2]}>
        <primitive object={wireMaterial} attach={"material"} />
      </lineLoop>
      <lineLoop geometry={circleGeometry} position={[0, 0, -cylinder.h / 2]}>
        <primitive object={wireMaterial} attach={"material"} />
      </lineLoop>
      <lineSegments geometry={lineGeometry}>
        <primitive object={wireMaterial} attach={"material"} />
      </lineSegments>
    </group>
  )
}

const CYLINDER_THETA_SEGMENTS = 64
const CYLINDER_ORIENTATION = new THREE.Quaternion().setFromAxisAngle(
  new THREE.Vector3(1, 0, 0),
  (90 * Math.PI) / 180
)
