import * as THREE from "three"

import { DEG2RAD } from "src/util/geometry/transforms"

// export interface Vector {
//   x: number
//   y: number
//   z: number
// }

interface EulerIntrinsicZYXDegrees {
  i?: number
  j?: number
  k?: number
}

export function intrinsicZYXRotation(euler: EulerIntrinsicZYXDegrees): THREE.Quaternion {
  const { i, j, k } = euler
  const qx = new THREE.Quaternion()
  const qy = new THREE.Quaternion()
  const qz = new THREE.Quaternion()
  qx.setFromAxisAngle(new THREE.Vector3(1, 0, 0), (i ?? 0) * DEG2RAD)
  qy.setFromAxisAngle(new THREE.Vector3(0, 1, 0), (j ?? 0) * DEG2RAD)
  qz.setFromAxisAngle(new THREE.Vector3(0, 0, 1), (k ?? 0) * DEG2RAD)
  return qz.multiply(qy).multiply(qx)
}

export function quat2euler(quat: THREE.Quaternion, order?: string): THREE.Euler {
  const euler = new THREE.Euler()
  euler.setFromQuaternion(quat, order)
  return euler
}
