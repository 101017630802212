import * as note20220815 from "./markdown/notes-2022-08-15.md"
import * as note20220819 from "./markdown/notes-2022-08-19.md"
import * as note20221014 from "./markdown/notes-2022-10-14.md"
import * as note20221117 from "./markdown/notes-2022-11-17.md"
import * as note20221201 from "./markdown/notes-2022-12-01.md"
import * as note20221207 from "./markdown/notes-2022-12-07.md"
import * as note20221208 from "./markdown/notes-2022-12-08.md"
import * as note20221209 from "./markdown/notes-2022-12-09.md"
import * as note20230110 from "./markdown/notes-2023-01-10.md"
import * as note20230118 from "./markdown/notes-2023-01-18.md"
import * as note20230202 from "./markdown/notes-2023-02-02.md"
import * as note20230712 from "./markdown/notes-2023-07-12.md"
import * as note20230803 from "./markdown/notes-2023-08-03.md"
import * as note20231208 from "./markdown/notes-2023-12-08.md"

export const releaseNotes = [
  note20231208,
  note20230803,
  note20230712,
  note20230202,
  note20230118,
  note20230110,
  note20221209,
  note20221208,
  note20221207,
  note20221201,
  note20221117,
  note20221014,
  note20220819,
  note20220815,
]
