import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { AnchorButton, EditableText, Tag, Text } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import copy from "copy-to-clipboard"
import { format } from "date-fns"

import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  useGetToolsQuery,
  useMesPublishingsQuery,
  useNcEventsByUriLazyQuery,
  useUpdateCamOperationLabelMutation,
  useUpdatePlanMutation,
  useVericutFeedbackQuery,
  VericutFeedbackNodeFragment,
} from "src/graphql/generated"
import { useGetProbingFeedback } from "src/hooks/useGetProbingFeedback"
import { useToaster } from "src/hooks/useToaster"
import { formatSecondsDuration } from "src/pages/GCodeEditor/ToolChangeTable/ToolChangeTable"
import { useToolchangesData } from "src/pages/GCodeEditor/useToolchangesData"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState, useAppDispatch } from "src/store/rootStore"

import styles from "./Labels.module.css"

const LABEL_REGEX = /^[a-z0-9_-]+$/i

export const PlanLabel: FC<{
  planId: string
  leader?: boolean
  isProposal?: boolean
  editable?: boolean
}> = ({ planId, leader = false, isProposal = false, editable }) => {
  const planLabel = useSelector((state: RootState) =>
    storedPlansSelectors.selectPlanLabel(state, planId)
  )
  const [updatePlan] = useUpdatePlanMutation()

  const [labelText, setLabelText] = useState(planLabel)

  const [isLabelError, setIsLabelError] = useState<boolean>(false)

  const handleLabelChange = (label: string) => {
    if (label === "") {
      setLabelText(label)
      setIsLabelError(true)
      return
    }

    if (!label.match(LABEL_REGEX)) {
      setIsLabelError(true)
      return
    }
    setIsLabelError(false)
    setLabelText(label)
  }

  useEffect(() => {
    setLabelText(planLabel)
  }, [planLabel])

  const handleUpdateLabel = (label: string) => {
    if (!label.length || label === planLabel) return
    updatePlan({
      variables: { planId, label },
    })
      .then(result => {
        console.log("updateCamPlanMutation -> result", result)
      })
      .catch(e => {
        console.log("updateCamPlanMutation -> e", e)
      })
  }

  return (
    <div className={styles.labelGroup}>
      {leader && (
        <Text className={styles.labelLeader}>
          <b>{"Label:"}</b>
        </Text>
      )}
      <div onClick={e => e.stopPropagation()}>
        {editable ? (
          <EditableText
            className={styles.labelTitle}
            value={labelText}
            onChange={handleLabelChange}
            onConfirm={handleUpdateLabel}
            placeholder={"Enter label"}
            intent={isLabelError ? "danger" : "none"}
            selectAllOnFocus
          />
        ) : (
          <div>{labelText}</div>
        )}
      </div>
      {isProposal && <Tag minimal={true}>Proposal</Tag>}
    </div>
  )
}

export const OperationLabel: FC<{
  planId: string
  operationIdx: number
  editableLabel?: boolean
  revision?: number
  refetchPublishingsRef?: React.MutableRefObject<() => void>
  showTimestamp?: boolean
}> = ({
  planId,
  operationIdx,
  revision,
  editableLabel = true,
  showTimestamp,
  refetchPublishingsRef,
}) => {
  const operationLabel = useSelector((state: RootState) =>
    storedPlansSelectors.selectOperation(state, planId, operationIdx)
  )?.label
  const [updateCamOperationLabelMutation] = useUpdateCamOperationLabelMutation()

  const [labelText, setLabelText] = useState(operationLabel)
  const [isLabelError, setIsLabelError] = useState<boolean>(false)

  useEffect(() => {
    setLabelText(operationLabel)
  }, [operationLabel])

  const handleUpdateLabel = (label: string) => {
    setIsLabelError(false)
    if (!label.length || label === operationLabel) return
    if (!label.match(LABEL_REGEX)) return

    updateCamOperationLabelMutation({
      variables: { planId, operationIdx, label },
      refetchQueries: ["planPatchRecords"],
    })
      .then(result => {
        console.log("updateCamOperationLabelMutation -> result", result)
      })
      .catch(e => {
        console.error("updateCamOperationLabelMutation -> e", e)
      })
  }
  const { data, refetch: refetchMesPublishingsQuery } = useMesPublishingsQuery({
    variables: { planId },
  })

  useEffect(() => {
    if (refetchPublishingsRef) {
      refetchPublishingsRef.current = () => refetchMesPublishingsQuery()
    }
  }, [refetchPublishingsRef, refetchMesPublishingsQuery])

  const publishing = data?.mesPublishes?.nodes.find(
    val =>
      val.operationIdx === operationIdx &&
      (revision !== undefined ? val.revision === revision : true)
  )
  const version = publishing?.version
  const timestamp =
    publishing?.createdAt && `- Published ${format(new Date(publishing?.createdAt), "M/d")}`
  const tooltipTimestamp =
    publishing?.createdAt &&
    `Published ${format(new Date(publishing?.createdAt), "EEE, MMM d, YYY 'at' hh:mma")}`
  const versionPrefix = publishing?.isProveout ? "x" : "v"
  const versionLabel = version !== undefined ? `${versionPrefix}${version}` : ""

  const handleLabelChange = (label: string) => {
    if (!label.match(LABEL_REGEX)) {
      setIsLabelError(true)
      return
    }
    setIsLabelError(false)
    setLabelText(label)
  }

  const inner = (
    <>
      {editableLabel ? (
        <>
          <Popover2
            content={"Allowed characters: a-z, A-Z, 0-9, _, -"}
            isOpen={isLabelError}
            popoverClassName={styles.popoverContainer}
            autoFocus={false}
          >
            <EditableText
              className={styles.labelTitle}
              value={labelText}
              onChange={handleLabelChange}
              onConfirm={handleUpdateLabel}
              placeholder={"Enter label"}
              selectAllOnFocus
              intent={isLabelError ? "danger" : "none"}
            />
          </Popover2>
          {publishing && (
            <>
              ({versionLabel}
              {showTimestamp && <>&nbsp;</>}
              {showTimestamp && (
                <>
                  &nbsp;
                  <Tooltip2 content={tooltipTimestamp}>{timestamp}</Tooltip2>
                </>
              )}
              )
            </>
          )}
        </>
      ) : (
        <>
          {operationLabel}
          {publishing && (
            <>
              ({versionLabel}
              {showTimestamp && ` ${timestamp}`})
            </>
          )}
        </>
      )}
    </>
  )

  return <div className={styles.labelGroup}>{inner}</div>
}

export const OperationTimeLabel: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const toaster = useToaster()
  const dispatch = useAppDispatch()

  const operationId = useSelector(
    (state: RootState) => storedPlansSelectors.selectOperation(state, planId, operationIdx)?.id
  )

  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )
  const [getNCEventsByUri, { data: ncEvents }] = useNcEventsByUriLazyQuery()
  const { operationProbingFeedback, refetch } = useGetProbingFeedback({ planId, operationIdx })

  useWebsocketMessageListener(wsMessage => {
    if (
      wsMessage.type === "TaskProgress" &&
      wsMessage.name.endsWith("validate_probing_task") &&
      wsMessage.status === "Success"
    ) {
      refetch && refetch()
    }
  })

  const { data: vericutFeedback } = useVericutFeedbackQuery({
    variables: { planId },
  })
  const { data: toolInfo } = useGetToolsQuery()

  useEffect(() => {
    if (planId && operationIdx !== undefined && toolpathProject?.ncFileLocator) {
      getNCEventsByUri({ variables: { uri: toolpathProject.ncFileLocator, rsBucket: true } })
    }
  }, [planId, operationIdx, toolpathProject, getNCEventsByUri])

  const feedback: VericutFeedbackNodeFragment | undefined = useMemo(
    () =>
      vericutFeedback?.validVericutFeedback.find(
        val =>
          val.feedback.operationIdx === operationIdx &&
          val.feedback.ncFileLocator === toolpathProject?.ncFileLocator
      )?.feedback,
    [vericutFeedback, toolpathProject?.ncFileLocator, operationIdx]
  )

  const toolchangesData = useToolchangesData(
    toolInfo?.getTools ?? [],
    ncEvents?.getNcEventsByUri ?? [],
    feedback
  )

  const timeSeconds = useMemo(() => {
    let counter = 0

    operationProbingFeedback?.every(
      probingFeedback => (counter += probingFeedback.estimatedRuntime ?? 0)
    )
    toolchangesData.forEach(entry => {
      counter += entry?.vericutDetails?.time ?? 0
    })

    return counter
  }, [toolchangesData, operationProbingFeedback])

  useEffect(() => {
    if (operationId) {
      dispatch(activeActions.setActiveOpRuntime({ opId: operationId, runtime: timeSeconds }))
    }
  }, [dispatch, operationId, timeSeconds])

  const timeText = timeSeconds === 0 ? undefined : formatSecondsDuration(timeSeconds)

  const copyTimeText = () => {
    copy(timeText ?? "")
    toaster.show({ message: `Copied simulated runtime`, icon: "clipboard", intent: "primary" })
  }

  const body = (
    <>
      {timeText !== undefined && (
        <Tooltip2 position={"top"} content={"Simulated cutting runtime"}>
          <div className={styles.timeLabelFollower}>
            <AnchorButton minimal icon={"stopwatch"} intent={"none"} onClick={copyTimeText}>
              {timeText}
            </AnchorButton>
          </div>
        </Tooltip2>
      )}
    </>
  )

  return <>{body}</>
}

export const ActiveOperationLabel: FC<{
  editableLabel?: boolean
}> = ({ editableLabel = true }) => {
  const activeOperationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const activeRevision = useSelector(activeSelectors.selectActiveRevison)
  if (!activePlanId || activeOperationIdx == null) return null
  return (
    <OperationLabel
      planId={activePlanId}
      operationIdx={activeOperationIdx}
      editableLabel={editableLabel}
      revision={activeRevision}
    />
  )
}
