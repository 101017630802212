import { Matrix4 } from "three"

import { TransformMatrix } from "../generated/bindings/TransformMatrix"

export const convertToMatrix = (transform: TransformMatrix | undefined): Matrix4 | undefined => {
  if (!transform) return undefined

  const matrix = new Matrix4()
  if (transform.length === 16) {
    matrix.fromArray(transform)
  } else {
    console.error("Wrong number of entries in transform")
  }
  return matrix
}
