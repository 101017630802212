import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Route, Router, Switch } from "react-router-dom"
import { HotkeysProvider } from "@blueprintjs/core"
import { AuthProvider } from "@subscale/formlogic-auth-ts"
import { createBrowserHistory } from "history"

import { ApiProvider } from "./hooks/useApi"
import { LocalStorageSettingsProvider } from "./hooks/useLocalStorageSettings"
import { JiraCallbackPage } from "./pages/JiraCallbackPage/JiraCallbackPage"
import { rootStore } from "./store/rootStore"
import App from "./App"

import "./index.css"
const history = createBrowserHistory()

ReactDOM.render(
  <LocalStorageSettingsProvider>
    <Provider store={rootStore}>
      <Router history={history}>
        <Switch>
          <Route exact path="/callback-jira">
            <JiraCallbackPage />
          </Route>
          <Route path="*">
            <AuthProvider>
              <ApiProvider>
                <HotkeysProvider>
                  <App />
                </HotkeysProvider>
              </ApiProvider>
            </AuthProvider>
          </Route>
        </Switch>
      </Router>
    </Provider>
  </LocalStorageSettingsProvider>,
  document.getElementById("root")
)
