import React, { FC, useState } from "react"
import { AnchorButton, Classes, Dialog, Divider, Text } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { PdfPreview } from "src/components/Generic/PdfPreview/PdfPreview"
import {
  useDeleteQualityControlPlanFileMutation,
  useQualityControlPlanFilesQuery,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useToaster } from "src/hooks/useToaster"
import { downloadFromUrl } from "src/util/files"

import styles from "./QualityControlPlan.module.css"

export const QualityControlPlanFiles: FC<{
  controlPlanId: string
  controlPlanRevision: number
  locked?: boolean
}> = ({ controlPlanId, controlPlanRevision, locked }) => {
  const {
    data: controlPlanFilesQuery,
    refetch: refetchControlPlanFiles,
  } = useQualityControlPlanFilesQuery({
    variables: {
      controlPlanId,
      controlPlanRevision,
    },
  })

  const [deleteControlPlanFile] = useDeleteQualityControlPlanFileMutation()

  const { planchangerApi } = useApi()
  const toaster = useToaster()

  const [pdfUrl, setPdfUrl] = useState<string | undefined>()
  const [toDelete, setDelete] = useState<string | undefined>()

  const downloadFile = (locator?: string) => {
    if (!locator) {
      toaster.show({ message: "No URL present", intent: "danger" })
      return
    }
    planchangerApi.urlFor_getFile(locator).then(url => downloadFromUrl(url.toString()))
  }

  const openPDFPreview = (locator?: string) => {
    if (locator) {
      planchangerApi.urlFor_getFile(locator, undefined, true).then(result => {
        setPdfUrl(result.toString())
      })
    }
  }

  const onDeleteConfirm = (drawingFileId: string) => {
    deleteControlPlanFile({ variables: { fileId: drawingFileId } }).then(() => {
      refetchControlPlanFiles().then(() => {
        setDelete(undefined)
      })
    })
  }

  const controlPlanFiles = controlPlanFilesQuery?.qualityControlPlanFiles?.nodes || []

  const fileToDelete = toDelete ? controlPlanFiles.find(val => val.id === toDelete) : undefined

  return (
    <>
      {controlPlanFiles.length > 0 && <Divider />}
      {controlPlanFiles.map((controlPlanFile, idx) => {
        const basename = controlPlanFile.file?.basename

        return (
          basename && (
            <div className={styles.controlPlanFileItem} key={idx}>
              <div className={styles.controlPlanFileLeft}>
                <Tooltip2 content={basename} openOnTargetFocus={false}>
                  <Text className={styles.labelText}>
                    {basename.length > 19 ? `${basename.slice(0, 19)}…` : basename}
                  </Text>
                </Tooltip2>
              </div>
              <div className={styles.controlPlanFileRight}>
                {basename?.toLocaleLowerCase().endsWith("pdf") && (
                  <Tooltip2 content={`View file`} openOnTargetFocus={false}>
                    <AnchorButton
                      minimal
                      icon={"document"}
                      intent="primary"
                      onClick={() => {
                        openPDFPreview(controlPlanFile.file?.locator)
                      }}
                      disabled={locked}
                    />
                  </Tooltip2>
                )}
                <Tooltip2 content={`Download file`} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"download"}
                    intent="primary"
                    onClick={() => {
                      downloadFile(controlPlanFile.file?.locator)
                    }}
                    disabled={locked}
                  />
                </Tooltip2>
                <Tooltip2 content={`Delete file`} openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={"delete"}
                    intent="primary"
                    onClick={() => {
                      setDelete(controlPlanFile.id)
                    }}
                    disabled={locked}
                  />
                </Tooltip2>
              </div>
            </div>
          )
        )
      })}
      <PdfPreview
        url={pdfUrl}
        onClose={() => {
          setPdfUrl(undefined)
        }}
      />
      {toDelete && (
        <Dialog
          icon={"error"}
          onClose={() => {
            setDelete(undefined)
          }}
          title={"Deleting File"}
          isOpen
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            Are you sure you want to delete <strong>{fileToDelete?.file?.basename}</strong>?
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton onClick={() => setDelete(undefined)}>Cancel</AnchorButton>
              <AnchorButton onClick={() => onDeleteConfirm(toDelete)} intent="danger">
                Delete
              </AnchorButton>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
