import { OffsetKind } from "src/client-axios"

// For now, offsets are fixed per kind. If this
// changes later, change this to true
export const ALLOW_CUSTOM_OFFSETS = false

export const defaultOffsets = (offsetKind: OffsetKind): { [key: string]: number } => {
  switch (offsetKind) {
    case OffsetKind.POSITION:
      return { x: 0, y: 0, z: 0 }
    case OffsetKind.TOOL:
      return { D: 0, H: 0 }
    case OffsetKind.FEEDANDSPEED:
      return { FEED: 100, SPEED: 100 }
    case OffsetKind.TOOLRENUMBER:
      return { NEW: 0 }
    case OffsetKind.WCS:
      return { x: 0, y: 0, z: 0, wcs: 55 }
  }
}
