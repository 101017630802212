import {
  AlignmentKindEnum,
  InspectionKindEnum,
  PointsFeature,
  PointsFeatureKindEnum,
  PointsFeatureMove,
  PointsFeatureMoveKindEnum,
  PointsFeaturePoint,
  PointsFeaturePointKindEnum,
} from "src/client-axios"
import { ProbingThunkProps } from "src/store/cam/storedPlanThunks"
import { AppDispatch } from "src/store/rootStore"
import { ProbingSection } from "src/util/cam/probing/probingTypes"
import { handleAddSubStep } from "./handleAddSubStep"

export const handlePastePointsFeaturePoint: (
  offset: number,
  selectedStep: PointsFeaturePoint | PointsFeatureMove | PointsFeature,
  copiedProbeSteps: (PointsFeaturePoint | PointsFeatureMove)[],
  selectedProbingSection: ProbingSection | undefined,
  probingThunkContext: ProbingThunkProps,
  dispatch: AppDispatch,
  selectedSection: ProbingSection | undefined
) => void = (
  offset: number,
  selectedStep: PointsFeaturePoint | PointsFeatureMove | PointsFeature,
  copiedProbeSteps: (PointsFeaturePoint | PointsFeatureMove)[],
  selectedProbingSection: ProbingSection | undefined,
  probingThunkContext: ProbingThunkProps,
  dispatch: AppDispatch,
  selectedSection: ProbingSection | undefined
) => {
  if (
    (copiedProbeSteps[0]?.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
      copiedProbeSteps[0]?.kind === PointsFeatureMoveKindEnum.PointsFeatureMove) &&
    (selectedProbingSection?.kind === AlignmentKindEnum.Alignment ||
      selectedProbingSection?.kind === InspectionKindEnum.Inspection)
  ) {
    let pointsFeature =
      selectedStep.kind === PointsFeatureKindEnum.PointsFeature ? selectedStep : undefined

    if (!pointsFeature) {
      selectedProbingSection.steps.forEach(parent => {
        if (parent.kind === PointsFeatureKindEnum.PointsFeature) {
          parent.steps.forEach(substep => {
            if (substep.id === selectedStep.id) {
              pointsFeature = parent
            }
          })
        }
      })
    }

    pointsFeature &&
      handleAddSubStep(
        copiedProbeSteps,
        offset,
        pointsFeature,
        selectedProbingSection,
        selectedStep,
        probingThunkContext,
        dispatch,
        selectedSection
      )
  }
}
