import { FluidValue } from "@react-spring/shared"
import { to } from "@react-spring/three"

export type MaybeFluidValue<T> = FluidValue<T> | T

export function mergeValues(
  v1: number | FluidValue<number> | undefined,
  v2: number | FluidValue<number> | undefined
): number | FluidValue<number> | undefined {
  if (v1 === undefined) {
    return v2
  } else if (v2 === undefined) {
    return v1
  }
  if (typeof v1 === "number") {
    if (typeof v2 === "number") {
      return v1 * v2
    } else {
      return to([v2], o => v1 * o)
    }
  } else {
    if (typeof v2 === "number") {
      return to([v1], o => v2 * o)
    } else {
      return to([v1, v2], (o1, o2) => o1 * o2)
    }
  }
}

export function mergeValuesSum(
  v1: number | FluidValue<number> | undefined,
  v2: number | FluidValue<number> | undefined
): number | FluidValue<number> | undefined {
  if (v1 === undefined) {
    return v2
  } else if (v2 === undefined) {
    return v1
  }
  if (typeof v1 === "number") {
    if (typeof v2 === "number") {
      return v1 + v2
    } else {
      return to([v2], o => v1 + o)
    }
  } else {
    if (typeof v2 === "number") {
      return to([v1], o => v2 + o)
    } else {
      return to([v1, v2], (o1, o2) => o1 + o2)
    }
  }
}
