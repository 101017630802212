import React, { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { FluidValue } from "@react-spring/shared"
import { useTransition } from "@react-spring/three"
import * as THREE from "three"

import { WcsProbingKindEnum } from "src/client-axios"
import { ProbingPath } from "src/components/Cam/ProbingPath/ProbingPath"
import { AnimatedAxesHelper } from "src/components/Canvas/Viewer/Scene/Cam/AnimatedAxesHelper"
import { TransformNodeFragment } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { activeProbingSelectors } from "src/store/cam/active"
import { guardrailSelectors } from "src/store/cam/guardrail"
import { DisplayMode, viewOptionsSelectors } from "src/store/ui/viewOptions"
import { rtcpCoordToMachineCoord, useMachineCoordsStore } from "src/store/zustandMachine"
import { TRANSITION_CONFIG } from "src/util/animation/springConfig"

type TRANSITION_KEY = string

interface KeyedWcs {
  identifier: string
  wcs: TransformNodeFragment
  mcs: TransformNodeFragment
}

interface WcsSceneProps {
  wcs: KeyedWcs | undefined
  minimal?: boolean
}

export const WcsScene: FC<WcsSceneProps> = ({ wcs, minimal }) => {
  const { transferInvalidate } = useTransferInvalidate()
  const visible = useSelector(viewOptionsSelectors.showWcs)
  const transitions = useTransition(wcs ? [wcs] : [null], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    key: (item: KeyedWcs | null) => item?.identifier || "null",
    leave: { opacity: 0 },
    onChange: () => transferInvalidate(),
    config: TRANSITION_CONFIG,
  })

  return (
    <group visible={visible}>
      {transitions((values, item, transition) => (
        <SingleWcsScene
          minimal={minimal}
          wcs={item?.wcs}
          mcs={item?.mcs}
          opacity={values.opacity}
          key={transition.key as TRANSITION_KEY}
        />
      ))}
    </group>
  )
}

interface SingleWcsSceneProps {
  wcs: TransformNodeFragment | undefined
  mcs: TransformNodeFragment | undefined
  opacity: FluidValue<number>
  minimal?: boolean
}

const SingleWcsScene: FC<SingleWcsSceneProps> = ({ wcs, mcs, opacity, minimal }) => {
  const section = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const probingDisplayMode = useSelector(viewOptionsSelectors.probingDisplayMode)

  const showProbeScene =
    (mcs &&
      section &&
      section?.kind !== WcsProbingKindEnum.WcsProbing &&
      probingDisplayMode === DisplayMode.Visible) ||
    probingDisplayMode === DisplayMode.Focused

  useEffect(() => {
    useMachineCoordsStore.setState({ rtcpEnabled: !!showProbeScene })
  }, [showProbeScene])

  const guardrailArrowPositions = useSelector(guardrailSelectors.selectGuardrailArrows)

  const guardrailArrows = useMemo(() => {
    const arrowHelpers = guardrailArrowPositions?.map(position => {
      let point = position.point
      point = rtcpCoordToMachineCoord(
        useMachineCoordsStore.getState(),
        {
          x: point.x,
          y: point.y,
          z: point.z,
          extraCoords: position.extra_coords,
        },
        false
      )
      const dir = new THREE.Vector3(0, 0, -1)

      dir.normalize()

      const length = 10
      const origin = new THREE.Vector3(point.x, point.y, point.z + length)
      const color = 0xff0000

      const arrowHelper = new THREE.ArrowHelper(dir, origin, length, color)
      return arrowHelper
    })
    return arrowHelpers
  }, [guardrailArrowPositions])

  if (!wcs) return null

  const axesHelperSize = 10

  return (
    <AnimatedAxesHelper transform={wcs} size={axesHelperSize} opacity={opacity}>
      {showProbeScene && <>{!minimal && <ProbingPath />}</>}
      {guardrailArrows?.map((arrow, i) => {
        return <primitive object={arrow} key={i} />
      })}
    </AnimatedAxesHelper>
  )
}
