const REACT_APP_PARTS_LIBRARY_BUCKET_URL = "https://subscale-parts.s3.us-east-2.amazonaws.com"

export function getThumbnailUrl(sceneId: string): string {
  return getAssetUrl("thumbnails", sceneId, "png")
}

export function getModelUrl(sceneId: string): string {
  return getAssetUrl("models", sceneId, "step")
}

function getAssetUrl(kind: string, sceneId: string, extension: string): string {
  return `${REACT_APP_PARTS_LIBRARY_BUCKET_URL}/${kind}/${getAssetUrlStem(sceneId)}.${extension}`
}

function getAssetUrlStem(objectId: string): string {
  return objectId.split(":").join("/")
}
