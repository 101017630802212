import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Icon, Intent } from "@blueprintjs/core"
import { Maybe } from "graphql/jsutils/Maybe"

import { UnlockButton } from "src/components/Cam/UnlockButton/UnlockButton"
import { getIntentStyle } from "src/pages/ViewerPage/MachineCoordsPanel/MachineCoordsPanel"
import { activeProbingSelectors } from "src/store/cam/active"
import {
  ControllerState,
  MachineCoords,
  TravelLimitIntents,
  useMachineCoordsStore,
  useTravelLimitsStore,
} from "src/store/zustandMachine"

import styles from "./ProbingWarningBanner.module.css"

export const ProbingWarningBanner: FC<{
  handleRequestClose: () => void
  locked?: boolean
  operationId?: string
  assignees?: Maybe<string[]> | undefined
}> = ({ handleRequestClose, locked, operationId, assignees }) => {
  const selectedSectionId = useSelector(activeProbingSelectors.selectActiveProbingSectionId)
  const [travelLimitIntents, setTravelLimitIntents] = useState<Record<string, Intent>>({})
  const [bannerIntent, setBannerIntent] = useState<Intent>(Intent.NONE)
  const [coords, setCoords] = useState<MachineCoords>()

  useEffect(() => {
    return useTravelLimitsStore.subscribe(
      (state: TravelLimitIntents) => state.axes,
      (axes: Record<string, Intent>) => {
        setTravelLimitIntents(axes)
      },
      { fireImmediately: true }
    )
  }, [])

  useEffect(() => {
    return useMachineCoordsStore.subscribe(
      (state: ControllerState) => state.currentCoords,
      (currentCoords: MachineCoords) => {
        setCoords(currentCoords)
      },
      { fireImmediately: true }
    )
  }, [])

  useEffect(() => {
    const intents = Object.values(travelLimitIntents)
    if (intents.some(intent => intent === Intent.DANGER)) {
      setBannerIntent(Intent.DANGER)
    } else if (intents.some(intent => intent === Intent.WARNING)) {
      setBannerIntent(Intent.WARNING)
    } else {
      setBannerIntent(Intent.NONE)
    }
  }, [travelLimitIntents])

  let headerClassName = `${styles.headerContainer}`
  let iconClassName = `${styles.closeButton}`
  let text = ``
  if (selectedSectionId != null) {
    headerClassName = `${headerClassName} ${getIntentStyle(bannerIntent, styles)}`
    iconClassName = `${iconClassName} ${getIntentStyle(bannerIntent, styles)}`
    if (Object.values(travelLimitIntents).some(v => v === "danger")) {
      text = `Overtravel:`
      Object.keys(travelLimitIntents).map(axis => {
        if (travelLimitIntents[axis] === "danger") {
          if (coords) {
            if (axis in coords) {
              text = `${text} ${axis.toUpperCase()}=${Number(
                coords?.[axis as keyof MachineCoords]
              ).toFixed(3)}`
            } else if (axis in coords.extraCoords) {
              text = `${text} ${axis.toUpperCase()}=${Number(coords?.extraCoords?.[axis]).toFixed(
                3
              )}`
            } else {
              text = `${text} ${axis.toUpperCase()} is an invalid axis`
            }
          }
        }
      })
    } else if (Object.values(travelLimitIntents).some(v => v === "warning")) {
      text = `Near overtravel:`
      Object.keys(travelLimitIntents).map(axis => {
        if (travelLimitIntents[axis] === "warning") {
          if (coords) {
            if (axis in coords) {
              text = `${text} ${axis.toUpperCase()}=${Number(
                coords?.[axis as keyof MachineCoords]
              ).toFixed(3)}`
            } else if (axis in coords.extraCoords) {
              text = `${text} ${axis.toUpperCase()}=${Number(coords?.extraCoords?.[axis]).toFixed(
                3
              )}`
            } else {
              text = `${text} ${axis.toUpperCase()} is an invalid axis`
            }
          }
        }
      })
    }
  }

  return (
    <div className={headerClassName}>
      <div className={styles.overTravel}>{text}</div>
      <Icon icon="cross" className={iconClassName} onClick={handleRequestClose} />
      {/* <Button icon={locked ? "lock" : "unlock"} minimal onClick={handleClickLock} /> */}
      <UnlockButton operationId={operationId} locked={locked} assignees={assignees} />
    </div>
  )
}
