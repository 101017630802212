import { createContext, useContext } from "react"

export interface CoordinateSystemContextProps {
  maxSize: number
  hideLabel: boolean
  textScale: number
  shaftRadius: number
  shaftLength: number
  headRadius: number
  headLength: number
}

export const CoordinateSystemContext = createContext<CoordinateSystemContextProps>({
  maxSize: 12,
  hideLabel: false,
  textScale: 0.2,
  shaftRadius: 0.02,
  shaftLength: 0.85,
  headRadius: 0.05,
  headLength: 0.15,
})
export const useCoordinateSystemContext = () => useContext(CoordinateSystemContext)
