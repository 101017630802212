import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Classes, HTMLTable, Spinner } from "@blueprintjs/core"
import { format } from "date-fns"

import { useQualityControlPlanHistoryQuery } from "src/graphql/generated"

import styles from "./PlanHistory.module.css"

export const QualityControlPlanHistory: FC<{ controlPlanId: string }> = ({ controlPlanId }) => {
  const { data, loading } = useQualityControlPlanHistoryQuery({
    variables: { planId: controlPlanId },
  })

  let planPatches = data?.qualityControlPlanPatchRecords?.nodes

  if (loading || planPatches === undefined) {
    return <Spinner />
  }

  planPatches = [...planPatches]
  planPatches.reverse()

  return (
    <>
      <HTMLTable
        className={styles.planHistory}
        bordered={true}
        condensed={true}
        interactive={true}
        striped={true}
      >
        <thead>
          <tr>
            <th>Rev #</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Op</th>
            <th>Path</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {planPatches.map((val, i) => {
            return (
              <tr key={i}>
                <td>{val.revision} </td>
                <td>{val.createdBy}</td>
                <td>{format(new Date(val.createdAt), "MMM d h:mma")}</td>
                <td>{val.op}</td>
                <td>
                  {val.from ? (
                    <>
                      <pre className={Classes.CODE_BLOCK}>{val.from}</pre> To{" "}
                    </>
                  ) : (
                    ""
                  )}
                  <pre className={Classes.CODE_BLOCK}>{val.path}</pre>
                </td>
                <td>
                  <pre className={Classes.CODE_BLOCK}>
                    <RenderValue value={val.value} path={val.path} controlPlanId={controlPlanId} />
                  </pre>
                </td>
              </tr>
            )
          })}
        </tbody>
      </HTMLTable>
    </>
  )
}

const opMatch = /operations\/(\d+)/

const RenderValue: FC<{
  value?: Record<string, unknown> | string | null
  path: string
  controlPlanId: string
}> = ({ value, path, controlPlanId }) => {
  if (path.endsWith("modelId") && typeof value === "string") {
    return <Link to={`/3d-viewer?modelId=${value}`}>{value}</Link>
  }

  if (path.endsWith("ncFileId") && typeof value === "string") {
    const match = path.match(opMatch)

    if (match) {
      return <Link to={`/nc-edit/${controlPlanId}/${match[1]}/${value}`}>{value}</Link>
    }
  }

  return <>{JSON.stringify(value, null, 2)}</>
}
