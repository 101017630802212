import React, { FC } from "react"
import { ServerError, ServerParseError } from "@apollo/client"
import { ApolloError } from "@apollo/client/errors"
import { NonIdealState, Spinner } from "@blueprintjs/core"
import { GraphQLError } from "graphql/error/GraphQLError"

export const ApolloQueryView: FC<{
  error?: ApolloError
  loading: boolean
  loadingProps: { title: string; description: string }
}> = ({ error, loading, loadingProps, children }) => {
  if (loading) {
    return <ApolloLoadingView {...loadingProps} />
  }
  if (error) {
    return <ApolloErrorView error={error} />
  }
  return <>{children}</>
}

const ApolloLoadingView: FC<{ title: string; description: string }> = ({ title, description }) => {
  return <NonIdealState title={title} description={description} icon={<Spinner />} />
}

const ApolloErrorView: FC<{ error: ApolloError }> = ({ error }) => {
  const extraInfoView = error.extraInfo && (
    <div>
      <b>Extra Info:</b>
      {`${error.extraInfo}`}
    </div>
  )

  const networkError = error.networkError

  if (networkError === null) {
    const graphqlErrors = error.graphQLErrors

    if (graphqlErrors) {
      console.error(graphqlErrors)
      const description = (
        <div>
          <b>GraphQL Errors:</b>
          <ul>
            {graphqlErrors.map((e, i) => (
              <li key={i}>
                <GraphQLErrorView error={e} />
              </li>
            ))}
          </ul>
          {extraInfoView}
        </div>
      )
      return <NonIdealState title={"Network Error"} description={description} icon={"error"} />
    } else {
      console.error(error)
      return (
        <NonIdealState
          title={"Error"}
          description={
            <div>
              {error.message}
              {extraInfoView}
            </div>
          }
          icon={"error"}
        />
      )
    }
  }

  console.error(networkError)

  if (isServerParseError(networkError)) {
    const description = (
      <div>
        <b>{networkError.statusCode}:</b> {networkError.message}
        <br />
        <br />
        <b>Response body text:</b>
        <pre>{networkError.bodyText}</pre>
        {extraInfoView}
      </div>
    )
    return <NonIdealState title={"Server Parsing Error"} description={description} icon={"error"} />
  }

  if (isServerError(networkError)) {
    console.error(networkError.result)
    const description = (
      <div>
        <b>{networkError.statusCode}:</b> {networkError.message}
        {extraInfoView}
      </div>
    )
    return <NonIdealState title={"Server Error"} description={description} icon={"error"} />
  }

  const description = (
    <div>
      <b>{networkError.name}:</b> {networkError.message}
      {extraInfoView}
    </div>
  )
  return <NonIdealState title={"Network Error"} description={description} icon={"error"} />
}

const GraphQLErrorView: FC<{ error: GraphQLError }> = ({ error }) => {
  return (
    <div>
      {error.message}
      {error.source && (
        <div>
          <b>Source: </b>
          <pre>{error.source}</pre>
        </div>
      )}
    </div>
  )
}

function isServerError(
  networkError: Error | ServerParseError | ServerError | null
): networkError is ServerError {
  if (networkError === null) return false
  return "result" in networkError
}

function isServerParseError(
  networkError: Error | ServerParseError | ServerError | null
): networkError is ServerParseError {
  if (networkError === null) return false
  return "bodyText" in networkError
}
