import React, { FC, useMemo } from "react"
import * as THREE from "three"

import {
  createCmmPointMarkers,
  createMarkerGeometry,
} from "src/components/Canvas/Viewer/Scene/Cam/CmmPointMarkers"
import { datumReferenceFrameVis } from "src/components/Canvas/Viewer/Scene/Cam/DatumReferenceFrameVis"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { SceneClickData, useSceneClickEventHandler } from "src/hooks/useSceneClickEventHandler"
import { pcdmisSimAnalysisFieldResult, useCmmDisplayStore } from "src/store/zustandCmm"
import { getFeatureDimensions } from "src/store/zustandCmm"
import { transformToMatrix } from "src/util/geometry/transforms"
import { findNearbyIntersection, getClickedIntersections } from "../../ClickHandlers/util"

const CONE_RADIUS = 1.5
const CONE_HEIGHT = 4
const CONE_SLICES = 20

const markerGeometry = createMarkerGeometry(CONE_RADIUS, CONE_HEIGHT, CONE_SLICES)

export const CmmScene: FC = () => {
  const cmmState = useCmmDisplayStore()
  const {
    skuSchemaField,
    unitSchemaField,
    pcdmisSimAnalysis,
    selectedFeatures,
    selectedDrfs,
  } = cmmState
  const { transferInvalidate } = useTransferInvalidate()

  const { mesh, featureToolResultIndex, pointIndex } = useMemo(() => {
    transferInvalidate()

    if (pcdmisSimAnalysis) {
      return createCmmPointMarkers(pcdmisSimAnalysis, selectedFeatures, markerGeometry)
    } else {
      return {
        mesh: undefined,
        featureToolResultIndex: undefined,
        pointIndex: undefined,
      }
    }
  }, [transferInvalidate, selectedFeatures, pcdmisSimAnalysis])

  const drfVis = useMemo(() => {
    return selectedDrfs.map((drf, i) => {
      const homog = transformToMatrix(drf.sku_coordinate_system)
      const group = datumReferenceFrameVis()
      const scale = new THREE.Vector3()
      homog.decompose(group.position, group.quaternion, scale)
      return <primitive object={group} key={i} />
    })
  }, [selectedDrfs])

  const onClick = (ev: CustomEvent<SceneClickData>) => {
    if (featureToolResultIndex && pcdmisSimAnalysis && skuSchemaField && unitSchemaField) {
      const intersection = findNearbyIntersection(
        getClickedIntersections(ev.detail),
        o => o.userData.isCmmMarkers,
        1.5
      )
      if (intersection && intersection.instanceId !== undefined) {
        const index = featureToolResultIndex[intersection.instanceId]
        const pointIdx = pointIndex[intersection.instanceId]
        const fieldResult = pcdmisSimAnalysisFieldResult(pcdmisSimAnalysis)
        const featureToolResult = fieldResult.feature_tool_results[index]
        const skuDims = getFeatureDimensions(skuSchemaField, [featureToolResult.feature_name])
        const unitDims = getFeatureDimensions(unitSchemaField, [featureToolResult.feature_name])

        const skuFeature = skuSchemaField.features.find(
          f => f.name === featureToolResult.feature_name
        )!
        const unitFeature = unitSchemaField.features.find(
          f => f.name === featureToolResult.feature_name
        )!

        const clickSelection = {
          skuDims,
          unitDims,
          skuFeature,
          unitFeature,
          featureToolResult,
          pointIdx,
        }
        useCmmDisplayStore.setState({
          clickSelection,
        })
      } else {
        useCmmDisplayStore.setState({
          clickSelection: undefined,
        })
      }
    } else {
      useCmmDisplayStore.setState({
        clickSelection: undefined,
      })
    }
  }
  useSceneClickEventHandler(onClick, onClick, onClick)

  return (
    <group>
      {mesh && <primitive object={mesh} />}
      {drfVis}
    </group>
  )
}
