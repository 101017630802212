import { batch } from "react-redux"
import { combineReducers } from "redux"

import { ConfigApi, PlanchangerApi } from "src/client-axios"
import { fixturesConfigReducer, fixturesThunks } from "src/store/config/fixtures"
import { machinesConfigReducer, machinesThunks } from "src/store/config/machines"
import { probesConfigReducer, probesThunks } from "src/store/config/probes"
import { materialsConfigReducer, stocksConfigReducer, stocksThunks } from "src/store/config/stocks"
import { AppDispatch } from "src/store/rootStore"
import { toolsConfigReducer, toolsThunks } from "./tools"

export const fetchAllConfigs = (
  dispatch: AppDispatch,
  configApi: ConfigApi,
  planchangerApi: PlanchangerApi
): void => {
  batch(() => {
    dispatch(fixturesThunks.fetchFixturesConfig({ configApi }))
    dispatch(machinesThunks.fetchMachinesConfig({ configApi }))
    dispatch(probesThunks.fetchProbesConfig({ configApi }))
    dispatch(stocksThunks.fetchStocksConfig({ configApi }))
    dispatch(toolsThunks.fetchToolsConfig({ planchangerApi }))
  })
}

export const configReducer = combineReducers({
  toolsConfig: toolsConfigReducer,
  machinesConfig: machinesConfigReducer,
  stocksConfig: stocksConfigReducer,
  materialsConfig: materialsConfigReducer,
  fixturesConfig: fixturesConfigReducer,
  probesConfig: probesConfigReducer,
})
