import React, { FC } from "react"
import { useSelector } from "react-redux"
import { Button, Checkbox } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"

import { sendEvent } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { storedOperationSelectors } from "src/store/cam/storedPlans"

import styles from "./CutConfigPopover.module.css"

export const CutConfigPopover: FC<{ planId: string; operationIdx: number }> = ({
  planId,
  operationIdx,
}) => {
  const { plansApi } = useApi()
  const toolpathProject = useSelector(
    storedOperationSelectors.createSelectToolpathProject(planId, operationIdx)
  )
  const machineId = useSelector(
    storedOperationSelectors.createSelectMachineId(planId, operationIdx)
  )
  const errorToast = useNetworkErrorToast()

  const enableGrobToolchecks = (enable: boolean) => {
    plansApi
      .enableGrobToolchecks(planId, operationIdx, enable)
      .then(async _ => {
        sendEvent("enableGrobToolchecks", {
          planId,
          operationIdx,
          enable,
        })
      })
      .catch((error: AxiosError) => {
        errorToast(error, "Enable Grob Toolchecks Error")
      })
  }

  return (
    <Popover2
      modifiers={{
        preventOverflow: { enabled: false },
      }}
      content={
        <div className={styles.container}>
          {machineId?.includes("grob") && (
            <Checkbox
              checked={!!toolpathProject?.enableGrobToolchecks}
              label="Enable Grob Toolchecks"
              onChange={() => enableGrobToolchecks(!toolpathProject?.enableGrobToolchecks)}
            />
          )}
        </div>
      }
    >
      <Button minimal intent={"primary"} icon="flow-review-branch" />
    </Popover2>
  )
}
