import * as THREE from "three"

// May want to make it easier to use THREE.FrontSide for investigation of model issues
// If the model looks wrong with FrontSide, it generally means there are problems with the normals
const PART_SIDE = THREE.DoubleSide
const STOCK_SIDE = THREE.DoubleSide
const FIXTURE_SIDE = THREE.FrontSide

export interface MaterialData {
  polygonOffset?: boolean
  polygonOffsetFactor?: number
  flatShading: boolean
  metalness: number
  roughness: number
  color: THREE.Color
  wireColor: THREE.Color
  opacity?: number
  transparent: boolean
  side: THREE.Side
  depthWrite?: boolean
}

export function getPartMaterial(transparent: boolean): MaterialData {
  return transparent ? TRANSPARENT_PART_MATERIAL : OPAQUE_PART_MATERIAL
}

const OPAQUE_PART_MATERIAL: MaterialData = {
  flatShading: false,
  metalness: 1.0,
  roughness: 0.6,
  color: new THREE.Color("#84878a"),
  wireColor: new THREE.Color("#000000"),
  opacity: 1.0,
  transparent: false,
  side: PART_SIDE,
}

const TRANSPARENT_PART_MATERIAL: MaterialData = {
  ...OPAQUE_PART_MATERIAL,
  opacity: 0.4,
  transparent: true,
}

export const FIXTURE_MATERIAL: MaterialData = {
  flatShading: false,
  metalness: 0.88,
  roughness: 1.0,
  color: new THREE.Color("#111111"),
  wireColor: new THREE.Color("#000000"),
  transparent: false,
  side: FIXTURE_SIDE,
}

export const INPUT_STOCK_MATERIAL: MaterialData = {
  polygonOffset: true,
  polygonOffsetFactor: -1,
  flatShading: true,
  metalness: 0.99,
  roughness: 0.5,
  color: new THREE.Color("#afe9f8"),
  wireColor: new THREE.Color("#000000"),
  opacity: 0.2,
  transparent: true,
  side: STOCK_SIDE,
}

export const SIMULATED_OUTPUT_STOCK_MATERIAL: MaterialData = {
  ...INPUT_STOCK_MATERIAL,
  color: new THREE.Color("#a5f582"),
  opacity: 0.25,
}

export const ESTIMATED_OUTPUT_STOCK_MATERIAL: MaterialData = {
  ...SIMULATED_OUTPUT_STOCK_MATERIAL,
  color: new THREE.Color("#ffe621"),
}

export const CUSTOM_MODEL: MaterialData = {
  ...INPUT_STOCK_MATERIAL,
  color: new THREE.Color("#EE874C"),
  transparent: false,
  opacity: 1.0,
}

export const STOCK_WIRE_MATERIAL = {
  transparent: INPUT_STOCK_MATERIAL.transparent,
  color: INPUT_STOCK_MATERIAL.wireColor,
}

export const colorMaterial = (
  color: string
): { transparent: boolean; color: THREE.Color; opacity: number } => ({
  transparent: INPUT_STOCK_MATERIAL.transparent,
  color: new THREE.Color(color),
  opacity: 1.0,
})

export const TRANSPARENT_MATERIAL = {
  depthWrite: false,
}
