import { useDispatch } from "react-redux"
import { configureStore, createAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { produce } from "immer"
import { AnyAction, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"

import { machineReducer } from "src/store/cam/machine"
import { plansReducer } from "src/store/cam/storedPlans"
import { cycleDisplayMachineMode, DisplayMode, viewOptionsReducer } from "src/store/ui/viewOptions"
import { activeOperationSelectors, activeReducer } from "./cam/active"
import { fixtureReducer } from "./cam/fixture"
import { guardrailReducer } from "./cam/guardrail"
import { probingReducer } from "./cam/probing"
import { revisionReducer } from "./cam/revision"
import { configReducer } from "./config/config"
import { fileModalReducer } from "./ui/fileModal"
import { viewerModalReducer } from "./ui/viewerModal"

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

// Combined within-slice reducers
const combinedReducer = combineReducers({
  plans: plansReducer,
  viewOptions: viewOptionsReducer,

  // TODO: We should consider putting selection/active state into a single reducer
  //  (this includes selected operation, plan, config panel, etc.)
  //  (this may or may not include selected points in the viewer, which are currently stored on viewOptions)
  active: activeReducer,

  probing: probingReducer,
  fixture: fixtureReducer,
  machine: machineReducer,

  config: configReducer,
  viewerModal: viewerModalReducer,
  fileModal: fileModalReducer,
  revision: revisionReducer,
  guardrail: guardrailReducer,
})

export type RootState = ReturnType<typeof combinedReducer>

// Cross-slice actions and reducer
const cycleMachineDisplayMode = createAction<undefined>("crossSlice/cycleMachineVisibility")

export const crossSliceActions = { cycleMachineDisplayMode }

function crossSliceReducer(state: RootState, action: AnyAction): RootState {
  switch (action.type) {
    case cycleMachineDisplayMode.type: {
      return produce(state, (draftState: RootState) => {
        const machine = activeOperationSelectors.selectActiveMachineRecord(draftState)
        if (!machine) return
        draftState.viewOptions.machineDisplayMode = cycleDisplayMachineMode(
          draftState.viewOptions.machineDisplayMode,
          [...machine.visibilities, DisplayMode.Hidden]
        )
      })
    }
    default:
      return state
  }
}

// Root reducer
const rootReducer: typeof combinedReducer = (state, action) => {
  const intermediateState = combinedReducer(state, action)
  return crossSliceReducer(intermediateState, action)
}

export const rootStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  // devTools: true,
  devTools: { maxAge: 20 },
  enhancers: [applyMiddleware(thunk), sentryReduxEnhancer],
})

export type AppDispatch = typeof rootStore.dispatch

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
