import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"

import { ParametricStock } from "src/client-axios"
import { InputStockLengthInput } from "src/components/Cam/TreeNavigator/PlanTreeItem/ProductStockItem/InputStockLengthInput/InputStockLengthInput"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { MatrixTransform } from "src/util/geometry/transforms"
import { argSort } from "../InputStockConfigPanel"

import styles from "./RectangularStockSizeControls.module.css"

export const RectangularStockSizeControls: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
  inputStock: ParametricStock
}> = ({ planId, operationIdx, locked, inputStock }) => {
  const mcs = useSelector((state: RootState) =>
    storedOperationSelectors.selectMcs(state, planId, operationIdx)
  )

  const mcsToStockTransform = useMemo(() => {
    return new MatrixTransform(inputStock.transform).invert().multiply(new MatrixTransform(mcs))
  }, [inputStock.transform, mcs])

  const order: ("x" | "y" | "cutLength")[] = useMemo(() => {
    // Idea: for each of X, Y, and Z, determine which under-MCS-axis is most affected by changing the stock dimension
    // Use this data to produce the ordering of the controls, so you can predict the dimension that will be affected
    // when you change the stock size in the UI

    // NOTE: matrix.elements is in column-major order
    const absMatrixElements = mcsToStockTransform.toMatrix().elements.map(x => Math.abs(x))

    const xArgSorted = argSort(absMatrixElements.slice(0, 3))
    const yArgSorted = argSort(absMatrixElements.slice(4, 7))
    const zArgSorted = argSort(absMatrixElements.slice(8, 11))
    const axesArgSorted = [xArgSorted, yArgSorted, zArgSorted]

    const selected = new Set()
    const order = []
    for (let axis = 0; axis < 3; axis++) {
      const axisArgSorted = axesArgSorted[axis]
      for (let index = 0; index < 3; index++) {
        const largestAxis = axisArgSorted[2 - index]
        if (!selected.has(largestAxis)) {
          order.push(largestAxis)
          selected.add(largestAxis)
          break
        }
      }
    }

    const mapping = ["x", "y", "cutLength"] as const
    return order.map(x => mapping[x])
  }, [mcsToStockTransform])

  const xControl = (
    <div className={styles.inputRow}>
      <InputStockLengthInput
        key={order[0]}
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
        field={order[0]}
        label={"Length"}
        className={styles.dimensionInput}
      />
      <div className={styles.tolerancesContainer}>
        <div>Tol</div>
        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>+</div>
          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="xMaxTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>-</div>
          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="xMinTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
      </div>
    </div>
  )
  const yControl = (
    <div className={styles.inputRow}>
      <InputStockLengthInput
        key={order[1]}
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
        field={order[1]}
        label={"Width"}
        className={styles.dimensionInput}
      />
      <div className={styles.tolerancesContainer}>
        <div>Tol</div>

        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>+</div>
          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="yMaxTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>-</div>

          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="yMinTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
      </div>
    </div>
  )
  const zControl = (
    <div className={styles.inputRow}>
      <InputStockLengthInput
        key={order[2]}
        planId={planId}
        operationIdx={operationIdx}
        inputStock={inputStock}
        locked={locked}
        field={order[2]}
        label={"Height"}
        className={styles.dimensionInput}
      />
      <div className={styles.tolerancesContainer}>
        <div>Tol</div>
        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>+</div>
          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="cutLengthMaxTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
        <div className={styles.toleranceInputContainer}>
          <div className={styles.sign}>-</div>
          <InputStockLengthInput
            planId={planId}
            operationIdx={operationIdx}
            inputStock={inputStock}
            locked={locked}
            field="cutLengthMinTolerance"
            label={""}
            className={styles.toleranceInput}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {xControl}
      {yControl}
      {zControl}
    </div>
  )
}
