import React, { FC } from "react"
import { useSelector } from "react-redux"
import { Icon } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { DifficultyGroup } from "src/pages/DrawingViewer/interfaces"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { InspectionData } from "../DrawingViewer"

import styles from "./InspectionIcon.module.css"

export const InspectionIcon: FC<{
  inspectionData: InspectionData
  difficultyGroups: Record<string, DifficultyGroup | undefined>
  shapeTitle: string
  tooltip?: boolean
  allGreenCheckMarks?: boolean
  inPdf?: boolean
}> = ({ inspectionData, difficultyGroups, shapeTitle, tooltip, allGreenCheckMarks, inPdf }) => {
  const dimInspectionData = inspectionData[shapeTitle]
  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  const operations =
    useSelector((state: RootState) => storedPlansSelectors.selectOperations(state, activePlanId)) ??
    []

  const activeOperationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const activeOperation = operations[Number(activeOperationIdx)]

  if (!dimInspectionData) return null

  const difficulty = difficultyGroups[shapeTitle] ?? DifficultyGroup.gray

  const entries = Object.entries(dimInspectionData)
  if (entries.length === 0) return null

  entries.sort(([a], [b]) => a.localeCompare(b))

  if (!tooltip) return <Icon icon="tick-circle" intent={"success"} iconSize={16} />

  return (
    <Tooltip2
      className={styles.inspectionCell}
      content={
        <div className={styles.inspectionContainer}>
          <div>Callout {shapeTitle} is inspected in:</div>
          {entries.map(([opLabel, toolLabelsSet]) => (
            <div key={opLabel}>
              • {opLabel} {!!toolLabelsSet.size && <>({[...toolLabelsSet].sort().join(", ")})</>}
            </div>
          ))}
        </div>
      }
      openOnTargetFocus={false}
    >
      <Icon
        icon="tick-circle"
        intent={
          allGreenCheckMarks ||
          entries.map(([opLabel, _]) => opLabel).includes(activeOperation?.label)
            ? "success"
            : "none"
        }
        iconSize={16}
        className={
          inPdf
            ? difficulty === DifficultyGroup.blue
              ? styles.inPdfWhiteIcon
              : styles.inPdfBlackIcon
            : undefined
        }
        color={inPdf && difficulty === DifficultyGroup.blue ? "white" : undefined}
      />
    </Tooltip2>
  )
}
