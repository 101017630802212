import IntervalTree from "@flatten-js/interval-tree"

import { Compensation } from "src/client-axios/api"
import { NcEvent, NcEventKind } from "src/graphql/generated"
import { TopLevelProbingStep } from "src/util/cam/probing/probingTypes"

export type OperationNcEvent = Pick<NcEvent, "kind" | "row" | "val" | "comment">

export type InspectionCompensation = {
  probingStep: TopLevelProbingStep
  compensation: Compensation
}

export const toolCompensationOffsetId = (toolId: string | number): string => `\${tool:${toolId}}`

// Tracks relationships between NC events and probing data
export class NcEventIntervals {
  ncEvents: OperationNcEvent[]
  toolChanges: OperationNcEvent[]
  eventIntervals: Partial<Record<NcEventKind, IntervalTree>>
  compensationIds: Record<string, boolean>

  constructor(
    ncEvents: OperationNcEvent[],
    toolChanges: OperationNcEvent[],
    eventIntervals: Partial<Record<NcEventKind, IntervalTree>>,
    compensationIds: Record<string, boolean>
  ) {
    this.ncEvents = ncEvents
    this.toolChanges = toolChanges
    this.eventIntervals = eventIntervals
    this.compensationIds = compensationIds
  }

  markCompensationsAsProbed(inspectionCompensations: InspectionCompensation[]): void {
    inspectionCompensations.forEach(inspectionCompensation => {
      if (inspectionCompensation.compensation.offsetId in this.compensationIds) {
        this.compensationIds[inspectionCompensation.compensation.offsetId] = true
      }
    })
  }

  gcodeLineHasProbedRadialCompensation(gcodeLine: number): boolean {
    const vals = this.eventIntervals[NcEventKind.Tool]?.search([gcodeLine, gcodeLine + 1])
    if (vals && vals.length > 0) {
      const key = toolCompensationOffsetId(vals[0].event.val)
      if (key in this.compensationIds) {
        return this.compensationIds[key]
      }
    }
    return false
  }
}
