import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import * as THREE from "three"

import { CamDataCoordinateSystem, StockAnalysisToolPathData } from "src/client-axios"
import { ToolWithHolderScene } from "src/components/Canvas/Viewer/Scene/Cam/Tool/ToolWithHolderScene"
import { ToolInfo } from "src/graphql/generated"
import { useTransferInvalidate } from "src/hooks/transferCanvas/useTransferCanvas"
import { toolsSelectors } from "src/store/config/tools"
import { useCuttingSimDisplayStore } from "src/store/zustandCuttingSim"
import { useMachineCoordsStore, useMountPartTransform } from "src/store/zustandMachine"
import { NcEventIntervals } from "./stockSimDefs"
import { StockSimToolPath } from "./StockSimToolPath"
import { createToolPathIndices } from "./toolPathVis"

interface StockSimToolPathsProps {
  toolPathCbor?: StockAnalysisToolPathData
  ncEventIntervals: NcEventIntervals
}

function toolQuat(toolAxis: THREE.Vector3): THREE.Quaternion {
  const quat = new THREE.Quaternion()
  quat.setFromUnitVectors(new THREE.Vector3(0, 0, 1), toolAxis)
  return quat
}

function invertPartTransform(mcs: THREE.Matrix4, mountPartTransform: THREE.Matrix4) {
  const transform = mcs.clone()
  transform.multiply(mountPartTransform.clone().invert())
  const translation = new THREE.Vector3()
  const quat = new THREE.Quaternion()
  const scale = new THREE.Vector3()
  transform.decompose(translation, quat, scale)
  return { translation, quat, scale }
}

function getTool(
  tools: ToolInfo[] | undefined,
  ncEventIntervals: NcEventIntervals,
  toolPathIdx: number
): ToolInfo | undefined {
  return tools?.find(tool => tool.id === ncEventIntervals.toolChanges[toolPathIdx]?.val)
}

export const StockSimToolPaths: FC<StockSimToolPathsProps> = ({
  toolPathCbor,
  ncEventIntervals,
}) => {
  const [toolPathIndices, setToolPathIndices] = useState<Array<[number, number] | undefined>>()
  const tools = useSelector(toolsSelectors.selectToolRecords)
  const { transferInvalidate } = useTransferInvalidate()

  const state = useCuttingSimDisplayStore()
  const selectedToolPaths = state.selectedToolPaths

  useEffect(() => {
    if (toolPathCbor && !toolPathIndices && state.showToolPath) {
      setToolPathIndices(createToolPathIndices(toolPathCbor))
    }
  }, [toolPathCbor, state.showToolPath, toolPathIndices, setToolPathIndices])

  const toolPathLines = useMemo(() => {
    if (toolPathCbor && toolPathIndices) {
      return toolPathIndices.map((toolPathInterval, idx) => {
        return (
          <StockSimToolPath
            key={idx}
            toolPathCbor={toolPathCbor}
            toolPathInterval={toolPathInterval}
            toolPathIdx={idx}
          />
        )
      })
    }
    return []
  }, [toolPathCbor, toolPathIndices])

  const { tool } = useMemo(() => {
    // Show the tool if only a single tool path is being displayed
    // and the tool change
    if (selectedToolPaths.length === 1) {
      if (selectedToolPaths[0] === state.cutData?.toolChange) {
        return {
          tool: getTool(tools, ncEventIntervals, selectedToolPaths[0]),
        }
      }
    }
    return { tool: undefined }
  }, [state.cutData?.toolChange, selectedToolPaths, ncEventIntervals, tools])

  useEffect(() => {
    transferInvalidate()
  }, [state.showToolPath, transferInvalidate])

  const mountPartTransform = useMountPartTransform()
  const { mcs } = useMachineCoordsStore().rtcpState

  const coordinateSystem = toolPathCbor?.coordinate_system
  const { translation: invPartTranslation, quat: invPartQuat } = useMemo(() => {
    if (coordinateSystem === CamDataCoordinateSystem.Attach) {
      const translation = new THREE.Vector3()
      const quat = new THREE.Quaternion()
      const scale = new THREE.Vector3()
      mcs.decompose(translation, quat, scale)
      return { translation, quat, scale }
    } else {
      return invertPartTransform(mcs, mountPartTransform)
    }
  }, [mcs, mountPartTransform, coordinateSystem])

  return (
    <>
      {state.showToolPath && (
        <group position={invPartTranslation} quaternion={invPartQuat}>
          {toolPathLines}
        </group>
      )}
      {state.cutData && state.showTool && (
        <group position={invPartTranslation} quaternion={invPartQuat}>
          <group
            position={state.cutData.toolPosition}
            quaternion={toolQuat(state.cutData.toolAxis)}
          >
            <ToolWithHolderScene tool={tool} showHolder={state.showHolder} compensateLength />
          </group>
        </group>
      )}
    </>
  )
}
