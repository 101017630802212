import { ITreeNode } from "@blueprintjs/core"
import { createAction, createReducer } from "@reduxjs/toolkit"

import { RootState } from "src/store/rootStore"

export interface FixtureTreeNode {
  label?: string
}

const setFixtureTreeNodes = createAction<ITreeNode<FixtureTreeNode>[]>(
  "fixture/setFixtureTreeNodes"
)
const setSelectedNode = createAction<{ id: string | undefined }>("fixture/setSelectedNode")
const removeNode = createAction<{ id: string }>("fixture/removeNode")
const appendChild = createAction<{ id: string; node: ITreeNode<FixtureTreeNode> }>(
  "fixture/appendChild"
)
const prependChild = createAction<{ id: string; node: ITreeNode<FixtureTreeNode> }>(
  "fixture/prependChild"
)
const insertAfter = createAction<{ id?: string; node: ITreeNode<FixtureTreeNode> }>(
  "fixture/insertAfter"
)
const insertBefore = createAction<{ id: string; node: ITreeNode<FixtureTreeNode> }>(
  "fixture/insertBefore"
)
const updateNode = createAction<{ node: ITreeNode<FixtureTreeNode> }>("fixture/updateNode")
const updateChildNode = createAction<{ node: ITreeNode<FixtureTreeNode> }>(
  "fixture/updateChildNode"
)

export const fixtureReducer = createReducer<{
  fixtureTreeNodes: ITreeNode<FixtureTreeNode>[]
  selectedNode?: string | undefined
}>(
  {
    fixtureTreeNodes: [],
  },
  builder => {
    builder.addCase(setFixtureTreeNodes, (state, action) => {
      state.fixtureTreeNodes = action.payload
    })

    builder.addCase(setSelectedNode, (state, action) => {
      state.selectedNode = action.payload.id
    })

    builder.addCase(removeNode, (state, action) => {
      const id = action.payload.id
      const parentLevelNodeIndex = state.fixtureTreeNodes.findIndex(node => node.id === id)
      if (parentLevelNodeIndex >= 0) {
        state.fixtureTreeNodes.splice(parentLevelNodeIndex, 1)
      } else {
        let level1Index = -1
        let level2Index = -1
        let level3Index = -1
        state.fixtureTreeNodes.forEach((level1Node, i) => {
          level1Node.childNodes?.forEach((level2Node, j) => {
            if (level2Node.id === id) {
              level1Index = i
              level2Index = j
              state.fixtureTreeNodes[level1Index].childNodes?.splice(level2Index, 1)
            } else {
              level2Node.childNodes?.forEach((level3Node, k) => {
                if (level3Node.id === id) {
                  level1Index = i
                  level2Index = j
                  level3Index = k
                  state.fixtureTreeNodes[level1Index].childNodes?.[level2Index].childNodes?.splice(
                    level3Index,
                    1
                  )
                }
              })
            }
          })
        })
      }
    })

    builder.addCase(appendChild, (state, action) => {
      const { id, node: newNode } = action.payload

      const level1Nodes = state.fixtureTreeNodes?.map(level1Node => {
        const level2Nodes = level1Node.childNodes?.length ? level1Node.childNodes : []

        const updatedLevel2Nodes = level2Nodes.map(level2Node => {
          const level3Nodes = level2Node.childNodes?.length ? level2Node.childNodes : []

          return level2Node.id === id
            ? { ...level2Node, childNodes: [...level3Nodes, newNode] }
            : level2Node
        })
        return level1Node.id === id
          ? { ...level1Node, childNodes: [...updatedLevel2Nodes, newNode] }
          : { ...level1Node, childNodes: updatedLevel2Nodes }
      }) as ITreeNode<FixtureTreeNode>[]

      state.fixtureTreeNodes = level1Nodes
    })

    builder.addCase(prependChild, (state, action) => {
      const { id, node: childNode } = action.payload

      const updatedNodes = state.fixtureTreeNodes?.map(node => {
        const childNodes = node.childNodes?.length ? node.childNodes : []
        return node.id === id ? { ...node, childNodes: [childNode, ...childNodes] } : node
      }) as ITreeNode<FixtureTreeNode>[]
      state.fixtureTreeNodes = updatedNodes
    })

    builder.addCase(insertAfter, (state, action) => {
      const { id, node } = action.payload
      const index = state.fixtureTreeNodes.findIndex(node => node.id === id)
      if (index > -1) {
        state.fixtureTreeNodes.splice(index + 1, 0, node)
      } else {
        state.fixtureTreeNodes.push(node)
      }
    })

    builder.addCase(insertBefore, (state, action) => {
      const { id, node } = action.payload
      const index = state.fixtureTreeNodes.findIndex(node => node.id === id)
      state.fixtureTreeNodes.splice(index, 0, node)
    })

    builder.addCase(updateNode, (state, action) => {
      const { node } = action.payload
      const index = state.fixtureTreeNodes.findIndex(({ id }) => node.id === id)
      state.fixtureTreeNodes[index] = node
    })

    builder.addCase(updateChildNode, (state, action) => {
      const { node } = action.payload
      let level1Index = -1
      let level2Index = -1
      let level3Index = -1
      state.fixtureTreeNodes.forEach((level1Node, i) => {
        level1Node.childNodes?.forEach((level2Node, j) => {
          if (node.id === level2Node.id) {
            level1Index = i
            level2Index = j
            state.fixtureTreeNodes[level1Index].childNodes?.splice(level2Index, 1, node)
          }
          level2Node.childNodes?.forEach((level3Node, k) => {
            if (node.id === level3Node.id) {
              level1Index = i
              level2Index = j
              level3Index = k
              state.fixtureTreeNodes[level1Index].childNodes?.[level2Index].childNodes?.splice(
                level3Index,
                1,
                node
              )
            }
          })
        })
      })
    })
  }
)

/**
 * Actions
 */
export const fixtureActions = {
  setFixtureTreeNodes,
  setSelectedNode,
  removeNode,
  appendChild,
  prependChild,
  insertAfter,
  insertBefore,
  updateNode,
  updateChildNode,
}

/**
 * Selectors
 */
const selectFixtureTreeNodes = (state: RootState): ITreeNode<FixtureTreeNode>[] | undefined =>
  state.fixture.fixtureTreeNodes
const selectSelectedNode = (state: RootState): string | undefined => state.fixture.selectedNode

export const fixtureSelectors = {
  selectFixtureTreeNodes,
  selectSelectedNode,
}
