import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Checkbox, ControlGroup, FormGroup, Label } from "@blueprintjs/core"

import { AppModelsGeometryTransform as Transform } from "src/client-axios"
import { FormulaInput } from "src/components/Generic/Forms/FormulaInput/FormulaInput"
import { viewOptionsSelectors } from "src/store/ui/viewOptions"
import { transformIncrementally } from "src/util/geometry/transforms"

import styles from "./TransformInput.module.css"

export const TransformInput: FC<{
  transform: Transform
  label?: string
  onChange: (transform: Transform) => void
  showIncremental?: boolean
  mcs?: Transform
  wcs?: Transform
  positionOnly?: boolean
  locked?: boolean
}> = ({ transform, label, onChange, showIncremental, mcs, wcs, positionOnly, locked }) => {
  const [incrementalTransform, setIncrementalTransform] = useState<Transform>({
    x: 0,
    y: 0,
    z: 0,
    i: 0,
    j: 0,
    k: 0,
  })

  useEffect(() => {
    setIncrementalTransform({
      x: 0,
      y: 0,
      z: 0,
      i: 0,
      j: 0,
      k: 0,
    })
  }, [transform])

  const [incrementalMode, setIncrementalMode] = useState(false)
  const axisMovementChanged = useSelector(viewOptionsSelectors.axisMovementChanged)

  const { x, y, z, i, j, k } = incrementalMode ? incrementalTransform : transform

  const onChangeInternal = (newVal: Transform) => {
    if (incrementalMode) {
      const newTransform = transformIncrementally(transform, newVal, mcs, wcs)
      onChange(newTransform)
    } else {
      onChange(newVal)
    }
  }

  const formatNumber = (number: number) => {
    return number
      .toFixed(4)
      .replace(/([0-9]\.[0-9]*[1-9])0+$/g, "$1")
      .replace(/([0-9])\.0*$/g, "$1")
  }

  return (
    <>
      {label && <Label style={{ margin: "0" }}>{label}:</Label>}
      {showIncremental && !locked && (
        <Checkbox
          checked={incrementalMode}
          label="Incremental"
          onChange={() => setIncrementalMode(!incrementalMode)}
        />
      )}
      <FormGroup
        inline
        className={locked ? styles.lockedLabel : styles.fullWidthForm}
        label={
          <>
            <br />
            <div className={styles.x}>X</div>
            <br />
            <div className={styles.y}>Y</div>
            <br />
            <div className={styles.z}>Z</div>
          </>
        }
        labelFor={"form-position-rotation"}
      >
        <ControlGroup id={"form-position-rotation"} vertical fill>
          <ControlGroup fill>
            <Label>
              Position
              {!locked ? (
                <PositionInput
                  focus={axisMovementChanged === "x"}
                  commitOnBlur={!incrementalMode}
                  reset={incrementalMode}
                  value={x}
                  onValueChange={v => onChangeInternal({ x: v, y, z, i, j, k })}
                  disabled={locked}
                />
              ) : (
                <input disabled value={formatNumber(x)} className={styles.lockedInput} />
              )}
            </Label>
            {!positionOnly && (
              <Label>
                Rotation
                {!locked ? (
                  <RotationInput
                    focus={axisMovementChanged === "i"}
                    commitOnBlur={!incrementalMode}
                    reset={incrementalMode}
                    value={i}
                    onValueChange={v => onChangeInternal({ x, y, z, i: v, j, k })}
                    disabled={locked}
                  />
                ) : (
                  <input disabled value={formatNumber(i)} className={styles.lockedInput} />
                )}
              </Label>
            )}
          </ControlGroup>
          <ControlGroup fill>
            {!locked ? (
              <PositionInput
                focus={axisMovementChanged === "y"}
                commitOnBlur={!incrementalMode}
                reset={incrementalMode}
                value={y}
                onValueChange={v => onChangeInternal({ x, y: v, z, i, j, k })}
                disabled={locked}
              />
            ) : (
              <input disabled value={formatNumber(y)} className={styles.lockedInput} />
            )}
            {!positionOnly && (
              <>
                {!locked ? (
                  <RotationInput
                    focus={axisMovementChanged === "j"}
                    commitOnBlur={!incrementalMode}
                    reset={incrementalMode}
                    value={j}
                    onValueChange={v => onChangeInternal({ x, y, z, i, j: v, k })}
                    disabled={locked}
                  />
                ) : (
                  <input disabled value={formatNumber(j)} className={styles.lockedInput} />
                )}
              </>
            )}
          </ControlGroup>
          <ControlGroup fill>
            {!locked ? (
              <PositionInput
                focus={axisMovementChanged === "z"}
                commitOnBlur={!incrementalMode}
                reset={incrementalMode}
                value={z}
                onValueChange={v => onChangeInternal({ x, y, z: v, i, j, k })}
                disabled={locked}
              />
            ) : (
              <input disabled value={formatNumber(z)} className={styles.lockedInput} />
            )}
            {!positionOnly && (
              <>
                {!locked ? (
                  <RotationInput
                    focus={axisMovementChanged === "k"}
                    commitOnBlur={!incrementalMode}
                    reset={incrementalMode}
                    value={k}
                    onValueChange={v => onChangeInternal({ x, y, z, i, j, k: v })}
                    disabled={locked}
                  />
                ) : (
                  <input disabled value={formatNumber(k)} className={styles.lockedInput} />
                )}
              </>
            )}
          </ControlGroup>
        </ControlGroup>
      </FormGroup>
    </>
  )
}

const PositionInput: FC<React.ComponentProps<typeof FormulaInput>> = props => {
  return <FormulaInput {...props} fill />
}

export const RotationInput: FC<React.ComponentProps<typeof FormulaInput>> = props => {
  return <FormulaInput {...props} fill stepSize={15} majorStepSize={90} />
}
