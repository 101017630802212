import React, { FC } from "react"
import { Button, Callout, Classes, Icon, Position } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import styles from "./ManufacturingConfirm.module.css"

export const ManufacturingConfirm: FC<{
  actionText: string
  nextVersion: string
  showWarning: boolean
  disabled?: boolean
  handleRequestClose: () => void
  handleConfirmClick: () => void
}> = ({
  actionText,
  nextVersion,
  showWarning,
  disabled,
  handleRequestClose,
  handleConfirmClick,
}) => {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <div className={styles.actions}>
          <div>
            <Button onClick={handleRequestClose}>Cancel</Button>
            <Button
              className={disabled ? Classes.DISABLED : ""}
              intent={showWarning ? "danger" : "primary"}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (!disabled || event.ctrlKey || (event.shiftKey && event.altKey)) {
                  handleConfirmClick()
                }
              }}
            >
              {showWarning ? `${actionText}${disabled ? "" : " Anyway"}` : `${actionText}`}
            </Button>
          </div>
          <div className={styles.version}>At version: {nextVersion}</div>
        </div>
      </div>
    </div>
  )
}

export const ManufacturingChecksStatus: FC<{ actionText: string }> = ({ actionText }) => {
  return (
    <Callout icon={"tick-circle"} intent={"success"} title={`Ready To ${actionText}`}>
      <div style={{ display: "flex" }}>
        {`All pre-${actionText.toLowerCase()} checks are passing.`}{" "}
        <div className={styles.tooltip}>
          <Tooltip2
            hoverOpenDelay={300}
            content={`Pre-${actionText.toLowerCase()} checks will not catch all possible issues.`}
            openOnTargetFocus={false}
            position={Position.RIGHT}
          >
            <Icon icon={"help"} iconSize={14} />
          </Tooltip2>
        </div>
      </div>
    </Callout>
  )
}
