import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Spinner, Switch } from "@blueprintjs/core"
import { format } from "date-fns"
import { Matrix4 } from "three"

import { ExportCoordinates } from "src/client-axios"
import { DesignConfigSelect } from "src/components/Cam/TreeNavigator/ConfigPanel/props"
import { UploadModelDialog } from "src/components/Cam/UploadModelDialog/UploadModelDialog"
import { Modal } from "src/components/Generic/Modal/Modal"
import { Thumbnail } from "src/components/Generic/Thumbnail/Thumbnail"
import {
  useDeleteDesignMutation,
  useModelByIdLazyQuery,
  useUpdateCamOperationDesignMutation,
  useUpdateCamOperationMcsMutation,
  useUpdateCamOperationWcsMutation,
  useUpdateInputStockTransformMutation,
} from "src/graphql/generated"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { useMachineCoordsStore } from "src/store/zustandMachine"
import { MatrixTransform, transformToMatrix } from "src/util/geometry/transforms"

import styles from "./DesignConfigPanel.module.css"

export const DesignConfigPanel: FC<{ selection: DesignConfigSelect; locked?: boolean }> = ({
  selection,
  locked,
}) => {
  const [showOverride, setShowOverride] = useState(false)

  const [getModel, { data: modelData }] = useModelByIdLazyQuery()

  const designId = useSelector(
    (state: RootState) =>
      storedPlansSelectors.selectOperation(state, selection.planId, selection.operationIdx)
        ?.designId
  )

  useEffect(() => {
    if (designId !== undefined) {
      getModel({ variables: { id: designId } })
    }
  }, [getModel, designId])

  if (designId !== undefined) {
    if (modelData === undefined) {
      return <Spinner />
    } else {
      return (
        <>
          <div className={styles.thumbnail}>
            <Thumbnail medium model={modelData.model3D} />
          </div>
          {
            <p>
              <strong>Filename: </strong> {modelData.model3D?.source?.basename} <br />
              <strong>Created at: </strong>
              {modelData.model3D?.source?.createdAt
                ? format(new Date(modelData.model3D?.source?.createdAt), "MMM d h:mma")
                : ""}
              <br />
              <strong>Created by: </strong> {modelData.model3D?.source?.createdBy}
            </p>
          }
          {modelData.model3D?.thumbnail?.exists === false ? (
            <Spinner />
          ) : (
            <>
              <p>
                <PartModelUpload
                  replace
                  operationIdx={selection.operationIdx}
                  planId={selection.planId}
                />
              </p>
              <p>
                <PartModelRemove operationIdx={selection.operationIdx} planId={selection.planId} />
              </p>
            </>
          )}
        </>
      )
    }
  }

  return (
    <>
      <Switch
        checked={showOverride}
        disabled={locked}
        label="Override Part Model"
        onChange={() => {
          setShowOverride(!showOverride)
        }}
      />
      {showOverride === false && (
        <>
          <p>This operation&apos;s part model is currently the job&apos;s part model.</p>
          <p>
            Enable the switch above to instead use a different desired output part model (i.e, for a
            custom fixture op)
          </p>
        </>
      )}
      {showOverride && (
        <>
          <p>
            <PartModelUpload operationIdx={selection.operationIdx} planId={selection.planId} />
          </p>
        </>
      )}
    </>
  )
}

export const PartModelUpload: FC<{
  planId: string
  operationIdx: number
  replace?: boolean
}> = ({ planId, operationIdx, replace }) => {
  const [modalOpen, setIsModalOpen] = useState(false)

  const {
    settings: { exportCoordinates: coordinates },
  } = useLocalStorageSettings()

  const inputStockTransformInput = useSelector(
    (state: RootState) =>
      storedOperationSelectors.selectInputStock(state, planId, operationIdx, true)?.transform
  )

  const machineCoordStore = useMachineCoordsStore()
  const [updateDesign] = useUpdateCamOperationDesignMutation()
  const [updateMcs] = useUpdateCamOperationMcsMutation()
  const [updateWcs] = useUpdateCamOperationWcsMutation()
  const [updateInputStockTransform] = useUpdateInputStockTransformMutation()

  const mcs = machineCoordStore.rtcpState.mcs
  const wcs = machineCoordStore.rtcpState.wcs

  const errorToast = useNetworkErrorToast()

  const onModelCreation = (modelId: string, _: string) => {
    updateDesign({
      variables: {
        planId,
        operationIdx,
        designId: modelId,
      },
    })
      .then(() => {
        // Use the export coordinates option to position the imported part
        // The imported part's coordinate system is expected to match with the
        // selection in the export coordinates
        // Also apply any relative transform specified during import
        const relativeTransform = new MatrixTransform(
          coordinates === ExportCoordinates.Part
            ? new Matrix4()
            : coordinates === ExportCoordinates.Wcs
            ? wcs.clone().invert()
            : mcs.clone().invert()
        )

        const newMcs = relativeTransform.multiply(new MatrixTransform(mcs))

        updateMcs({
          variables: {
            planId,
            operationIdx,
            transform: newMcs,
          },
        })

        // Only update the part's location
        // The stock and WCS transform should remain the same
        // But, since they are relative to the part, re-apply transforms to get them
        // back to their original location
        const inputStockMatrix = transformToMatrix(inputStockTransformInput)

        const newInputStockMatrix = relativeTransform.multiply(
          new MatrixTransform(inputStockMatrix)
        )

        updateInputStockTransform({
          variables: {
            planId,
            operationIdx,
            transform: newInputStockMatrix,
          },
        })

        const newWcs = relativeTransform
          .invert()
          .multiply(new MatrixTransform(wcs.clone().invert()))

        updateWcs({
          variables: {
            planId,
            operationIdx,
            transform: newWcs.invert(),
          },
        })

        setIsModalOpen(false)
      })
      .catch(error => errorToast(error, "Upload Design Model Error"))
  }

  return (
    <>
      <Button
        text={replace ? "Replace Custom Part Model" : "Upload Custom Part Model"}
        intent={replace ? "none" : "primary"}
        onClick={(
          e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLElement, MouseEvent>
        ) => {
          e.stopPropagation()
          setIsModalOpen(true)
        }}
      ></Button>
      {modalOpen && (
        <UploadModelDialog
          onModelCreation={onModelCreation}
          isOpen={modalOpen}
          handleRequestClose={() => setIsModalOpen(false)}
          title={"Upload Custom Part Model"}
          requireName={false}
        />
      )}
    </>
  )
}

export const PartModelRemove: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const [modalOpen, setIsModalOpen] = useState(false)

  const [removeModel] = useDeleteDesignMutation()
  const errorToast = useNetworkErrorToast()

  const handleRemove = () => {
    removeModel({ variables: { planId, operationIdx } })
      .then(() => {
        setIsModalOpen(false)
      })
      .catch(error => errorToast(error, "Remove Design Model Error"))
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button intent="danger" onClick={() => setIsModalOpen(true)}>
        Remove Custom Part Model
      </Button>
      <Modal
        iconName={"high-priority"}
        isOpen={modalOpen}
        headerText="Remove Custom Part Model"
        bodyText="This will remove the custom part model from this operation, reverting to using the part model from the job.  Are you sure you want to remove this custom part model?"
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        handleCancel={handleCancel}
        handleConfirm={handleRemove}
        handleRequestClose={handleCancel}
      />
    </>
  )
}
