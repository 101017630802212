import { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { isEqual } from "lodash-es"

import { ProbingIssue } from "src/client-axios"
import {
  ProbingFeedbackLazyQueryHookResult,
  ProbingFeedbackQuery,
  useProbingFeedbackLazyQuery,
} from "src/graphql/generated"
import { activeSelectors } from "src/store/cam/active"
import { storedOperationSelectors, storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { ProbingSection } from "src/util/cam/probing/probingTypes"

type useGetProbingFeedbackType = Pick<ProbingFeedbackLazyQueryHookResult[1], "refetch"> & {
  getSectionProbingIssues: (sectionId?: string) => ProbingIssue[]
  operationProbingFeedback: ProbingFeedbackQuery["validProbingFeedback"] | undefined
}
export const useGetProbingFeedback = (planOp?: {
  planId: string
  operationIdx: number
}): useGetProbingFeedbackType => {
  const [getProbingFeedback, { data: probingFeedback, refetch }] = useProbingFeedbackLazyQuery()

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const planId = planOp?.planId ?? activePlanId

  const activeOperationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const operationId = useSelector(
    (state: RootState) =>
      storedPlansSelectors.selectOperation(
        state,
        planId,
        planOp?.operationIdx ?? activeOperationIdx
      ),
    isEqual
  )?.id

  const strategy = useSelector((state: RootState) =>
    storedOperationSelectors.selectProbing(state, planId, operationId)
  )?.strategy

  const sections = strategy && (Object.values(strategy).flat() as ProbingSection[])

  const getSectionProbingIssues = useCallback(
    (sectionId?: string) =>
      (probingFeedback?.validProbingFeedback.find(
        probingFeedback => probingFeedback.sectionId === sectionId
      )?.feedback.issues as unknown) as ProbingIssue[],
    [probingFeedback]
  )

  const operationProbingFeedback = useMemo(
    () =>
      probingFeedback?.validProbingFeedback.filter(probingFeedback =>
        sections?.some(section => section.id === probingFeedback.sectionId)
      ),
    [probingFeedback, sections]
  )

  useEffect(() => {
    if (planId && operationId) {
      getProbingFeedback({ variables: { planId, operationId } })
    }
  }, [getProbingFeedback, operationId, planId])

  return {
    getSectionProbingIssues,
    operationProbingFeedback,
    refetch,
  }
}
