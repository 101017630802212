import React, { FC } from "react"
import { Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

import styles from "./SetupSheetLink.module.css"

export const SetupSheetLink: FC<{ revision: number }> = ({ revision }) => {
  const params = new URLSearchParams(window.location.search)

  params.set(`revision`, `${revision}`)

  const newParamsString = params.toString()

  const url = newParamsString.length > 0 ? `?${newParamsString}` : ``
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Icon icon={IconNames.EYE_OPEN} className={styles.icon} />
    </a>
  )
}
