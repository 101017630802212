import React, { FC } from "react"
import { PathSection, Point2, ToolScene } from "@subscale/formlogic-opscene"

import { ModelHolderScene } from "src/components/Canvas/Viewer/Scene/Cam/Tool/HolderScene"
import { Model, ToolInfo } from "src/graphql/generated"
import { profileOffsetZ, profileTranslateZ, toolShapeFromInfo } from "src/util/opsceneTool"
import { isModel, isSor } from "./util"

function isPoint2(section: PathSection): section is Point2 {
  return "x" in section
}

function getFirstPoint(profile: PathSection[]): Point2 {
  return profile.find(isPoint2) as Point2
}

function getLastPoint(profile: PathSection[]): Point2 {
  for (let i = profile.length - 1; i >= 0; i--) {
    if (isPoint2(profile[i])) {
      return profile[i] as Point2
    }
  }
  throw new Error("Unreachable")
}

function sorProfileIsDecreasing(profile: PathSection[]) {
  const p1 = getFirstPoint(profile)
  const p2 = getLastPoint(profile)
  return p1.z > p2.z
}

function reverseProfile(profile: PathSection[]): PathSection[] {
  const newProfile = profile.map(section => {
    const res = { ...section }
    if ("direction" in res) {
      if (res.direction === "CCW") {
        res.direction = "CW"
      } else {
        res.direction = "CCW"
      }
    }
    return res
  })
  newProfile.reverse()
  return newProfile
}

export const ToolWithHolderScene: FC<{
  tool: ToolInfo | undefined
  showHolder?: boolean
  showAlternate?: boolean
  compensateLength?: boolean
  holderModelKeyLookup?: (model: Model) => string
}> = ({ tool, showHolder, showAlternate, compensateLength, holderModelKeyLookup }) => {
  if (!tool) return null

  const modelHolders = tool.holders.filter(isModel)
  const sorHolders = showHolder ? tool.holders.filter(isSor) : []

  // The following ignores cutter and holders (Z) origin. Both profiles are
  // translated to start at Z == 0. After translation the first section's Z is
  // 0, and subsequent sections are expected to have positive and increasing Z.
  return (
    <>
      <group position-z={compensateLength ? tool.len : 0}>
        {modelHolders.map((modelHolder, index) => (
          <ModelHolderScene
            key={`${modelHolder.filename}-${index}`}
            modelHolder={modelHolder}
            hideHolderModel={!showHolder}
            holderModelKeyLookup={holderModelKeyLookup}
          />
        ))}
        {sorHolders.map((model, index) => {
          // Some SOR profiles start at z=0 and decrease in z.
          // To render properly, they need to be reversed
          let profile = [...model.data.profile] as PathSection[]
          if (sorProfileIsDecreasing(profile)) {
            profile = reverseProfile(profile)
          }

          const zOffset = profileOffsetZ(profile)
          const toolShape = {
            profile: profileTranslateZ(profile, -zOffset),
            flute_length: -1e6,
            alternate: null,
          }
          const key = `${tool.id}-${model.id}-${index}`
          return (
            <group key={key} position-z={zOffset}>
              <ToolScene
                key={key}
                tool={toolShape}
                alternate={false}
                fluteColor="#262626"
                shankColor="#262626"
                sharedMaterial={{
                  opacity: 1.0,
                  polygonOffset: true,
                  polygonOffsetFactor: 1,
                  transparent: false,
                  metalness: 0.95,
                  roughness: 0.6,
                  flatShading: false,
                }}
              />
            </group>
          )
        })}
      </group>

      <group position-z={compensateLength ? 0 : -tool.len}>
        <ToolScene tool={toolShapeFromInfo(tool)} alternate={showAlternate ?? false} />
      </group>
    </>
  )
}
