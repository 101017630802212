const colors = [
  "#009fbd",
  "#ff6eff",
  "#fecd34",
  "#ED6A4A",
  "#a656c2",
  "#ff3333",
  "#1d9c33",
  "#ed2861",
  "#fdb228",
  "#d77133",
]

export const toolColor = (toolId: number): string => {
  const x = toolId + 2
  const colorIdx = x % colors.length
  return colors[colorIdx]
}

const pointColors = ["#00C49A", "#473BF0", "#2F9FFA", "#8D0FA6"]

export function featureColor(index: number): string {
  const colorIdx = index % pointColors.length
  return pointColors[colorIdx]
}

export const X_COLOR = "#710000"
export const Y_COLOR = "#004000"
export const Z_COLOR = "#000071"
