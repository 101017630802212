import React, { FC } from "react"
import * as THREE from "three"

import { Trihedron } from "./Trihedron"

const ORIGIN = new THREE.Vector3(0, 0, 0)

const makeColor = (c: [number, number, number]) => {
  const v = [
    Math.floor(Math.pow(c[0] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[1] / 255, 2.0) * 255),
    Math.floor(Math.pow(c[2] / 255, 2.0) * 255),
  ]
  return `rgb(${v[0]}, ${v[1]}, ${v[2]})`
}

const R = 0.5

const ORIGIN_COLOR = new THREE.Color(makeColor([0, 0, 0]))

const meshMaterial = new THREE.MeshStandardMaterial({
  color: ORIGIN_COLOR,
  flatShading: false,
  metalness: 0.69,
  opacity: 1.0,
  roughness: 0.05,
})

export const StockSimFcs: FC<{ showOrigin: boolean }> = ({ showOrigin }) => {
  let origin
  if (showOrigin) {
    origin = (
      <mesh key={"origin"} position={ORIGIN}>
        <sphereGeometry args={[R * 1.5, 32, 16]} />
        <primitive object={meshMaterial} attach={"material"} />
      </mesh>
    )
  }

  return (
    <group>
      <Trihedron radius={R} length={10} />
      {origin && origin}
    </group>
  )
}
