import React, { FC } from "react"
import { ApolloError } from "@apollo/client"
import { NonIdealState } from "@blueprintjs/core"

import { Camjobstatus, OverviewNodeFragment } from "src/graphql/generated"
import { JobsTable } from "./JobsTable/JobsTable"
import { ApolloQueryView } from "./ApolloQueryView"

const JobsPanel: FC<{
  rows?: OverviewNodeFragment[]
  selectedTabId: Camjobstatus
  error?: ApolloError
  loading: boolean
}> = ({ rows, selectedTabId, error, loading }) => {
  const filteredRows = rows?.filter(node => node.job.status === selectedTabId) ?? []

  return (
    <>
      <ApolloQueryView
        error={error}
        loading={loading || filteredRows == null}
        loadingProps={{ title: "Loading", description: "Loading CAM jobs..." }}
      >
        {filteredRows?.length ? (
          <JobsTable {...{ camJobs: filteredRows, selectedTabId }} />
        ) : (
          <NonIdealState
            icon={"search"}
            title={"There are no CAM jobs meeting the current filters"}
          />
        )}
      </ApolloQueryView>
    </>
  )
}

export default JobsPanel
