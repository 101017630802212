import React, { FC, useMemo, useState } from "react"
import { Sphere } from "@react-three/drei"
import { useThree } from "@react-three/fiber"
import * as THREE from "three"
import { OrthographicCamera } from "three"

import { useInspectorContext } from "../../hooks/useInspectorContext"
import { CONTROL_UPDATE } from "../../util/constants"
import { colorMaterial, TRANSPARENT_MATERIAL } from "../OpScene/Tool/ToolScene"
import { getSelectedPoint } from "./clickUtils"
import { useEventListener } from "./useEventListener"
import { useSceneClickEventHandler } from "./useSceneClickEventHandler"
import { getClickedIntersection } from "./util"

export const Inspector: FC<{ showSelectionPoint: boolean }> = ({ showSelectionPoint }) => {
  const { raycaster, mouse, camera, scene } = useThree()

  const { selectedPoint1, setSelectedPoint1, setSelectedPoint2 } = useInspectorContext()

  const onClick = (event: PointerEvent) => {
    const mouseEvent = event

    raycaster.params.Line = { threshold: 3 }

    const clickData = {
      camera,
      raycaster,
      scene,
      mouse,
      mouseEvent: event as MouseEvent,
    }

    const intersection = getClickedIntersection(clickData)

    if (!intersection) {
      setSelectedPoint1(undefined)
      setSelectedPoint2(undefined)
      return
    }

    const clamp = !(mouseEvent.metaKey || mouseEvent.ctrlKey)
    const newSelectedPoint = getSelectedPoint(intersection, clamp)

    if (mouseEvent.shiftKey && selectedPoint1) {
      setSelectedPoint2(newSelectedPoint)
    } else {
      setSelectedPoint1(newSelectedPoint)
      setSelectedPoint2(undefined)
    }
  }

  useSceneClickEventHandler(onClick)

  return <>{showSelectionPoint && <InspectorScene />}</>
}

const InspectorScene: FC = () => {
  const { selectedPoint1, selectedPoint2 } = useInspectorContext()

  const [radius, setRadius] = useState(1.25)

  const { camera, gl } = useThree()

  useEventListener(
    CONTROL_UPDATE,
    () => {
      const initialRadius = 1.25

      if (camera instanceof OrthographicCamera) {
        setRadius(
          (2 * initialRadius * (camera.right - camera.left)) /
            (camera.zoom * gl.domElement.clientWidth)
        )
      }
    },
    gl.domElement
  )

  const lineGeometries = useMemo(() => {
    if (!(selectedPoint1 && selectedPoint2)) return []

    const [x1, y1, z1] = selectedPoint1.point
    const [x2, y2, z2] = selectedPoint2.point
    const start = new THREE.Vector3(x1, y1, z1)
    const mid1 = new THREE.Vector3(x2, y1, z1)
    const mid2 = new THREE.Vector3(x2, y2, z1)
    const end = new THREE.Vector3(x2, y2, z2)

    const inputs: [THREE.Vector3, THREE.Vector3, string, string][] = [
      [start, end, "#000000", "Distance"],
      [start, mid1, "#FF0000", "DX"],
      [mid1, mid2, "#00FF00", "DY"],
      [mid2, end, "#0000FF", "DZ"],
    ]
    let filteredInputs = inputs
      .map(([p1, p2, color, key]) => {
        const geometry = new THREE.BufferGeometry().setFromPoints([p1, p2])
        const distance = new THREE.Vector3().subVectors(p1, p2).length()
        // const center = new THREE.Vector3().lerpVectors(p1, p2, 0.5)
        // const label = `${key}: ${distance.toFixed(3)}`
        return { geometry, distance, color, key }
      })
      .filter(item => {
        return item.distance > 1e-6
      })
    if (filteredInputs.length <= 2) {
      filteredInputs = filteredInputs.filter(item => item.key !== "Distance")
    }
    return filteredInputs
  }, [selectedPoint1, selectedPoint2])

  return (
    <>
      {selectedPoint1 && (
        <group position={selectedPoint1?.point}>
          <Sphere args={[radius, 32, 32]}>
            <meshBasicMaterial color={"#000000"} />
          </Sphere>
        </group>
      )}

      {selectedPoint2 && (
        <group position={selectedPoint2?.point}>
          <Sphere args={[radius, 32, 32]}>
            <meshBasicMaterial color={"#000000"} />
          </Sphere>
        </group>
      )}

      <group>
        {lineGeometries.map(({ geometry, color, key }) => (
          <React.Fragment key={key}>
            <lineSegments geometry={geometry}>
              <lineBasicMaterial {...colorMaterial(color)} {...TRANSPARENT_MATERIAL} />
            </lineSegments>
          </React.Fragment>
        ))}
      </group>
    </>
  )
}
