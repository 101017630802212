import React, { FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  EditableText,
  HTMLTable,
  IconName,
  Intent,
  Spinner,
} from "@blueprintjs/core"
import { AxiosError } from "axios"
import { format } from "date-fns"

import { JobUploadKindEnum } from "src/client-axios"
import { PdfPreview } from "src/components/Generic/PdfPreview/PdfPreview"
import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  useCamJobFilesQuery,
  useCreateCamJobFileMutation,
  useDeleteCamJobFileMutation,
  useUpdateCamJobFileMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { downloadFromUrl } from "src/util/files"

import styles from "./JobFiles.module.css"

interface JobFilesProps {
  jobId: string
}

interface CamFileNotes {
  jobId?: string
  notes?: string
}

export const JobFiles: FC<JobFilesProps> = ({ jobId }) => {
  const { data, loading, refetch } = useCamJobFilesQuery({ variables: { jobId } })
  const [deleteJobFile] = useDeleteCamJobFileMutation()
  const [updateCamJobFile] = useUpdateCamJobFileMutation()
  const toaster = useToaster()

  const [pdfUrl, setPdfUrl] = useState<string | undefined>()
  const [camJobNotes, setCamJobNotes] = useState<CamFileNotes>({
    jobId: undefined,
    notes: undefined,
  })

  const [toDelete, setDelete] = useState<string | undefined>()

  const { planchangerApi } = useApi()

  useWebsocketMessageListener(wsMessage => {
    if (
      wsMessage.type === "TaskProgress" &&
      wsMessage.jobId === jobId &&
      wsMessage.name.endsWith("parse_drawing") &&
      wsMessage.status === "Success"
    ) {
      refetch()
    }
  })

  const onDeleteConfirm = (jobFileId: string) => {
    deleteJobFile({ variables: { jobFileId } }).then(() => {
      refetch().then(() => {
        setDelete(undefined)
      })
    })
  }

  const downloadFile = (locator?: string) => {
    if (!locator) {
      toaster.show({ message: "No URL present", intent: "danger" })
      return
    }
    planchangerApi.urlFor_getFile(locator).then(url => downloadFromUrl(url.toString()))
  }

  const openPDFPreview = (locator?: string) => {
    if (locator) {
      planchangerApi.urlFor_getFile(locator, undefined, true).then(result => {
        setPdfUrl(result.toString())
      })
    }
  }

  const nodes = data?.camJobFiles?.nodes

  const handleUpdateCamFileNotes = (jobFileId: string) => {
    camJobNotes &&
      updateCamJobFile({ variables: { jobFileId, notes: camJobNotes.notes || "" } }).then(() => {
        refetch().then(() => {
          setCamJobNotes({})
        })
      })
  }

  if (loading) {
    return <Spinner />
  }

  if (nodes === undefined) {
    return <></>
  }

  const nodeDelete = toDelete ? nodes.find(val => val.id === toDelete) : undefined

  return (
    <>
      <HTMLTable striped={true} className={styles.fileTable}>
        <thead>
          <tr>
            <th>Filename</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {nodes
            .filter(val => val.drawings.nodes?.length === 0)
            .map(val => {
              return (
                <tr key={val.id}>
                  <td>{val.file?.basename}</td>
                  <td>{val.createdBy}</td>
                  <td>{format(new Date(val.createdAt), "MMM d h:mma")}</td>
                  <td>
                    <EditableText
                      multiline
                      className={styles.fileNote}
                      value={camJobNotes.jobId === val.id ? camJobNotes.notes : val.notes || ""}
                      onChange={value => setCamJobNotes({ jobId: val.id, notes: value })}
                      onConfirm={() => handleUpdateCamFileNotes(val.id)}
                      placeholder={"(No Notes)"}
                      selectAllOnFocus
                      onEdit={() => setCamJobNotes({ jobId: val.id, notes: val.notes || "" })}
                    />
                  </td>
                  <td>
                    <ButtonGroup>
                      {val.file?.basename.toLocaleLowerCase().endsWith("pdf") && (
                        <>
                          <Button
                            onClick={() => {
                              openPDFPreview(val.file?.locator)
                            }}
                            small={true}
                            icon="document-open"
                            intent="success"
                          >
                            PDF Preview
                          </Button>
                        </>
                      )}
                      <Button
                        onClick={() => {
                          downloadFile(val.file?.locator)
                        }}
                        small={true}
                        icon="download"
                        intent="primary"
                      >
                        Download
                      </Button>
                      <Button
                        onClick={() => setDelete(val.id)}
                        small={true}
                        icon="delete"
                        intent="danger"
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              )
            })}
          <tr>
            <td colSpan={5}>
              <ButtonGroup>
                <UploadFileButton jobId={jobId} refreshJobFiles={async () => refetch()} />
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </HTMLTable>
      {toDelete && (
        <Dialog
          icon={"error"}
          onClose={() => {
            setDelete(undefined)
          }}
          title={"Deleting File"}
          isOpen
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            Are you sure you want to delete <strong>{nodeDelete?.file?.basename}</strong>?
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setDelete(undefined)}>Cancel</Button>
              <Button onClick={() => onDeleteConfirm(toDelete)} intent="danger">
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <PdfPreview
        url={pdfUrl}
        onClose={() => {
          setPdfUrl(undefined)
        }}
      />
    </>
  )
}

const UploadFileButton: FC<{ jobId: string; refreshJobFiles: () => Promise<unknown> }> = ({
  jobId,
  refreshJobFiles,
}) => {
  const errorToast = useNetworkErrorToast()
  const { planchangerApi } = useApi()
  const [createJobFile] = useCreateCamJobFileMutation()

  const uploadFile = (file: File, setUploading: (uploading: boolean) => void) => {
    setUploading(true)
    planchangerApi
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .newFile(JSON.stringify({ kind: JobUploadKindEnum.Job, jobId }) as any, file)
      .then(result => {
        createJobFile({ variables: { jobId, fileId: result.data.id } }).then(() => {
          refreshJobFiles().then(() => {
            setUploading(false)
          })
        })
        console.log(result)
      })
      .catch((error: AxiosError) => {
        setUploading(false)
        errorToast(error, "Upload File Error")
      })
  }

  return (
    <DragDropButton
      noDragText={"Upload New File"}
      dragText={"Drop Files Here"}
      handleFile={uploadFile}
      icon={"upload"}
      intent={"success"}
    />
  )
}

const DragDropButton: FC<{
  handleFile: (acceptedFiles: File, setUploading: (uploading: boolean) => void) => void
  maxFiles?: number
  dragText: string
  noDragText: string
  icon?: IconName
  intent?: Intent
}> = ({ handleFile, dragText, noDragText, icon, intent, maxFiles }) => {
  const [isUploading, setUploading] = useState(false)

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      handleFile(file, setUploading)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles })
  return (
    <Button small icon={icon} intent={intent} className={styles.uploadFileButton}>
      <div {...getRootProps()} className={styles.dragDropRoot}>
        <input {...getInputProps()} />
        <div className={styles.uploadFileButtonContents}>
          {isUploading ? (
            <div className={"bp3-dark"}>
              <Spinner size={16} />
            </div>
          ) : (
            <div>{isDragActive ? dragText : noDragText}</div>
          )}
        </div>
      </div>
    </Button>
  )
}
