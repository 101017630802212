import React, { FC, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Classes, Dialog, HTMLTable, Spinner } from "@blueprintjs/core"
import { format } from "date-fns"

import { sendEvent } from "src/components/Websocket/Websocket"
import {
  PatchDb,
  useGetOperationHistoryQuery,
  useMesPublishingsQuery,
  usePlanHistoryQuery,
  useRevertCamPlanToRevisionMutation,
} from "src/graphql/generated"
import { SetupSheetLink } from "./SetupSheetLink/SetupSheetLink"

import styles from "./OperationHistory.module.css"

export const OperationHistory: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
}> = ({ planId, operationIdx, locked }) => {
  sendEvent("viewOperationHistory", { planId, operationIdx })

  const RESULTS_LIMIT = 100

  const [limit, setLimit] = useState<number>(RESULTS_LIMIT)
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true)

  const { data, loading, refetch } = useGetOperationHistoryQuery({
    variables: { planId, operationIdx, limit },
    fetchPolicy: "network-only",
  })

  const { data: mesPublishingData } = useMesPublishingsQuery({
    variables: { planId },
  })

  const [curRevision, setRevision] = useState<number | undefined>()
  const [isOpen, setIsOpen] = useState(false)

  const handleMoreClick = () => {
    setLimit(limit + RESULTS_LIMIT)
  }

  useEffect(() => {
    if (!loading && data?.getOperationHistory.length && data?.getOperationHistory.length < limit) {
      setShowMoreButton(false)
    }
  }, [data, loading, limit])

  // We include publishings and changes as one big list, sorted by date
  const tableRows = useMemo(() => {
    const planPatches = data?.getOperationHistory ?? []

    const publishing =
      mesPublishingData?.mesPublishes?.nodes.filter(val => val.operationIdx === operationIdx) ?? []

    const tableRows = [...planPatches, ...publishing]
    tableRows.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

    return tableRows
  }, [data, mesPublishingData, operationIdx])

  if (!tableRows.length) {
    return <Spinner />
  }

  return (
    <div className={styles.container}>
      <HTMLTable
        className={styles.planHistory}
        bordered={true}
        condensed={true}
        interactive={true}
        striped={true}
      >
        <thead>
          <tr>
            <th>Rev #</th>
            <th>View</th>
            <th>Changed By</th>
            <th>When</th>
            <th>Changed</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((val, i) => {
            if (val.__typename === "PatchDb") {
              return (
                <tr key={i}>
                  <td>
                    {val.revision}{" "}
                    <Button
                      icon={"undo"}
                      minimal
                      disabled={locked}
                      onClick={() => {
                        setRevision(val.revision)
                        setIsOpen(true)
                      }}
                    />
                  </td>
                  <td className={styles.setupSheetCell}>
                    <SetupSheetLink revision={val.revision} />
                  </td>
                  <td>{val.createdBy}</td>
                  <td title={val.createdAt as string}>
                    {format(new Date(val.createdAt as string), "MMM d h:mma")}
                  </td>
                  <td>
                    {val.from ? (
                      <>
                        <pre className={Classes.CODE_BLOCK}>{val.from}</pre> To{" "}
                      </>
                    ) : (
                      ""
                    )}
                    <OperationHistoryItemChangedLabel val={val} />
                  </td>
                </tr>
              )
            } else if (val.__typename === "MesPublish") {
              return (
                <tr className={styles.highlightRow} key={i}>
                  <td>
                    {val.revision}{" "}
                    <Button
                      icon={"undo"}
                      minimal
                      onClick={() => {
                        setRevision(val.revision)
                        setIsOpen(true)
                      }}
                    />
                  </td>
                  <td className={styles.setupSheetCell}>
                    <SetupSheetLink revision={val.revision} />
                  </td>
                  <td>{val.createdBy}</td>
                  <td title={val.createdAt}>{format(new Date(val.createdAt), "MMM d h:mma")}</td>
                  <td>v{val.version} published</td>
                </tr>
              )
            } else {
              return <></>
            }
          })}
        </tbody>
      </HTMLTable>

      {showMoreButton && (
        <Button onClick={handleMoreClick} className={styles.moreButton}>
          Load More
        </Button>
      )}

      {curRevision && (
        <RevertOperationDialog
          planId={planId}
          isOpen={isOpen}
          handleRequestClose={() => {
            refetch()
            setIsOpen(false)
          }}
          revision={curRevision}
          operationIdx={operationIdx}
        />
      )}
    </div>
  )
}

const opMatch = /operations\/(\d+)/

const RenderValue: FC<{
  value?: Record<string, unknown> | string | null
  path: string
  planId: string
}> = ({ value, path, planId }) => {
  if (path.endsWith("modelId") && typeof value === "string") {
    return <Link to={`/3d-viewer?modelId=${value}`}>{value}</Link>
  }

  if (path.endsWith("ncFileId") && typeof value === "string") {
    const match = path.match(opMatch)

    if (match) {
      return <Link to={`/nc-edit/${planId}/${match[1]}/${value}`}>{value}</Link>
    }
  }

  return <>{JSON.stringify(value, null, 2)}</>
}

const RevertOperationDialog: FC<{
  planId: string
  operationIdx: number
  isOpen: boolean
  revision: number
  handleRequestClose: () => void
}> = ({ planId, operationIdx, isOpen, revision, handleRequestClose }) => {
  const [revertToRevision] = useRevertCamPlanToRevisionMutation()

  const { refetch, data } = usePlanHistoryQuery({ variables: { planId } })

  const patch = data?.planPatchRecords?.nodes.find(val => val.revision === revision)

  useEffect(() => {
    refetch()
  }, [isOpen, refetch])

  const revert = () => {
    revertToRevision({
      variables: {
        planId,
        revision,
        operationIdx,
      },
    }).then(() => {
      refetch().then(() => {
        handleRequestClose()
      })
    })
  }

  if (patch === undefined) {
    return <></>
  }

  return (
    <Dialog
      icon={"undo"}
      onClose={handleRequestClose}
      title={"Revert to a Previous Revision"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>Are you sure you want to revert to revision #{patch.revision}?</p>
        <p>
          <strong>Created By:</strong> {patch.createdBy}
        </p>
        <p>
          <strong>Created At:</strong> {format(new Date(patch.createdAt), "MMM d h:mma")}
        </p>
        <p>
          <strong>Operation:</strong> {patch.op}
        </p>
        <strong>Path:</strong> <pre className={Classes.CODE_BLOCK}>{patch.path}</pre>
        <br />
        <strong>Value: </strong>
        <pre className={Classes.CODE_BLOCK}>
          <RenderValue value={patch.value} path={patch.path} planId={planId} />
        </pre>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose}>Close</Button>
          <Button onClick={revert} intent="warning">
            Revert
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const OperationHistoryItemChangedLabel: FC<{ val: PatchDb }> = ({ val }) => {
  let shortenedPath = val.path.split("/").slice(3).join("/")
  if (shortenedPath === "") {
    shortenedPath = "<operation>"
  }
  return (
    <pre className={Classes.CODE_BLOCK}>
      {val.op} {shortenedPath}
    </pre>
  )
}
