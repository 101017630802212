import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "@blueprintjs/core"
import { useCurrentUserData } from "@subscale/formlogic-auth-ts"
import { Maybe } from "graphql/jsutils/Maybe"

import { UnlockDialog } from "src/components/Cam/UnlockDialog/UnlockDialog"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { viewOptionsActions, viewOptionsSelectors } from "src/store/ui/viewOptions"

export const UnlockButton: FC<{
  operationId?: string
  locked?: boolean
  assignees?: Maybe<string[]> | undefined
}> = ({ operationId, locked, assignees }) => {
  const dispatch = useAppDispatch()
  const forceLocked = useSelector((state: RootState) =>
    viewOptionsSelectors.operationLocked(state, operationId)
  )
  const editingLocked = locked || forceLocked
  const [unlockUserOverrideModalIsOpen, setUnlockUserOverrideModalIsOpen] = useState<boolean>(false)
  const currentUserData = useCurrentUserData()

  const isAssignee = currentUserData?.email ? assignees?.includes(currentUserData.email) : false
  const canChangeLockState = isAssignee || !editingLocked

  const handleClickLock = () => {
    if (operationId) {
      if (forceLocked) {
        dispatch(viewOptionsActions.setOperationUnlocked(operationId))
      } else {
        dispatch(viewOptionsActions.setOperationLocked(operationId))
      }
    }
  }

  const handleVerifyClickLock = () => {
    if (canChangeLockState) {
      handleClickLock()
    } else {
      setUnlockUserOverrideModalIsOpen(true)
    }
  }

  const handlecloseUnlockModal = () => {
    setUnlockUserOverrideModalIsOpen(false)
  }

  return (
    <>
      <Button icon={editingLocked ? "lock" : "unlock"} minimal onClick={handleVerifyClickLock} />
      <UnlockDialog
        isOpen={unlockUserOverrideModalIsOpen}
        handleUnlock={handleClickLock}
        onClose={handlecloseUnlockModal}
      />
    </>
  )
}
