import React, { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { AnchorButton, ButtonGroup, Icon } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import {
  PointsFeatureMoveKindEnum,
  PointsFeaturePointKindEnum,
  PrecisionStrategy,
} from "src/client-axios"
import { useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import { useRevertCamPlanToRevisionMutation } from "src/graphql/generated"
import { useCameraControls } from "src/hooks/useCameraControls"
import { useHandleAddSteps } from "src/hooks/useHandleAddSteps"
import { useMoveProbeStep } from "src/pages/ViewerPage/Probing/ProbingProgramPanel/ProbingStepsTabPanel/useMoveProbeStep"
import {
  activeOperationSelectors,
  activeProbingSelectors,
  activeSelectors,
} from "src/store/cam/active"
import { revisionActions, revisionSelectors } from "src/store/cam/revision"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { storedPlanThunks, useProbingThunkContext } from "src/store/cam/storedPlanThunks"
import { RootState, useAppDispatch } from "src/store/rootStore"
import { ProbingSection, ProbingStep, WcsProbingStep } from "src/util/cam/probing/probingTypes"
import { useHandleCopyProbeFeature } from "../../Probing/ProbingProgramPanel/ProbingProgramTreeLabel/useHandleCopyProbeFeature"
import { useHandlePasteProbeFeature } from "../../Probing/ProbingProgramPanel/ProbingProgramTreeLabel/useHandlePasteProbeFeature"
import { usePreviousNextProbeStep } from "../../Probing/ProbingProgramPanel/ProbingStepsTabPanel/usePreviousNextProbeStep"
import { KeyboardControls } from "./KeyboardControls"

import styles from "./ControlsHelper.module.css"

interface ControlsCardProps {
  className?: string
  resetCallbackRef?: React.MutableRefObject<(() => void) | undefined>
}

export const UndoButtons: FC<{ className?: string; minimal?: boolean; locked?: boolean }> = ({
  className,
  minimal,
  locked,
}) => {
  const { onUndo, onRedo, canUndo, canRedo } = useUndoControls()

  return (
    <>
      <ButtonGroup className={className}>
        <Tooltip2
          hoverOpenDelay={500}
          content={"Undo Last Change"}
          position={"top"}
          openOnTargetFocus={false}
        >
          <AnchorButton
            icon={"undo"}
            disabled={!canUndo || locked}
            onClick={onUndo}
            minimal={minimal}
          />
        </Tooltip2>
        <Tooltip2
          hoverOpenDelay={500}
          content={"Redo Last Change"}
          position={"top"}
          openOnTargetFocus={false}
        >
          <AnchorButton
            icon={"redo"}
            disabled={!canRedo || locked}
            onClick={onRedo}
            minimal={minimal}
          />
        </Tooltip2>
      </ButtonGroup>
    </>
  )
}

const useUndoControls = (): {
  onUndo: () => void
  onRedo: () => void
  canUndo: boolean
  canRedo: boolean
} => {
  const dispatch = useAppDispatch()

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const revision = useSelector((state: RootState) =>
    activePlanId ? storedPlansSelectors.selectPlanRevision(state, activePlanId) : undefined
  )

  const undoHistory = useSelector(revisionSelectors.selectUndoHistory)
  const currentIndex = useSelector(revisionSelectors.selectCurrentIndex)
  const undoToken = useSelector(revisionSelectors.selectUndoToken)

  const [revertToRevision] = useRevertCamPlanToRevisionMutation()

  let undoIndex: number
  let redoIndex: number | undefined

  if (currentIndex === undefined) {
    undoIndex = undoHistory.length - 2
  } else {
    undoIndex = currentIndex - 1
    redoIndex = currentIndex + 1
  }

  const canUndo = undoIndex >= 0 && undoToken !== undefined

  const canRedo = redoIndex !== undefined && redoIndex <= undoHistory.length - 1

  const onUndo = () => {
    if (activePlanId !== undefined && revision !== undefined) {
      if (canUndo) {
        const toRevert = undoHistory[undoIndex]
        dispatch(revisionActions.updateIndex(undoIndex))
        revertToRevision({
          variables: {
            planId: activePlanId ?? "",
            revision: toRevert,
            changeToken: undoToken ?? "",
          },
        })
      }
    }
  }

  const onRedo = () => {
    if (redoIndex !== undefined && canRedo) {
      const toRevert = undoHistory[redoIndex]
      dispatch(revisionActions.updateIndex(redoIndex))
      revertToRevision({
        variables: {
          planId: activePlanId ?? "",
          revision: toRevert,
          changeToken: undoToken ?? "",
        },
      })
    }
  }

  return { onUndo, onRedo, canRedo, canUndo }
}

export const ControlsHelper: FC<ControlsCardProps> = ({ className, ...props }) => {
  const dispatch = useAppDispatch()
  const cameraControls = useCameraControls()

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)
  const revision = useSelector((state: RootState) =>
    activePlanId ? storedPlansSelectors.selectPlanRevision(state, activePlanId) : undefined
  )

  const undoHistory = useSelector(revisionSelectors.selectUndoHistory)
  const currentIndex = useSelector(revisionSelectors.selectCurrentIndex)
  const undoToken = useSelector(revisionSelectors.selectUndoToken)

  const { onUndo, onRedo } = useUndoControls()

  useEffect(() => {
    // This primes the undo history with the current revision
    // Normally this is hit when the overview page first loads
    if (undoHistory.length === 0 && activePlanId !== undefined && revision !== undefined) {
      dispatch(revisionActions.appendUndoRevision(revision))
    }
  }, [activePlanId, undoHistory.length, revision, dispatch])

  useWebsocketMessageListener(wsMessage => {
    // When new updates come through, we include them in the history
    if (wsMessage.type === "PlanUpdate" && activePlanId === wsMessage.planId) {
      if (revision && undoToken !== wsMessage.changeToken) {
        if (currentIndex !== undefined) {
          dispatch(revisionActions.pruneHistory())
        }
        dispatch(revisionActions.appendUndoRevision(revision))
      }
    }
  })

  const selectedProbeStep = useSelector(activeProbingSelectors.selectActiveProbingStep)

  const { handleCopyProbeFeature } = useHandleCopyProbeFeature()
  const { handlePasteProbeFeature } = useHandlePasteProbeFeature()
  const { moveProbeSteps } = useMoveProbeStep()
  const copiedProbeSteps = useSelector(activeProbingSelectors.selectActiveCopiedProbeSteps)
  const multipleSelectedProbeSteps = useSelector(
    activeProbingSelectors.selectMultipleSelectedProbeSteps
  )
  const { handleAddMove } = useHandleAddSteps()
  const { onPreviousNextProbeStep } = usePreviousNextProbeStep()

  const stepsToMove = multipleSelectedProbeSteps?.length
    ? multipleSelectedProbeSteps
    : selectedProbeStep
    ? [selectedProbeStep]
    : []

  const onCopy = () =>
    multipleSelectedProbeSteps?.length
      ? handleCopyProbeFeature()
      : handleCopyProbeFeature(selectedProbeStep)

  const strategy: PrecisionStrategy | undefined = useSelector(
    activeOperationSelectors.selectActiveProbing
  )?.strategy

  const selectedSection = useSelector(activeProbingSelectors.selectActiveProbingSection)
  const probingThunkContext = useProbingThunkContext()

  const onDelete = () => {
    let steps: (ProbingStep | WcsProbingStep)[] = []
    if (multipleSelectedProbeSteps?.length) {
      steps = multipleSelectedProbeSteps
    } else {
      steps = selectedProbeStep ? [selectedProbeStep] : []
    }

    if (
      (steps[0]?.kind === PointsFeaturePointKindEnum.PointsFeaturePoint ||
        steps[0]?.kind === PointsFeatureMoveKindEnum.PointsFeatureMove) &&
      strategy
    ) {
      Object.entries(strategy).forEach(([key, sectionList]) => {
        sectionList.forEach((section: ProbingSection, i: number) => {
          if (section.id === selectedSection?.id) {
            dispatch(
              storedPlanThunks.deleteSubProbingSteps({
                ...probingThunkContext,
                idsToRemove: new Set(...[steps.map(step => step.id)]),
                sectionName: key as "alignments" | "inspections",
                sectionIdx: i,
              })
            )
          }
        })
      })
    } else {
      dispatch(
        storedPlanThunks.deleteProbingSteps({
          ...probingThunkContext,
          idsToRemove: new Set(...[steps.map(step => step.id)]),
        })
      )
    }
  }

  return (
    <KeyboardControls
      {...props}
      onPreviousProbeStep={() => {
        onPreviousNextProbeStep(selectedProbeStep, -1)
      }}
      onNextProbeStep={() => onPreviousNextProbeStep(selectedProbeStep, 1)}
      onRedo={onRedo}
      onUndo={onUndo}
      onCopy={onCopy}
      onCut={() => {
        onCopy()
        onDelete()
      }}
      onPaste={() => handlePasteProbeFeature(copiedProbeSteps, selectedProbeStep, 1)}
      onAddMove={() => handleAddMove(selectedProbeStep)}
      onMoveProbeStepUp={() => moveProbeSteps(stepsToMove, -1)}
      onMoveProbeStepDown={() => moveProbeSteps(stepsToMove, 1)}
      dispatch={dispatch}
      cameraControls={cameraControls}
    >
      <Tooltip2
        content={
          <div>
            Orbit: Left Mouse <br />
            Pan: Shift + Left mouse; Right Mouse <br />
            Zoom: Mouse Wheel <br /> <br />
            {"Press '?' to see keyboard controls"}
          </div>
        }
        className={className}
        openOnTargetFocus={false}
      >
        <div className={`${styles.tooltip}`}>
          <span>Controls&nbsp;</span>
          <Icon icon="info-sign" iconSize={14} />
        </div>
      </Tooltip2>
    </KeyboardControls>
  )
}
