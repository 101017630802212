import React, { FC } from "react"
import { MenuItem } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { sendEvent } from "src/components/Websocket/Websocket"
import { useToaster } from "src/hooks/useToaster"

export const VericutLauncher: FC<{
  planId: string
  operationIdx: number
  projectLocator: string | undefined
}> = ({ planId, operationIdx, projectLocator }) => {
  const toaster = useToaster()

  const openVericut = () => {
    if (!projectLocator) return

    sendEvent("openVericut", {
      planId,
      operationIdx,
    })
    window.location.href =
      "remoteshop-vc:" +
      btoa(projectLocator).replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", ".")
    toaster.show({
      message: "Launching Vericut...",
      intent: "success",
    })
  }

  return (
    <Tooltip2
      placement={"right"}
      content={`${
        projectLocator ? "" : "Run a simulation to enable this choice. "
      }This will only work in the Formlogic lab.`}
      openOnTargetFocus={false}
    >
      <MenuItem disabled={!projectLocator} onClick={() => openVericut()} text={"Open in Vericut"} />
    </Tooltip2>
  )
}
