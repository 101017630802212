import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Button, Classes, Dialog } from "@blueprintjs/core"

import { SimulationKind } from "src/client-axios"
import { FileNodeFragment, useFileByLocatorQuery, useFilesByIDsQuery } from "src/graphql/generated"
import { SimulationMenu } from "src/pages/JobOverview/OperationCard/NcProgramInfo/NcProgramInfo"
import { activeSelectors } from "src/store/cam/active"
import { storedOperationSelectors } from "src/store/cam/storedPlans"

const MAX_CAM_CHANGES_TO_SHOW = 3

function useCuttingNcFile(planId: string, operationIdx: number): FileNodeFragment | undefined {
  const selectToolpathProject = useMemo(
    () => storedOperationSelectors.createSelectToolpathProject(planId, operationIdx),
    [planId, operationIdx]
  )

  const toolpathProject = useSelector(selectToolpathProject)

  const { data: toolpathProjectNcFile } = useFileByLocatorQuery({
    variables: {
      locator: toolpathProject?.ncFileLocator ?? "",
    },
  })

  const fileIds = []
  if (toolpathProjectNcFile?.fileByLocator?.id) fileIds.push(toolpathProjectNcFile.fileByLocator.id)

  const { data: filesData } = useFilesByIDsQuery({
    variables: {
      ids: fileIds,
    },
  })

  return filesData?.files?.nodes.filter(node => node.locator === toolpathProject?.ncFileLocator)[0]
}

export const CurrentOperationSimPanel: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const planId = useSelector(activeSelectors.selectActivePlanId)
  const operationIdx = useSelector(activeSelectors.selectActiveOperationIdx)
  const ncFile = useCuttingNcFile(planId ?? "", operationIdx ?? 0)

  return (
    <>
      {ncFile && (
        <SimulationMenu
          planId={planId ?? ""}
          operationIdx={operationIdx ?? 0}
          isOpen={isOpen}
          simulationKind={SimulationKind.CutInputStock}
          ncProgramLocator={ncFile.locator}
          onClose={onClose}
        />
      )}
    </>
  )
}

export const SimTaskRunRequiredCheck: FC<{
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
  reasons: string[]
}> = ({ isOpen, onSubmit, onClose, reasons }) => {
  return (
    <Dialog
      icon={"info-sign"}
      onClose={onClose}
      title={"Simulate Probing"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Operation setup has changed such that a Vericut simulation is required to properly
          simulate probing.
        </p>
        <blockquote>
          <ul>
            {reasons.slice(0, MAX_CAM_CHANGES_TO_SHOW).map((value, idx) => {
              return <li key={idx}>{value}</li>
            })}
          </ul>
        </blockquote>
        {reasons.length > MAX_CAM_CHANGES_TO_SHOW && (
          <blockquote>...And {reasons.length - MAX_CAM_CHANGES_TO_SHOW} more... </blockquote>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={"primary"} onClick={onSubmit}>
            Continue
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}
