import React, { FC } from "react"
import { Button, Classes, Dialog, IconName, Intent, MaybeElement } from "@blueprintjs/core"

import styles from "./Modal.module.css"

interface ModalProps {
  isOpen: boolean
  headerText?: string | JSX.Element
  headerRight?: string | JSX.Element
  className?: string
  headerTextClassName?: string
  buttonLeftClassName?: string
  buttonRightClassName?: string
  buttonsClassName?: string
  bodyText?: string
  cancelButtonText?: string
  confirmButtonText?: string
  handleCancel?: (event: React.SyntheticEvent) => void
  handleConfirm?: (event: React.SyntheticEvent) => void
  handleRequestClose: (event: React.SyntheticEvent) => void
  bodyComponent?: React.ReactNode
  confirmButtonIntent?: Intent
  iconName?: IconName | MaybeElement
  iconColor?: string
  style?: React.CSSProperties
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  headerText,
  buttonLeftClassName,
  buttonRightClassName,
  bodyText,
  cancelButtonText,
  confirmButtonText,
  handleCancel,
  handleConfirm,
  handleRequestClose,
  bodyComponent,
  confirmButtonIntent,
  iconName,
  buttonsClassName,
  style,
}) => {
  return (
    <Dialog
      icon={iconName ?? "help"}
      onClose={handleRequestClose}
      title={headerText}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
      style={style}
    >
      <>
        {bodyText && (
          <div className={`Classes.DIALOG_BODY ${styles.bodyTextContainer}`}>{bodyText}</div>
        )}
        {bodyComponent}

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div className={`${buttonsClassName || ""} ${styles.modalButtons}`}>
              {cancelButtonText && (
                <Button
                  onClick={handleCancel}
                  className={`${buttonLeftClassName || ""} ${styles.modalButtonLeft}`}
                >
                  {cancelButtonText}
                </Button>
              )}
              {confirmButtonText && (
                <Button
                  className={buttonRightClassName || ""}
                  intent={confirmButtonIntent || "danger"}
                  onClick={handleConfirm}
                >
                  {confirmButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    </Dialog>
  )
}
