import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  Dimension,
  PlanchangerApi,
  PlanSummary,
  ReplacePatchOperationOpEnum,
} from "src/client-axios"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { RootState } from "src/store/rootStore"

const fetchControlPlanById = createAsyncThunk(
  "storedControlPlans/fetchPlanById",
  async (
    {
      planId,
      planchangerApi,
      revision,
    }: {
      planId: string
      planchangerApi: PlanchangerApi
      revision?: number
    },
    thunkAPI
  ) => {
    try {
      await planchangerApi.getPlanchangerQualityControlPlan(planId, revision)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to fetch control plan: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

const updateDimensions = createAsyncThunk(
  "updateDimensions",
  async (
    {
      planId,
      planchangerApi,
      updatedDimensions,
      updatedDimIndices,
    }: {
      planId: string
      planchangerApi: PlanchangerApi
      updatedDimensions: Dimension[]
      updatedDimIndices: number[]
    },
    thunkAPI
  ) => {
    if (!planId) return

    const state = (await thunkAPI.getState()) as RootState
    const changeToken = activeSelectors.selectChangeToken(state)
    const operations = updatedDimensions.map((dim, index) => ({
      op: ReplacePatchOperationOpEnum.Replace,
      path: `/dimensions/${updatedDimIndices[index]}`,
      value: dim,
    }))

    try {
      await planchangerApi.applyQualityControlPlanPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to update control plan dimensions: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

const updateSummary = createAsyncThunk(
  "updateSummary",
  async (
    {
      planId,
      planchangerApi,
      updatedSummary,
    }: {
      planId: string
      planchangerApi: PlanchangerApi
      updatedSummary: PlanSummary
    },
    thunkAPI
  ) => {
    if (!planId) return

    const state = (await thunkAPI.getState()) as RootState
    const changeToken = activeSelectors.selectChangeToken(state)
    const path = `/summary`
    const operations = [
      {
        op: ReplacePatchOperationOpEnum.Replace,
        path,
        value: updatedSummary,
      },
    ]

    try {
      await planchangerApi.applyQualityControlPlanPatch(planId, operations, changeToken)
    } catch (err) {
      console.error(err)
      thunkAPI.dispatch(
        activeActions.setActiveToastMessage({
          message: `Failed to update control plan summary: ${err}`,
          intent: "danger",
        })
      )
    }
  }
)

export const storedControlPlanThunks = {
  fetchControlPlanById,
  updateDimensions,
  updateSummary,
}
